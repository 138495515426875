import React, {useState, useEffect} from 'react'

import {  BodyRg, TitleXl} from 'components/elements'
import { LocationCardWrap, LocationInnerWrap } from './elements'
import RichText from "components/RichText";


function LocationCard({ data }){

  const [width, setWidth] = useState();

  useEffect(() => {
    let newWidth = window.innerWidth;
    setWidth(newWidth);

    const updateWindowDimensions = () => {
       newWidth = window.innerWidth;
      setWidth(newWidth);
    };

    window.addEventListener("resize", updateWindowDimensions);

    return () => window.removeEventListener("resize", updateWindowDimensions) 

  }, []);

	return (
		<LocationCardWrap>
      <LocationInnerWrap>
			      <TitleXl fontWeight="900">
                {data.location}
            </TitleXl>
              <a href={data.addressUrl} target="_blank" rel="noopener noreferrer">
                {width <= 1024 ? <BodyRg>{data.address}</BodyRg> :  <RichText body={data.addressText}/> }         
              </a>  
       </LocationInnerWrap>   
		</LocationCardWrap>
	)

  }

export default LocationCard

