import styled from 'styled-components';
import { sizeTabletLg, sizeWideScreen } from "components/devices";

export const WistiaClickWrapper = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: transparent;
  z-index: 333;
  border: 0;
`;

export const WistiaWrapper = styled.div`
  border-top: 1px solid black;
  border-bottom: 1px solid black;
  position: relative;
  .wistia_custom_popup {
    z-index: 1;
  }
`;

export const WistiaAspectRatio = styled.div`
  position: relative;
  height: ${({ height }) => height || "600px"};
  overflow: hidden;
  ${sizeWideScreen(`
      min-height: 600px;
      height: 40vh;
    `)}

${sizeTabletLg(`
      padding: 0;
      height: 500px !important;
  `)}
  [data-handle="clickForSoundButton"] {
   display: none !important;
  }
`;

export const WistiaResponsiveWrapper = styled.div`
  height: 100%;
  left: 0;
  position: absolute;
  top: 0 ;
  width: 100%;
`;



export const Video = styled.video`
  background-color: ${({ backgroundColor }) => backgroundColor || "#FFF"};
  object-fit: ${({ objectFit }) => objectFit || "cover"};
  margin: 0 auto;
  height: 100%;
  width: ${({ width }) => width || "100%"};
  position: absolute;
  left: 0;
  top: 0;
  z-index: 10;
  &:focus {
    outline: none;
    box-shadow: none;
    border: none;
  }
  ${sizeTabletLg(`
  height: 100%;  
    width: 100%;
     object-fit: cover;
  `)}
  display: ${props => props.isSafari ? "none" : "block"};
`;
