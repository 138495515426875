import React from 'react';
import {
    ContentContainer,
    MobileYear,
} from './elements';

export const NoContentItem = ({ year }) => {
    return (
        <ContentContainer>
            <MobileYear isItem={year.item}>{year.year}</MobileYear>
        </ContentContainer>
    );
}

export default NoContentItem;