import {useState, useEffect} from 'react';
const useBrowserVisibility = (videoRef) => {
	const [hidden, setHidden] = useState('hidden');
    useEffect(() => {
        let visibilityChange = null;
        if (typeof document.hidden !== "undefined") { // Opera 12.10 and Firefox 18 and later support
            setHidden('hidden');
            visibilityChange = "visibilitychange";
        } else if (typeof document.msHidden !== "undefined") {
            setHidden('msHidden');
            visibilityChange = "msvisibilitychange";
        } else if (typeof document.webkitHidden !== "undefined") {
            setHidden('webkitHidden');
            visibilityChange = "webkitvisibilitychange";
        }
        document.addEventListener(visibilityChange, handleVisibilityChange, false);
        document.addEventListener('touchstart', handleVisibilityChange, true);
        return () => {
            document.removeEventListener(visibilityChange, handleVisibilityChange);
            document.removeEventListener('touchstart', handleVisibilityChange);
        }
        /* eslint-disable react-hooks/exhaustive-deps */
    }, []);
		const handleVisibilityChange = () => {
			if(videoRef.current) {
					if (!document[hidden]) {
							videoRef.current.play();
					}
			}
	}
}

export default useBrowserVisibility;