
import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components'

import { BodyRg, TitleSm } from '../elements'

import {
    sizeLaptopLg,
    sizeTabletLg,
    sizeMobileMd,
    sizeDesktopMd,
    sizeXlDevice,
    sizeMobileLg,
    sizeWideScreen
} from '../devices'


export const PopupCardsWrapper = styled.div`
    border-bottom: 1px solid #707070;

    /* Wide Screen CSS */
    ${sizeWideScreen(`
        border-left: 1px solid #707070;
        border-right: 1px solid #707070;
    `)}
`;

export const PopupCardsRow = styled.div`
    display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
    width: 100%;
    ${sizeTabletLg(css`
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    `)}
    
`;
export const PopupCardsCol = styled.div`
    -webkit-box-flex: 0;
    -ms-flex: 0 0 33.3333%;
    flex: 0 0 33.3333%;
    width: 33.3333%;
    ${sizeTabletLg(css`
    -webkit-box-flex: 0;
        -ms-flex: 0 0 100%;
        flex: 0 0 100%;
        width: 100%;
    `)}
`;




// PopupCard Card CSS Start
export const PopupCardNumber = styled.span`
    position: absolute;
    left: 50%;
    top: 53.5%;
    transform: translate(-50%, -50%);
    font-size: 500px;
    color: #fff;
    line-height: 0.75;
    font-family: 'Neue Pixel Grotesk';

    ${sizeDesktopMd(css`
        font-size: 300px;
    `)}
    ${sizeTabletLg(css`
        transform: none;
        top: auto;
        left: 30px;
        bottom: 30px;
        font-size: 250px;
        transform: none;
    `)}
    ${sizeMobileMd(css`
        font-size: 200px;
        left: 20px;
    `)}
`;
export const PopupCardImgWrap = styled.div`
    height: calc(100% - 300px);
    position: relative;

    ${sizeDesktopMd(css`
        height: calc(100% - 230px);
    `)}
`;
export const PopupCardImg = styled.img`
    width: 100%;
    object-fit: cover;
    height: 100%;
`;
export const PopupCardInfo = styled.div`
    background-color: #C3BFC2;
    position: absolute;
    left: 0;
    top: calc(100% - 300px);
    width: 100%;
    height: 100%;
    padding: 30px 35px;
    box-sizing: border-box;
    cursor: pointer;
    border-left: 1px solid #fff;
    border-top: 1px solid #fff;
    border-right: 1px solid #fff;
    transition: 0.7s all ease;
    padding-top: 60px;
    z-index: 2;

    // For 4k Device
    ${sizeXlDevice(`
        padding-left: 75px;
        padding-right: 75px;
    `)}

    ${sizeDesktopMd(css`
        padding-top: 40px;
    `)}

    ${sizeMobileMd(css`
        padding-top: 45px;
    `)}
    &:after {
        content: "";
        background-color: ${({ backgroundColor }) => backgroundColor || "#D12FFF"};
        width: 100%;
        height: 100%;
        position: absolute;
        left: 0;
        top: 0;
        z-index: -1;
        transition: .7s all ease;
        opacity: 0;
    }
    &:hover {
        &:after {
            opacity: 1;
        }
    }
    ${sizeDesktopMd(css`
        top: calc(100% - 230px);
    `)}

    ${TitleSm} {
        font-size: 40px;
        margin-bottom: 100px;

        // For 4k Device
        ${sizeXlDevice(`
            font-size: 2vw;
        `)}

        ${sizeTabletLg(css`
            font-size: 32px;
        `)}

        ${sizeWideScreen(`
            font-size: 1vw !important;
        `)}
    }
    ${BodyRg} {
        ${sizeWideScreen(`
            font-size: 0.7vw !important;
        `)}
    }
`;
export const PlusIconButton = styled.button`
    background: transparent;
    border: none;
    cursor: pointer;
    display: inline-block;
    width: 90px;
    height: 90px;
    margin-bottom: 35px;
    transition: .7s all ease;
    &:focus{
        outline: none;
        box-shadow: none;
    }
    
    ${sizeDesktopMd(`
        width: 70px;
        height: 70px;
        margin-bottom: 25px;
    `)}
    ${sizeLaptopLg(css`
        margin-bottom: 10px;
    `)}
`;
export const PlusIcon = styled.img`
    width: 100%;
`;
export const CloseIconButton = styled.button`
    background: transparent;
    border: none;
    cursor: pointer;
    display: inline-block;
    width: 150px; 
    cursor: pointer;
    position: absolute;
    left: 0;
    top: 0;
    ${sizeLaptopLg(css`
        width: 100px;
    `)}
    &:focus{
        outline: none;
        box-shadow: none;
    }
`;

export const CloseIcon = styled.img`
    width: 100%;
`;
export const PopupCardDetail = styled.div`
    max-width: 470px;
    margin-top: 0;
    
    // For 4k Device
    ${sizeXlDevice(`
        max-width: 550px;
    `)}
    ${sizeWideScreen(`
        max-width: 85%;
    `)}


    ${BodyRg} {
        margin-bottom: 35px;

        // For 4k Device
        ${sizeXlDevice(`
            font-size: 1.1vw;
            margin-bottom: 50px;
        `)}

        ${sizeDesktopMd(`
            font-size: 18px;
        `)}
    }
`;
export const PopupCardLink = styled(Link)`
    font-size: 18px;
    text-transform: uppercase;
    color: #fff;
    // For 4k Device
    ${sizeXlDevice(`
        font-size: 1.2vw;
    `)}
    ${sizeTabletLg(`
        font-size: 26px;
        margin-top: 20px;
    `)}
    ${sizeMobileLg(`
        font-size: 16px;
    `)}


        ${sizeWideScreen(`
            font-size: 0.6vw !important;
        `)}
`;

export const PopupCardWrap = styled.div`
    -ms-flex-wrap: wrap;flex-wrap: wrap;
    height: 100vh;
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
            flex-direction: column;
    overflow: hidden;
    -webkit-transition: .7s all ease;
    transition: .7s all ease;

    /* Wide Screen CSS */
    ${sizeWideScreen(`
        height: 1300px;
    `)}


    ${TitleSm} {
        ${sizeTabletLg(`
            font-size: 40px;
        `)}
        ${sizeMobileLg(`
            font-size: 32px;
        `)}
    }
    &.showOverlay {
        ${PopupCardInfo} {
            ${sizeDesktopMd(`
                padding: 30px;
            `)}
            top: 0;
            background-color: #000;
            &:hover {
                &:after {
                    content: none;
                }
            }
        }
        ${PlusIconButton} {
            transform: rotate(45deg);
            ${sizeDesktopMd(`
                margin-bottom: 10px;
            `)}
        }
        ${TitleSm} {
            margin-bottom: 50px;
            font-size: 56px;

            // For 4k Device
            ${sizeXlDevice(`
                margin-bottom: 45px;
                font-size: 2.2vw;
            `)}

            ${sizeDesktopMd(`
                margin-bottom: 30px;
                font-size: 36px;
            `)}
            ${sizeTabletLg(`
                font-size: 50px;
            `)}
            ${sizeMobileLg(`
                font-size: 32px;
            `)}
        }
        ${BodyRg} {
            ${sizeTabletLg(`
                font-size: 26px;
            `)}
            ${sizeMobileLg(`
                font-size: 16px;
            `)}
        }
        ${PopupCardDetail} {
            margin-top: 0;

            // For 4k Device
            ${sizeXlDevice(`
                max-width: 85%;
                margin-top: 5%;
            `)}

            ${sizeDesktopMd(`
                margin-top: 5%;
            `)}
            ${sizeTabletLg(`
                max-width: 90%;
                margin-top: 60%;
                transform: translateY(-50%);
            `)}
            ${sizeMobileLg(`
                max-width: 90%;
                margin-top: 25px;
                transform: none;
            `)}
        }
    }
    &.hoverRemove {
        ${PopupCardInfo} {
            &:hover {
                &:after {
                    opacity: 0;
                }
            }
        }
        ${PopupCardInfo} {
            border-top: none;
        }
    }
`;
