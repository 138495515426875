import styled from 'styled-components'

import {
  TitleSm,
  BodySm
} from "components/elements";

import {
  sizeTabletLg, sizeDesktopMd,
  sizeMobileSm, sizeMobileLg, sizeDesktopLg,  sizeMobileXs, sizeLaptopLg
} from "components/devices";


export const LinkCardWrap = styled.a`
  background-color: ${({ backgroundColor }) => backgroundColor || "#D12FFF"};
  padding: 40px 35px 20px;
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  align-items: stretch;
  position: relative;
  height: 470px;
  margin: ${({ margin }) => margin || "0"};
  transition: .4s all ease;
  box-sizing: border-box;
  ${sizeTabletLg(`
    margin-right: 0;
    padding: 30px 13px;
    min-height: 380px;
    max-height: 520px;
    height:  auto;
`)}
    &:hover {
      filter: ${({ noHover }) => noHover || "brightness(85%)"};

     
    }

`;

export const CopyListContainer = styled.div``

export const LinkCardIcon = styled.img`
      width: 91px;
      height: 95px;
  ${sizeTabletLg(`
      width: 65px;
      height: 69px;
    `)}
    ${sizeMobileXs(`
       width: 55px;
       height: 59px;
    `)}
`;

export const TitleParagraphContainer = styled.div`
 
  ${TitleSm} {
    font-size: 16px;
    font-size: 2.7vw;
    line-height: 43px;
    ${sizeDesktopLg(`
      line-height: 57px;
    `)}
    ${sizeDesktopMd(`
      line-height: 43px;
    `)}
    ${sizeLaptopLg(`
       font-size: 15px;
       font-size: 2.5vw;
    `)}
    ${sizeTabletLg(`
      font-size: 8vw;
       padding: 5px 0 0;
    `)}
    ${sizeMobileLg(`
     font-size: 8vw;
     line-height: 1.2;
    `)}
    ${sizeMobileSm(`
      font-size: 8vw;
      line-height: 1.2;
    `)}

  }
  ${BodySm} {
    margin: 30px 0 0;
    font-size: 20px;
    line-height: 24px;
    ${sizeTabletLg(`
      font-size: 16px;
      line-height: 19px;
  `)}
  }
`

export const UnorderedList = styled.ul`
  list-style-type: disc;
  color: #fff;
  float: left;
   width: 100%;
  -webkit-column-count:2;
  column-count: 2;
  padding: 5% 0 0;
  ${sizeTabletLg(`
      column-count: 1;
      -webkit-column-count: 1;
      padding: 10px 0 0;
`)}
`

export const ListItem = styled.li`
  font-size: 20px;
  line-height: 31px;
  color: #fff;
  margin-left: 1em;
  ${sizeTabletLg(`
    font-size: 16px;
    line-height: 24px;
`)}

`


export const LinkCardFooter = styled.div`
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: end;
  -ms-flex-align: end;
  align-items: center;
  padding: 0 0 10px;
  ${sizeTabletLg(`
      padding: 10px 0 0;
  `)}
  }
`

export const BodySmContainer = styled.div`
  width: 65%;
  ${sizeTabletLg(`
    width: 80%;
`)}
  ${BodySm} {
    margin-bottom: 6px;
    font-size: 16px;
    line-height: 19px;
    ${sizeTabletLg(`
      font-size: 14px;
      padding: 5px 0 0;
`)}
   ${sizeMobileXs(`
      font-size: 12px;
      line-height: 16px;
   `)}

`