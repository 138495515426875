import React, { useEffect} from 'react';
import {withRouter} from "react-router-dom";

import { PardotFormComp, PardotFormWrap} from './elements';

function PardotForm({pardotLink}) {

useEffect(() => {
    let iframe = document.getElementById('pardotForm');

    iframe.style.height = 250 + 'px';
	
	window.addEventListener('message', e => {
        const key = e.message ? 'message' : 'data';
        const data = e[key];
        iframe.style.height = data.height + 75 + 'px';
        if(data.height < 300){
            iframe.scrollIntoView({
                block: "center",
                behavior: "smooth",
            });
        }

    },false);

},[]);

return(
    <PardotFormComp>
        <PardotFormWrap>
            <iframe src={pardotLink} scrolling="no" width="100%" type="text/html" style={{border: 'none'}} id="pardotForm" className={"pardotForm"}></iframe>
        </PardotFormWrap>
    </PardotFormComp>
)


}

export default withRouter(PardotForm);
