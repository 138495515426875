import styled from 'styled-components';
import {BodyRg, BodySm, Col, Col6,  TitleMd, TitleSm, TitleXl, TitleXs} from "../../components/elements";
import {sizeLaptopLg, sizeMobileLg, sizeTablet, sizeTabletLg} from "../../components/devices";
import { SocialItem } from 'pages/footer/elements';
import { CircleSection, SmallCirclesContainer, AiPlusIdeas, LogoContainer } from 'components/HowWeWorkSection/elements';
import { BlogContainer } from 'pages/blog-details/elements';
import { MediaCardWrap } from 'components/MediaCard/elements';


export const EventsPageWrap = styled.div`
  position: relative;
  ${sizeMobileLg(`
    ${TitleMd}{
      font-size: 16px !important;
    }
 
  `)}


  .register-link {
    padding: 0;
    justify-content: center;
    width: 100%;

    a {
      justify-content: center;
      width: 100%;
    }
  }

  ${BlogContainer}{
    padding: 0 15px 50px 15px !important;
  }
`;
export const EventsPageRow = styled.div`
  display: flex;
  align-items: flex-start;
  position: relative;
  flex-wrap: wrap;
`;
export const EventsColLeft = styled.div`
  flex: 0 0 320px;
  max-width: 320px;
  width: 100%;
  position: sticky;
  top: 85px;
  ${sizeTabletLg(`
    flex: 0 0 100%;
    max-width: 100%;
    margin-bottom: 40px;
  `)}
`;
export const EventsColRight = styled.div`
  flex: 0 0 calc(100% - 320px);
  max-width: calc(100% - 320px);
  width: 100%;
  padding-left: 150px;
  ${sizeLaptopLg(`
    padding-left: 30px;
  `)}

  ${sizeTabletLg(`
    flex: 0 0 100%;
    max-width: 100%;
    padding-left: 0;
  `)}
`;
export const EventsContainer = styled.div`
  max-width: 940px;
  width: 100%;
  margin: 0 auto;
  section > ${TitleMd} {
    ${sizeTabletLg(`
        font-size: 1.9em;
    `)}
  }
  
  section + section {
    padding-top: 96px;
    ${sizeTabletLg(`
       padding-top: 40px;
       ${TitleMd} {
           font-size: 2em;
       }
    `)}
  }
  ${TitleXl}{
    font-size: 40px;
    line-height: 1.2;
    ${sizeTabletLg(`
      font-size: 36px;
    `)}
  }



  ${SocialItem}{
    justify-content: space-around;
  }
  ${BodyRg} {
    a {
      color: #D12FFF;
      text-decoration: underline;
    }
  }
`;

export const VideoCopyContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  ${sizeTabletLg(`
   flex-direction: column-reverse;
`)
}
  ${TitleXl}{
    padding: 0 0 0 30px;
    font-size: 32px;
    line-height: 1.2;
    max-width: 40%;
    ${sizeTabletLg(`
      font-size: 24px;
      max-width: 100%;
      padding: 0 0 40px;
    `)
  }
`

export const EventsReg = styled.section`
  margin-bottom: 35px;
 
`;


export const EventDetails = styled.section`
    ${TitleSm}{
      font-size: 24px;
    }
    
   ${sizeTabletLg(`
      .hide-mobile {
         display: none;
      }
    `)}
`;

export const FeaturedSpeakersSection = styled.section`
  .speaker-col {
    max-width: calc(50% - 12px);
    flex: 0 0 calc(50% - 12px);
    ${sizeTabletLg(`
      max-width: 100%;
      flex: 0 0 100%;
    `)}
  }
`;

export const SpeakersSection = styled.section`
  .speaker-col {
    max-width: calc(33% - 12px);
    flex: 0 0 calc(33% - 12px);
    ${sizeTabletLg(`
        max-width: 100%;
        flex: 0 0 100%;
    `)}
  }
`;

export const BlogsSection = styled.section`
  padding: 0 0 35px;
${Col6} {
  ${sizeMobileLg(`
    flex: 0 0 100%;
    max-width: 100%;
    padding: 0;

`)}
${MediaCardWrap}{
  ${sizeMobileLg(`
    padding: 15px 0;

`)}
}
}
`;

export const ViewAllContainer = styled.div`
   display: flex;
   justify-content: center;
`

export const ViewAllContainerTop = styled.div`
   display: flex;
   justify-content: center;
   padding: 70px 0 35px;
`

export const PromotionalSection = styled.section`
  img {
    width: 100%;
  }
`;


export const HealthcareSection = styled.section`
    margin: 35px 0 0 0;
    ${CircleSection}{
      ${TitleXl}{
        font-size: 30px;
        line-height: 1.2;
        font-weight: 700;
        ${sizeTablet(`
          font-size: 26px;
        `)}
        ${sizeMobileLg(`
         font-size: 20px;
        `)}
      }
      ${TitleSm}{
        font-size: 24px;
        line-height: 1.2;
      }
      ${BodySm}{
        font-size: 20px;
        ${sizeMobileLg(`
          font-size: 16px;
       `)}
        
      }
    }
    ${AiPlusIdeas}{
      ${TitleXl}{
        font-size: 70px;
        line-height: 84px;
        font-weight: 900;
        background: linear-gradient(180deg, #A036F5 34.9%, #8C31F4 68.23%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
        text-fill-color: transparent;
        ${sizeMobileLg(`
            font-size: 50px;
            line-height: 60px;

    `)}
    }
    }
    ${SmallCirclesContainer}{
      ${TitleXl}{
        font-size: 20px;
      }
    }
    }
    ${LogoContainer}{
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      ${TitleXl}{
        font-size: 34px;
        line-height: 1.2;
        ${sizeMobileLg(`
         font-size: 26px;
      `)}
      }
    }
`;



export const AgendaSection = styled.section`
  .speaker-col-sm {
    flex: 0 0 calc(33.333% - 12px);
    min-width: calc(33.333% - 12px);
    ${sizeTabletLg(`
        max-width: 100%;
        flex: 0 0 100%;
    `)}
  }
`;

export const SeeAllBtn = styled.button`
  background: transparent;
  border: none;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  font-size: 24px;
  color: #000;
  line-height: 1.2;
  margin-top: 45px;
  padding: 15px;
  text-transform: uppercase;
  transition: 0.3s all ease;
  ${sizeTabletLg(`
    margin-top: 25px;
    font-size: 20px;
  `)}

  svg {
    margin-right: 16px;
  }

  &:hover {
    color: #D12FFF;

    svg {
      path {
        stroke: #D12FFF;
      }
    }
  }
`;

// small accordion CSS
export const SmallAccordionHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  padding: 26px 24px;
  background: #F8F9FA;

  > ${TitleXs} {
    width: 65%;
    ${sizeTabletLg(`
        width: 100%;
    `)}
  }

  &:hover {
    svg {
      rect {
        fill: #D12FFF;
      }
    }
  }
`;
export const AccHeadRight = styled.div`
  display: flex;
  align-items: center;
  
  ${TitleXs} {
    ${sizeMobileLg(`
        display: none;
    `)}
  }

  svg {
    margin-left: 25px;
    width: 36px;
    height: 36px;
    flex: 0 0 36px;
    ${sizeTabletLg(`
        margin-left: 15px;
        width: 26px;
        height: 26px;
        flex: 0 0 26px;
    `)}
  }
`;
export const SmallAccordionBody = styled.div`
  background: #F8F9FA;
  padding: 15px 24px;
  display: none;
`;
export const SmallAccordion = styled.div`
  &:nth-child(2n + 2) {
    ${SmallAccordionHeader} {
      background: #FFF;
    }
  }

  &.show {
    ${SmallAccordionHeader} {
      background: #fff;

      svg {
        rect {
          &:first-child {
            display: none;
          }
        }
      }
    }

    ${SmallAccordionBody} {
      background: #F8F9FA;
      display: block;
    }
  }
`;


// Location Section CSS
export const LocationSection = styled.section`
`;


// Recording Section CSS
export const RecordingsSection = styled.section`
  ${Col} {
    max-width: calc(50% - 12px);
    flex: 0 0 calc(50% - 12px);
    ${sizeTabletLg(`
        max-width: 100%;
        flex: 0 0 100%;
    `)}
  }
`;

// Register Section CSS
export const RegSection = styled.section`
  margin-bottom: 0;

  ${TitleMd} {
    margin-bottom: 8px;
    text-transform: uppercase;
  }
`;
export const RegForm = styled.form`
  padding: 40px 0 0 0;
`;
export const RegSubmitBtn = styled.button`
  margin-top: 70px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  background: transparent;
`;
