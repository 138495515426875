import React, {useState} from 'react';
import {DropdownIcon} from "../elements";
import {
    Accordion, AccordionBody, AccordionWrapper, BodyInner
} from "./elements";


import CustomCheckBox from "./CustomCheckBox";
import {TitleMd} from "../elements";

function CollapsibleCheckbox({title, options, handleOnInputChange, handleDropdownSelect,type}) {
    const [activeItems, setActiveItems] = useState([true, true])

    const toggleCollapse = (index) => {
        const activeCopy = [...activeItems]
        activeCopy[index] = !activeCopy[index]
        setActiveItems(activeCopy)
    }



    return <div>
        <TitleMd>{title}</TitleMd>
        <AccordionWrapper>
            {Object.keys(options).map((option, index) => {
                const cname = (activeItems[index]) ? 'accordion active' : "accordion"
                return (
                    <Accordion className={cname} key={index}>
                        <BodyInner>
                            {options[option].cities.length !== 0 ? <DropdownIcon
                                onClick={() => toggleCollapse(index)} clicked={activeItems[index]}
                                src="/images/Dropdown-arrow.svg" alt="Dropwdown arrow to sub menu"/> : <div style={{marginLeft: "41px"}}/>}
                            <div>
                                <span className={options[option].count !== 0 ? "" : "c0"}>{type ==="Career" ? "NO CURRENT OPPORTUNITIES ON THESE TEAMS" : "NO CURRENT OPPORTUNITIES IN THIS LOCATION"}</span>
                                <CustomCheckBox
                                    id={option}
                                    option={option}
                                    optionsCount={options[option].count}
                                    checked={options[option].checked}
                                    disabled={options[option].count === 0}
                                    handleCheck={() => {
                                        if(options[option].count !== 0){
                                            handleDropdownSelect(option)
                                        }}
                                    }
                                />
                            </div>
                        </BodyInner>
                        <AccordionBody>
                            <div style={{marginLeft: "100px"}}>
                                {options[option].cities.map((subOption, index) => (
                                    <BodyInner key={index}>
                                        <span style={{left:"-10px"}} className={subOption.count !== 0 ? "" : "c0"}>{type==="Career" ? "NO CURRENT OPPORTUNITIES ON THESE TEAMS" : "NO CURRENT OPPORTUNITIES IN THIS LOCATION"}</span>
                                        <CustomCheckBox
                                            id={subOption.name}
                                            option={subOption.name}
                                            optionsCount={subOption.count}
                                            disabled={subOption.count === 0}
                                            checked={subOption.checked}
                                            handleCheck={() => {
                                                if(subOption.count !== 0){
                                                    handleOnInputChange(subOption)
                                                }}
                                            }
                                        />
                                    </BodyInner>
                                ))}
                            </div>
                        </AccordionBody>
                    </Accordion>
                )
            })}
        </AccordionWrapper>
    </div>
}

export default CollapsibleCheckbox;
