  import styled from 'styled-components';
  import {sizeMobileLg, sizeTabletLg} from "../devices";
  
  export const AudioBlock = styled.div`
    width: 100%;
    height: 100px;
    min-width: 685px;
      ${sizeMobileLg(`
        min-width: 100%;
    `)}
  `;
  export const PlayAudioBtn = styled.button`
    padding: 0;
    margin: 0;
    background: transparent;
    width: 50px;
    height: 50px;
    border: none;
    cursor: pointer;
    ${sizeTabletLg(`
      width: 40px;
      height: 40px;
    `)}
    svg {
      width: 100%;
      height: 100%;
    }
  `;
  export const PlayerActions = styled.div`
    max-width: 180px;
    margin: 0 auto 20px auto;
    display: flex;
    align-items: center;
    justify-content: center;
    height: auto;
    ${PlayAudioBtn} {
      &:hover {
        path {
          fill: inherit;
          stroke: #0F20FF;
        }
      } 
    }
  `;
  export const SkipButton  = styled.button`
    padding: 0;
    margin: 0;
    background: transparent;
    width: 32px;
    height: 32px;
    border: none;
    cursor: pointer;
    ${sizeTabletLg(`
      width: 30px;
      height: 30px;
    `)}
    svg {
      width: 100%;
      height: 100%;
    }
  }
    &:hover {
      path {
        fill: #0F20FF;
      }
  `;
  export const AudioRow = styled.div`
    display: flex;
    align-items: center;
    flex-direction: row;
  `;
  export const PlayerTime = styled.span`
    font-weight: normal;
    font-size: 16px;
    line-height: 22px;
    color: #000000;
    width: 35px;
    flex: 0 0 35px;
  `;
  export const AudioSlider = styled.input`
    position: relative;
    -webkit-appearance: none;
    padding: 0;
    height: 8px;
    outline: none;
    cursor: pointer;
    background-color: #E9ECEF;
    border-radius: 0;
    width: 100%;
    margin: 0 15px;
    ${sizeTabletLg(`
      margin: 0 15px;
    `)}
    &::-ms-fill-lower {
      background-color: #E9ECEF;
    }
    &::-ms-fill-upper {
      background-color: #000;
    }
    //  
    &:before {
      position: absolute;
      content: "";
      top: 0;
      left: 0;
      width: var(--seek-before-width);
      height: 100%;
      background-color: #000;
      cursor: pointer;
    }
    
    &::-webkit-slider-thumb {
      position: relative;
      -webkit-appearance: none;
      border: 2px solid #000;
      width: 16px;
      height: 16px;
      border-radius: 50%;
      background-color: #fff;
      cursor: pointer;
      margin: 0;
      box-sizing: border-box;
      z-index: 2;
    }
    &:active::-webkit-slider-thumb,
    &:active::-ms-thumb,
    &:active::-moz-range-thumb {
      background: #0F20FF;
      border-color: #0F20FF;
    }
  `;
