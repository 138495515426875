import React from 'react';
import styled from 'styled-components';
import {BodyRg, TitleSm} from "./elements";
import {sizeLaptopMd} from "./devices";


export const EmptySearchBlock = styled.div`
  padding: 32px;
  height: 680px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 100%;

  ${sizeLaptopMd(`
    height: 400px;
  `)}
  .icon {
    width: 64px;
    height: 64px;
    margin: 0 auto 25px;
    ${sizeLaptopMd(`
     width: 50px;
    height: 50px;
    margin: 0 auto 15px;
  `)}
  }
`;
export const EmptySearchContainer = styled.div`
  max-width: 600px;
`;


export const EmptySearch = () => {
    return (
        <EmptySearchBlock>
            <EmptySearchContainer>
                <img src="/images/RC_icon_digital_ search.svg" alt="Search media icon" className={'icon'}/>
                <TitleSm margin={'0 0 15px 0'}>No results</TitleSm>
                <BodyRg>There are no results for your search. Please refine your search.</BodyRg>
            </EmptySearchContainer>
        </EmptySearchBlock>
    )
};

export default EmptySearch;
