import React from 'react'

import
{
	GridSectionWrap,
	GridRow,
	GridCol
} from './elements'

import ItemsCard from 'components/ItemsCard'

function Grid({ items, activeTab })
{
	return (
		<GridSectionWrap>
			<GridRow className={activeTab === 0 ? 'show' : ''}>
				{ items && items.map( (item, index) => { return <GridCol  key={'tabelem-'+index} > <ItemsCard card={item} /> </GridCol> })}
			</GridRow>

		</GridSectionWrap>
	)
}

export default Grid

