import React, {useEffect} from "react";
import {TitleLg, TitleSm,} from "../../../components/elements";
import {
    ArrowIcon,
    ButtonWrapper,
    CalendarWrapper,
    BackArrowIcon,
    TitleWrapper,
    Calendar,
    CalendarCell
} from "./elements";
import MediaButton from "../../../components/MediaButton";

function CalendarSection({featuredEvents}) {

    //TODO clean this junk up
    const [date, setDate] = React.useState(new Date())
    const [displayedDates, setDisplayedDates] = React.useState([])


    useEffect(() => {
        const buildCalendarView = () => {
            const firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
            let firstDayOfMonth = firstDay.getDay()

            //because we index our calendar at 1 we only have to shift 0 values to 7
            if (firstDayOfMonth === 0) {
                firstDayOfMonth = 7
            }

            for (let i = firstDayOfMonth; i > 0; i--) {
                firstDay.setDate(firstDay.getDate() - 1)
            }

            const displayedDates = [[]]
            let j = 0
            for (let i = 0; i < 42; i++) {
                if (i % 7 === 0 && i !== 0) {
                    //checking if the current date is in the next month OR is
                    // the last day of the current month therefore we do not need a new row
                    if (firstDay.getMonth() !== date.getMonth() || new Date(firstDay.getTime() + 86400000).getDate() === 1)
                        break
                    j++
                    displayedDates[j] = []
                }
                featuredEvents.forEach((event) => {
                    if (new Date(event.date).toDateString() === firstDay.toDateString()) {
                    }
                })
                firstDay.setDate(firstDay.getDate() + 1)
                displayedDates[j].push(new Date(firstDay))
            }
            return displayedDates
        }
        const displayedDates = buildCalendarView()
        setDisplayedDates(displayedDates)
    },[date,featuredEvents])


    const monthString = date.toLocaleString('default', {month: 'long'});
    const yearString = date.getFullYear().toString()

    return (
        <CalendarWrapper>
            <ButtonWrapper>
                <TitleSm>
                    <MediaButton text={'Today'} handleChange={() => {setDate(new Date())}}/>
                    <BackArrowIcon onClick={() => {setDate(new Date(date.setMonth(date.getMonth() - 1)))}}/>
                    <ArrowIcon onClick={() => {setDate(new Date(date.setMonth(date.getMonth() + 1)))}}/>
                </TitleSm>
                <TitleWrapper>
                    <TitleLg>{monthString + " " + yearString}</TitleLg>
                </TitleWrapper>
            </ButtonWrapper>

            <Calendar>
                <thead>
                <tr>
                    {['Monday','Tuesday','Wednesday','Thursday','Friday','Saturday','Sunday'].map((day)=>(
                        <th key={day}>
                            {day}
                        </th>
                    ))}
                </tr>
                </thead>
                <tbody>
                {displayedDates.map((row,index) => (
                    <tr key={index}>
                        {row.map((date,index) => (
                            <CalendarCell key={index}>{date.getDate()}</CalendarCell>
                        ))}
                    </tr>
                ))}
                </tbody>
            </Calendar>
        </CalendarWrapper>

    );
}

export default CalendarSection;