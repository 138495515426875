import React, { useState, useEffect } from 'react';
import { Helmet } from "react-helmet";
import {
    WideScreenContainer
} from 'components/elements'

import { findContent, getImageDimensions } from "../../../generalHelpers";
import PurpleAnimHeader from 'components/PurpleAnimHeader';
// import ViewAll from 'components/ViewAll';

import { r3cPageContent } from "../../../constants";
// import {r3cPageContent} from 'constants/index'
import NewsletterSignup from 'components/NewsletterSignup';
// import {findContent, getImageDimensions, isEmptyObject} from 'generalHelpers'

import FooterAnimation from 'components/FooterAnimation'
import LearnAboutOtherNewsletters from 'components/LearnAboutOtherNewsletters';
import { NewslettersPageWrap } from '../elements';
// import useRememberScrollPosition from 'hooks/useRememberScrollPosition';
import LoadingHoc from 'hoc/LoadingHOC';


function MonthlyDigestSignup(props) {

	const {setLoading} = props		 
	const [heroSectionData, setHeroSectionData] = useState({});
	const [textSectionData, setTextSectionData] = useState({});
	const [region, setRegion] = useState({});
	const [seoData, setSeoData] = useState({}); 

	useEffect(() => {
		fetch(r3cPageContent + '?route=/healthcare-connection')
		  .then(response => response.json())
		  .then(page => {
			let seo = page.seo;
			let image = page?.seo?.image?.split('.');
	
			if (page?.seo?.image) {
			  getImageDimensions(page?.seo?.image, (width, height) => {
				setSeoData({
				  ...seo,
				  mimeType: image[image.length - 1],
				  seoImageHeight: height,
				  seoImageWidth: width
				});
			  });
			} else {
			  setSeoData(seo);
			}
	
			setHeroSectionData(findContent(page.contents, 'heroArea'));
			setTextSectionData(findContent(page.contents, 'textArea'));
			setRegion(findContent(page.contents, 'region', 1));
	
			setLoading(false);
		  });
	  }, [setLoading]);

	// useRememberScrollPosition('monthly-digest')
	
	return (
		<NewslettersPageWrap className="pageWrapper">
			<Helmet>
				<title>{seoData.title}</title>
				<link rel="canonical" href={window.location.pathname}/>
				<meta name="description" content={seoData.description}/>
				<meta name="og:description" content={seoData.description}/>
				<meta name="og:title" content={seoData.title}/>
				<meta name="og:url"
					  content={window.location.protocol + "//" + window.location.host + window.location.pathname}/>
				<meta name="og:type" content="website"/>
				<meta name="og:site_name" content="Real Chemistry"/>

				<meta name="og:image" content={window.location.protocol + seoData.image}/>
				<meta name="og:image:type" content={"image/" + seoData?.mimeType}/>
				<meta name="og:image:width" content={seoData?.seoImageWidth}/>
				<meta name="og:image:height" content={seoData?.seoImageHeight}/>
			</Helmet>
			<PurpleAnimHeader
                sectionLabel={heroSectionData.label}
                sectionTitle={undefined}
				heroIntro={undefined}
				headerSplit={"2"}
        		backgroundImage={heroSectionData.heroImage}
				backgroundImgMbl={heroSectionData.heroImage}
				backgroundImgTitle={""}
				header={heroSectionData?.textArea?.content?.[0]?.content?.[0]?.value || undefined}
				introCopy={textSectionData?.text?.content?.[0]?.content?.[0]?.value || undefined}
                titleMdPadding={"25% 0 0"}
				maxWidth={"30%"}
				marginRight={"15%"}
				maxHeight={"700px"}
				headerMaxWidth="60%"
            />

			<WideScreenContainer>
				<NewsletterSignup
                    phoneDesktop={"/images/monthly-digest-phone.png"}
                    phoneMobile={"/images/monthly-digest-phone.png"}
					pardotLink={"https://go.realchemistry.com/l/603341/2022-11-11/691jm"}
				/>
				<LearnAboutOtherNewsletters
				  header={region?.elements?.[0]}
				  cards={region?.elements?.[1]}
				  monthly={false}
				  healthEquity={true}
				  valueReport={true}
				  displayHeader={true}
				/>

			</WideScreenContainer>

			<FooterAnimation
				svgDesktop="/images/purple-footer.svg"
				svgMobile="/images/purple-footer-mbl.svg"
				borderTop="none"
			/>
		</NewslettersPageWrap>
	)
}


export default LoadingHoc(MonthlyDigestSignup);
