import React from 'react';
import {withRouter} from "react-router-dom"
import { TitleXl,  TitleSm, TitleXs, Row, Col4, Col3} from "components/elements";
import {LeadershipTeamContainer, TitleXlContainer,  ExecutivesContainer, ImageContainer, NameContainer, TitleContainer, LinkedInContainer, InnerColContainer} from '../LeadershipTeamSection/elements';

function SpecialtyLeadershipSection({leadership, maxWidth, thm, backgroundColor}) {

return(
        <LeadershipTeamContainer  backgroundColor={backgroundColor}>
          {!thm && (
                      <TitleXlContainer  maxWidth={maxWidth ? maxWidth : '75%'}>
                      <TitleXl fontWeight="900">
                         {leadership?.title}
                      </TitleXl>
                  </TitleXlContainer>
            
            )}
      
            <ExecutivesContainer>
                {!thm && (
                    <Row justifyContent={'flex-start'}>
                        {leadership.cards?.map((data, index) => (
                            <Col4 justifyContent="center" key={index}>
                                <InnerColContainer>
                                    <ImageContainer>
                                        <img src={data.image} alt={data.label} className="leaderImage" />
                                    </ImageContainer>
                                    <NameContainer>
                                        <TitleSm>
                                            {data.label}
                                        </TitleSm>
                                    </NameContainer>
                                    <TitleContainer>
                                        <TitleXs>
                                            {data.title}
                                        </TitleXs>
                                    </TitleContainer>
                                    <LinkedInContainer target="_blank" href={data.ctaLink}>
                                        <img src="/images/linkedIN-logo.svg" className={"linkedIn"} alt={"LinkedIn logo link"} />
                                    </LinkedInContainer>
                            </InnerColContainer>                        
                            </Col4>
                        ))} 
                    </Row>
                  )}
                   {thm && (
                    <Row className={"thmRow"}>
                        {leadership.cards?.map((data, index) => (
                         <Col3  key={index}>
                             <InnerColContainer className={"thmInner"}>
                                 <ImageContainer>
                                     <img src={data.image} alt={data.label} className="leaderImage" />
                                 </ImageContainer>
                                 <NameContainer className={"thmNameCont"}>
                                     <TitleSm>
                                         {data.label}
                                     </TitleSm>
                                 </NameContainer>
                                 <TitleContainer className={"thmTitleCont"}>
                                     <TitleXs>
                                         {data.title}
                                     </TitleXs>
                                 </TitleContainer>
                                 <LinkedInContainer target="_blank" href={data.ctaLink} className={"thmLinkedin"}>
                                     <img src="/images/linkedIN-logo.svg" className={"linkedIn"} alt={"LinkedIn logo link"} />
                                 </LinkedInContainer>
                           </InnerColContainer>                        
                         </Col3> 
                        ))} 
                    </Row>
                  )}
            </ExecutivesContainer>
          
        </LeadershipTeamContainer>
    )
}

export default withRouter(SpecialtyLeadershipSection);