import React from 'react';
import {documentToReactComponents} from "@contentful/rich-text-react-renderer";
import {richTextOptions} from "./options";

function RichText({body,options}) {
  return (
    <>
      {body ? documentToReactComponents(body, richTextOptions(options)) : ''}
    </>
  );
}

export default RichText;