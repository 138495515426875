import {Link} from "react-router-dom";
import styled from "styled-components";

import {TitleXs, OverlayBase, TitleMd, SectionContentContainer, BodyRg, TitleSm} from "components/elements";
import {sizeTabletLg, sizeLaptopMd, sizeWideScreen, sizeLaptop} from "components/devices";

// CSS Start
export const AccordionCardWrap = styled.div`
  iframe {
    max-width: 750px;
    width: 100%;
    display: block;
    height: 100%;
    min-height: 1050px;
    border: 1px solid #c0c2c7;
    margin: auto;
    overflow: visible;
    box-sizing: border-box;
    ${sizeTabletLg(`
      min-height: 1200px;
    `)}
  }

  ${SectionContentContainer} {
    padding: 2em 1.5em;
  }
`;
export const AccordionToggle = styled.div`
  width: 84px;
  height: 84px;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 84px;
  flex: 0 0 84px;
  margin-left: 50px;
  position: relative;
  overflow: hidden;
  -webkit-transition: 0.5s all ease;
  transition: 0.5s all ease;
  ${sizeTabletLg(`
        width: 24px;
        height: 28px;
        -webkit-box-flex: 0;
        -ms-flex: 0 0 24px;
        flex: 0 0 24px;
        margin-left: 20px;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
    `)}
`;
export const Bar = styled.span`
  width: 50px;
  height: 4px;
  background-color: #000;
  display: block;
  position: absolute;
  left: 0;
  top: 40px;
  transition: 0.7s all ease;

  &:nth-child(2) {
    left: 0;
    transform: rotate(90deg);
  }

  ${sizeTabletLg(`
        width: 24px;
        top: 12px;
    `)}
`;
export const AccordionHeader = styled(OverlayBase)`
  border-top: 1px solid #707070;
  box-sizing: border-box;
  padding: 20px 104px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  background-color: #fff;
  cursor: pointer;
  z-index: 2;
  position: relative;

  ${sizeLaptop(`
        padding: 20px 25px;
    `)}
  ${TitleXs} {
    transition: 0.9s all ease;
  }

  ${TitleMd} {
    ${sizeTabletLg(`
            font-size: 24px;
        `)}
  }

  &.expanded {
    &:before {
      width: 100%;
    }

    ${TitleSm} {
      color: #fff;
    }

    ${Bar} {
      background-color: #fff;

      &:last-child {
        top: 200%;
        opacity: 0;
      }
    }

    &:hover {
      ${TitleSm} {
        color: #fff;
      }

      ${Bar} {
        background-color: #fff;
      }
    }
  }

  @media (min-width: 992px) {
    &:hover {
      ${TitleSm} {
        color: #D12FFF;
      }

      ${Bar} {
        background-color: #D12FFF;
      }
    }
  }
`;

export const AccordionBody = styled.div`
  a {
    line-height: 1.3;
    color: #0f20ff;
    border-bottom: 1.3px solid #0f20ff;
    word-break: break-word;
  }

  ${BodyRg} {
    font-size: 24px;
    line-height: 1.5;
    ${sizeTabletLg(`
        font-size: 20px;,
    `)}

    ${sizeWideScreen(`
      font-size: 0.85vw !important;
    `)}
  }
`;
export const BodyInner = styled.div`
  padding: 2em;
  box-sizing: border-box;
  ${sizeLaptopMd(`
        padding: 50px 25px;
    `)}
  ${sizeTabletLg(`
        padding: 30px 25px;
    `)}
`;
export const BodyContainer = styled.div`
  max-width: 900px;
  margin: 0 auto;
  ${sizeWideScreen(`
      max-width: 100%;
    `)}
`;
export const PrivacyLink = styled(Link)`
  color: #0f20ff;
  text-decoration: underline;
`;

export const DataList = styled.ul``;
export const ListItem = styled.li`
  padding-left: 25px;
  position: relative;
  margin-bottom: 25px;

  ${sizeTabletLg(`
    margin-bottom: 20px;
`)}
  &:before {
    content: "";
    width: 10px;
    height: 2px;
    display: inline-block;
    position: absolute;
    left: 0;
    top: 15px;
    background-color: #000;
  }
`;

// HTML Table
export const Table = styled.table`
  table-layout: fixed;
  width: 100%;

  &.desktop {
    ${sizeTabletLg(`
            display: none;
        `)}
  }
`;
export const THead = styled.thead``;
export const Th = styled.th`
  background-color: #fff;
  border: 1px solid #707070;
  border-bottom: none;
  border-left: none;

  &:first-child {
    border-left: 1px solid #707070;
  }
`;
export const TBody = styled.tbody``;
export const Td = styled.td`
  padding: 0;
  border: 1px solid #707070;
  background-color: #f8f8f8;
  border-left: none;
  border-bottom: none;
  vertical-align: top;

  &:first-child {
    border-left: 1px solid #707070;
  }
`;
export const Tr = styled.tr`
  &:last-child {
    ${Td} {
      border-bottom: 1px solid #707070;
    }
  }
`;

// Inform Card Header
export const InformHeader = styled.div`
  padding: 15px;
  height: 112px;
  box-sizing: border-box;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  border-top: ${({borderTop}) => borderTop || "1px solid #707070"};
  border-right: ${({borderRight}) => borderRight || "1px solid #707070"};
  border-bottom: ${({borderBottom}) => borderBottom || "1px solid #707070"};
  border-left: ${({borderLeft}) => borderLeft || "1px solid #707070"};
  text-align: center;

  ${sizeTabletLg(`
        border-bottom: none;
        text-align: center;
        background-color: #F8F8F8;
    `)}
  ${BodyRg} {
    font-size: 24px;
    max-width: 420px;
    margin-bottom: 0;
    ${sizeLaptopMd(`
            font-size: 20px;
        `)}

    ${sizeWideScreen(`
      max-width: 100%;
    `)}
  }
`;
