import React, {useEffect} from 'react'
import { TitleXl,  Row , Col7, Col5 } from 'components/elements';
import RichText from 'components/RichText';
import { BlogWistiaVideo } from 'pages/blog-details/BlogDetail/elements';
import WistiaPlayer from 'components/WistiaPlayer/WistiaPlayer';
import Carousel from 'components/Carousel';

function TwoColCopyMediaSection({data, colMargin, alignItems, rowMargin, mobileColumn}) {
    const [wistiaVideoID, setWistiaVideoID] = React.useState()


    useEffect(() => {
        if(data?.columnVideo){
            let id = data?.columnVideo.split('/')
            setWistiaVideoID(id[id?.length - 1])
        }
    },[data])


    return(
        <Row justifyContent={"space-between"} alignItems={alignItems} margin={rowMargin} mobileColumn={mobileColumn}>

            {data?.title && data?.columnMedia && (
                <>
                    <Col5 margin={colMargin}>
                        <TitleXl fontWeight={"900"} fontSize={"24px"}>
                                {data?.title}
                        </TitleXl>
                        <div style={{padding: '20px 0 0 '}}>
                            <RichText options={{fontSize: '20px', color: '#000', margin: '0 0 20px'}} body={data?.columnCopy}/>
                        </div>
                    </Col5>
                    <Col7 textAlign={'right'}>
                        <img src={data?.columnMedia} alt="Real Chemistry workers" />
                    </Col7>
                </>
            )}

            {!data?.title && data?.columnMedia && (
                <>
                    <Col7 padding={'0 5% 0 0'}>
                        <img src={data?.columnMedia} alt="Real Chemistry workers" />
                    </Col7>
                    <Col5 margin={colMargin} className='mobilePad'>
                         <RichText options={{fontSize: '20px', color: '#000', margin: '0 0 20px'}} body={data?.columnCopy}/>
                    </Col5>           
                </>
            )}

            {data?.columnVideo && (
                <>
                  <Col7>
                        <BlogWistiaVideo className={'video'}>
                            <WistiaPlayer
                                controlsVisibleOnLoad={false}
                                customClass="wistia_custom"
                                hashedId={wistiaVideoID}
                                isChannel={false}
                            />
                        </BlogWistiaVideo>
                    </Col7>
                    <Col5>
                        <RichText options={{fontSize: '20px', color: '#000'}} body={data?.columnCopy}/>
                    </Col5>
             
                </>
            )}

            {data?.columnCarousel && (
                <>
                     <Col5 padding={'0 5% 0 0'}>
                        <RichText options={{fontSize: '20px', color: '#000', margin: '0 0 20px'}} body={data?.columnCopy}/>
                    </Col5>
                    <Col7 padding={'200px 0 0 7%'}>
                        <Carousel content={data.columnCarousel}/>
                    </Col7>
             
                </>
            )}
        
        </Row>
    )

}

export default TwoColCopyMediaSection;