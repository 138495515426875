import React from 'react'

import {
    Row,
    Col5,
    Col7,
    TitleContainer,
    TitleXs,
    TitleLg,
    TitleXl,
    BackArrow,
    BodySm,
    WideScreenContainer, TitleSm,
} from 'components/elements'

import {
    FilterCard,
    JobCategoryPageWrap,
    CategoryFilterSection,
    CategorySectionRight,
    SearchGroup,
    SearchBtn,
    SearchInput,
    SearchBox,
    BadgesRow,
    ResetButton,
    BadgeContainer,
    HeaderContainer,
    ViewAllContainer
} from './elements'
import ViewAll from 'components/ViewAll'
import JobPostCard from 'components/JobPostCard'

import {DynamicContent, r3cPageContent} from 'constants/index'
import LoadingHoc from "../../hoc/LoadingHOC";
import BackArrowSvg from "../../assets/icons/BackArrowSvg";
import FooterAnimation from "../../components/FooterAnimation";
import CollapsibleCheckboxArea from "../../components/CollapsibleCheckboxArea";

function JobCategoryPage(props) {
    const {setLoading} = props;
    let a = props.location.pathname.split('/')
    let initialFilter = null
    if (a && a.length > 2 && a[2].length > 0)
        initialFilter = a[2]
    const [capabilities, selectCapabilities] = React.useState(["All Careers"])
    const [locations, selectLocations] = React.useState(["All Locations"])
    const [allLocations, setAllLocationOptions] = React.useState({})
    const [allCapabilities, setAllCapabilityOptions] = React.useState({})
    const [allJobs, setAllJobs] = React.useState([])
    const [currentJobs, setCurrentJobs] = React.useState([])
    const [scrollTop, setScrollTop] = React.useState(false)
    const [scrollThresh, setScrollThresh] = React.useState(0)
    const [threshold, setThreshold] = React.useState(0)

    // for search prototype
    const [searchedJob, setSearchedJob] = React.useState('');
    const [totalJobs, setTotalJobs] = React.useState([]);
    const locationRef = React.useRef(null)
    const filterRef = React.useRef(null)




    React.useEffect(() => {
        setThreshold(locationRef.current.offsetTop)

        window.addEventListener('scroll', function () {
            setScrollTop((scrollThresh < window.scrollY) ? true : false)
            setScrollThresh(window.scrollY)
            // setValue((threshold > window.scrollY) ? false : true)
        }, true)

        return () => {
            window.removeEventListener('scroll', function () {
            }, true)
        }
    }, [threshold, scrollThresh, scrollTop])

    const normalizeLocations = (locations) => {
        const allLocs = {}
        const countryCount = {}

        allLocs["All Locations"] = {cities: [], checked: false}

        for (let i = 0; i < locations.length; i++) {
            let country = locations[i].country
            let city = locations[i].city
            let name = locations[i].name
            const state = locations[i].state
            const count = locations[i].count

            if (name.includes("Remote - USA")) {
                country = "United States of America"
                name = "Remote"
            }
            if (name.includes("Remote - UK")) {
                country = "United Kingdom"
                name = "Remote - UK"
            }

            if (name.includes("Remote - Canada")) {
                country = "Canada"
                name = "Remote - Canada"
            }

            if (country === ""){
                continue
            }

            if (!(country in allLocs)) {
                allLocs[country] = {
                    cities: [{
                        city: city,
                        value: locations[i],
                        checked: false,
                        country: country,
                        state: state,
                        name: name,
                        count: count
                    }], checked: false, count: count
                }
                countryCount[country] = count
            } else {
                if (name.includes("Remote")) {
                    allLocs[country].cities.unshift({
                        city: city,
                        value: locations[i],
                        checked: false,
                        country: country,
                        state: state,
                        name: name,
                        count: count
                    })
                } else {
                    allLocs[country].cities.push({
                        city: city,
                        value: locations[i],
                        checked: false,
                        country: country,
                        state: state,
                        name: name,
                        count: count
                    })
                }
                countryCount[country] = countryCount[country] += count
            }
        }
        Object.keys(countryCount).forEach((country) => {
            allLocs[country].count = countryCount[country]
        })

        if (initialFilter) {
            let a = initialFilter.split(':')
            let option = ""
            if (a.length === 2) {
                if (a[0] === 'l') {
                    if (a[1].includes("Remote") && !a[1].includes("Canada"))
                        a[1] = "Remote"
                    
                    if (a[1].includes("Remote - Canada"))
                        a[1] = "Remote - Canada"
                    if (a[1].includes("Remote - UK"))
                        a[1] = "Remote - UK"
                    localStorage.setItem('locations', a[1])
                    Object.keys(allLocs).forEach((country) => {
                        allLocs[country].cities.forEach((city) => {
                            if (city.name === a[1])
                                option = city
                        })
                    })
                    if (option === "")
                        option = "All Locations"
                    // if (a[1].includes("Remote"))
                    //     option = "Remote"
                    const ret = updateLocationHelper(option, allLocs, [])
                    setAllLocationOptions(ret[0])
                    selectLocations(ret[1])
                    return
                }
                else{
                    allLocs["All Locations"].checked = true
                }
            }
        } else {
            allLocs["All Locations"].checked = true
        }
        setAllLocationOptions(allLocs)
    }

    const normalizeCapabilities = (capabilities) => {
        const allCaps = {}
        const capsList = []
        allCaps["All Careers"] = {cities: [], checked: false}

        const sortedCaps = capabilities.sort((a, b) => {
                if(a.name == b.name) return 0;
                if (a.name == 'All Careers') return -1;
                if (b.name == 'All Careers') return 1;
              
                if (a.name < b.name )
                    return -1;
                if (a.name  > b.name)
                    return 1;
                return 0;
        })

        sortedCaps.forEach((cap) => {
            allCaps[cap.name] = {cities: [], checked: false, count: cap.count}
            capsList.push(cap.name)
        })

        if (initialFilter) {
            let a = initialFilter.split(':')
            if (a.length === 2) {
                if (a[0] === 'c' && capsList.includes(a[1])) {
                    const ret = updateCapabilityHelper(a[1], allCaps, [])
                    setAllCapabilityOptions(ret[0])
                    selectCapabilities(ret[1])
                    localStorage.setItem('capability', a[1])
                    return
                }
                else{
                    allCaps["All Careers"].checked = true
                }
            }
        } else {
            allCaps["All Careers"].checked = true
        }
    
        setAllCapabilityOptions(allCaps)
    }

   

    React.useEffect(() => {
        if (DynamicContent) {
            fetch(r3cPageContent + '?route=/job-category').then((response) => response.json()).then((pgData) => {
                let cOpts = []
                let lOpts = []
                let filters = []

                if (pgData.locations === null)
                    pgData.locations = []


                pgData.capabilities && pgData.capabilities.map((cap, index) => cOpts.push({id: index + 1, name: cap}))
                pgData.locations.map((loc, index) => lOpts.push({id: index + 1, name: loc.split(',')[0]}))
                pgData.filters && pgData.filters.map((fil, index) => filters.push({id: index + 1, name: fil}))
                // setCapabilityOptions([{ id: -1, name: 'All Focus Areas' }, ...cOpts])
                // setLocationOptions([{ id: -2, name: 'All Locations' }, ...lOpts])

                for (let i = 0; i < pgData.jobs.length; i++) {
                    if (pgData.jobs[i].primaryLocation === "Remote - USA"){
                        pgData.jobs[i].primaryLocation = "Remote";
                    }

                    //TODO probably remove this ideally we get complete results from workday
                    if (!pgData.jobs[i].primaryLocation || pgData.jobs[i].primaryLocation === "Remote - CT"){
                        let index = pgData.jobs.indexOf(pgData.jobs[i]);
                        if (index !== -1) {
                            pgData.jobs.splice(index, 1);
                        }
                    }

                    if (pgData.jobs[i].locations) 
                        pgData.jobs[i].locations = pgData.jobs[i].locations.split(';')
                    
                }
                setAllJobs(pgData.jobs)
                // for search prototype
                setTotalJobs(pgData.jobs);


                //Need to move this to the backend mapping eventually
                let capsNoCreative = pgData.capabilities.map((c) => c.name).indexOf("Creative")
                if (capsNoCreative > -1) { 
                    pgData.capabilities.splice(capsNoCreative, 1); 
                  }
                  


                let allCaps = [...pgData.capabilities, ...pgData.filters]
      

                normalizeLocations(pgData.jobLocations, a)
                normalizeCapabilities(allCaps, a)

                setLoading(false)
            })
        } else {
            setAllJobs
            ([{
                detailLink: "/job-detail",
                date: "Posted: January 1, 2020",
                primaryLocation: "London",
                department: "Integrated Communications",
                title: "Senior Account Manager"
            }])
        }
        //eslint-disable-next-line
    }, [initialFilter])

    React.useEffect(() => {
        locationRef.current.scrollTo(0, 0)
        let nj = []


        //TODO This can be improved
        for (let h = 0; h < capabilities.length; h++) {
            let capability = capabilities[h]
            for (let k = 0; k < locations.length; k++) {
                let location = locations[k]
                for (let i = 0; i < allJobs.length; i++) {
                    let j = allJobs[i]
                        const isAllLocations = location === 'All Locations';
                        const isAllCareers = capability === 'All Careers';

                        const matchesLocation = isAllLocations || location === j.primaryLocation;
                        const matchesCapability = isAllCareers || capability === j.bucket || capability === j.filterBucket;

                        if (matchesLocation && matchesCapability && !nj.includes(j)) {
                            nj.push(j);
                        }
                }
            }
        }
        setCurrentJobs(nj)
    }, [allJobs, capabilities, locations])


    //handling job search.
    const handleSearch = (e) => {

        let searchedTerm = e.target.value;
        let result;
        setSearchedJob(searchedTerm);
        if (searchedTerm.length > 2) {
            result = totalJobs.filter(job => {
                let search = searchedTerm.toLowerCase(),
                    match = false;

                if (job.title?.toLowerCase().includes(search)) {
                    match = true;
                } else if (job.department?.toLowerCase().includes(search)) {
                    match = true;
                } else if (job.primaryLocation?.toLowerCase().includes(search)) {
                    match = true;
                } else if (job.departmentDescription?.toLowerCase().includes(search)) {
                    match = true;
                }

                return match;
            })
        } else {
            result = totalJobs;
        }
        setAllJobs(result);
    }

    const handleSearchClick = () => {
        if (window.innerWidth < 992) {
            scrollToJobResults();
        } else {
            document.getElementById('search').focus();
        }
    }

    const handleKeyDown = (e) => {
        if (window.innerWidth < 992 && e.key === 'Enter') {
            scrollToJobResults();
        }
    }

    function scrollToJobResults() {
        document.activeElement.blur();

        let jobResults = document.getElementById('job-results'),
            position = jobResults.getBoundingClientRect().top,
            offset = 71;

        window.scrollTo({
            left: 0,
            top: position - offset,
            behavior: 'smooth',
        });
    }

    const updateSelectedCity = (option) => {
        const a = updateLocationHelper(option, allLocations, locations)
        setAllLocationOptions(a[0])
        selectLocations(a[1])
    }

    const updateSelectedCountry = (option) => {
        const a = updateLocationHelper(option, allLocations, locations)
        setAllLocationOptions(a[0])
        selectLocations(a[1])
    }

    const updateSelectedCapability = (option) => {
        const a = updateCapabilityHelper(option, allCapabilities, capabilities)
        setAllCapabilityOptions(a[0])
        selectCapabilities(a[1])
    }

    const updateLocationHelper = (option, allLocsRef, locsRef) => {
        const allLocs = JSON.parse(JSON.stringify(allLocsRef))
        let locs = [...locsRef]

        if (locs.includes("All Locations") && locs.length < 2) {
            locs = []
            allLocs["All Locations"].checked = false
        }

        if (option === "All Locations") {
            locs = []
            Object.keys(allLocs).forEach((country) => {
                allLocs[country].checked = false
                allLocs[country].cities.forEach((city) => {
                    city.checked = false
                })
            })
        }

        //checking if it is a city or a country
        if (allLocs[option]?.cities) {
            if (option === "All Locations") {
                if (allLocs[option].checked) {
                    let index = locs.indexOf("All Locations");
                    if (index !== -1) {
                        locs.splice(index, 1);
                    }
                } else {
                    locs.push("All Locations")
                }
            } else {
                if (allLocs[option].checked) {
                    allLocs[option].cities.forEach((city) => {
                        city.checked = false
                        let index = locs.indexOf(city.name);
                        if (index !== -1) {
                            locs.splice(index, 1);
                        }
                    })
                } else {
                    allLocs[option].cities.forEach((city) => {
                        city.checked = true
                        locs.push(city.name)
                    })
                }
            }
            allLocs[option].checked = !allLocs[option].checked
        } else {
            allLocs[option.country].checked = true
            allLocs[option.country].cities.forEach(element => {
                if (element.city === option.city)
                    element.checked = !element.checked
            })

            if (allLocs[option.country].cities.every(obj => obj.checked === false))
                allLocs[option.country].checked = false

            if (!option.checked) {
                locs.push(option.name)
            } else {
                let index = locs.indexOf(option.name);
                if (index !== -1) {
                    locs.splice(index, 1);
                }
            }
        }

        if (locs.length === 0){
            locs = ["All Locations"]
            allLocs["All Locations"].checked = true
        }
        return [allLocs, locs]
    }

    const updateCapabilityHelper = (option, allCapsRef, capsRef) => {
        const allCaps = JSON.parse(JSON.stringify(allCapsRef))
        let caps = [...capsRef]

        if (option === "All Careers") {
            caps = ["All Careers"]
            Object.keys(allCaps).forEach((i) => {
                allCaps[i].checked = false
            })
        }

        if (caps[0] === "All Careers" && caps.length === 1) {
            caps = [option]
            allCaps["All Careers"].checked = false
        }
        else {
            let index = caps.indexOf(option);
            if (index !== -1) {
                caps.splice(index, 1);
            } else {
                caps.push(option)
            }
        }

        if (caps.length === 0){
            caps = ["All Careers"]
            allCaps["All Careers"].checked = true
        }

        allCaps[option].checked = !allCaps[option].checked
        return [allCaps, caps]
    }

    const clearFilters = () => {
        const caps = ["All Careers"]
        selectCapabilities(caps)

        const locs = ["All Locations"]
        selectLocations(locs)

        const allLocs = JSON.parse(JSON.stringify(allLocations))
        Object.keys(allLocs).forEach((loc) => {
            allLocs[loc].checked = false
            allLocs[loc].cities.forEach((city) => {
                city.checked = false
            })
        })
        allLocs["All Locations"].checked = true
        setAllLocationOptions(allLocs)

        const allCaps = JSON.parse(JSON.stringify(allCapabilities))
        Object.keys(allCaps).forEach((cap) => {
            allCaps[cap].checked = false
        })
        allCaps["All Careers"].checked = true
        setAllCapabilityOptions(allCaps)
    }


    return (
        <JobCategoryPageWrap className="pageWrapper">
            <WideScreenContainer>
              <HeaderContainer>            
                {/* GPTW certification */}
                <BadgeContainer>
                    <a href="http://www.greatplacetowork.com/certified-company/7038366" title="Rating and Review" target="_blank" rel="noopener noreferrer">
                        <img  src="/images/gptw-2024.svg" alt="Review" width="100" />
                    </a>
                </BadgeContainer>
                <TitleContainer padding="1.5em 6.5em .5em">
                    <TitleXl textAlign="center">All job opportunities<span className="magenta">.</span></TitleXl>
                    <p class="title-copy">Not seeing an open role that's right for you? Join our talent community and we'll keep you informed of career opportunities and events matching your interests.</p>
                </TitleContainer>
                <ViewAllContainer>
                    <ViewAll target="_blank" link={'https://w2o.wd1.myworkdayjobs.com/en-US/RealChemistry/introduceYourself'} title={'STAY CONNECTED'} borderColor="#FFF" titleColor="#FFF" hoverBackground="#FFF" width="275px" height="38px" svgColor="#FFF" />
                </ViewAllContainer> 
                <SearchGroup>
                    <SearchBox>
                        <SearchBtn onClick={handleSearchClick} backgroundImage={'/images/search-icon-white.svg'} alt="white magnifying glass icon" aria-label="Search button for job listings on this site"  type='button' name="Search button"/>
                        <SearchInput
                            id='search'
                            type="search"
                            aria-label="Search for job listings on this site" 
                            aria-description="search results will appear below"
                            placeholder='Search by role or by keyword'
                            name="job-search" min="3" value={searchedJob}
                            onChange={(e) => {
                                handleSearch(e)
                            }}
                            onKeyDown={(e) => handleKeyDown(e)}
                        />
                    </SearchBox>
                </SearchGroup>
                <a href={"/careers"}>
                    <BackArrow className="cta arrow back">
                        <BackArrowSvg/>
                        <TitleXs textTransform={"uppercase"} margin={"0 0 0 15px"}>BACK TO CAREERS</TitleXs>
                    </BackArrow>
                </a>
                </HeaderContainer>


                <Row className="job-filter-wrap">
                    <Col5 className="job-search-section">
                        <CategoryFilterSection ref={filterRef}>
                            <TitleLg>FILTER BY</TitleLg>
                            {/* search input code start added for careers search prototype*/}

                            {/* <button
                  style={{marginRight: "5px",height: '30px', border:"none"}}
                  onClick={() => {
                    selectLocation("Remote");
                    localStorage.setItem('location', "Remote")
                  }}
                >remote</button>
                <button 
                  onClick={() => {
                    selectCapability("Clinical");
                    localStorage.setItem('capability', "Clinical")
                  }}  
                    style={{marginRight: "5px",height: '30px', border:"none"}}
                >clinical</button>
                <button 
                  style={{marginRight: "5px",height: '30px', border:"none"}}
                  onClick={() => selectLocation("Zurich")}
                >zurich</button>
                 */}
                            {/* search input code end*/}


                            <ResetButton
                                type='button'
                                className={(searchedJob.length > 2 || currentJobs.length < allJobs.length) ? "searched" : ""}
                                style={{textTransform: "uppercase"}}
                                onClick={() => {
                                    clearFilters()
                                }}
                            >
                                Clear filters
                            </ResetButton>
                            <CollapsibleCheckboxArea
                                type={"Career"}
                                handleDropdownSelect={updateSelectedCapability}
                                title={"Career Focus"}
                                options={allCapabilities}/>

                            <CollapsibleCheckboxArea
                                type={"Location"}
                                handleOnInputChange={updateSelectedCity}
                                handleDropdownSelect={updateSelectedCountry}
                                title={"Location"}
                                options={allLocations}/>
                        </CategoryFilterSection>


                    </Col5>

                    {/*TODO Clean this up later*/}
                    <Col7 ref={locationRef} className="job-right" style={{height: filterRef.current?.clientHeight}}>

                        <FilterCard
                            // className={(value === true && (location.length > 0 || capability.length > 0)) ? "showFilterCard" : ""}
                            scrollTop={scrollTop}>
                            <BodySm color="#fff" textTransform="uppercase">{locations}</BodySm>
                            <BodySm color="#fff" textTransform="uppercase">{capabilities}</BodySm>
                        </FilterCard>

                        <BadgesRow id='job-results'
                                   className={(searchedJob.length > 2 || currentJobs.length < allJobs.length) ? "searched" : ""}>
                            {searchedJob.length > 2 && <BodySm>Searched for: "{searchedJob}"</BodySm>}
                            <TitleSm style={{textTransform: "uppercase", fontWeight: "400"}}>
                                <span style={{
                                    fontWeight: "900",
                                    paddingRight: "5px"
                                }}>{currentJobs.length > 0 ? currentJobs.length : ''}</span>
                                {currentJobs.length !== 0 && searchedJob.length < 3 ? 'Jobs Available' : ''}
                                {searchedJob.length > 2 && currentJobs.length !== 0 ? ' Job Search Results' : ''}
                                {currentJobs.length === 0 ? 'No Results Found' : ''}
                            </TitleSm>
                        </BadgesRow>

                        <CategorySectionRight className="listings">
                            {currentJobs.map((item, index) => {
                                return <JobPostCard
                                    borderLeft="none"
                                    jobLink={item.detailLink}
                                    jobLocation={item.department + ' | ' + item.primaryLocation}
                                    // (locations.includes("All Locations") && item.primaryLocation === "Remote" ? item.primaryLocation : "") +
                                        //     //&& item.primaryLocation === "Remote" belongs below
                                        // (locations === "Remote"  ? locations : "") +
                                        // // (location === "Remote" && item.primaryLocation !== "Remote" ? location + " - " + item.primaryLocation : "") +
                                        // (locations !== "All Locations" && locations !== "Remote" ? locations  : "")
                                    jobTitle={item.title}
                                    key={'jobPostCard-' + index}
                                    postDate={item.date}
                                />
                            })}
                        </CategorySectionRight>
                    </Col7>
                </Row>
            </WideScreenContainer>

            <FooterAnimation
				svgDesktop="/images/purple-footer.svg"
				svgMobile="/images/purple-footer-mbl.svg"
				borderTop="none"
			/>
        </JobCategoryPageWrap>
    )
}

//Loader added to improve UX
export default LoadingHoc(JobCategoryPage)