import styled from 'styled-components';
import {
    sizeTabletLg
} from "components/devices";

export const PrivacyPolicyPage = styled.div`

`;
export const PvInfoContainer = styled.div`
    max-width: 915px;
    margin: 0 auto;
    padding: 0 25px;
`;

export const BlogContainer = styled.div`
    padding: 0 40px 0px 40px;
    ${sizeTabletLg(`
        padding: 0 25px 0px 25px;
        overflow: hidden;
    `)}
`;
