import styled, { css } from "styled-components";

import { BodySm, TitleXs, TitleMd, TitleSm, TitleLg, BodyRg } from "../elements";

import {
  sizeXlDevice,
  sizeLaptopLg,
  sizeLaptop,
  sizeTabletLg,
  sizeMobileLg,
  sizeDesktopSm,
  sizeWideScreen
} from "../devices";

// This could be react-router-dom's Link for example
import { Link } from 'react-router-dom';

export const ExpCardsWrapper = styled.div`
  /* Wide Screen CSS */
  ${sizeWideScreen(`
    border-left: 1px solid #707070;
    border-right: 1px solid #707070;
  `)}
`;

export const CardsContainer = styled.div`
  padding: 60px 30px;
  border-top: 1px solid #707070;
  border-bottom: 1px solid #707070;
  ${sizeTabletLg(css`
    padding: 40px 20px;
  `)}
`;
export const ExpCardsRow = styled.div`
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
          justify-content: space-between;
  box-sizing: border-box;
  ${sizeTabletLg(css`
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  `)}
`;

export const HeadRow = styled.div`
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding-bottom: 35px;
  ${sizeLaptopLg(css`
    padding-bottom: 50px;
  `)}
    ${sizeTabletLg(css`
      padding-bottom: 35px;
    `)}
`;
export const CardLink = styled(Link)`
  display: none;
  font-size: 18px;
  font-weight: 700;
  color: #fff;
  text-transform: uppercase;

  /* Wide Screen CSS */
  ${sizeWideScreen(`
      font-size: 0.7vw;
  `)}
`;
export const CardIconCollapse = styled.button`
  background: transparent;
  border: none;
  cursor: pointer;
  display: none;
  width: 45px;
  &:focus {
    outline: none;
    box-shadow: none;
  }
  ${sizeTabletLg(css`
    position: absolute;
    bottom: 30px;
    left: 20px;
    width: 50px;
    padding: 0;
  `)}
`;
export const Image = styled.img`
  margin-bottom: 30px;
  opacity: 0;
  width: 100%;
`;
export const ContainerCard = styled.div`
    padding: 35px;
    height: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    box-sizing: border-box;
    cursor: pointer;
    ${sizeLaptopLg(css`
        padding: 20px 20px;
    `)}
    ${sizeLaptopLg(css`
        margin: 0 25px;
        padding: 25px 0;
  `)}
    ${sizeTabletLg(css`
        height: 400px;
        width: 100%;
        padding: 30px 20px;
        margin: 0;
    `)}

    ${TitleMd} {
      font-family: "Neue Pixel Grotesk";
      ${sizeLaptopLg(css`
          font-size: 42px;
      `)}


      /* Wide Screen CSS */
      ${sizeWideScreen(`
          font-size: 1.3vw;
      `)}
    }
`;
export const CardTitle = styled.h5`
  font-size: 26px;
  color: #fff;
  text-transform: uppercase;
  transition: .5s all ease;

  /* Wide Screen CSS */
  ${sizeWideScreen(`
      font-size: 0.75vw;
  `)}
`;
export const CardIcon = styled.button`
  background: transparent;
  border: none;
  cursor: pointer;
  display: inline-block;
  width: 45px;
  text-align: left;
  padding-left: 0;
  ${sizeLaptop(css`
    width: 50px;
    padding: 0;
  `)}
  &:focus {
    outline: none;
    box-shadow: none;
  }
`;
export const Icon = styled.img`
  width: 40px;
`;

// Card Expanded CSS Start
export const ExpandedContent = styled.div`
    padding: 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    ${sizeTabletLg(css`
        flex-wrap: wrap;
        padding: 0;
    `)}
    ${TitleLg} {
        opacity: 0;
        transition: 0.5s all ease;
        transition-timing-function: ease-in-out;
    ${sizeMobileLg(css`
        font-size: 30px;
    `)}
    }
    ${TitleSm} {
        opacity: 0;
        margin-bottom: 20px;
        line-height: 1.1;
        max-width: 870px;

        /* Wide Screen CSS */
        ${sizeWideScreen(`
            font-size: 0.75vw;
        `)}
    }
    ${BodySm} {
      opacity: 0;
        line-height: 1.4;
        margin-bottom: 40px;
        ${sizeXlDevice(`
          max-width: 85%;
        `)}
        max-width: 730px;


        /* Wide Screen CSS */
        ${sizeWideScreen(`
            font-size: 0.75vw;
        `)}
    }
    ${TitleXs} {
      ${sizeXlDevice(`
          max-width: 85%;
      `)}
    }

  ${BodyRg} {
    /* Wide Screen CSS */
    ${sizeWideScreen(`
        font-size: 0.9vw;
    `)}
  }
`;
export const CardNumber = styled.div`
   padding: 35px;
  height: 100%;
  box-sizing: border-box;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
          justify-content: space-between;
  position: relative;
  &:after {
    content: "";
    width: 1px;
    right: 0;
    top: 0;
    height: 0;
    position: absolute;
    background-color: #fff;
}
  ${sizeLaptopLg(css`
    padding: 25px;
  `)}
  ${sizeTabletLg(css`
  height: auto;
    padding: 0;
    border: none;
    padding: 0;
    margin-bottom: 25px;
    width: 100% !important;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 100% !iportant;
            flex: 0 0 100% !iportant;
    &:after {
      content: none;
    }
  `)}
  ${TitleMd} {
        font-family: "Neue Pixel Grotesk";
        text-align: center;
        ${sizeLaptopLg(css`
          font-size: 42px;
      `)}
      ${sizeTabletLg(css`
          text-align: left;
      `)}
  }
  ${CardIconCollapse} {
      ${sizeTabletLg(css`
        display: none !important;
    `)}
  }
`;
export const ContentRight = styled.div`
  padding: 35px 60px 100px 130px;
  box-sizing: border-box;
  width: 100%;
  ${sizeLaptopLg(css`
    padding-left: 40px;
    padding-top: 20px;
    width: 100%;
    padding-bottom: 100px;
  `)}
  ${sizeTabletLg(css`
    padding: 0;
  `)}
  ${TitleLg} {
    ${sizeLaptopLg(`
      font-size: 42px;
    `)}
    ${sizeTabletLg(css`
        font-size: 32px;
    `)}
  }
`;

export const ExpandingCardsWrapper = styled.div`
  background-color: ${({ backgroundColor }) => backgroundColor || "#0F20FF"};
  width: 100%;
  height: 420px;
  // transition: 0.5s all ease;
  // transition-timing-function: ease-in-out;
  margin: 0 15px;
  box-sizing: border-box;
  &:hover {
    background-color: ${({ hoverBg }) => hoverBg || "#0B16A8"};
  }
  ${sizeLaptopLg(css`
    margin: 0 10px;
  `)}
    ${sizeTabletLg(css`
    margin: 0 0 25px 0;
    width: 100%;
  `)}
  ${sizeXlDevice(css`
    height: 520px !important;
  `)}
    &:last-child {
    margin-right: 0;
  }
  &:first-child {
    margin-left: 0;
  }
  ${TitleMd} {
    color: #fff;
    font-family: "Neue Pixel Grotesk";
    transition: 0.5s all ease;
    transition-timing-function: ease-in-out;
  }
  ${({ state }) => {
    if (state === "open")
      return css`
        // width: 100%;
        // height: auto;
        padding: 0;
        ${sizeLaptopLg(css`
          padding: 0;
        `)}
        ${sizeTabletLg(css`
          width: 100% !important;
          flex: 0 0 100% !iportant;
          padding: 30px 20px 180px 20px;
          position: relative;
          height: auto;
        `)}
        ${sizeXlDevice(css`
          height: auto !important;
        `)}
        ${TitleLg} {
          opacity: 1;
        }
      
      `;
    else if (state === "shrink")
      return css`
        min-width: auto;
        -webkit-box-align: center;
        -ms-flex-align: center;
        -ms-grid-row-align: center;
        align-items: center;
        ${sizeDesktopSm(css`
          -webkit-box-flex: 0;
          -ms-flex: 0 0 130px;
          flex: 0 0 130px;
        `)}
        ${sizeLaptop(css`
          -webkit-box-flex: 0;
          -ms-flex: 0 0 100px;
          flex: 0 0 100px;
        `)}


        ${sizeTabletLg(css`
        width: 100% !important;
          -webkit-box-flex: 0 !important;
          -ms-flex: 0 0 100% !important;
                  flex: 0 0 100% !important;
        `)}
        ${CardTitle} {
          opacity: 0 !important;
          ${sizeTabletLg(css`
            opacity: 1 !important;
          `)}
        }
        ${ContainerCard} {
          -webkit-box-align: center;
          -ms-flex-align: center;
          -ms-grid-row-align: center;
          align-items: center;
          ${sizeTabletLg(css`
          -webkit-box-align: start;
          -ms-flex-align: start;
          -ms-grid-row-align: flex-start;
          align-items: flex-start;
          `)}
        }
      `;
  }};
`;
