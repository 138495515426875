import styled from "styled-components";

import {
    sizeMobileLg,
} from "components/devices";
import { WideScreenContainer } from "components/elements";


export const ContactUsWrapper = styled.div`
    width: 100%;

${WideScreenContainer}{
    display: flex;
    flex-direction: row;
    ${sizeMobileLg(`
        flex-direction: column;
    `)}
}

`

export const ContactUsFormWrapper= styled.div`
    flex: 0 0 55%;
`



