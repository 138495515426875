import styled from "styled-components";
import {

    TitleXl,
 
} from "components/elements";
import {

    sizeMobileLg,

} from "components/devices";



export const OtherNewsLettersWrapper = styled.div`
width: 100%;
padding: 2% 2% 10%;
position: relative;
${sizeMobileLg(`
  padding: 2% 15px 32px 15px;
`)}
`

export const TitleXlContainer = styled.div`
    max-width: 100%;
    ${TitleXl} {
        font-size: 48px;
        font-weight: 900;
        line-height: 120%;
        ${sizeMobileLg(`
            font-size: 36px;
       `)}
    }
  
`


export const NewsLetterContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    padding: 3% 0 0;
    ${sizeMobileLg(`
        flex-direction: column;
        gap: 40px;
        padding: 15% 0 20% ;
    `)}
`
