import { Link } from "react-router-dom";
import styled, { css } from 'styled-components';
import {
    TitleXl,
    TitleSm, BodySm
} from "components/elements";

import {
    sizeLaptop,
    sizeTabletLg,
    sizeMobileMd,
    sizeWideScreen
} from "components/devices";

// Style
export const Arrow = styled.img`
    display: block;
    ${sizeTabletLg(`
        display: none;
    `)}
`;
export const ArrowBlue = styled.img`
    width: 0;
    transition: 0.3s all ease;
    transition-timing-function: ease-in-out;
    display: block;
    ${sizeTabletLg(`
        display: none;
    `)}
`;
export const ArrowMobile = styled(Arrow)`
    display: none;
    width: calc(100% - 40px);
    ${sizeTabletLg(`
        display: block;
    `)}
`;
export const ArrowBlueMobile = styled(ArrowBlue)`
    display: none;
    ${sizeTabletLg(`
        display: block;
    `)}
`;
export const ArrowLongWrap = styled.div`
    position: absolute;
    left: 25px;
    top: 55%;
    transform: translateY(-50%);
    width: 115px;
    height: 60px;
    transition: 0.3s all ease;
    transition-timing-function: ease-in-out;
    ${sizeTabletLg(`
        width: calc(100% - 50px);
        left: 20px;
    `)}
`;
export const ArrowAngle = styled.div`
    border-top: 8px solid #000;
    border-left: 8px solid #000;
    display: block;
    margin: 0;
    width: 30px;
    height: 30px;
    transform: rotate(135deg);
    position: absolute;
    right: 0;
    top: 9px;
    ${sizeTabletLg(`
        border-width: 6px;
        top: 12px;
    `)}
`;
export const ArrowLine = styled.div`
    display: block;
    width: 100%;
    height: 8px;
    background-color: #000;
    transform: rotate(180deg);
    left: 3px;
    top: 20px;
    position: absolute;
    ${sizeTabletLg(`
        height: 6px;
        top: 24px;
    `)}
`;

export const NextArrows = styled.div`
    border: 8px solid #000;
    height: 112px;
    position: relative;
    border-radius: 130px;
    padding: 0 30px;
    overflow: hidden;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-transition: 0.3s all ease;
    transition: 0.3s all ease;
    border-color: ${({ borderColor }) => borderColor || "#000"};

    ${Arrow},
    ${ArrowBlue},
    ${ArrowMobile},
    ${ArrowBlueMobile} {
        position: absolute;
        left: 30px;
        bottom: 50%;
        transform: translateY(+50%);
        ${sizeLaptop(`
            left: 20px;
        `)}
    }

    ${sizeLaptop(`
        border-width: 5px;
        padding: 0 20px;
    `)}
    ${sizeTabletLg(`
        max-width: 330px;
        margin: 0 auto;
        box-sizing: border-box;
        height: 110px;
    `)}
`;


export const NextRow = styled.div`
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    margin-bottom: 25px;
    -webkit-transition: 0.5s all ease;
    transition: 0.5s all ease;
    -webkit-transition-timing-function: ease-in-out;
    transition-timing-function: ease-in-out;
    ${sizeTabletLg(`
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        text-align: center;
        margin-bottom: 0;
    `)}
    ${TitleXl} {
        margin-bottom: 20px;
        ${sizeTabletLg(`
            display: block;
            width: 100%;
        `)}
        ${sizeTabletLg(`
            
        `)}
        &:first-child {
            display: none;
            ${sizeTabletLg(`
                font-size: 16px;
                display: block;
            `)}
        }
    }
`;


export const NextWrap = styled.div`
   padding: 66px 104px;
    box-sizing: border-box;
    -webkit-transition: 0.2s all ease;
    transition: 0.2s all ease;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: end;
    -ms-flex-align: end;
    align-items: flex-end;
    border-top: 1px solid #707070;
    margin-top: -1px;
    ${sizeLaptop(`
        padding: 90px 25px 50px 25px;
    `)}
    ${BodySm} {
        margin-bottom: 30px;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        ${sizeTabletLg(`
            display: none;
        `)}
    }

    ${sizeTabletLg(`
        min-height: 300px;
    `)}
    ${sizeMobileMd(`
        padding: 50px 20px 50px 20px;
    `)}

    &.back {
        text-align: center;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        ${NextArrows} {
            transform: rotate(180deg);
        }
        ${TitleSm},
        ${TitleXl},
        ${BodySm} {
            text-align: center;
            -webkit-box-pack: center;
            -ms-flex-pack: center;
            justify-content: center;
            width: 100%;
        }
    }


    /* Wide Screen CSS */
    ${sizeWideScreen(css`
      border-right: ${({ borderRight }) => borderRight || "1px solid #707070"};
      border-left: ${({ borderLeft }) => borderLeft || "1px solid #707070"};
    `)}
`;
export const NextLink = styled(Link)`
    transition: 0.3s all ease;
    transition-timing-function: ease-in-out;
    width: 100%;
    &:hover {
        ${ArrowLongWrap} {
            width: calc(100% - 55px);
            opacity: 1;
            transition: 0.3s all ease;
            transition-timing-function: ease-in-out;
            ${sizeLaptop(`
                width: calc(100% - 55px);
            `)}
            ${sizeTabletLg(`
                width: calc(100% - 50px);
            `)}
        }
        ${ArrowAngle},
        ${NextArrows} {
            border-color: #D12FFF;
        }
        ${ArrowLine} {
            background-color: #D12FFF;
        }
        ${TitleXl} {
            color: #D12FFF;
            ${sizeTabletLg(`
                &:first-child {
                    color: #000;
                }
            `)}
        }
    }
`;





