
import React from 'react'

import Slider from 'components/Slider'

import Panel from './Panel'

import {
  MegaSliderWrapper,
  MegaSliderHeightContainer,
  MegaSliderHead,
  MegaSliderContainer,
  ScrollWrapper,
  LabelsRow,
} from './elements'

import {
  TitleXl,
  TitleContainer,
  BodySm,
  TitleSm,
  BodyRg,
} from 'components/elements'


function MegaSlider(props) {
  let itemlist = []
  const data = props.sliderData ? props.sliderData : { items: itemlist }

  if (data && data.items)
    itemlist = data.items

  return (
    <MegaSliderWrapper>
      <TitleContainer borderBottom={'1px solid #707070'}>
        <TitleXl>{data.title}</TitleXl>
      </TitleContainer>

      <MegaSliderContainer>
        {
          data.subTitle || data.description ?
            <MegaSliderHead>
              {
                data.subTitle && <BodySm textTransform="uppercase">{data.subTitle}</BodySm>
              }
              {
                data.description && <TitleSm>{data.description}</TitleSm>
              }
            </MegaSliderHead>
            : ''
        }


        <MegaSliderHeightContainer>
          <ScrollWrapper>
            <LabelsRow>
              {itemlist.map((item, index) => {
                return <BodyRg key={'pLabl-' + index} textTransform="uppercase">{item.title}</BodyRg>
              })}
            </LabelsRow>
          </ScrollWrapper>

          <Slider customCursor={true}>
            {itemlist.map((item, index) => {
              return <Panel sliderType={props.sliderType} fromHome={props.fromHome} imagePadding={props.imagePadding} key={'pElem-' + index} data={item} number={index + 1} />
            })}
          </Slider>
        </MegaSliderHeightContainer>
      </MegaSliderContainer>
    </MegaSliderWrapper>
  )
}

export default MegaSlider
