import React from 'react';
import {
    RectContainer,
    SmallRectItem,
    ArrowWrap,
    ItemContainer,
} from './elements';
import { ReactComponent as Arrow } from './svg/arrow.svg';

export const SmallRect = ({ item, size, isShown, setIsShown, setItemIndex }) => {
    const handleClick = () => {
        setIsShown(true);
        setItemIndex(item.no);
    }
    
    return (
        <RectContainer>
            <ItemContainer onClick={handleClick}>
                <SmallRectItem
                    width={size.smallWidth}
                    height={size.smallHeight}
                    marginLeft={item.marginLeft}
                    color={item.color}
                    dangerouslySetInnerHTML={{ __html: '<p>' + item.title + '</p>' }}
                />
                <ArrowWrap>
                    <Arrow />
                </ArrowWrap>
            </ItemContainer>
        </RectContainer>
    );
}

export default SmallRect;