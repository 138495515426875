import React from 'react';
import {TitleMd, BodySm} from "components/elements";
import {InfoSectionWrap, WorkInfoRow, WorkInfoCol} from "./elements";
import RichText from "components/RichText";

function WorkInfoSection(props) {
    return (
        <InfoSectionWrap
            borderTop={props.borderTop}
            borderRight={props.borderRight}
            borderBottom={props.borderBottom}
            borderLeft={props.borderLeft}
        >
            {
                props.label &&
                <WorkInfoRow>
                    <WorkInfoCol>
                        <BodySm margin={"0 0 12px 0 !important"}>{props.label}</BodySm>
                    </WorkInfoCol>
                </WorkInfoRow>
            }
            <WorkInfoRow>
                <WorkInfoCol>
                    <TitleMd textTransform={"uppercase"}>{props.title}</TitleMd>
                </WorkInfoCol>
                {
                    props.detail && <WorkInfoCol>
                        <RichText body={props.detail}/>
                    </WorkInfoCol>
                }
            </WorkInfoRow>
        </InfoSectionWrap>
    );
}

export default WorkInfoSection;
