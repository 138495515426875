import styled from "styled-components";
import {

    TitleXl,
    BodySm,
    Col4
 
} from "components/elements";
import {
    sizeTabletLg,
    sizeMobileLg,

} from "components/devices";
import { NewsLetterWrapper } from "components/Newsletters/elements";
import {  Row } from "components/elements";



export const OtherNewsLettersWrapper = styled.div`
    width: 100%;
    padding: 5% 2% 6%;
    position: relative;
    ${sizeMobileLg(`
        padding: 15% 15px 10%;
    `)}

${NewsLetterWrapper}{
    width: 100%;
}
${Row}{
    padding: 5% 0 0;
    ${sizeMobileLg(`
        flex-direction: column;
    `)}
}
${Col4}{
    padding: 0 0 3%;
    flex: 0 0 32%;
    ${sizeMobileLg(`
     flex: 0 0 100%;
     max-width: 100%;
     padding: 0 0 15%;
`)}
}
`

export const TitleXlContainer = styled.div`
    max-width: 80%;
    ${sizeMobileLg(`
         max-width: 100%;
    `)}
    ${TitleXl} {
        font-size: 48px;
        font-weight: 900;
        line-height: 57px;
        ${sizeMobileLg(`
            font-size: 36px;
            line-height: 43px; 
       `)}
    }
  
`

export const BodySmContainer = styled.div`
    padding-top: 2%;
    max-width: 50%;
    width: 100%;
    ${sizeTabletLg(`
        max-width: 75%;
    `)}
${sizeMobileLg(`
   padding: 3% 0 15%;
   max-width: 100%;
`)} 
${BodySm}{
   font-size: 20px;
   line-height: 24px;
   ${sizeMobileLg(`
       font-size:16px;
       line-height: 19px;
`)} 
`


