import styled from "styled-components";
import {
    BodySm,
    TitleXl,
  
} from "components/elements";
import { sizeMobileLg,  sizeWideScreen, sizeLaptop, sizeDesktopLg, sizeDesktopSm } from "components/devices";


export const NewsLetterWrapper = styled.div`
    background-color: #F0F0F0;
    display: flex; 
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    padding: 4% 4% 5%;
    width:  ${({width}) => width || '32%' };
    height:  ${({height}) => height || '520px' }; 
    ${sizeWideScreen(`
        height: 750px;       
    `)}
    ${sizeDesktopLg (`
        height: 600px;   
    `)}

    ${sizeDesktopSm (`
        height: 520px;
    `)} 
    ${sizeLaptop(`
        height: 450px;
    `)} 

   
    ${sizeMobileLg(`
        height: 100%;
        width: 100%;
        padding: 10% 8%;
    `)}
`

export const TopFlexWrapper = styled.div`

`

export const ImageWrapper = styled.div`
    .newsLetterImage {
      max-width: 100%;
      width 100%;

    }
`

export const TitleWrapper = styled.div`
    padding: 4% 0 0;
    ${TitleXl}{
        font-size: 34px;
        line-height: 120%;
        font-weight: 900;
    ${sizeLaptop(`
        font-size: 28px;
    `)}
    ${sizeMobileLg(`
        font-size: 20px;
`)}
}
`

export const IntroWrapper = styled.div`
  padding: 6% 0 0;
  ${sizeMobileLg(`
    padding: 8% 0;
 `)}
  ${BodySm}{
    ${sizeLaptop(`
        font-size: 20px;
    `)}
    ${sizeMobileLg(`
        font-size: 16px;
        line-height: 19px;
    `)}
  }
`

export const CtaWrapper = styled.div`

`