import React, {useRef} from "react";

import {
    SubscribeWrap,
    SubscribeRow,
    SubscribeCol,
    RightInner,
    // SubscribeImgMobile
} from "./elements";

import SubscribeCard from "components/SubscribeCard";
import useBrowserVisibility from '../../../hooks/useBrowserVisibility';

function SubscribeSection({from}) {
    const subscribeRef = useRef(null);
    useBrowserVisibility(subscribeRef);
    return (
        <SubscribeWrap className="nextSection">
            <SubscribeRow>
                <SubscribeCol className="colLRight">
                    <RightInner>
                        <SubscribeCard from={from}/>
                    </RightInner>
                </SubscribeCol>
            </SubscribeRow>
        </SubscribeWrap>
    );
}

export default SubscribeSection;
