import React from 'react';
import { SubMenu } from "../elements";

// Components
import InfoCard from "./InfoCard";
import ContentCard from "./ContentCard";
import { Col4, Col6, Col8, Row } from "components/elements";

function CareersMenu(props) {
  const clearFilters = () => {
    if (localStorage.getItem("location") !== null)
      localStorage.removeItem('location')

    if (localStorage.getItem("capability") !== null)
      localStorage.removeItem('capability')
  }

  return (<SubMenu showSubMenu={props.open}>
    <Col4>
      <InfoCard rows={3} cols={2} data={props.data} />
    </Col4>
    <Col8>
      <Row>
        {
          props.data?.subNavigation?.cards?.map((card, index) => {
            return (
              <Col6 onClick={clearFilters} key={index}>
                <ContentCard rows={3} cols={2} data={card} borderBottom="none" />
              </Col6>
            )
          })
        }
      </Row>
    </Col8>
  </SubMenu>)
}

export default CareersMenu;
