import styled from 'styled-components';
import {
  TitleXl,
  TitleSm,
  BodySm,
  TitleMd
} from "components/elements";
import {
  sizeMobileLg,
  sizeTabletLg,
  device,
  sizeLaptop,
} from "components/devices";
import { css } from 'styled-components';

export const Container = styled.div`
    width: 100vw;
    height: 100%;
    max-height: ${({ maxHeight }) => maxHeight || 'auto'};
    overflow: hidden;
`

// Inner Hero Style Start
export const HeaderHeroWrap = styled.div`
    position: relative;
    width: 100%;
    height: 100%;
    max-height: ${({ maxHeight }) => maxHeight || 'auto'};
    background-image: url(${props => props.backgroundImage});
    background-position: center center no-repeat;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    background-size: cover;
    transform: scale(1.3, 1.5);
    padding: ${({ padding }) => padding || '69px 33px 500px;'};
    ${props => props.backgroundImgMbl && css`
        @media only screen and (max-width: ${device.mobileLg}) {
            background-image: url(${props => props.backgroundImgMbl});
        }
    `}
    ${sizeMobileLg(`
        padding: 130px 15px;     
    `)}

`;

export const BadgeContainer = styled.div`
    position: absolute;
    top: -3px;
    right: 33px;
    overflow: hidden;
    img {
        transform: translateY(120%);
    }
    &.logo {
        top: 15px;
    }
    ${sizeTabletLg(`
        top: 0;
        &.logo {
            top: 20px;
        }
    `)}
    ${sizeMobileLg(`
        top: 7px;
        &.logo {
            top: 20px;
            right: 15px;
        }
    `)}
    img {
        ${sizeMobileLg(`
            width: 80px;
            &.tgLogo {
                width: 150px;
            }
        `)}
    }
`

export const CtaContainer = styled.div`
    align-self: flex-start;
    opacity: 0;
    padding: 5% 0 0;
`

export const AnchorContainer = styled.div`
    align-self: flex-start;
    opacity: 0;
    padding: 10% 0 0;
    .anchorLink {
        display: flex;
        align-items: center;
        &:hover {
            cursor: pointer;
        }
        
        ${TitleMd}{
            font-size: 20px;
            line-height: 1.2;
            color:#fff;
            text-transform: uppercase;
            padding-right: 15px;
            cursor: pointer;
        }
        .arrow {
            cursor: pointer;
        }
    }

`

export const TitleCtaContainer = styled.div`
    display: flex;
    float: right;
    flex-direction: column;
    align-items: flex-end;
    overflow: hidden;
    max-width: ${({ maxWidth }) => maxWidth || '40%'};
    margin-right: ${({ marginRight }) => marginRight || "0"};
    font-size: 2.25em;
    ${sizeLaptop(`
       max-width: 55%;
    `)}
    ${sizeTabletLg(`
      max-width: 85%;
    `)}
    ${sizeMobileLg(`
        max-width: 100%;
        float: none;
        margin-right: 0;
    `)}
`


export const TitleMdSmContainer = styled.div` 
     padding: ${({ titleMdPadding }) => titleMdPadding};
    ${sizeTabletLg(`      
         padding: 15% 0 0; 
    `)}
    ${sizeMobileLg(`
        padding: 5% 0 0; 
    `)}
   ${BodySm} {
        font-size: .55em;
        color: #fff;
        opacity: 0;
        font-weight: 400;
        padding: 7% 0 0 ;
        ${sizeMobileLg(`
            max-width: 100%;
            font-size: 16px !important;
            line-height: 19px !important;
        `)}
    }

    ${TitleMd} {
        font-weight: 400;
        color: #fff;
        opacity: 0;
        line-height: 43px !important;       
        ${sizeMobileLg(`
        font-size: 20px !important;
        line-height: 24px !important;
    `)}
    }


`

export const BodySmContainer = styled.div`
        overflow: hidden;
        font-size: .5em;
    ${TitleSm} {
        color: #fff;
        text-transform: uppercase;
        transform: translateY(130%);
        ${sizeMobileLg(`
        font-size: 15px !important;
        line-height: 20px !important;
       `)}
    }
`

export const TitleXlContainer = styled.div`
    margin: 2% 0 0;
    font-size: 1.125em;
    width: ${({ titleXlWidth }) => titleXlWidth};
    ${sizeMobileLg(`
        margin: 5% 0 0;
        width: 100%;
    `)}
    ${TitleXl} {
        font-weight: 900;
        color: #fff;
        transform: translateY(40px);
        padding: 0 0 5px;
        display: inline-block;
        opacity: 0;
        .magenta {
            color: #D12FFF;
        }
        ${sizeMobileLg(`
            font-size: 40px !important;
            padding: 0 0 0;
       `)}
    }
`
export const RichTextContainerHead1 = styled.div`
    overflow: hidden;
    max-width: ${({ headerMaxWidth }) => headerMaxWidth || '100%'};
    padding: 3px 0 3px;
    ${sizeLaptop(`
        max-width: 76%;
    `)}
    ${sizeMobileLg(`
        max-width: 100%;
    `)}
    .period {
        opacity: 0;
        font-size: 76px;
        font-weight: 900;
        color: #D12FFF;
    }
`


