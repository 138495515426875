
import styled from 'styled-components'

export const PreviewContainer = styled.div`
	color: #000;
	font-size: 1em;
	margin: 0 4vw;
	border: 2px dotted #ddd;
`;

