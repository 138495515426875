import styled from 'styled-components';

import {
    Row,
    Col4, Col, TitleXl, BodySm, SectionTitle
} from "components/elements";
import {
    sizeWideScreen,
    sizeTabletLg, sizeLaptop, sizeMobileLg, sizeMobileXs, sizeTablet,
} from "components/devices";
import {Link} from "react-router-dom";
import { ViewAllWrap } from 'components/ViewAll';
import { BlogWistiaVideo } from 'pages/blog-details/BlogDetail/elements';

export const CareersPageWrap = styled.div`

  .corporate-title,
  .view-all-border {
    ${sizeWideScreen(`
        border-left: 1px solid #707070;
        border-right: 1px solid #707070;
    `)}
  }

  .gptw-cert {
    position: absolute;
    right: 100px;
    margin-top: 95px;
    ${sizeTabletLg(`
      right: 40px;
    `)}
    ${sizeMobileLg(`
      position: relative;
      text-align: center;
      right: auto;
      top: auto;
    `)}
  }

  .openPositionSec {
    ${sizeTabletLg(`
      padding: 30px 15px;
    `)}
  }
  ${SectionTitle}{ 
    .bodySm {
        font-size: 16px !important;
        line-height: 19px !important;
      ${sizeTabletLg(`
        padding: 30px 0 0;
        font-size: 15px !important;
    `)}
    }
    
   .jobOppCopy{
      font-size: 70px;
      line-height: 1;
      font-weight: 900;
      padding-bottom: 20px;
      ${sizeTabletLg(`
        font-size: 36px;
        line-height: 43px;
      `)}
    }
    ${ViewAllWrap}{
      ${sizeTabletLg(`
          width: 300px;
    `)}
    ${sizeMobileXs(`
      width: 250px;
      height: 35px;
  `)}
    }
  }

`;

export const PlaceHolderImgLeft = styled.img`
  @media (max-width: 1150px) {
    display:none
  }
`;

export const PlaceHolderImgRight = styled.img`
  @media (max-width: 2125px) {
    display:none
  }
`;

export const WorkCardsSection = styled.div`
  ${BodySm}{
      font-size: 16px !important;
      line-height: 19px !important;
  }
  ${TitleXl}{
    font-weight: 900;
    font-size: 48px;
    line-height: 57px;
    text-transform: none;
    ${sizeTabletLg(`
      font-size: 36px;
      line-height: 43px;
    `)}
  }
  ${sizeTabletLg(`
        ${Row} {
            -ms-flex-wrap: wrap;flex-wrap: wrap;
        }
        ${Col4} {
            max-width: 100%;
            -webkit-box-flex: 0;
            -ms-flex: 0 0 100%;
             flex: 0 0 100%;
        }
    `)}
`;


export const ImageSliderWrap = styled.div`
  padding: 45px 0;
  display: none;
  overflow: hidden;
  border-bottom: 1px solid #707070;
  ${sizeTabletLg(`
        display: block;
    `)}
`;

// Text Blocks CSS Start
export const TexBlocks = styled.div`
  border: ${({border}) => border || "none"};
  border-top: ${({borderTop}) => borderTop || "none"};
  border-right: ${({borderRight}) => borderRight || "none"};
  border-bottom: ${({borderBottom}) => borderBottom || "none"};
  border-left: ${({borderLeft}) => borderLeft || "none"};

  ${Row} {
    margin: 0 -30px;
  }

  ${Col} {
    padding: 0 30px;
    margin-bottom: 65px;
    width: 100%;
    flex: 0 0 50%;
    max-width: 50%;
    ${sizeTabletLg(`
        flex: 0 0 100%;
        max-width: 100%;
        margin-bottom: 45px;
    `)}
  }
`;
export const BlocksContainer = styled.div`
  padding: ${({padding}) => padding || "3.5em 6.5em"};
  padding-top: ${({paddingTop}) => paddingTop || "3.5em"};
  padding-right: ${({paddingRight}) => paddingRight || "6.5em"};
  padding-bottom: ${({paddingBottom}) => paddingBottom || "3.5em"};
  padding-left: ${({paddingLeft}) => paddingLeft || "6.5em"};
  ${sizeLaptop(`
    padding: 2.5em 1.563em;
`)}
`;
// Text Blocks CSS End


// Corporate Section CSS Start
export const CorpSection = styled.div`
  ${BodySm}{
      font-size: 16px;
      line-height: 19px;
      padding: 20px 0 0;
      
  }

  ${TitleXl}{
    font-weight: 900;
    font-size: 48px;
    line-height: 57px;
    text-transform: none;
    ${sizeTabletLg(`
      font-size: 36px;
      line-height: 43px;
    `)}
  }
  p {
    font-size: 16px;
    line-height: 19px;
    max-width: 80%;
    padding: 64px 0;
    ${sizeMobileLg(`
      max-width: 100%;
  `)}
    a {
      color: #D12FFF;
      text-decoration: none;
    }
  }
  


`;
export const Cta = styled(Link)`
  font-size: 18px;
  color: rgb(0, 0, 0);
  text-transform: uppercase;
  display: block;
  margin-top: 30px;
  ${sizeTabletLg(`
    font-size: 16px;
`)}
  &:hover{
    color: #D12FFF;
  }
`;

// Corporate Section CSS End

export const VideoCopyContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 9% 30px 7%;
  ${sizeTablet(`
    flex-direction: column-reverse;
    align-items: flex-start;
    padding: 9% 15px 7%;
  `)}

  ${TitleXl}{
     font-size: 48px;
     line-height: 1.2;
     max-width: 35%;
     ${sizeLaptop(`
      padding-left: 40px;
      font-size: 36px;
     `)}
     ${sizeTablet(`
      padding-left: 0;
      padding-bottom: 40px;
      max-width: 95%;
    `)}
    ${sizeMobileLg(`
      max-width: 100%;
    `)}
  }

  ${BlogWistiaVideo}{
      
      width: 55%;
      padding: 0 4% 0 0;
    
     ${sizeTablet(`
        padding: 0;
        width: 100%;
   `)}

  }

`

export const FlexContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 6% 30px 7%;
  ${sizeTablet(`
    flex-direction: column;
    align-items: flex-start;
    padding: 9% 15px 7%;
  `)}
  ${TitleXl}{
    flex: 0 0 40%;
    font-weight: 900;
    font-size: 48px;
    line-height: 57px;
    text-transform: none;
    ${sizeTabletLg(`
      font-size: 36px;
      line-height: 43px;
    `)}
    ${sizeTablet(`
      padding: 0 0 40px;
  `)}
  }
  

  div {
    flex: 0 0 60%;

    img {
      width: 100%;
      max-width: 854px;
      object-fit: cover;
    }
  }

`
