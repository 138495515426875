import React, {useCallback, useEffect, useState} from "react";
import {
  FooterWrap,
  FooterRow,
  GetTouch,
  InputWrap,
  InputMail,
  SubmitBtn,
  ArrowSubmit,
  FooterCol1,
  FooterCol2,
  FooterCol3,
  FooterCopy,
  FooterLogo,
  FooterMenu,
  PageName,
  PageLink,
  FooterInfo,
  InfolList,
  InfoItem,
  InfoLink,
  SocialItem,
  SocialIcons,
  SocialIcon,
  // FooterInfoMobile,
  // FooterCopyMobile,
  FooterLogoLink,
  FooterDol,
  DolTopText,
  InfoA,
} from "./elements";
import { TitleXs, BodySm, TitleMd, Label, WideScreenContainer,  Col8, Col4 } from "components/elements";
import { useAppContext } from "store/index"
import {useHistory, useLocation} from "react-router-dom";
import { withRouter } from "react-router-dom"

import useFormManager from 'hooks/useFormManager'
import {
  DynamicContent,
  r3cContent, RecaptchaSiteKey,
} from 'constants/index';
import {useExternalScript} from "../../hooks/useExternalScript";
import {recaptchaHelper} from "../../generalHelpers";

const OPTIONAL = [];

function Footer(props) {
  const recaptchaScript = `https://www.google.com/recaptcha/api.js?render=${RecaptchaSiteKey}`;
  // eslint-disable-next-line
  const state = useExternalScript( recaptchaScript )
  const [footerData, setFooterData] = useState({});
  let history = useHistory();
  const {pathname} = useLocation();

  const thmPageLinks = [
    {
      destination: '/transformative-healthcare-markets#whoweare',
      label: 'WHO WE ARE'
    },
    {
      destination: '/transformative-healthcare-markets#howwehelp',
      label: 'HOW WE HELP'
    },
    {
      destination: '/transformative-healthcare-markets#ourproducts',
      label: 'OUR PRODUCTS'
    }
  ]


  useEffect(() => {
    if (DynamicContent) {
      fetch(r3cContent + '/footer').then((response) => response.json()).then((pgData) => {
        setFooterData(pgData);
      })
    } else {
      setFooterData({
        copyrightText: 'All Rights Reserved © Real Chemistry 2022',
        pageLinks: [
          {
            destination: '/about',
            label: 'About Us'
          },
          {
            destination: '/news-events',
            label: 'Insights'
          },
          {
            destination: '/services',
            label: 'Services'
          },
          {
            destination: '/solutions',
            label: 'Solutions'
          },
          {
            destination: '/careers',
            label: 'Careers'
          },
          {
            destination: '/contact',
            label: 'Contact Us'
          },
          {
            destination: '/newsletters',
            label: 'Newsletters'
          }
        ],
        socialLinks: [
          {
            label: 'Facebook',
            destination: '#'
          },
          {
            label: 'Instagram',
            destination: '#'
          },
          {
            label: 'Twitter',
            destination: '#'
          },
          {
            label: 'Linkedin',
            destination: '#'
          },
          {
            label: 'YouTube',
            destination: '#'
          },
        ]
      })
    }
  }, [])
  
  function validateEmail(email) {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }


  useEffect(()=>{
    setSubscription('');
    setValid(true)
  },[pathname])

  const [subscription, setSubscription] = useState("");
  let [isValid, setValid] = useState(true);
  const [validRecaptcha,setValidRecaptcha] = useState(true)
  const { setSubscriptionEmail, updateFormSubmitted } = useAppContext();


  const {
    handleSubmit,
    subscriptionFormData
  } = useFormManager({
    data: {
      email: '',
      from:'footer-form'
    },
    errors: {
      email: ""
    }
  }, OPTIONAL);

  
  const handleOnChangeSubsriptionBtn = useCallback(
    (e) => {
      setSubscription(e.target.value);
      setValid(true)
      if (e.target.value === '') {
        setValid(true)
      }
    },
    [setSubscription]
  );

  const helperSubscriptionStateManager = useCallback(
     () => {
      setSubscriptionEmail(subscription);
      subscriptionFormData.data.email = subscription;
      setSubscription("");
      updateFormSubmitted(false);
      handleSubmit('footer');
      history.push("/subscribe");
    },
    /* eslint-disable react-hooks/exhaustive-deps */
    [history, subscription, setSubscriptionEmail, setSubscription, isValid]
  );


  const handelOnSubscriptionBtnClick = useCallback(async (e) => {
      e.preventDefault();

      let validate = validateEmail(subscription);
      let proceed = null

      if (validate) {
        setValid(true)
        proceed = await recaptchaHelper();
        if(proceed){
          setValidRecaptcha(true)
        }else(
            setValidRecaptcha(false)
        )
      } else {
        setValid(false)
      };

      if (validate && proceed) {
        e.preventDefault();
        if (subscription.length <= 0) return;
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
          'event': 'e_form_submit01'
        }
        );
        helperSubscriptionStateManager();
      }
    },[subscription, helperSubscriptionStateManager, isValid]);

  const handelOnkeyDown = useCallback(
    (e) => {
      if (e.keyCode === 13 && subscription.length > 0) {
        handelOnSubscriptionBtnClick(e);
      }
    },
    [subscription, helperSubscriptionStateManager]
  );



const render = () => {

  if (props.location.pathname.includes('/transformative-healthcare-markets')){
    return(
     <FooterWrap>
        <WideScreenContainer>
          <FooterRow className={"thmFootTopRow"}>
              <Col8>
                  <TitleMd color="#fff" fontWeight="900" fontFamily="HelveticaNowDisplay Black">
                       Transformative <br/> Healthcare <br/> Markets
                  </TitleMd>
              </Col8>
              <Col4>
                <FooterMenu>
                {
                  thmPageLinks.map((item, index) => {
                    return (
                      <PageName key={index} className={"thmFootLinks"}>
                        <PageLink
                          className={props.location.pathname === item.destination ? "active" : ""}
                          to={item.destination !== "null" && `${item.destination ? item.destination : '#'}`}
                          >
                          {item.label}
                        </PageLink>
                      </PageName>
                    )
                  })
                }
                    <FooterInfo>
                      <InfolList>
                        <InfoItem>
                          <InfoLink to="/privacy-policy">Privacy Policy</InfoLink>
                        </InfoItem>
                        <InfoItem>
                        <InfoA href="/pdf/vendorcodeofconduct.pdf" target="_blank" className="vCOC" >Vendor Code of Conduct</InfoA>
                        </InfoItem>
                        <InfoItem>
                          <button id="ot-sdk-btn" className="ot-sdk-show-settings">Cookie Settings</button>
                        </InfoItem>
                        <InfoItem>
                            <InfoLink href="https://app.convercent.com/en-us/LandingPage/d9b572cb-a8b9-eb11-a97d-000d3ab9f296" target="_blank" >To report an incident anonymously and confidentially, please call 1&nbsp;-&nbsp;800&nbsp;-&nbsp;495&nbsp;-&nbsp;1756</InfoLink>
                          </InfoItem>
                        
                      </InfolList>
                  </FooterInfo>
                </FooterMenu>
              </Col4>
            </FooterRow>
            <FooterRow className={"thmFootBotRow"}>
              <FooterCol3 className={"thmFootCol3"}>
                <FooterCopy className={"thmFootCopy"}>
                  <BodySm color="#fff">
                      © Real Chemistry 2024. All Rights Reserved 
                  </BodySm>
                  <FooterLogoLink to="/">
                    <FooterLogo src="/images/logo/footer-logo.svg" alt="RC white logo"  width="96" style={{display: 'block'}}/>
                  </FooterLogoLink>
                </FooterCopy>
            </FooterCol3>   
          </FooterRow>
        </WideScreenContainer>
     </FooterWrap>
    )
  }else{

    return (
      <FooterWrap id={"subscribe"}>
        <WideScreenContainer>
          <FooterRow>
            <FooterCol1>
              <GetTouch >
                <TitleXs color="#fff">Subscribe for Updates</TitleXs>
                <InputWrap>
                  <InputMail
                    id="emailAddress"
                    placeholder="Email Address"
                    name="email"
                    type="text"
                    aria-label="Submit email address"
                    aria-description="Submit email address to subscribe for Real Chemistry updates"
                    value={subscription}
                    onChange={handleOnChangeSubsriptionBtn}
                    onKeyDown={handelOnkeyDown}
                  />
                  <SubmitBtn onClick={handelOnSubscriptionBtnClick} to="#">
                    <ArrowSubmit src="/images/arrow_submit_white.svg"   alt="Right submit arrow icon"/>
                  </SubmitBtn>
                </InputWrap>
                <Label color={"#7f7f7f !important"}>
                  <p color="#7f7f7f" className="sc-iCfLBT lcUpIw recaptcha-copy"><span>This site is protected by reCAPTCHA, and the Google&nbsp;
                    <a target="_blank" rel="noopener noreferrer" href="https://policies.google.com/privacy" aria-label="View our Privacy Policy">Privacy Policy</a>&nbsp; and&nbsp;
                    <a target="_blank" rel="noopener noreferrer" href="https://policies.google.com/terms" aria-label="View our terms of service">Terms of Service</a>&nbsp; apply.</span>
                  </p>
                </Label>
                {(() => {
                  if (subscription.length === 0 && !isValid) {
                    return <Label>{"Email Required*"}</Label>
                  } else {
                    return subscription.length > 0 && !isValid ? <Label>{"Valid Email Required*"}</Label> : ""
                  }
                })()}
                {
                  !validRecaptcha? <Label>Sorry, Google reCAPTCHA has denied your submission. If you believe this is an error, please contact marketingteam@realchemistry.com for assistance.</Label> : ''
                }
              </GetTouch>
              <SocialIcons>
                <SocialItem>
                  <SocialIcon href="https://www.linkedin.com/company/therealchemistry" target="_blank" aria-label="Link to Real Chemistry Linkedin">
                      <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M11.7862 11.2113H16.4287V13.5238C17.0975 12.1938 18.8125 10.9988 21.3888 10.9988C26.3275 10.9988 27.5 13.6463 27.5 18.5038V27.5H22.5V19.61C22.5 16.8437 21.8313 15.2838 20.1288 15.2838C17.7675 15.2838 16.7862 16.965 16.7862 19.6088V27.5H11.7862V11.2113V11.2113ZM3.2125 27.2875H8.2125V10.9988H3.2125V27.2875V27.2875ZM8.92875 5.6875C8.92894 6.10659 8.84582 6.52154 8.68423 6.90823C8.52265 7.29492 8.28581 7.64564 7.9875 7.94C7.383 8.54078 6.56476 8.87707 5.7125 8.875C4.86174 8.87443 4.04539 8.539 3.44 7.94125C3.14276 7.64589 2.90673 7.29478 2.74543 6.90804C2.58413 6.5213 2.50072 6.10653 2.5 5.6875C2.5 4.84125 2.8375 4.03125 3.44125 3.43375C4.04611 2.8352 4.8628 2.49963 5.71375 2.5C6.56625 2.5 7.38375 2.83625 7.9875 3.43375C8.59 4.03125 8.92875 4.84125 8.92875 5.6875Z" fill="white"/></svg>
                  </SocialIcon>

                  <SocialIcon href="https://x.com/RealChemistry_" target="_blank" aria-label="Link to Real Chemistry X Account">
                  <img src="/images/x-logo_40px.svg" width="28px" alt="X social media icon"/>

                  </SocialIcon>
                  <SocialIcon href="https://www.youtube.com/channel/UCEkRSHRmdM9bZGkjpb-GVSA" target="_blank" aria-label="Link to Real Chemistry Youtube">
                    <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M36.7695 11.5664C36.5703 10.8244 36.1797 10.1477 35.6367 9.60405C35.0938 9.06042 34.4176 8.66893 33.6758 8.46875C30.9453 7.73438 20 7.73438 20 7.73438C20 7.73438 9.05469 7.73437 6.32422 8.46484C5.58211 8.66437 4.90556 9.05565 4.36252 9.59937C3.81948 10.1431 3.42906 10.8201 3.23047 11.5625C2.5 14.2969 2.5 20 2.5 20C2.5 20 2.5 25.7031 3.23047 28.4336C3.63281 29.9414 4.82031 31.1289 6.32422 31.5312C9.05469 32.2656 20 32.2656 20 32.2656C20 32.2656 30.9453 32.2656 33.6758 31.5312C35.1836 31.1289 36.3672 29.9414 36.7695 28.4336C37.5 25.7031 37.5 20 37.5 20C37.5 20 37.5 14.2969 36.7695 11.5664ZM16.5234 25.2344V14.7656L25.5859 19.9609L16.5234 25.2344Z" fill="white"/></svg>
                  </SocialIcon>
                  <SocialIcon href="https://www.instagram.com/real.chemistry/" target="_blank" aria-label="Link to Real Chemistry Instagram">
                    <svg width="35" height="35" viewBox="0 0 35 35" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M17.501 12.9438C14.9922 12.9438 12.9449 14.9911 12.9449 17.4999C12.9449 20.0087 14.9922 22.0561 17.501 22.0561C20.0098 22.0561 22.0572 20.0087 22.0572 17.4999C22.0572 14.9911 20.0098 12.9438 17.501 12.9438ZM31.1661 17.4999C31.1661 15.6132 31.1831 13.7436 31.0772 11.8603C30.9712 9.67279 30.4722 7.73138 28.8726 6.13177C27.2696 4.52874 25.3316 4.03314 23.1441 3.92718C21.2574 3.82122 19.3877 3.83831 17.5044 3.83831C15.6177 3.83831 13.7481 3.82122 11.8648 3.92718C9.67729 4.03314 7.73588 4.53216 6.13627 6.13177C4.53324 7.7348 4.03764 9.67279 3.93168 11.8603C3.82572 13.747 3.84281 15.6166 3.84281 17.4999C3.84281 19.3832 3.82572 21.2563 3.93168 23.1396C4.03764 25.3271 4.53666 27.2685 6.13627 28.8681C7.7393 30.4711 9.67729 30.9667 11.8648 31.0727C13.7515 31.1786 15.6211 31.1616 17.5044 31.1616C19.3912 31.1616 21.2608 31.1786 23.1441 31.0727C25.3316 30.9667 27.273 30.4677 28.8726 28.8681C30.4756 27.2651 30.9712 25.3271 31.0772 23.1396C31.1866 21.2563 31.1661 19.3867 31.1661 17.4999ZM17.501 24.5102C13.6216 24.5102 10.4908 21.3793 10.4908 17.4999C10.4908 13.6205 13.6216 10.4897 17.501 10.4897C21.3804 10.4897 24.5113 13.6205 24.5113 17.4999C24.5113 21.3793 21.3804 24.5102 17.501 24.5102ZM24.7984 11.8398C23.8926 11.8398 23.1612 11.1083 23.1612 10.2026C23.1612 9.29681 23.8926 8.56536 24.7984 8.56536C25.7041 8.56536 26.4356 9.29681 26.4356 10.2026C26.4359 10.4176 26.3937 10.6307 26.3115 10.8294C26.2293 11.0282 26.1087 11.2088 25.9567 11.3609C25.8046 11.5129 25.624 11.6335 25.4252 11.7157C25.2265 11.7979 25.0135 11.84 24.7984 11.8398Z" fill="white"/></svg>
                  </SocialIcon>
                  <SocialIcon href="https://www.facebook.com/TheRealChemistry" target="_blank" aria-label="Link to Real Chemistry Facebook">
                    <svg width="15" height="30" viewBox="0 0 15 30" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M3.72222 30V15.9231H0V10.8548H3.72222V6.52575C3.72222 3.12396 5.92095 0 10.9873 0C13.0386 0 14.5554 0.19665 14.5554 0.19665L14.4359 4.92963C14.4359 4.92963 12.889 4.91457 11.2009 4.91457C9.37389 4.91457 9.08118 5.75652 9.08118 7.15395V10.8548H14.5811L14.3418 15.9231H9.08118V30H3.72222Z" fill="white"/></svg>
                  </SocialIcon>
                </SocialItem>
              </SocialIcons>
            </FooterCol1>
            <FooterCol2 className="footer nav">
              <FooterMenu>
                {
                  footerData?.pageLinks?.map((item, index) => {
                    return (
                      <PageName key={index} >
                        <PageLink
                          className={props.location.pathname === item.destination ? "active" : ""}
                          to={item.destination !== "null" && `${item.destination ? item.destination : '#'}`}
                          >
                          {item.label}
                        </PageLink>
                      </PageName>
                    )
                  })
                }
                {(props.location.pathname === '/careers' || props.location.pathname === '/job-category' || props.location.pathname.includes('/job-detail')) && <li><FooterDol>
                  
                  <DolTopText>
                    <p>Applicants have rights under</p>
                    <p>Federal Employment Laws</p>
                  </DolTopText>
                  <ul>
                    <InfoItem><InfoA href="https://www.dol.gov/sites/dolgov/files/WHD/legacy/files/fmlaen.pdf" target="_blank" >Family and Medical Leave Act (FMLA)</InfoA></InfoItem>
                    <InfoItem><InfoA href="https://www.eeoc.gov/sites/default/files/migrated_files/employers/eeoc_self_print_poster.pdf" target="_blank" >Equal Employment Opportunity (EEO)</InfoA></InfoItem>
                    <InfoItem><InfoA href="https://www.dol.gov/sites/dolgov/files/WHD/legacy/files/eppac.pdf" target="_blank" >Employee Polygraph Protection Act (EPPA)</InfoA></InfoItem>
                    <InfoItem><InfoA href="/pdf/Right-To-Work.pdf" target="_blank" >Right to Work</InfoA></InfoItem>
                    <InfoItem><InfoA href="/pdf/E-Verify-Participation.pdf" target="_blank" >E-Verify Participation</InfoA></InfoItem>
                    <InfoItem><InfoA href="https://transparency-in-coverage.uhc.com" target="_blank" >HealthPlan Transparency in Coverage – UnitedHealthcare</InfoA></InfoItem>
                    <InfoItem><InfoA href="https://healthy.kaiserpermanente.org/front-door/machine-readable" target="_blank" >HealthPlan Transparency in Coverage – Kaiser Permanente</InfoA></InfoItem>
                  </ul>
                  
                </FooterDol></li> }
              </FooterMenu>
              
              <FooterInfo>
                <InfolList>
                  <InfoItem>
                    <InfoLink to="/privacy-policy">Privacy Policy</InfoLink>
                  </InfoItem>
                  <InfoItem>
                  <InfoA href="/pdf/vendorcodeofconduct.pdf" target="_blank" className="vCOC" >Vendor Code of Conduct</InfoA>
                  </InfoItem>
                  <InfoItem>
                    <button id="ot-sdk-btn" className="ot-sdk-show-settings">Cookie Settings</button>
                    {/* | Powered by <InfoLink to="/">&nbsp;OneTrust</InfoLink>
                                    <Avatar src="/images/avatar.svg" /> */}
                  </InfoItem>
                  {(props.location.pathname === '/') && 
                   <InfoItem>
                      <InfoLink href="https://app.convercent.com/en-us/LandingPage/d9b572cb-a8b9-eb11-a97d-000d3ab9f296" target="_blank" >To report an incident anonymously and confidentially, please call 1&nbsp;-&nbsp;800&nbsp;-&nbsp;495&nbsp;-&nbsp;1756</InfoLink>
                    </InfoItem>
                  }
                </InfolList>
              </FooterInfo>
            </FooterCol2>

            <FooterCol3>
              <FooterCopy>
                <BodySm color="#fff">
                  {footerData?.copyrightText}
                </BodySm>
                <FooterLogoLink to="/">
                  <FooterLogo src="/images/logo/footer-logo.svg" alt="RC white logo"/>
                </FooterLogoLink>
              </FooterCopy>
            </FooterCol3>     
          </FooterRow> 
        </WideScreenContainer>
      </FooterWrap>
    )
    }
  }

  if (!props.location.pathname.includes('/content-preview/'))
    return render()

  return <React.Fragment />
}

export default withRouter(Footer);
