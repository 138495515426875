import styled, { css } from 'styled-components'

import {
    BodySm,
    TitleMd,
    TitleXs,
    BodyRg
} from '../elements'
import {
    sizeLaptop,
    sizeTabletLg,
    sizeWideScreen,
    sizeXlDevice
} from '../devices'


// About Style Start
export const AboutWrapper = styled.div`
    border-bottom: 1px solid #707070;
    
    box-sizing: border-box;


    ${sizeWideScreen(`
        // max-width: 2500px;
        // margin: 0 auto;
    `)}
`;
export const AboutRow = styled.div`
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    box-sizing: border-box;
    border-top: 1px solid #707070;
    ${sizeLaptop(css`
    `)}
    ${sizeTabletLg(css`
        height: auto;
    `)}
`;
export const ColLeft = styled.div`
    -webkit-box-flex: 0;
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    box-sizing: border-box;
    // overflow:hidden;
    ${sizeTabletLg(css`
    height: auto;
        -webkit-box-flex: 0;
        -ms-flex: 0 0 100%;
                flex: 0 0 100%;
        width: 100%;
        border-left: none;
    `)}

    &:nth-child(2) {
        ${sizeTabletLg(css`
            display: none;
        `)}
    }
    &::-webkit-scrollbar {
        opacity:0;
        width:0px;
    }

    &::-webkit-scrollbar-track {
        opacity:0;
    }

    &::-webkit-scrollbar-thumb {
        opacity:0;
    }
`;
export const ColRight = styled.div`
    -webkit-box-flex: 0;
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    width: 50%;
    border-left: 1px solid #707070;
    box-sizing: border-box;
    &:first-of-type {
        /*margin-top: -100vh;*/
    }
    &:nth-child(2) {
        ${sizeTabletLg(css`
            display: none;
        `)}
    }
    ${sizeTabletLg(css`
    -webkit-box-flex: 0;
        -ms-flex: 0 0 100%;
        flex: 0 0 100%;
        width: 100%;
    `)}
`;

export const ColLeftInnner = styled.div`
    position: sticky;
    top: 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
`;



// Info Card CSS Start
export const InfoCardOuter = styled.div`
    border-top: 1px solid #707070;
    flex: 0 0 50%;
    &:first-child {
        border-top: none;
    }
`;
export const InfoCardWrap = styled.div`
    padding: 66px 104px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    box-sizing: border-box;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    box-sizing: border-box;
    ${sizeLaptop(css`
        padding: 25px 25px 50px 25px;
    `)}
    ${sizeTabletLg(css`
        height: auto;
        padding: 25px 20px;
    `)}

    ${BodySm} {
        margin-bottom: 50px;
        ${sizeTabletLg(css`
            margin-bottom: 30px;
        `)}
        // For 4k Device
        ${sizeXlDevice(`
            font-size: 1.3em;
        `)}

        /* WideScreen CSS */
        ${sizeWideScreen(`
            font-size: 1.3em;
        `)}
    }
    ${TitleMd} {
        margin-bottom: 30px;
    }
    ${TitleXs} {
        margin-bottom: 25px;
        ${sizeTabletLg(css`
            margin-bottom: 30px;
        `)}
    }
    ${BodyRg} {
        margin-bottom: 25px;
        &::last-child {
            margin-bottom: 0;
        }
    }
`;
export const InfoColTop = styled.div`
    ${sizeTabletLg(`
        margin-bottom: 0;
    `)}
`;
export const InfoColBottom = styled.div`
    p:empty {
        display: none;
    }
    p:last-child{
        margin-bottom:0;
    }
`;


export const ImageWrap = styled.div`
   
`;
export const ImgScroll = styled.img`
    padding: 0 30px;
    box-sizing: border-box;
    width: 100%;
    height: auto;
    margin: 10px 0 0 0;
    &:first-child {
        margin-top: 25px;
    }
    &:last-child {
        margin-bottom: 25px;
    }
    
    ${sizeLaptop(css`
        padding: 0 25px;
    `)}
`;
export const ImgScrollMob = styled.img`
  display: none;
  width: 100%;
  height: auto;
  margin: 0;
  ${sizeTabletLg(`
    display: block;
  `)} 
`
export const Link = styled.a`
    font-size: 18px;
    color: #000;
    text-transform: uppercase;
    display: inline-block;
    margin-top: 30px;
    // For 4k Device
    ${sizeXlDevice(`
        font-size: 0.7vw;
    `)}

    ${sizeWideScreen(`
        font-size: 0.7vw;
    `)}
`;




// About Mobile CSS
export const InfoImgWrap = styled.div`
    display: none;
    ${sizeTabletLg(css`
        display: block;
        font-size: 0;
    `)}
`;
export const InfoImg = styled.img`
    width: 100%;
    margin: 0;
`;
