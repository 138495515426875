import React from 'react';

import {
    TitleXl
} from "components/elements";
import {
    ErrorPageOuter,
    ErrorPageWrap,
    ErrorContainer,
    ErrorContent,
    BackWrap,
    BackLink,
    BackArrows,
    ArrowLongWrap,
    ArrowAngle,
    ArrowLine
} from "./elements";

function ErrorPage() {


 


    return (
        <ErrorPageOuter className="pageWrapper">
            <ErrorPageWrap>
               
                <ErrorContainer>
                    <ErrorContent>
                        <TitleXl color="#FFF">This page does not exist, <br/> but if it did you would love it.</TitleXl>
                    </ErrorContent>

                    <BackWrap>
                        <BackLink to="/">
                            <TitleXl textTransform="uppercase" color="#FFF">back to homepage</TitleXl>
                            <BackArrows>
                                {/* <Arrow src="/images/back-arrow-white.svg" /> */}

                                <ArrowLongWrap>
                                    <ArrowAngle/>
                                    <ArrowLine/>
                                </ArrowLongWrap>

                                {/* <ArrowBlue src="/images/back-arrow-long-white.svg" /> */}
                                {/* <ArrowMobile src="/images/back-arrow-mobile-white.svg" />
                            <ArrowBlueMobile src="/images/back-arrow-mobile-long-white.svg" /> */}
                            </BackArrows>
                        </BackLink>
                    </BackWrap>
                </ErrorContainer>
            </ErrorPageWrap>
        </ErrorPageOuter>
    );
}

export default ErrorPage;
