import React  from 'react';
import {withRouter} from "react-router-dom"
import { TitleXl } from "components/elements";
import {OtherNewsLettersWrapper, TitleXlContainer, NewsLetterContainer} from './elements';
import Newsletters from 'components/Newsletters';



function LearnAboutOtherNewsletters({monthly, valueReport, rdv, healthEquity, displayHeader,cards,header}) {

    console.log(cards)
    return(
        <OtherNewsLettersWrapper>
            {displayHeader && (
            <TitleXlContainer>
                <TitleXl color="#000" fontWeight="900">{header?.text?.content?.[0]?.content?.[0]?.value || undefined}</TitleXl>
            </TitleXlContainer>
            )}
       
           <NewsLetterContainer>
            {cards?.elements?.map((card) => (
            <Newsletters
              link={card.ctaLink}
              title={card.title}
              intro={card.summary}
              image={card.image}
              width="48%"
            />
        ))}
              
            </NewsLetterContainer>
        </OtherNewsLettersWrapper>
    )
}

export default withRouter(LearnAboutOtherNewsletters);
