// import { sizeLaptopMd } from 'components/devices';
import styled from 'styled-components';
import { ReactComponent as leftArrow } from './svg/leftArrow.svg';
import { ReactComponent as rightArrow } from './svg/rightArrow.svg';
import { ReactComponent as cross } from './svg/cross.svg';
import {
  TitleXs,
} from "components/elements";
import {
    sizeMobileSm,
    sizeMobileMd,
    sizeMobileLg,
    sizeLaptopMd,
    sizeLaptopLg,
    sizeDesktopSm,
    sizeDesktopMd,
    sizeXlDevice,
    sizeWideScreen,
} from 'components/devices';
import { Link } from "react-router-dom";

export const TimelineContainer = styled.div`
    position: relative;
    background-color: #000;
    padding: 80px;
    color: #fff;

    ${sizeLaptopMd(`
        padding: 20px;
    `)}
`;

export const TimelineTitle = styled.p`
    font-size: 47px;
    color: #fff;

    ${sizeLaptopMd(`
        font-size: 32px;
    `)}
`;

export const TimelineBody = styled.div`
    padding: 90px 0;

    ${sizeLaptopMd(`
        display: none;
    `)}
`;

export const MobileTimelineBody = styled.div`
    display: none;
    margin-left: -20px;
    margin-right: -20px;

    ${sizeLaptopMd(`
        display: block;
    `)}
`;

export const Years = styled.div`
    // width: ${props => props.width.desktop}px;
    // margin-top: -4px;
    margin-bottom: -6px;
    svg {
      width: 100%;
      height: auto;
    }
`;

export const TimelineRow = styled.div`
    display: flex;
    flex-direction: row;
`;


export const RectContainer = styled.div`
    width: ${props => props.width}%;
`;

export const BigRectItem = styled.div`
    position: relative;
    width: ${props => props.width.desktop}px;
    height: ${props => props.height.desktop}px;
    border-color: ${props => props.color};
    border-width: 1px;
    border-style: solid;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    flex-direction: column;
    cursor: pointer;
    margin: 0 0 0 auto;
    z-index: 10;
    font-size: 16px;

    &:hover {
      text-decoration: underline;
    }

    p {
      padding: 0 4px;
    }

    ${sizeWideScreen(`
        width: 150px;
        font-size: 18px;
    `)}
    ${sizeXlDevice(`
        width: 130px;
    `)}
    ${sizeDesktopMd(`
        width: 96px;
        font-size: 14px;
    `)}
    ${sizeDesktopSm(`
        width: 80px;
        font-size: 12px;
    `)}
`;

export const SmallRectItem = styled.div`
    position: relative;
    width: ${props => props.width.desktop}px;
    height: ${props => props.height.desktop}px;
    border-color: ${props => props.color};
    border-width: 1px;
    border-style: solid;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    flex-direction: column;
    cursor: pointer;
    margin: 0 0 0 auto;
    z-index: 10;
    font-size: 16px;

    &:hover {
      text-decoration: underline;
    }

    p {
      padding: 0 4px;
    }

    ${sizeWideScreen(`
        width: 150px;
        font-size: 18px;
    `)}
    ${sizeXlDevice(`
        width: 130px;
    `)}
    ${sizeDesktopMd(`
        width: 96px;
        font-size: 14px;
    `)}
    ${sizeDesktopSm(`
        width: 80px;
        font-size: 12px;
    `)}
`;

export const LongLine = styled.div`
    width: 1px;
    height: ${props => props.length.desktop}px;
    border-left-color: ${props => props.color};
    border-left-width: 1px;
    border-left-style: solid;
    margin-left: auto;
    margin-right: ${props => props.margin.desktop / 2}px;

    ${sizeWideScreen(`
        margin-right: 75px;
    `)}
    ${sizeXlDevice(`
        margin-right: 65px;
    `)}
    ${sizeDesktopMd(`
        margin-right: 48px;
    `)}
    ${sizeDesktopSm(`
        margin-right: 40px;
    `)}
`;

export const ShortLine = styled.div`
    width: 1px;
    height: ${props => props.length.desktop}px;
    border-left-color: ${props => props.color};
    border-left-width: 1px;
    border-left-style: solid;
    margin-left: auto;
    margin-right: ${props => props.margin.desktop / 2}px;

    ${sizeWideScreen(`
        margin-right: 75px;
    `)}
    ${sizeXlDevice(`
        margin-right: 65px;
    `)}
    ${sizeDesktopMd(`
        margin-right: 48px;
    `)}
    ${sizeDesktopSm(`
        margin-right: 40px;
    `)}
`;

export const SmallRectArea = styled.div`
    display: flex;
    flex-direction: column;
    width: ${props => props.width}%;
`;

export const ItemContainer = styled.div`
    position: relative;
    margin-left: ${props => props.isRight ? '3px' : '0'};
    width: 100%;
`;
export const ArrowContainer = styled.div`
    
`;
export const ArrowWrap = styled.div`
    position: absolute;
    top: 2px;
    right: 2px;
    cursor: pointer;

    ${sizeLaptopMd(`
        &.big-rect-arrow {
          top: 3px;
        }
    `)}
`;
export const TooltipOverlay = styled.div`
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: rgb(31, 31, 31, 0.6);
`;
export const Tooltip = styled.div`
    max-width: 1360px;
    width: 80%;
    height: 780px;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    color: white;
    background-color: rgb(0, 0, 0, 1);
    z-index: 20;

    ${sizeLaptopMd(`
        display: none;
    `)}
`;
export const TooltipTop = styled.div`
    display: flex;
    flex-direction: row;
    padding: 100px;
    height: 280px;

    ${sizeLaptopLg(`
        padding: 80px;
    `)}
`;
export const TooltipBottom = styled.div`
    display: flex;
    flex-direction: row;
    position: absolute;
    bottom: 0;
    height: 200px;
    width: 100%;
`;
export const TopLeft = styled.div`
    width: 40%;
    margin-right: 40px;
    border-bottom: ${props => `2px solid ${props.color}`};
`;
export const TopRight = styled.div`
    width: 60%;
    padding-top: 30px;
`;
export const CTAWrapper = styled(Link)`
  display: flex;
  margin-top: 25px;
  transition: .4s all ease;
  justify-content: flex-end;
  svg {
    margin-left: 1.5em;
    transition: .4s all;
    // Safari repaint fix
    -webkit-transform: translate3d(0, 0, 0);
    width: 24px;
    height: 24px;
    ${sizeLaptopMd(`
      width: 40px;
    `)}
  }
  &:hover {
      ${TitleXs} {
        color: #D12FFF;  
      }
      g, line, path {
        stroke: #D12FFF;
      }
  }
`;
export const DateP = styled.p`
    font-size: 16px;
    margin-bottom: 25px;

    ${sizeMobileSm(`
        margin-bottom: 20px;
    `)}
`;
export const LargeText = styled.p`
    font-size: 47px;
    line-height: 56px;
    // height: 220px;
    font-weight: 400;
    margin-bottom: 40px;

    ${sizeLaptopMd(`
        font-size: 32px;
        line-height: 42px;
    `)}

    ${sizeMobileSm(`
        margin-bottom: 20px;
        font-size: 30px;
        line-height: 40px;
    `)}
`;
export const MiddleTextLeft = styled.p`
    font-size: 28px;
    text-transform: uppercase;
    height: 120px;
    border-bottom: 2px solid #64FFC5;
`;
export const MiddleTextRight = styled.p`
    font-size: 28px;
    text-transform: uppercase;
    height: 120px;
    border-bottom: 2px solid #FF9C30;
`;
export const SmallText = styled.p`
    font-size: 22px;

    ${sizeLaptopMd(`
        font-size: 16px;
        margin-top: 30px;
    `)}

    ${sizeMobileSm(`
        margin-top: 20px;
    `)}
`;
export const BottomItemLeft = styled.div`
    border-top: 2px solid white;
    border-right: 1px solid white;
    width: 100%;
    display: flex;
    align-items: center;
    padding: 30px 60px 30px 0;
`;
export const BottomItemRight = styled.div`
    border-top: 2px solid white;
    border-left: 1px solid white;
    width: 100%;
    display: flex;
    align-items: center;
    padding: 30px 0 30px 60px;
`;
export const LeftArrow = styled(leftArrow)`
  width: 117px;
  height: 58px;
  fill: palevioletred;
  margin-left: 60px;
  cursor: ${props => props.isfirst};
  &.hide {
    visibility: hidden;
    opacity: 0;
  }

  ${sizeLaptopMd(`
        width: 50px;
        margin-left: 25px;
    `)}
`;
export const RightArrow = styled(rightArrow)`
  width: 117px;
  height: 58px;
  fill: palevioletred;
  margin-right: 60px;
  cursor: ${props => props.islast};
  &.hide {
    visibility: hidden;
    opacity: 0;
  }

  ${sizeLaptopMd(`
        width: 50px;
        margin-right: 25px;
        margin-top: 1px;
    `)}
`;
export const Cross = styled(cross)`
  position: absolute;
  right: -82px;
  top: 0;
  width: 50px;
  height: 50px;
  fill: black;
  cursor: pointer;

  ${sizeLaptopMd(`
        right: 20px;
        top: 10px;
        width: 45px;
        height: 45px;
    `)}
`;

export const ContentContainer = styled.div`
    display: flex;
    flex-direction: row;
    border-bottom: ${props => props.isLast ? '0' : '1px solid #8F8F8F'};
    justify-content: space-between;
    padding: 15px 20px;
`;

export const NoContentItem = styled.div`
`;

export const ItemsWrap = styled.div`
    display: flex;
    flex-direction: column;
    width: 80%;
`;

export const MobileRowWrap = styled.div`
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    min-width: 265px;
    width: 100%;
    margin: 5px 0;
`;

export const MobileYear = styled.div`
    color: ${props => props.isItem ? "white" : "gray"};
    margin-top: 5px;
`;

export const MobileBigRectItem = styled.div`
    border-color: ${props => props.color};
    border-width: 1px;
    border-style: solid;
    display: inline-block;
    align-items: left;
    justify-content: center;
    text-align: left;
    flex-direction: column;
    cursor: pointer;
    padding: 20px;
    width: calc(100% - 40px);
    font-size: 18px;

    ${sizeMobileMd(`
        font-size: 16px;
    `)}
`;

export const MobileSmallRectItem = styled.div`
    border-color: ${props => props.color};
    border-width: 1px;
    border-style: solid;
    display: flex;
    justify-content: center;
    text-align: left;
    flex-direction: column;
    cursor: pointer;
    padding: 20px;
    vertical-align: middle;
    // min-width: 90px;
    font-size: 18px;

    ${sizeMobileLg(`
        height: calc(100% - 40px);
    `)}
    ${sizeMobileMd(`
        font-size: 16px;
    `)}
`;

export const CrossWrap = styled.div`
    border-bottom: 1px solid #8F8F8F;
    display: flex;
    justify-content: flex-end;
    height: 70px;
    align-items: center;
    padding: 0 15px;
`;

export const MobileTooltip = styled.div`
    display: none;
    flex-direction: column;
    color: white;
    background: black;
    width: 100vw;
    height: 100vh;
    position: fixed;
    z-index: 100;
    top: 0;
    left: 0;
    

    ${sizeLaptopMd(`
        display: flex;
    `)}
`;

export const TooltipTitle = styled.div`
    // height: 180px;
    border-bottom: 1px solid ${props => props.color};
`;

export const MobileTooltipBody = styled.div`
    padding: 25px;
`;

export const TooltipFooter = styled.div`
    position: fixed;
    bottom: 0;
    left: 0;
    z-index: 101;
    height: 80px;
    width: 100vw;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    border-top: 1px solid #8F8F8F;
`;







export const TimelineWrap = styled.div`
`;
export const TimeLineImgContainer = styled.div`
    max-width: 100%;
    box-sizing: border-box;
`;
export const TimelineImg = styled.img`
    width: 100%;
    display: block;
    background-color: #000;
    ${sizeLaptopMd(`
        display: none;
    `)}
`;
export const TimeLineImgMobile = styled.img`
    background-color: #000;
    width: 100%;
    display: none;
    ${sizeLaptopMd(`
        display: block;
    `)}
`;