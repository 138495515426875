import styled from "styled-components";

import { SectionTitle, BodyRg, TitleSm } from "components/elements";
import { sizeTabletLg, sizeWideScreen } from "components/devices";

// CSS Start
export const ScamAlertPageWrap = styled.div`
  padding-top: 120px;
  ${sizeTabletLg(`
        padding-bottom: 30px;
    `)}
  ${SectionTitle} {
    margin-bottom: 65px;
    padding: 0;
    ${sizeTabletLg(`
            text-align: left !important;
            margin-bottom: 45px;
        `)}
  }
  ${BodyRg} {
    font-size: 24px;
    margin-bottom: 20px;
    ${sizeTabletLg(`
            font-size: 16px;
        `)}

  ${sizeWideScreen(`
      font-size: 0.85vw;
    `)}
  }
  ${TitleSm} {
    margin-bottom: 20px;
    ${sizeWideScreen(`
      font-size: 0.95vw;
    `)}
  }
`;
