import React from 'react'
import RichText from 'components/RichText'

import {
    TitleXs, BodyRg
} from 'components/elements'
import styled from "styled-components";
import {sizeDesktopMd, sizeLaptopLg, sizeTabletLg, sizeWideScreen} from "./devices";

// Items Card  Start
export const ItemsCardWrap = styled.div`
  ${TitleXs} {
    margin-bottom: 20px;
    font-size: 2em;
    line-height: 1;
    ${sizeWideScreen(`
        font-size: 1vw;
    `)}
  }

  ${sizeTabletLg(`
        text-align: center;
    `)}
  ${BodyRg} {
    ${sizeWideScreen(`
            font-size: 0.7vw;
        `)}
  }
`;
export const ItemsCardRow = styled.div`
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  // -ms-flex-wrap: wrap;
  // flex-wrap: wrap;
  ${sizeTabletLg(`
        flex-direction: row important!;
    `)}
`;
export const ItemsLeft = styled.div`
  width: 140px;
  text-align: left;
  flex: 0 0 25%;
  ${sizeDesktopMd(`
        // width: 106px;
        // margin-bottom: 20px;
    `)}
  ${sizeTabletLg(`
        flex: 0 0 25%;
    `)}
`;
export const ItemsCardIcon = styled.img`
  width: 70px;
  height: 70px;
  object-fit: contain;
  text-align: left; 
`;
export const ItemsCardRight = styled.div`
 text-align: left;
  flex: 0 0 75%;
  // width: calc(100% - 150px);
  ${sizeDesktopMd(`
    // width: calc(100% - 122px);
  `)}
  ${sizeLaptopLg(`
      // width: calc(100% - 50px);
  `)};
  ${sizeTabletLg(`
      //  width: 100%;
      flex: 0 0 75%;
  `)}
`;

function ItemsCard({card}) {
    let data = card ? card : {}


    return (
        <ItemsCardWrap>
            <ItemsCardRow>
                <ItemsLeft>
                    <ItemsCardIcon src={data.icon} alt={''}/>
                </ItemsLeft>
                <ItemsCardRight>
                    <TitleXs>{data.title}</TitleXs>
                    <RichText body={data.detail} options={{fontSize: '1em'}}/>
                </ItemsCardRight>
            </ItemsCardRow>
        </ItemsCardWrap>
    )
}

export default ItemsCard
