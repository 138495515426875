
import styled, { css } from 'styled-components'

import {
    BodyRg,
    TitleSm,
    TitleXl
} from 'components/elements'

import {
    sizeXlDevice,
    sizeTabletLg,
    sizeWideScreen
} from 'components/devices'

export const QuoteWrap = styled.div`
    height: ${({ height }) => height || "auto"};
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    padding: ${({ padding }) => padding || "30px 30px"};
    box-sizing: border-box;
    width: 100%;
    border-top: ${({ borderTop }) => borderTop || "1px solid #707070"};
    border-right: ${({ borderRight }) => borderRight || "1px solid #707070"};
    border-bottom: ${({ borderBottom }) => borderBottom || "1px solid #707070"};
    border-left: ${({ borderLeft }) => borderLeft || "1px solid #707070"};

    ${BodyRg} {
        ${sizeWideScreen(`
            font-size: 0.6vw;
        `)}
    }
    ${TitleXl} {
        font-size: 150px;
        font-family: 'Neue Pixel Grotesk';
        line-height: 0.8;
        position: absolute;
        left: 0;
        top: -7px;
        ${sizeTabletLg(css`
            top: -15px;
        `)} 

        ${sizeWideScreen(`
            font-size: 3.5vw;
        `)}
    }
    ${TitleSm} {
        margin-bottom: 40px;
        max-width: 780px;
        line-height: 1.2;

        /* For 4k Device */
        ${sizeXlDevice(`
            max-width: 1000px;
        `)}

        ${sizeWideScreen(`
            font-size: 1vw;
        `)}
    }
    ${sizeTabletLg(css`
        border-left: none;
        border-right: none;
    `)} 
`;

export const QuoteInner = styled.div`
    position: relative;
    padding-top: 50px;
`;

