import { Link } from "react-router-dom";
import styled, { css } from 'styled-components';

import {
    TitleXs,
    BodySm,
    TitleMd,
    TitleSm
} from "components/elements";
import {
    sizeLaptopLg,
    sizeLaptop,
    sizeTabletLg,
    sizeMobileLg,
} from "components/devices";


// Text Component Style
export const InsightImg = styled.img`
    width: 100%;
    height: 100%;
    transition: 0.4s all ease;
    object-fit: cover;
    ${sizeTabletLg(`
        display: none;
    `)}
`;
export const InsightImgMobile = styled.img`
    width: 100%;
    height: 100%;
    transition: 0.4s all ease;
    object-fit: cover;
    display: none;
    ${sizeTabletLg(`
        display: block;
    `)}
`;
export const ImageDiv = styled.div`
    overflow: hidden;
    transition: 0.4s all ease;
    height: 100%;
    box-sizing: border-box;
    width: 100%;
`;

export const InsightIcon = styled.img`
    width: ${(props) => ['BlogPost', 'Event', 'PressRelease', 'Podcast', 'News'].some((v) => props?.type === v) ? "56px" : "47px"};
`;
export const InsightWhite = styled.img`
    display: none;
    margin-right: 20px;
    width: ${(props) => ['BlogPost', 'Event', 'PressRelease', 'Podcast', 'News'].some((v) => props?.type === v) ? "56px" : "47px"};
`;
export const PlainIconWhite = styled.img`
    display: none;
    margin-right: 20px;
    width: ${(props) => ['BlogPost', 'Event', 'PressRelease', 'Podcast', 'News'].some((v) => props?.type === v) ? "56px" : "47px"};
    height: ${(props) => ['BlogPost', 'Event', 'PressRelease', 'Podcast', 'News'].some((v) => props?.type === v) ? "56px" : "47px"};
`;
export const InsightTextCol = styled.div`
    width: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
    height: 100%;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    padding: ${({ padding }) => padding || "30px 30px"};
    border-right: 1px solid #707070;
    box-sizing: border-box;
    position: relative;
    transition: 0.5s all ease;
    &:last-child {
        border-right: none;
    }
    ${sizeLaptop(css`
        padding: 25px;
    `)}
    ${sizeTabletLg(css`
        align-items: center;
        text-align: center;
        border-right: none;
        padding: 30px 20px;
    `)}
`;
export const InsightsLink = styled(Link)`
    font-size: 16px;
    font-weight: 500;
    text-transform: uppercase;
    color: #000;

`;
export const InsightTextLink = styled(Link)`
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 10;
`;

export const InsightsTextWrap = styled.div`
    height: ${({ height }) => height || "auto"};
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    box-sizing: border-box;
    position: relative;
    -webkit-transition: 0.5s all ease;
    transition: 0.5s all ease;
    width: 100%;
    border-top: ${({ borderTop }) => borderTop || "1px solid #707070"};
    border-right: ${({ borderRight }) => borderRight || "1px solid #707070"};
    border-bottom: ${({ borderBottom }) => borderBottom || "1px solid #707070"};
    border-left: ${({ borderLeft }) => borderLeft || "1px solid #707070"};

    &:hover {
        ${InsightImg},
        ${InsightImgMobile} {
            transform: scale(${props => props.link ? "1.2" : "1.2"});
        }
    }
    
    ${sizeTabletLg(css`
        text-align: center;
        align-items: center;
        height: auto;
        flex-wrap: wrap;
        flex-direction: column-reverse;
        border-right: none;
    `)}
    ${TitleMd} {
        display: ${({ displayTitleMd }) => displayTitleMd};
        ${sizeTabletLg(css`
            margin-bottom: 40px;
            max-width: 400px;
        `)}
    }
    ${TitleXs} {
        display: ${({ displayTitleXs }) => displayTitleXs};
        font-size: 32px;
        ${sizeTabletLg(css`
            margin-bottom: 40px;
            font-size: 28px;
        `)}
    }
`;





// Image Lg CSS
export const InsightImgLg = styled.div`
    height: ${({ height }) => height || "auto"};
    padding: ${({ padding }) => padding || "40px 30px"};
    width: 100%;
    box-sizing: border-box;
    border-top: ${({ borderTop }) => borderTop || "1px solid #707070"};
    border-right: ${({ borderRight }) => borderRight || "1px solid #707070"};
    border-bottom: ${({ borderBottom }) => borderBottom || "1px solid #707070"};
    border-left: ${({ borderLeft }) => borderLeft || "1px solid #707070"};
    position: relative;
    &:hover {
        ${InsightImg},
        ${InsightImgMobile} {
            transform: scale(${props => props.link ? "1.2" : ""});
        }
    }

    ${sizeLaptop(css`
        padding: 25px;
    `)}
    ${sizeTabletLg(css`
        padding: 20px;
        border: none;
    `)}
`;



// Info With Large Image CSS
export const InfoLargeImg = styled.div`
    height: ${({ height }) => height || "auto"};
    width: 100%;
    box-sizing: border-box;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    padding: ${({ padding }) => padding || "40px 30px"};
    border-top: ${({ borderTop }) => borderTop || "1px solid #707070"};
    border-right: ${({ borderRight }) => borderRight || "1px solid #707070"};
    border-bottom: ${({ borderBottom }) => borderBottom || "1px solid #707070"};
    border-left: ${({ borderLeft }) => borderLeft || "1px solid #707070"};
    position: relative;
    &:hover {
        ${InsightImg},
        ${InsightImgMobile} {
            transform: scale(${props => props.link ? "1.2" : ""});
        }
    }
    ${sizeMobileLg(css`
        display: none;
    `)}



    ${TitleXs} {
        font-size: ${(props) => props.height === "640px" ? "26px" : "20px"};
    }
`;
export const InfoCol = styled.div`
    width: 48%;
    flex: 0 0 48%;
    ${sizeMobileLg(css`
        display: none;
    `)}
`;

export const InfoColRight = styled.div`
    width: 48%;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 48%;
            flex: 0 0 48%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    height: 100%;
    box-sizing: border-box;
    
    ${sizeMobileLg(css`
        width: 100%;
        -webkit-box-flex: 0;
        -ms-flex: 0 0 100%;
        flex: 0 0 100%;
    `)}
`;


export const InsightLabel = styled.div`
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    ${sizeTabletLg(css`
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
        -webkit-box-orient: vertical;
        -webkit-box-direction: reverse;
        -ms-flex-direction: column-reverse;
        flex-direction: column-reverse;
        text-align: center;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        margin-right: 0;
        margin-bottom: 40px;
    `)}
    ${InsightIcon},
    ${InsightWhite},
    ${PlainIconWhite} {
        margin-right: 20px;
        ${sizeTabletLg(css`
            margin-right: 0;
            margin-top: 17px;
        `)}
    }
`;
export const BottomInfoInsight = styled.div`
    margin-top: auto;
    ${sizeTabletLg(css`
        text-align: center;
        ${sizeTabletLg(css`
            max-width: 300px;
            margin: auto auto 0 auto;
        `)}
    `)}
    ${TitleXs} {
        margin-bottom: 60px;
    }
`;


export const SmallImg = styled.div`
`;

export const ImageTextWrap = styled.div`
    height: ${({ image }) => image ? "100%" : "auto"};
    padding-top: 15px;
`;
export const InfoSmallImg = styled.div`
    height: ${({ height }) => height || "auto"};
    padding: ${({ padding }) => padding || "38px 30px"};
    width: 100%;
    box-sizing: border-box;
    border-top: ${({ borderTop }) => borderTop || "1px solid #707070"};
    border-right: ${({ borderRight }) => borderRight || "1px solid #707070"};
    border-bottom: ${({ borderBottom }) => borderBottom || "1px solid #707070"};
    border-left: ${({ borderLeft }) => borderLeft || "1px solid #707070"};
    position: relative;
    ${sizeTabletLg(css`
        border-right: none;
        height: auto !important;
        text-align: center;
        padding: ${({ padding }) => padding || "35px 20px"};
    `)}
    &:hover {
        ${InsightImg},
        ${InsightImgMobile} {
            transform: scale(${props => props.link ? "1.2" : ""});
        }
    }

    ${InsightLabel} {
        margin-bottom: 25px;
    }
    ${BottomInfoInsight} {
        margin-top: 25px;
        ${sizeLaptop(css`
            margin-top: 15px;
        `)}
    }
    ${TitleXs} {
        font-size: ${(props) => props.height === "640px" ? "26px" : "20px"};
    }
    &.image-comp {
        ${ImageDiv} {
            margin-bottom: 20px;
            height: 100%;
            max-height: 250px;
            ${sizeTabletLg(css`
                max-height: fit-content;
            `)}
        }
    }
`;
export const InfoColSmall = styled.div`
     display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    height: 100%;
    ${sizeTabletLg(`
        height: auto;
    `)}
`;


export const ArticleCardLink = styled.a`
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 10;
`;
export const ArticleCardLinkInsight = styled(Link)`
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 10;
`;

export const LinkPlain = styled.a`
    width: 54px;
    height: 54px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    ${sizeTabletLg(css`
        margin: 40px auto 0;
        width: 40px;
        height: 40px;
    `)}
`;
export const Arrow = styled.img`
    ${sizeLaptop(css`
        width: 35px;
    `)}
`;




export const PlainWrapRow = styled.div`
    width: 50%;
    flex: 0 0 50%;
    ${sizeTabletLg(css`
        text-align: center;
    `)}
    ${sizeMobileLg(css`
        flex: 0 0 100%;
        width: 100%;
    `)}
`;

export const ArrowWhite = styled.img`
    display: none;
    ${sizeLaptop(css`
        width: 35px;
    `)}
    ${sizeTabletLg(css`
        margin: 0 auto;
    `)}
`;
export const PlainWrap = styled.div`
    height: 100%; 
    padding: ${({ padding }) => padding || "40px 30px"}; 
    background-color: ${({ backgroundColor }) => backgroundColor || "#E5E7FF"};
    width: 100%;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    box-sizing: border-box;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-transition: 0.4s all ease;
    transition: 0.4s all ease;
    border: none;

    ${sizeLaptopLg(css`
        padding: 25px;
    `)}
    ${sizeLaptop(css`
        padding: 15px;
    `)}
    ${sizeTabletLg(css`
        border-right: none;
        min-height: 300px;
    `)}

    ${InsightLabel} {
        margin-bottom: 15px;
    }
`;
export const PlainCol = styled.div`
    max-width: 100%;
    width: 100%;
    overflow: hidden;
`;
export const PlainImg = styled.img`
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: 0.4s all ease;
    min-height: 300px;
    border-right: 1px solid #707070;
    box-sizing: border-box;
`;
export const PlainImgMobile = styled.img`
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: 0.4s all ease;
    min-height: 300px;
`;

export const PlainRow = styled.div`
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    height: ${({ height }) => height || "auto"}; 
    border-top: ${({ borderTop }) => borderTop || "1px solid #707070"};
    border-right: ${({ borderRight }) => borderRight || "1px solid #707070"};
    border-bottom: ${({ borderBottom }) => borderBottom || "1px solid #707070"};
    border-left: ${({ borderLeft }) => borderLeft || "1px solid #707070"};
    position: relative;
    overflow: hidden;
    box-sizing: border-box;
    transition: 0.4s all ease;
    ${sizeTabletLg(`
        height: auto !important;
    `)}
    &.reverse {
        -webkit-box-orient: horizontal;
        -webkit-box-direction: reverse;
        -ms-flex-direction: row-reverse;
        flex-direction: row-reverse;
        ${PlainImg} {
            border-right: none;
            border-left: 1px solid #707070;
        }
    }

    &:hover {
        ${BodySm},
        ${TitleXs},
        ${InsightsLink} {
            color: #fff;
        }
        ${PlainIconWhite},
        ${ArrowWhite} {
            display: inline-block;
        }
        ${InsightIcon},
        ${Arrow} {
            display: none;
        }
        ${PlainWrap} {
            background-color: ${({ hoverBg }) => hoverBg || "#0F20FF"};
        }
        ${PlainImg},
        ${PlainImgMobile} {
            transform: scale(1.2);
        }
    }


    ${TitleXs} {
        font-size: ${(props) => props.height === "640px" ? "26px" : "20px"};
    }
`;




// Insight with date CSS Start
export const InsightDateWrap = styled.div`
    height: ${({ height }) => height || "100"};
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    padding: ${({ padding }) => padding || "40px 30px"};
    box-sizing: border-box;
    width: 100%;
    position: relative;
    border-top: ${({ borderTop }) => borderTop || "1px solid #707070"};
    border-right: ${({ borderRight }) => borderRight || "1px solid #707070"};
    border-bottom: ${({ borderBottom }) => borderBottom || "1px solid #707070"};
    border-left: ${({ borderLeft }) => borderLeft || "1px solid #707070"};
    &:hover {
        ${InsightImg},
        ${InsightImgMobile} {
            transform: scale(${props => props.link ? "1.2" : ""});
        }
    }
    ${sizeTabletLg(css`
        height: auto !important;
        padding: 20px 0 0 0;
        border-bottom: 1px solid #707070;
        border-left: none;
        border-right: none;
    `)}

    ${ImageDiv} {
        ${sizeTabletLg(css`
            padding: 0 20px 20px 20px;
        `)} 
    }
    
    ${InsightImg} {
        flex: 0 0 60%;
    }
    ${InsightLabel} {
        width: 100%;
        ${sizeTabletLg(css`
            flex-direction: column;
            align-items: center;
        `)} 
    }
    ${BodySm} {
        &:last-child {
            margin-left: auto;
            ${sizeTabletLg(css`
                margin-left: 0;
            `)} 
        }
    }
    ${TitleSm} {
        margin: 25px 0 55px;
        font-size: 32px;
    }
    ${InsightIcon} {
        ${sizeTabletLg(css`
            margin-bottom: 30px;
            margin-top: 0;
        `)} 
    }
`;

export const ImageDivMobile = styled(ImageDiv)`
    display: none;
    ${sizeTabletLg(css`
        display: block !important;
    `)} 
`;
export const InsightDateInfo = styled.div`
    padding: 30px;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 40%;
    flex: 0 0 40%;
    width: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    box-sizing: border-box;
    ${sizeTabletLg(css`
        padding: 35px 25px 50px 25px;
        -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 100%;
        text-align: center;
        -webkit-box-align: center;
        -ms-flex-align: center;
        -ms-grid-row-align: center;
        align-items: center;
    `)}
    ${BodySm} {
        &.label {
            ${sizeTabletLg(css`
                display: none;
            `)}
        }
    }
`;
