import React, {useCallback, useEffect, useState} from "react";
import {Link, useLocation} from "react-router-dom";
import styled, {css} from "styled-components";

import {BodySm, TitleMd, TitleXs, Label} from "components/elements";

import {
    sizeXlDevice,
    sizeDesktopMd,
    sizeTabletLg,
    sizeMobileLg,
    sizeMobileMd,
    sizeLaptopLg,
    sizeLaptop,
    sizeMobileXs,
    sizeMobileSm,
    sizeWideScreen
} from "components/devices";

import useFormManager from 'hooks/useFormManager';

import {useAppContext} from "store";
import {useHistory} from "react-router-dom";
import {r3cPageContent} from "../constants";
import {findContent, recaptchaHelper} from "../generalHelpers";
// import {recaptchaHelper} from "../generalHelpers";


// Subscribe Card Style Start
export const SubscribeCardWrap = styled.div`
  width: 650px;
  height: 650px;
  background-color: #fff;
  border-radius: 50%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  text-align: center;
  margin: 0 auto;
  /* For 4k Device */
  ${sizeXlDevice(`
        width: 750px;
        height: 750px;
        -webkit-box-flex: 0;
        -ms-flex: 0 0 750px;
                flex: 0 0 750px;
    `)}
  ${sizeDesktopMd(`
        width: 500px;
        height: 500px;
        -webkit-box-flex: 0;
        -ms-flex: 0 0 500px;
                flex: 0 0 500px;
    `)};
  ${sizeLaptopLg(`
        // width: 570px;
        // height: 570px;
        // -webkit-box-flex: 0;
        // -ms-flex: 0 0 570px;
        //         flex: 0 0 570px;
    `)};
  ${sizeLaptop(`
        // width: 500px;
        // height: 500px;
        // flex: 0 0 500px;
    `)};
  ${sizeLaptop(`
        width: 450px;
        height: 450px;
        -webkit-box-flex: 0;
        -ms-flex: 0 0 450px;
                flex: 0 0 450px;
    `)};
  ${sizeMobileMd(`
        width: 340px;
        height: 340px;
        -webkit-box-flex: 0;
        -ms-flex: 0 0 340px;
                flex: 0 0 340px;
    `)};
  ${sizeMobileXs(`
        width: 290px;
        height: 290px;
        -webkit-box-flex: 0;
        -ms-flex: 0 0 290px;
                flex: 0 0 290px;
    `)};
  /* Wide Screen CSS */
  ${sizeWideScreen(`
      width: 800px;
        height: 800px;
        -webkit-box-flex: 0;
        -ms-flex: 0 0 800px;
                flex: 0 0 800px;
    `)}
  ${BodySm} {
    color: #000;
    margin-bottom: 20px;
    /* For 4k Device */
    ${sizeXlDevice(`
            font-size: 1.1vw;
            margin-bottom: 40px;
        `)}
  }
  ${TitleMd} {
    color: #000;
    line-height: 50px;
    font-size: 44px;
    margin-bottom: 20px;
    ${sizeTabletLg(`
        font-size: 32px;
        line-height: 35px;
    `)}
    ${sizeMobileLg(`
        margin-bottom: 5px;
        font-size: 26px;
        line-height: 30px;
        padding: 20px 0 0;
    `)};
    ${sizeMobileSm(`
        font-size: 24px;
    `)};
 
  }
  ${BodySm} {
    font-size: 15px;
    line-height: 20px;
    ${sizeMobileLg(`
      line-height: 17px;
      margin-bottom: 0;
    `)};
    ${sizeMobileSm(`
        font-size: 14px;
    `)};
  
    /* Wide Screen CSS */ 
    ${sizeWideScreen(`
      font-size: 0.65vw;
    `)}
  }
`;
export const CardInner = styled.div`
  max-width: 450px;
  width: 100%;
  padding: 30px;
  /* Wide Screen CSS */
  ${sizeWideScreen(`
      max-width: 77%;
  `)} /* For 4k Device */ ${sizeXlDevice(`
        max-width: 77%;
    `)}
  ${sizeDesktopMd(`
        width: 90%;
        box-sizing: border-box;
        padding: 25px;
    `)};
  ${sizeMobileLg(`
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
    `)};
  ${sizeMobileXs(`
      width: 96%;
    `)}
`;

export const GetTouch = styled.div`
  margin-bottom: 0;
  ${TitleXs} {
    margin-bottom: 20px;
  }
  ${sizeTabletLg(css`
    margin-bottom: 10px;
    width: 100%;
  `)}
  ${sizeMobileLg(`
        margin-bottom: 10px;
    `)}
  ${Label} {
    color: #d54639;
    text-transform: inherit;
    margin-bottom: 0;
    display: block;
    margin-top: 7px;
    text-align: left;
  }
`;
export const InputWrap = styled.div`
  position: relative;
  height: 50px;
  border-bottom: 1.75px solid #000;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  ${sizeTabletLg(`
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        -webkit-box-align: center;
        -ms-flex-align: center;
        -ms-grid-row-align: center;
        align-items: center;
        height: auto;
        border-bottom: none;
    `)}
`;
export const InputMail = styled.input`
  border: none;
  background-color: transparent;
  height: 50px;
  width: calc(100% - 50px);
  font-size: 20px;
  font-weight: 400;
  color: #000;
  padding: 5px 5px 5px 15px;
  box-sizing: border-box;
  -webkit-border-radius: 0 !important;
  -moz-border-radius: 0 !important;
  border-radius: 0 !important;
  &::placeholder {
    color: #000;
  }
  ${sizeMobileSm(`
      font-size: 16px;
      height: 40px;
      padding-left: 10px;
  `)}
  &:focus {
    outline: none;
  }
  &:-webkit-autofill,
  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus,
  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus,
  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus {
    -webkit-text-fill-color: #000 !important;
  }
  /* For 4k Device */
  ${sizeXlDevice(`
        border-width: 2px;
        font-size: 1.2vw;
    `)}
  ${sizeTabletLg(`
        border-bottom: 1px solid #000;
        margin-bottom: 15px;
        width: 100%;
        padding: 5px 10px;
    `)}
  ${sizeMobileSm(`
        margin-bottom: 10px;
    `)} /* Wide Screen CSS */ ${sizeWideScreen(`
      font-size: 0.8vw;
    `)}
`;
export const SubmitBtn = styled(Link)`
  width: 60px;
  height: 50px;
  background-color: transparent;
  border-radius: 0;
  border: none;
  position: relative;
  left: 0;
  transition: 0.4s all ease;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  &:focus {
    outline: none;
    box-shadow: none;
  }
  &:hover {
    left: 6px;
    ${sizeTabletLg(`
      left: 0;
  `)}
  }
  ${sizeTabletLg(`
        flex-direction: column;
        align-items: center;
        height: auto;
    `)}
  ${sizeMobileSm(`
      height: 40px;
  `)}
`;
export const ArrowSubmit = styled.img`
  width: 95%;
`;
const OPTIONAL = [];

function SubscribeCard(props) {
    function validateEmail(email) {
        const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    }

    let history = useHistory();
    const { pathname } = useLocation();
    const [subscription, setSubscription] = useState("");
    let [isValid, setValid] = useState(true);
    const [validRecaptcha, setValidRecaptcha] = useState(true); //,
    const {setSubscriptionEmail, updateFormSubmitted} = useAppContext();
    const [subscribeContent,setSubscribeContent] = useState([]);

    const {
        handleSubmit,
        subscriptionFormData
    } = useFormManager({
        data: {
            email: ''
        },
        errors: {
            email: ""
        }
    }, OPTIONAL);

    const handleOnChangeSubsriptionBtn = (e) => {

        setSubscription(e.target.value);
        setValid(true)

    };
    const helperSubscriptionStateManager = useCallback( () => {

        setSubscriptionEmail(subscription);
        subscriptionFormData.data.email = subscription;
        subscriptionFormData.data['from'] = props?.from;
        setSubscription("");
        updateFormSubmitted(false);
        handleSubmit();
        history.push("/subscribe");
        /* eslint-disable react-hooks/exhaustive-deps */
    }, [history, subscription, setSubscriptionEmail, setSubscription, isValid]);

    const handelOnSubscriptionBtnClick = useCallback(
        async (e) => {
            e.preventDefault();
            let validate = validateEmail(subscription);
            let proceed = null


            if (validate) {
                setValid(true)
                proceed = await recaptchaHelper();
                if(proceed){
                    setValidRecaptcha(true)
                }else(
                    setValidRecaptcha(false)
                )
            } else {
                setValid(false)
            }
            if (validate && proceed ) { 
                e.preventDefault();
                if (subscription.length <= 0) return;
                window.dataLayer = window.dataLayer || [];
                window.dataLayer.push({
                        'event': 'e_form_submit02'
                    }
                );
                helperSubscriptionStateManager();
            }
        },
        [subscription, helperSubscriptionStateManager, isValid]
    );

    const handleOnkeyDown = useCallback(
        (e) => {
            if (e.keyCode === 13 && subscription.length > 0)
                handelOnSubscriptionBtnClick(e)
        },
        [subscription, helperSubscriptionStateManager]
    );

    useEffect(()=>{
        setSubscription('');
        setValid(true)
    },[pathname])

    useEffect(()=>{
        fetch(r3cPageContent + '?route=/subscribe-popup').then((response) => response.json()).then((page) => {
            setSubscribeContent(findContent(page.contents, 'subscribeContent'))

        })
    },[])

    return (
        <SubscribeCardWrap className="subscribe-card-wrap">
            <CardInner>
                <TitleMd textTransform="uppercase">
                    {subscribeContent?.headline}
                </TitleMd>
                <BodySm>
                    {subscribeContent?.description}
                </BodySm>
                <GetTouch>
                    <InputWrap>
                        <InputMail
                            placeholder="Email Address"
                            name="email"
                            value={subscription}
                            onChange={handleOnChangeSubsriptionBtn}
                            onKeyDown={handleOnkeyDown}
                        />
                        <SubmitBtn onClick={handelOnSubscriptionBtnClick} to="#">
                            <ArrowSubmit src="/images/arrow_submit.svg" alt="Right submit arrow icon"/>
                        </SubmitBtn>
                    </InputWrap>
                    {(() => {
                        if (subscription.length === 0 && !isValid) {
                            return <Label textAlign={"center !important"}>{"Email Required*"}</Label>
                        } else {
                            return !isValid ? <Label>{"Valid Email Required*"}</Label> : ""
                        }
                    })()}
                    {
                        !validRecaptcha? <Label textAlign={"center !important"} fontSize={'11px'}>Sorry, Google reCAPTCHA has denied your submission. If you believe this is an error, please contact marketingteam@realchemistry.com for assistance.</Label> : ''
                    }
                </GetTouch>
            </CardInner>
        </SubscribeCardWrap>
    );
}

export default SubscribeCard;