import styled from 'styled-components';

import {
  TitleLg,
  BodyRg,
  BodySm,
} from "components/elements";
import {
  sizeMobileLg, sizeWideScreen
} from "components/devices";



export const ArticleInfoWrap = styled.div`
    padding: 120px 0 0 0;
    position: relative;
    text-align: center;
    ${TitleLg} {
        margin: 25px 0 35px;
        line-height: 1.1;
        text-transform: unset;
        ${sizeMobileLg(`
            font-size: 30px;
        `)} 

        
    }
    ${BodyRg} {
     margin-bottom: 10px; 
     
     ${sizeWideScreen(`
        font-size: 0.7vw; 
    `)}
    }
    ${sizeMobileLg(`
        padding-top: 90px;
    `)} 


    ${BodySm} {
        ${sizeWideScreen(`
            font-size: 0.6vw; 
        `)}
    }
`;

export const ArticleInfo = styled.div`
    max-width: 1460px;
    margin: 0 auto 50px;
    padding: 0 40px;
    ${sizeMobileLg(`
        padding: 0 25px;
    `)} 
`;

export const AuthorSocial = styled.div`
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    border-top: 1px solid #707070;
    border-bottom: 1px solid #707070;
    ${sizeMobileLg(`
        flex-wrap: wrap;
    `)} 
    button {
        height: 80px;
        text-align: center;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        width: 100%;
        border-right: 1px solid #707070 !important;
        position: relative;
        overflow: hidden;
        &:focus {
            outline: none;
        }
        ${sizeMobileLg(`
            height: 60px;
            border-bottom: 1px solid #707070 !important;
            border-right: none !important;
        `)}
        &:last-child {
            border-right: none !important;
            ${sizeMobileLg(`
                border-bottom: none !important;
            `)}
        }
        ${BodyRg} {
            margin-bottom: 0;
            transition: 0.9s all ease;
        }
        &:before {
            content: "";
            background-color: ${({ backgroundColor }) => backgroundColor || "#D12FFF"};
            width: 100%;
            height: 100%;
            position: absolute;
            left: -150%;
            top: 0;
            z-index: -1;
            transition: 0.5s all ease;
        }
        &:hover {
            ${BodyRg} {
                color: #fff;
            }
            &:before {
                left: 0;
            }
        }
    }
`;
