import React, {useEffect} from 'react';
import {
    WideScreenContainer,
} from "components/elements"

import ScamAlertDetail from "./ScamAlertDetail";

import {
    ScamAlertPageWrap,
} from "./elements";

import { Helmet } from "react-helmet";
import LoadingHoc from "../../hoc/LoadingHOC";


function ScamAlert(props) {
    const {setLoading} = props;
    useEffect(()=>{
        setLoading(false)
        //eslint-disable-next-line
    },[])
    // let date = new Date();

    // let todayDate = date.toLocaleString('default', { month: 'long' }) + " " + date.getDate() + ", " + date.getFullYear();
    return (
        <ScamAlertPageWrap className="pageWrapper">
            <Helmet>
                <title>{"ALERT: ONLINE RECRUITING SCAMS | Real Chemistry"}</title>
                <link rel="canonical" href={window.location.pathname} />
                <meta name="description" content={"ALERT: ONLINE RECRUITING SCAMS"} />
                <meta name="og:description" content={"ALERT: ONLINE RECRUITING SCAMS"} />
                <meta name="og:title" content={"ALERT: ONLINE RECRUITING SCAMS | Real Chemistry"} />
                <meta name="og:url" content={window.location.protocol + "//" + window.location.host + window.location.pathname} />
                <meta name="og:type" content="website" />
                <meta name="og:site_name" content="Real Chemistry" />

            </Helmet>
            <WideScreenContainer style={{ paddingTop: "45px" }} borderTop="1px solid #707070" borderLeft="1px solid #707070" borderRight="1px solid #707070">
              <ScamAlertDetail />
            </WideScreenContainer>  
        </ScamAlertPageWrap>
    );
}

export default LoadingHoc(ScamAlert);
