import React, {useEffect,useRef,useState} from "react";
import {
    AudioBlock,
    PlayerActions,
    AudioRow,
    AudioSlider,
    PlayerTime, SkipButton, PlayAudioBtn
} from "./elements";
import SkipBackwardIcon from "../../assets/icons/SkipBackwardIcon";
import SkipForwardIcon from "../../assets/icons/SkipForwardIcon";
import AudioPlayIcon from "../../assets/icons/PlayAudioIcon";
import PauseAudioIcon from "../../assets/icons/PauseAudioIcon";
// import SkipBackwardIcon from "../../assets/icons/SkipBackwardIcon";
// import SkipForwardIcon from "../../assets/icons/SkipForwardIcon";
// import AudioPlayIcon from "../../assets/icons/PlayAudioIcon";
function AudioPlayer({source}) {
    /* Implementation of the presentation of the audio player */
    
    const [playState,setPlayState] = useState('play')
    const playIconContainer = useRef(null)
    const audioPlayerContainer = useRef(null)
    const seekSlider = useRef(null)
    const fwdBtn = useRef(null)
    const backBtn = useRef(null)

    const audio = useRef(null);
    const durationContainer = useRef(null);
    const currentTimeContainer = useRef(null);
    let raf = null;



    const calculateTime = (secs) => {
        const minutes = Math.floor(secs / 60);
        const seconds = Math.floor(secs % 60);
        const returnedSeconds = seconds < 10 ? `0${seconds}` : `${seconds}`;
        return `${minutes}:${returnedSeconds}`;
    }

    const displayDuration = () => {
        durationContainer.current.textContent = calculateTime(audio.current.duration);
    }

    const setSliderMax = () => {
        seekSlider.current.max = Math.floor(audio.current.duration);
    }

    // const displayBufferedAmount = () => {
    //     audio.current.onprogress = function (){
    //         const bufferedAmount = Math.floor(audio.current.buffered.end(audio.current.buffered.length - 1));
    //         audioPlayerContainer.current.style.setProperty('--buffered-width', `${(bufferedAmount / seekSlider.current.max) * 100}%`);
    //     }
    //
    // }
  

    const whilePlaying = () => {
        seekSlider.current.value = Math.floor(audio.current.currentTime);
        currentTimeContainer.current.textContent = calculateTime(seekSlider.current.value);
        audioPlayerContainer.current.style.setProperty('--seek-before-width', `${seekSlider.current.value / seekSlider.current.max * 100}%`);
        raf = requestAnimationFrame(whilePlaying);
    }


    const showRangeProgress = (rangeInput) => {
        if(rangeInput === seekSlider) audioPlayerContainer.current.style.setProperty('--seek-before-width', rangeInput.value / rangeInput.max * 100 + '%');
        else audioPlayerContainer.current.style.setProperty('--volume-before-width', rangeInput.value / rangeInput.max * 100 + '%');
    }


    useEffect(()=>{
        if(playIconContainer && audio) {
         let playState = 'play'
            playIconContainer.current.addEventListener('click', () => {
                if (playState === 'play') {
                    audio.current.play();

                    requestAnimationFrame(whilePlaying);
                    playState = 'pause'
                    setPlayState('pause')
                } else {
                    audio.current.pause();

                    cancelAnimationFrame(raf);
                   playState = 'play'
                    setPlayState('play')
                }
            });
        }
       
        return ()=>{
            // eslint-disable-next-line 
            playIconContainer.current.removeEventListener('click',()=>{})
        }
        // eslint-disable-next-line 
    },[playIconContainer,audio])
    
    
  useEffect(()=>{
      seekSlider.current.value = 0;
      seekSlider.current.addEventListener('input', (e) => {
          showRangeProgress(e.target);
      });
      
      if (audio.current.readyState > 0) {
          displayDuration();
          setSliderMax();
          //displayBufferedAmount();
      } else {
          audio.current.addEventListener('loadedmetadata', () => {
              displayDuration();
              setSliderMax();
             // displayBufferedAmount();
          });
      }

     // audio.current.addEventListener('progress', displayBufferedAmount);

      seekSlider.current.addEventListener('input', () => {
          currentTimeContainer.current.textContent = calculateTime(seekSlider.current.value);
          if(!audio.current.paused) {
              cancelAnimationFrame(raf);
          }
      });

      seekSlider.current.addEventListener('change', () => {
          audio.current.currentTime = seekSlider.current.value;
          if(!audio.current.paused) {
              requestAnimationFrame(whilePlaying);
          }
      });
      fwdBtn.current.addEventListener('click', () => {
          console.log()
          audio.current.currentTime = audio.current.currentTime  + 15;
          if(!audio.current.paused) {
              requestAnimationFrame(whilePlaying);
          }
      });
      backBtn.current.addEventListener('click', () => {
          audio.current.currentTime = audio.current.currentTime  - 15;
          if(!audio.current.paused) {
              requestAnimationFrame(whilePlaying);
          }
      });
      // eslint-disable-next-line 
  },[seekSlider,audio,fwdBtn,backBtn])

   
    

    return (
        <AudioBlock>
            <div ref={audioPlayerContainer} id="audio-player-container">
                <audio ref={audio} src={source} preload="metadata" loop/>
                <PlayerActions>
                    <SkipButton ref={backBtn}>
                        <SkipBackwardIcon />
                    </SkipButton>
                    <PlayAudioBtn ref={playIconContainer} id={'play-icon'}>
                        {
                            playState ==='play'?
                                <PauseAudioIcon /> : <AudioPlayIcon  />
                        }
                    </PlayAudioBtn>
                    <SkipButton ref={fwdBtn}>
                        <SkipForwardIcon />
                    </SkipButton>
                </PlayerActions>
                <AudioRow>
                    <PlayerTime ref={currentTimeContainer} id="current-time" className="time" >0:00</PlayerTime>
                    <AudioSlider ref={seekSlider} type="range" id="seek-slider" max="100"  />
                    <PlayerTime ref={durationContainer} id="duration" className="time">0:00</PlayerTime>
                </AudioRow>
            </div>
        </AudioBlock>
)
}

export default AudioPlayer;
