import styled, { css } from 'styled-components';

import {
    sizeWideScreen,
    sizeTabletLg,
    sizeDesktopMd
} from "components/devices";
import { InputWrap } from 'components/SubscribeCard';

export const ArticlesPageWrap = styled.div`
position: relative;
`;


export const BlogSubscribe = styled.div`
    position: fixed;
    left: -40px;
    top: 60%;
    transform: translateY(-50%);
    transition: .4s all ease;
    opacity: 0;
    z-index: -1;
    &.showSubcribe {
        opacity: 1;
        z-index: 99;
        left: 30px;
    }
  ${sizeDesktopMd(`
    top: 55%;
  `)}
    ${sizeTabletLg(`
        position: static;
        transform: none;
        padding: 0;
        opacity: 1;
        z-index: 99;
        padding:20px 0;
    `)}


    ${sizeWideScreen(css`
        ${InputWrap} {
            margin-top: 25px;
        }
    `)}
`;

export const SubscibeClose = styled.button`
    background-color: transparent;
    border: none;
    box-shadow: none;
    outline: none;
    position: absolute;
    left: 0;
    top: 15px;
    cursor: pointer;
    &:focus {
        outline: none;
        border: none;
        box-shadow: none;
    }
    svg {
        width: 45px;
        height: 45px;
        ${sizeWideScreen(css`
            width: 80px;
            height: 80px;
        `)}
    }
    ${sizeTabletLg(`
        display: none;
    `)}
`;
