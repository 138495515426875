import React from 'react'
import styled from 'styled-components'
import {BodyRg, TitleSm, TitleXl} from "./elements";
import BackArrowSvg from "../assets/icons/BackArrowSvg";
import {Link} from "react-router-dom";
import {sizeLaptopLg, sizeMobileLg, sizeTabletLg} from "./devices";


export const LocationImgWrap = styled.div`
  height: 298px;
  ${sizeTabletLg(`
    height: auto;
  `)}
  ${BodyRg} {
    display: none;
    padding: 35px 35px 0 35px;
    font-size: 24px;
    line-height: 28px;
    ${sizeMobileLg(`
        padding: 20px 5px 0;
        font-size: 16px;
        line-height: 19px;
  `)}
  }
`;
export const LocationImg = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;
export const LocationCard = styled(Link)`
  padding: 0;
  background: #F0F0F0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 553px;
  height: auto;

    ${sizeTabletLg(`
        height: auto;
        min-height: auto;

    `)}

  ${TitleXl}{
    font-size: 76px;
    line-height: 91px;
    font-weight: 900;
    ${sizeMobileLg(`
      font-size: 20px;
      line-height: 24px;
    `)}
  }

  ${BodyRg} {
    font-size: 24px;
    line-height: 28px;
    ${sizeMobileLg(`
        font-size: 16px;
        line-height: 19px;
  `)}
  }

  ${TitleXl},
  ${TitleSm},
  ${BodyRg} {
    color: #000;
  }
  .location-title {
    flex: 0 0 100%;
  }
  
  svg {
    transition: 0.3s all ease;
    transform: rotate(180deg);
    width: 62px;
    height: 35px;
    top: 5px;
    left: 0;
    position: relative;
    path, line {
      stroke: #D12FFF;
    }
    ${sizeMobileLg(`
         width: 36px;
         height: 27px;

  `)}
   
  }

  &:hover {
    svg {
      width: 79px;
      height: 35px;
      left: -15px;
      // top: 0;
    }
    ${sizeLaptopLg(`
     height: auto;
     min-height: 680px;
 `)}
  ${sizeTabletLg(`
    height: auto;
    min-height: auto;
  `)}
 
    ${BodyRg} {
      display: block;
    }
    .bodyCopy {
      padding:  25px;
      max-width: 100%;
      font-size: 20px;
      line-height: 24px;
      ${sizeMobileLg(`
        padding: 5px;
        font-size: 16px;
        line-height: 19px;
      `)}
    }
    ${LocationImg} {
      display: none;
    }
    .location-title,
    .count {
      display: none;
      transition: 0.3s all ease;
  }
  }
`;
export const LocationDarkHead = styled.div`

`;
export const LocationDarkFooter = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  flex-wrap: wrap;
  padding: 35px 35px;
  ${TitleSm}{
    font-size: 24px !important;
    line-height: 28px !important;
  }
  ${BodyRg} {
      font-size: 20px;
      line-height: 24px;
      font-weight: 400;
  }
  ${sizeMobileLg(`
  ${BodyRg} {
    font-size: 16px;
    line-height: 19px;
  }
  ${TitleSm}{
    margin: 0 0 5px;
  }
  `)}
}
  ${sizeMobileLg(
    `
    padding: 0 5px 35px;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    `
  )}
`;
export const LocationDarkCol = styled.div`
  width: calc(100% - 70px);

  &.arrow-col {
    width: 62px;
    height: 32px;

    
   
  }
  &.single-col {
    max-width: 100%;
    width: 100%;
  }
 
`;

function LocationCardDark({jobType, jobDesc, count, link, locImg}) {


    return (
        <LocationCard to={count > 0 ? link : "#"} className={count > 0 ? "" : "pointerNone"}>
            <LocationDarkHead>
                <LocationImgWrap>
                    <LocationImg src={locImg ? locImg : "images/loc-img.png"} alt={jobType} />
                    <BodyRg className='bodyCopy'>
                        {
                            jobDesc ? jobDesc
                                : ""
                        }
                    </BodyRg>
                </LocationImgWrap>
            </LocationDarkHead>
            <LocationDarkFooter>
                <TitleSm className={"location-title"} margin={"0 0 15px 0"}>{jobType}</TitleSm>
                <LocationDarkCol  className={count > 0 ? '' :  'single-col'}>
                    { count > 0 ?  <TitleXl className={'count'} margin={"0 0 5px 0"}>
                        {count}
                    </TitleXl> : "" }
                    {
                        count > 0 ?
                            <BodyRg textTransform={"uppercase"}>opportunities</BodyRg> :
                            <BodyRg textTransform={"uppercase"}>No current opportunities in this location</BodyRg>
                    }
                </LocationDarkCol>
                {
                    count > 0 ? <LocationDarkCol className={'arrow-col'}>
                        <BackArrowSvg color={"#fff"}/>
                    </LocationDarkCol> : ""
                }
            </LocationDarkFooter>
        </LocationCard>
    )
}

export default LocationCardDark;
