import styled from 'styled-components';

import {
    BodyRg,
    BodySm
} from "components/elements";
import {
    sizeTabletLg, sizeWideScreen
} from "components/devices";


// CSS Start
export const CopyrightsWrap = styled.div`
    padding: 100px 25px;
    text-align: left;
    ${sizeTabletLg(`
        text-align: center;
    `)}
    ${BodyRg} {
        font-size: 24px;
        ${sizeTabletLg(`
            font-size: 16px;
        `)}

    ${sizeWideScreen(`
      font-size: 0.85vw;
    `)}
    }
    ${BodySm} {
        margin-top: 45px;
        ${sizeTabletLg(`
            margin-top: 25px;
        `)}
        ${sizeWideScreen(`
            font-size: 0.7vw;
        `)}
    }
    ${sizeTabletLg(`
        padding: 65px 25px;
    `)}
    a {
        line-height: 1.3;
        color:#D12FFF;
        border-bottom: 1.3px solid #D12FFF;
        word-break: break-word;
    }
`;
export const CopyContainer = styled.div`
    max-width: 900px;
    margin: 0 auto;

    ${sizeWideScreen(`
      max-width: 100%;
      padding: 0 35px;
    `)}
`;
