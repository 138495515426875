import React from 'react'


function BlogIcon() {

    return (
        <svg width="32" height="33" viewBox="0 0 32 33" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M24.99 21.5145L19.8046 26.6999L18.4092 26.6811L2.85286 11.1247L2.834 9.72935L8.01945 4.5439H9.43366L24.99 20.1003V21.5145ZM19.1163 24.5597L22.8876 20.7885L8.74541 6.64637L4.97418 10.4176L19.1163 24.5597Z"
                fill="black"/>
            <path
                d="M26.4556 26.9783L25.2866 28.1474L18.932 26.9595L18.3899 25.286L23.5754 20.1005L25.2677 20.6238L26.4556 26.9783ZM21.1665 25.3379L24.2212 25.913L23.6461 22.8583L21.1665 25.3379Z"
                fill="black"/>
            <path d="M6.13501 13.0299L11.3016 7.86331L12.7158 9.27753L7.54922 14.4441L6.13501 13.0299Z" fill="black"/>
        </svg>


    )
}

export default BlogIcon;
