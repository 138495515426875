import React from 'react'

function BackArrowSvg({ className }) {

    return (
        <svg
            className={className}
            xmlns="http://www.w3.org/2000/svg"
            width="86"
            height="47.114"
            viewBox="0 0 86 47.114"
        >
            <g
                id="M_Arrow"
                data-name="M Arrow"
                transform="translate(86 45.7) rotate(180)"
            >
                <line
                    id="Line_18"
                    data-name="Line 18"
                    x2="83.905"
                    transform="translate(0 21.967)"
                    fill="none"
                    stroke="#D12FFF"
                    strokeWidth="4"
                />
                <path
                    id="Path_592"
                    data-name="Path 592"
                    d="M0,0,22.143,22.143,0,44.286"
                    transform="translate(61.857)"
                    fill="none"
                    stroke="#D12FFF"
                    strokeLinejoin="round"
                    strokeWidth="4"
                />
            </g>
        </svg>
    )
}

export default BackArrowSvg;
