import React, { useEffect } from 'react';
import {withRouter} from "react-router-dom"
import {TitleXl, TitleMd} from "components/elements";
import { Container, ThumbnailContainer, Thumbnail, CopyContainer, Overlay } from './elements';
import ViewAll from 'components/ViewAll';

function CaseStudiesContainer({caseStudies, filteredStudies}) {

    const [equalsThree, setEqualsThere] = React.useState(false);

    useEffect(() => {
        if(caseStudies?.items?.length === 3 || filteredStudies?.length === 3 ){
            setEqualsThere(true)
        }
    },[caseStudies, filteredStudies]) 


    return(
        <Container>
            <TitleXl fontWeight={'900'} fontSize={"48px"}>
                {caseStudies?.title}
            </TitleXl>
            <ThumbnailContainer>
                {caseStudies?.items?.slice(0,4).map((cs, key) => (
                    <Thumbnail key={key} flex={equalsThree ? '1 0 30%' : '1 0 48%'} backgroundImage={cs.thumbnail}>
                        <Overlay>
                        <CopyContainer fontSize={equalsThree ? '24px' : '28px'}>
                            <TitleMd color="#ffffff">
                                {cs.client } 
                            </TitleMd>
                            <TitleXl fontWeight="900" lineHeight="1.2" fontSize={equalsThree ? '24px' : '28px '} >
                                    {cs.thumbnailTitle}
                            </TitleXl>
                        </CopyContainer>
                        <ViewAll title={cs.ctaCopy ? cs.ctaCopy : 'VIEW CASE STUDY'} link={cs.ctaLink ? cs.ctaLink : `/case-study${cs.slug }`} target={ cs?.ctaLink ? '_blank' : undefined}  borderColor="#fff" titleColor="#fff" hoverBackground="#fff" svgColor="#fff" width="245px" height="38px" />
                       </Overlay>
                    </Thumbnail>
                ))}
                {filteredStudies?.slice(0,3).map((cs, key) => (
                    <Thumbnail key={key} flex={equalsThree ? '1 0 30%' : '1 0 48%'} backgroundImage={cs.fields.thumbnail.fields.file.url}>
                        <Overlay>
                        <CopyContainer fontSize={equalsThree ? '24px' : '28px'}>
                            <TitleMd color="#ffffff">
                                {cs.fields.client} 
                            </TitleMd>
                            <TitleXl fontWeight="900" lineHeight="1.2" fontSize={equalsThree ? '24px' : '28px'}>
                                    {cs.fields.thumbnailTitle}
                            </TitleXl>
                        </CopyContainer>
                        <ViewAll title={'VIEW CASE STUDY'} link={cs.fields.ctaLink ? cs.fields.ctaLink : `/case-study${cs.fields.slug }`}  target={ cs?.fields?.ctaLink ? '_blank' : undefined}  borderColor="#fff" titleColor="#fff" hoverBackground="#fff" svgColor="#fff" width="245px" height="38px" />
                       </Overlay>
                    </Thumbnail>
                ))}
            </ThumbnailContainer>
            
        </Container>
        )
    }
    
    export default withRouter(CaseStudiesContainer);