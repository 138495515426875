import styled from 'styled-components';
import {
    BodySm
} from "components/elements";

import {
    sizeLaptop,
    sizeTabletLg, sizeWideScreen
} from "components/devices";


// Work Info CSS Start
export const InfoSectionWrap = styled.div`
    padding: 56px 104px;
    border-top: ${({ borderTop }) => borderTop || "none"};
    border-right: ${({ borderRight }) => borderRight || "none"};
    border-bottom: ${({ borderBottom }) => borderBottom || "none"};
    border-left: ${({ borderLeft }) => borderLeft || "none"};
  margin-bottom: -1px;
      p, h5 {
        margin-bottom: 16px;
        &:last-child {
          margin-bottom: 0;
        }
      }
      p {
        &:empty {
          display: none;
        }
      }
    ${BodySm} {
        margin-bottom: 30px;
        ${sizeWideScreen(`
            font-size: 0.7vw;
        `)}
    }
    ${sizeLaptop(`
        padding-left: 25px;
        padding-right: 25px;
    `)}
    ${sizeTabletLg(`
        padding: 35px 25px 35px 25px;
    `)}


    /* Wide Screen CSS */
    ${sizeWideScreen(`
        border-left: 1px solid #707070;
        border-right: 1px solid #707070;
    `)}
`;
export const WorkInfoRow = styled.div`
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
            justify-content: space-between;
  margin-left: -15px;
  margin-right: -15px;
  box-sizing: border-box;
    ${sizeTabletLg(`
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
        margin-left: 0;
  margin-right: 0;
    `)}
`;
export const WorkInfoCol = styled.div`
    width: 50%;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 50%;
            flex: 0 0 50%;
  padding-left: 15px;
  padding-right: 15px;
  box-sizing: border-box;
    ${sizeTabletLg(`
    padding-left: 0;
  padding-right: 0;
        width: 100%;
        -webkit-box-flex: 0;
        -ms-flex: 0 0 100%;
                flex: 0 0 100%;
        margin-bottom: 15px;
    `)}

`;
