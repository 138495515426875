import React from 'react'

import {
    WideScreenContainer, 
} from 'components/elements'
import ContactUsForm from 'components/ContactUsForm'
import LoadingHoc from 'hoc/LoadingHOC'
import FooterAnimation from 'components/FooterAnimation'
import { ContactUsWrapper, ContactUsFormWrapper } from './elements';
import PurpleVerticalHeader from 'components/PurpleVerticalHeader';
import {r3cPageContent} from 'constants/index'
import {getImageDimensions} from 'generalHelpers'
import {Helmet} from "react-helmet";
import LocationTabs from 'components/LocationTabs'

function ContactUsPage(props) {

	const {setLoading} = props	
	
	const [seoData, setSeoData] = React.useState({})
	const [locationDataUs, setLocationDataUs] = React.useState([]);
	const [locationDataEu, setLocationDataEu] = React.useState([]);
	const [locationDataCa, setLocationDataCa] = React.useState([]);


	React.useEffect(() => {
		fetch(r3cPageContent + '?route=/contact').then((response) => response.json()).then((page) => {
			let seo = page.seo;
			let image = page?.seo?.image?.split('.')

			page?.seo?.image ? getImageDimensions(page?.seo?.image, function (width, height) {
				setSeoData({
					...seo,
					mimeType: image[image.length - 1],
					seoImageHeight: height,
					seoImageWidth: width
				})

			}) : setSeoData(seo)

			
			page.contents.forEach((data) => {
			if(data.country === "United States"){
					setLocationDataUs(locationDataUs => [...locationDataUs, data])
				}else if(data.country === "Europe"){
					setLocationDataEu(locationDataEu => [...locationDataEu, data])
				}else {
					setLocationDataCa(locationDataCa => [...locationDataCa, data])
				}
			return;
		})

			setLoading(false)
		})
		// eslint-disable-next-line
	}, [])



	return (

		<ContactUsWrapper >
			<Helmet>
				<title>{seoData.title}</title>
				<link rel="canonical" href={window.location.pathname}/>
				<meta name="description" content={seoData.description}/>
				<meta name="og:description" content={seoData.description}/>
				<meta name="og:title" content={seoData.title}/>
				<meta name="og:url" content={window.location.protocol + "//" + window.location.host + window.location.pathname}/>
				<meta name="og:type" content="website"/>
				<meta name="og:site_name" content="Real Chemistry"/>
				<meta name="og:image" content={window.location.protocol + seoData.image}/>
				<meta name="og:image:type" content={"image/" + seoData?.mimeType}/>
				<meta name="og:image:width" content={seoData?.seoImageWidth}/>
				<meta name="og:image:height" content={seoData?.seoImageHeight}/>
			</Helmet>
			<WideScreenContainer> 
                <PurpleVerticalHeader/>
                <ContactUsFormWrapper>
                    <ContactUsForm/>
                </ContactUsFormWrapper>
			</WideScreenContainer>
			<WideScreenContainer style={{overflow: 'hidden'}}>
				{locationDataUs && locationDataEu && locationDataCa && (
				<LocationTabs locationDataUs={locationDataUs} locationDataEu={locationDataEu} locationDataCa={locationDataCa}/>
				)}
			</WideScreenContainer>
			<FooterAnimation
				svgDesktop="/images/purple-footer.svg"
				svgMobile="/images/purple-footer-mbl.svg"
				borderTop="none"
			/>
		</ContactUsWrapper>
	)
}


export default LoadingHoc(ContactUsPage);
