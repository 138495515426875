import React from 'react';

import {
	TitleLg,
	BodyRg,
	BodySm
} from "components/elements";
import {
	JobDetailCardWrap,
	JobDetailCardContainer, 
	ViewAllContainer
} from "./elements";
import moment from "moment";
import ViewAll from "components/ViewAll";
// import Badge from "../FilterBadge"

function JobDetailCard({ capability, location, locations, jobTitle, date, link, target }) {
	const [otherLocations, setOtherLocations] = React.useState(null)
	React.useEffect(() => {
		if (locations) {
			let nl = []
			let a = locations.split(';')

			for (let i = 0; i < a.length; i++) {
				if (a[i] !== location)
					nl.push(a[i])
			}
			setOtherLocations(nl.join(' | '))
		}
	}, [location, locations])

	return (
		<JobDetailCardWrap>
			<JobDetailCardContainer>
				<BodyRg className="job details">{capability} | {location}</BodyRg>
				<TitleLg className="job title">{jobTitle}</TitleLg>

				{otherLocations ? <BodyRg id="jobLocations" className="job locations">OTHER LOCATIONS:&nbsp;&nbsp;{otherLocations}</BodyRg> : ''}

				<BodySm className="job posting date" textTransform="uppercase">Posted: {moment(date).format("MMMM D, YYYY")}</BodySm>
				
				<ViewAllContainer>
					<ViewAll              
						title="APPLY NOW"
						textTransform="uppercase"
						borderTop="none"
						borderColor="#fff"
						titleColor="#fff"
						hoverBackground="#fff"
						svgColor="#fff"
						width="200px"
						height="38px"
						link={link}
						target={target}
						className="btn-apply-now"
					/>
				</ViewAllContainer>
			

			</JobDetailCardContainer>
		</JobDetailCardWrap >
	)
}

export default JobDetailCard;
