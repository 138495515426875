import React, { useEffect, useRef, useState } from 'react';
import { BodySm, TitleXl, TitleSm, Label } from "components/elements";
import { ContactUsFormWrap, InputSignUp, InputContainer, CtaContainer, SubmitLink, SubmitWrap, FormContainer, TitleXlContainer, BodySmContainer } from './elements';
import Checkbox from 'components/Checkbox';
import Select from 'components/SelectField';
import useFormManager from '../../hooks/useFormManager';
import { recaptchaHelper } from "../../generalHelpers";
import { withRouter } from "react-router-dom"
import { RightArrowWrap } from 'components/ViewAll';


const OPTIONAL = ["additionalInfo", "test"]

function ContactUsForm() {

  const [validRecaptcha, setValidRecaptcha] = useState(true)
  const firstInputRef = useRef(null);
  const inputElLastName = useRef(null);
  const inputElEmail = useRef(null);
  const contactFormRef = useRef(null);
  const [isSelected, setSelected] = React.useState(false);
  const [focus, setFocus] = React.useState(false)

  const {
    handleOnInputChange,
    handleSubmit,
    setIsSubmitted,
    isSubmitted,
    subscriptionFormData
  } = useFormManager({
    data: {
      firstName: "",
      lastName: "",
      email: "",
      knowAbout: "",
      additionalInfo: "",
      test: "",
      from: 'contact-us'
    },
    errors: {
      firstName: "",
      lastName: "",
      email: "",
      knowAbout: "",

    }
  }, OPTIONAL);


  const handleSubmitClick = async () => {
    let proceed = await recaptchaHelper();

    if (proceed) {
      handleSubmit('Contact')
    } else {
      setValidRecaptcha(false)
    }

  }


  useEffect(() => {
    if (
      (subscriptionFormData.errors.email.length > 0
        && subscriptionFormData.errors.lastName.length > 0
        && subscriptionFormData.errors.firstName.length > 0)
      || (subscriptionFormData.errors.email.length > 0
        && subscriptionFormData.errors.lastName.length === 0
        && subscriptionFormData.errors.firstName.length > 0)
    ) {
      firstInputRef.current.focus()
    }
    if (subscriptionFormData.errors.email.length > 0
      && subscriptionFormData.errors.lastName.length > 0
      && subscriptionFormData.errors.firstName.length === 0
      && !(firstInputRef.current === document.activeElement)
    ) {
      inputElLastName.current.focus()
    } else if (
      //eslint-disable-next-line
      subscriptionFormData.errors.email.length > 0 &&
      (!(firstInputRef.current === document.activeElement || inputElLastName.current === document.activeElement))
    ) {
      inputElEmail.current.focus()
    }


    //eslint-disable-next-line
  }, [
    subscriptionFormData.errors.email,
    subscriptionFormData.errors.firstName,
  ])

  useEffect(() => {
    window.scrollTo(0, 100)
  }, [isSubmitted])



  return (
    <ContactUsFormWrap>
      {isSubmitted && (
        <>
          <TitleXlContainer>
            <TitleXl color="000" fontWeight="900">
              Thank you!
            </TitleXl>
          </TitleXlContainer>
          <BodySmContainer>
            <BodySm>
              We’ll be in touch soon. While you wait, why not explore our <a href={'https://go.realchemistry.com/l/603341/2024-07-03/7h5p7'} className="magenta">latest news</a> or check out the <a target="_blank" rel="noopener noreferrer" href={'https://go.realchemistry.com/l/603341/2024-07-03/7h5pb'} className="magenta">Real Chemistry Podcast</a>.
            </BodySm>
          </BodySmContainer>
        </>
      )}
      {!isSubmitted && (
        <>
          <FormContainer className="contact us" ref={contactFormRef} action="">
            <BodySm>*Required</BodySm>
            <InputContainer>
              <TitleSm className={subscriptionFormData.errors.firstName.length > 0 ? 'error' : null}>*First Name</TitleSm>
              <InputSignUp
                placeholder="Enter your first name"
                spellCheck={'false'}
                required={subscriptionFormData.errors.firstName.length > 0}
                name="firstName"
                value={subscriptionFormData.data.firstName}
                ref={firstInputRef}
                onChange={handleOnInputChange}
              />
              {subscriptionFormData.errors.firstName.length > 0 ?
                <Label fontSize="16px" lineHeight="19px" color="#FF0000" textTransform="none" >First name is required</Label>
                : null
              }
            </InputContainer>
            <InputContainer>
              <TitleSm className={subscriptionFormData.errors.lastName.length > 0 ? 'error' : null}>*Last Name</TitleSm>
              <InputSignUp
                spellCheck={'false'}
                placeholder="Enter your last name"
                name="lastName"
                type="text"
                required={subscriptionFormData.errors.lastName.length > 0}
                ref={inputElLastName}
                value={subscriptionFormData.data.lastName}
                onChange={handleOnInputChange}
              />
              {subscriptionFormData.errors.lastName.length > 0 ?
                <Label fontSize="16px" lineHeight="19px" color="#FF0000" textTransform="none">Last name is required</Label>
                : null
              }
            </InputContainer>
            <InputContainer>
              <TitleSm className={subscriptionFormData.errors.email === 'Required Field' || subscriptionFormData.errors.email.length > 0 ? 'error' : null}>*Email</TitleSm>
              <InputSignUp
                spellCheck={'false'}
                placeholder="Enter your email"
                name="email"
                required={subscriptionFormData.errors.email.length > 0}
                ref={inputElEmail}
                type="mail"
                value={subscriptionFormData.data.email}
                onChange={handleOnInputChange} />
              {subscriptionFormData.errors.email === 'Please enter valid email' ?
                <Label fontSize="16px" lineHeight="19px" color="#FF0000" textTransform="none">Valid email is required*</Label>
                : subscriptionFormData.errors.email === 'Required Field' || subscriptionFormData.errors.email.length > 0 ?
                  <Label fontSize="16px" lineHeight="19px" color="#FF0000" textTransform="none">Email is required</Label> : null
              }

            </InputContainer>

            <InputContainer>
              <TitleSm className={subscriptionFormData.errors.knowAbout.length > 0 ? 'error' : null}>*I’d like to learn more about</TitleSm>
              <Select
                isSelected={isSelected}
                required={subscriptionFormData.errors.knowAbout.length > 0}
                name="knowAbout"
                focus={focus}
                change={(e) => {
                  setSelected(true);
                  handleOnInputChange(e);
                }}
                click={() => setFocus(!focus)}
                rotateIcon="/images/select-dropdown-purple.svg"
                backgroundImage="/images/select-purple-rotated.png"
                options={
                  [
                    {
                      label: "Select one",
                      value: ""
                    },
                    {
                      label: "Career opportunities",
                      value: "Career opportunities"
                    },
                    {
                      label: "Exploring business with Real Chemistry",
                      value: "Exploring business with Real Chemistry"
                    },
                    {
                      label: "Employment verification",
                      value: "Employment verification"
                    },
                    {
                      label: "Something else",
                      value: "Something else"
                    }

                  ]
                }>
              </Select>
              {subscriptionFormData.errors.knowAbout.length > 0 ?
                <Label fontSize="16px" lineHeight="19px" color="#FF0000" textTransform="none">Selection is required</Label>
                : null
              }
            </InputContainer>
            <InputContainer className='furtherInfo'>
              <TitleSm>Anything else you want to know?</TitleSm>
              <InputSignUp
                placeholder="We’re excited to hear from you"
                name="additionalInfo"
                type="text"
                onChange={handleOnInputChange}
                value={subscriptionFormData.data.additionalInfo}
              />
            </InputContainer>
            <InputContainer>
              <Checkbox handleCheck={handleOnInputChange} name="test" id="test" checkDotColor="#FFF" circleColor="#D12FFF" className="square">
                I agree to receive relevant emails from Real Chemistry, including exclusive event
                invitations and a monthly digest of key healthcare news.
              </Checkbox>

            </InputContainer>


            {
              !validRecaptcha ? <Label color={"#FF0000"} padding="30px 0 0" fontSize="16px" lineHeight="19px" textTransform="none">Sorry, Google reCAPTCHA has denied your submission. If you believe this is an error, please contact marketingteam@realchemistry.com for assistance.</Label> : ''
            }

          </FormContainer>
          <CtaContainer>
            <SubmitWrap borderColor="#000" borderColorHover="#fff" hoverBackground="#000" width="160px" height="38px">
              <SubmitLink titleColor="#000" onClick={() => {
                window.dataLayer = window.dataLayer || [];
                window.dataLayer.push({
                  'event': 'e_form_submit04'
                }
                );
                handleSubmitClick()

              }}>
                <TitleSm>SUBMIT</TitleSm>
              </SubmitLink>
              <RightArrowWrap borderColor="#000" svgColor="#000" >
                <svg width="23" height="17" viewBox="0 0 23 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M20 9L0 9L20 9Z" fill="white" />
                  <path d="M20 9L0 9" stroke="white" stroke-width="3" />
                  <path d="M13.4141 2L20.4246 8.92499L13.4141 15.85" stroke="white" stroke-width="3" stroke-miterlimit="10" />
                </svg>
              </RightArrowWrap>
            </SubmitWrap>
          </CtaContainer>
        </>
      )}

    </ContactUsFormWrap>

  )
}

export default withRouter(ContactUsForm);


