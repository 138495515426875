import React from 'react'

import
{
	GridSectionWrap,
	GridRow,
	GridCol
} from './elements'
import LocationCard from './LocationCard'


function LocationGrid({ data, activeTab }){



	return (
		<GridSectionWrap className={activeTab === 0 ? 'show' : ''}>
			<GridRow >
                {data && data.map((location) => (
					<GridCol>
						<LocationCard data={location}/>
					</GridCol>
				))}
			</GridRow>
		</GridSectionWrap>
	)

  }

export default LocationGrid

