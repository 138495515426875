import React from 'react';
import styled from 'styled-components';
import {
  TitleSm,
} from "components/elements";
import { Link } from 'react-router-dom';



export const RightArrowWrap = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border-left: 2px solid;
  padding: 0 3% 0 3%;
  height: 100%;
  -webkit-transition: .4s all ease;
  transition: .4s all ease;
  margin: 0 auto;
  border-color: ${({borderColor}) => borderColor};
  cursor: pointer;
    svg {
      width: 20px;
      height: 28px;
      margin: 0 0 2px;
      -webkit-transition: .4s all ease;
      transition: .4s all ease;
      path {
        stroke: ${({svgColor}) => svgColor || "#fff"};
        &:nth-of-type(2){
          stroke: ${({svgColor}) => svgColor || "#fff"};
        }
        &:nth-of-type(3){
          stroke: ${({svgColor}) => svgColor || "#fff"};
        }
      }

    }
  }
`

// View All  Start
export const ViewAllWrap = styled.div`
  transition: 0.4s all ease;
  border: 2px solid;
  border-color: ${({borderColor}) => borderColor};
  height:  ${({height}) => height};
  width: ${({width}) => width};
  background: transparent;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 20px 0 0 0 ;
  &:hover {
    background: ${({hoverBackground}) => hoverBackground};
    border: none;
    ${RightArrowWrap}{
      border-color: ${({borderColorHover}) => borderColorHover || '#D12FFF'};
    }
    ${TitleSm}{
      color: #D12FFF; 
    }
    svg {
      path {
        stroke: #D12FFF;
        &:nth-of-type(2){
          stroke: #D12FFF;
        }
        &:nth-of-type(3){
          stroke: #D12FFF;
        }
      }
    }

`;


export const ViewAllLink = styled(Link)`
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  justify-content: center;
  -ms-flex-align: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  align-items: center;
  -webkit-transition: .4s all ease;
  transition: .4s all ease;
  width: 85%;
  height: 100%;
  text-decoration: none !important;
  ${TitleSm} {
    font-size: 16px;
    line-height: 1;
    letter-spacing: 0.135em;
    -webkit-transition: .4s all ease;
    transition: .4s all ease;
    color: ${({titleColor}) => titleColor || "#fff"};
    font-weight: 700;
  }
`;

function ViewAll(props) {
  const handleOnClick = () => {
    if (!!props.openContactUsModal) props.openContactUsModal();
    if (props.clearFilters) {
      if (localStorage.getItem("location") !== null) {
        localStorage.removeItem('location')
      }
      if (localStorage.getItem("capability") !== null) {
        localStorage.removeItem('capability')
      }
    }

  };

  return (
    <ViewAllWrap borderColor={props.borderColor} borderColorHover={props.borderColorHover}  hoverBackground={props.hoverBackground} width={props.width} height={props.height}>
        <ViewAllLink reloadDocument titleColor={props.titleColor} to={props.link ?  { pathname: props.link } : '#'} target={props.target} onClick={handleOnClick} >
          <TitleSm>{props.title}</TitleSm>
        </ViewAllLink>
        <RightArrowWrap borderColor={props.borderColor} svgColor={props.svgColor} >
          <svg width="23" height="17" viewBox="0 0 23 13" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M20 9L0 9L20 9Z" fill="white"/>
            <path d="M20 9L0 9" stroke="white" stroke-width="3"/>
            <path d="M13.4141 2L20.4246 8.92499L13.4141 15.85" stroke="white" stroke-width="3" stroke-miterlimit="10"/>
          </svg>
        </RightArrowWrap>
    </ViewAllWrap>
  )
}
export default ViewAll;

