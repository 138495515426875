import React, {createContext} from 'react';

var queryParams = new URLSearchParams(window.location.search);
var mediaFilter = 'all';
if(queryParams.get("media")) {
  var mediatype = queryParams.get("media");
  if(mediatype === 'blog') {
    mediaFilter = 'BlogPost';
  } else if(mediatype === 'press-releases') {
    mediaFilter = 'PressRelease';
  } else if(mediatype === 'podcasts') {
    mediaFilter = 'Podcast';
  } else if(mediatype === 'events') {
    mediaFilter = 'Event';
  }
  setTimeout(() => {
    window.scrollTo({ top: document.getElementById(mediaFilter).offsetTop, behavior: "smooth" })
  }, 1000)
}

export const AppContext = createContext({
  logoAniComplete: false,
  blackHeader: false,
  updateLogoState: undefined,
  updateBlackHeaderState: undefined,
  openedCard: -1,
  locationFilterValue: '',
  capabilityFilterValue: '',
  activeMediaFilter:'',
  articlesSearchTerm:''  
});

export class AppProvider extends React.Component {

  state = {
    logoAniComplete: false,
    blackHeader: false,
    subscriptionEmail: "",
    isFormSubmitted: false,
    openedCard: -1,
    locationFilterValue: '',
    capabilityFilterValue: '',
    activeMediaFilter: mediaFilter,
    articleSearchTerm:''
  };

  updateLogoState = (value) => {
    this.setState({logoAniComplete: value})
  }
  updateBlackHeaderState = (value) => {
    this.setState({blackHeader: value})
  }
  updateActiveMedialFilter = (value) => {
    this.setState({activeMediaFilter: value})
  }
    updateArticlesSearchTerm = (value) => {
        this.setState({articleSearchTerm: value})
    }
  setSubscriptionEmail = (value) => {
    this.setState({
      subscriptionEmail: value
    })
  }
  serviceOpenedCard = (index) => {
    this.setState({
      openedCard: index
    });
  }
  updateFormSubmitted = (value) => {
    this.setState({
      isFormSubmitted: value
    })
  }
  setLocationFilterValue = value => {
    this.setState({
      locationFilterValue: value
    })
  };
  setCapabilityFilterValue = value => {
    this.setState({
      capabilityFilterValue: value
    })
  };

  render() {
    const {
      logoAniComplete,
      blackHeader,
      subscriptionEmail,
      isFormSubmitted,
      openedCard,
      locationFilterValue,
      capabilityFilterValue,
        activeMediaFilter,
        articleSearchTerm
    } = this.state;
    return (
      <AppContext.Provider
        value={{
          logoAniComplete,
          blackHeader,
          subscriptionEmail,
          isFormSubmitted,
          openedCard,
          locationFilterValue,
          capabilityFilterValue,
          activeMediaFilter,
            articleSearchTerm,
          updateFormSubmitted: this.updateFormSubmitted,
          updateLogoState: this.updateLogoState,
          updateBlackHeaderState: this.updateBlackHeaderState,
          setSubscriptionEmail: this.setSubscriptionEmail,
          serviceOpenedCard: this.serviceOpenedCard,
          setCapabilityFilterValue: this.setCapabilityFilterValue,
          setLocationFilterValue: this.setLocationFilterValue,
          setActiveMediaFilter: this.updateActiveMedialFilter,
          setArticleSearchTerm:this.updateArticlesSearchTerm  
        }}
      >
        {this.props.children}
      </AppContext.Provider>
    );
  }
}