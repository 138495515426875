import React from 'react';
import Slider from "react-slick";

import {
    BlogSliderWrap,
    BlogSlide,
    SlideImage,
} from "./elements";

// Slick Slider
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";


function BlogSlider({ slides }) {
    const settings = {
        centerMode: false,
        infinite: false,
        loop: false,
        centerPadding: "0",
        centerSpacing: "50px",
        slidesToShow: 2,
        dots: false,
        autoplay: false,
        speed: 500,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1.15,
                    loop: false,
                }
            },
        ]
    };



    return (
        <BlogSliderWrap>
            <Slider {...settings}>
                {slides?.map((item, index) => {
                    return <BlogSlide key={index}>
                        <SlideImage src={item.imageAsset} />
                    </BlogSlide>
                })
                }
            </Slider>
        </BlogSliderWrap>
    );
}

export default BlogSlider;
