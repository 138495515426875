import React, {useEffect} from 'react';
import styled from 'styled-components';

const WistiaPlayer = (props) => {

  const {hashedId, overlay, id, isChannel, ...embedOptions} = {...props}

  const Wrapper = styled.div`
  position: relative;
`;

const WistiaEmbed = styled.div`
  height: 100%;
  width: 100%;
  border: none !important;
  box-shadow: none !important;
`;

const Overlay = styled.div`
  position: absolute;
  top: -2px;
  left: -2px;
  right: -2px;
  bottom: -2px;
  border-bottom: 4px solid #F0F0F0;
  border-left: 4px solid #F0F0F0;
  border-right: 4px solid #F0F0F0;
  border-top: 4px solid #F0F0F0;
  pointer-events: none;
  border-radius: 4px;
`;
  useEffect(() => {
    window._wq = window._wq || [];
    window._wq.push({id: props.hashedId, options: embedOptions});
    return () => {
      window._wq = window._wq || [];
    }
//eslint-disable-next-line
  }, [props.id])

  return (
      /*   <div id={props.id} className={`${props.customClass} wistia_embed wistia_async_${props.hashedId} videoFoam=true`}
              style={{height: '100% !important', width: '100% !important'}}>&nbsp;</div>*/
      /*<div className="wistia_responsive_padding" style={{padding:"56.25% 0 0 0", position:"relative"}}>
          <div className="wistia_responsive_wrapper" style={{height:"100%", left:0, position:"absolute", top:0, width:"100%"}}>
      <div className={`${props.customClass} wistia_embed wistia_async_${props.hashedId} videoFoam=true fitStrategy=cover`}
           style={{height:'100%',position:'relative',width:'100%'}}>
        <div className="wistia_swatch"
             style={{height:'100%',left:0,opacity:0,overflow:'hidden',position:'absolute',top:0,transition:'opacity 200ms',width:'100%'}}>
          <img src={`https://fast.wistia.com/embed/medias/${props.hashedId}/swatch`}
               style={{filter:'blur(5px)',height:'100%',objectFit:'contain',width:'100%'}} alt="" aria-hidden="true"
              /></div>
      </div>
          </div>
      </div>*/

   //   <div className="wistia_responsive_padding" style={{position:"relative"}}>
      //   {/* <div className="wistia_responsive_wrapper" style={{height:"100%",left:0,position:"relative",top:0,width:"100%"}}> */}
      //       <div class={`wistia_channel wistia_async_${props.hashedId} videoFoam=true mode=popover overlayZIndex=10000000`} style={{height: '100%', position: 'relative',  display: 'inline', width: '100%'}}> &nbsp;</div>
      //   {/* </div> */}
      // </div> 

      <>
      {isChannel && (
        <iframe src={`https://fast.wistia.com/embed/channel/${props.hashedId}`}
        height = "100%" 
        width = "100%" 
        style={{minHeight: '800px'}}
    >
      </iframe>
      )}

    
{!isChannel && (
        <Wrapper>
          <div className="wistia_responsive_padding" style={{ padding: "56.25% 0 0 0", position: "relative" }}>
            <div className="wistia_responsive_wrapper" style={{ height: "100%", left: 0, position: "absolute", top: 0, width: "100%" }}>
              <WistiaEmbed className={`wistia_embed wistia_async_${props.hashedId} seo=false videoFoam=true`}>
                &nbsp;
              </WistiaEmbed>
            </div>
          </div>
          {overlay && <Overlay />}
        </Wrapper>
      )}
      </>
  
    
  )


}

export default WistiaPlayer
