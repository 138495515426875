import styled from 'styled-components';

import {
    sizeWideScreen,
    sizeMobileLg
} from "components/devices";



// Footer Animation CSS


export const FtrBgVideo = styled.video`
    object-fit: cover;
    width: 100%;
    height: 100%;
    [poster] {
        object-fit: cover;
    }
    &:focus {
        outline: none;
        box-shadow: none;
        border: none;
    }
`;
export const SvgFtrDesktop = styled.img`
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: 2;
    object-fit: cover;
    width: 100%;
    height: 100%;
    margin: 0 auto;
    ${sizeMobileLg(`
        display: none;
    `)}
`;
export const SvgFtrMobile = styled(SvgFtrDesktop)`
    display: none;
    height: calc(100%);
    width: 100%;
    left: 50%;
    ${sizeMobileLg(`
        display: block;
    `)}
`;

export const FooterAnimationWrap = styled.div`
    height: 350px;
    position: relative;

    border-top: ${({ borderTop }) => borderTop || "1px solid #707070"};
    border-bottom: ${({ borderBottom }) => borderBottom || "1px solid #707070"};

    /* WideScreen CSS */
    ${sizeWideScreen(`
        height: 450px;
    `)}
`;
export const FtrVerticalImg = styled.img`
    display: none;
    width: 100%;
    max-height: 300px;
    ${sizeMobileLg(`
        display: block;
    `)}
`;


