import styled from 'styled-components';
import {TitleSm} from "../../../components/elements";


export const CalendarWrapper = styled.div`
  border-top: 1px solid rgb(153, 153, 153);
`


export const ArrowIcon = styled.div`
  height: 44px;
  width: 44px;
  cursor: pointer;
  background-size: cover;
  background-image: url("/images/gallery-arrow.png");
`

export const BackArrowIcon = styled.div`
  height: 44px;
  width: 44px;
  margin-left: 26px;
  margin-right: 12px;
  cursor: pointer;
  background-size: cover;
  background-image: url("/images/gallery-arrow.png");
  transform: rotate(180deg);
`

export const TitleWrapper = styled.div`
  display: flex;
  justify-content: center;
  padding: 45px;
`

export const Calendar = styled.table`
  width: 95%;
  height: 95%;
  border-collapse: collapse;
  margin: 0 30px 30px 30px;
`

export const CalendarCell = styled.td`
  height: 13vw;
  width: 13vw;
  border: 1px solid rgb(153, 153, 153);
  text-align: right;
  vertical-align: top;
  padding: 16px
`


export const ButtonWrapper = styled.div`
  position: relative;
  padding: 0 30px;

  ${TitleSm} {
    display: flex;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    -ms-transform: translateY(-50%);
  }
`



