import styled from 'styled-components';
import {sizeTabletLg} from "../devices";

export const PodcastWrap = styled.div`
  max-width: 900px;
  margin: 0 auto;
  padding: 36px 0 90px;
  text-align: center;
  
  ${sizeTabletLg(`
       padding: 40px 0;
   `)}
`;
export const ListenHere = styled.div`
  margin-bottom: 70px;
  width: 100%;
  ${sizeTabletLg(`
       margin-bottom: 30px;
       iframe {
        min-width: 100% !important;
       }
   `)}
`;
export const ListenOn = styled.div`

`;
export const PodcastLinksRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  ${sizeTabletLg(`
    flex-wrap: wrap;
`)}
`;
export const PodcastLink = styled.a`
  display: flex;
  font-size: 24px;
  line-height: 28px;
  color: #000000;
  margin-right: 70px;

  ${sizeTabletLg(`
        font-size: 20px;
        line-height: 1.3;
        margin: 0 20px 30px 20px;
    `)}
  &:last-child {
    margin-right: 0;
  }

  svg {
    margin-left: 20px;
  }

  &:hover {
    color: #D12FFF;

    svg {
      path {
        fill: #D12FFF;
      }
    }
  }
`;