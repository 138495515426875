import React from 'react';
import styled from 'styled-components';

import {
    sizeTabletLg, sizeWideScreen
} from "components/devices";
import {
    BodySm
} from "components/elements";


export const Mark = styled.span`
    display: inline-block;
    position: relative;
    border: 2px solid #707070;
    width: 20px;
    height: 20px;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 20px;
            flex: 0 0 20px;
    left: 0;
    top: 0;
    border-radius: 50%;
    margin-right: 12px;
    vertical-align: middle;
    cursor: pointer;
    ${sizeWideScreen(`
        width: 40px;
        height: 40px;
        flex: 0 0 40px;
        margin-right: 20px;
        top: 0;
    `)}
    &::after {
        content: "";
        display: block;
        width: 10px;
        height: 10px;
        border-radius: 50%;
        background-color: ${({ checkDotColor }) => checkDotColor || "#707070"};
        opacity: 0;
        left: 50%;
        top: 50%;
        position: absolute;
        transition: all 110ms;
        transform: translate(-50%, -50%);

        ${sizeWideScreen(`
            width: 20px;
            height: 20px;
        `)}
    }
    ${sizeTabletLg(`
        width: 16px;
        height: 16px;
        -webkit-box-flex: 0;
        -ms-flex: 0 0 16px;
                flex: 0 0 16px;
        margin-right: 7px;
        top: 2px;
    `)}
`;
export const CheckboxLabel = styled.label`
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    color: #000000;
    font-weight: 400;
    font-size: 20px;
    line-height: 120%;
    margin-left: -32px;
    padding-left: 32px;
    z-index: 1;
    position: relative;
    cursor: pointer;
    ${sizeTabletLg(`
        font-size: 16px;
    `)}
      ${sizeWideScreen(`
        font-size: 28px;
      `)}
`;
export const Input = styled.input`
    position: absolute;
    visibility: hidden;
    // opacity: 0;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    -webkit-border-radius: 0 !important; 
    -moz-border-radius: 0 !important;   
    border-radius: 0 !important;
    &:checked + ${Mark} {
        border-color: ${({ circleColor }) => circleColor || "#FFF"};
        &:after {
            opacity: 1;
        }
        + ${CheckboxLabel}, + ${CheckboxLabel} p {
            color: #000 !important;
        }
    }
`;

export const CheckBox = styled.div`
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    position: relative;
    box-sizing: border-box;
    cursor: pointer;
`;
export const CheckboxWrapper = styled.div`
    display: inline-block;
    cursor: pointer;
   
    ${BodySm} {
      font-weight: 400;
      font-size: 20px;
      line-height: 120%;
      color: #000000;
    }

  
  &.dark {
      ${CheckboxLabel} {
        color: #000;
      }
      ${Input} {
        &:checked + ${Mark} {
          border-color: ${({ circleColor }) => circleColor || "#000"};
          &:after {
            opacity: 1;
          }
          + ${CheckboxLabel}, + ${CheckboxLabel} p {
            color: #000 !important;
          }
        }
      }
    }
    &.square {
      padding-left: 0;
      padding-right: 0;
      & + &.square {
        margin-top: 24px;
      }
      ${Mark} {
        width: 28px !important;
        height: 28px !important;
        flex: 0 0 28px !important;
        border-radius: 0 !important;
        border: 3px solid #000;
        top: 4px;
        &:after {
          content: '';
          display: block;
          position: absolute;
          top: 0;
          left: 7px;
          width: 9px;
          height: 17px;
          border: solid #000;
          border-width: 0 3px 3px 0;
          transform: rotate(45deg);
          background: transparent;
          border-radius: 0;
        }
      }
    }
`;


export const Checkbox = ({ name, children, checkDotColor, circleColor, id, handleCheck, className }) => (
    <CheckboxWrapper className={className}>
        <CheckBox>
            <Input onChange={handleCheck} name={name} id={id} type="checkbox" />
            <Mark circleColor={circleColor} checkDotColor={checkDotColor} />
            <CheckboxLabel htmlFor={id} className="radio-label">{children}</CheckboxLabel>
        </CheckBox>
    </CheckboxWrapper>
);

export default Checkbox;
