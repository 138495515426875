import React, { useEffect } from 'react';

import {
    PrivacyPolicyPage,
    PvInfoContainer
} from "./elements";

import ViewAll from "components/ViewAll";
import NextPage from "components/NextPage";
function PrivacyPolicyOneTrust() {
    useEffect(() => {
        window.OneTrust.NoticeApi.Initialized.then(function () {
            window.OneTrust.NoticeApi.LoadNotices(["https://privacyportal-cdn.onetrust.com/6cdc31d6-1e41-47c4-9fcc-e505fd9cbc62/privacy-notices/7b071496-4059-4810-b413-64bd34e258a7.json"], false);
        });
    }, [])
    return (
        <PrivacyPolicyPage>
            <PvInfoContainer>
                <div id="otnotice-7b071496-4059-4810-b413-64bd34e258a7" className="otnotice"></div>
            </PvInfoContainer>
            {/* <PrivacyInfoSection />
            <AccordionSection />
            <Copyrights
                body="A printable version of this policy is available here. We reserve the right 
                    to modify this Policy as needed, for example, to comply with changes in laws, regulations, 
                    or requirements introduced by applicable data protection authorities, changes to our business model, 
                    structure, products or services. We encourage you to review this 
                    Policy periodically to be informed of how we may use your personal information."
            /> */}
            
            <ViewAll
                titleLarge="Need to chat?"
                title="Contact Us"
                textTransform="uppercase"
                showTitleLarge={true}
            />
            <NextPage
                pageName="Go to Homepage"
                pageLink="/"
                back={true}
            />
        </PrivacyPolicyPage>
    );
}

export default PrivacyPolicyOneTrust;
