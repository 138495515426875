import React, {useState} from 'react';
import FooterAnimation from "components/FooterAnimation";
import {WideScreenContainer} from "../../components/elements";
import {r3cPageContent} from "../../constants";
import {findContent, getImageDimensions} from "../../generalHelpers";
import LoadingHoc from "../../hoc/LoadingHOC";
import {Helmet} from "react-helmet";
import useRememberScrollPosition from "../../hooks/useRememberScrollPosition";
import PurpleAnimHeader from 'components/PurpleAnimHeader';
import HowWeWorkSection from 'components/HowWeWorkSection';
import LeadersBySpecialtyCards from 'components/LeadersBySpecialtyCards';
import { OurServicesContainer } from './integrated-communications/elements';


function Alchemy(props) {
    const {setLoading} = props
    const [heroSectionData, setHeroSectionData] = useState({})
    // const [jumpData, setJumpData] = React.useState({})
    const [firstSection, setFirstSection] = React.useState({})
    const [secondSection, setSectionSection] = useState({})
    const [cards, setCards] = useState({})
    const [textSectionData, setTextSectionData] = React.useState({})
    // const [plusCards, setPlusCards] = useState({})
    const [seoData, setSeoData] = useState({})

    React.useEffect(() => {
        fetch(r3cPageContent + '?route=/what-we-do').then((response) => response.json()).then((page) => {

        let seo = page.seo; 
        let image  = page?.seo?.image?.split('.')

        page?.seo?.image ?  getImageDimensions(page?.seo?.image,function (width,height){
            setSeoData({
                ...seo,
                mimeType:image[image.length - 1],
                seoImageHeight:height,
                seoImageWidth:width
            })

        }) :    setSeoData(seo)

        // setInfoSectionData(findContent(page.contents, 'cards'))
        setHeroSectionData(findContent(page.contents, 'heroArea'))
        setTextSectionData(findContent(page.contents, 'textArea'))
        setFirstSection(findContent(page.contents, 'region',1))
        setSectionSection(findContent(page.contents, 'region',2))
        setCards(findContent(page.contents, 'region',3))
        // setBrandsAndProducts(findContent(page.contents, 'tabbedLinks'))
        setLoading(false)
    })
        // eslint-disable-next-line
    }, [])

    useRememberScrollPosition('alchemy');
    // let bgImages = ["/images/data-technology-gradient.jpg", "/images/gradient-bg3.jpg", "/images/commercial-gradient.jpg", "/images/corporate-gradient.jpg", "/images/services-gradient.jpg"]
    return (
        <div className="pageWrapper about">
            <Helmet>
                <title>{seoData.title}</title>
                <link rel="canonical" href={ window.location.pathname} />
                <meta name="description" content={seoData.description} />
                <meta name="og:description" content={seoData.description} />
                <meta name="og:title" content={seoData.title} />
                <meta name="og:url" content={window.location.protocol + "//" + window.location.host + window.location.pathname} />
                <meta name="og:type" content="website" />
                <meta name="og:site_name" content="Real Chemistry" />

                <meta name="og:image" content={window.location.protocol +seoData.image} />
                <meta name="og:image:type" content={"image/"+seoData?.mimeType} />
                <meta name="og:image:width" content={seoData?.seoImageWidth} />
                <meta name="og:image:height" content={seoData?.seoImageHeight} />

            </Helmet>
            <PurpleAnimHeader
                sectionLabel={heroSectionData.label}
                sectionTitle={heroSectionData.textArea}
                heroIntro={textSectionData.text}
                headerSplit={"2"}
                maxWidth="30%"
                marginRight="12%"
                backgroundImage={"/images/what-we-do-header.svg"}
                backgroundImgMbl={"/images/what-we-do-mbl.svg"}
                backgroundImgTitle={""}
                header={"Healthcare cannot be transformed in pieces"}
                subHead={"There isn’t a lack of care in our industry, just a lack of connection."}
                titleMdPadding={"18% 0 0"}
                headerMaxWidth="65%"
            />
            <WideScreenContainer>
                <HowWeWorkSection firstSectionData={firstSection} secondSectionData={secondSection} />
            </WideScreenContainer>
            <WideScreenContainer>
                <OurServicesContainer>
                    <LeadersBySpecialtyCards content={cards} headline={"Dive deeper into our services"} ctaText={"SEE WHAT WE DO"}/>
                </OurServicesContainer>

            </WideScreenContainer>
            <FooterAnimation
				svgDesktop="/images/purple-footer.svg"
				svgMobile="/images/purple-footer-mbl.svg"
				borderTop="none"
			/>
        </div>
    );
}

export default LoadingHoc(Alchemy);
