import React from 'react';
import {withRouter} from "react-router-dom"
import { TitleXl, TitleMd, TitleSm, TitleXs, Row, Col4} from "components/elements";
import {LeadershipTeamContainer, TitleXlContainer, TitleMdContainer, ExecutivesContainer, ImageContainer, NameContainer, TitleContainer, LinkedInContainer, InnerColContainer, AdditionalLeadersContainer} from './elements';
// import OverlapCircles from 'components/OverlapCircles';
import LeadershipAccordian from 'components/LeadershipAccordian';

function LeadershipTeamSection({executiveLeaders, additionalLeaders, boardOfDirectors}) {


return(
        <LeadershipTeamContainer>
            <TitleXlContainer>
                <TitleXl fontWeight="900">
                    Our leaders are only as good as the people they lead.  
                </TitleXl>
            </TitleXlContainer>
            <TitleMdContainer>
                <TitleMd>
                And our people are really, really good. 
                </TitleMd>
            </TitleMdContainer>
            <TitleMdContainer>
                <TitleXl fontWeight="900">
                Executive Leadership Team
                </TitleXl>
            </TitleMdContainer>
            <ExecutivesContainer>
                <Row>
                    {executiveLeaders.cards?.map((data, index) => (
                          <Col4 justifyContent="center" key={index}>
                            <InnerColContainer>
                                <ImageContainer>
                                    <img src={data.image} alt={data.label} className="leaderImage" />
                                </ImageContainer>
                                <NameContainer>
                                    <TitleSm>
                                        {data.label}
                                    </TitleSm>
                                </NameContainer>
                                <TitleContainer>
                                    <TitleXs>
                                        {data.title}
                                    </TitleXs>
                                </TitleContainer>
                                <LinkedInContainer target="_blank" href={data.ctaLink}>
                                    <img src="/images/linkedIN-logo.svg" className={"linkedIn"} alt={"LinkedIn logo link"} />
                                </LinkedInContainer>
                          </InnerColContainer>                        
                        </Col4> 
                    ))} 
                </Row>
            </ExecutivesContainer>
            <AdditionalLeadersContainer>
                <LeadershipAccordian title="Our Corporate Leaders" leaders={additionalLeaders}/>
                <LeadershipAccordian title="Board of Directors" leaders={boardOfDirectors} flexRow={true}/>
            </AdditionalLeadersContainer>
          
        </LeadershipTeamContainer>
    )
}

export default withRouter(LeadershipTeamSection);
