import axios from 'axios'
import qs from "qs"
export const callApi = (url, method, data,) => {
    try {
        axios.defaults.trailingSlash = false;

        let content_type = 'application/x-www-form-urlencoded';

        if (method === 'GET') {
            return axios.get(url, data, {
                headers: {
                    'Content-Type': content_type,
                    'Accept': '*/*'

                }
            });
        } else if (method === 'POST') {
            let formData = null;
           if(typeof data=== 'string'){
               formData = data
           }else{
                data['visitorId'] = getPardotId(document.cookie);
                formData = qs.stringify(data);
                console.log('Form Data',data);
           }

            return axios.post(
                url, formData,
                {
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded',
                        'Accept': '*/*'
                    },
                },

            )
        }
        else if (method === 'PUT') {
            return axios.put(url + "/", data, {
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                    'Accept': '*/*'
                },
            });
        } else {
            return axios.delete(url, data, {
                headers: {

                    'Content-Type': content_type
                }
            }, data);
        }
    }
    catch (e) {
        console.log(e);
        throw e;
    }
}



const getPardotId = (cookie) => {
    let search = cookie.split(';');
    let id = '';
    for (let i = 0; i < search.length; i++) {
        if (search[i].startsWith(' visitor_id') && search[i].indexOf('hash') === -1) {
            id = search[i].split('=')[1];
        }
    }
    return id;
}

