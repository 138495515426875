import styled from 'styled-components';

import {
    sizeXlDevice,
    sizeDesktopMd,
    sizeLaptopMd,
    sizeTabletLg,
    sizeLaptopLg,
    sizeLaptop,
    sizeWideScreen,
} from "components/devices";


export const SubscribeWrap = styled.div`
  border-bottom: 1px solid #707070;


  /* Wide Screen CSS */
  ${sizeWideScreen(`
        border-right: 1px solid #707070;
        border-left: 1px solid #707070;
    `)}
`;
export const SubscribeRow = styled.div`
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  height: 670px;
  ${sizeTabletLg(`
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
        -webkit-box-orient: vertical;
        -webkit-box-direction: reverse;
        -ms-flex-direction: column-reverse;
        flex-direction: column-reverse;
    `)};

  /* Wide Screen CSS */
  ${sizeWideScreen(`
            height: 830px;
        `)} /* For 4k Device */ ${sizeXlDevice(`
            height: 770px;
        `)}
  ${sizeDesktopMd(`
            height: 520px;
        `)};
  ${sizeLaptopLg(`
            height: 520px;
        `)};
  ${sizeLaptop(`
            height: 470px;
        `)};
  ${sizeTabletLg(`
            position: static;
            width: 100%;
            height: auto;
        `)};
`;
export const SubscribeCol = styled.div`
  width: 100%;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 100%;
  flex: 0 0 100%;
  background-color: #6900FF;

}

${sizeLaptopMd(`
        width: 60%;
        -webkit-box-flex: 0;
        -ms-flex: 0 0 60%;
                flex: 0 0 60%;
        &:first-child {
            width: 40%;
            -webkit-box-flex: 0;
            -ms-flex: 0 0 40%;
                    flex: 0 0 40%;
        }
    `)}

;

${sizeTabletLg(`
        width: 100%;
        -webkit-box-flex: 0;
        -ms-flex: 0 0 100%;
                flex: 0 0 100%;
        &:first-child {
            height: auto;
            width: 100%;
            -webkit-box-flex: 0;
            -ms-flex: 0 0 100%;
                    flex: 0 0 100%;
        }
    `)}
`;
export const SubscribeImgWrap = styled.div`
  height: 100%;
  width: 100%;
  ${sizeTabletLg(`
        height: auto;
        font-size: 0;
    `)};
`;
export const SubscribeVideo = styled.video`
  width: 100%;
  height: 100%;
  object-fit: cover;

  [poster] {
    object-fit: cover;
  }
  
  
  
  ${sizeTabletLg(`
    display: none;
`)};
`;
export const SubscribeImgMobile = styled.img`
  display: none;
  width: 100%;
  max-height: 500px;
  object-fit: cover;
  ${sizeTabletLg(`
    display: block;
`)};
`;
export const RightInner = styled.div`
  padding: 10px 40px;
  ${sizeDesktopMd(`
        padding: 10px 30px;
    `)};
  ${sizeLaptop(`
        padding: 10px 15px;
    `)};
  ${sizeTabletLg(`
        padding: 30px 5px;
    `)};
`;
