import styled from "styled-components";
import { sizeTabletLg } from "../devices";
import { TitleXs } from "../elements";

export const AccordionBody = styled.div`
  max-height: 0;
  padding: 0;
  overflow: hidden;
  -webkit-transition: -webkit-max-height .5s ease;
  transition: -webkit-max-height .5s ease;
  transition: max-height .5s ease;
  transition: max-height .5s ease, -webkit-max-height .5s ease;
`;
export const Accordion = styled.div`
  border-bottom: ${({ color }) => `5px solid ${color}` || "1px solid #707070"};
  margin-bottom: 8px;

  &:last-child {
    margin-bottom: 0;
  }

  &.active {
    ${AccordionBody} {
      max-height: 1000px;
      overflow: hidden;
    }
  }

  &.hidden {
    ${sizeTabletLg(`
      display:none;
    `)}
  }
`;

export const BodyInner = styled.div`
  display: flex;
  align-items: center;
  padding: 5px 0;
  position: relative;

  ${TitleXs} {
    display: flex;
    align-items: center;
    position: relative;
    padding-left: 10px;
    // top: 3px;
  }

  span {
    display: none;
  }

  &:hover {
    span.c0 { {
      display: block;
      position: absolute;
      top: -6px;
      left: 32px;
      white-space: nowrap;
      color: #ccc;
      font-size: 14px;
    }
    }
  }
`;

export const AccordionWrapper = styled.div`
  padding: 20px 0;
`;





