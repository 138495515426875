import React from 'react'
import styled from 'styled-components'
import {BodyRg, TitleSm, TitleXl} from "./elements";
import BackArrowSvg from "../assets/icons/BackArrowSvg";
import {Link} from "react-router-dom";
import {sizeTabletLg, sizeMobileLg} from "./devices";


export const JobDarkCol  = styled.div`
  width: calc(100% - 70px);
  &.arrow-col {
    width: 62px;
    height: 25px;
  }
  &.single-col {
    max-width: 100%;
    width: 100%;
  }
`;
export const JobDarkBox = styled(Link)`
  padding: 35px 35px;
  background: #F0F0F0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 640px;
  ${sizeMobileLg(`
    padding: 35px 15px;
  `)}
  &.pointerNone {
    pointer-events: none;
    ${JobDarkCol} {
      max-width: 100%;
  }
  }

  ${sizeTabletLg(`
     min-height: 440px;
  `)}

  ${TitleXl}{
    font-size: 76px;
    line-height: 91px;
    font-weight: 900;
    ${sizeMobileLg( 
      `
        font-size: 40px;
        line-height: 48px;
      `
    )}
  }

  ${TitleSm}{
    font-size: 36px;
    line-height: 43px;
    font-weight: 900;
    ${sizeMobileLg(`
      font-size: 20px;
      line-height: 24px;
    `)}
  }
  ${BodyRg}{
    font-size: 16px;
    line-height: 19px;
  
  }
  
  ${TitleXl},
  ${TitleSm},
  ${BodyRg} {
    color: #000;
  }
  ${TitleSm} {
    font-size: 36px;
    line-height: 43px;
    font-weight: 900;
    ${sizeMobileLg(`
      font-size: 20px;
      line-height: 24px;
    `)}
  }
  ${BodyRg}{
    font-size: 16px;
    line-height: 19px;
    max-width: 100%;
  }
  svg {
    transition: 0.3s all ease;
    transform: rotate(180deg);
    width: 62px;
    height: 32px;
    top: 0;
    left: 0;
    position: relative;
    path, line {
      stroke: #D12FFF; 
    }
  }
  &:hover {
    svg {
      width: 89px;
      height: 45px;
      left: -15px;
      top: -15px;
    }
  }
`;
export const JobDarkHead = styled.div`
  
`;
export const JobDarkFooter = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  ${BodyRg}{
    font-size: 20px; 
    line-height: 24px;
  }
`;

function JobDarkCard({ jobTitle, jobDesc, count, link }) {

    return (
        <JobDarkBox to={count > 0 ? link : "#"} className={count > 0 ? "" : "pointerNone"}>
            <JobDarkHead>
                <TitleSm margin={"0 0 10px 0"}>{jobTitle}</TitleSm>
                <BodyRg>{jobDesc}</BodyRg>
            </JobDarkHead>
            <JobDarkFooter>
                <JobDarkCol className={count > 0 ? '' :  'single-col'}>
                    { count > 0 ? <TitleXl margin={"0 0 5px 0"}>
                        {count}
                    </TitleXl> : "" }
                    {
                        count > 0 ?
                            <BodyRg textTransform={"uppercase"}>opportunities</BodyRg> :
                            <BodyRg textTransform={"uppercase"}>No current opportunities in this team</BodyRg>
                    }
                </JobDarkCol>
                {
                    count > 0 ? <JobDarkCol className={'arrow-col'}>
                        <BackArrowSvg color={"#D12FFF"} />
                    </JobDarkCol> : ""
                }
            </JobDarkFooter>
        </JobDarkBox>
    )
}

export default JobDarkCard;
