import React from 'react'

import {
	ItemsSectionWrap
} from './elements'

import LinkCard from "../../LinkCard";
import { Col6, Row } from 'components/elements';

function TwoColumnTabbedItems({ items }) {

	const backgroundColors = ["#0F20FF", "#D12FFF"];
	

	return (
		<ItemsSectionWrap>
			<Row>
				{items && items.map((item, index) => {
					return <Col6 key={'tabelem-' + index} padding="0 15px" >
						<LinkCard
							title={item.title}
							index={index + 1}
							cta={item.ctaText}
							bodySm={item?.detail?.content[0].content[0].value}
							orderedList={item?.detail}
							link={ item.ctaLink}
							backgroundColor={backgroundColors[index]}
						/>
					</Col6>
				}
			)}
			</Row>
		</ItemsSectionWrap>
	)
}

export default TwoColumnTabbedItems
