import styled from "styled-components";
import { sizeTabletLg, sizeWideScreen } from "components/devices";


export const VideoWrapper = styled.div`
  box-sizing: border-box;
  position: relative;
  height: 725px;
  ${sizeWideScreen(`
      padding-top: 56.25%;
    `)}

    ${sizeTabletLg(`
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%; 
        padding: 0;
    `)}
`;
export const Video = styled.video`
  object-fit: cover;
  width: auto;
  margin: 0 auto;
  height: 100%;
  width: 100%;
  position: absolute;
  left: 0;
  top: 0;
    ${sizeTabletLg(`
        position: relative;
        width: 100%;
        height: 100%; 
    `)}

  &:focus {
    outline: none;
    box-shadow: none;
    border: none;
  }
;
  display: ${props => props.isSafari ? "none" : "block"};
`;

export const ImageSafari = styled.img`
  object-fit: cover;
  width: 100%;
  height: 100%;

  &:focus {
    outline: none;
    box-shadow: none;
    border: none;
  }

  display: ${props => props.isSafari ? "block" : "none"};
`;


export const HeroVideoWrap = styled.div`
  width: 100%;
  box-sizing: border-box;
    overflow: hidden;
    // background-image: ${(props) => props.backgroundImage};
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
    position: relative;
    height: 725px;
    ${sizeTabletLg(`
        height: 500px;
    `)}

  &.innerHeight {
    height: 600px;
    ${sizeTabletLg(`
        height: 500px;
    `)}
    
    /* Wide Screen CSS */
    ${sizeWideScreen(`
      height: 1000px;
    `)}


    ${VideoWrapper} {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%; 
    }
      ${Video} {
          width: 100%;
      }
    ${VideoWrapper} {
      padding: 0;
    }
  }
`;
