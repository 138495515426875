import styled from 'styled-components';

import {
  sizeDesktopMd,
  sizeMobileXs,
  sizeLaptop,
  sizeMobileLg,
  sizeTabletLg,
  sizeMobileMd,
  sizeWideScreen,
} from "components/devices";

import {
    TitleMd,
    Col6, TitleXs
} from 'components/elements'

export const InsightPageWrap = styled.div`
`;

export const MediaResourceBladeContainer = styled.div`
    padding: 66px 25px;
    ${sizeMobileMd(`
        padding: 100px 22px;
    `)}
    ${sizeWideScreen(`
        border-left: 1px solid #707070;
        border-right: 1px solid #707070;
    `)}
`;

export const MediaResourceBlade = styled.div`
  padding: 100px;
  background: #FFFFFF;
  ${Col6} {
    display: flex;
    &:first-of-type {
      align-items: center;
      ${sizeTabletLg(`
        justify-content: flex-start;
        gap: 3;
        padding: 0 0 40px;
      `)}
  
      ${TitleMd} {
        margin-left: 20px;
        padding: 0 20px 0 0;
        font-size: 50px;
        line-height: 57px;
        font-weight: 400;
        ${sizeLaptop(`
          padding: 0 20px 0 0;
        `)}
        ${sizeTabletLg(`
          padding: 0 0 0 0;
          font-size: 25px;
          line-height:29px;
          margin-left: 40px;
        `)}
        ${sizeMobileLg (`
          margin-left: 25px;
        `)}
        ${sizeMobileXs(`
          margin-left: 20px;
          font-size: 20px;
          line-height: 22px;
        `)}
      }
    }
    &:last-of-type {
      flex-direction: column;
    }
    ${sizeLaptop(`
      &:first-of-type {
        padding-bottom: 30px;
      }
      max-width: 100%;
      width: 100%;
      -webkit-box-flex: 0;
      -ms-flex: 0 0 100%;
      flex: 0 0 100%;
    `)}
    ${sizeTabletLg(`
      justify-content: center;
      text-align: center;
    `)}
  }
  ${sizeDesktopMd(`
      padding: 80px 25px 80px 0;
  `)}
  ${sizeLaptop(`
      padding: 60px 40px 60px 0;
  `)}
  ${sizeMobileMd(`
    padding: 50px 0;
  `)}


  ${TitleXs} {
      font-size: 23px;
      line-height: 26px;
    ${sizeTabletLg(`
      text-align: left;
    `)}
    ${sizeMobileXs(`
      font-size: 20px;
      line-height: 22px;
  `)}
  }
`;

export const StarRaysIcon = styled.img`
  width: 96px;
  height: 96px;
  ${sizeMobileMd(`
    width: 48px;
    height: 48px;
  `)}
`;

export const RightArrowIcon = styled.img`
  width: auto;
  height: 24px;
  margin-left: 16px;
  transition: 0.4s all ease;
`;



export const ContactUsLink = styled.a`
  display: flex;
  &:hover {
    ${RightArrowIcon} {
      margin-left: 32px;
    }
  }
  ${sizeTabletLg(`
    justify-content: center;
    text-align: center;
  `)}
`;