import React from 'react'
import { gsap } from 'gsap'
import { BodyRg } from 'components/elements'

import {
	PagedListInsightArrow,
	PagedListNewsArrow,
	PagedListRowIcon,
	PagedListRowIconWhite,
	PagedListRowWrap,
	TitleCol
} from './elements'

import Tooltip from "components/Tooltip";
import { useHistory } from "react-router-dom";
import moment from "moment";

function PagedListRow({ item, index, fromInsights }) {
	const { title, department, departmentDescription, detailLink, externalUrl, type, date, source } = item

	React.useEffect(() => {
		gsap.fromTo(`.item${index}`, { opacity: 0 }, { opacity: 1, duration: 1, ease: 'fadeIn' })
	}, [index])

	const history = useHistory();

	const navigateTo = (e) => {
		if (detailLink && e.target.id === "top-openings-clickable" && type !== "News" )
			history.push(detailLink)
		if(type === "News" && e.target.id === "top-openings-clickable"){
			window.open(detailLink, '_blank');
		}
	}

	return (
		<PagedListRowWrap id="top-openings-clickable" onClick={navigateTo} className={`item${index}`}>

			{ title ? <TitleCol id="top-openings-clickable" >{title}</TitleCol> : ''}

			{ !fromInsights &&
				<Tooltip
					underline={false}
					tootltipTitle={department}
					tooltipInfo={departmentDescription}
					width="auto"
				/>
			}

			{ (type === 'BlogPost' || type === 'Insight') &&
				<PagedListInsightArrow className="fromInsights notnews" to={detailLink ? detailLink : "#"}>
					<PagedListRowIcon src="/images/BlogPost-icon-black.svg" />
					<PagedListRowIconWhite src="/images/BlogPost-icon-white.svg" />
				</PagedListInsightArrow>
			}

			{ (type === 'BlogPost' || type === 'Insight') &&
				<BodyRg  id="top-openings-clickable">BLOG POST</BodyRg>
			}

			{ type === 'Event' &&
				<PagedListInsightArrow className="fromInsights notnews" to={detailLink ? detailLink : "#"}>
					<PagedListRowIcon src="/images/Event-icon-black.svg" />
					<PagedListRowIconWhite src="/images/Event-icon-white.svg" />
				</PagedListInsightArrow>
			}

			{ type === 'Event' &&
				<BodyRg  id="top-openings-clickable">EVENT</BodyRg>
			}

			{ type === 'Podcast' &&
				<PagedListInsightArrow className="fromInsights notnews" to={detailLink ? detailLink : "#"}>
					<PagedListRowIcon src="/images/Podcast-icon-black.svg" />
					<PagedListRowIconWhite src="/images/Podcast-icon-white.svg" />
				</PagedListInsightArrow>
			}

			{ type === 'Podcast' &&
				<BodyRg  id="top-openings-clickable">PODCAST</BodyRg>
			}

			{ type === 'PressRelease' &&
				<PagedListInsightArrow className="fromInsights notnews" to={detailLink ? detailLink : "#"}>
					<PagedListRowIcon src="/images/PressRelease-icon-black.svg" />
					<PagedListRowIconWhite src="/images/PressRelease-icon-white.svg" />
				</PagedListInsightArrow>
			}

			{ type === 'PressRelease' &&
				<BodyRg  id="top-openings-clickable">PRESS RELEASE</BodyRg>
			}

			{/* { type === 'News' &&
				<PagedListInsightArrow className="fromInsights" to={detailLink ? detailLink : "#"}>
					<PagedListRowIcon src="/images/News-icon-black.svg" />
					<PagedListRowIconWhite src="/images/News-icon-white.svg" />
				</PagedListInsightArrow>
			}

			{ type === 'News' &&
				<BodyRg  id="top-openings-clickable">News</BodyRg>
			} */}

			{ fromInsights && <BodyRg  id="top-openings-clickable"  key={Math.random()}>{moment(date).format("MMMM D, YYYY")}</BodyRg>}

			{ fromInsights && type === 'News' && <BodyRg className={'p-0-mobile'} style={{padding:"0 65px 0 15px"}} id="top-openings-clickable">{source}</BodyRg>}

			{ type !== 'News' &&
				<PagedListInsightArrow className="fromInsights" to={detailLink ? detailLink : "#"}>
					<PagedListRowIcon src="/images/arrow-right.svg" />
					<PagedListRowIconWhite src="/images/arrow-right-white.svg" />
				</PagedListInsightArrow>
			}

			{ type === 'News' &&

				<PagedListNewsArrow href={externalUrl ? externalUrl : "#"} rel="noopener noreferrer" target="_blank">
					<PagedListRowIcon src="/images/arrow_news.svg" alt="Arrow go to page"/>
					<PagedListRowIconWhite src="/images/arrow_news-white.svg" alt="Arrow go to page"/>
				</PagedListNewsArrow>
			}

		</PagedListRowWrap>
	)
}

export default PagedListRow
