import React from 'react';
import styled from 'styled-components';
import PlusIconSvg from "../assets/icons/PlusIconSvg";
import {BodySm, TitleXs} from "components/elements";
import {sizeMobileLg, sizeTabletLg} from "./devices";


export const LoadCenter = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  ${TitleXs} {
    font-size: 32px;
    line-height: 38px;
  }

  svg {
    width: 34px;
    height: 34px;
    margin-right: 24px;
    ${sizeTabletLg(`
      margin-right: 10px;
  `)}
  }
`;
export const LoadItemCount = styled.div`
  position: absolute;
  right: 0%;
  align-self: center;
  padding-right: ${({paddingRight}) => paddingRight};
  ${BodySm}{
    font-size: 16px;
    line-height: 32px;
    }
  ${sizeMobileLg (`
      display: none;
  `)}

`;
export const LoadMoreBlock = styled.div`
  padding: ${({padding}) => padding || "0 32px 115px"};
  border-left: ${({borderLeft}) => borderLeft || "none"};
  border-right: ${({borderRight}) => borderRight || "none"};
  border-top: ${({borderTop}) => borderTop || "1px solid #999999"};
  border-bottom: ${({borderBottom}) => borderBottom || "1px solid #999999"};
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  cursor: pointer;
  transition: 0.3s all ease;

  &:hover {
    ${LoadCenter} {
      ${TitleXs}, path {
        stroke: #D12FFF;
        color: #D12FFF;
      }
    }
  }
`;


function LoadMore ({text, list, totalLength, paddingRight, padding, onClick, borderTop, borderRight, borderBottom, borderLeft}) {


return (
    <LoadMoreBlock
        onClick={onClick}
        borderTop={borderTop}
        borderRight={borderRight}
        borderBottom={borderBottom}
        borderLeft={borderLeft}
        padding={padding}
        className={"load more"}
    >
        <LoadCenter>
            <PlusIconSvg/>
            <TitleXs textTransform={"uppercase"}>{text}</TitleXs>
        </LoadCenter>
        <LoadItemCount paddingRight={paddingRight}>
            <BodySm fontWeight={'500'}>Viewing <b>1 - {list + 8}</b> of {totalLength + 2}</BodySm>
        </LoadItemCount>

    </LoadMoreBlock>
)
 }

export default LoadMore;
