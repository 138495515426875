import React from 'react'


function AudioPlayIcon() {

    return (
        <svg width="60" height="61" viewBox="0 0 60 61" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M21.9609 17.6951L42.0409 29.9651L21.9609 42.6951V17.6951Z" stroke="black" stroke-width="3"
                  stroke-linejoin="bevel"/>
            <path
                d="M30 56.1951C44.3594 56.1951 56 44.5545 56 30.1951C56 15.8357 44.3594 4.19507 30 4.19507C15.6406 4.19507 4 15.8357 4 30.1951C4 44.5545 15.6406 56.1951 30 56.1951Z"
                stroke="black" stroke-width="3" stroke-miterlimit="10" stroke-linecap="square"/>
        </svg>


    )
}

export default AudioPlayIcon;
