import React from 'react'
import {
    DisplayCardWrapper,
    DisplayCardHead,
    DisplayCardTitle,
    DisplayCardBody,
    Avatar,
    CtaLink,
    AvatarWrapper
} from './elements'
import { TitleXs } from '../elements'
import gsap from 'gsap'
import { Elastic } from '../../assets/gsap-club'
import RichText from '../RichText'

function DisplayCard({ data }) {

    gsap.fromTo("#wrapper", { x: 1, opacity: 0.5, ease: Elastic.easeOut }, { x: 0, opacity: 1, ease: Elastic.easeIn })
    return (
        <DisplayCardWrapper id={"wrapper"}>
            <DisplayCardHead>
                <AvatarWrapper>
                    {
                        data?.image?
                            <Avatar src={data?.image} />
                            :''
                    }
                    </AvatarWrapper>
                    <DisplayCardTitle>
                        <TitleXs fontWeight={'500'} margin={'0 0 10px 0'}>{data?.label}</TitleXs>
                        <TitleXs lineHeight={'1'}>{data?.title}</TitleXs>
                        { data?.ctaLink &&
                        <CtaLink target="_blank" href={data?.ctaLink}>
                            <img src={'/images/linked-in-icon-black.svg'} alt={'linkedIN'} />
                        </CtaLink>
                        }
                    </DisplayCardTitle>
            </DisplayCardHead>
            <DisplayCardBody>
                <RichText body={data?.detail} />
            </DisplayCardBody>
        </DisplayCardWrapper>
)
}

export default DisplayCard
