import React from 'react'
import { r3cPageContent } from 'constants/index'
import { getImageDimensions} from 'generalHelpers'
import LoadingHoc from "hoc/LoadingHOC";
import {Helmet} from "react-helmet";
import HomeAnimationSection from 'components/HomeAnimationSection'
import HomeModal from 'components/HomeModal'
import {findContent} from "../../generalHelpers";


function Home(props) {
	const { setLoading } = props;
	// eslint-disable-next-line
	const [seoData, setSeoData] = React.useState({})
	// eslint-disable-next-line

	const [closeModal, setCloseModal] = React.useState(!sessionStorage.getItem('closeModal'));
	const [popUpData, setPopUpData] = React.useState()


	React.useEffect(() => {

		const abortController = new AbortController()


		fetch(r3cPageContent + '?route=/').then((response) => response.json()).then((page) => {
			let seo = page.seo;

			let image  = page?.seo?.image?.split('.')


			page?.seo?.image ?  getImageDimensions(page?.seo?.image,function (width,height){
				setSeoData({
					...seo,
					mimeType:image[image.length - 1],
					seoImageHeight:height,
					seoImageWidth:width
				})

			}) :    setSeoData(seo)

		
			setPopUpData(findContent(page.contents, 'homePopUp'))

			
			setLoading(false)	

		})


		return () => {
			abortController.abort()
			// stop the query by aborting on the AbortController on unmount
		  }


	}, [])


	// React.useEffect(()=> {
	// 	let body = document.body;
	// 	if (closeModal === true ) {
	// 	  body.style.overflow = "hidden";
	// 	} else {
	// 	  body.style.overflow = "auto";
	// 	}
	//   }, [closeModal])

	  const closeHomeModal = () => {
		setCloseModal(false)
		sessionStorage.setItem('closeModal', true);
	}


	return (
		<div className="home pageWrapper">
			<Helmet>
				<title>{seoData.title}</title>
				<link rel="canonical" href={ window.location.pathname} />
				<meta name="description" content={seoData.description} />
				<meta name="og:description" content={seoData.description} />
				<meta name="og:title" content={seoData.title} />
				<meta name="og:url" content={window.location.protocol + "//" + window.location.host + window.location.pathname} />
				<meta name="og:type" content="website" />
				<meta name="og:site_name" content="Real Chemistry" />
				<meta name="og:image" content={window.location.protocol +seoData.image} />
				<meta name="og:image:type" content={"image/"+seoData?.mimeType} />
				<meta name="og:image:width" content={seoData?.seoImageWidth} />
				<meta name="og:image:height" content={seoData?.seoImageHeight} />
			</Helmet>
			
			<HomeAnimationSection />

			{popUpData && (
				<HomeModal
					data={popUpData}
					show={closeModal}
					closeContactUsModal={closeHomeModal}
			/>
			)}
		

		</div>
	)
}

export default LoadingHoc(Home)
