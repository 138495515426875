import React, { useEffect, Fragment } from 'react';
import { withRouter } from 'react-router-dom';

/* eslint-disable react-hooks/exhaustive-deps */
function ScrollToTop({ history, children }) {
  useEffect(() => {
    const pageURL = window.location.pathname;
    if (pageURL === '/newsletters' || pageURL === '/contact' || pageURL === '/' || pageURL === '/case-study/:slug') {
      const unlisten = history.listen(() => {
        window.scrollTo(0, 0);
      });
      return () => {
        unlisten();
      }
    }

  }, [window.location.pathname]);

  return <Fragment>{children}</Fragment>;

}

export default withRouter(ScrollToTop);
