import React, { useState, useEffect } from 'react';
import { Helmet } from "react-helmet";
import styled from "styled-components";

import FooterAnimation from "components/FooterAnimation";
import PurpleAnimHeader from 'components/PurpleAnimHeader';
import { WideScreenContainer } from "../../components/elements";
import { r3cPageContent } from "../../constants";
import { findContent, getImageDimensions } from "../../generalHelpers";
import LoadingHoc from "../../hoc/LoadingHOC";
import useRememberScrollPosition from "../../hooks/useRememberScrollPosition";

import { ContactUsFormWrapper, ContactUsWrapper } from './elements';
import AvantNumbersSection from 'components/Avant/AvantNumbersSection';
import PreLaunch from 'components/Avant/PreLaunch';
import ChangeHealthcare from "components/Avant/ChangeHealthcare";
import ContactUsForm from 'components/ContactUsForm';

function Avant(props) {
  const { setLoading } = props;
  const [heroSectionData, setHeroSectionData] = useState({});
  const [textSectionData, setTextSectionData] = useState({});
  const [numberSectionData, setNumberSectionData] = useState({});
  const [launchData, setLaunchData] = useState({});
  const [contactData, setContactData] = useState({});
  const [seoData, setSeoData] = useState({});
  const [windowWidth, setWindowWidth] = useState();

  useEffect(() => {
    fetch(r3cPageContent + '?route=/medical/lp/avant-paid')
      .then(response => response.json())
      .then(page => {
        let seo = page.seo;
        let image = page?.seo?.image?.split('.');

        if (page?.seo?.image) {
          getImageDimensions(page?.seo?.image, (width, height) => {
            setSeoData({
              ...seo,
              mimeType: image[image.length - 1],
              seoImageHeight: height,
              seoImageWidth: width
            });
          });
        } else {
          setSeoData(seo);
        }

        setHeroSectionData(findContent(page.contents, 'heroArea'));
        setTextSectionData(findContent(page.contents, 'textArea'));
        setNumberSectionData(findContent(page.contents, 'region', 1));
        setLaunchData(findContent(page.contents, 'region', 2));
        setContactData(findContent(page.contents, 'region', 3));

        setLoading(false);
      });
  }, [setLoading]);

  useEffect(() => {
    const handleResize = () => {
      window.innerWidth < 570 ? setWindowWidth(true) : setWindowWidth(false);
    };

    window.addEventListener("resize", handleResize);
    handleResize();
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useRememberScrollPosition('avant');

  return (
    <div className="pageWrapper about">
      <Helmet>
        <title>{seoData.title}</title>
        <link rel="canonical" href={window.location.pathname} />
        <meta name="description" content={seoData.description} />
        <meta name="og:description" content={seoData.description} />
        <meta name="og:title" content={seoData.title} />
        <meta name="og:url" content={window.location.protocol + "//" + window.location.host + window.location.pathname} />
        <meta name="og:type" content="website" />
        <meta name="og:site_name" content="Real Chemistry" />
        <meta name="og:image" content={window.location.protocol + seoData.image} />
        <meta name="og:image:type" content={"image/" + seoData?.mimeType} />
        <meta name="og:image:width" content={seoData?.seoImageWidth} />
        <meta name="og:image:height" content={seoData?.seoImageHeight} />
        <meta name="robots" content="noindex" />
      </Helmet>

      <PurpleAnimHeader
        sectionLabel={'MEDICAL COMMUNICATIONS'}
        heroIntro={textSectionData.text}
        subHead={'Bringing tomorrow’s science to today’s HCP.'}
        boldSubText={'Welcome to the future of medical communications.'}
        headerSplit={"2"}
        maxWidth="45%"
        marginRight="10%"
        period={false}
        backgroundImage={heroSectionData.heroImage}
        backgroundImgMbl={heroSectionData.heroImage}
        backgroundImgTitle={""}
        header={heroSectionData?.textArea?.content[0].content[0].value}
        titleMdPadding={"18% 0 0"}
        headerMaxWidth="60%"
      />

      <AvantNumbersSection data={numberSectionData} />
      <PreLaunch data={launchData} />

      <ContactUsWrapper>
        <WideScreenContainer>
          <ChangeHealthcare data={contactData} />
          <ContactUsFormWrapper>
            <iframe title="Newsletter signup form" src={'https://go.realchemistry.com/l/603341/2024-07-03/7h5kb'} scrolling="no" width="100%" type="text/html" height={902} style={{ border: 'none' }} id="pardotForm" className={"pardotForm"}></iframe>
          </ContactUsFormWrapper>
        </WideScreenContainer>
      </ContactUsWrapper>

      <FooterAnimation
        svgDesktop="/images/purple-footer.svg"
        svgMobile="/images/purple-footer-mbl.svg"
        borderTop="none"
      />
    </div>
  );
}

export default LoadingHoc(Avant);
