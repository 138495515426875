import styled from "styled-components";

import {
  sizeMobileLg,
} from "components/devices";
import { WideScreenContainer } from "components/elements";



export const ContactUsWrapper = styled.div`
  width: 100%;
  
  ${WideScreenContainer} {
    display: flex;
    flex-direction: row;
    
    @media (max-width: 991px) {
      flex-direction: column;
    }
  }
`;

export const ContactUsFormWrapper = styled.div`
  flex: 0 0 50%;
  margin: auto 0 auto 0;
  padding: 0 30px;

  @media (max-width: 991px) {
    flex: 0 0 100%;
    margin: 20px 0;
    padding: 0 20px
  }
`;

