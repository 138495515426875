import React from 'react';
import {
    BodyRg,
    Bold
} from "components/elements";

import {
    InformCardWrap,
    InformBody
} from "./elements";

function InformCard({ borderTop, borderRight, borderBottom, borderLeft, subTitle, body }) {
    return (
        <InformCardWrap
            borderTop={borderTop}
            borderRight={borderRight}
            borderBottom={borderBottom}
            borderLeft={borderLeft}
        >
            <InformBody>
                {
                    subTitle && subTitle.length > 0 ? <BodyRg margin="0 !important">
                        <Bold>{subTitle}</Bold>
                    </BodyRg> : null
                }

                {body?.map((item, idx) => {
                    return (
                        <BodyRg key={idx} dangerouslySetInnerHTML={{ __html: item }}></BodyRg>
                    )
                })}
            </InformBody>
        </InformCardWrap>
    );
}

export default InformCard;
