
import React from 'react';
import {
  SubMenuMobile,
  SubList,
  SubLink,
  ItemText,
  MoreInfoIcon
} from "../../elements";

function AboutUsMobSubMenu(props) {
  return (
    <SubMenuMobile open={props.open}>
      <SubList>
        <SubLink onClick={props.close} to={`${props.data ? props.data.destination : "/careers"}`}>
          <ItemText>GO TO {props.data?.label}</ItemText>
          <MoreInfoIcon src="/images/moreinfo-icon.svg" alt="Click for more info arrow" />
        </SubLink>
        {
          props.data?.subNavigation?.cards?.map((card, index) => {
            return (
              <SubLink key={index} onClick={props.close} to={`${card.destination}`}>
                <ItemText>{card.headline}</ItemText>
                <MoreInfoIcon src="/images/moreinfo-icon.svg" alt="Click for more info arrow" />
              </SubLink>
            )
          })
        }
      </SubList>
    </SubMenuMobile>
  )
}

export default AboutUsMobSubMenu;
