import styled from "styled-components";

import { SectionTitle, BodyRg, TitleSm } from "components/elements";
import { sizeTabletLg, sizeWideScreen } from "components/devices";

// CSS Start
export const ScamAlertWrap = styled.div`
  padding-bottom: 100px;
  ${sizeTabletLg(`
        padding-bottom: 30px;
    `)}
  ${SectionTitle} {
    margin-bottom: 65px;
    padding: 0;
    ${sizeTabletLg(`
            text-align: left !important;
            margin-bottom: 45px;
        `)}
  }
  ${BodyRg} {
    font-size: 24px;
    margin-bottom: 20px;
    ${sizeTabletLg(`
            font-size: 16px;
        `)}

  ${sizeWideScreen(`
      font-size: 0.85vw;
    `)}
  }
  ${TitleSm} {
    margin-bottom: 20px;
    ${sizeWideScreen(`
      font-size: 0.95vw;
    `)}
  }
`;
export const ScamAlertContainer = styled.div`
  max-width: 915px;
  margin: 0 auto;
  padding: 0 25px;
  ${sizeWideScreen(`
    max-width: 100%;
    padding: 0 35px;
  `)}
`;
export const AnchorLink = styled.a`
  line-height: 1.3;
  color: #D12FFF;
  border-bottom: 1.3px solid #D12FFF;
  word-break: break-word;
  &.external {
    color: #000;
  }
`;

export const UnorderedList = styled.ul`
  list-style-type: disc;
  padding-left: 50px;
`;

export const UnorderedListItem = styled.li`
  position: relative;
  margin-bottom: 25px;
  font-size: 22px;
  padding-left: 10px;
  ${sizeTabletLg(`
      font-size: 16px;
  `)}
`;


