import {
  BrowserRouter as Router,
} from "react-router-dom";
import React from "react";
import ReactDOM from "react-dom";
import "./css/Base.scss";
import * as serviceWorker from "./serviceWorker";
import "react-input-range/lib/css/index.css";
import { gsap} from 'gsap'
// Components
import { AppProvider } from "./store";
import Header from "./pages/header";
import Footer from "./pages/footer";
import Routes from "./Routes";
import TagManager from "react-gtm-module";
import ScrollToTop from "components/ScrollToTop";


const tagManagerArgs = {
  gtmId: "GTM-WRGLNKM",
};



TagManager.initialize(tagManagerArgs);
gsap.config({ nullTargetWarn: false });

const App = () => (

  
  <div className="app">
    <AppProvider>
      <Router >
        <Header />
        <ScrollToTop>
          <Routes/>
        </ScrollToTop>
        <Footer />
      </Router>
    </AppProvider>
  </div>
);

ReactDOM.render(<App />, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
