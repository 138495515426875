import React from 'react'


function EventsIcon() {

    return (
        <svg id="Icons" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48">
            <rect x="22.5" y="19" width="3" height="5"/>
            <rect x="23.78" y="21.48" width="5" height="3" transform="translate(-7.07 12.67) rotate(-23.99)"/>
            <rect x="24" y="23.5" width="3" height="5" transform="translate(-10.51 20.59) rotate(-37)"/>
            <rect x="20.22" y="20.48" width="3" height="5" transform="translate(-8.11 33.47) rotate(-66)"/>
            <rect x="20" y="24.5" width="5" height="3" transform="translate(-11.8 28.31) rotate(-52.99)"/>
            <path
                d="M41.91,12l1.09.91v4.77a7,7,0,0,0,0,12.64v4.77L41.91,36H6.09L5,35.09V30.32A7,7,0,0,0,5,17.68V12.91L6.09,12H41.91M43,9H5L2,11.5V20a4,4,0,0,1,4,4H6a4,4,0,0,1-4,4v8.5L5,39H43l3-2.5V28a4,4,0,0,1,0-8V11.5L43,9Z"/>
        </svg>

    )
}

export default EventsIcon;
