import styled from 'styled-components';
import {
    TitleXl,
} from "components/elements";
import {
    sizeMobileLg,
    sizeTablet
} from "components/devices";
import { ViewAllWrap } from 'components/ViewAll';


export const Container = styled.div`
    width: 100%;
    padding: 0 0 5%;
    ${TitleXl}{
        font-size: 24px;
     ${sizeTablet (`
        font-size: 20px;
    `)}
    }

`

export const ThumbnailContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    padding: 30px 0 0;

`

export const Thumbnail = styled.div`
    background-image: ${({backgroundImage}) => `url('${backgroundImage}')` || ""};
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    text-align: center;
    flex: ${({flex}) => flex || '1 0 50%'};
    max-width: 350px;
    margin: 8px;
    overflow: hidden;
    height: 330px;
    ${sizeMobileLg(`
        max-width: 100%;
        flex: 1 0 100%;
        margin: 8px 0;
        height: 315px;
       
   `)}

   ${ViewAllWrap}{
    ${sizeTablet(`
        width: 185px !important;
    `)}
    ${sizeMobileLg(`
         width: 215px !important;
    `)}
}
`

export const Overlay = styled.div`
    width: 100%;
    height: 330px;
    margin: 0 auto;
    background: rgba(105, 0, 255, 0.79);
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-start;
    padding: 0 15px 22px;
    ${sizeMobileLg(`
            padding: 0 15px 30px;
        `)} 
`


export const CopyContainer = styled.div`
    ${TitleXl}{
        color: #ffffff;
        text-align: left;
        max-width: 77%;
        ${sizeMobileLg(`
            font-size: 24px !important;
        `)}
    }
 
`