import React from 'react';
import {withRouter} from "react-router-dom"
import { BodySm, TitleXl } from "components/elements";
import ViewAll from 'components/ViewAll';

import { NewsLetterWrapper, ImageWrapper, TitleWrapper, IntroWrapper, CtaWrapper, TopFlexWrapper} from './elements';


function Newsletters({title, intro, image, width, height, link}) {

    return(
            <NewsLetterWrapper width={width} height={height}>
              <TopFlexWrapper>
                <ImageWrapper>
                    <img src={image} alt={title} className="newsLetterImage"/>
                </ImageWrapper>
                <TitleWrapper>
                    <TitleXl color="#000" fontWeight="900" fontSize="34px" lineHeight="42px">{title} </TitleXl>
                </TitleWrapper>
                <IntroWrapper>
                    <BodySm fontSize="26px" lineHeight="30px">
                        {intro}
                    </BodySm>
                </IntroWrapper>
                </TopFlexWrapper>
            
                <CtaWrapper>
                    <ViewAll title={"LEARN MORE"} link={link} borderColorHover="#fff" borderColor="#000" titleColor="#000" hoverBackground="#000" svgColor="#000" width="190px" height="38px"/>
                </CtaWrapper>

            </NewsLetterWrapper>        
    )
}

export default withRouter(Newsletters);
