import React, { useRef } from 'react';
import {
    FooterAnimationWrap,
    SvgFtrDesktop,
    SvgFtrMobile,
    FtrBgVideo
} from './elements';
import useBrowserVisibility from 'hooks/useBrowserVisibility';

function FooterAnimation({ poster, svgDesktop, svgMobile, bgVideo, borderBottom, borderTop, purpleVideo }) {

    const vidRef = useRef(null);
    useBrowserVisibility(vidRef);

    return (

        <FooterAnimationWrap
            borderTop={borderTop}
            borderBottom={borderBottom}
        >
            {/* <FtrBgVideo
                bid
                loop
                muted
                ref={vidRef}
                autoPlay
                playsInline
                poster={poster}>
                    <source src={bgVideo} />

                </FtrBgVideo>
           */}
         
            <SvgFtrDesktop src={svgDesktop} alt="Purple gradient background with decorative square dots"/>
            <SvgFtrMobile src={svgMobile} alt="Purple gradient background with decorative square dots"/>
        </FooterAnimationWrap>

    )
}

export default FooterAnimation;
