import React from 'react';
import {withRouter} from "react-router-dom"
import {SectionTitle, BodySm} from "components/elements";
import {
    InnerHeroWrap,
    InnerHeroImg,
    InnerHeroMobileImg,
    MobileHeroCta,
    HeroTitleContainer
} from "./elements";

import ViewAll from "components/ViewAll";
import HeroVideo from "../HeroVideo";
import RichText from 'components/RichText'
import HeroWistia from "../HeroWistia";


function InnerHero({
                       history,
                       image,
                       mobileImage,
                       displayTitle,
                       sectionLabel,
                       sectionTitle,
                       linkTitle,
                       displayView,
                       openContactUsModal,
                       mobileBtnTitle,
                       videoPoster,
                       videoSrc,
                       link,
                       marquee,
                       autoPlay,
                       disable, fromInsights,
                       videoHeight,
                       srcArray, wistiaVidID,
                       showWistiaAsBg,
                       clearFilters,
                       objectFit,
                       width,
                       marqueeText
                   }) {
    return (
        <InnerHeroWrap className="inner-hero-container">
            <HeroTitleContainer>
                <SectionTitle
                    displayTitle={displayTitle}
                >
                    <BodySm textTransform="uppercase">{sectionLabel}</BodySm>
                    {sectionTitle ? <RichText body={sectionTitle}/> : ''}
                    {
                        linkTitle ?
                            <ViewAll title={linkTitle} borderColor="#000" titleColor="#000" width="400px" height="45px" hoverBackground="#000" openContactUsModal={openContactUsModal} displayView={displayView} clearFilters={clearFilters} link={link}/>
                            : ""
                    }
                </SectionTitle>

            </HeroTitleContainer>
            {
                mobileBtnTitle ? <MobileHeroCta to="#"
                                                onClick={() => !openContactUsModal ? history.push(link) : openContactUsModal()}>
                    {mobileBtnTitle}</MobileHeroCta> : ""
            }

            {/* Desktop Image */}
            {
                image ? <InnerHeroImg src={image}/> : ""
            }

            {/* Mobile Image */}
            {
                mobileImage ? <InnerHeroMobileImg src={mobileImage}/> : ""
            }

            {/* hero video */}
            {
                showWistiaAsBg ?
                    wistiaVidID && videoSrc ?
                        <HeroWistia
                            objectFit={objectFit}
                            width={width}
                            videoSrc={videoSrc}
                            hashedId={wistiaVidID}
                            marqueeText={marqueeText}
                            />
                        : ""
                    : videoPoster || videoSrc || (srcArray && srcArray.length) ?
                        <HeroVideo
                            customClass={'innerHeight'}
                            disable={disable}
                            hashedId={wistiaVidID}
                            autoPlay={autoPlay}
                            marquee={marquee}
                            fromInsights={fromInsights}
                            videoPoster={videoPoster}
                            videoSrc={videoSrc}
                            srcArray={srcArray}
                            marqueeText={marqueeText}
                        />
                        : ""
            }


        </InnerHeroWrap>
    );
}

export default withRouter(InnerHero);
