
import React from 'react'

import { BodyRg } from 'components/elements'
import RichText from 'components/RichText'

import {
	AuthorInfoWrap,
	AuthorRow,
	AuthorColLeft,
	AuthorColRight,
} from './elements'

function AuthorInfo({ authors }) {
	return (authors) ?
		(() => {
			for (const a of authors) {
				if (a?.biography) {
					return <AuthorInfoWrap>
						{authors.map((author, index) => {
							return author.biography ?
								<AuthorRow key={'author-' + index}>
									<AuthorColLeft>
										<BodyRg>Written by {author.name}</BodyRg>
									</AuthorColLeft>
									<AuthorColRight>
										<RichText body={author.biography} />
									</AuthorColRight>
								</AuthorRow>
								: null
						})}
					</AuthorInfoWrap>
				} else{
					return null
				}
			}
		})()
		: null
}

export default AuthorInfo
