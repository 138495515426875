import styled from 'styled-components';

import {
    sizeLaptopMd,
    sizeTabletLg
} from "components/devices";
import { Col6, Row, BodySm, TitleXl} from 'components/elements';
// import { LinkCardWrap } from 'components/LinkCard/elements';

// Items Section CSS Start
export const ItemsSectionWrap = styled.div`
    padding: 0 30px 20px;
    ${sizeTabletLg(`
        padding: 0 0;
        margin: 0 15px 35px;
    `)}
    ${Row} {
        justify-content: space-between;
        margin: 0 -1em;
        ${sizeTabletLg(`
            margin: 0;
        `)}
    }
    ${Col6} {
        ${sizeLaptopMd(`
            min-width: 50%;
            max-width: 50%;
            margin: 1em 0;
        `)}
        ${sizeTabletLg(`
            padding: 0;
            margin: 0 0 1em 0;
            min-width: 100%;
            max-width: 100%;
        `)}
    }
`;

export const TitleBodyContainer = styled.div`
    width: 90%;
    padding: 0 0 80px;
    ${sizeTabletLg (`
        padding: 40px 0 60px;
        width: 100%;
    `)}
    ${TitleXl} {
        font-size: 48px;
        ${sizeTabletLg(`
            font-size: 36px;
       `)}
    }
    ${BodySm} {
        font-size: 20px;
        line-height: 24px;
        padding-top: 30px;
        ${sizeTabletLg(`
            font-size: 16px;
            padding-top: 15px;
            line-height: 20px;
       `)}
    }
 

`
