import styled from 'styled-components';
import {
    BodyRg,
    TitleLg, 
    BodySm
} from "components/elements";
import {
    sizeMobileLg, sizeWideScreen
} from "components/devices";

// CSS Start
export const JobDetailCardWrap = styled.div`
border-bottom: ${({ borderBottm }) => borderBottm || ""};
    padding: 120px 25px 20px;
    text-align: center;
    ${BodyRg} {
        font-size: 22px;
        line-height: 26px;
        font-weight: 400;
        ${sizeMobileLg(`
            text-transform: uppercase;
        `)}

    }
    ${BodySm}{
        font-size: 16px;
        line-height: 19px;
        font-weight: 400;

    }
    ${TitleLg} {
        position: relative;
        text-transform: none;
        font-weight: 900;
        font-size: 48px;
        line-height: 57px;
        padding: 15px 0;
        ${sizeMobileLg(`
            font-size: 36px;
            line-height: 43px;
            text-align: center;
        `)}
    }
`;

export const ViewAllContainer = styled.div`
    display: flex;
    justify-content: center;
    padding: 10px 0 0;
    ${sizeMobileLg(`
        padding: 10px 0 0;
 `)}
`
export const JobDetailCardContainer = styled.div`
    max-width: 1000px;
    margin: 0 auto;
    #jobLocations {
        padding: 0 0 10px;
        ${sizeMobileLg(`
            font-size: 16px;
            line-height: 19px;
     `)} 
    }
    ${sizeMobileLg(`
       display: flex;
       flex-direction: column;
    `)}
    ${sizeWideScreen(`
        max-width: 80%;
    `)}
`;
export const BadgesRow = styled.div`
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    margin-bottom: 45px;
    ${sizeMobileLg(`
        order: 12;
        margin: 30px 0 0 0;
    `)}
`;
