import React from 'react';
import {BodySm, TitleXl, TitleSm} from "components/elements";
import {ContactUsHeader, TitleSmContainer, TitleXlContainer, BodySmContainer} from './elements';
import {withRouter} from "react-router-dom";



function PurpleVerticalHeader() {
  
    return(
        <ContactUsHeader>
            <TitleSmContainer>
                <TitleSm>
                    Contact Us
                </TitleSm>
            </TitleSmContainer>
            <TitleXlContainer>
                <TitleXl>
                    Let's get<br className="hide-mobile"/> to work<span className="magenta">.</span>
                </TitleXl>
            </TitleXlContainer>
            <BodySmContainer>
                <BodySm>
                    If you’re ready to transform healthcare into what it should be, we’d love to connect.
                </BodySm>
            </BodySmContainer>
    </ContactUsHeader>
    )
}

export default withRouter(PurpleVerticalHeader);
