import React, {useEffect} from 'react'

import SubscribeSection from './SubscribeSection'
import FooterAnimation from 'components/FooterAnimation'
import PagedList from 'components/PagedList'

import {
    InsightPageWrap,
    MediaResourceBlade,
    MediaResourceBladeContainer,
    StarRaysIcon
} from './elements'
import {findContent, getImageDimensions,  scrollToTargetAdjusted} from 'generalHelpers'
import {useLocation} from "react-router-dom";

import {  r3cBase, r3cPageContent,  newsEventsPages,} from 'constants/index'
import {
    WideScreenContainer,
    Row,
    Col6,
    TitleMd,
    TitleXs,
} from 'components/elements'
import LoadingHoc from "../../hoc/LoadingHOC";
import {Helmet} from "react-helmet";
import useRememberScrollPosition from "../../hooks/useRememberScrollPosition";
import {useAppContext} from "../../store";
import MediaAndEventsSection from "./MediaAndEventsSection";
import PurpleAnimHeader from 'components/PurpleAnimHeader'


function Insights(props) {
    useRememberScrollPosition('our-views');
    const {setLoading} = props;
    const [heroSectionData, setHeroSectionData] = React.useState([])
    const [heroIntroData, setHeroIntroData] = React.useState([])
    const {activeMediaFilter, setActiveMediaFilter,   articleSearchTerm} = useAppContext();
    const [featuredEvents, setFeaturedEvents] = React.useState({})
    const [allArticles, setAllArticles] = React.useState([])
    const [filteredArticles, setFilteredArticles] = React.useState([])
    const [topNewsData, setTopNewsData] = React.useState([])
    const [seoData, setSeoData] = React.useState({})
    // const [setJumpData] = React.useState({})
    const [articleLoad, setArticleLoad] = React.useState(true)


    const sortByDate = (arr) => {
        const sortedArr = arr.sort(function (a, b) {
            if (new Date(a.date) < new Date(b.date)) return 1;
            if (new Date(a.date) > new Date(b.date)) return -1;
            return 0;
        })

        return sortedArr
    }

    let location = useLocation();

    useEffect(() => {
        setActiveMediaFilter(newsEventsPages[location.pathname])
        scrollToTargetAdjusted('insights', 75);
        // eslint-disable-next-line
    }, [location.pathname])


    React.useEffect(() => {
        fetch(r3cPageContent + '?route=/news-events').then((response) => response.json()).then((page) => {
            let seo = page.seo;

            let image = page?.seo?.image?.split('.')

            page?.seo?.image ? getImageDimensions(page?.seo?.image, function (width, height) {
                setSeoData({
                    ...seo,
                    mimeType: image[image.length - 1],
                    seoImageHeight: height,
                    seoImageWidth: width
                })

            }) : setSeoData(seo)

            setHeroSectionData(findContent(page.contents, 'heroArea'))
            setHeroIntroData(findContent(page.contents, 'textArea'))
            const featuredEvents = findContent(page.contents, 'featuredEvents')
            let obj = {}
            obj.BlogPost = featuredEvents.featuredPosts;
            obj.PressRelease = featuredEvents.featuredPressReleases;
            obj.Podcast = featuredEvents.featuredPodcasts;
            obj.Event = featuredEvents.featuredEvents;
            obj.all = featuredEvents.featuredContent;
            setFeaturedEvents(obj)

            setLoading(false)
            setArticleLoad(false)
            // setJumpData(findContent(page.contents, 'jumpArea'))
        })
        // eslint-disable-next-line
    }, [])
    React.useEffect(() => {
        fetch(r3cBase + '/news').then((response) => response.json()).then((page) => {
            let obj = {}
            obj.items = sortByDate(page);

            setTopNewsData(obj)
        })
        // eslint-disable-next-line
    }, [])

    
    React.useEffect(() => {
        fetch(r3cBase + `/all-articles?search=${articleSearchTerm}`).then((response) => response.json()).then((page) => {
            sortByDate(page);
            setAllArticles(page)
        })
        // eslint-disable-next-line
    }, [articleSearchTerm])



    React.useEffect(() => {
        setArticleLoad(true)
        if (activeMediaFilter === 'all') {
            setFilteredArticles(allArticles)
            setArticleLoad(false)

        } else {
     
            let temp = allArticles.filter(item => item.type === activeMediaFilter);
            setFilteredArticles(temp)
            setArticleLoad(false)

        }
  
    }, [activeMediaFilter, articleSearchTerm, allArticles])

    return (
        <InsightPageWrap className="pageWrapper">
            <Helmet>
                <title>{seoData.title}</title>
                <link rel="canonical" href={window.location.pathname}/>
                <meta name="description" content={seoData.description}/>
                <meta name="og:description" content={seoData.description}/>
                <meta name="og:title" content={seoData.title}/>
                <meta name="og:url"
                      content={window.location.protocol + "//" + window.location.host + window.location.pathname}/>
                <meta name="og:type" content="website"/>
                <meta name="og:site_name" content="Real Chemistry"/>

                <meta name="og:image" content={window.location.protocol + seoData.image}/>
                <meta name="og:image:type" content={"image/" + seoData?.mimeType}/>
                <meta name="og:image:width" content={seoData?.seoImageWidth}/>
                <meta name="og:image:height" content={seoData?.seoImageHeight}/>
            </Helmet>
            <WideScreenContainer>
            <PurpleAnimHeader
                sectionLabel={heroSectionData.label}
                sectionTitle={heroSectionData.textArea}
                heroIntro={heroIntroData.text}
                headerSplit={"2"}
                maxHeight="620px"
                backgroundImage={"/images/news-events-hero.svg"}
                backgroundImgMbl={"/images/news-events-mbl.svg"}
                backgroundImgTitle={""}
                header={"Have we got a story for you"}
                titleMdPadding={"5% 0 0"}
                maxWidth="23%"
                marginRight="10%"
                headerMaxWidth="40%"
                anchor={true}
            />


                <MediaAndEventsSection searchTerm={articleSearchTerm} filteredItem={activeMediaFilter} id={'insights'} isLoading={articleLoad}
                                       featuredEvents={featuredEvents[activeMediaFilter]}
                                       allArticles={filteredArticles} />

                {/* <InsightsSection id="insights" parallaxData={parallaxSectionData}/> */}

                <SubscribeSection from={'blog-details'}/>
                <PagedList
                    fromInsights={true}
                    title={"In the news"}
                    rows={topNewsData.items}
                    no={5}
                    borderTop="none"
                    borderBottom="1px solid #707070"
                    id="news"
                    altLoadMore
                />

                <MediaResourceBladeContainer>
                    <MediaResourceBlade>
                        <Row alignItems='center'>
                            <Col6>
                                <StarRaysIcon src='/images/star-rays-icon-black.svg' alt="star rays icon black"/>
                                <TitleMd>MEDIA RESOURCES</TitleMd>
                            </Col6>
                            <Col6>
                                <TitleXs padding='0 0 10px 0'>Looking to connect with us or write about our work? Our media team is always at <a href="mailto:media@realchemistry.com" rel="noopener noreferrer" target="_blank" style={{color: "#D12FFF"}}>media@realchemistry.com</a>.</TitleXs>
                            </Col6>
                        </Row>
                    </MediaResourceBlade>
                </MediaResourceBladeContainer>
            </WideScreenContainer>
            <FooterAnimation
				svgDesktop="/images/purple-footer.svg"
				svgMobile="/images/purple-footer-mbl.svg"
				borderTop="none"
			/>
        </InsightPageWrap>
    )
}

export default LoadingHoc(Insights)
