import { css } from 'styled-components';

// Devices size for Responsive
export const device = {
  mobileXsXs: '330px',
  mobileXs: '374px',
  mobileSm: '375px',
  mobileMd: '480px',
  mobileLg: '767px',
  tablet: '768px',
  tabletLg: '991px',
  laptop: '1024px',
  laptopMd: '1199px',
  laptopLg: '1366px',
  desktopSm: '1440px',
  desktopMd: '1700px',
  desktopLg: '1921px',
  xlDeviceMin: '1921px',
  xlDeviceMax: '2500px',
  wideScreen: '2561px',
}


export const sizeWideScreen = (content) => css`
  @media (min-width: ${device.wideScreen}) {
    ${content};
  }
`;


export const sizeWideScreenMax = (content) => css`
  @media (max-width: ${device.wideScreen}) {
    ${content};
  }
`;

export const sizeXlDevice = (content) => css`
  @media (min-width: ${device.xlDeviceMin}) and (max-width: ${device.xlDeviceMax}) {
    ${content};
  }
`;

export const sizeDesktopLg = (content) => css`
  @media (max-width: ${device.desktopLg}) {
    ${content};
  }
`;
export const sizeDesktopMd = (content) => css`
  @media (max-width: ${device.desktopMd}) {
    ${content};
  }
`;
export const sizeDesktopSm = (content) => css`
  @media (max-width: ${device.desktopSm}) {
    ${content};
  }
`;
export const sizeLaptopLg = (content) => css`
  @media (max-width: ${device.laptopLg}) {
    ${content};
  }
`;
export const sizeLaptopMd = (content) => css`
  @media (max-width: ${device.laptopMd}) {
    ${content};
  }
`;
export const sizeLaptop = (content) => css`
  @media (max-width: ${device.laptop}) {
    ${content};
  }
`;
export const sizeTabletLg = (content) => css`
  @media (max-width: ${device.tabletLg}) {
    ${content};
  }
`;
export const sizeTablet = (content) => css`
  @media (max-width: ${device.tablet}) {
    ${content};
  }
`;
export const sizeMobileLg = (content) => css`
  @media (max-width: ${device.mobileLg}) {
    ${content};
  }
`;
export const sizeMobileMd = (content) => css`
  @media (max-width: ${device.mobileMd}) {
    ${content};
  }
`;
export const sizeMobileSm = (content) => css`
  @media (max-width: ${device.mobileSm}) {
    ${content};
  }
`;
export const sizeMobileXs = (content) => css`
  @media (max-width: ${device.mobileXs}) {
    ${content};
  }
`;

export const sizeMobileXsXs = (content) => css`
  @media (max-width: ${device.mobileXsXs}) {
    ${content};
  }
`; 

export const sizeMinLaptop = (content) => css`
  @media (min-width: ${device.laptopMd}) {
    ${content};
  }
`;
