import styled from 'styled-components'
import { BodyRg, TitleXl,  Row , Col7,  Col5 } from 'components/elements';

import {sizeLaptop,  sizeMobileLg, sizeTablet, sizeTabletLg } from '../devices'



export const DaHeader = styled.div`
display: flex;
align-items: center;
justify-content: space-between;
margin: 0 auto;
border-top: 1px solid #D6D6D6;
padding: 38px 0 50px;
cursor: pointer;
transition: 0.25s all ease;
${TitleXl} {
    color: #000;
    font-weight: 900;
    font-size: 36px;
    line-height: 43px;
    max-width: 80%;
${sizeMobileLg(`
    font-size: 20px;
    line-height: 24px;
    max-width: 95%;
`)}
}

${sizeMobileLg (`
  width: 100%;
  padding: 26px 0;
`)}

`;
export const DaBody = styled.div`
    padding: 30px 0 0 0;
    max-height: 0;
    overflow:hidden;
    transition: max-height .75s ease-in-out;
    ${Row}{

        ${Col5}{      
            ${TitleXl}{
                font-size: 24px;
                ${sizeMobileLg(`
                    font-size: 20px;

                `)}
            }
            ${sizeTabletLg(`
                padding: 0 0 0 0;
            `)}
            ${sizeMobileLg(`
                max-width: 100%;
                ${BodyRg}{
                    font-size: 16px;
                }

            `)}

        }
        ${Col7}{

            &.right {
                text-align: right;
            }

            img {
                width: 95%;
                vertical-align: middle;
                object-fit: cover;
            }

            .video {
                width: 90%;
                object-fit: cover;
                vertical-align: middle;
                float: left;
            }

            ${sizeMobileLg(`
                max-width: 100%;
                padding: 0 0 0 0;
                &.right{
                    text-align: center;
                }
                img {
                    width: 100%;
                }
                .video {
                    width: 100%;
                    padding: 0 0 30px;
                }

        `)}
        }
    }

    ${sizeMobileLg (`
         padding: 0 0 0 0;
    `)}


    .mobilePad {
        ${sizeTabletLg(`
            padding: 30px 0 0 !important;
        `)}
     }

`;

export const DarkAccordionBlock = styled.div`
    background-color: #ffffff;
    width: 83%;
    margin: 0 auto;
  ${sizeLaptop(`
    padding: 35px 25px;
  `)}
  ${sizeTablet(`
    width: 100%;
    padding: 20px 25px 0;
  `)}

  ${sizeMobileLg(`
     width: 100%;
     padding: 20px 15px 0 ;
  `)}

  .plus-icon {
    width: 34px;
    height: 34px;
    transition: 0.35s all ease;
    transform: rotate(0);

    ${sizeLaptop(`
        width: 18px;
        height: 18px;
  `)}
    path {
      stroke: #D12FFF;
    }
  }

  &.show {
    ${DaBody} {
        max-height: 5000px;
        height: 100%;
        transition: max-height .75s ease-in-out; 

    }
    .plus-icon {
      transform: rotate(45deg);
    }
  }
`;
