import React from 'react';
import {
    TitleLg,
    BodyRg,
    Bold,
    BodySm,
    SectionTitle
} from "components/elements"

import {
    SwoopIpmPageWrap,
    SwoopIpmContainer,
    OrderedList,
    OrderedListItem,
    RomanList,
    NumberedList
} from "./elements";

import {Helmet} from "react-helmet";


function SwoopIpmPage() {

    // let date = new Date();

    // let todayDate = date.toLocaleString('default', { month: 'long' }) + " " + date.getDate() + ", " + date.getFullYear();
    return (
        <SwoopIpmPageWrap className="pageWrapper">

            <Helmet>
                <title>{"Swoop / IPM Analytics Agreement  | Real Chemistry"}</title>
                <link rel="canonical" href={ window.location.pathname} />
                <meta name="description" content={"Swoop / IPM Analytics Agreement "} />
                <meta name="og:description" content={"Swoop / IPM Analytics Agreement "} />
                <meta name="og:title" content={"Swoop / IPM Analytics Agreement  | Real Chemistry"} />
                <meta name="og:url" content={window.location.protocol + "//" + window.location.host + window.location.pathname} />
                <meta name="og:type" content="website" />
                <meta name="og:site_name" content="Real Chemistry" />

            </Helmet>
            <SwoopIpmContainer>
                <SectionTitle textAlign="center">
                <BodySm textTransform="uppercase" textAlign="center">LAST UPDATED: JANUARY 3, 2022</BodySm>
                    <TitleLg textAlign="center">Swoop.com, Inc.<br/>Insights-as-a-Service Terms and Conditions</TitleLg>
                </SectionTitle>

                <BodyRg>
                The terms and conditions herein (the “<Bold>Agreement</Bold>”) govern the purchase and use of an Insights-as-a-Service report (the “<Bold>IaaS Report</Bold>”) from Swoop.com, Inc. and its wholly owned subsidiary, IPM.ai, Inc. (collectively, “<Bold>Swoop</Bold>”) by you (“<Bold>Customer</Bold>”) pursuant to one or more written purchase orders or statements of work (“<Bold>Statement of Work</Bold>”).  Each Statement of Work between Swoop and Customer incorporates the terms and conditions herein, together with any exhibits, schedules, or additional documents referenced herein or in Statements of Work, each of which as may be amended by the Parties (as later defined) in writing from time to time, which collectively constitute the agreement for Customer to purchase the IaaS Report(s) from Swoop.  In the event of any conflict between the provisions in this Agreement and those in a Statement of Work, the provisions in the Statement of Work will prevail over the conflicting terms in this Agreement, but only as to that Statement of Work.  Swoop and Customer are referred to individually as a “<Bold>Party</Bold>” and collectively as the “<Bold>Parties</Bold>.”  Customer accepts the terms of this Agreement by executing a Statement of Work that references the Agreement.                
                </BodyRg>


                <NumberedList>
                    <OrderedListItem>
                        <BodyRg>
                        <Bold>Order Placement; Change Orders.</Bold>  Customer or any of its Affiliates may order an IaaS Report by entering into a Statement of Work with Swoop.  The “<Bold>IaaS Report</Bold>” means the physicians lists, documentation, or analytics reports that are specifically identified in a Statement of Work to be provided by Swoop to Customer thereunder, plus all attendant services described therein.  IaaS Reports and attendant services by Swoop under this Agreement are restricted to and intended for use only in the territory of the United States of America.  Customer may request changes to the Statement of Work at any time by executing a mutually agreeable written change order signed by authorized representatives of both Parties.  As used herein, the term “Affiliate” means any entity that controls, is controlled by, or under common control with Customer or Swoop. 
                        </BodyRg>
                    </OrderedListItem>
                    <OrderedListItem>
                        <BodyRg>
                        <Bold>Statements of Work.</Bold>  Each Statement of Work executed by the Parties will, at minimum, describe the IaaS Report to be provided by Swoop, the permitted uses of the IaaS Report by Customer, the term of such Statement of Work, and the fees and payments to be paid by Customer for the IaaS Report and attendant services.  Where applicable, a Statement of Work will also specify any data, materials, information or other content provided by Customer to Swoop to be used in connection with preparing or providing the IaaS Report (the “<Bold>Customer Materials</Bold>”).  The Customer Materials constitute Customer’s Confidential Information (as defined in Section 4 below).
                        </BodyRg>
                    </OrderedListItem>
                    <OrderedListItem>
                        <BodyRg>
                        <Bold>Licenses.</Bold>  Subject to Customer’s payment of applicable fees set forth in a Statement of Work and compliance with this Agreement, Swoop grants Customer a non-transferrable license to use the IaaS Report as set forth in the applicable Statement of Work.  Customer grants Swoop a fully paid-up and royalty-free, non-exclusive and revocable license to use the Customer Materials as necessary to provide the IaaS Report and attendant services to Customer pursuant to the applicable Statement of Work.  Where agreed in a Statement of Work, Customer may sublicense the IaaS report for use by one or more of Customer’s Affiliates, clients or other specified third party.  Excluding any Customer Materials, except for the licenses granted herein and in the applicable Statement of Work, Swoop and its licensors retain the exclusive rights, title and interest in and to all materials, documentation, data, know-how, content, designs, and any and all intellectual property rights in the foregoing, owned or licensed by Swoop prior to executing the applicable Statement of Work, or after executing the applicable Statement of Work but not specifically for Customer.
                        </BodyRg>
                    </OrderedListItem>
                    <OrderedListItem>
                        <BodyRg>
                        <Bold>Confidentiality.</Bold>  Customer and Swoop will treat as confidential all proprietary, non-public information exchanged under this Agreement and any Statement of Work issued hereunder that is identified as confidential at the time of disclosure or that can reasonably be understood to be confidential based on its nature or the circumstances of its disclosure (“<Bold>Confidential Information</Bold>”).  The Parties may only use Confidential Information to fulfill obligations or obtain benefits under the Agreement and Statements of Work.  The Parties will only share Confidential Information with their respective employees, agents, or contractors as needed under the Agreement and Statements of Work, and shall remain responsible for such third parties’ actions.  The Parties will use a reasonable degree of care to prevent unauthorized disclosure or use of Confidential Information for five (5) years from receipt or, in case of trade secrets, for as long as Confidential Information remains a trade secret under applicable laws.  The Parties’ obligations with respect to Confidential Information do not apply to information that: (i) was known or becomes known to the receiving party from independent sources that are not bound by obligation of confidentiality; (ii) is independently developed by the receiving party; or (iii) where disclosure is required by applicable law, regulatory request or court order, provided that the receiving party will promptly notify the disclosing party in writing and will cooperate with the disclosing party if the disclosing party seeks a protective order or otherwise attempts to prevent disclosure of Confidential Information.
                        Swoop’s Confidential Information includes, without limitation, any records relating to its internal financial information (including, without limitation, information related to its payroll, overhead or other financial information unrelated to this Agreement or any Statement of Work), proprietary materials or trade secrets, or information that is subject to confidentiality obligations with third parties (collectively, “Swoop’s Proprietary Confidential Information”).  Under no circumstances will Swoop be required to deliver or provide Customer with access to Swoop’s Proprietary Confidential Information.
                        </BodyRg>
                    </OrderedListItem>
                    <OrderedListItem>
                        <BodyRg>
                        <Bold>Fees and Taxes.</Bold>  Fees and expenses quoted by Swoop in a Statement of Work are exclusive of taxes.  Customer will pay to Swoop or to the relevant taxing authority, as appropriate, all applicable sales, use, value added or other taxes payable under the Statement of Work, other than taxes imposed on Swoop’s income.                           
                        </BodyRg>
                    </OrderedListItem>
                    <OrderedListItem>
                        <BodyRg>
                        <Bold>Invoices and Payment. </Bold> Except as otherwise stated in a Statement of Work, Customer will pay Swoop in US Dollars all fees and other expenses set forth in the signed Statement of Work within thirty (30) days of the invoice date in accordance with the remittance instructions as set forth in the applicable invoice.  Fees and expenses set forth in each applicable Statement of Work are non-refundable and payable without deduction or setoff arising from any other Statement of Work.  Swoop will be entitled to suspend the provision of any services if the Customer fails to pay any undisputed amounts when due and such failure continues for thirty (30) days following written notice thereof.  The foregoing shall not apply to amounts that Customer has disputed in good faith.                         
                        </BodyRg>
                    </OrderedListItem>
                    <OrderedListItem>
                        <BodyRg>
                        <Bold>Consequential Damages Waiver.</Bold> IN NO EVENT SHALL EITHER PARTY BE LIABLE FOR ANY SPECIAL, CONSEQUENTIAL, INCIDENTAL, PUNITIVE, EXEMPLARY, OR INDIRECT DAMAGES, LOSS OF PROFITS OR LOSS OF DATA, HOWEVER CAUSED AND UNDER ANY THEORY OF LIABILITY, WHETHER IN CONTRACT, TORT, OR OTHERWISE, WHETHER OR NOT ADVISED OF THE POSSIBILITY OF SUCH DAMAGES, AND NOTWITHSTANDING ANY FAILURE OF ESSENTIAL PURPOSE OF ANY LIMITED REMEDY.                        
                        </BodyRg>
                    </OrderedListItem>
                    <OrderedListItem>
                        <BodyRg>
                        <Bold>Limitation of Liability. </Bold> A Party’s liability to the other Party under a Statement of Work entered into pursuant to this Agreement will not exceed the amount payable by Customer to Swoop under the applicable Statement of Work.  Nothing herein is intended to limit a Party’s liability for: (i) a Party’s willful misconduct, gross negligence, or fraud; (ii) a Party’s breach of Confidentiality obligations under this Agreement; (iii) a Party’s indemnification obligations under Section 11 herein; and (vi) any liability which may not be limited under applicable laws.                         
                        </BodyRg>
                    </OrderedListItem>
                    <OrderedListItem>
                        <BodyRg>
                        <Bold>Disclaimer of Warranties.</Bold> ALL IAAS REPORTS ARE PROVIDED “AS IS,” “WHERE IS” AND “AS AVAILABLE” AND SWOOP HEREBY DISCLAIMS ANY AND ALL WARRANTIES, CONDITIONS, GUARANTEES OR REPRESENTATIONS (WHETHER EXPRESS, IMPLIED, STATUTORY OR OTHERWISE) RELATED TO IAAS REPORTS, INCLUDING WITHOUT LIMITATION, ANY IMPLIED WARRANTY OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, TITLE, NON-INFRINGEMENT, OR ANY OTHER WARRANTIES ARISING FROM COURSE OF DEALING, USAGE, OR TRADE PRACTICE.  SWOOP DOES NOT GUARANTEE ANY RESULTS BASED ON IAAS REPORTS OR ANY INFORMATION PROVIDED IN IAAS REPORTS.  WITHOUT LIMITING THE FOREGOING, SWOOP MAKES NO REPRESENTATIONS OR WARRANTIES WITH RESPECT TO, AND WILL NOT BE LIABLE FOR ANY DAMAGES SUFFERED OR INCURRED BY CUSTOMER ARISING OUT OF ANY INACCURACIES, ERRORS OR OMISSIONS IN THE DATA COMPRISING THE IAAS REPORTS.                        </BodyRg>
                    </OrderedListItem>
                    <OrderedListItem>
                        <BodyRg>
                            <Bold>Warranties</Bold>                        
                        </BodyRg>
                    </OrderedListItem>
                    <OrderedList>
                    <OrderedListItem>
                        <BodyRg>
                        <Bold>Mutual Warranties. </Bold> Each Party hereby represents and warrants that: (i) it has full power and authority to enter into and perform under this Agreement; (ii) its entry into under this Agreement does not violate any applicable laws and codes and does not breach any other agreement to which it is a party or is bound; and (iii) it will otherwise comply with all applicable laws and codes in connection with its performance or rights granted under this Agreement.
                        </BodyRg>
                    </OrderedListItem>
                    <OrderedListItem>
                        <BodyRg>
                            <Bold>Customer’s Warranties.</Bold>  Customer acknowledges that IaaS Reports contain deidentified patient information and that California Civil Code § 1798.148 prohibits reidentification and attempted reidentification, as defined therein, of the deidentified information by Customer. Customer further represents and warrants that: (i) it will not attempt to re-identify patients based on any de-identified data provided as part of the IaaS Report under this Agreement; (ii) it will not further disclose the deidentified patient information from IaaS Reports to any third party unless otherwise required by law; (iii) it will not provide Swoop with any information relating to an identified or identifiable natural person that, alone or in combination with other information, is considered “personal data”, “personal information” or an equivalent term under applicable data privacy laws; (iv) Customer has all necessary rights, releases or consents to grant to Swoop rights to use Customer Materials as directed by Customer in accordance with this Agreement and the applicable Statement of Work; and (v) Customer will not use the IaaS Reports in any manner other than as expressly permitted herein or in the applicable Statement of Work.
                        </BodyRg>
                    </OrderedListItem>
                    <OrderedListItem>
                        <BodyRg>
                            <Bold>Swoop’s Warranties.</Bold>  Swoop further represents and warrants that: (i) Swoop will provide the IaaS Report and attendant services in a professional and workman-like manner and in accordance with the applicable Statement of Work; (ii) Swoop has all necessary rights (including, under appropriate licenses) to grant to Customer rights to use the IaaS Report in accordance with this Agreement and the applicable Statement of Work; and (iii) Customer’s use of the IaaS Report in accordance with this Agreement and the applicable Statement of Work will not violate rights of third parties.
                        </BodyRg>
                    </OrderedListItem>
                    </OrderedList>
                    <OrderedListItem>
                        <BodyRg>
                        <Bold>Indemnification. </Bold> Each Party (“Indemnifying Party”) shall defend, indemnify and hold the other Party and its Affiliates and their employees (“Indemnified Party”) harmless from and against any third-party claim or liability (including costs, expenses, damages, and reasonable attorneys’ fees) that may arise resulting from or in connection with the Indemnifying Party’s: (i) breach of its representations or warranties stated in this Agreement; and (ii) fraud, gross negligence, or willful misconduct.  Indemnification obligations will not arise unless: (a) the Indemnified Party provides prompt written notice to the Indemnifying Party of any third-party claim giving rise to indemnification obligations under this Agreement; (b) the Indemnified Party reasonably cooperates with the Indemnifying Party in defense or settlement of such claim at the Indemnifying Party’s expense; and (c) the Indemnified Party allows the Indemnifying Party to direct and control the defense or settlement of such claim, except that the Indemnifying Party will not enter in any settlement or entry of judgment without the prior written consent of the Indemnified Party if such judgment or settlement would result in: the requirement of the Indemnified Party to take any action (including the payment of money), the finding or admission of fault by the Indemnified Party, or injunctive or other equitable relief imposed against the Indemnified Party.                          
                        </BodyRg>
                    </OrderedListItem>
                    <OrderedListItem>
                        <BodyRg>
                            <Bold>Termination</Bold>                        
                        </BodyRg>
                    </OrderedListItem>
                    <RomanList>
                        <OrderedListItem>
                            <BodyRg>
                                <Bold>Mutual Termination Rights.</Bold>  Either Party may terminate a Statement of Work, on written notice if the other Party: (i) commits a material breach of the Agreement or an applicable Statement of Work and fails to remedy such breach within thirty (30) days after receipt of written notice of such breach; or (ii) becomes insolvent, unable to pay debts when due, files for or is subject to bankruptcy or receivership or asset assignment.                     
                            </BodyRg>
                        </OrderedListItem>
                        <OrderedListItem>
                            <BodyRg>
                            <Bold>Swoop’s Additional Termination Rights.</Bold>   Swoop may terminate any Statements of Work without penalty by providing written notice to Customer if Swoop determines that the use of or access to the relevant data needed to create the IaaS Report is prohibited by applicable law or the procurement of Swoop data needed to create the IaaS Report has become impractical or unfeasible for legal or regulatory reasons.                            </BodyRg>
                        </OrderedListItem>
                    </RomanList>
                    <OrderedListItem>
                        <BodyRg>
                        <Bold>Effect of Termination.</Bold>  Each Party will (i) return to the other Party all documents and tangible materials (and any and all copies thereof) containing, reflecting, incorporating or based on such Party’s Confidential Information, and (ii) within reasonable time, permanently erase the other Party’s Confidential Information from its computer systems, except to the extent that the receiving Party is required to retain such Confidential Information pursuant to applicable law, rule or regulation, provided that such party shall continue to comply with its confidentiality obligations hereunder for so long as such Confidential Information is retained.                        
                        </BodyRg>
                    </OrderedListItem>
                    <OrderedListItem>
                        <BodyRg>
                        <Bold>Survival. </Bold> All provisions of the Agreement which, in accordance with their terms, are intended to have effect after termination or expiration of the Agreement will survive termination or expiration of the Agreement and will apply to both Parties’ respective successors and permitted assigns.                        
                        </BodyRg>
                    </OrderedListItem>
                    <OrderedListItem>
                        <BodyRg>
                        <Bold>Force Majeure.</Bold>  Neither Party will be liable for non-performance or delayed performance of its obligations under the Agreement where such performance issues are due to causes beyond its reasonable control.                        
                        </BodyRg>
                    </OrderedListItem>
                    <OrderedListItem>
                        <BodyRg>
                        <Bold>Entire Agreement, Amendment.</Bold>  This Agreement as incorporated into an applicable Statement of Work executed by the Parties represents the entire understanding of the Parties with respect to its subject matter and supersedes all previous communications and agreements that may exist relating to the subject matter set forth in the applicable Statement of Work.                            
                        </BodyRg>
                    </OrderedListItem>
                    <OrderedListItem>
                        <BodyRg>
                        <Bold>Notices.</Bold>  All notices under this Agreement shall be provided in writing in a manner stated in applicable Statements of Work or as otherwise agreed upon by the Parties in writing.                          
                        </BodyRg>
                    </OrderedListItem>
                    <OrderedListItem>
                        <BodyRg>
                            <Bold>Governing Law.</Bold> This Agreement will be construed in accordance with the law of New York, without regard to conflicts of law principles.  The Parties agree that the United Nations Convention on Contracts for the International Sale of Goods shall not apply.   
                        </BodyRg>
                    </OrderedListItem>
                    <OrderedListItem>
                        <BodyRg>
                            <Bold>Assignment.</Bold>  Neither Party may assign or transfer the Agreement, or any rights or obligations hereunder, without the prior written consent of the other Party.  Notwithstanding the foregoing, either Party may assign or transfer the Agreement, in whole or in part, or any of its rights or obligations under the Agreement in connection with a merger, acquisition, sale or other transaction relating to all or substantially all of assets of the Party, provided that the successor entity is bound by the applicable terms and conditions of the Agreement.                          
                        </BodyRg>
                    </OrderedListItem>
                </NumberedList>

            </SwoopIpmContainer>
        </SwoopIpmPageWrap>
    );
}

export default SwoopIpmPage;
