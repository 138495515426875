import React from 'react'
import Slider from "react-slick";

import
{
    TabbedGridSectionWrap,
    Tabs,
    TabContentWrap,
    TabsContent,
} from './elements'

import { Divider } from 'pages/careers/JobsSection/elements';

import
{
    SectionTitle,
    BodySm,
    TitleXl
} from 'components/elements'

import Grid from './Grid'
// Slick Slider
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

function TabbedGrid({gridData}) {

    let tabs = (gridData && gridData.items) ? gridData.items : []
   
    const settings = {
        infinite: false,
        centerSpacing: "0",
        accessibility: true,
        margin: "20px",
        slidesToShow: 1,
        draggable: false,
        autoplay: false,
        speed: 1000,
        slidesToScroll: 1,
        dots: true,
        customPaging: (slider, i)=>{
            return <button aria-label={slider === 0 ? 'UNITED STATES' : slider === 1 ? 'UNITED KINGDOM' : 'CANADA'}/>;
        },
        swipeToSlide: true,
        responsive: [
            {
                breakpoint: 0,
                settings: {
                    speed: 600,
                }
            },
            {
                breakpoint: 991,
                settings: {
                    speed: 1000,
                }
            },
        ]
    };


    return (
        <TabbedGridSectionWrap>
            <Divider/>
            <SectionTitle padding="0 30px 0">
                <BodySm textTransform="uppercase">{gridData.label}</BodySm>
                <TitleXl>Let's talk perks<span className='magenta'>:</span></TitleXl>
            </SectionTitle>

            <Tabs>
                <TabContentWrap id="benefits" className="animate tab-contents">
                    <TabsContent className={'tabs-animate'}>
                        <Slider className="benefits-slider" {...settings}>
                            <Grid items={tabs.length ? tabs[0].elements : ''}/>
                            <Grid items={tabs.length ? tabs[1].elements : ''}/>
                            <Grid items={tabs.length ? tabs[2].elements : ''}/>
                        </Slider>
                    </TabsContent>
                </TabContentWrap>
            </Tabs>
        </TabbedGridSectionWrap>
    )
}

export default TabbedGrid

