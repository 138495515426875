import { Link } from "react-router-dom";
import styled, { css } from 'styled-components';
import {
    TitleXl
} from "components/elements";

import {
    sizeLaptop,
    sizeTabletLg,
    sizeDesktopMd,
    sizeMobileSm,
    sizeWideScreen
} from "components/devices";


export const ErrorPageOuter = styled.div`
    background-color: #000;
    padding: 80px 0 0 0;
    margin-top: -80px;
    ${sizeTabletLg(css`
        margin-top: 0;
    `)}
`;
export const ErrorPageWrap = styled.div`
    position: relative;
    background-size: contain;
    background-position: center center;
    background-repeat: no-repeat;
    background-color: #000;
    text-align: center;
    padding: 100px 0;
    box-sizing: border-box;
    border-bottom: 1px solid #fff;
    height: calc(100vh - 80px);
    z-index: 10;
    ${sizeDesktopMd(`
        padding: 50px 0;
    `)}

    ${sizeTabletLg(css`
        background-image: url(${({ backgroundMobile }) => backgroundMobile || ""});
        background-size: cover;
    `)}
    ${TitleXl} {
        font-size: 76px;
        ${sizeDesktopMd(`
            font-size: 56px;
        `)}
        ${sizeTabletLg(`
            font-size: 32px;
        `)}

    ${sizeWideScreen(`
        font-size: 2.7vw;
    `)}
    }
`;
// export const ErrorPageVideo = styled.video`
//     position: absolute;
//     left: 0;
//     top: 0;
//     width: 100%;
//     height: 100%;
//     z-index: -1;
//     object-fit: cover;
//     [poster] {
//         object-fit: cover;
//     }
// `;
export const ErrorContainer = styled.div`
    max-width: 1700px;
    margin: 0 auto;
    padding: 0 25px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    height: 100%;
    box-sizing: border-box;
    ${sizeMobileSm(`
        padding: 0 15px;
    `)}

    ${sizeWideScreen(`
        padding: 10% 0;
        max-width: 2500px;
        width: 100%;
        margin: 0 auto;
    `)}
`;
export const ErrorContent = styled.div`
 ${TitleXl}{
    color: #ffffff !important;
 }
`;



// Style
export const Arrow = styled.img`
    display: block;
    ${sizeTabletLg(`
        display: none;
    `)}
`;
export const ArrowBlue = styled.img`
    width: 0;
    transition: 0.3s all ease;
    transition-timing-function: ease-in-out;
    display: block;
    ${sizeTabletLg(`
        display: none;
    `)}
`;
export const ArrowMobile = styled(Arrow)`
    display: none;
    width: calc(100% - 40px);
    ${sizeTabletLg(`
        display: block;
    `)}
`;
export const ArrowBlueMobile = styled(ArrowBlue)`
    display: none;
    ${sizeTabletLg(`
        display: block;
    `)}
`;
export const ArrowLongWrap = styled.div`
    position: absolute;
    left: 25px;
    bottom: 50%;
    transform: translateY(+50%);
    width: 115px;
    height: 60px;
    transition: 0.3s all ease;
    transition-timing-function: ease-in-out;
    ${sizeTabletLg(`
        width: calc(100% - 50px);
        left: 20px;
    `)}
`;
export const ArrowAngle = styled.div`
    border-top: 8px solid #FFF;
    border-left: 8px solid #FFF;
    display: block;
    margin: 0;
    width: 50px;
    height: 50px;
    transform: rotate(135deg);
    position: absolute;
    right: 0;
    top: 2px;
    ${sizeTabletLg(`
        border-width: 6px;
        top: -1px;
    `)}
`;
export const ArrowLine = styled.div`
    display: block;
    width: 100%;
    height: 8px;
    background-color: #FFF;
    transform: rotate(180deg);
    left: 5px;
    top: 26.5px;
    position: absolute;
    ${sizeTabletLg(`
        height: 6px;
        top: 24px;
    `)}
`;



export const BackArrows = styled.div`
    border: 8px solid #fff;
    height: 112px;
    position: relative;
    border-radius: 130px;
    padding: 0 30px;
    overflow: hidden;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    transition: 0.3s all ease;
    border-color: ${({ borderColor }) => borderColor || "#fff"};
    transform: rotate(180deg);
    margin-top: 60px;
    position: relative;
    ${Arrow},
    ${ArrowBlue},
    ${ArrowMobile},
    ${ArrowBlueMobile} {
        position: absolute;
        left: 30px;
        bottom: 50%;
        transform: translateY(+50%);
        ${sizeLaptop(`
            left: 20px;
        `)}
    }

    ${sizeLaptop(`
        border-width: 5px;
        padding: 0 20px;
    `)}
    ${sizeTabletLg(`
        max-width: 330px;
        margin: 35px auto 0 auto;
        box-sizing: border-box;
    `)}
`;

export const BackWrap = styled.div`
    box-sizing: border-box;
    transition: 0.2s all ease;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: end;
    -ms-flex-align: end;
    align-items: flex-end;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    text-align: center;
`;
export const BackLink = styled(Link)`
    transition: 0.3s all ease;
    transition-timing-function: ease-in-out;
    width: 100%;
    ${TitleXl}{
        color: #ffffff;
    }
    &:hover {
        ${Arrow} {
            display: none;
        }
        ${ArrowBlue},
        ${ArrowLongWrap} {
            width: calc(100% - 55px);
            transition: 0.3s all ease;
            transition-timing-function: ease-in-out;
            opacity: 1;
            border-color: #D12FFF;
            ${sizeLaptop(`
                width: calc(100% - 35px);
            `)}
            ${sizeTabletLg(`
                width: calc(100% - 50px);
            `)}
        }
        ${ArrowAngle},
        ${BackArrows} {
            border-color: #D12FFF;
        }
        ${ArrowLine} {
            background-color: #D12FFF;
        }
        ${TitleXl} {
            color: #D12FFF;
        }
    }
`;
