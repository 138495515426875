import React from "react";
import styled  from 'styled-components';
import {BodySm, H6} from "./elements";
import { sizeMobileLg } from "./devices";


export const EventScheduleBlock = styled.div`
  padding: 24px;
  width: 100%;
  border: 1px solid #000000;
  height: 100px;
  h6 {
    // max-width: 210px;
  }
  ${sizeMobileLg(`
    padding: 24px 12px;
  `)}
`;


function EventScheduleCard({ label, detail }) {
    return (
        <EventScheduleBlock>
            <BodySm margin={'0 0 15px 0'}>{label}</BodySm>
            <H6 lineHeight={'1.25'}>{detail}</H6>
        </EventScheduleBlock>
    );
}

export default EventScheduleCard;
