
import React from 'react'
import ReleaseIcon from "../assets/icons/ReleaseIcon";
import PodcastIcon from "../assets/icons/PodcastIcon";
import EventsIcon from "../assets/icons/EventsIcon";
import BlogIcon from "../assets/icons/BlogIcon";

export const DynamicContent = true
export const Verbose = true

// 
// Determine which r3c instance to use.  This decision is based on the value of the
// REACT_APP_RC_ENV shell environment variable.  We will also set the Google reCAPTCHA
// site key appropriately.
//
// NOTE: For local, we currently use `r3c-dev` and a common Google reCAPTCHA key; one that
// allows `localhost` -- the rest use a "real" site key that allows *.realchemistry.com
//

const r3cUrls = 
{
	local :	'https://57nwwhnpid.execute-api.us-east-1.amazonaws.com/dev',
	dev :	'https://57nwwhnpid.execute-api.us-east-1.amazonaws.com/dev',
	exp :	'https://lmfoa0avtd.execute-api.us-east-1.amazonaws.com/exp',
	rvw :	'https://6c7xoo31va.execute-api.us-east-1.amazonaws.com/rvw',
	tst :	'https://3bdygmteic.execute-api.us-east-1.amazonaws.com/tst',
	master: 'https://bxx2opi688.execute-api.us-east-1.amazonaws.com/master'
}

const liveRecaptchaSiteKey = '6LdzECAcAAAAAMgqY7EGrm73YazXRegHJ_Ft2UuD'
const localRecaptchaSiteKey = '6LfKyGwcAAAAAPk6Fl6T4esD83nBfA-KTlB-d883'

export var RecaptchaSiteKey = liveRecaptchaSiteKey
export var RecaptchaBotScore = .5

let env = process.env.REACT_APP_RC_ENV

console.log( `REACT_APP_RC_ENV = ${env}` )

if ( ! env )
{
	console.warn( `REACT_APP_RC_ENV unset -- defaulting to local` )
	env = 'local'
}

if ( ! r3cUrls[env] )
{
	console.warn( `REACT_APP_RC_ENV ${env} unknown; using local` )
	env = 'local'
}

if ( env === 'local' )
{
	RecaptchaSiteKey = localRecaptchaSiteKey
	env = 'dev'
}

console.log( `RC env = ${env}` )
console.log( `RecaptchaSiteKey = ${RecaptchaSiteKey}` )

export const r3cBase = r3cUrls[env]
export const r3cJobDetail = r3cBase + '/content/job'
export const r3cPageContent = r3cBase + '/content/page'
export const r3cPardotProxy = r3cBase + '/pardot'
export const r3cRssFeed = r3cBase + '/feed'
export const r3cVerifyRecaptcha = r3cBase + '/verify-recaptcha'
export const r3cContent = r3cBase + '/content'


//
// Used by PlayMarquee
//

export const playArray = Array.from(Array(20).keys()).map((item) =>
{
	return {itemRef: React.createRef(null)}
})

export const eyeBrows = {
	'all':'NEWS + EVENTS',
	'PressRelease': 'PRESS RELEASES',
	'Podcast': 'PODCASTS',
	'Event': 'EVENTS',
	'BlogPost': 'BLOG'
}

export const newsEventsPages = {
	'/news-events':'all',
	'/blog':'BlogPost',
	'/events':'Event',
	'/podcasts':'Podcast',
	'/press-releases':'PressRelease'
	
}
export const mediaIcons = {
	'PressRelease': <ReleaseIcon/>,
	'Podcast': <PodcastIcon/>,
	'Event': <EventsIcon/>,
	'BlogPost': <BlogIcon/>
}


