import React  from 'react';
import {withRouter} from "react-router-dom"
import { TitleXl } from "components/elements";
import {LocationTabsContainer, SliderContainer} from "./elements"
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import LocationGrid from './LocationGrid';

function LocationTabs({locationDataUs, locationDataEu, locationDataCa}) {


    const settings = {
        infinite: false,
        accessibility: true,
        centerSpacing: "0",
        margin: "20px",
        slidesToShow: 1,
        draggable: false,
        autoplay: false,
        speed: 1000,
        slidesToScroll: 1,
        dots: true,
        customPaging: (slider, i)=>{
            return <button aria-label={slider === 0 ? 'UNITED STATES' : slider === 1 ? 'EUROPE' : 'CANADA'}/>;
        },
        swipeToSlide: true,
        responsive: [
            {
                breakpoint: 0,
                settings: {
                    speed: 600,
                }
            },
            {
                breakpoint: 991,
                settings: {
                    speed: 1000,
                }
            },
        ]
    };

    return(
        <LocationTabsContainer>
            <TitleXl fontWeight={"900"}>
                Our Locations
            </TitleXl>

        <SliderContainer>
            <Slider {...settings}>
                <LocationGrid data={locationDataUs}/>
                <LocationGrid data={locationDataEu}/>    
                <LocationGrid data={locationDataCa}/>    
            </Slider>
        </SliderContainer>

        </LocationTabsContainer>
    )
}

export default withRouter(LocationTabs);
