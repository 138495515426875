import React, { useEffect } from 'react';
import {
    WistiaEmbedWrapper
} from "./elements";

const WistiaPopupEmbed = (props) => {

    const { hashedId, id, ...embedOptions } = { ...props }


    useEffect(() => {
        window._wq = window._wq || [];
        window._wq.push({ id: props.hashedId, options: embedOptions });
        return () => {
            window._wq = window._wq || [];
        }
        //eslint-disable-next-line
    }, [props.id])


    return (
        /*   <div id={props.id} className={`${props.customClass} wistia_embed wistia_async_${props.hashedId} videoFoam=true`}
                style={{height: '100% !important', width: '100% !important'}}>&nbsp;</div>*/
        <WistiaEmbedWrapper className={`${props.customClass} wistia_embed popover=true  popoverPreventScroll=true wistia_async_${props.hashedId} videoFoam=true`}
            style={{ height: '100%', position: 'relative', width: '100%' }}>
            <div className="wistia_swatch"
                style={{ height: '100%', left: 0, opacity: 0, overflow: 'hidden', position: 'absolute', top: 0, transition: 'opacity 200ms', width: '100%' }}>
                <img src={`https://fast.wistia.com/embed/medias/${props.hashedId}/swatch`}
                    style={{ filter: 'blur(5px)', height: '100%', objectFit: 'contain', width: '100%' }} alt="" aria-hidden="true"
                /></div>
        </WistiaEmbedWrapper>
    )


}

export default WistiaPopupEmbed
