import React from 'react';
import { CardTextWrapper, SmallCardWrapper, SmallCardContainer } from "./elements";
import { BodySm } from "components/elements";

function SmallCard({ data }) {
  return (
    <SmallCardWrapper className="leadership block">
      <SmallCardContainer>
        <CardTextWrapper>
          <BodySm fontWeight={'bold'} margin="0 0 3px 0">{data.label}</BodySm>
          <BodySm  >{data.title}</BodySm>
        </CardTextWrapper>
      </SmallCardContainer>
    </SmallCardWrapper>
  );
}

export default SmallCard;