import React from 'react'
import styled from 'styled-components'


// Media Button
export const MediaBtn = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #000;
  background-color: transparent;
  padding: 8px 12px;
  text-transform: uppercase;
  cursor: pointer;
  border-radius: 0;
  color: #000;
  transition: 0.3s all ease;
  font-weight: 500;
  font-size: 16px;
  line-height: 1.2;
  font-family: 'HelveticaNowDisplay',serif;

  svg {
    width: 32px;
    margin-right: 7px;

    path, rect {
      //fill: #000;
    }
  }
  &.active {
    color: #fff !important;
    background-color: #000 !important;
    border-color: #000 !important;

    svg {
      path, rect {
        fill: #fff !important;
      }
      rect{
        stroke: #000 !important;
      }
    }
    &:after {
      background-color: #000 !important;
    }
  }

  &:hover {
    color: #0F20FF;
    border-color: #0F20FF;
    background-color: transparent;

    svg {
      path {
        fill: #0F20FF;
      }
      rect {
        stroke: #0F20FF;
      }
    }
  }
`;


function MediaButton({id, icon, text, status,handleChange,index}) {

    return (
        <MediaBtn onClick={()=>{handleChange(id,index)}} id={id} className={status}>
            {icon}
            {text}
        </MediaBtn>
    )
}

export default MediaButton;
