import styled from 'styled-components';
import {
    TitleXl,
    TitleMd,
} from "components/elements";
import {
    sizeMobileLg,
    sizeTablet,
    sizeTabletLg
} from "components/devices";

export const Container = styled.div`
    width: 100%;
    padding: 8% 8.5% 0%;
    ${sizeTabletLg(`
     padding: 15% 4.5% 5%;
    `)}
    ${TitleXl}{
        font-size: 48px;
        ${sizeMobileLg(`
            font-size: 36px;
       `)}
    }

`

export const ThumbnailContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    padding: 3% 0;
    ${sizeMobileLg(`
        padding: 10% 0 0;
    `)}
`

export const Thumbnail = styled.div`
    background-image: ${({backgroundImage}) => `url('${backgroundImage}')` || ""};
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    text-align: center;
    flex: ${({flex}) => flex || '1 0 50%'};
    max-width: 50%;
    margin: 8px;
    overflow: hidden;
    height: 365px;
    ${sizeTablet (`
        max-width: 100%;
        flex: 1 0 100%;
        height: 250px;
        margin: 8px 0;
        ${TitleMd}{
            font-size: 16px;
        }
        ${TitleXl}{
            font-size: 20px;
        }
   `)}
`

export const Overlay = styled.div`
    width: 100%;
    height: 365px;
    margin: 0 auto;
    background: rgba(105, 0, 255, 0.87);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 15% 2% 10%;
    ${sizeTablet(`
        height: 250px;
        padding: 5% 3%;
    `)}
    ${sizeMobileLg(`
        padding: 12% 3% 10%;
    `)}

`


export const CopyContainer = styled.div`
    ${TitleMd}{
        font-size: 16px;
        padding: 0 0 3%;
        text-transform: uppercase;
    }
    ${TitleXl}{
        color: #ffffff;
        font-size: ${({ fontSize }) => fontSize || "28px"};
    }
    ${sizeMobileLg (`
        ${TitleMd}{
            font-size: 16px;
            padding: 0 0 2%;
        }
        ${TitleXl}{
            font-size: 20px;
        }
    `)}
`