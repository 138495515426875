import styled from 'styled-components'
import {sizeDesktopMd, sizeDesktopSm, sizeLaptop, sizeLaptopLg, sizeLaptopMd, sizeTabletLg} from "../devices";

export const CardSelectorWrapper = styled.div`
  overflow: hidden;

  ${sizeTabletLg(`display:none`)}
`;

export const CardSelectorRow = styled.div`
  border-top: 1px solid #707070;
  border-bottom: 1px solid #707070;
  .card-selector-left {
    border-right: 1px solid #707070;
  }
`;


export const SmallCardContainer = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
  border-bottom: 1px solid #707070;
`;
export const SmallCardWrapper = styled.div`
  padding: 35px 16px 0 45px;
  height: 146px;
  transition: .4s all ease;
  cursor: pointer;
  box-sizing: border-box;
  ${sizeDesktopSm(`
    padding: 35px 16px 0 20px;
  `)}
  ${sizeLaptopLg(`
    padding: 20px 15px 0 15px;
  `)}
  ${sizeLaptopMd(`
    padding: 15px 15px 0 15px;
  `)}

  &:hover {
    background: #0f20ff;

    p {
      color: white;
    }

    ${SmallCardContainer} {
      border: none;
    }
  }
`;
export const CardTextWrapper = styled.div`
  align-self: flex-start;
`;

export const DisplayCardWrapper = styled.div`
  transition: .4s all ease;
  padding: 30px 104px 30px 30px;
  
  box-sizing: border-box;
  border-left: 1px solid #707070;
  margin-left: -1px;
`;

export const DisplayCardHead = styled.div`
  display: flex;
  justify-content: flex-start;

`;

export const DisplayCardTitle = styled.div`
  padding-left: 16px;
  box-sizing: border-box;
  width: 100%;

`;

export const DisplayCardBody = styled.div`
  margin: 16px 0 0 0;

  p {
    margin-bottom: 16px;
    font-size: 16px;
  }

`;

export const Avatar = styled.img`
  width: 100%;
  align-self: flex-start;
`;
export const AvatarWrapper = styled.div`
  max-width:330px;
  min-height:410px;
  width:100%;
  height: 100%;
  ${sizeDesktopMd(`
    min-height: 300px;  
  `)}
  ${sizeLaptopLg(`
    max-width: 45%;
    width: 100%;
    min-height: 250px;
  `)}
  ${sizeLaptop(`
    min-height: 170px;
  `)}
`

export const CtaLink = styled.a`
  display: inline-block;
  margin: 36px 8px 8px 0;
`;
