import React, { useEffect } from 'react'
// import gsap from 'gsap'

import
{
  BenefitsSectionWrap
} from './elements'

import
{
  SectionTitle,
  TitleXl
} from 'components/elements'
import TabbedItems from './ItemsSection'
import TwoColumnTabbedItems from './TwoColumnTabbedItems'

function TabNavigation({label, titleTop, titleBottom, items, id, sectionTitlePad, backgroundColor, infoSectionData}) {
  let tabs = items ?? []
  const [windowWidth, setWindowWidth] = React.useState();

  useEffect(() => {
    const handleResize = () => {
      window.innerWidth < 570 ? setWindowWidth(true) : setWindowWidth(false)
    }

    window.addEventListener("resize", handleResize);
    handleResize();
    return () => window.removeEventListener("resize", handleResize);
  })


  // const [activeTab] = React.useState(1)

  // const handleTabClick = (tab) => {
  //
  //   /*
  //       gsap.fromTo('.animate', {
  //           duration: 1,
  //           opacity: 0,
  //           delay: 0.1,
  //           stagger: 0.2,
  //           force3D: true,
  //       }, { opacity: 1 })
  //   */
  //   setActiveTab(tab)
  // }

  return (
    <BenefitsSectionWrap id={id} backgroundColor={backgroundColor}>
        {windowWidth && (
            <SectionTitle padding={sectionTitlePad}>
                <TitleXl >{titleTop} {titleBottom}</TitleXl>
             </SectionTitle>
        )}
        {!windowWidth && (
          <SectionTitle padding={sectionTitlePad}>
              <TitleXl>{titleTop}</TitleXl>
              <TitleXl>{titleBottom}</TitleXl>
           </SectionTitle>
        )}
      <TwoColumnTabbedItems items={tabs.length ? tabs[0].elements : ''} />
      <TabbedItems  items={tabs.length ? tabs[1].elements : ''} infoSectionData={infoSectionData}/>
    </BenefitsSectionWrap>
  )
}

export default TabNavigation
