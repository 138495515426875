import styled from 'styled-components';
import {
    TitleXl,
    BodySm,
    TitleSm, 
    Row,  
    Col6
} from "components/elements";
import {
    sizeMobileLg,
    sizeLaptopMd,
    sizeTabletLg,
    sizeTablet
} from "components/devices";
// import { CircleContainer, InnerCircle, OuterCircle } from 'components/OverlapCircles/elements';


export const AboutUsBottomContainer = styled.div`
    width: 100%;
    height: 100%;
    padding: 6% 9%;
    ${sizeTabletLg (`
        padding: 5% 4.5%;
    `)}
`

export const RowContainer = styled.div`
    margin-top: 15%;
    ${sizeMobileLg (`
        margin-top: 50px;
    `)}
    ${Row} {
        ${sizeTabletLg (`
            flex-direction: column;
            justify-content: flex-start;
            width: 100%;
    `)}

}
${Col6}{
    ${sizeTabletLg (`
        width: 100%;
        max-width: 100%;
        min-width: 100%;
    `)}
    &:nth-child(odd){
        padding-right: 10%; 
        margin-top: -5%;
       ${sizeTabletLg (`
            padding: 20px 0;
            margin-top: 0;
    `)}
    }
    &:nth-child(even){
        margin-top: 25%;
        padding-left: 14%;
        justify-content: end;
        alignItems: flex-end;
        ${sizeLaptopMd (`
           padding: 0 20px 0;
    `)}
        ${sizeTabletLg (`
            margin-top: 0;
            margin-right: 0;
            padding: 40px 0;
        `)}
    }
}
`

export const TitleXlContainer = styled.div`
    max-width: 70%;
    ${sizeTablet(`
        max-width: 90%;
    `)}
    ${sizeMobileLg(`
        max-width: 100%;
        width: 100%;
    `)}
    ${TitleXl}{
        font-size: 48px;
        line-height: 57px;
        font-weight: 900;
        ${sizeMobileLg (`
             font-size: 36px;
             line-height: 43px;
        `)}
    }
`
export const ColWrapper = styled.div`
    width: 100%;
    ${sizeTabletLg (`
        width: 100%;
    `)}
`

export const TitleSmContainer = styled.div`
    padding: 6% 0;
    ${sizeTabletLg (`
       text-align: center;
    `)}
    ${sizeMobileLg (`
        text-align: left;
        padding: 20px 0;
    `)}
    ${TitleSm}{
        font-size: 36px;
        font-weight: 900;
        line-height: 43px;
        background: linear-gradient(180deg, #A036F5 34.9%, #8C31F4 68.23%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
        text-fill-color: transparent;
        ${sizeMobileLg (`
            font-size: 20px; 
            line-height: 24px;
        `)}
    }
`

export const BodySmContainer = styled.div`
     max-width: 90%;
     ${sizeMobileLg (`
     max-width: 100%;
   `)}
    ${BodySm}{
        font-size: 20px;
        line-height: 24px;
        ${sizeMobileLg (`
         font-size: 16px;
         line-height: 19px;
    `)}
    }
`

export const NumbersContainer = styled.div`
    width: 110px;
    height: 110px;
    border: 12px solid #000;
    display: flex;
    justify-content: center;
    align-items: center;
 
    ${sizeTabletLg (`
        margin: 0 auto;
    `)}
    ${sizeMobileLg (`
        border: 9px solid #000;
        width: 81px;
        height: 81px;
        margin: 0;
    `)}

    ${TitleSm}{
        font-size: 90px;
        color: #000;
        font-weight: 700;
        ${sizeMobileLg (`
            font-size: 60px;
        `)}
    }
`