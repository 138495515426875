import React from "react";
import { AccordionSectionWrap } from "./elements";
import AccordionCard from "components/Accordion/accordion";

function AccordionSection(props) {
    return (
        <AccordionSectionWrap className="acc-cont">
            <AccordionCard
                openOtherAccordion={props.openOtherAccordion.filter(
                    (a) => a.id === "updatesPrivacyPolicy"
                )}
                handleOpenAccordion={props.handleOpenAccordion}
                handleToggleOtherAccordion={props.handleToggleOtherAccordion}
                cardId="updatesPrivacyPolicy"
                title="Digital Acceleration + The Tech-Enabled Patient"
                body={[
                    {
                        paragraphs: [
                            "<strong>Download creative asset <a target='_blank' href='https://ent.box.com/s/sv21l004f99vj92ra8zut4x1fewlontf'>here</a>.</strong>",
                            "<strong>LinkedIn Copy Options – highlight and copy your preferred LinkedIn post from the options below.</strong>",

                            "<div style='margin-left: 50px'><strong style='padding: 20px 0'>Option 1</strong><br/>Over the past two years, patients embraced technology as a critical part of engaging with #health care providers and managing their health. Now, we're seeing the emergence of the \"tech-enabled patient\" — and it's up to the health care industry to keep up. </div>",
                            "<div style='margin-left: 50px'>If you're attending or streaming #SXSW 2022, join me and Real Chemistry on March 11 for our discussion about supporting patients in their digital health journeys. <a href='https://bit.ly/3JNLRAg'>bit.ly/3JNLRAg</a> #RealChemSXSW</div>",
                            "<div style='margin-left: 50px'><strong style='padding: 20px 0'>Option 2</strong><br/>I'll be at #SXSW 2022 with Real Chemistry! If you're in Austin or tuning in virtually, check out my panel about exploring the digitalization of #health care — #telehealth, online medical records, and expanded patient data sets — and how these innovations are giving patients more control of their health. <a href='https://bit.ly/3JNLRAg'>bit.ly/3JNLRAg</a> #RealChemSXSW</div>",
                            "<div style='margin-left: 50px'><strong style='padding: 20px 0'>Option 3</strong><br/>Technology has changed #health care, allowing our industry to leverage data to drive better patient experiences. But not all patients are alike — how do we harness this knowledge to provide culturally sensitive and personalized patient engagement?</div>",
                            "<div style='margin-left: 50px'>If you’re attending #SXSW, join me, fellow health innovators, and Real Chemistry for our discussion on March 11! <a href='https://bit.ly/3JNLRAg'>bit.ly/3JNLRAg</a> #RealChemSXSW</div>",

                            "<strong>Twitter Copy Options – highlight and copy your preferred tweet from the options below.</strong>",
                            "<div style='margin-left: 50px'><strong style='padding: 20px 0'>Option 1</strong><br/>Tech-savvy patients are changing #health care, and it's up to our industry to optimize their experiences & build personalized care. If you’ll be at #SXSW 2022, join me on March 11 to discuss this transformation with @RealChemistry_! <a href='https://bit.ly/3JNLRAg'>bit.ly/3JNLRAg</a> #RealChemSXSW</div>",
                            "<div style='margin-left: 50px'><strong style='padding: 20px 0'>Option 2</strong><br/>How do we keep up with tech-enabled patients and ensure digital access for all? I'm looking forward to addressing this at #SXSW with @RealChemistry_, and my fellow panelists! <a href='https://bit.ly/3JNLRAg'>bit.ly/3JNLRAg</a> #RealChemSXSW</div>",
                            "<div style='margin-left: 50px'><strong style='padding: 20px 0'>Option 3</strong><br/>Attending #SXSW 2022? Join me along with @RealChemistry_ and fellow health leaders as we explore how COVID 19 accelerated #digitalhealth and how we can help patients navigate these changes and take more control of their health. <a href='https://bit.ly/3JNLRAg'>bit.ly/3JNLRAg</a> #RealChemSXSW</div>",
                        ]
                    }
                ]}
                index={1}
            />
            <AccordionCard
                openOtherAccordion={props.openOtherAccordion.filter(
                    (a) => a.id === "healthEquity"
                )}
                handleOpenAccordion={props.handleOpenAccordion}
                handleToggleOtherAccordion={props.handleToggleOtherAccordion}
                cardId="healthEquity"
                title="Going Beyond Clinical Trials to Advance Health Equity"
                body={[
                    {
                        paragraphs: [
                            "<strong>Download creative asset <a target='_blank' href='https://ent.box.com/s/efuzmvy505icweyu3x5mpnku8odfwdjn'>here</a>.</strong>",
                            "<strong>LinkedIn Copy Options – highlight and copy your preferred LinkedIn post from the options below.</strong>",

                            "<div style='margin-left: 50px'><strong style='padding: 20px 0'>Option 1</strong><br/>After two years of a global pandemic, the world is more aware than ever of the need for community and public #health. It's time to take action and increase #healthequity for under-served and marginalized communities. </div>",
                            "<div style='margin-left: 50px'>Will you be at #SXSW 2022? Join me on March 11 in Austin or virtually with fellow health leaders and @RealChemistry_! We'll discuss how we can improve health outcomes for under-served and marginalized communities to advance #healthequity. #RealChemSXSW <a href='https://bit.ly/3LUH41V'>bit.ly/3LUH41V</a></div>",
                            "<div style='margin-left: 50px'><strong style='padding: 20px 0'>Option 2</strong><br/>I'm looking forward to taking the stage at #SXSW with Real Chemistry and fellow #health innovators and leaders to share our visions for a healthier and more equitable future. Join me on March 11 if you’re attending/tuning in virtually! #RealChemSXSW <a href='https://bit.ly/3LUH41V'>bit.ly/3LUH41V</a></div>",
                            "<div style='margin-left: 50px'><strong style='padding: 20px 0'>Option 3</strong><br/>Ensuring a healthier future means building #healthequity for all, especially for communities of color who have been historically under-served and marginalized. Together with Real Chemistry and my fellow #health innovators, I'll be sharing how we can make this future a reality at #SXSW 2022. If you’re attending or tuning in virtually, join on March 11! #RealChemSXSW <a href='https://bit.ly/3LUH41V'>bit.ly/3LUH41V</a></div>",

                            "<strong>Twitter Copy Options – highlight and copy your preferred tweet from the options below.</strong>",
                            "<div style='margin-left: 50px'><strong style='padding: 20px 0'>Option 1</strong><br/>Will you be at #SXSW 2022? Join me on March 11 in Austin or virtually with fellow health leaders and @RealChemistry_! We'll discuss how we can improve health outcomes for under-served and marginalized communities to advance #healthequity. #RealChemSXSW  <a href='https://bit.ly/3LUH41V'>bit.ly/3LUH41V</a>bit.ly/3JNLRAg</a></div>",
                            "<div style='margin-left: 50px'><strong style='padding: 20px 0'>Option 2</strong><br/>Looking forward to taking the #SXSW 2022 stage on March 11 with @RealChemistry_ to share visions for a future we can create together: #healthequity for all. If you’ll be there virtually or in person, join me! #RealChemSXSW  <a href='https://bit.ly/3LUH41V'>bit.ly/3LUH41V</a></div>",
                            "<div style='margin-left: 50px'><strong style='padding: 20px 0'>Option 3</strong><br/>We've identified pressing #health inequities. Now let's make sure marginalized communities aren't left behind & build greater #healthequity. If you’re attending/streaming #SXSW 2022, join the discussion with me & @RealChemistry_! #RealChemSXSW  <a href='https://bit.ly/3LUH41V'>bit.ly/3LUH41V</a></div>",
                        ]
                    }
                ]}
                index={2}
            />
            <AccordionCard
                openOtherAccordion={props.openOtherAccordion.filter(
                    (a) => a.id === "useData"
                )}
                handleOpenAccordion={props.handleOpenAccordion}
                handleToggleOtherAccordion={props.handleToggleOtherAccordion}
                cardId="useData"
                title="Instructions for Sharing a LinkedIn Post"
                body={[
                    {
                        numberedlist: [
                            "Save your preferred image to your computer.",
                            "Login to LinkedIn.",
                            "At the top of your homepage, you’ll see a box that says, “Start a post”’. ",
                            "Click into the “Start a post” Box.",
                            "Copy and paste your preferred LinkedIn copy into the text box.",
                            "Along the bottom, there’s a rectangle with a mountain landscape. If you hover over the button, it says “Add a photo.”",
                            "Click that button and upload the image you saved to your computer. ",
                            "Click done.",
                            "Click post!",
                        ],
                    },
                ]}
                index={3}
            />
            <AccordionCard
                openOtherAccordion={props.openOtherAccordion.filter(
                    (a) => a.id === "researchServices"
                )}
                handleOpenAccordion={props.handleOpenAccordion}
                handleToggleOtherAccordion={props.handleToggleOtherAccordion}
                cardId="researchServices"
                title="Instructions for Sharing a Tweet"
                body={[
                    {
                        numberedlist: [
                            "Save the image attached to your computer.",
                            "Login to Twitter.",
                            "At the top of your homepage, you’ll see a box that says, “What’s happening?”’",
                            "Copy and paste the Twitter copy above into the “What’s happening?” box",
                            "A thumbnail image will appear – click the small “x” on the top left-hand side of the image to remove.",
                            "Along the bottom, there’s a rectangle with a mountain landscape. If you hover over the button, it says “Media.”",
                            "Click that button and upload the image you saved to your computer.",
                            "Click tweet!",
                        ],
                    },
                ]}
                index={4}
            />
        </AccordionSectionWrap>
    );
}

export default AccordionSection;
