import styled from 'styled-components';
import {
    TitleXl,
    BodySm,
    TitleSm
} from "components/elements";
import {
    sizeTabletLg,
    sizeMobileLg,  
    sizeLaptopLg, 
    sizeTablet,
    sizeMobileSm,
    sizeLaptop,
    sizeMobileXs,
} from "components/devices";
import { Circle } from 'components/OverlapCircles/elements';

export const HowWeWorkContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    .sixBuImage {
        width: 575px;
        max-width: 100%;
        margin: 50px auto;
    }

.six-bu-container {
    display: flex;
    justify-content: center;
    align-items: center;
}

${sizeTablet(`
    overflow-x: hidden;
    .six-bu-container {
        padding: 0 4.3%;
    }
    .sixBuImage {
        margin: 20px auto;
    }
`)}

`
export const HowWeWorkTopContainer = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 65%;
    padding: 8% 0 0 9%;
    ${sizeLaptopLg(`
        width: 80%;
        padding-left: 75px;
    `)}
    ${sizeTabletLg(`
        width: 100%;
        padding-top: 12%;
        padding-left: 16px;
        padding-right: 16px;
    `)} 
`
export const TitleXlContainer = styled.div`
    max-width: 85%;
    ${sizeMobileLg(`
         max-width: 100%;
    `)}
    ${TitleXl} {
        font-size: 48px;
        font-weight: 900;
        line-height: 57px;
        ${sizeMobileLg(`
            font-size: 36px;
            line-height: 43px; 
       `)}
    }
  
`
export const BodySmContainer = styled.div`
     align-self: flex-end;
     padding-top: 70px;
     max-width: 65%;
     width: 100%;
     ${sizeMobileLg(`
        padding-top: 30px;
        max-width: 100%;
    `)} 
    ${TitleSm}{
        font-size: 36px;
        line-height:43px;
        font-weight: 400;
        background: linear-gradient(180deg, #A036F5 34.9%, #8C31F4 68.23%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
        text-fill-color: transparent;
        text-align: left;
        ${sizeMobileLg(`
            font-size: 20px;
            line-height: 24px;
    `)} 
    }
    ${BodySm}{
        font-size: 20px;
        line-height: 24px;
        padding: 18px 0 0;
        ${sizeMobileLg(`
            font-size:16px;
            line-height: 19px;
            padding: 20px 0 0;
    `)} 
`
export const LogoContainer = styled.div`
    display: block;
    padding-top: 130px;
    align-self: center;
    align-items: flex-start;
    padding-bottom: 20px; 
    ${sizeTabletLg(`
        align-self: center;
        padding: 12% 4.3%;
`)}
    ${TitleXl} {
        font-size: 35px;
        line-height: 42px;
        font-weight: black;
        text-align: center;
        ${sizeMobileLg(`
            font-size: 27px;
            line-height: 32px;
            text-align: left;
       `)}
       ${sizeMobileXs(`
          font-size: 24px;
          line-height: 28px;
       `)}
    }
    .realChemLogo {
        padding-top: 10px;
        width: 420px;
        ${sizeMobileLg(`
            width: 100%;
            margin: 0 auto;
        `)}
    }
`
export const CircleSection = styled.div`
    position: relative;
    align-self: center;
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;
    min-height: 900px;
    height: auto;
    padding: 40px 0 0;
    ${sizeMobileLg(`
        padding: 0 0 0;
    `)}
`
export const LeftCircleContainer = styled.div`
    position: relative;
    left: 88px;
    top: 190px; 
    ${sizeTabletLg (`
        top: 190px;
        left: 100px;
        ${Circle}{
            width: 330px;
            height: 330px;
            ${TitleXl}{
                font-size: 26px;
                line-height: 28px;
            }
        }
    `)}
    ${sizeMobileLg(`
        ${Circle}{
            width: 300px;
            height: 300px;
            ${TitleXl}{
                font-size: 20px;
                line-height: 24px;
            }
        }
        position: absolute;
        left: 0;
        margin-left: -20px;
        top: 0
    `)}
`
export const LollipopStickLeft = styled.div`
    position: absolute;
    border-left: 5px solid;
    border-bottom: 5px solid;
    border-color: ${({borderColor}) => borderColor};
    background-color: transparent;
    height: 350px;
    width: 170px;
    left: 49%;
    top: 382px;
    ${sizeTabletLg (`
        top: 320px;
        left: 48%;
        width: 110px;
    `)}
    ${sizeMobileLg(`    
       top: 240px;
       height: 1120px;
       width: 40px;
       left: 10%;
    `)}
`
export const MiddleCircleContainer = styled.div`
    position: relative;
    z-index: 100;
    top: 0px;
    ${sizeTabletLg (`
        top: 0;
        ${Circle}{
            width: 330px;
            height: 330px;
            ${TitleXl}{
                font-size: 26px;
                line-height: 28px;
            }
        }
    `)}
    ${sizeMobileLg(`
        ${Circle}{
            width: 300px;
            height: 300px;
            ${TitleXl}{
                font-size: 20px;
                line-height: 24px;
            }
        }
       top: 240px;
    `)}

`
export const LollipopStickMiddle = styled.div`
    position: absolute;
    border-color: ${({borderColor}) => borderColor};
    background-color: ${({backgroundColor}) => backgroundColor};
    height: 80px;
    width: 5px;
    left: 49%;
    top: 382px;
    ${sizeTabletLg (`
        top: 320px;
    `)}
    ${sizeMobileLg(`
        top: 240px;
        left: 12%;
        height: 450px;
    `)}
`
export const RightCircleContainer = styled.div`
    position: relative;
    right: 88px;
    top: 190px;
    ${sizeTabletLg (`
        top: 190px;
        right: 95px;
        ${Circle}{
            width: 330px;
            height: 330px;
            ${TitleXl}{
                font-size: 26px;
                line-height: 28px;
            }
        }
    `)}
    ${sizeMobileLg(`
        ${Circle}{
            width: 300px;
            height: 300px;
            ${TitleXl}{
                font-size: 20px;
                line-height: 24px;
            }
        }
        position: absolute;
        z-index: 99;
        right: 0;
        margin-right: -20px;
        top: 480px;
    `)}
        ${sizeMobileSm(`
        //  top: 520px;
    `)}
`
export const LollipopStickRight = styled.div`
    position: absolute;
    border-right: 5px solid;
    border-bottom: 5px solid;
    border-color: ${({borderColor}) => borderColor};
    background-color: transparent;
    height: 350px;
    width: 170px;
    right: 49%;
    top: 382px;
    ${sizeTabletLg (`
    top: 320px;
    right: 48%;
    width: 110px;
`)}
    ${sizeMobileLg(`
        top: 235px;
        width: 130px;
        height: 645px;
        right: 12%;
    `)}
`
export const AiPlusIdeasSectionContainer = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 100%;
    max-height: 550px;
    z-index: -200;
    margin-top: -160px;
    ${sizeTabletLg (`
    margin-top: -220px;
    `)}
    ${sizeMobileLg(`
        margin-top: 355px;
        margin-left: -53px;
    `)}
`
export const AiPlusIdeas = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .plusSignImage {
        width: 58px;
        padding: 12px 0;
    }
    ${TitleXl}{
        font-size: 70px;
        line-height: 84px;
        font-weight: 900;
        background: linear-gradient(180deg, #A036F5 34.9%, #8C31F4 68.23%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
        text-fill-color: transparent;
        ${sizeMobileLg(`
            font-size: 50px;
            line-height: 60px;

    `)}
    }
`

export const SmallCirclesContainer = styled.div`
    position: relative;
    top: 70px;  
    left: 100px;
    ${sizeTabletLg (`
        width: 50%;
        left: 78px;
        ${Circle}{
            width: 182px;
            height: 182px;
            ${TitleXl}{
                font-size: 18px;
                line-height: 21px;
            }
        }
    `)}
    ${sizeMobileLg(`
        width: 100%;
        justify-content: space-around;
        top: 355px;
        left: 30px;
        ${Circle}{
            width: 160px;
            height: 160px;
        }

    `)}
`
export const SmLeftCircleContainer = styled.div`
${sizeMobileLg(`
    padding: 0 0;
    position: relative;
    left: -8px;
    
`)}
`
export const SmRightCircleContainer = styled.div`
 ${sizeMobileLg(`
    padding: 0 0;
    position: relative;
    left: -8px;
    
`)}
`
export const MiddleBorder = styled.div`
    position: relative;
    left: 89px;
    width: 5px;
    height: 50px;
    color: #6900FF;
    background: #6900FF;
 ${sizeMobileLg(`
    left: 65px;
    width: 5px;
    height: 35px;
`)}

`


export const HealthCareExperienceContainer = styled.div`
    position: relative;
    width: 90%;
    padding-left: 9%;
    padding-top: 6%;
    ${sizeTabletLg (`
        width: 100%;
        padding: 10% 18px 0;
    `)} 
`

export const TitleContainer = styled.div`
    max-width: 55%;
    ${sizeLaptop(`         
        max-width: 80%;
    `)}
    ${sizeMobileLg(`         
        max-width: 100%;
    `)}
    ${TitleXl} {
        font-size: 48px;
        line-height: 57px;
        font-weight:900;
        ${sizeMobileLg(`
            font-size: 36px;
            line-height: 43px;
    `)}
}
`

export const TitleSmBodySmContainer = styled.div`
    max-width: 100%;
    position: relative;
    float: right;
    padding: 50px 0 0;
    ${sizeTabletLg(`         
        padding: 50px 0 0 0;
        float: left
        left: 0;
    `)}
    ${sizeMobileLg(`         
        max-width: 100%;
        padding: 20px 0 0;
    `)}
    ${TitleSm}{
        font-size: 36px;
        line-height: 43px;
        ${sizeMobileLg(`         
            font-size: 20px;
            line-height: 24px;
    `)}
    }
    ${BodySm} {
        font-size: 20px;
        line-height: 24px;
        padding: 20px 0 0;
        max-width: 75%;
        ${sizeMobileLg(`
            max-width: 100%;
            font-size: 16px;
            line-height: 19px;
    `)}
}
`

export const BottomCircleContainer = styled.div`
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
    width: 100%;
    padding: 50px 0 0;
    ${Circle}{
        ${TitleXl}{
            color: #000;
            font-size: 30px;
            font-weight: 700;
            line-height: 36px;
            text-transform: uppercase;
            background: linear-gradient(180deg, #A036F5 34.9%, #8C31F4 68.23%);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            background-clip: text;
            text-fill-color: transparent;
            ${sizeMobileLg(`         
                font-size: 26px;
                line-height: 31px;
            `)}
        }
        ${TitleSm} {
            font-size:24px;
            line-height: 28px;
            background: linear-gradient(180deg, #A036F5 34.9%, #8C31F4 68.23%);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            background-clip: text;
            text-fill-color: transparent;
            text-align: center;
            padding: 5px 0 0;
            ${sizeMobileLg(`         
                font-size: 20px;
                line-height: 24px;
                padding: 5px 15px 0;
            `)}
        }
        ${BodySm}{
            font-size: 20px;
            line-height: 24px;
            color: #000;
            margin: 10px 0 0;
            ${sizeMobileLg(`         
                font-size: 16px;
                line-height: 19px;
                margin: 0 auto;
                width: 90%;
            `)}
        }
    }
    ${sizeTabletLg (`
        flex-direction: column;
        justify-content: center;
        align-items: center;
        overflow-x: hidden; 
    `)}

`

export const BottomLeftCircleContainer = styled.div`
    padding: 0 38px;
    ${sizeMobileLg(`
        padding: 0 0;
        position: relative;
        margin-left: -3px;
    `)}
`
export const BottomRightCircleContainer = styled.div`
     padding: 0 38px;
     ${sizeTablet (`
     padding: 40px 0 0;
     `)}
    ${sizeMobileLg(`
        position: relative;
        margin-left: -3px;

    `)}
`

export const CtaContainer = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    padding: 70px 0 85px;
    ${sizeMobileLg(` 
        padding: 60px 0 70px;
`)}

`