import styled, {css} from 'styled-components'
import {Link} from 'react-router-dom'

import {sizeLaptop, sizeLaptopLg, sizeTabletLg} from '../../devices'
import {
    BodyRg,
    TitleXs,
    OverlayBase
} from 'components/elements'
import {Tooltip, TooltipWrap} from "components/Tooltip";

// PagedListRow Style Start

export const PagedListRowIcon = styled.img`
  width: 65px;
  height: 65px;
  position: absolute;
  right: 0;
  top: 0;
  left: auto;
  ${sizeTabletLg(`
        width: 50px;
        height: 50px;
        right: unset;
        left: 0;
    `)}
`;

export const PagedListRowIconWhite = styled(PagedListRowIcon)`
  opacity: 0;
`;

export const PagedListRowWrap = styled(OverlayBase)`
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  border-bottom: 1px solid #707070;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 30px 80px;
  position: relative;
  overflow: visible;
  cursor: pointer;
  z-index: 1;
  background-color: #F0F0F0;
  .p-0-mobile {
    ${sizeTabletLg(`
      padding: 0 !important;
    `)}
  }

  ${TooltipWrap} {
    ${BodyRg} {
      width: 100%;
    }
    z-index: 3;
    padding: 0 15px;
    min-width: 200px;
    width: 70%;
    ${sizeTabletLg(css`
      padding: 0;
      order: -1;
      margin-bottom: 20px;
    `)}
  }
${Tooltip} {
    z-index: 3;
    ${sizeLaptopLg(`
      width: 300px;
    `)}
  }
  &:first-child {
    border-top: 1px solid #707070;
  }

  ${sizeLaptop(`
    padding-left: 30px;
    padding-right: 30px;
`)}
  ${sizeTabletLg(`
        flex-direction: column;
        align-items: flex-start;
        padding: 60px 30px 60px 30px;
        &:before {
            content: none !important;
        }
    `)}
  ${BodyRg} {
    width: 70%;
    padding: 0 15px;
    box-sizing: border-box;
    z-index: 3;
    ${sizeTabletLg(`
      padding: 0;
        &:nth-child(2) {
            margin-bottom: 30px;
            order: -1;
        }
      `)}
  }

  ${TitleXs} {
      font-size: 24px;
      line-height: 27px;
    ${sizeTabletLg(`
            font-size: 20px;
            font-size: 24px;
            margin-bottom: 30px;
        `)}
  }

  &:hover {
    & * {
      transition: 0.2s all ease;
      transition-delay: 0.4s;
    }

    ${TitleXs},
    ${BodyRg} {
      color: #fff;
      ${sizeTabletLg(`
                color: #000;
            `)}
    }

    ${PagedListRowIcon} {
      opacity: 0;
      ${sizeTabletLg(`
                opacity: 1;
            `)}
    }

    ${PagedListRowIconWhite} {
      opacity: 1;
      z-index: 3;
      ${sizeTabletLg(`
                opacity: 0;
            `)}
    }

    &:before {
      width: 100%;
    }
  }

  & * {
    transition: 0.1s all ease;
    transition-delay: 0s;
  }
`;

export const TitleCol = styled(TitleXs)`
  width: 140%;
  padding-right: 65px;
  z-index: 3;
  ${sizeTabletLg(`
    width: 80%;
    padding-right: 0;
  `)}
`;

export const PagedListInsightArrow = styled(Link)`
  height: 65px;
  position: relative;

  &.fromInsights {
    width: 65px;
    flex: 0 0 65px;
  }

  ${sizeTabletLg(`
        position: absolute;
        top: 75%;
        transform: translateY(-50%);
        translate
        right: 15px;
        width: 50px;
        height: 50px;
        flex: 0 0 50px;  
        &.notnews {
          position: relative;
          top: unset;
          right: unset;
        }
    `)}
  
`;
export const PagedListNewsArrow = styled.a`
  height: 65px;
  position: relative;
  ${sizeTabletLg(`
        position: absolute;
        top: 75%;
        transform: translateY(-50%);
        right: 15px;
        width: 50px;
        height: 50px;
        flex: 0 0 50px;
    `)}
`;
