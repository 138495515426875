import { SectionTitle, TitleLg} from 'components/elements';
import React, { useState } from 'react';
import {
    TimelineWrap,
    TimelineContainer,
    TimelineTitle,
    TimelineBody,
    MobileTimelineBody,
    Years,
    TimelineRow,
    SmallRectArea,
    ShortLine,
    LongLine,
    TooltipOverlay,
    Tooltip,
    TooltipTop,
    TooltipBottom,
    TopLeft,
    TopRight,
    DateP,
    LargeText,
    MiddleTextLeft,
    MiddleTextRight,
    SmallText,
    BottomItemLeft,
    BottomItemRight,
    LeftArrow,
    RightArrow,
    Cross,
    CrossWrap,
    CTAWrapper,
    MobileTooltip,
    TooltipTitle,
    MobileTooltipBody,
    TooltipFooter,
    ArrowContainer,
    // TimeLineImgContainer,
    // TimelineImg,
    // TimeLineImgMobile
} from "./elements";
import {TitleXs} from 'components/elements';
import BigRect from './BigRect';
import SmallRect from './SmallRect';
import ContentItem from './ContentItem';
import NoContentItem from './NoContentItem';
import { ReactComponent as YearsSVG } from './svg/Years.svg';
import { ReactComponent as Arrow } from './svg/arrow.svg';
import timelineData from './timelineData.json';

export const TimeLine = ({data}) => {
    const { top: topData, bottom: bottomData, data: dataItems, size, dates } = timelineData;
    const [isShown, setIsShown] = useState(false);
    const [selectedItemIndex, setSelectedItemIndex] = useState(-1);

    const handleCross = () => {
        setIsShown(false);
        setSelectedItemIndex(-1);

        if (window.innerWidth < 1200) {
          document.body.style.overflow = 'auto';
        }
    }

    const handleArrow = (direction) => {
        if (direction === 'left') {
            if (selectedItemIndex === 0) return;
            setSelectedItemIndex(selectedItemIndex - 1);
        } else {
            if (selectedItemIndex === 23) return;
            setSelectedItemIndex(selectedItemIndex + 1);
        }
    }

    const openInNewTab = (url) => {
		if (!url) return;
		const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
		if (newWindow) newWindow.opener = null
	}

    return  (
        <>
            <TimelineWrap>
                {
                    data?.title && <SectionTitle>
                        <TitleLg>{data?.title}</TitleLg>
                    </SectionTitle>
                }

                <TimelineContainer>
                    <TimelineTitle>
                        {'20 years of helping build a healthier world'.toUpperCase()}
                    </TimelineTitle>
                    <TimelineBody>
                        <TimelineRow>
                        {
                            topData.data.map((key, index) => {
                                if (typeof key === 'number') {
                                    return (
                                        <BigRect 
                                            key={index}
                                            topLine={true}
                                            item={dataItems[key]}
                                            size={size}
                                            setIsShown={setIsShown}
                                            isShown={isShown}
                                            setItemIndex={setSelectedItemIndex}
                                        />
                                    );
                                } else {
                                    return (
                                        <SmallRectArea
                                            key={index}
                                            width={dataItems[key[0]].width}
                                        >
                                            <SmallRect
                                                item={dataItems[key[0]]}
                                                size={size}
                                                setIsShown={setIsShown}
                                                isShown={isShown}
                                                setItemIndex={setSelectedItemIndex}
                                            />
                                            <ShortLine length={size.shortLine} color={dataItems[key[0]].color} margin={size.smallWidth} />
                                            <SmallRect
                                                item={dataItems[key[1]]}
                                                size={size}
                                                setIsShown={setIsShown}
                                                isShown={isShown}
                                                setItemIndex={setSelectedItemIndex}
                                            />
                                            <LongLine length={size.longLine} color={dataItems[key[1]].color} margin={size.smallWidth} />
                                        </SmallRectArea>
                                    );
                                }
                            })
                        }
                        </TimelineRow>
                        <Years width={size.yearsWidth}>
                            <YearsSVG />
                        </Years>
                        <TimelineRow>
                        {
                            bottomData.data.map((key, index) => {
                                if (typeof key === 'number') {
                                    return (
                                        <BigRect 
                                            key={index}
                                            topLine={false}
                                            item={dataItems[key]}
                                            size={size}
                                            setIsShown={setIsShown}
                                            isShown={isShown}
                                            setItemIndex={setSelectedItemIndex}
                                        />
                                    );
                                } else {
                                    return (
                                        <SmallRectArea
                                            key={index}
                                            width={dataItems[key[0]].width}
                                        >
                                            <LongLine length={size.longLine} color={dataItems[key[1]].color} margin={size.smallWidth} />
                                            <SmallRect
                                                item={dataItems[key[0]]}
                                                size={size}
                                                setIsShown={setIsShown}
                                                isShown={isShown}
                                                setItemIndex={setSelectedItemIndex}
                                            />
                                            <ShortLine length={size.shortLine} color={dataItems[key[0]].color} margin={size.smallWidth} />
                                            <SmallRect
                                                item={dataItems[key[1]]}
                                                size={size}
                                                setIsShown={setIsShown}
                                                isShown={isShown}
                                                setItemIndex={setSelectedItemIndex}
                                            />
                                        </SmallRectArea>
                                    );
                                }
                            })
                        }
                        </TimelineRow>
                        {
                        isShown && (
                            <TooltipOverlay>
                                <Tooltip>
                                    <Cross onClick={handleCross} />
                                    <TooltipTop>
                                        <TopLeft color={dataItems[selectedItemIndex].color}>
                                            <DateP>{dataItems[selectedItemIndex].title_year}</DateP>
                                            <LargeText dangerouslySetInnerHTML={{ __html: dataItems[selectedItemIndex].title.toUpperCase() }} />
                                        </TopLeft>
                                        <TopRight>
                                            <SmallText>{dataItems[selectedItemIndex].blurb}</SmallText>
                                            {
                                              dataItems[selectedItemIndex].link !== null && 
                                              <CTAWrapper link={dataItems[selectedItemIndex].link} onClick={() => openInNewTab(dataItems[selectedItemIndex].link)}>
                                                <TitleXs color="#FFF">
                                                  LEARN MORE
                                                </TitleXs>
                                                <ArrowContainer>
                                                  <Arrow />
                                                </ArrowContainer>
                                              </CTAWrapper>
                                            }
                                        </TopRight>
                                    </TooltipTop>
                                    <TooltipBottom>
                                        <BottomItemLeft>
                                            <LeftArrow 
                                              isfirst={selectedItemIndex === 0 ? 'default' : 'pointer'}
                                              style={{ display: `${selectedItemIndex === 0 ? 'none' : 'block'}`}}
                                              onClick={() => handleArrow('left')}
                                            >
                                              Arrow
                                            </LeftArrow>
                                            <div style={{ marginLeft: '50px', width: '64%' }}>
                                                <DateP>{dataItems[selectedItemIndex].before_year}</DateP>
                                                <MiddleTextLeft style={{ borderBottom: `2px solid ${selectedItemIndex - 1 >= 0 ? dataItems[selectedItemIndex - 1].color : '#000'}` }}>{dataItems[selectedItemIndex].before_title}</MiddleTextLeft>
                                            </div>
                                        </BottomItemLeft>
                                        <BottomItemRight>
                                            <div style={{ marginRight: '50px', width: '64%'}}>
                                                <DateP>{dataItems[selectedItemIndex].next_year}</DateP>
                                                <MiddleTextRight style={{ borderBottom: `2px solid ${selectedItemIndex + 1 < dataItems.length ? dataItems[selectedItemIndex + 1].color : '#000'}` }}>{dataItems[selectedItemIndex].next_title}</MiddleTextRight>
                                            </div>
                                            <RightArrow 
                                              islast={selectedItemIndex === 0 ? 'default' : 'pointer'}
                                              style={{ display: `${selectedItemIndex === dataItems.length - 1 ? 'none' : 'block'}`}}
                                              onClick={() => handleArrow('right')}
                                            >
                                              Arrow
                                            </RightArrow>
                                        </BottomItemRight>
                                    </TooltipBottom>
                                </Tooltip>
                            </TooltipOverlay>
                        )
                    }
                    
                    </TimelineBody>
                    <MobileTimelineBody>
                        {
                            dates.map((dateItem, index) => {
                                if (dateItem.item) {
                                    return (
                                        <ContentItem
                                            key={index}
                                            year={dateItem}
                                            data={dataItems}
                                            setIsShown={setIsShown}
                                            setItemIndex={setSelectedItemIndex}
                                        />
                                    );
                                } else {
                                    return (
                                        <NoContentItem key={index} year={dateItem} />
                                    );
                                }
                            })
                        }
                    </MobileTimelineBody>

                    
                </TimelineContainer>
        {/* 
                <TimeLineImgContainer>
                    {
                        data?.subTitle && <BodySm>data?.subTitle</BodySm>
                    }
                    <TimelineImg src={data?.desktopImage}/>
                    <TimeLineImgMobile src={data?.mobileImage}/>
                </TimeLineImgContainer> */}
                {
                    isShown && (
                        <MobileTooltip>
                            <CrossWrap><Cross onClick={handleCross} /></CrossWrap>
                            <MobileTooltipBody>
                                <DateP>{dataItems[selectedItemIndex].title_year}</DateP>
                                <TooltipTitle color={dataItems[selectedItemIndex].color}>
                                    <LargeText dangerouslySetInnerHTML={{ __html: dataItems[selectedItemIndex].title.toUpperCase() }} />
                                </TooltipTitle>
                                <SmallText link={dataItems[selectedItemIndex].link} onClick={() => openInNewTab(dataItems[selectedItemIndex].link)}>{dataItems[selectedItemIndex].blurb}</SmallText>
                                {
                                  dataItems[selectedItemIndex].link !== null && 
                                  <CTAWrapper link={dataItems[selectedItemIndex].link} onClick={() => openInNewTab(dataItems[selectedItemIndex].link)}>
                                    <TitleXs color="#FFF">
                                      LEARN MORE
                                    </TitleXs>
                                    <ArrowContainer>
                                      <Arrow />
                                    </ArrowContainer>
                                  </CTAWrapper>
                                }
                            </MobileTooltipBody>
                            <TooltipFooter>
                                <LeftArrow className={selectedItemIndex === 0 ? 'hide' : ''} onClick={() => handleArrow('left')}>Arrow</LeftArrow>
                                <RightArrow className={selectedItemIndex === dataItems.length - 1 ? 'hide' : ''} onClick={() => handleArrow('right')}>Arrow</RightArrow>
                            </TooltipFooter>
                        </MobileTooltip>
                    )
                }
            </TimelineWrap>
            
        </>
    );
}

export default TimeLine;
