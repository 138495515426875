import React, { useEffect, useRef, useState } from 'react';
import { useLocation } from "react-router-dom";
import { useAppContext } from "store";

import {
  HeroVideoWrap,
  VideoWrapper,
  Video,
} from "./elements";

import PlayMarquee from "components/PlayMarquee";
//import VideoPopup from "../VideoPopup";


function HeroVideo({ hdata, videoPoster, srcArray, videoSrc, autoPlay, disable,customClass, videoHeight, marqueeText, fromInsights,hashedId }) {
  //eslint-disable-next-line
  const [showPopup, setShowPopup] = useState(false);
  const [marque, setMarque] = useState(1)
  //const [isSafari, setIsSafari] = useState(false);
  const videoRef = useRef(null);
  const videoContainer = useRef(null);
  let [controlLoaded, setLoadControl] = useState(false);
  let [playVid, setPlayVid] = useState(false);
  const mobVid = useRef(null);
  let route = useLocation().pathname;
  const { updateBlackHeaderState } = useAppContext();
  let size = useWindowSize();


  function useWindowSize() {
    const [windowSize, setWindowSize] = useState({
      width: undefined,
      height: undefined,
    });

    useEffect(() => {
      function handleResize() {
        setWindowSize({
          width: window.innerWidth,
          height: window.innerHeight,
        });
      }

      window.addEventListener("resize", handleResize);

      handleResize();

      return () => window.removeEventListener("resize", handleResize);
    }, []);

    return windowSize;
  }


  useEffect(() => {
    var checkSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

    if (checkSafari) {
      // setIsSafari(true);
      //Make Video Fullscreen on mobile iOS
      /*     let video = document.getElementById('heroVideo');
           video.addEventListener("webkitendfullscreen", function () {
             if (document.fullScreenElement || document.webkitIsFullScreen === true || document.mozFullScreen || document.msFullscreenElement) {
               //do whatever here if the video is now (just became) full screen
             } else {
               videoRef.current.pause();
               videoRef.current.load();
               videoRef.current.controls = false;
               setMarque(true);
               setLoadControl(false);
               setIsSafari(true)
             }
           }, false);*/
    }
    setShowPopup(false);

    //Make video full screen on mobile Android

    /* document.addEventListener('fullscreenchange', (event) => {
       if (document.fullscreenElement) {
         console.log(`Element: ${document.fullscreenElement.id} entered full-screen mode.`);
       } else {
         videoRef.current.pause();
         videoRef.current.load();
         videoRef.current.controls = false;
         setMarque(true);
         setLoadControl(false);
       }
     });*/
    videoRef.current.pause();
    videoRef.current.load();
    // videoRef.current.currentTime = 0;
    videoRef.current.controls = false;
    videoRef.current.poster = videoPoster;
    setLoadControl(false);
    if (srcArray) {
      let check = videoRef.current.canPlayType('video/webm; codecs="vp8, vorbis"');
      if (size.width > 1920) {
        if (check === 'probably') {
          videoRef.current.src = srcArray[0];
        } else {
          videoRef.current.src = srcArray[1];
        }
      } else {
        if (check === 'probably') {
          videoRef.current.src = srcArray[2];
        } else {
          videoRef.current.src = srcArray[3];
        }
      }
    }
    // eslint-disable-next-line
  }, [size.width, srcArray, videoPoster])

  useEffect(() => {
    if (!disable) {
      setMarque(1)
    } else {
      setMarque(0)
    }
    if (route === "/") updateBlackHeaderState(true);

    //window.addEventListener("scroll", handleScroll, true);

    return () => {
      //window.removeEventListener("scroll", handleScroll, true);
      updateBlackHeaderState(false);
    };
    /* eslint-disable react-hooks/exhaustive-deps */
  }, [route]);


  useEffect(() => {

    const videoRefElement = videoRef.current;
    // document.addEventListener("keyup", handleKeyup, false);
    videoRefElement.addEventListener("click", heroClickHandler);
    return () => {
      // document.removeEventListener("keyup", handleKeyup, false);
      videoRefElement.removeEventListener("click", heroClickHandler);
    };
  }, []);

  useEffect(() => {
    window.addEventListener("scroll", handleVideoScroll, true);
    return () => {
      window.removeEventListener("scroll", handleVideoScroll, true);

    };
  }, [route]);



  /* const videoControls = () => {
     setMarque(0);

     if (!disable) {
       mobVid && mobVid.current && mobVid.current.play();
       const mobVidFalse = () => {
         setShowPopup(true)
         mobVid.current.controls = true;
       }
       setTimeout(mobVidFalse, 300);
       videoRef.current.pause();
       videoRef.current.load();
       // videoRef.current.currentTime = 0;
       videoRef.current.poster = videoPoster;
       setLoadControl(false);
     }

   }*/
  const heroClickHandler = () => {


    if (!fromInsights) {
      document.body.className = 'bodyFixed'
      // setIsSafari(false)
      if (!disable && size.width > 992) {
        // videoRef.current.setAttribute("controls", true);
        // setLoadControl(true);
        setMarque(0);
        setPlayVid(true);
        if (!playVid) {
          videoRef.current.play();
        }
        setShowPopup(true)
      }
      /*   if (!disable && size.width < 992) {
           videoControls()
         }*/
      if (!disable && size.width < 992) {

        setMarque(0);

        mobVid && mobVid.current && mobVid.current.play();

        const mobVidFalse = () => {
          setShowPopup(true)
          // control = true;
        }

        setTimeout(mobVidFalse, 200);
        videoRef.current.pause();
        videoRef.current.load();
        // videoRef.current.currentTime = 0;

        videoRef.current.poster = videoPoster;
        setLoadControl(false);

        // if (!disable) {
        //     setShowPopup(true)
        //     mobVid.current.play();
        //     setTimeout(mobVidFalse, 2000);
        //     videoRef.current.pause();
        //     videoRef.current.load();
        //     // videoRef.current.currentTime = 0;

        //     videoRef.current.poster = videoPoster;
        //     setLoadControl(false);
        // }
        // setMarque(false)

        // setShowPopup(true);
        // videoRef.current.pause();
        // videoRef.current.load();
        // // videoRef.current.currentTime = 0;
        // videoRef.current.controls = false;
        // videoRef.current.poster = videoPoster;
        // setMarque(false);
        // setLoadControl(false);


        // videoRef.current.currentTime = 0;


      }
    }



  };
  const handleVideoScroll = () => {
    const containerHeight = (videoContainer.current) ? videoContainer.current.parentNode.clientHeight + 80 : 1300;
    if (window.scrollY >= containerHeight) {
      videoRef.current.pause();
      videoRef.current.load();
      // videoRef.current.currentTime = 0;
      videoRef.current.controls = false;

      // videoRef.current.poster = hdata.image
      setMarque(1);
      setLoadControl(false);


    }
  }
  //eslint-disable-next-line
  const handleClose = () => {
    document.body.className = ''
    /*  mobVid.current.pause();
      mobVid.current.load();
      mobVid.current.currentTime = 0*/
    setShowPopup(false);

  }


  /*
    const handleSafariPlayer = () => {
      setIsSafari(false);
      size.width > 992 ? heroClickHandler() : videoControls()
    }

  */


  return (
    <HeroVideoWrap ref={videoContainer} className={customClass}>
      {
        !fromInsights ? <PlayMarquee marqueeText={marqueeText} opacity={marque} controlLoaded={controlLoaded} heroClickHandler={()=>{setShowPopup(true)}} />  : ''
      }

      <VideoWrapper>
        {/*  {
          !fromInsights ? (videoPoster ?
            <ImageSafari onClick={!fromInsights ? handleSafariPlayer : () => {
            }} isSafari={isSafari} src={videoPoster} />
            : '') : ''
        }*/}
        <Video
          id="heroVideo"
          isSafari={ false}
          playing={ true}
          ref={videoRef}
          onClick={!fromInsights ? ()=>{setShowPopup(true)} : () => {
          }}
          loop
          muted={true}
          autoPlay={true}
          playsInline={true}
          poster={videoPoster ? videoPoster : ""}
        >
          <source src={videoSrc} />
        </Video>

      </VideoWrapper>
    </HeroVideoWrap>
  );
}

export default HeroVideo;
