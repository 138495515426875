import React, {useState, useEffect} from 'react';
import {withRouter} from "react-router-dom"
import {BodySm, TitleXl, TitleMd,  Row,  Col5, Col7} from "components/elements";
import {AboutUsPurpleSectionContainer, TitleXlContainer} from './elements';

function AboutUsPurpleSection({content}) {
	const [windowWidth, setWindowWidth] = useState(false)


    useEffect(() => {
        const handleResize = () => {
            window.innerWidth < 769 ? setWindowWidth(true) : setWindowWidth(false)
			document.documentElement.clientWidth < 769 ? setWindowWidth(true) : setWindowWidth(false)
        }
    
        window.addEventListener("resize", handleResize);
		document.documentElement.addEventListener("resize", handleResize);
        handleResize();
        return () => {
			window.removeEventListener("resize", handleResize);
			document.documentElement.removeEventListener("resize", handleResize);
		}
     },[])

return(
        <AboutUsPurpleSectionContainer title="">
            <TitleXlContainer>
                <TitleXl fontWeight="900">{content.title}</TitleXl>
            </TitleXlContainer>
            <Row  alignItems="start">
                <Col5>
                    <TitleMd>
                    {content?.columnCopy?.content?.[0]?.content?.[0]?.value}
                    </TitleMd>
                    <BodySm>
                    {content?.columnCopy?.content?.[1]?.content?.[0]?.value}
                    </BodySm>
                </Col5>
                <Col7>
                {(windowWidth && content.columnMediaMobile) ? <img src={content.columnMediaMobile} alt="#1 Medical Marketing Agency, #2 Most innovative in data sciences, #3 Most creative in health" />
                    : <img src={content.columnMedia} alt="#1 Medical Marketing Agency, #2 Most innovative in data sciences, #3 Most creative in health" />
                 }
                </Col7>
            </Row>
          
        </AboutUsPurpleSectionContainer>
    )
}

export default withRouter(AboutUsPurpleSection);
