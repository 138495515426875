import React, { useState, useEffect } from "react";

import { 
  PrivacyPolicyPage, 
  BlogContainer 
} from "./elements";

import PrivacyInfoSection from "./PrivacyInfoSection";
import AccordionSection from "./AccordionSection";
import ViewAll from "components/ViewAll";
import NextPage from "components/NextPage";
import ContactModal from "components/ContactModal";
import { WideScreenContainer } from "components/elements";
import SEO from "../../components/Helmets/SEO";
import {Helmet} from "react-helmet";
import LoadingHoc from "../../hoc/LoadingHOC";
import useRememberScrollPosition from "../../hooks/useRememberScrollPosition";
import BlogHero from './BlogHero'

function PanelToolkit(props) {
  useRememberScrollPosition('privacy-policy');
  const {setLoading} = props;
  const [openModal, setOpenModal] = useState(false);
  const [openInfoAccordion, setOpenInfoAccordion] = useState(false);
  const [openOtherAccordion, setOpenOtherAccordion] = useState([
    {
      id: "updatesPrivacyPolicy",
      state: false,
    },
    {
      id: "useData",
      state: false,
    },
    {
      id: "healthEquity",
      state: false,
    },
    { 
      id: "researchServices", 
      state: false 
    },
  ]);
  const openContactUsModal = () => {
    setOpenModal(true);
    document.body.className = "bodyFixed";
  };

  useEffect(()=>{
    setTimeout(()=>{
      setLoading(false)
    },500)
    // eslint-disable-next-line
  },[])

  useEffect(() => {
    const pageURL = window.location.href.split("#");
    if (pageURL[1] === "information-we-collect") {
      setOpenInfoAccordion(true);
    } else if (pageURL[1] === "subsidiaries") {
      let updatedCard = openOtherAccordion.filter(
        (a) => a.id === "subsidiaries"
      );
      updatedCard[0].state = true;
      setOpenOtherAccordion([...openOtherAccordion, updatedCard[0]]);
    }
    /* eslint-disable react-hooks/exhaustive-deps */
  }, []);
  const closeContactUsModal = () => {
    setOpenModal(false);
  };

  const handleToggleOtherAccordion = (id, state) => {
    let updatedCard = openOtherAccordion.filter((a) => a.id === id);
    updatedCard[0].state = state;

    setOpenOtherAccordion([...openOtherAccordion, updatedCard[0]]);
  };

  const handleOpenAccordion = (e) => {
    if (e && e.target && e.target.className === "internal-page-link") {
      setOpenInfoAccordion(true);
    }
  };

  const handleToggleInfoAccordion = (state) => {
    setOpenInfoAccordion(state);
  };

  return (
    <PrivacyPolicyPage className="pageWrapper">
      <SEO
          title={"SXSW Speaker Social Toolkit: SXSW Official Panels | Real Chemistry"}
          description={"SXSW Speaker Social Toolkit: SXSW Official Panels"}
          pathname={window.location.pathname}
      />
      <Helmet>
        <title>{"SXSW Speaker Social Toolkit: SXSW Official Panels | Real Chemistry"}</title>
        <link rel="canonical" href={ window.location.pathname} />
        <meta name="description" content={"SXSW Speaker Social Toolkit: SXSW Official Panels"} />
        <meta name="og:description" content={"SXSW Speaker Social Toolkit: SXSW Official Panels"} />
        <meta name="og:title" content={"SXSW Speaker Social Toolkit: SXSW Official Panels| Real Chemistry"} />
        <meta name="og:url" content={window.location.protocol + "//" + window.location.host + window.location.pathname} />
        <meta name="og:type" content="website" />
        <meta name="og:site_name" content="Real Chemistry" />
      </Helmet>

      <WideScreenContainer style={{ paddingTop: "20px" }} borderTop="1px solid #707070" borderLeft="1px solid #707070" borderRight="1px solid #707070">
        
        <BlogContainer>
          <BlogHero
            image={'/images/RC_SXSW_Official_Session_2_Blog_Hero.jpg'}
            // mobileImage={article?.mobileImage}
          />
        </BlogContainer>

        <PrivacyInfoSection
          handleToggleOtherAccordion={handleToggleOtherAccordion}
        />

        <AccordionSection
          openInfoAccordion={openInfoAccordion}
          openOtherAccordion={openOtherAccordion}
          handleOpenAccordion={handleOpenAccordion}
          handleToggleOtherAccordion={handleToggleOtherAccordion}
          handleToggleInfoAccordion={handleToggleInfoAccordion}
        />
         <ViewAll title={'Contact us'} borderColor="#000" titleColor="#000" hoverBackground="#000" width="300px" height="44px" svgColor="#000" openContactUsModal={openContactUsModal} />

      </WideScreenContainer>
      <WideScreenContainer>
        <NextPage pageName="Go to Homepage" pageLink="/" back={true} />
      </WideScreenContainer>
      {/* Contact Modal */}
      <ContactModal
        show={openModal}
        closeContactUsModal={closeContactUsModal}
      />
    </PrivacyPolicyPage>
  );
}

export default LoadingHoc(PanelToolkit);
