import React, { useEffect, useRef } from "react";
import { TweenLite } from "gsap";
import { TitleMd, BodyRg } from "components/elements";

import {
  AccordionCardWrap,
  AccordionHeader,
  AccordionToggle,
  Bar,
  AccordionBody,
  BodyInner,
  BodyContainer,
  DataList,
  ListItem,
  NumberedList,
  LI
} from "./elements";

function AccordionCard({
  title,
  body,
  cardId,
  handleToggleOtherAccordion,
  openOtherAccordion,
  handleOpenAccordion,
}) {
  const contentRef = useRef(null);
  const accordionRef = useRef(null);
  // const [collapse, setCollapse] = React.useState(true);

  useEffect(() => {
    openOtherAccordion &&
    openOtherAccordion[0].state &&
    openOtherAccordion[0].id === cardId
      ? TweenLite.to(contentRef.current, 0.25, {
          height: "auto",
          overflow: "visible",
        })
      : TweenLite.to(contentRef.current, 0.25, {
          height: 0,
          overflow: "hidden",
        });
    /* eslint-disable react-hooks/exhaustive-deps */
  }, [openOtherAccordion]);

  useEffect(() => {
    // setCollapse(!openSubsidiaries);
    const accordionEvent = accordionRef.current;
    accordionEvent.addEventListener("click", handleOpenAccordion, true);
    return () => {
      accordionEvent.removeEventListener("click", handleOpenAccordion);
    };
    /* eslint-disable react-hooks/exhaustive-deps */
  }, []);

  const handleOnClick = (id) => {
    handleToggleOtherAccordion(id, !openOtherAccordion[0].state);
  };

  return (
    <AccordionCardWrap id={cardId ? cardId : ""}>
      <AccordionHeader
        className={
          openOtherAccordion &&
          openOtherAccordion[0].state &&
          cardId === openOtherAccordion[0].id
            ? "expanded"
            : null
        }
        onClick={handleOnClick.bind(null, cardId)}
      >
        <TitleMd textTransform="uppercase">{title}</TitleMd>
        <AccordionToggle>
          <Bar />
          <Bar />
        </AccordionToggle>
      </AccordionHeader>
      <AccordionBody ref={contentRef}>
        <BodyInner>
          <BodyContainer ref={accordionRef}>
            {body &&
              body?.map((item, idx) => {
                return (
                  <div key={`container-${idx}`}>
                    {item.paragraphs &&
                      item.paragraphs?.map((paragraph, pIndex) => {
                        return (
                          <BodyRg
                            key={pIndex}
                            dangerouslySetInnerHTML={{ __html: paragraph }}
                          ></BodyRg>
                        );
                      })}
                    {item.list ? (
                      <DataList>
                        {item.list?.map((l, lIndex) => {
                          return (
                            <ListItem key={lIndex}>
                              <BodyRg
                                dangerouslySetInnerHTML={{ __html: l }}
                              ></BodyRg>
                            </ListItem>
                          );
                        })}
                      </DataList>
                    ) : (
                      ""
                    )}
                    {item.numberedlist ? (
                      <NumberedList>
                        {item.numberedlist?.map((l, lIndex) => {
                          return (
                            <LI key={lIndex}>
                              <BodyRg
                                dangerouslySetInnerHTML={{ __html: l }}
                              ></BodyRg>
                            </LI>
                          );
                        })}
                      </NumberedList>
                    ) : (
                      ""
                    )}
                  </div>
                );
              })}
          </BodyContainer>
        </BodyInner>
      </AccordionBody>
    </AccordionCardWrap>
  );
}

export default AccordionCard;
