import React from 'react'

import { BodySm, TitleSm } from 'components/elements'

import {
    InsightDateWrap,
    InsightImg,
    InsightLabel,
    InsightIcon,
    InsightDateInfo,
    InsightsLink,
    ImageDiv,
    InsightTextLink,
    InsightImgMobile
} from './elements'
import moment from 'moment'
function DoubleWide(props) {
    return (
        <InsightDateWrap
            height={props.height}
            borderTop={props.borderTop}
            borderRight={props.borderRight}
            borderBottom={props.borderBottom}
            borderLeft={props.borderLeft}
            padding={props.padding}
            link={props.link}
            className="parallexItem"
        >
            {
                props.link ? <InsightTextLink className="article card" to={props.link ? props.link : "#"} /> : null
            }

            {/* Image Desktop */}
            <ImageDiv>
                <InsightImg src={props.image} />
                <InsightImgMobile src={props.mobileImage} />
            </ImageDiv>
            {/* Image mobile */}

            <InsightDateInfo>
                <InsightLabel className="article type">
                    <InsightIcon type={props.type} src={props.icon} />
                    <BodySm textTransform="uppercase" className="label">{props.label}</BodySm>
                    <BodySm textTransform="capitalize">{props.date ? moment(props.date).format("MMMM D, YYYY") : ''}</BodySm>
                </InsightLabel>
                <TitleSm className="article title">{props.description}</TitleSm>
                <InsightsLink to={props.link} className={props.insightLinkcls ? `${props.insightLinkcls} article link` : 'article link'}>
                    {props.ctaText ? props.ctaText : 'Read More'}
                </InsightsLink>
            </InsightDateInfo>
        </InsightDateWrap>
    )
}

export default DoubleWide

