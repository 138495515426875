
import styled from 'styled-components'

import {
    BodyRg,
    TitleXs
} from 'components/elements'

import {
    sizeTabletLg,
    sizeWideScreen
} from 'components/devices'

export const AwardWrap = styled.div`
    height: ${({ height }) => height || "auto"};
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    box-sizing: border-box;
    width: 100%;
    cursor: pointer;
    border-top: ${({ borderTop }) => borderTop || "1px solid #707070"};
    border-right: ${({ borderRight }) => borderRight || "1px solid #707070"};
    border-bottom: ${({ borderBottom }) => borderBottom || "1px solid #707070"};
    border-left: ${({ borderLeft }) => borderLeft || "1px solid #707070"};
    padding: ${({ padding }) => padding || "20px"};
  ${sizeTabletLg(`
    border-right: none;
  `)}
    
    ${TitleXs} {
        margin-bottom: 15px;
        font-size: 26px;
        ${sizeWideScreen(`
            font-size: 0.85vw;
        `)}
        ${sizeTabletLg(`
            font-size: 22px;
        `)}
    }
    ${BodyRg} {
        ${sizeWideScreen(`
            font-size: 0.6vw;
        `)}
    }
  .award-link {
    display: flex;
    align-items: center;
    justify-content: center;
    svg {
      margin-left: 17px;
      width: 23px;
      height: 22px;
    }
  }

    &:hover {
        ${TitleXs} {
            color: #D12FFF;
        }

        ${BodyRg} {
            color: #D12FFF;
          svg {
            path {
              fill: #D12FFF;
            }
          }
        }
    }
`;
export const AwardWrapWithoutLink = styled.div`
    height: ${({ height }) => height || "auto"};
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    box-sizing: border-box;
    width: 100%;
    border-top: ${({ borderTop }) => borderTop || "1px solid #707070"};
    border-right: ${({ borderRight }) => borderRight || "1px solid #707070"};
    border-bottom: ${({ borderBottom }) => borderBottom || "1px solid #707070"};
    border-left: ${({ borderLeft }) => borderLeft || "1px solid #707070"};
    padding: ${({ padding }) => padding || "20px"};
    
    ${TitleXs} {
        margin-bottom: 15px;
        font-size: 26px;
        ${sizeWideScreen(`
            font-size: 0.85vw;
        `)}
        ${sizeTabletLg(`
            font-size: 22px;
        `)}
    }
    ${BodyRg} {
        ${sizeWideScreen(`
            font-size: 0.6vw;
        `)}
    }
`;

export const AwardInner = styled.div`
    width: 100%;
`;

export const AwardLogo = styled.img`
    margin-bottom: 15px;
    width: 100%;
    max-width: 180px;
`;

