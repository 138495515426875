import styled from "styled-components";
import { BodySm, TitleSm , TitleXl} from "components/elements";
import { sizeMobileLg, sizeLaptop } from "components/devices";
import { RightArrowWrap } from "components/ViewAll";
import { SelectField } from "components/SelectField";

export const ContactUsFormWrap = styled.div`
    width: 90%;
    height: 100%;
    padding: 0 0 3% 5%;
    ${sizeLaptop(`
     width: 100%;
  `)}

  ${sizeMobileLg(`
      padding: 10% 5% 30%;
  `)}

    ${SelectField}{
        width: 100%;
        border: none;
        border-bottom: 1.79px solid #000;
        padding: 2% 0 0 ;
        background-color: transparent;
        outline: none;
        box-shadow: none;
        display: block;
        outline: none !important;
        box-shadow: none !important;
        max-width: 100%;
        font-family: 'HelveticaNowDisplay';
        font-weight: 400;
        background-size: 30px;
    
    }

    .furtherInfo {
      padding: 2% 0 5%;
      ${sizeMobileLg(`
        padding: 5% 0 5%;
      `
      )}
  
    }
`
export const InputContainer = styled.div`
    width: 90%;
    .error {
      color: #FF0000 !important;
    }
    ${sizeMobileLg(
      `
        width: 100%;
      `
    )}

`

export const FormContainer = styled.form`
    padding: 8% 0 0;
    ${BodySm}{
        font-size: 16px;
        line-height: 19px;
    }
    ${TitleSm}{
        padding: 4% 0 1%;
        font-size: 20px; 
        line-height: 19px;
        color: #000000;
        .error {
          color: #FF0000 !important;
        }
    
    }
    .firstTitleSm {
        padding: 0 0 0 !important;
    }
`


export const CtaContainer = styled.div`
    padding: 4% 0 0;
    ${sizeMobileLg(`
        display: flex;
        justify-content: center;
    `)}
`

export const InputSignUp = styled.input`
    height: 40px;
    width: 100%;
    border: none;
    box-sizing: border-box;
    border-bottom: 1.76px solid #000;
    margin-bottom: 15px;
    font-size: 22px;
    line-height: 1;
    color: #000;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-border-radius: 0 !important; 
    -moz-border-radius: 0 !important;   
    border-radius: 0 !important;
    font-family: 'HelveticaNowDisplay';
    font-weight: 400;
    &::placeholder {
        color: #999999;
        font-size: 20px;
        padding-left: 15px;
        line-height: 29px;
        font-family: 'HelveticaNowDisplay';
        font-weight: 400 !important;
        opacity: 0.73;
    }
    &:-webkit-autofill,
    &:-webkit-autofill, 
    &:-webkit-autofill:hover, 
    &:-webkit-autofill:focus, 
    &:-webkit-autofill, 
    &:-webkit-autofill:hover, 
    &:-webkit-autofill:focus, 
    &:-webkit-autofill, 
    &:-webkit-autofill:hover, 
    &:-webkit-autofill:focus {
        -webkit-text-fill-color: #000 !important;
    }
    &:focus {
        outline: none;
        box-shadow: none;
        color: #000;
        border-color: #000;
    }

    &.error {
        color: #FF0000;
    }
 
`;

export const SubmitWrap = styled.div`
  transition: 0.4s all ease;
  border: 2px solid;
  border-color: ${({borderColor}) => borderColor};
  height:  ${({height}) => height};
  width: ${({width}) => width};
  background: transparent;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 20px 0 0 0 ;
  &:hover {
    background: ${({hoverBackground}) => hoverBackground};
    border: none;
    ${RightArrowWrap}{
      border-color: ${({borderColorHover}) => borderColorHover || '#D12FFF'};
    }
    ${TitleSm}{
      color: #D12FFF; 
    }
    svg {
      path {
        stroke: #D12FFF;
        &:nth-of-type(2){
          stroke: #D12FFF;
        }
        &:nth-of-type(3){
          stroke: #D12FFF;
        }
      }
    }

`;


export const SubmitLink = styled.div`
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  justify-content: center;
  -ms-flex-align: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  align-items: center;
  -webkit-transition: .4s all ease;
  transition: .4s all ease;
  width: 85%;
  height: 100%;
  text-decoration: none !important;
  cursor: pointer;
  border: none;
  &:hover {
    background: ${({hoverBackground}) => hoverBackground};
    border: none;
    ${RightArrowWrap}{
      border-color: ${({borderColorHover}) => borderColorHover || '#D12FFF'};
    }
}
  ${TitleSm} {
    font-size: 16px;
    line-height: 1;
    letter-spacing: 0.135em;
    -webkit-transition: .4s all ease;
    transition: .4s all ease;
    color: ${({titleColor}) => titleColor || "#fff"};
    font-weight: 700;
  }
`;

export const TitleXlContainer = styled.div`
    max-width: 60%;
    padding: 28% 0 0;
    ${sizeMobileLg(`
         max-width: 100%;
         padding: 10% 0 0;
    `)}
    ${TitleXl} {
        font-size: 48px;
        font-weight: 900;
        line-height: 57px;
        ${sizeMobileLg(`
            font-size: 36px;
            line-height: 43px; 
       `)}
    }
  
`

export const BodySmContainer = styled.div`
     padding-top: 8%;
     max-width: 80%;
     width: 100%;
     ${sizeMobileLg(`
        max-width: 100%;
        padding-bottom: 100%;
    `)} 
    ${BodySm}{
        font-size: 20px;
        line-height: 24px;
        ${sizeMobileLg(`
            font-size:16px;
            line-height: 19px;
    `)} 
    .magenta {
        color: #D12FFF
    }
`



