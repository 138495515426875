import React from 'react'
import styled from 'styled-components'
import { sizeMobileLg } from './devices';

// Media Type Container
export const MediaTypeContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  text-transform: uppercase;
  border-radius: 0;
  color: #000;
  transition: 0.3s all ease;
  font-weight: 500;
  font-size: 16px;
  line-height: 1;
  font-family: 'HelveticaNowDisplay',serif;
  padding: ${({headerPad}) => headerPad || '0 0 0'};
  ${sizeMobileLg(
    `
      padding: 0 0 15px;
    `
  )}
  svg {
    width: 32px;
    margin-right: 7px;

    path, rect {
      fill: #000;
    }
  }

`;


function MediaType({ icon, text, status, headerPad}) {

    return (
        <MediaTypeContainer headerPad={headerPad} className={status + " article type"}>
            {icon}
            {text}
        </MediaTypeContainer>
    )
}

export default MediaType;
