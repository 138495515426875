// Subscribe BLock CSS
import { TitleXl} from "components/elements";
import styled from "styled-components";
import {sizeDesktopSm, sizeLaptop, sizeLaptopMd, sizeMobileLg, sizeTabletLg} from "../devices";


export const ImgCard = styled.div`
  display: flex !important;
  flex-direction: column;
  justify-content: stretch;
  height: ${({height}) => height || "686px"}; 
   ${sizeDesktopSm(`
        height: 655px;
   `)}
   ${sizeTabletLg(`
        height: 600px !important;
   `)}
  img {
    width: 100%;
    object-fit: cover;
     flex-basis: 0;
     flex-grow: 1;
     max-width: 100%;
     min-height: 180px;
     ${sizeTabletLg(`
        height: 120px;
   `)}
     ${sizeTabletLg(`
        height: 120px;
   `)}
     & + img {
      margin-top: 20px;
       ${sizeTabletLg(`
           margin-top: 15px;
       `)}
     }
   }
  }
`;

export const CultureWrap = styled.div`
  ${TitleXl}{
    font-weight: 900;
    font-size: 48px;
    line-height: 57px;
    ${sizeTabletLg(`
      font-size: 36px;
      line-height: 43px;
    `)}
  }
  .magenta {
    color: #D12FFF;
  }
  .main-title {
    padding: 28px 28px 0;
    ${sizeTabletLg(`
      padding: 28px 15px 0;
    `)}
  
  }
  .slick-slider {
    overflow: hidden;
    padding: 0 0 146px 0px;
    ${sizeLaptop(`
        padding: 0 0 100px 15px;
    `)}
  }
  .slick-list {
    overflow: visible;
    padding: 0;
  }
  .slick-prev, 
  .slick-next {
    position: absolute;
    top: auto;
    bottom: -10px;
    width: 80px;
    height: 80px;
    border-radius: 50%;
    font-size: 0;
    z-index: 22;
    opacity: 1;
    ${sizeLaptop(`
        width: 50px;
        height: 50px;
    `)}
    ${sizeMobileLg(`
        width: 40px;
        height: 40px;
        bottom: 10px;
    `)}
    &:before {
      content: "";
      background: url("/images/next-arrow.svg") center center no-repeat;
      background-size: cover;
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      margin: 0;
      padding: 0;
      opacity: 1;
    }
  }
  .slick-prev {
      left: calc(50% - 100px);
    ${sizeLaptop(`
        left: calc(50% - 70px);
    `)}
    ${sizeMobileLg(`
       left: calc(50% - 50px); 
    `)}
    &:before {
      transform: rotate(360deg);
      background: url("/images/prev-arrow-purple.svg") center center no-repeat;
      background-size: cover;
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      margin: 0;
      padding: 0;
      opacity: 1;
    }

    &.slick-disabled {
      &:before {
        background: url("/images/prev-arrow.svg") center center no-repeat !important;
        background-size: cover;
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        margin: 0;
        padding: 0;
        opacity: 0.5;
      }
    }
  }
  .slick-next {
    &.slick-disabled {
      &:before {
        transform: rotate(180deg);
        background: url("/images/prev-arrow.svg") center center no-repeat !important;
        background-size: cover;
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        margin: 0;
        padding: 0;
        opacity: 0.5;
      }
    }
    right: calc(50% - 100px);
    ${sizeLaptop(`
        right: calc(50% - 70px);
    `)}
    ${sizeMobileLg(`
       right: calc(50% - 50px); 
    `)}
  }
  .slick-slide {
    padding-right: 60px;
    @media (max-width: 1700px) {
      padding-right: 30px;
    }
    ${sizeTabletLg(`
        padding-right: 15px;
    `)}
  }
  
  
  // cards responsiveness
  ${sizeLaptopMd(`
    .mediaCard {
        max-height: 655px !important;
    }
  `)}
  ${sizeTabletLg(`
    .mediaCard,
     .testimonialsCard {
        max-height: 650px !important;
    }
  `)}
  ${sizeMobileLg(`
    .mediaCard {
        padding: 20px;
    }
    .mediaCard,
    .testimonialsCard {
       max-height: 600px !important;
   }
  `)}
  
`;
export const Divider = styled.div`
  height: 1px;
  width: 329px;
  background-color: #000;
  margin-left: 30px;
  margin-top: 30px; 
  margin-bottom: 15px;
  ${sizeLaptop(`
    margin-left: 25px;
    width: 300px;
`)}
${sizeLaptopMd(`
  width: 280px;
`)}
${sizeTabletLg(`
  width: 250px;
  margin-left: 15px;
`)}
${sizeMobileLg(`
  width: 225px;
  margin-left: 15px;
`)}
`;

export const CardContainer = styled.div`
    padding: 0 28px;
    ${sizeTabletLg(`
      padding: 0 15px;
    `)}
`;