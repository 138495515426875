import React, {useState} from "react";

/**
 * This is an loading HOC which takes in a component show loading screen until
 * the condition to remove it has been met. You can add advance loader components here was well with animations if need
 * be
 * @param WrappedComponent
 * @returns {function(*)}
 * @constructor
 */

export const LoadingHoc = (WrappedComponent) => {
  let  style = {
    position: 'fixed',/* Stay in place */
    zIndex: 12222222, /* Sit on top */
    paddingTop: '100px',/* Location of the box */
    left: 0,
    top: '80px',
    width: '100%', /* Full width */
    backgroundColor: 'white',
    height: 'calc(100vh - 80px)', padding: "60px 30px",
  }
  function HOC(props) {

    const [isLoading, setLoading] = useState(true);
    if(isLoading){
      document.body.className = "bodyFixed";
    }else{
      if(document.body.className === 'bodyFixed'){
        document.body.className = "";
      }

    }
    const setLoadingState = (isComponentLoading = true) => {
      setLoading(isComponentLoading)
      if(document.body.className === 'bodyFixed'){
        document.body.className = "";
      }
    }
    return (
      <>
        {isLoading && <div style={style}/>}
        <WrappedComponent {...props} setLoading={setLoadingState}/>
      </>
    )
  }

  return HOC;
};

export default LoadingHoc;
