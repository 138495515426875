import React, {useState} from 'react'
import styled from 'styled-components'
import { TitleXl, TitleXs, TitleSm} from "./elements";
import PlusIconSvg from "../assets/icons/PlusIconSvg";

import {sizeLaptop, sizeMobileLg, sizeTabletLg, sizeMobileXsXs, sizeTablet} from "./devices";
import { Col4, Row } from './elements';
import { TitleContainer, LinkedInContainer } from './LeadershipTeamSection/elements';


export const InnerColContainer = styled.div`
    text-align: left;
    margin: 0 auto;
     width: 44%;
    height: auto;
   ${sizeTabletLg (`
        width: 100%;
        margin: 0;
    `)}
    ${sizeMobileLg(`
        margin: 0;
        width: 100%;
    `)}
`

export const InnerColRowContainer = styled.div`
 display: flex;
 flex-direction: row;
 align-items: center;
 width: 85%;
 margin: 0 auto;

${sizeTabletLg (`
flex-direction: column;
text-align: left;
width: 100%;
margin: 0;
`)}
${sizeMobileLg(`
    flex-direction: column;
    text-align: left;
    width: 100%;
    margin: 0;
`)}
`


export const DaHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 85%;
  margin: 0 auto;
  border-top: 1px solid #D6D6D6;
  padding: 38px 0 50px;
  cursor: pointer;
  transition: 0.25s all ease;
  ${sizeTabletLg (`
    width: 100%;
    padding: 26px 0;
`)}

  .plus-icon {
    path {
      stroke: #D12FFF;
    }
  }

`;
export const DaBody = styled.div`
  padding: 40px 0 0 0;

  ${sizeTabletLg (`
    padding: 0 0 0 0;
`)}

  max-height: 0;
  overflow:hidden;
  transition: max-height 1.3s ease-in-out;
  .row {
    padding: 0 7% 0;
    ${sizeTabletLg(`
        padding: 5% 0 0;
    `)}
  }
  .leadersRow {
    ${sizeTablet(`
        justify-content: space-between !important;
    `)}
  }
      
  ${Col4}{
    ${sizeTabletLg (`
        -ms-flex: 0 0 26%;
        flex: 0 0 26%;
        max-width: 26%;
        justify-content: space-between;
        align-items: flex-start;
        padding: 0 0 40px;
    `)}
    ${sizeMobileLg(`
       -ms-flex: 0 0 45%;
        flex: 0 0 45%;
        max-width: 45%;
        justify-content: space-between;
    `)}
}

`;

export const NameContainer = styled.div`
    padding: 0 0 0;
    ${TitleSm} {
        font-size: 24px;
        ${sizeMobileLg(`
            font-size: 18px;
            line-height: 20px;
        `)}
    }
`

export const ImageContainer = styled.div`
    height: 182px;
    width: 182px;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    .leaderImage {
        width: 136px;
        height: 136px;
        border-radius: 50%;
    ${sizeMobileLg(`
        width: 115px;
        height: 115px; 
    `)}
    }
    ${sizeMobileLg(`
        width: 150px; 
        height: 150px;
    `)}

    ${sizeMobileXsXs(`
        width: 120px;
        height: 120px;
    `)}
`

export const ImageContainerRow = styled.div`
    height: 100px;
    width: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-right: 20px;
    .leaderImage {
        width: 90px;
        height: 90px;
        border-radius: 50%;
        ${sizeTabletLg(`
            width: 115px;
            height: 115px; 
    `)}

    }
    ${sizeMobileLg(`
        padding-right: 0;
        width: 150px; 
        height: 150px;
    `)}

    ${sizeMobileXsXs(`
        width: 120px;
        height: 120px;
    `)}
`

export const ContentContainer = styled.div`
${sizeTabletLg(`
    padding: 40px 0 0;
}
`)}
${sizeMobileLg(`
    padding: 0 0 0;
`)}
`

export const DarkAccordionBlock = styled.div`

  &:last-child {
    margin-bottom: 0 !important;
  }

  ${sizeLaptop(`
    padding: 35px 25px;
  `)}
  ${sizeTabletLg(`
     padding: 20px 25px 0;
     &:first-of-type {
        margin-top: 50px;
     }
  `)}
  ${sizeMobileLg(`
     padding: 20px 15px 0 ;
  `)}
  ${TitleXl} {
    color: #000;
    font-weight: 900;
    font-size: 36px;
    line-height: 43px;
    ${sizeMobileLg(`
        font-size: 20px;
        line-height: 24px;
    `)}
  }

  .plus-icon {
    width: 34px;
    height: 34px;
    transition: 0.35s all ease;
    transform: rotate(0);

    ${sizeLaptop(`
        width: 18px;
        height: 18px;
  `)}
    path {
      stroke: #D12FFF;
    }
  }

  &.show {
    ${DaBody} {
        max-height: 5000px;
        transition: max-height 1.3s ease-in-out;
      
    }

    .plus-icon {
      transform: rotate(45deg);
    }
  }
`;


function LeadershipAccordian({leaders, title, flexRow}) {
    const [acc, setAcc] = useState(false);

    const handleAcc = () => {
        setAcc(!acc);
    }

    return (
        <DarkAccordionBlock className={acc ? 'show' : ''} >
            <DaHeader onClick={handleAcc}>
                <TitleXl fontWeight="900">{title}</TitleXl>
                <PlusIconSvg className={'plus-icon'}/>
            </DaHeader>
            <DaBody>
                {!flexRow && (
                    <Row justifyContent="flex-start" className='leadersRow'>
                    {leaders.cards?.map((data, index) => (
                            <Col4 justifyContent="center" key={index} padding="0 0 90px">
                            <InnerColContainer >
                                <ImageContainer>
                                    <img src={data.image} alt={data.label} className="leaderImage" />
                                </ImageContainer>
                                <NameContainer>
                                    <TitleSm>
                                        {data.label}
                                    </TitleSm>
                                </NameContainer>
                                <TitleContainer>
                                    <TitleXs>
                                        {data.title}
                                    </TitleXs>
                                </TitleContainer>
                                <LinkedInContainer target="_blank" href={data.ctaLink}>
                                    <img src="/images/linkedIN-logo.svg" className={"linkedIn"} alt={"LinkedIn logo link"} />
                                </LinkedInContainer>
                            </InnerColContainer>                        
                        </Col4> 
                    ))} 
                    </Row>
                )}
          
                {flexRow && (
                    <Row className='row'>
                    {leaders.cards?.map((data, index) => (
                        <Col4 justifyContent="center" key={index} padding="0 0 90px">
                            <InnerColRowContainer  >
                                <ImageContainerRow>
                                    <img src={data.image} alt={data.label} className="leaderImage" />
                                </ImageContainerRow>
                                <ContentContainer>
                                <NameContainer>
                                    <TitleSm>
                                        {data.label}
                                    </TitleSm>
                                </NameContainer>
                                <TitleContainer>
                                    <TitleXs>
                                        {data.title}
                                    </TitleXs>
                                </TitleContainer>
                                <LinkedInContainer target="_blank" href={data.ctaLink}>
                                    <img src="/images/linkedIN-logo.svg" className={"linkedIn"} alt={"LinkedIn logo link"} />
                                </LinkedInContainer>
                                </ContentContainer>
                        </InnerColRowContainer >                        
                        </Col4> 
                    ))} 
                    </Row>
                )}
               
            </DaBody>
        </DarkAccordionBlock>
    )
}

export default LeadershipAccordian;
