import styled, {css} from "styled-components";
import {BodySm, BodyRg, TitleContainer} from "components/elements";
import {
    sizeLaptop,
    sizeTabletLg,
    sizeLaptopMd,
    sizeWideScreen,
    sizeLaptopLg,
    sizeDesktopMd,
    sizeDesktopSm
} from "components/devices";
import {
    OverlayBase,
    TitleXs,
    TitleLg,
    TitleMd
} from "components/elements";

export const PanelTitle = styled(OverlayBase)`
  position: relative;
  overflow: hidden;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  box-sizing: border-box;
  padding: 20px 40px;
  outline: 1px solid #707070;
    // border-top: ${({borderTop}) => borderTop || "1px solid #707070"};
    /*    border-right: ${({borderRight}) => borderRight || "1px solid #707070"};
    border-bottom: ${({borderBottom}) => borderBottom || "1px solid #707070"};*/
    //border-left: ${({borderLeft}) => borderLeft || "1px solid #707070"};
  background-color: ${({backgroundColor}) => backgroundColor || "#D12FFF"};

  grid-column-end: ${({cols}) => `span ${cols}` || "span 1"};
  grid-row-end: ${({rows}) => `span ${rows}` || "span 1"};
  max-height: calc(100% - 1px);
  max-width: calc(100% - 1px);

  ${sizeWideScreen(`
        max-height: calc(100% - 2px);
     `)}
  ${sizeLaptopLg(`
        padding: 15px 30px;
    `)}
  ${sizeLaptopMd(`
      padding: 15px 20px;
    `)}
  ${TitleMd} {
    &.index {
      font-family: 'Neue Pixel Grotesk', serif;
      margin-bottom: -3px;
      ${sizeDesktopMd(`
            margin-bottom: -9px;
        `)}
    }


    ${sizeDesktopMd(`
            font-size: 44px;
        `)}
    ${sizeDesktopSm(`
            font-size: 36px;
          `)}
    ${sizeLaptopMd(`
            font-size: 32px;
          `)}
  }
`;


// Empty Card CSS
export const EmptyWrap = styled.div`
  border-collapse: collapse;
  padding: 10px 15px;
  border-top: none;
  box-sizing: border-box;
  width: 100%;
  outline: 1px solid #707070;
    // border-top: ${({borderTop}) => borderTop || "1px solid #707070"};
    // border-right: ${({borderRight}) => borderRight || "1px solid #707070"};
    // border-bottom: ${({borderBottom}) => borderBottom || "1px solid #707070"};
    // border-left: ${({borderLeft}) => borderLeft || "1px solid #707070"};

  background-color: ${({backgroundColor}) => backgroundColor || "#fff"};

  grid-column-end: ${({cols}) => `span ${cols}` || "span 1"};
  grid-row-end: ${({rows}) => `span ${rows}` || "span 1"};
  height: calc(100% - 1px)
`;
export const EmptyImg = styled.img`
  height: 100%;
  width: 100%;
  object-fit: contain;
  border: none;

  &[src=""] {
    display: none;
  }
`;


// Image Comp Style
export const ImgWrap = styled.div`
  border-collapse: collapse;
  box-sizing: border-box;
  padding: ${({imagePadding}) => imagePadding || "0"};
  outline: 1px solid #707070;
    // border-top: ${({borderTop}) => borderTop || "0.5px solid #707070"};
    // border-right: ${({borderRight}) => borderRight || "0.5px solid #707070"};
    // border-bottom: ${({borderBottom}) => borderBottom || "0.5px solid #707070"};
    // border-left: ${({borderLeft}) => borderLeft || "0.5px solid #707070"};
  background-color: ${({backgroundColor}) => backgroundColor || "#fff"};
  grid-column-end: ${({cols}) => `span ${cols}` || "span 1"};
  grid-row-end: ${({rows}) => `span ${rows}` || "span 1"};
  overflow: hidden;
  max-height: calc(100% - 1px);
  max-width: calc(100% - 1px);
  ${sizeWideScreen(`
        max-height: calc(100% - 2px);
     `)}

  ${sizeLaptopMd(`
      padding: 20px;
    `)}
`;
export const SlideImg = styled.img`
  width: 100%;
  object-fit: cover;
  height: 100%;
  height: ${({height}) => height || "100%"};
  width: ${({width}) => width || "100%"};
`;


// Text Component Style
export const TextWrap = styled.div`
  position: relative;
  border-collapse: collapse;
  padding: 35px 75px;
  box-sizing: border-box;
  outline: 1px solid #707070;
    // margin-right: ${({cols}) => cols === 2 ? "1px" : "0"};
    //  border-right: ${({borderRight}) => borderRight || "0.5px solid #707070"};
    //  border-top: ${({borderTop}) => borderTop || "0.5px solid #707070"};
    // border-bottom: ${({borderBottom}) => borderBottom || "0.5px solid #707070"};
    // border-left: ${({borderLeft}) => borderLeft || "0.5px solid #707070"};
  background-color: ${({backgroundColor}) => backgroundColor || "#fff"};

  grid-column-end: ${({cols}) => `span ${cols}` || "span 1"};
  grid-row-end: ${({rows}) => `span ${rows}` || "span 1"};
  overflow: hidden;
  max-height: calc(100% - 1px);
  max-width: calc(100% - 1px);

  ${sizeWideScreen(`
        max-height: calc(100% - 2px);
     `)}
  &:after {
    content: "";
    width: 100%;
    height: 40px;
    background: #FFF;
    position: absolute;
    left: 0;
    bottom: 0;
  }


  ${sizeLaptopLg(css`
    padding: 30px;

    &:after {
      height: 35px;
    }
  `)}
  ${sizeLaptopMd(css`
    padding: 20px;

    &:after {
      height: 25px;
    }
  `)}
  ${BodyRg} {
    max-width: 100%;
    line-height: 1.6;
    margin-bottom: 15px;

    &:last-child {
      margin-bottom: 0;
    }

    ${sizeDesktopMd(`
            font-size: 16px;
            max-width: 93%;
        `)}
    ${sizeLaptopMd(`
          line-height: 1.4;
        `)}
  }

  ${TitleMd} {
    padding: 35px 75px;
    margin: 0;
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    line-height: 1.1;
  }

  h1 {
    ${sizeDesktopMd(`
        font-size: 50px;
      `)}
    ${sizeLaptopMd(`
        font-size: 44px;
      `)}

  }
`;


// Percent Component CSS

export const PercentCompWrap = styled.div`
  padding: 30px;
  box-sizing: border-box;
  outline: 1px solid #707070;
    // border-top: ${({borderTop}) => borderTop || "1px solid #707070"};
    // border-right: ${({borderRight}) => borderRight || "1px solid #707070"};
    // border-bottom: ${({borderBottom}) => borderBottom || "1px solid #707070"};
    // border-left: ${({borderLeft}) => borderLeft || "1px solid #707070"};
  background-color: ${({backgroundColor}) => backgroundColor || "#fff"};
  grid-column-end: ${({cols}) => `span ${cols}` || "span 1"};
  grid-row-end: ${({rows}) => `span ${rows}` || "span 1"};

  ${sizeDesktopMd(`
        padding: 15px 20px;
    `)}
  ${TitleLg} {
    ${sizeDesktopMd(`
            font-size: 42px;
        `)}
  }

  ${TitleXs} {
    margin-bottom: 15px;
    ${sizeDesktopMd(`
            font-size: 20px;
            margin-bottom: 10px;
        `)}
  }
`;

export const MegaSliderWrapper = styled.div`

  /* Wide Screen CSS */

  ${sizeWideScreen(`
    // border-right: 1px solid #707070;
    // border-left: 1px solid #707070;
  `)}
  ${TitleContainer} {
    ${sizeTabletLg(`
        padding: 35px 20px;
        border-bottom: 1px solid #707070;
    `)}
  }
`;


export const MegaSliderHeightContainer = styled.div`
  padding-top: 40px;
  //overflow: hidden;
  ${sizeDesktopMd(`
     padding-top: 30px;
  `)}
  ${sizeTabletLg(`
    display: none;
  `)} /* Wide Screen CSS */ ${sizeWideScreen(`
    height: auto;
  `)}
`;

export const MegaSliderContainer = styled.div`
  display: block;
  ${sizeTabletLg(`
        display:none;
    `)}
  position: relative;
  ${sizeLaptop(`
    overflow: hidden;
  `)}
`;

export const MegaSliderHead = styled.div`
  padding: 35px 104px 0 104px;

  ${BodySm} {
    margin-bottom: 20px;
  }

  ${sizeLaptop(`
      padding: 60px 30px 0 30px;
    `)}

  ${sizeTabletLg(`
      padding: 50px 20px 35px 20px;
  `)}
`;

export const ScrollWrapper = styled.div`
  padding: 0 104px 0px 104px;

  ${sizeLaptopMd(`
    padding: 0 25px;
  `)}
  ${BodyRg} {
    margin-bottom: 15px;
    max-width: 20%;
    width: 100%;
    text-align: center;

    &:first-child {
      text-align: left;
    }

    &:last-child {
      text-align: right;
    }

    ${sizeWideScreen(`
      font-size: 0.6vw;
    `)}
  }

`;

export const LabelsRow = styled.div`
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
`;
export const Scrollbar = styled.div`
  border: 8px solid #000;
  height: 112px;
  position: relative;
  border-radius: 130px;
  padding: 0 30px;
  overflow: hidden;
`;
export const ScrollDot = styled.div`
  width: 48px;
  height: 48px;
  border-radius: 50%;
  background-color: #000;
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  margin: 0 30px;
  cursor: pointer;
`;

export const HealthSlidesWrap = styled.div`
  overflow: hidden;
  display: grid;
  grid-auto-rows: minmax(100%, auto);
  grid-template-rows: 100% auto;
`;
export const SlideInnerWrap = styled.div`
  width: 5760px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  ${sizeLaptopMd(`
    width: 4320px;
  `)}
  ${sizeTabletLg(css`
    display: none;
  `)};
`;

// Slide 2 CSS

// Mobile Slider CSS
export const MobileSliderWrap = styled.div`
  margin-bottom: 40px;
  display: none;

  &:last-child {
    margin-bottom: 0;
  }

  ${sizeTabletLg(css`
    display: block;
  `)}
`;
export const MobileSlide = styled.div`
  height: 365px;

  ${TextWrap} {
    height: 100%;
    width: calc(100% - 15px);
  }
`;
export const MobileSlideImg = styled.img`
  width: calc(100% - 15px);
  height: 100%;
  object-fit: cover;
`;
export const SlideTitleRow = styled.div`
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding: 0 20px 30px 20px;
  width: 100%;
  box-sizing: border-box;
`;
export const SlideCount = styled.div`
  padding: 10px 25px;
  text-align: right;
`;

// Panel CSS Start

export const DragButton = styled.div`
  width: 60px;
  height: 60px;
  border-radius: 100%;
  position: fixed;
  transform: translate(-30px, -30px);
  pointer-events: none;
  z-index: 9999;
  left: 50%;
  top: 50%;
  cursor: none;


  ${sizeTabletLg(`
    display: none;
  `)}
`;

export const PanelGrid = styled.div`
  width: 1700px;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(25%, 1fr));
  grid-auto-rows: minmax(25%, auto);
  grid-template-rows: 25% auto;
  grid-auto-flow: dense;
  height: 650px;
  border-spacing: 0;
  border-collapse: collapse;
  border-top: 1px solid #707070;

  ${sizeDesktopMd(`
    width: 1320px;
    height: 600px;
  `)}
  ${sizeDesktopSm(`
    width: 1260px;
  `)}
  ${sizeLaptopLg(`
    width: 1200px;
  `)}
  ${sizeLaptopMd(`
    width: 930px;
  `)}
    /* Wide Screen CSS */
  ${sizeWideScreen(`
    height: 900px;
    width: 1920px;
  `)}
    /* Home Panel CSS */
  &.homePanel {
    width: 2550px;
    grid-template-columns: repeat(auto-fill, minmax(16.6%, 1fr));

    ${sizeDesktopMd(`
    width: 1920px;
  `)}
    ${sizeDesktopSm(`
    width: 1700px;
  `)}

    ${sizeLaptopLg(`
    width: 1600px;
  `)}
    ${sizeLaptopMd(`
    width: 1400px;
  `)} /* Wide Screen CSS */ ${sizeWideScreen(`
        width: 2460px;
      `)}
  }
`;

export const CustomGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(480px, 1fr));
  grid-auto-rows: minmax(25%, auto);

  grid-column-end: ${({cols}) => `span ${cols}` || "span 1"};
  grid-row-end: ${({rows}) => `span ${rows}` || "span 1"};

  ${sizeLaptopMd(`
    grid-template-columns: repeat(auto-fill, minmax(360px, 1fr));
  `)}
`;

export const DragIcon = styled.img``;
