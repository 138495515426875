import styled, { css } from 'styled-components';

import {
    TitleXs,
    TitleLg,
    TitleXl,
    BodySm,
    Col7,
    Col5
} from "components/elements";
import {
    sizeDesktopMd,
    sizeDesktopSm,
    sizeTabletLg,
    sizeXlDevice,
    sizeMobileLg,
    sizeMobileMd,
    sizeMobileXs,
    sizeWideScreen,
    sizeLaptopLg,
    sizeMobileXsXs,
} from "components/devices";

import {
    JobArrow,
} from "components/JobPostCard/elements";

export const JobCategoryPageWrap = styled.div`
    position: relative;
    width: 100%;
    height: 100%;
  
    ${sizeTabletLg(`
        height: auto;
    `)};
    ${TitleXl} {
        color: #fff;
        font-size: 76px;
        line-height: 91px;
        font-weight: 900;
        padding: 6% 0 0;
        .magenta {
            color: #D12FFF;
        }
        ${sizeMobileLg(`
            font-size: 40px;
            line-height: 48px;
            text-align: left;
            max-width: 70%;
        `)};
        ${sizeMobileXsXs(`
            font-size: 36px;
            line-height: 42px;
        `)}
    }
    .title-copy {
        color: #fff;
        font-size: 16px;
        line-height: 19px;
        text-align: center;
        max-width: 45vw;
        margin: 15px auto 0;
        a {
            color: #fff !important;
            text-decoration: underline;
            display: inline-block;
        }
        ${sizeMobileLg(`
            text-align: left;
        `)};
        ${sizeTabletLg(`
            max-width: 100vw;
        `)}
    }
  
    .job-filter-wrap {
        border-top: 1px solid #707070;
        border-bottom: 1px solid #707070;
    }
    ${Col7} {
        &.job-right {
            border-left: 1px solid #707070;
            overflow: auto;
            min-height: 112vh;
           -ms-overflow-style: none;
            scrollbar-width: none;
            position: relative;
            &::-webkit-scrollbar {
              display: none;
            }
            ${sizeTabletLg(`
                max-width: 100%;
                width: 100%;
                -webkit-box-flex: 0;
                -ms-flex: 0 0 100%;
                        flex: 0 0 100%;
                height: auto;
                margin-left: 0;
            `)};
        }
    }
    ${Col5} {
        &.job-search-section {
            box-sizing: border-box;
            z-index: 10;
            background-color: #fff;
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-orient: vertical;
            -webkit-box-direction: normal;
            -ms-flex-direction: column;
                    flex-direction: column;

                    -webkit-box-align: center;
                    -ms-flex-align: center;
                    -ms-grid-row-align: center;
        align-items: center;
            ${sizeTabletLg(`
                max-width: 100%;
                width: 100%;
                -webkit-box-flex: 0;
                -ms-flex: 0 0 100%;
                        flex: 0 0 100%;
                border-top: none;
                min-height: 100%;
            `)};

            ${sizeWideScreen(`
                border-left: 1px solid #707070;
                border-top: 1px solid #707070;
            `)}
        }
    }
`;

export const BadgeContainer = styled.div `
    position: absolute;
    top: -3px;
    right: 33px;
    ${sizeTabletLg(`
        top: 4px;
    `)}
    ${sizeMobileLg(`
        top: 5px;
        right: 15px;
    `)}
    img {
    ${sizeTabletLg(`
        width: 70px;
    `)}
    ${sizeMobileLg(`
        width: 80px;
    `)}
    ${sizeMobileXs(`
        width: 70px;
    `)}
    `

export const ViewAllContainer = styled.div`
   display: flex;
   justify-content: center;
   padding: 0 0 1.5em;
`


export const CategoryFilterSection = styled.div`
    padding: 40px 90px 40px 90px;
    width: 100%;
    box-sizing: border-box;
    ${sizeLaptopLg(`
        padding: 40px 25px 0 25px;
    `)}
    
    ${TitleLg} {
        font-size: 60px;
        margin-bottom: 30px;
        ${sizeDesktopMd(`
            font-size: 46px;
            margin-bottom: 30px;
        `)}
    }
`;

export const DisclaimerContainer = styled.div`
    &.close {
      display: none;
    }
    ${sizeTabletLg(`
        position: fixed;
        top: 71px;
        left: 0;
        width: 100%;
        height: calc(100% - 71px);
        z-index: 999;
        -webkit-backdrop-filter: blur(4px);
          backdrop-filter: blur(4px);
          background-color: transparent;
        &:before {
          position: absolute;
          // content: '';
          left: 0;
          top: 0;
          width: 100%;
          height: 100%;
          z-index: -1;
          
        }
    `)}
`;

export const HeaderContainer = styled.div`
 width: 100%;
 height: 100%;
 max-height: 700px;
 min-height: 400px;
background-image: url(/images/job-opp-header.svg);
background-position: center center no-repeat;
-webkit-background-size: cover;
-moz-background-size: cover;
background-size: cover;
${sizeWideScreen(`
  margin-top: 40px;
`)}
${sizeMobileLg(`
   background-image: url(/images/job-opp-header-mbl.svg) !important;
`)}
${sizeMobileMd(`
   padding-top: 40px;
`)};
`

export const Disclaimer = styled.div`
    position: relative;
    margin: 70px 0;
    padding: 50px 70px;
    background: #E5FFFF;  
    ${sizeTabletLg(`
        margin: 0 25px;
        top: 50%;
        transform: translateY(-50%);
        padding: 60px 30px;
    `)}
`;

export const DisclaimerCloseBtn = styled.button`
    background-image: url("${({ backgroundImage }) => backgroundImage || ""}");
    background-size: 30px 30px;
    background-repeat: no-repeat;
    background-position: center center;
    position: absolute;
    right: 25px;
    top: 30px;
    width: 35px;
    height: 35px;
    border: none;
    cursor: pointer;
    background-color: transparent;
    z-index: 1;
    &:focus {
        outline: none;
        box-shadow: none;
    }
    ${sizeTabletLg(`
        top: 15px;
        right: 15px;
    `)}
`;

// Search Style
export const SearchGroup = styled.div`
    position: relative;
    padding: 0 0px 30px 0px;
    ${sizeTabletLg(`
        padding: 0 25px 30px 25px;
    `)}
`;
export const SearchInput = styled.input`
    font-size: 20px;
    line-height: 24px;
    color: #fff;
    border: none;
    background-color: transparent;
    padding: 7px 40px 7px 5px;
    box-sizing: border-box;
    width: 100%;
    -webkit-border-radius: 0 !important; 
    -moz-border-radius: 0 !important;   
    border-radius: 0 !important;
    &:focus {
        outline: none;
        box-shadow: none;
    }
    &::placeholder {
        color: #fff;
    }
    &:-webkit-autofill, 
    &:-webkit-autofill, 
    &:-webkit-autofill:hover, 
    &:-webkit-autofill:focus, 
    &:-webkit-autofill, 
    &:-webkit-autofill:hover, 
    &:-webkit-autofill:focus, 
    &:-webkit-autofill, 
    &:-webkit-autofill:hover, 
    &:-webkit-autofill:focus {
        -webkit-text-fill-color: #fff !important;
    }
    ${sizeMobileLg(`
        font-size: 16px;
        line-height: 19px;
    `)}
  
  ::placeholder{
    color: #fff;
    font-size: 20px;
    font-family: inherit;
    line-height: 24px;
    ${sizeMobileLg(`
        font-size: 16px;
        line-height: 19px;
    `)}

  }
`;
export const SearchBtn = styled.button`
    background-image: url("${({ backgroundImage }) => backgroundImage || ""}");
    background-size: 45px 45px;
    background-repeat: no-repeat;
    background-position: center center;
    position: absolute;
    left: 35px;
    top: 50%;
    transform: translateY(-50%);
    width: 45px;
    height: 45px;
    border: none;
    cursor: pointer;
    background-color: transparent;
    z-index: 1;
    &:focus {
        outline: none;
        box-shadow: none;
    }
    ${sizeMobileMd(`
        left: 5px;
    `)};
`;
export const SearchBox = styled.div`
    position: relative;
    padding: 30px 40px 30px 100px;
    margin: 0 auto;
    background-color: transparent;
    border: 1px solid #fff;
    max-width: 914px;
    max-height: 88px;
    box-sizing: border-box;
    &.focused {
        color: #fff;
        background-color: #FFF;
        ${SearchInput} {
             opacity: 1;
        }
    }

    /* For 4k */
    ${sizeXlDevice(`
    `)}

    ${sizeDesktopMd(`
        padding: 20px 25px 20px 100px;
    `)}

    ${sizeDesktopSm(`
    `)}

    ${sizeMobileMd(`
        padding: 15px 15px 15px 50px;
    `)};

    ${sizeMobileXs(`
        
    `)}
`;

export const ResetButton = styled.button`
    font-size: 18px;
    color: #707070;
    text-decoration: underline;
    background: transparent;
    border: none;
    width: fit-content;
    padding: 0;
    transition: color 0.5s ease-in-out;
    margin-bottom: 30px;
  &.searched {
      color: #D12FFF;
      cursor: pointer;
    
`;

export const BadgesRow = styled.div`
    position: sticky;
    top: 0;
    left: 0;
    background: white;
    border-bottom: 1px solid #707070;
    padding: 25px 75px 25px 45px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    justify-content: space-between;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    border-right: 1px solid #707070;
    &.searched {
      ${BodySm} {
        max-height: 22px;
      }
      ${TitleXs} {
        padding-top: 15px;
      }
      ${ResetButton} {
        color: #D12FFF;
        cursor: pointer;
      }
    }
    ${BodySm} {
        color: #707070;
        -webkit-box-flex: 0;
        -ms-flex: 0 0 100%;
        flex: 0 0 100%;
        overflow: hidden;
        max-height: 0;
        transition: max-height 0.5s ease-in-out;
        
        /* For 4k */
        ${sizeXlDevice(`
        `)}
    }
    ${TitleXs} {
      padding-top: 0;
      transition: padding-top 0.5s ease-in-out;
      ${sizeMobileMd(`
        padding-bottom: 15px;
      `)}
    }
    ${sizeLaptopLg(`
        padding: 25px;
    `)}
    ${sizeMobileMd(`
        background: #F7F7F7;
        flex-direction: column;
    `)}
`;




export const CategorySectionRight = styled.div`
    background-color: #fff;
    position: relative;
    margin-top: -2px;
`;

export const FilterCard = styled.div`
    background-color: #D12FFF;
    padding: 12px 15px;
    position: fixed;
    left: 0;
    transition: .6s all ease;
    z-index: 99999;
    width: 100%;
    box-sizing: border-box;
    top: ${({ scrollTop }) => scrollTop ? "-100%" : "-100%"};
    transition-delay: 0s;
    &.showFilterCard {
      ${sizeTabletLg(css`
        top: ${({ scrollTop }) => scrollTop ? "60px" : "60px"};
        // transition: ${({ scrollTop }) => scrollTop ? ".3s all ease" : ".7s all ease"};
      transition: 0.5s all ease-in-out;
    `)}
    }
    ${sizeTabletLg(`
        display: block !important;
    `)}
    ${BodySm} {
        font-weight: 500;
        padding: 2px 0;
        &:last-child {
            &:before {
                content: "";
                width: 10px;
                height: 10px;
                border-top: 2px solid #fff;
                display: inline-block;
                transform: rotate(45deg);
                border-right: 2px solid #fff;
                margin-right: 7px;
            }
        }
    }
`;
export const AddMore = styled.button`
    padding: 40px 0;
    text-align: center;
    cursor: pointer;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
   align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
   justify-content: center;
    background-color: transparent;
    width: 100%;
    border: none;
    border-bottom: 1px solid #707070;
    border-right: 1px solid #707070;
    font-weight: 500;
    font-size: 60px;
    margin-bottom: 30px;
    &:focus {
        outline: none;
        box-shadow: none;
    }

    ${sizeDesktopMd(`
        padding: 30px 0;
    `)};
    ${sizeMobileLg(`
        padding: 20px 0;
    `)}

    &:before {
        content: "";
        width: 0;
        height: 3px;
        background-color: #D12FFF;
        position: absolute;
        left: 0;
        bottom: 0;
        transition: .4s all ease;
        /* transition: width .4s all ease; */
    }
    &:hover {
        line, 
        path {
            stroke: #D12FFF;
        }
        &:before {
            width: 100%;
        }
        ${JobArrow} {
            right: 50px;
        }
    }
`;
export const AddIcon = styled.img`
    ${sizeMobileLg(`
        width: 60px;
    `)}
`;


// Subscribe BLock CSS
export const SubscribeBlock = styled.div`
    padding: 50px 30px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    ${sizeTabletLg(`
        padding: 25px;
    `)};
    ${sizeMobileXs(`
        padding: 20px 15px;
    `)}
`;
