import {useEffect} from "react";

const useRememberScrollPosition = (page) => {

    const doScroll = (path, scrollPage, scrollTo) => {
        const pageName = path[1] !== "" ? path[1] : 'home';
        if(scrollPage === pageName) {
            const scrollInterval = setInterval(() => {
                if(window.scrollY === 0 && scrollTo > 0)
                    window.scrollTo(0, scrollTo);
                else
                    clearInterval(scrollInterval);
            }, 500);
        }
    }
    
    useEffect(() => {
        setTimeout(()=>{
            window.onpopstate = (event)=> {
                const path = event.target.location.pathname.split('/');
                const lastPageScrollPosition = JSON.parse(localStorage.getItem('pageScrollPosition'));
                if(lastPageScrollPosition) {
                    doScroll(path, lastPageScrollPosition.scrollPage, lastPageScrollPosition.scrollTo)
                }
            }
        },5000)

    },[])

    useEffect(() => {
        window.onload = (event)=>{
            const path = event.target.location.pathname.split('/');

            const lastPageScrollPosition = JSON.parse(localStorage.getItem('pageScrollPosition'));
            if(lastPageScrollPosition) {
                doScroll(path, lastPageScrollPosition.page, lastPageScrollPosition.scrollPosition)
            }
        }


        const handleScrollYChange = () => {
            const scrollPosition = window.scrollY;

            const lastPageScrollPosition = JSON.parse(localStorage.getItem('pageScrollPosition'));
            let pageScrollPosition;
            if(lastPageScrollPosition) {
                pageScrollPosition = {...lastPageScrollPosition, page, scrollPosition}
            } else {
                pageScrollPosition = {
                    page,
                    scrollPosition,
                    prevPage: null,
                    prevScrollPosition: null
                }
            }
            localStorage.setItem('pageScrollPosition', JSON.stringify(pageScrollPosition));

        }

        window.addEventListener("scroll", handleScrollYChange, true);
        return () => {
            const lastPageScrollPosition = JSON.parse(localStorage.getItem('pageScrollPosition'));
            if(lastPageScrollPosition) {
                const newPageData = {
                    prevPage: lastPageScrollPosition.page,
                    prevScrollPosition: lastPageScrollPosition.scrollPosition,
                    scrollTo: lastPageScrollPosition.prevScrollPosition,
                    scrollPage: lastPageScrollPosition.prevPage
                };
                localStorage.setItem('pageScrollPosition', JSON.stringify(newPageData));
            }

            window.removeEventListener("scroll", handleScrollYChange, true);
        };
        // eslint-disable-next-line
    }, []);
}

export default useRememberScrollPosition;
