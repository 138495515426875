import styled from 'styled-components';
import { sizeTablet } from 'components/devices';


export const THMModalContainer = styled.div`
position: fixed;
left: 0;
top: 0;
bottom:0;
right: 0;
width: 100%;
height: 100%;
background-size: cover;
background-repeat: no-repeat;
background-position: center center; 
background-color: rgba(74,74,74,0.8);
box-sizing: border-box;
text-align: center;
display: block;
transition: 0.15s all ease; 
display: ${({ show }) => show ? "block" : "none"};
z-index: ${({ show }) => show ? "9999999999" : "-1"};
overflow: auto;
`

export const ContactUsFormContainer = styled.div`
    position: relative;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 45%;
    height: 685px;
    background-color: #F0F0F0;
    padding: 20px 30px 30px;
    display: flex;
    align-items: center;
    overflow: scroll;
    .exitIcon {
        position: absolute;
        top: 20px;
        right: 20px;
        cursor: pointer;
    }
    ${sizeTablet (`
        width: 94%;  
        padding: 20px 10px 20px;
        height: 650px;
    `)}
`