import React from 'react';
import {
    TitleLg,
    BodyRg, TitleXs, UL, LI
} from "components/elements"

import {
    ServicesProvidersPageWrap,
    ServicesContainer,
    ServicesTable,
    Table,
    TableHead,
    TableRow,
    TableBody,
    Th,
    TableData, PreviousVersions
} from "./elements";

import { Helmet } from "react-helmet";


function ServicesProvidersPage() {

    // let date = new Date();

    let todayDate = 'September 15, 2024';// date.toLocaleString('default', { month: 'long' }) + " " + date.getDate() + ", " + date.getFullYear();
    return (
        <ServicesProvidersPageWrap className="pageWrapper">

            <Helmet>
                <title>{"Service Providers | Real Chemistry"}</title>
                <link rel="canonical" href={window.location.pathname} />
                <meta name="description" content={"Service Providers"} />
                <meta name="og:description" content={"Service Providers"} />
                <meta name="og:title" content={"Service Providers | Real Chemistry"} />
                <meta name="og:url" content={window.location.protocol + "//" + window.location.host + window.location.pathname} />
                <meta name="og:type" content="website" />
                <meta name="og:site_name" content="Real Chemistry" />

            </Helmet>
            <ServicesContainer>
                <TitleLg textAlign="center">Service Providers</TitleLg>
                <BodyRg textAlign="center">Updated: {todayDate}</BodyRg>

                <ServicesTable cellpadding="0" cellspacing="0">
                    <Table>
                        <TableHead>
                            <TableRow>
                                <Th>Name</Th>
                                <Th>Purpose</Th>
                                <Th>Location(s)</Th>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <TableRow>
                                <TableData>Amazon Web Services (AWS)</TableData>
                                <TableData>Cloud Storage and Data processing</TableData>
                                <TableData>USA</TableData>
                            </TableRow>
                            <TableRow>
                                <TableData>Adobe, Inc.</TableData>
                                <TableData>Document handling</TableData>
                                <TableData>USA</TableData>
                            </TableRow>
                            <TableRow>
                                <TableData>Runtime Collective Limited d/b/a Brandwatch</TableData>
                                <TableData>Research and Analytics</TableData>
                                <TableData>USA, United Kingdom</TableData>
                            </TableRow>
                            <TableRow>
                                <TableData>Box Inc.</TableData>
                                <TableData>Cloud Storage</TableData>
                                <TableData>USA, United Kingdom and EU</TableData>
                            </TableRow>
                            <TableRow>
                                <TableData>ComScore, Inc.</TableData>
                                <TableData>Research and Analytics</TableData>
                                <TableData>USA</TableData>
                            </TableRow>
                            <TableRow>
                                <TableData>Contentful Inc.</TableData>
                                <TableData>Marketing</TableData>
                                <TableData>USA</TableData>
                            </TableRow>
                            <TableRow>
                                <TableData>Critical Mention, Inc.</TableData>
                                <TableData>Research and Analytics</TableData>
                                <TableData>USA</TableData>
                            </TableRow>
                            <TableRow>
                                <TableData>Domo, Inc.</TableData>
                                <TableData>Research and Analytics</TableData>
                                <TableData>USA</TableData>
                            </TableRow>
                            <TableRow>
                                <TableData>Freshworks Inc.</TableData>
                                <TableData>Operations</TableData>
                                <TableData>USA</TableData>
                            </TableRow>
                            <TableRow>
                                <TableData>Google, LLC</TableData>
                                <TableData>Advertising,&nbsp; Cloud Storage, Research and Analytics</TableData>
                                <TableData>USA</TableData>
                            </TableRow>
                            <TableRow>
                                <TableData>InformaIT AB</TableData>
                                <TableData>Operations</TableData>
                                <TableData>Sweden</TableData>
                            </TableRow>
                            <TableRow>
                                <TableData>Kantar Group</TableData>
                                <TableData>Research and Analytics</TableData>
                                <TableData>USA</TableData>
                            </TableRow>
                            <TableRow>
                                <TableData>Marketo, Inc.</TableData>
                                <TableData>Marketing</TableData>
                                <TableData>USA</TableData>
                            </TableRow>
                            <TableRow>
                                <TableData>The Rocket Science Group LLC, d/b/a Mailchimp</TableData>
                                <TableData>Marketing</TableData>
                                <TableData>USA</TableData>
                            </TableRow>
                            <TableRow>
                                <TableData>MediaMath, Inc.</TableData>
                                <TableData>Marketing</TableData>
                                <TableData>USA, Ireland</TableData>
                            </TableRow>
                            <TableRow>
                                <TableData>Mediaocean, LLC</TableData>
                                <TableData>Marketing</TableData>
                                <TableData>USA</TableData>
                            </TableRow>
                            <TableRow>
                                <TableData>Meltwater News US Inc.</TableData>
                                <TableData>Research and Analytics</TableData>
                                <TableData>USA</TableData>
                            </TableRow>
                            <TableRow>
                                <TableData>Microsoft Corporation.</TableData>
                                <TableData>Email, Cloud Storage</TableData>
                                <TableData>USA, Ireland</TableData>
                            </TableRow>
                            <TableRow>
                                <TableData>RealtimeBoard Inc. d/b/a Miro</TableData>
                                <TableData>Collaborations</TableData>
                                <TableData>USA, United Kingdom</TableData>
                            </TableRow>
                            <TableRow>
                                <TableData>Muck Rack, LLC</TableData>
                                <TableData>Research and Analytics</TableData>
                                <TableData>USA</TableData>
                            </TableRow>
                            <TableRow>
                                <TableData>NetBase Solutions, Inc., Quid, LLC d/b/a NetBase Quid</TableData>
                                <TableData>Research and Analytics</TableData>
                                <TableData>USA</TableData>
                            </TableRow>
                            <TableRow>
                                <TableData>OneTrust, LLC</TableData>
                                <TableData>Operations</TableData>
                                <TableData>USA</TableData>
                            </TableRow>
                            <TableRow>
                                <TableData>Oracle America Inc.</TableData>
                                <TableData>Operations</TableData>
                                <TableData>USA</TableData>
                            </TableRow>
                            <TableRow>
                                <TableData>Otter.ai, Inc.</TableData>
                                <TableData>Automated Meeting Transcription and Summarization Tool</TableData>
                                <TableData>USA</TableData>
                            </TableRow>
                            <TableRow>
                                <TableData>Qualtrics, LLC</TableData>
                                <TableData>Research and Analytics</TableData>
                                <TableData>USA</TableData>
                            </TableRow>
                            <TableRow>
                                <TableData>Salesforce Inc.</TableData>
                                <TableData>Marketing</TableData>
                                <TableData>USA</TableData>
                            </TableRow>
                            <TableRow>
                                <TableData>SurveyMonkey Inc.</TableData>
                                <TableData>Research and Analytics</TableData>
                                <TableData>USA, United Kingdom</TableData>
                            </TableRow>
                            <TableRow>
                                <TableData>Tableau Software, Inc.</TableData>
                                <TableData>Research and Analytics</TableData>
                                <TableData>USA</TableData>
                            </TableRow>
                            <TableRow>
                                <TableData>The Virtu Group Ltd.</TableData>
                                <TableData>Operations</TableData>
                                <TableData>United Kingdom</TableData>
                            </TableRow>
                            <TableRow>
                                <TableData>Zoom Video Communications, Inc.</TableData>
                                <TableData>Operations</TableData>
                                <TableData>USA</TableData>
                            </TableRow>
                        </TableBody>
                    </Table>
                </ServicesTable>
                <PreviousVersions>
                    <TitleXs>
                        Previous Versions:
                    </TitleXs>
                    <UL>
                        <LI >
                            <a style={{ justifyContent: 'start', display: 'flex', fontSize: '1.25em !important' }} href="/pdf/2022.04.15-2024.09.15-List of Approved Sub-Processors.pdf" download>
                                <TitleXs>Service Providers April 15, 2022 to September 15, 2024</TitleXs>
                            </a>
                        </LI>
                        <LI >
                            <a style={{ justifyContent: 'start', display: 'flex', fontSize: '1.25em !important' }} href="/pdf/Service Providers_03212021_to_04152022.pdf" download>
                                <TitleXs>Service Providers March 26, 2021 to April 15, 2022</TitleXs>
                            </a>
                        </LI>

                    </UL>

                </PreviousVersions>
            </ServicesContainer>
        </ServicesProvidersPageWrap>
    );
}

export default ServicesProvidersPage;
