import React, { useState } from 'react';
import { SubMenu } from "../elements";
import InfoCard from "./InfoCard";
import ContentCard from "./ContentCard";
import { Col3, Col4, Col9, Row } from "components/elements";

function AlchemyMenu(props) {
  const [clickAllowed, setClickAllowed] = useState(true);


  // if another link is clicked within the 600 ms window it makes
  // the client jump between links
  const handleClick = () => {
    if (clickAllowed) {
      setClickAllowed(false);
      setTimeout(() => {
        setClickAllowed(true);
      }, 750);
    }
  };

  return (
    <SubMenu showSubMenu={props.open}>
      <Col3>
        <InfoCard rows={3} cols={2} data={props.data} />
      </Col3>
      <Col9>
        <Row>
          {props.data?.subNavigation?.cards?.map((card, index) => (
            <Col4 onClick={handleClick} key={index}>
              <ContentCard rows={3} cols={2} data={card} clickAllowed={clickAllowed} borderBottom="none" minHeight="105px" />
            </Col4>
          ))}
        </Row>
      </Col9>
    </SubMenu>
  );
}

export default AlchemyMenu;
