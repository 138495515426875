import React from 'react';
import {
    RectContainer,
    LongLine,
    BigRectItem,
    ArrowWrap,
    ItemContainer,
} from './elements';
import { ReactComponent as Arrow } from './svg/arrow.svg';

export const BigRect = ({ topLine, item, size, isShown, setIsShown, setItemIndex }) => {
    const handleClick = () => {
        setIsShown(true);
        setItemIndex(item.no);
    }

    return (
        <RectContainer
            width={item.width}
        >
            {
                !topLine && (
                    <LongLine length={size.longLine} color={item.color} margin={size.bigWidth} />
                )
            }
            <ItemContainer onClick={handleClick}>
                <BigRectItem
                    width={size.bigWidth}
                    height={size.bigHeight}
                    marginLeft={item.marginLeft}
                    color={item.color}
                    dangerouslySetInnerHTML={{ __html: '<p>' + item.title + '</p>' }}
                />
                <ArrowWrap>
                    <Arrow />
                </ArrowWrap>
            </ItemContainer>
            {
                topLine && (
                    <LongLine length={size.longLine} color={item.color} margin={size.bigWidth} />
                )
            }
        </RectContainer>
    );
}

export default BigRect;