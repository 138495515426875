import React from 'react'

import
{
	BodySm,
	TitleXs,
	Label
} from 'components/elements'

import
{
	JobPostCardWrap,
	JobPostContainer,
	JobArrow,
	JobCardLink
} from './elements'
import moment from 'moment';


function JobPostCard({ postDate, jobLocation, jobTitle, jobDescription, jobLink, borderTop, borderRight, borderBottom, borderLeft }) {
    return (
        <JobPostCardWrap
            borderTop={borderTop}
            borderRight={borderRight}
            borderBottom={borderBottom}
            borderLeft={borderLeft}
        >
            <JobCardLink to={jobLink}>
                <JobPostContainer>
                    <Label>{moment(postDate).format("MMMM D, YYYY")}</Label>

                    <TitleXs>{jobTitle}</TitleXs>
                    <BodySm fontSize="14px" textTransform="uppercase">{jobLocation}</BodySm>
                    {
                        jobDescription ? <BodySm>
                            {jobDescription}
                        </BodySm> : ""

                    }
                </JobPostContainer>
                <JobArrow>
                    <svg xmlns="http://www.w3.org/2000/svg" width="86" height="47.114" viewBox="0 0 86 47.114">
                        <g id="M_Arrow" data-name="M Arrow" transform="translate(0 1.414)">
                            <line id="Line_18" data-name="Line 18" x2="83.905" transform="translate(0 21.967)" fill="none" stroke="#D12FFF" strokeWidth="4" />
                            <path id="Path_592" data-name="Path 592" d="M0,0,22.143,22.143,0,44.286"
							transform="translate(61.857 0)" fill="none" stroke="#D12FFF" strokeLinejoin="round" strokeWidth="4" />
                        </g>
                    </svg>
                </JobArrow>
            </JobCardLink>
        </JobPostCardWrap>
    )
}

export default JobPostCard
