import React from 'react'

import { SectionTitle,  TitleXl } from '../elements'
import { AddIcon, AddMore, PagedListWrap, PagedListRowsWrap } from './elements'
import PagedListRow from './PagedListRow'
import LoadMore from "../LoadMore";

function PagedList(props) {
	
	const PAGE_SIZE = props.no ? props.no : 4
	const [rows, setRows] = React.useState([])
	const [list, setList] = React.useState([])
	const [addMoreViz, setAddMoreViz] = React.useState(true)


	React.useEffect(() => {
		const PAGE_SIZE = props.no ? props.no : 4
		if(props?.rows?.length <= PAGE_SIZE){
			setAddMoreViz(false)
		}
		if (props.rows) {
			setRows(props.rows)
			setList(props.rows.slice(0, PAGE_SIZE))
		}
		// eslint-disable-next-line
	}, [rows, props.rows])

	const handleOnAddMoreBtnClick = (e) => {
		setList(rows.slice(0, list.length + PAGE_SIZE))

		if (list.length + PAGE_SIZE >= rows.length)
			setAddMoreViz(false)
	}

	return (
		<PagedListWrap id={props.id} borderWideScreen={props.borderWideScreen}>
			<SectionTitle padding="100px 80px;">
				<TitleXl>{props.title}</TitleXl>
			</SectionTitle>

			<PagedListRowsWrap className={props.className} key={'plrow.' + Math.random()} listBorder={props.listBorder}>
				{
					list.map((item, index) => {
						return item.date ? <PagedListRow
							key={'plrow.' + index + Math.random()}
							fromInsights={props.fromInsights}
							item={item}
							index={index}
						/>
							: ''
					})
				}

				{
					addMoreViz ?
						props.altLoadMore? <LoadMore
							onClick={handleOnAddMoreBtnClick}
							text={'Load more'}
							list={list?.length - 8}
							borderBottom={props.borderBottom}
							borderTop={props.borderTop}
							totalLength={rows?.length}
							paddingRight={"32px"}
							padding={"80px 32px 80px"}
						/>:<AddMore
							className="expand"
							borderBottom={props.borderBottom}
							borderTop={props.borderTop}
							onClick={handleOnAddMoreBtnClick}>
							<AddIcon src="/images/plus-icon-black.svg" />
						</AddMore>
						: ''
				}
			</PagedListRowsWrap>
		</PagedListWrap>
	)
}

export default PagedList
