import React, { useEffect } from "react";

import {Label,Input,Indicator, } from "./elements";

export default function Checkbox({
                                     value,
                                     checked,
                                     onChange,
                                     name,
                                     id,
                                     disabled,
                                     handleCheck,
                                     option,
                                     optionsCount

                                 }) {

    const [customId, setCustomId] = React.useState();


  useEffect(() => {

    let newId = id.replace(/\W/g, '_').toLowerCase()
    setCustomId(newId)

  },[id])

    return (
        <Label for={customId} htmlFor={option} aria-label={id} disabled={disabled}>
            <Input
                id={customId}
                aria-labelledby={customId}
                aria-label={`Check box for ${id}`}
                type="checkbox"
                name={name}
                value={value}
                disabled={disabled}
                checked={checked}
                aria-checked={checked}
                onChange={onChange}
                readOnly
            />
            <Indicator className={checked ? "checked" : null}
                       onClick={handleCheck} disabled={disabled}/>
            {option + (optionsCount ? " (" + optionsCount + ")" : "")}
        </Label>
    );
}
