import React from 'react';
import {
    PageInfo,
    PageLink,
    InfoContainer,
} from "../elements";

import {
    BodySm,
    BodyRg,
} from "components/elements";

function InfoCard({ data, cols, rows }) {
    return (
        <PageInfo to={data?.subNavigation ? data?.subNavigation?.ctaDestination : "#"} cols={cols} rows={rows}>
            <InfoContainer>
                <BodyRg>{data?.subNavigation?.headline}</BodyRg>
                <BodySm>{data?.subNavigation?.description}</BodySm>
                <PageLink>{data?.subNavigation?.ctaText}</PageLink>
            </InfoContainer>
        </PageInfo>
    );
}

export default InfoCard;
