import { TitleSm,  BodySm } from 'components/elements'
import styled, { css } from 'styled-components'
import { sizeLaptopLg, sizeTablet, sizeLaptop, sizeLaptopMd } from 'components/devices'

export const Container = styled.div`
    width: 100%;
    max-width: 563px;
    height: auto;
    // height: 572px;
    background-color: ${({backgroundColor}) => backgroundColor || "#FFFFFF"};
    padding: 30px 30px 60px;
    ${TitleSm}{
        font-size: 28px;
        line-height: 1.2;
        color: #000000;
        font-weight: 900;
        font-family: "HelveticaNowDisplay Black";
        max-width: 60%;
        height: 105px;
        ${sizeLaptopLg (`
            max-width: 70%;
        `)}
        ${sizeLaptopMd(`
            height: unset;
            max-width: 77%;
        `)}
        ${sizeLaptop (`
    
            padding: 20px 0;
        `)}
        ${sizeTablet (`
            font-size: 20px;
            height: unset;
            padding: 20px 0;
        `)}
    }
    ${sizeLaptop (`
        height: 100%;  
    `)}
    ${sizeTablet (`
        width: 100%;
        padding: 30px 20px 60px;
        height: 100%;
    `)}

`

export const HeaderPill = styled.span`
    background-color: #000000;
    padding: 8px 16px;
    border-radius: 100px;
    text-align: center;
    font-size: 16px;
    font-weight: 400;
    color: #ffffff;
`

export const RichTextContainer = styled.div`
    width: 100%;
    border-top: 1px solid #000000;
    border-bottom: 1px solid #000000;
    padding: 15px 0;
    // height: 330px;
    min-height: 360px;
    ${sizeLaptopLg(`
        // height: 360px;
    `)}
    ${sizeLaptop (`
        height: unset;
        padding: 15px 0;  
        min-height: unset;  
    `)}
    ${sizeTablet (`
          height: unset;
          padding: 15px 0;      
    `)}
    p {
        font-size: 18px;
         line-height: 1.2;  
         padding: 20px 0 0;
         &:first-of-type {
            padding: 0 0 0;
        }
    }
    ul li p {
        font-size: 16px;
        padding: 0 0 0;
    }
    ul li {
        margin: 0 0 10px 0;
        vertical-align: middle;
    }

    ul {
        list-style-type: square;
        font-size: 11px;
        margin: 0 0 0px 20px;
    }

`

export const CTAContainer = styled.div`
    padding: 30px 0 0;
    ${BodySm}{
        color: #000000;
        font-size: 14px;
        font-weight: 700;
    }
    ${TitleSm}{
        font-size: 16px;
        text-transform: uppercase;
        font-weight: 700;
        max-width: unset;
        height: unset;
        font-family: unset;
        padding: unset;
    }

    button {
        height: 38px;
        width: 213px;
        margin: 20px 0 0;
        border: 2.5px solid #252525;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        text-transform: uppercase;
        background: transparent;
        font-weight: 700;
        font-size: 14px;
        line-height: 1.1;
        color: #252525;
        font-family: Helvetica;
        letter-spacing: 0.13rem;
        &:hover{
            background-color: #000000;
            color: #D12FFF;

        }
        &.disabledBtn {
            cursor: unset;
            &:hover{
                background-color: transparent;
                color: #252525;
            }

        }
    }

   

`