import { Link } from "react-router-dom";
import styled from "styled-components";
import {sizeDesktopSm, sizeMobileMd, sizeMobileXs, sizeTabletLg} from "../devices";

export const GalaxyWrapper = styled.div`
    background-color: #000;
    padding: 40px 40px 40px 0;
    ${sizeTabletLg(`
      padding: 30px 0;
    `)}
`;
export const Col6Custom = styled.div`
  -webkit-box-flex: 0;
  -ms-flex: 0 0 50%;
  flex: 0 0 50%;
  max-width: 50%;
  width: 100%;
  padding: ${({ padding }) => padding || "0"};
  box-sizing: border-box;
  height: ${({ height }) => height || "100%"};
  ${sizeTabletLg(`
  -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  `)}
  .
  &.btn {
    ${sizeDesktopSm(`
  -ms-flex: 0 0 100%;
    flex: 0 0 100%; 
    max-width: 100%;
    max-width: 100%;
  `)}
  }
`;

export const GalaxySvgWrap = styled.div`
    width: 100%;
    height: 100%;
    max-width: 600px;
    margin: 0 auto;
    position: relative;
    z-index: 1;
    svg, g {
        width: 100%;
        height: 100%;
    }
`;
export const GalaxySvg = styled.div`
    height: 100%;
    width: 100%;
`;
export const GalaxyVideo = styled.div`
    height: 100%;
    width: 60%;
    position: absolute;
    left: 50%;
    top: 0;
    transform: translateX(-50%);
    z-index: -1;
`;
export const Video = styled.video`
    height: 100%;
    width: 100%;
`;
export const AccordionWrap = styled.div`

`;
export const AccordionHeader = styled.div`
    padding: 15px 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    ${sizeTabletLg(`
      padding:15px 20px;
    `)}
    svg {
        display: -webkit-box;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        width: 2em;
        height: 2em;
        -webkit-box-pack: center;
        -webkit-justify-content: center;
        -ms-flex-pack: center;
        justify-content: center;
        -webkit-box-align: center;
        -webkit-align-items: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-box-flex: 0;
        -webkit-flex: 0 0 auto;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        -webkit-transition: -webkit-transform 475ms ease;
        transition: -webkit-transform 475ms ease;
        transition: transform 475ms ease;
        transition: transform 475ms ease, -webkit-transform 475ms ease;
    }
  
`;
export const AccordionBody = styled.div`
    height: 0;
    padding: 0;
    overflow: hidden;
    -webkit-transition: -webkit-height .3s ease;
        transition: -webkit-height .3s ease;
        transition: height .3s ease;
        transition: height .3s ease, -webkit-height .3s ease;
`;
export const AccordionLink = styled(Link)`
    color: #fff;
  margin-top: 2em;
  display: inline-block;
  ${sizeMobileMd(`
    margin-top: 1.2em;
  `)}
`;

export const BodyInner = styled.div`
    padding: 20px;
    opacity: 0;
    transition: .3s all ease;
    transition-delay: .4s;
  ${sizeMobileMd(`
    padding: 10px 20px;
  `)}
`;
export const Accordion = styled.div`
    border-bottom: ${({ color }) => `5px solid ${color}` || "1px solid #707070"};
    margin-bottom: 20px;
  &:last-child {
    margin-bottom: 0;
  }
    &.active {
      background: ${({ color }) => color || '#000'};
        ${AccordionHeader} {
            svg {
                -webkit-transform: rotate(-45deg);
                -ms-transform: rotate(-45deg);
                transform: rotate(-45deg);
            }
        }
         ${AccordionBody} {
             height: 200px;
             overflow: visible;
           ${sizeMobileXs(`
                height: 250px;
           `)}
         }
         ${BodyInner} {
            opacity: 1;
         }
    }
    &.hidden{
      ${sizeTabletLg(`
      display:none;
    `)}
    }
 
`;
