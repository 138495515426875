import React, { useState, useRef } from 'react'
import { ExpCardsWrapper, CardsContainer, ExpCardsRow } from './elements'
import { TitleXl, TitleContainer } from '../elements'

import CardSection from './CardSection'

function ExpandingCards({ componentData })
{
	const serviceWrapper = useRef(null)
	const [cards, setCards] = React.useState([])
	const [title, setTitle] = React.useState('')
	const [overrideColor, setOverrideColor] = React.useState(null)
	const [clickedOutSide, setClickedOutSide] = useState(false)

	let isOpened = false

	const handleOutSideClick = (e) =>
	{
		e.persist()
		if (e.target.classList[e.target.classList.length - 1] === 'serviceCardContainer' && isOpened)
			setClickedOutSide(true)
	}

	const updateCardState = (state) =>
	{
		if (!state)
			setClickedOutSide(false)
		isOpened = state
	}

	React.useEffect(() =>
	{
		if ( componentData )
		{
			setCards( componentData.items )
			setTitle( componentData.title )
			setOverrideColor( componentData.cardColor )
		}
	}, [componentData])

	return (
		<ExpCardsWrapper ref={serviceWrapper}>
			<TitleContainer>
				<TitleXl>{title}</TitleXl>
			</TitleContainer>
			<CardsContainer className="serviceCardContainer" onClick={handleOutSideClick}>
				<ExpCardsRow className="serviceCardContainer" onClick={handleOutSideClick}>
					<CardSection
						cards={cards}
						clickedOutSide={clickedOutSide}
						overrideColor={overrideColor}
						updateCardState={updateCardState}
						wrapperWidth={serviceWrapper?.current?.offsetWidth}
					/>
				</ExpCardsRow>
			</CardsContainer>
		</ExpCardsWrapper>
	)
}

export default ExpandingCards
