
import React from 'react'


import { TextWrap } from './elements'
import { TitleMd } from 'components/elements'
import RichText from 'components/RichText'



function TextComp({ borderTop, borderRight, borderBottom, borderLeft, title, backgroundColor, rows, cols, description }) {
	const [text, setText] = React.useState(null)

	React.useEffect(() => {
		if (description)
			setText(description)
	}, [description])

	return (
		<TextWrap
			borderTop={borderTop}
			borderRight={borderRight}
			borderBottom={borderBottom}
			borderLeft={borderLeft}
			backgroundColor={backgroundColor}
			rows={rows}
			cols={cols}
		>
			{ title ? <TitleMd lineHeight="1.1">{title}</TitleMd> : ''}

			<RichText body={text} />

		</TextWrap >
	)
}

export default TextComp;
