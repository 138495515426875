const cfapi	= require('contentful')
const cfcfg	= require('../cf/r3c/config').contentful
require('dotenv').config()


const useContentful = () => {

let env = process.env.REACT_APP_RC_ENV || process.env.RC_ENV

let accessToken = cfcfg.deliveryToken
let spaceID = cfcfg.spaceId 

if ( env === undefined ){
	console.warn( `RC_ENV ${env} unknown; using dev` )
	env = 'dev'
}

    const dlvrClient =()=>
    {
        return cfapi.createClient
        ({
            accessToken: accessToken,
            environment: env,
            space: spaceID
            
        })
    }

    const getCaseStudyEntry = async (slug) => {

        try {
           const caseStudy = await dlvrClient().getEntries({
                "fields.slug": slug,
                content_type: "caseStudy"
            })
            
            return caseStudy
        }catch(err){
            console.log(err, 'err')
        }

    }

    const getAllCaseStudiesForBu = async (bu) => {

        try {
           const caseStudy = await dlvrClient().getEntries({
                "fields.type": bu,
                content_type: "caseStudy"
            })
            
            return caseStudy
        }catch(err){
            console.log(err, 'err')
        }

    }

    return {getCaseStudyEntry, getAllCaseStudiesForBu }

}

export default useContentful
