import React from 'react';
import {
    TitleLg,
    BodyRg,
    Bold,
    TitleSm,
    BodySm,
    SectionTitle,
} from "components/elements"

import {
    GlobalVendorDpaPageWrap,
    GlobalVendorDpaContainer,
    OrderedList
} from "./elements";

import {Helmet} from "react-helmet";
import { OrderedListItem, RomanList } from 'pages/global-dpa/elements';


function GlobalVendorDpaPage() {

    // let date = new Date();

    // let todayDate = date.toLocaleString('default', { month: 'long' }) + " " + date.getDate() + ", " + date.getFullYear();
    return (
        <GlobalVendorDpaPageWrap className="pageWrapper">
            <Helmet>
                <title>{"Global Vendor DPA | Real Chemistry"}</title>
                <link rel="canonical" href={ window.location.pathname} />
                <meta name="description" content={"Global Vendor DPA"} />
                <meta name="og:description" content={"Global Vendor DPA"} />
                <meta name="og:title" content={"Global Vendor DPA | Real Chemistry"} />
                <meta name="og:url" content={window.location.protocol + "//" + window.location.host + window.location.pathname} />
                <meta name="og:type" content="website" />
                <meta name="og:site_name" content="Real Chemistry" />

            </Helmet>
            <GlobalVendorDpaContainer>
                <SectionTitle textAlign="center">
                    <BodySm textTransform="uppercase" textAlign="center">LAST UPDATED: JANUARY 3, 2022</BodySm>
                    <TitleLg textAlign="center">Global Vendor DPA</TitleLg>
                </SectionTitle>

                <TitleSm>DATA PROCESSING AGREEMENT (the, "DPA")</TitleSm>

                <BodyRg>
                 In connection with a master agreement for the provision of services (the, “<Bold>Main Agreement</Bold>”) by and between the applicable vendor (“<Bold>Vendor</Bold>”) and The WeissComm Group Ltd., d/b/a Real Chemistry (“<Bold>Company</Bold>”), Vendor will provide to Company certain services (collectively, the “<Bold>Services</Bold>”) pursuant to the Main Agreement. This DPA is effective as of the effective date of the Main Agreement, and amends and is made part of the applicable Main Agreement.
                </BodyRg>
                <BodyRg>
                    The purpose of this DPA is to ensure that the parties Process all Personal Data in a manner that complies with the parties’ respective duties under this DPA and applicable Data Privacy Laws.
                </BodyRg>
                <BodyRg>
                    <Bold>AGREED TERMS</Bold>
                </BodyRg>
                <BodyRg>
                <Bold>1.	Definitions and interpretation</Bold>
                </BodyRg>
                <BodyRg>
                    Capitalised terms used in this DPA shall have the following meanings:
                </BodyRg>
                <BodyRg>
                1.1	    "<Bold>Company Personal Data</Bold>" means any Personal Data Company provides Vendor under the Agreement and as further specified in Schedule 1 to this Addendum;
                </BodyRg>
                <BodyRg>
                1.2	“<Bold>EU Controller-to-Processor Clauses</Bold>” means the Standard Contractual Clauses between controllers and processors for Data Transfers, as approved by the European Commission Implementing Decision (EU) 2021/914 of 4 June 2021, as may be amended or replaced from time to time by a competent authority under the relevant Data Protection Laws;
                </BodyRg>
                <BodyRg>
                1.3	“<Bold>Data Controller</Bold>” means the natural or legal person, public authority, agency or any other body that alone or jointly with others determines the purposes and means of the Processing of Personal Data;
                </BodyRg>
                <BodyRg>
                1.4	“<Bold>Data Processor</Bold>” means a natural or legal person, public authority, agency or any other body that Processes Personal Data on behalf of a Data Controller;
                </BodyRg>
                <BodyRg>
                1.5	“<Bold>Data Privacy Laws</Bold>” shall mean all laws, rules, regulations, and orders of any jurisdiction or subdivision thereof relating to the privacy, security, confidentiality and/or integrity of Personal Data that are applicable to Services under the Main Agreement. 
                </BodyRg>
                <BodyRg>
                1.6	“<Bold>Data Subject</Bold>” means any individual about whom Personal Data may be Processed under this DPA;
                </BodyRg>
                <BodyRg>
                1.7	“<Bold>Data Subject Request</Bold>” means any communication from a Data Subject or authorized agent regarding the exercise of Data Subject rights pursuant to Data Privacy Laws;
                </BodyRg>
                <BodyRg>
                1.8	"<Bold>Personal Data</Bold>" means that information, whether alone or in combination with other information that directly or indirectly identifies a Data Subject or household, or any data defined as Personal Data under Data Privacy Laws, which is Processed as part of this DPA and under the Main Agreement; 
                </BodyRg>
                <BodyRg>
                1.9	 “<Bold>Processing</Bold>”, “<Bold>Process</Bold>” and/or “<Bold>Processed</Bold>” includes any operation or set of operations which is performed on Personal Data, whether or not by automated means, including but not limited to the collection, recording, organisation, structuring, storage, adaptation or alteration, retrieval, consultation, use, access, disclosure, copying, transfer, dissemination or otherwise making available, deletion, alignment or combination, restriction, anonymization, disposal, erasure, destruction, or other use of Personal Data;
                </BodyRg>
                <BodyRg>
                1.10	"<Bold>Security Breach</Bold>" means a breach of (i) security leading to the accidental or unlawful destruction, loss, alteration, unauthorised disclosure of, or access to, Personal Data transmitted, stored or otherwise Processed in connection with this Agreement by Vendor or any of Vendor’s Sub-processors, or any other identified or unidentified third party, or (ii) this DPA or any data protection, confidentiality or security provisions of the Main Agreement;
                </BodyRg>
                <BodyRg>
                1.11	“<Bold>Standard Contractual Clauses</Bold>” means (i) the EU Controller-to-Processor Clauses, or (ii) the UK Controller-to-Processor Clauses, as may be amended or replaced from time to time by a competent authority under the relevant Data Protection Laws;
                </BodyRg>
                <BodyRg>
                1.12	"<Bold>Sub-processor</Bold>" means any third-party engaged by or on behalf of the Data Processor to Process Personal Data on behalf of the Data Controller in connection with the Agreement. 
                </BodyRg>
                <BodyRg>
                1.13	"<Bold>Supervisory Authority</Bold>" means any competent regulatory authority responsible for monitoring the application of the Data Privacy Laws, including but not limited to the UK Information Commissioner's Office.
                </BodyRg>
                <BodyRg>
                1.14	“<Bold>Third Country</Bold>” – means a country outside of the European Economic Area (“<Bold>EEA</Bold>”) which is not recognized under Data Privacy Laws of the European Union or United Kingdom as providing an adequate level of protection for personal data.
                </BodyRg>
                <BodyRg>
                1.15	"<Bold>UK Controller-to-Processor Clauses</Bold>" means the standard contractual clauses (processors) set out in Decision 2010/87/EC as amended or replaced from time to time, pursuant to Article 46 of the UK GDPR.
                </BodyRg>
                <BodyRg>
                    <Bold>2. Purpose </Bold>
                </BodyRg>
                <BodyRg>
                2.1	Both parties will comply with all requirements of Data Privacy Laws applicable to their role as a Data Processor or Data Controller, as applicable. This DPA defines the principles and procedures that the parties shall adhere to and the responsibilities the parties owe to each other.
                </BodyRg>
                <BodyRg>
                2.2	Each party agrees to appoint a single point of contact (“<Bold>Representative</Bold>”) as soon as this DPA has been entered into and provide the other party with the contact details of its Representative as soon as he/she has been appointed. The parties shall procure that the Representatives work together to reach an agreement with regard to any issues arising from the data sharing described in this DPA and to actively monitor the effectiveness of the data sharing;
                </BodyRg>
                <BodyRg>
                    <Bold>3. Company Personal Data</Bold>
                </BodyRg>
                <BodyRg>
                3.1	The parties acknowledge that for the purposes of Data Privacy Laws and this DPA, the Company is the Data Controller and Vendor is the Data Processor in respect of Company Personal Data.
                </BodyRg>
                <BodyRg>
                3.2	Company will ensure that it is lawful to enable the transfer of Company Personal Data to Vendor for the duration and for the purposes of the Agreement. 
                </BodyRg>
                <BodyRg>
                3.3	Without prejudice to the generality of clause 3.1, Vendor shall, in relation to Company Personal Data Processed in connection with the performance by Vendor of its rights and obligations under the Agreement: 
                </BodyRg>
                <OrderedList>
                    <OrderedListItem>
                        <BodyRg>
                        only Process Company Personal Data in order to provide the Services, and shall act only in accordance with: (i) this DPA, (ii) Company's written instructions, and (iii) Data Privacy Laws, and Vendor is prohibited from retaining, using or disclosing Company Personal Data for any purpose other than providing the Services, unless required to do so by applicable Data Privacy Laws, in which case the Vendor shall inform Company of   such   legal   requirement   before   processing,   unless   such disclosure is prohibited by law;
                        </BodyRg>
                    </OrderedListItem>
                    <OrderedListItem>
                        <BodyRg>
                        in cases where pseudonymous, tokenized or encrypted data is used, make no attempt to re-identify the data or link data with other data sources, pseudonymous profiles, or identifiable persons in any way.
                        </BodyRg>
                    </OrderedListItem>
                    <OrderedListItem>
                        <BodyRg>
                        as soon as reasonably practicable upon becoming aware, inform Company if, in Vendor’s opinion, any instructions provided by Company under clause 3.3(a) violates Data Privacy Laws;
                        </BodyRg>
                    </OrderedListItem>
                    <OrderedListItem>
                        <BodyRg>
                        implement appropriate technical and organizational measures to ensure a level of security appropriate to the risks that are presented by the Processing of Company Personal Data, in particular protection against accidental or unlawful destruction, loss, alteration, unauthorised disclosure of, or access to Company Personal Data. Such measures include, without limitation, the security measures set out in Schedule 2;
                        </BodyRg>
                    </OrderedListItem>
                    <OrderedListItem>
                        <BodyRg>
                        take reasonable steps to ensure that only authorized personnel have access to such Company Personal Data and that any persons whom it authorizes to have access to the Company Personal Data are under obligations of confidentiality;
                        </BodyRg>
                    </OrderedListItem>
                    <OrderedListItem>
                        <BodyRg>
                        other than to the extent required to comply with applicable law, as soon as reasonably practicable following termination or expiry of the Agreement or completion of the Services, delete all Company Personal Data (including copies thereof) Processed pursuant to this DPA;
                        </BodyRg>
                    </OrderedListItem>
                    <OrderedListItem>
                        <BodyRg>
                        provide at no extra cost to Company such reasonable assistance to Company as Company reasonably requests in relation to Company’s obligations under Data Privacy Laws in each case solely in relation to Processing of Company Personal Data by the Vendor (as Data Processor) on behalf of Agency (as Data Controller) and taking into account the nature of the Processing and information available to Vendor with respect to:
                        </BodyRg>
                    </OrderedListItem>
                    <RomanList>
                        <OrderedListItem>
                            <BodyRg>
                                data protection impact assessments (as such term is defined in Data Privacy Laws);
                            </BodyRg>
                        </OrderedListItem>
                        <OrderedListItem>
                            <BodyRg>
                                notifications to the Supervisory Authority under Data Privacy Laws and/or communications to Data Subjects in response to any Security Breach; and
                            </BodyRg>
                        </OrderedListItem>
                        <OrderedListItem>
                            <BodyRg>
                                Company's compliance with its respective obligations under the Data Privacy Laws regarding the security of Processing.
                            </BodyRg>
                        </OrderedListItem>
                    </RomanList>
                </OrderedList>
                <BodyRg>
                3.4	Company hereby consents to the Vendor appointing Sub-processors in connection with the provision of the Services. Vendor shall make available on its website or directly to Company a list of current Sub-processors and shall notify Company when a Sub-processor is replaced or added to this list. Upon notification, Company shall have 30 days to object to the appointment of the new Sub-processor. If Company objects on reasonable grounds, Company shall have the right to terminate the Agreement immediately.
                </BodyRg>
                <BodyRg>
                3.5	The Vendor confirms that it has entered or (as the case may be) will enter with any appointed Sub-processor into a written agreement incorporating terms which are substantially similar to those set out in this DPA, and require such Sub-processors to adhere to the same data protection obligations as those applicable to Vendor under this DPA and Data Privacy Laws. As between Company and Vendor, Vendor shall remain fully liable for all acts or omissions of any Sub-processor appointed by it.
                </BodyRg>
                <BodyRg>
                    <Bold>4.	Processing Personal Data</Bold>
                </BodyRg>
                <BodyRg>
                4.1	Each party undertakes that it will comply with Data Privacy Laws at all times during the term of the Agreement, which will include:
                </BodyRg>
                <OrderedList>
                    <OrderedListItem>
                        <BodyRg>
                        Processing Personal Data in compliance with Data Privacy Laws; 
                        </BodyRg>
                    </OrderedListItem>
                    <OrderedListItem>
                        <BodyRg>
                        where applicable, maintaining valid and up to date registrations or notifications required to Process Personal Data; and
                        </BodyRg>
                    </OrderedListItem>
                </OrderedList>
                <BodyRg>
                4.2	Each party shall maintain records of all Processing activities under its responsibility and shall make a copy of the records available to the Supervisory Authority upon request. 
                </BodyRg>
                <BodyRg>
                4.3	Each party shall have in place appropriate technical and organisational measures to ensure a level of security appropriate to the risks that are presented by the Processing of Personal Data, in particular protection against accidental or unlawful destruction, loss, alteration, unauthorised disclosure of, or access to Personal Data transmitted, stored or otherwise Processed pursuant to this DPA, including where appropriate data protection by default and/or by design measures.
                </BodyRg>
                <BodyRg>
                4.4	If applicable, a party that is not established in the European Union and is Processing Personal Data of data subjects who are in the European Union shall, where required by Data Privacy Laws, designate in writing a representative in the European Union.
                </BodyRg>
                <BodyRg>
                4.5	A party and its data processors (or Sub-processors) shall not, without the prior written consent of the other party, transfer any Personal Data from a country or territory within either the United Kingdom or the European Economic Area to a country or territory outside the United Kingdom or European Economic Area unless adequate contractual or other assurances have first been put in place such as will enable each party to comply with the requirements of Data Privacy Laws.
                </BodyRg>
                <BodyRg>
                <Bold>5.	Application of Standard Contractual Clauses</Bold>
                </BodyRg>
                <BodyRg>
                5.1	To the extent any Processing of Company Personal Data requires the transfer of Company Personal Data of Data Subjects who are in the EEA or UK to outside the EEA or UK except if in an Adequate Country (a “<Bold>Data Transfer</Bold>”), the parties agree that the applicable Standard Contractual Clauses approved by the EU or UK authorities under Data Privacy Laws will apply in respect of that Processing, and are incorporated into this DPA by reference.
                </BodyRg>
                <OrderedList>
                    <OrderedListItem>
                        <BodyRg>
                            Where the Data Transfer concerns the Personal Data of Data Subjects who are in the EEA, Vendor hereby enters into Module 2 of the EU Controller-to-Processor Clauses (as Processor) with the Company (as Controller). Module 2 of the EU Controller-to-Processor Clauses is incorporated into this DPA by reference and takes effect from the commencement of an applicable Data transfer. The Parties make the following selections for the purposes of Module 2:
                        </BodyRg>
                    </OrderedListItem>
                    <RomanList>
                        <OrderedListItem>
                            <BodyRg>
                                Clause 7 – Docking clause shall not apply;
                            </BodyRg>
                        </OrderedListItem>
                        <OrderedListItem>
                            <BodyRg>
                                Clause 9 – Use of subprocessors: “Option 2” shall apply, Client agrees to the list of subprocessors located at www.realchemistry.com/service-providers, and the time period shall be a minimum of 30 days;
                            </BodyRg>
                        </OrderedListItem>
                        <OrderedListItem>
                            <BodyRg>
                                Clause 11(a) – Redress the optional language shall not apply;
                            </BodyRg>
                        </OrderedListItem>
                        <OrderedListItem>
                            <BodyRg>
                                Clause 13(a) – Supervision -The supervisory authority with responsibility for ensuring compliance by the data exporter with Regulation (EU) 2016/679 as regards the Data transfer, The Information Commissioner’s Office (“ICO”, United Kingdom), shall act as competent supervisory authority.
                            </BodyRg>
                        </OrderedListItem>
                        <OrderedListItem>
                            <BodyRg>
                                Governing law Option 1 shall apply and the “Member State” shall be the United Kingdom.
                            </BodyRg>
                        </OrderedListItem>
                        <OrderedListItem>
                            <BodyRg>
                                Clause 18 – Choice of forum and jurisdiction the Member State shall be the United Kingdom.
                            </BodyRg>
                        </OrderedListItem>
                        <OrderedListItem>
                            <BodyRg>
                                Annex 1 – shall be deemed pre-populated with the relevant sections of Vendor Data Processing Annex 1 to this DPA;
                            </BodyRg>
                        </OrderedListItem>
                        <OrderedListItem>
                            <BodyRg>
                                Annex 2 – shall be deemed pre-populated with the relevant sections of Vendor Data Processing Annex 2 to this DPA; and
                            </BodyRg>
                        </OrderedListItem>
                        <OrderedListItem>
                            <BodyRg>
                                Annex 3 – shall be deemed pre-populated with the relevant sections of Vendor Data Processing Annex 3 to this DPA.
                            </BodyRg>
                        </OrderedListItem>
                    </RomanList>
                    <OrderedListItem>
                        <BodyRg>
                            Where the Data Transfer concerns the Company Personal Data of Data Subjects who are in the United Kingdom, Vendor hereby enters into the UK Controller-to-Processor Clauses  (as Processor)  with the Company (as Controller). The UK Controller-to-Processor Clauses are incorporated into this DPA by reference and take effect from the commencement of an applicable Data transfer. Appendix 1 to the UK Controller-to-Processor Clauses shall be deemed to be pre-populated with the relevant sections of Vendor Data Processing Annex 1 to this DPA. Appendix 2 to the UK Controller-to-Processor Clauses shall be deemed to be pre-populated with the relevant sections of Vendor Data Processing Annex 2 to this DPA. If at any time the UK Government approves the EU Controller-to-Processor Clauses for use under the UK Data Protection Laws, the provisions of Clause 5.1(i) shall apply in place of this clause 5.1(ii) in respect Data Transfers concerning the Personal Data of Data Subjects who are in the United Kingdom, subject to any modifications to the EU Controller-to-Processor Clauses required by the UK Data Protection Laws (and subject to the governing law of the EU Controller-to-Processor Clauses being English law and the supervisory authority being the Information Commissioner’s Office).
                        </BodyRg>
                    </OrderedListItem>
                </OrderedList>
                <BodyRg>
                5.2	The Standard Contractual Clauses will not apply to a Data Transfer if an alternative recognized compliance standard for lawful Data Transfers has been adopted between the parties.
                </BodyRg>
                <BodyRg>
                <Bold>6.	Data Subject Requests</Bold>
                </BodyRg>
                <BodyRg>
                6.1	Vendor agrees to provide all necessary assistance to Company to enable Company to comply with any Data Subject Requests (whether in relation to access to Personal Data, rectification, restrictions on Processing, erasure or portability) and to respond to any other queries or complaints from data subjects in accordance with Data Privacy Laws.
                </BodyRg>
                <BodyRg>
                6.2	Vendor hereby agrees to promptly (and in any event within two working days of receipt) notify Company if it receives a Data Subject Request in relation to Company Personal Data. Vendor shall not respond to a Data Subject Request without Company’s prior written consent except to confirm that such request relates to Company Personal Data, to which Company hereby agrees.  Upon Company’s request Vendor shall at no extra charge to Company provide reasonable assistance to Company to facilitate Company responding to a Data Subject Request within the deadlines set out under Data Privacy Laws; 
                </BodyRg>
                <BodyRg>
                6.3	Each Representative is responsible for maintaining a record of Data Subject Requests received by each party, the decisions made and any information that was exchanged. Records must include copies of the request for information, details of the data accessed and shared and where relevant, notes of any meeting, correspondence or phone calls relating to the Data Subject Request. 
                </BodyRg>
                <BodyRg>
                <Bold>7.	Sub-processors</Bold>
                </BodyRg>
                <BodyRg>
                7.1	Where Vendor is to engage a sub-contractor to Process Company Personal Data on its behalf as a Data Processor (or Sub-processor) that Vendor must comply with, and ensure that such sub-contractor also complies with, Data Privacy Laws. Vendor shall be fully liable for the acts and/or omissions of its sub-contractors (including Data Processors and/or Sub-processors) as though they were Vendor’s acts or omissions.
                </BodyRg>
                <BodyRg>
                7.2	Vendor shall only use Data Processors (or Sub-processors) providing sufficient guarantees to implement appropriate technical and organisational measures in such a manner that the processing will meet the requirements of Data Privacy Laws and ensure the protection of the rights of the data subjects.
                </BodyRg>
                <BodyRg>
                7.3	Vendor shall have in place procedures so that any third party it authorises to access Company Personal data, including Data Processors (or Sub-processors), will respect and maintain the confidentiality of Company Personal Data. 
                </BodyRg>
                <BodyRg>
                    <Bold>8. Security Breaches and reporting procedures</Bold>
                </BodyRg>
                <BodyRg>
                8.1	Vendor shall:
                </BodyRg>
                <OrderedList>
                    <OrderedListItem>
                        <BodyRg>
                        as soon as reasonably practicable upon becoming aware, and in any event within one working day notify Company of any actual or suspected Security Breach by Vendor, any of Vendor's Sub-processors, or any other identified or unidentified third party;                        
                        </BodyRg>
                    </OrderedListItem>
                    <OrderedListItem>
                        <BodyRg>
                        promptly provide Company with reasonable cooperation and assistance in respect of a Security Breach and all reasonable information in Vendor’s possession concerning such Security Breach insofar as it affects Company, including the following to the extent then known:                        
                        </BodyRg>
                    </OrderedListItem>
                    <RomanList>
                        <OrderedListItem>
                            <BodyRg>
                            the possible cause and consequences for the Data Subjects of the Security Breach;
                            </BodyRg>
                        </OrderedListItem>
                        <OrderedListItem>
                            <BodyRg>
                            the categories of Personal Data involved;
                            </BodyRg>
                        </OrderedListItem>
                        <OrderedListItem>
                            <BodyRg>
                            a summary of the possible consequences for the relevant data subjects;
                            </BodyRg>
                        </OrderedListItem>
                        <OrderedListItem>
                            <BodyRg>
                            a summary of the unauthorised recipients of the Personal Data; and
                            </BodyRg>
                        </OrderedListItem>
                        <OrderedListItem>
                            <BodyRg>
                            (v)	the measures taken by Vendor to mitigate any damage;
                            </BodyRg>
                        </OrderedListItem>
                    </RomanList>
                    <OrderedListItem>
                        <BodyRg>
                        not make any public announcement about a Security Breach (a “<Bold>Breach Notice</Bold>”) without the prior written consent of Company, unless required by applicable law;                        
                        </BodyRg>
                    </OrderedListItem>
                </OrderedList>
                <BodyRg>
                8.2	Vendor shall put in place its own internal guidance and policies that must comply with Data Privacy Laws and must be followed in the event of a Security Breach. 
                </BodyRg>
                <BodyRg>
                8.3	Vendor agrees to provide reasonable assistance as is necessary to Company to facilitate the handling of any Security Breach in an expeditious and compliant manner, which shall include notifying:
                </BodyRg>
                <OrderedList>
                    <OrderedListItem>
                        <BodyRg>
                        the relevant Supervisory Authority promptly and in any event no later than 72 hours after becoming aware of it, where required by Data Privacy Laws; and                        
                        </BodyRg>
                    </OrderedListItem>
                    <OrderedListItem>
                        <BodyRg>
                        the relevant data subjects without undue delay, where required by Data Privacy Laws.
                        </BodyRg>
                    </OrderedListItem>
                </OrderedList>
                <BodyRg>
                8.4	Vendor shall document any Security Breach comprising the facts relating to the Security Breach, its effects and the remedial action taken. Vendor acknowledges that Supervisory Authorities can request a copy of that document to verify compliance with the Vendor’s reporting obligations under Data Privacy Laws.
                </BodyRg>
                <BodyRg>
                <Bold>9.	Return or Destruction of Personal Data</Bold>
                </BodyRg>
                <BodyRg>
                9.1	Other than to the extent required to comply with applicable law, as soon as reasonably practicable following termination or expiry of the Main Agreement or completion of the Services, or upon Company’s request, Vendor will delete all Company Personal Data (including copies thereof) Processed pursuant to this DPA and the Main Agreement;
                </BodyRg>
                <BodyRg>
                    <Bold>10.	Resolution of disputes with Data Subjects or the Supervisory Authority</Bold>
                </BodyRg>
                <BodyRg>
                10.1	Vendor agrees to provide reasonable assistance as is necessary to the Company to respond within a reasonable time to any enquiries from the Supervisory Authorities in relation to their handling of Company Personal Data.
                </BodyRg>
                <BodyRg>
                10.2	In the event of a dispute or claim brought by a Data Subject or a Supervisory Authority concerning the Processing of Personal Data against either or both parties, the parties will inform each other about any such disputes or claims, and will cooperate with a view to settling them amicably in a timely fashion.
                </BodyRg>
                <BodyRg>
                    <Bold>
                    11.	Audit and Assessment
                    </Bold>
                </BodyRg>
                <BodyRg>
                11.1	Company, upon providing Vendor 30 days’ written notice, may audit Vendor's systems and activities in relation to the Processing of Company Personal Data covered by this DPA. The Vendor shall provide Company with all reasonable information and assistance in connection with any such audit at no additional cost to Company.
                </BodyRg>
                <BodyRg>
                11.2	Vendor will maintain a continuous assessment program to monitor risk and ensure safeguards are functioning as designed to protect the confidentiality, availability, integrity, and privacy of applicable protected data. 
                </BodyRg>
                <BodyRg>
                11.3	If either party discovers that Vendor safeguards contain a vulnerability, Vendor will promptly correct or mitigate at Vendor’s cost any vulnerability within a reasonable period. If unable to correct or mitigate the vulnerabilities within the industry defined best practices timelines, Vendor will notify Company and propose reasonable remedies.
                </BodyRg>
                <BodyRg>
                    <Bold>
                        12.	Safeguards
                    </Bold>
                </BodyRg>
                <BodyRg>
                12.1	Vendor will maintain reasonable technical, administrative, and physical controls for personnel and systems that may store, Process, transfer or access Company’s protected data and/or systems, in compliance with this agreement and applicable laws and regulations. These include but are not limited to the follow: 
                </BodyRg>


                <OrderedList>
                    <OrderedListItem>
                        <BodyRg>
                        Physical Controls:
                        </BodyRg>
                    </OrderedListItem>
                    <RomanList>
                        <OrderedListItem>
                            <BodyRg>
                            Vendor will maintain layered physical controls designed to secure applicable facilities perimeter and interior areas, control individual access, suitable locks with key management, logging of accesses (granted and denied), intruder and environmental alarms and response procedures, and environmental protections. 
                            </BodyRg>
                        </OrderedListItem>
                    </RomanList>
                    <OrderedListItem>
                        <BodyRg>
                        Technical Controls:
                        </BodyRg>
                    </OrderedListItem>
                    <RomanList>
                        <OrderedListItem>
                            <BodyRg>
                            Establish, document, and enforce access control policies, procedures, and measures to make certain only individuals who require access to Personal Data to perform their duties are granted access
                            </BodyRg>
                        </OrderedListItem>
                        <OrderedListItem>
                            <BodyRg>
                            Multi-factor authentication is required to access Personal Data
                            </BodyRg>
                        </OrderedListItem>
                        <OrderedListItem>
                            <BodyRg>
                            Maintain reasonable and up to date anti-malware/anti-virus, anti-spam, and like controls on networks, systems, and devices
                            </BodyRg>
                        </OrderedListItem>
                        <OrderedListItem>
                            <BodyRg>
                            Using a reasonable encryption standard, vendor will encrypt all protected data at rest in vendor systems and devices; and encrypt data in transit at any point where said data is transferred across a network that is not solely owned and managed by vendor
                            </BodyRg>
                        </OrderedListItem>
                        <OrderedListItem>
                            <BodyRg>
                            Maintain controls and processes to ensure that all device operating system and software patches are applied in accordance with the timeframe recommended/required by the issuer of the patch 
                            </BodyRg>
                        </OrderedListItem>
                        <OrderedListItem>
                            <BodyRg>
                            Implement reasonable account management processes and procedures to ensure the secure and timely creation, amendment of, and deprovisioning of user and system accounts that access protected data and/or systems that store, Process, or transfer protected data. To include reasonable logging, monitoring, and alerting of account activities 
                            </BodyRg>
                        </OrderedListItem>
                        <OrderedListItem>
                            <BodyRg>
                            Maintain an incident response program to classify and track security incidents, to include specified timelines required for notification of Company to an incident involving Company’s data and/or systems
                            </BodyRg>
                        </OrderedListItem>
                    </RomanList>
                    <OrderedListItem>
                        <BodyRg>
                        Administrative Controls:
                        </BodyRg>
                    </OrderedListItem>
                    <RomanList>
                        <OrderedListItem>
                            <BodyRg>
                            Vendor will maintain policies and procedures to define acceptable use of and restrict access to protected data, including appropriate use and protection guidelines, confidentiality agreements, and applicable background checks for all personnel that access protected data and/or systems that store, Process, or transfer protected data or connect to Company systems. 
                            </BodyRg>
                        </OrderedListItem>
                        <OrderedListItem>
                            <BodyRg>
                            Vendor will maintain an ongoing training program that includes security awareness and privacy trainings
                            </BodyRg>
                        </OrderedListItem>
                        <OrderedListItem>
                            <BodyRg>
                            Vendor will track acknowledgement of policies and progress of trainings in a reasonable manner
                            </BodyRg>
                        </OrderedListItem>
                    </RomanList>
                </OrderedList>




                <BodyRg>
                <Bold>13.	Liability</Bold>
                </BodyRg>
                <BodyRg>
                13.1	If a claim is brought directly against Company for damages suffered in relation to Vendor’s breach of its obligations under this DPA and/or Data Privacy Laws, Vendor will indemnify Company for any loss, cost, charge, damages or expenses arising from such a claim. 
                </BodyRg>
                <BodyRg>
                13.2	Nothing in this DPA shall limit or exclude either of the party's liability for fraud, fraudulent misrepresentation, negligence, wilful default or any other conduct that may not be excluded by law.
                </BodyRg>
                <BodyRg>
                <Bold>14.	Law and jurisdiction</Bold>
                </BodyRg>
                <BodyRg>
                This DPA shall be governed by and construed in accordance with (a) New York Law if Vendor is a United States Company, or (b) English law if Vendor is a non-United States Company, and each of the parties agrees to submit to the non-exclusive jurisdiction of the courts located in New York, New York, and London, England, respectively for any claim or matter arising under this DPA.
                </BodyRg>
                <BodyRg>
                The applicable annexes as executed by the parties are hereby incorporated by reference and made part of this DPA.
                </BodyRg>
            </GlobalVendorDpaContainer>
        </GlobalVendorDpaPageWrap>
    );
}

export default GlobalVendorDpaPage;
