import React, {useState} from 'react'
import PlusIconSvg from "../../assets/icons/PlusIconSvg";
// import RichText from "./RichText";
import { TitleXl } from 'components/elements';
import { DarkAccordionBlock, DaHeader, DaBody } from './elements';
import TwoColCopyMediaSection from 'components/TwoColCopyImageSection';
import ArticlesContainer from 'components/ArticlesContainer';



function CareerAccordion({data}) {
    const [acc, setAcc] = useState(false);

    const handleAcc = () => {
        setAcc(!acc);
    }

    



    return (
        <DarkAccordionBlock className={acc ? 'show' : ''}>
            <DaHeader onClick={handleAcc}>
                <TitleXl fontWeight="900">{data?.title}</TitleXl>
                <PlusIconSvg className={'plus-icon'}/>
            </DaHeader>
            <DaBody>
                {data?.items?.map((colData, index) => (
                    colData.ctype === 'twoColumnCopyImage' ? (
                        <TwoColCopyMediaSection data={colData} rowMargin={index % 2 === 0 ? '5% 0' : undefined } colMargin={colData.title ? '10% 0 0 ' : '0 0'} alignItems={colData.columnVideo ? 'center' : undefined} mobileColumn={colData.columnVideo || colData.columnCarousel || data.title === 'Thanking our lucky stars'  ? 'column' : 'column-reverse'}/>
                    ) : <ArticlesContainer articles={colData} />          
                ))}            
            </DaBody>
        </DarkAccordionBlock>
    )
}

export default CareerAccordion;
