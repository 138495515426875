
import React, { useEffect, useState } from 'react'
import { CardSelectorRow, CardSelectorWrapper } from './elements'
import { Col4, Col6, Row, TitleContainer, TitleXl } from 'components/elements'
import SmallCard from './SmallCard'
import DisplayCard from './DisplayCard'

function CardSelector(props) {
	const [cards, setCards] = useState([])
	const [title, setTitle] = useState([])
	const [currentCard, setCurrentCard] = useState({})

	useEffect(() => {
		setCards(props.data.cards)
		setTitle(props.data.title)

		if (cards?.length > 0)
			setCurrentCard(cards[0])
	}, [props.data, cards, title])

	const setNewPreview = (data) => {
		setCurrentCard({
			...data,
			image:''
		})
		setTimeout(()=>{
			setCurrentCard({
				...data,
				image:data?.image
			})
			document.getElementById('scroller-leadership').scrollTop = 0
		},150)

	}

	return (
		<CardSelectorWrapper>

			<TitleContainer>
				<TitleXl>{title}</TitleXl>
			</TitleContainer>

			<CardSelectorRow>
				<Row>
					<Col6 className="card-selector-left">
						<Row>
							{
								cards?.map((card, index) => {
									return (
										<Col4 key={index} onClick={() => { setNewPreview(card) }}>
											<SmallCard data={card} />
										</Col4>
									)
								})
							}
						</Row>
					</Col6>
					<Col6 className="stretch">
						<div id={"scroller-leadership"} style={{overflow: "auto",maxHeight: "870px"}}>
							<DisplayCard data={currentCard} />
						</div>
					</Col6>
				</Row>
			</CardSelectorRow>
		</CardSelectorWrapper>
	)
}

export default CardSelector;
