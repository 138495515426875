import React from 'react';
import { BodySm, TitleXl, TitleSm } from 'components/elements';
import { ContactUsHeader, TitleSmContainer, TitleXlContainer, BodySmContainer } from './elements';
import { withRouter } from 'react-router-dom';
import styled from 'styled-components';



function ChangeHealthcare({ data }) {
  return (
    <ContactUsHeader >
      <TitleSmContainer>
        <TitleSm>
          Contact Us
        </TitleSm>
      </TitleSmContainer>
      <TitleXlContainer>
        <TitleXl>
          {data?.elements?.[0]?.text?.content[0]?.content[0]?.value || ''}<span className="magenta">.</span>
        </TitleXl>
      </TitleXlContainer>
      <BodySmContainer>
        <BodySm>
          {data?.elements?.[1]?.text?.content[0]?.content[0]?.value || ''}
        </BodySm>
      </BodySmContainer>
    </ContactUsHeader>
  );
}

export default withRouter(ChangeHealthcare);
