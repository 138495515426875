import styled from 'styled-components';
import {
    TitleXl,
    Row, 
    Col4,
    Col8
} from "components/elements";
import {
    sizeMobileLg,
    sizeDesktopSm,
    sizeMobileMd,
    sizeLaptopMd,
    sizeLaptop,
    sizeTabletLg,
    sizeTablet,
    sizeDesktopLg,
    sizeDesktopMd,
    sizeMobileXs,
    sizeWideScreen,
    sizeXlDevice,
    sizeLaptopLg
} from "components/devices";

export const AboutUsTopSectionContainer = styled.div`
        width: 100%;
        height: 100%;
        padding: 6% 9%;

    ${sizeDesktopSm (`
        padding: 6% 4.5%;
    `)}  
    ${sizeMobileLg (`
        overflow-x: hidden;
    `)}
   
`

export const FlexContainer = styled.div`
    width: 100%;
    height: 100%;
    min-height: 500px;
    margin-left: -80px;
    ${sizeDesktopSm (`
        min-height: 650px;
        margin-left: 0;
    `)}
    ${sizeMobileLg(`
        min-height: 600px;
    `)}
    ${Row} {
        margin: 7% 0 0;
        width: 100%;
        height: 100%;
        flex-dirction: row;   
        justify-content: center;
        align-items: center;
        ${sizeLaptopMd  (`
            flex-direction: column-reverse;  
        `)}
    }

    ${Col8} {
     text-align: center;

     img {
        width: 575px;
        max-width: 100%;
     }
     ${sizeLaptop(`
        padding: 40px 0 0;
     `)}
     ${sizeTablet(`
        width: 100%;
        max-width: 100%;
        margin-top: 30px;
        img {
            width: 100%;
        }
    `)}
    }


${Col4} {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    ${sizeLaptopMd(`
        left: 0;
        width: 100%;
        max-width: 75%;
        align-self: flex-start;
        justify-content: center;
        height: 20%;
    `)}
    ${sizeTablet(`
        max-width: 100%;
    `)}
    ${sizeMobileLg (`
        max-width: 100%;
    `)}

h3 {
    font-size: 36px;
    line-height: 43px;
    padding: 0 0 15px;
    ${sizeMobileLg (`
        font-size: 20px;
        line-height: 24px;
        padding: 0 0 15px;
    `)}
}

p {
    font-size: 20px;
    line-height: 24px;
    padding: 15px 0 10px;
    ${sizeMobileLg (`
        font-size: 16px;
        line-height: 19px;
    `)}
}

`


export const TitleXlContainer = styled.div`
    width: ${({width}) => width || "65%"};
    ${sizeXlDevice(`
        max-width: 60%;
    `)}
    ${sizeDesktopLg (`
        max-width: 60%;
    `)}
    ${sizeDesktopMd(`
        max-width: 80%;
    `)}
    ${sizeTabletLg (`
        width: 100%;
        max-width: 100%;
    `)}

    ${TitleXl} {
        font-size: 48px;
        line-height: 57px;
        font-weight: 900;
        ${sizeMobileLg (`
            font-size: 36px;
            line-height: 43px;
        `)}
    }
`

export const PurpleCircleContainer = styled.div`
    z-index: -99;
    margin: 0 auto;
    position: relative;
    ${sizeXlDevice(`
    `)}
`
export const MagentaCircleContainer = styled.div`
    position: relative;
    left: 120px;
    top: 160px;
    ${sizeWideScreen(`
       left: 27%;
    `)}
    ${sizeXlDevice(`
        left: 25%;
    `)}

    ${sizeDesktopLg(`
        left: 20%;
    `)}
    ${sizeDesktopMd(`
        left: 15%;
    `)} 
    ${sizeLaptopMd(`
       left: 90px
    `)}
    ${sizeMobileLg (`
        left: 100px;
        top: 150px;
    `)}
    ${sizeMobileMd (`
      left: 120px;

    `)}

`
export const BlueCircleContainer = styled.div`
    position: relative;
    right: 120px;
    top: 160px;
    ${sizeWideScreen(`
      right: 27%;
    `)}
    ${sizeXlDevice(`
       right: 25%;
    `)}

    ${sizeDesktopLg(`
        right: 20%;
    `)} 
    ${sizeDesktopMd(`
        right: 15%;
    `)} 
    ${sizeLaptopMd(`
        right: 90px;
    `)}
    ${sizeMobileLg (`
        top: 150px;
        right: 100px;
    `)}
    ${sizeMobileMd (`
        right: 115px;
    `)}
    ${sizeMobileXs(`
       right: 125px;
    `)}
`

export const LogoContainer = styled.div`
    position: absolute;
    right: 230px;
    top: 225px;
    ${sizeWideScreen(`
      right: 205px;
    `)}
    
    ${sizeXlDevice(`
        right: 215px;
    `)}
    ${sizeDesktopLg(`
        right: 200px;
    `)} 
    ${sizeDesktopMd(`
        right: 62%;
    `)} 
    ${sizeDesktopSm(`
        right: 200px;
        top: 230px;
    `)}
    ${sizeLaptopLg(`
       right: 170px;
    `)}
    ${sizeLaptopMd(`
        right: 220px;
    `)}
    ${sizeLaptop (`
        right: 220px;
    `)}
    ${sizeTabletLg (`
        right: 210px;
    `)}
    
    ${sizeMobileLg (`
        right: 125px;
        top: 190px
    `)}
    ${sizeMobileMd (`
        right: 110px;

    `)}

    .realChemLogo {
        width: 292px;
        height: 44px;

        ${sizeMobileLg (`
            width: 210px;
            height: 32px;
        `)}
    }


`