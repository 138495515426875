import styled from "styled-components";
import {
    TitleXl,
    BodyRg
 
} from "components/elements";
import {
    sizeLaptopLg,
    sizeLaptopMd,
    sizeMobileLg,
    sizeTablet,
    sizeTabletLg,


} from "components/devices";


export const LocationTabsContainer = styled.div`
     padding: 7% 0 0;
${TitleXl}{
    font-size: 48px;
    line-height: 1.2;
    padding: 0 7% 4%;
    ${sizeMobileLg(`
        font-size: 36px;
        padding: 0 15px 4%;
    `)}
}



.slick-slider {
  padding-top: 120px;
  ${sizeTablet(`
      padding-top: 100px;
  `)}
}
.slick-slide {
  height: 700px;
 
  &.slick-active.slick-current {
    height: auto;
  }
}

.slick-dots li button:before {
  line-height: 1 !important;
}

.slick-dots {
  top: 0;
  bottom: auto;
  margin-bottom: 40px;
  li {
    width: 100%;
    max-width: 350px;
    height: auto;
    ${sizeLaptopLg(`
      max-width: 320px;
    `)}
    ${sizeLaptopMd(`
      max-width: 275px;
    `)}

    ${sizeTabletLg(`
      max-width: 33.3333%;
      margin: 0;
    `)}
    button {
      width: 100%;
      height: 80px;
      background: transparent;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      justify-content: center;
      border: none;
      cursor: pointer;
      font-size: 36px;
      line-height: 1.2;
      text-transform: uppercase;
      font-weight: 400;
      color: #000;
      position: relative;
      z-index: 1;
      overflow: hidden;
      padding: 17px 20px;
      border-bottom: 4px solid transparent;
      ${sizeLaptopMd(`
        height: 110px;
     `)}


      ${sizeTablet(`
      flex-direction: row !important;
      padding: 0 0 !important;
      height: 80px;
      &:before { 
          font-size: 20px !important;
          line-height: 24px !important;        
          height: 38px !important;
      }
     `)}

      &:after {
        content: none !important;
      }
      &:before {
        content: "";
        width: 100%;
        height: 100%;
        opacity: 1;
        font-size: inherit;
        font-family: inherit;
        position: relative;
        top: 10px;
        left: 0;
      }
    }
    &.slick-active {
      button {
        border-color:#D12FFF;
        color: #D12FFF;
        &:before {
          color: #D12FFF;

        }
      }
    }
    &:first-child {
      button {
        &:before {
          content: 'United States';
        }
      }
    }
    &:nth-child(2) {
      button {
        &:before {
          content: 'Europe';
        }
      }
    }
    &:nth-child(3) {
      button {
        &:before {
          content: 'Canada';
        }
      }
    }
  }
}




`

export const SliderContainer = styled.div`
  max-width: 100vw;
  width: 100%;
  overflow: hidden;
  padding: 0 7%;
  ${sizeMobileLg(`
    padding: 0 15px;
  `)}

`

export const GridSectionWrap = styled.div`
 position: relative;

`;

export const InnerContainer = styled.div``

export const GridRow = styled.div`
display: -webkit-box;
display: -ms-flexbox;
display: flex;
-ms-flex-wrap: wrap;
flex-wrap: wrap;


  
`;

export const GridCol = styled.div`
  width: 33.3333;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 33.3333%;
  flex: 0 0 33.3333%;
  max-width: 33.333%;
  ${sizeMobileLg(`
  width: 50%;
  -ms-flex: 0 0 50%;
  flex: 0 0 50%;
  max-width: 50%;
  `)}
 
`;

export const LocationCardWrap = styled.div`
  display: flex;
  justify-content: center;
  padding: 9% 0;
`

export const LocationInnerWrap = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    width: 88%;
    margin: 0 auto;
  ${TitleXl}{
    font-size: 24px;
    line-height: 1.2;
    font-weight: 900 !important;
    padding: 0 0 10px;

    ${sizeTablet(`
      font-size: 18px;
    `)}
  }


      a{
        color: #D12FFF !important;
        cursor: pointer;
        ${BodyRg}{
          font-size: 24px;
          line-height: 1.2;
          color: #D12FFF !important;
          ${sizeTablet(`
            font-size: 16px;
    
        `)}
      }
}
`