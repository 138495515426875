import styled from "styled-components";

import { SectionTitle, BodyRg } from "components/elements";
import { sizeTabletLg, sizeWideScreen } from "components/devices";

// CSS Start
export const PrivacyInfoWrap = styled.div`
  padding-bottom: 100px;
  ${sizeTabletLg(`
        padding-bottom: 30px;
    `)}
  ${SectionTitle} {
    margin-bottom: 65px;
    padding: 0;
    ${sizeTabletLg(`
            text-align: left !important;
            margin-bottom: 45px;
        `)}
  }
  ${BodyRg} {
    font-size: 24px;
    margin-bottom: 25px;
    ${sizeTabletLg(`
            font-size: 16px;
        `)}

        ${sizeWideScreen(`
          font-size: 0.85vw;
        `)}
  }
`;
export const PvInfoContainer = styled.div`
  max-width: 915px;
  margin: 0 auto;
  padding: 0 25px;
  
  ${sizeWideScreen(`
    max-width: 100%;
    padding: 0 35px;
  `)}
`;
export const PrivacyLink = styled.a`
  line-height: 1.3;
  color: #D12FFF;
  border-bottom: 1.3px solid #D12FFF;
  word-break: break-word;
  &.external {
    color: #000;
    word-break: break-all;
  }
`;
