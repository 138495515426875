import React, { useRef } from 'react';
import { playArray } from "../../constants/index";


import {
  TitleXs
} from "components/elements";

import {
  PlayMarqueeWrap,
  Marquee,
  MarqueeRow,
  MarqueeCol,
  PlayArrow,
} from "./elements";

function PlayMarquee({ controlLoaded, heroClickHandler, opacity, marqueeText }) {
  const marqueColRef = useRef(null);

  return (
    <PlayMarqueeWrap opacity={opacity}>
      {!controlLoaded && <Marquee onClick={heroClickHandler} direction="right">
        <MarqueeRow className={"marquee-row one"}>
          {playArray.map((item, index) => {
            return <MarqueeCol ref={marqueColRef} key={index} className="infinite-marquee">

              <PlayArrow xmlns="http://www.w3.org/2000/svg" width="49.222" height="25.383" viewBox="0 0 49.222 25.383">
                <g id="S_Arrow" data-name="S Arrow" transform="translate(0 0.692)">
                  <path id="Path_1" data-name="Path 1" d="M995.5-213.02l11.489,12-11.489,12" transform="translate(-958.487 213.02)" fill="none" stroke="#fff" strokeLinejoin="bevel" strokeMiterlimit="10" strokeWidth="2" />
                  <line id="Line_18" data-name="Line 18" x2="48" transform="translate(0 11.75)" fill="none" stroke="#fff" strokeWidth="2" />
                </g>
              </PlayArrow>
              <TitleXs color="#fff" textTransform="uppercase">{marqueeText ? marqueeText : "Play"}</TitleXs>
            </MarqueeCol>
          })}
        </MarqueeRow>
        <MarqueeRow className={"marquee-row two"}>
          {playArray.map((item, index) => {
            return <MarqueeCol ref={marqueColRef} key={index} className="infinite-marquee">
              <PlayArrow xmlns="http://www.w3.org/2000/svg" width="49.222" height="25.383" viewBox="0 0 49.222 25.383">
                <g id="S_Arrow" data-name="S Arrow" transform="translate(0 0.692)">
                  <path id="Path_1" data-name="Path 1" d="M995.5-213.02l11.489,12-11.489,12" transform="translate(-958.487 213.02)" fill="none" stroke="#fff" strokeLinejoin="bevel" strokeMiterlimit="10" strokeWidth="2" />
                  <line id="Line_18" data-name="Line 18" x2="48" transform="translate(0 11.75)" fill="none" stroke="#fff" strokeWidth="2" />
                </g>
              </PlayArrow>
              <TitleXs color="#fff" textTransform="uppercase">{marqueeText ? marqueeText : "Play"}</TitleXs>
            </MarqueeCol>
          })}
        </MarqueeRow>
        <MarqueeRow className={"marquee-row three"}>
          {playArray.map((item, index) => {
            return <MarqueeCol ref={marqueColRef} key={index} className="infinite-marquee">
              <PlayArrow xmlns="http://www.w3.org/2000/svg" width="49.222" height="25.383" viewBox="0 0 49.222 25.383">
                <g id="S_Arrow" data-name="S Arrow" transform="translate(0 0.692)">
                  <path id="Path_1" data-name="Path 1" d="M995.5-213.02l11.489,12-11.489,12" transform="translate(-958.487 213.02)" fill="none" stroke="#fff" strokeLinejoin="bevel" strokeMiterlimit="10" strokeWidth="2" />
                  <line id="Line_18" data-name="Line 18" x2="48" transform="translate(0 11.75)" fill="none" stroke="#fff" strokeWidth="2" />
                </g>
              </PlayArrow>
              <TitleXs color="#fff" textTransform="uppercase">{marqueeText ? marqueeText : "Play"}</TitleXs>
            </MarqueeCol>
          })}
        </MarqueeRow>
      </Marquee>}
    </PlayMarqueeWrap>
  );
}

export default PlayMarquee;
