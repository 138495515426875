import React from 'react';
import {withRouter} from "react-router-dom"
import {TitleXl} from "components/elements";
import { Container, ThumbnailContainer, Thumbnail, CopyContainer, Overlay } from './elements';
import ViewAll from 'components/ViewAll';

function ArticlesContainer({articles}) {
 

    return(
        <Container>
            <TitleXl fontWeight={'900'} fontSize={"24px"}>
                {articles.title}
            </TitleXl>
            <ThumbnailContainer>
                {articles?.articles.map((a, key) => (
                    <Thumbnail key={key} flex={ '1 0 30%' } backgroundImage={a.image}>
                        <Overlay>
                        <CopyContainer>
                            <TitleXl fontWeight="900" fontSize="24px">
                                {a.title}
                            </TitleXl>
                        </CopyContainer>
                        <ViewAll title={'VIEW ARTICLE'} link={a.detailLink}  borderColor="#fff" titleColor="#fff" hoverBackground="#fff" svgColor="#fff" width="215px" height="38px" />
                       </Overlay>
                    </Thumbnail>
                ))}
        
            </ThumbnailContainer>
            
        </Container>
        )
    }
    
    export default withRouter(ArticlesContainer);