import React from 'react';
import {withRouter} from "react-router-dom";
import {BodySm, TitleXl, TitleSm} from "components/elements";
import { HowWeWorkContainer, HowWeWorkTopContainer, TitleXlContainer, BodySmContainer, LogoContainer,  TitleSmBodySmContainer, HealthCareExperienceContainer, TitleContainer, BottomCircleContainer, BottomLeftCircleContainer, BottomRightCircleContainer, CtaContainer } from './elements';
import OverlapCircles from 'components/OverlapCircles';
import ViewAll from "components/ViewAll";

function getValueByIndex(section, index) {
    return section?.elements?.[index]?.text?.content?.[0]?.content?.[0]?.value || '';
  }
  
function HowWeWorkSectoin ({firstSectionData, secondSectionData}) {

    //mapping elements
    const firstSection = {
        title: getValueByIndex(firstSectionData, 0),
        purpleText: getValueByIndex(firstSectionData, 1),
        body: getValueByIndex(firstSectionData, 2),
        bold: getValueByIndex(firstSectionData, 3),
        logo: firstSectionData?.elements?.[4]?.imageAsset,
        image: firstSectionData?.elements?.[5]?.imageAsset
    }

    const secondSection = {
        title: getValueByIndex(secondSectionData, 0),
        largeBody: getValueByIndex(secondSectionData, 1),
        body: getValueByIndex(secondSectionData, 2),
        leftCircle:{
            header: getValueByIndex(secondSectionData?.elements?.[3], 0),
            largeBody: getValueByIndex(secondSectionData?.elements?.[3], 1),
            body: getValueByIndex(secondSectionData?.elements?.[3], 2),
        },
        rightCircle:{
            header: getValueByIndex(secondSectionData?.elements?.[4], 0),
            largeBody: getValueByIndex(secondSectionData?.elements?.[4], 1),
            body: getValueByIndex(secondSectionData?.elements?.[4], 2),
        }
    }


    return(
      <HowWeWorkContainer>
        <HowWeWorkTopContainer>
            <TitleXlContainer>
                <TitleXl color="#000" fontWeight="900">{firstSection.title}</TitleXl>
            </TitleXlContainer>
            <BodySmContainer>
                <TitleSm >{firstSection.purpleText}</TitleSm>
                <BodySm>{firstSection.body}</BodySm>
            </BodySmContainer>
       </HowWeWorkTopContainer>
       <LogoContainer>
            <TitleXl fontWeight="900" textTransform={"uppercase"}>{firstSection.bold}</TitleXl>
            <img src={firstSection.logo} alt={'Real Chemistry logo'} className="realChemLogo"/>
       </LogoContainer>
       <div class="six-bu-container">
         <img src={firstSection.image} alt="" className="sixBuImage" />
       </div>
       <HealthCareExperienceContainer>
            <TitleContainer>
                <TitleXl color="#000">{secondSection.title}</TitleXl>
            </TitleContainer>
            <TitleSmBodySmContainer>
                <TitleSm>{secondSection.largeBody}</TitleSm>
                <BodySm fontSize="20px">{secondSection.body}</BodySm>
            </TitleSmBodySmContainer>
       </HealthCareExperienceContainer>
       <BottomCircleContainer>
            <BottomLeftCircleContainer>
                <OverlapCircles borderColor="#A036F5" dimensionsDesktop="463px" dimensionsMobile="360px" header="false" mobileBorder={"8px solid #A036F5"} copyHeader={secondSection?.leftCircle?.header} subHead={secondSection.leftCircle?.largeBody} bodyCopy={secondSection.leftCircle?.body}/>
            </BottomLeftCircleContainer>
            <BottomRightCircleContainer>
                <OverlapCircles borderColor="#A036F5" dimensionsDesktop="463px" dimensionsMobile="360px" header="false"  mobileBorder={"8px solid #A036F5"} copyHeader={secondSection?.rightCircle?.header} subHead={secondSection.rightCircle?.largeBody} bodyCopy={secondSection.rightCircle?.body}/>
            </BottomRightCircleContainer>
       </BottomCircleContainer>
       <CtaContainer>
            <ViewAll link="/contact" title={"GET IN TOUCH"} borderColor="#000" borderColorHover="#fff" titleColor="#000" hoverBackground="#000" width="214px" height="44px" svgColor="#000"  />
       </CtaContainer>

    </HowWeWorkContainer>
    )
}

export default withRouter(HowWeWorkSectoin);
