import React from 'react';
import { withRouter } from "react-router-dom"
import { BodySm, TitleXl, TitleSm, Row, Col6 } from "components/elements";
import { BackgroundWrapper, AboutUsBottomContainer, TitleXlContainer, TitleSmContainer, BodySmContainer, ColWrapper, RowContainer, NumbersContainer } from './elements';
import { dataObject } from './data';

function AvantNumbersSection({ data }) {


  return (
    <BackgroundWrapper>
      <AboutUsBottomContainer>
        <RowContainer>
          <Row>
            {data?.elements?.map((number, index) => (
              <Col6 key={index}>
                <ColWrapper>
                  <NumbersContainer>
                    <TitleSm>
                      {index + 1}
                    </TitleSm>
                  </NumbersContainer>
                  <TitleSmContainer>
                    <TitleSm>
                      {number?.name}
                    </TitleSm>
                  </TitleSmContainer>
                  <BodySmContainer>
                    <BodySm>
                      {number?.text?.content[0].content[0].value}
                    </BodySm>
                  </BodySmContainer>
                </ColWrapper>
              </Col6>
            ))}
          </Row>
        </RowContainer>
      </AboutUsBottomContainer>
    </BackgroundWrapper>
  )
}

export default withRouter(AvantNumbersSection);
