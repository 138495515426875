import React, { useEffect } from "react";
import { MediaWrap } from "./elements";
import { Row, Col12, Col4 } from "../../../components/elements";
import MediaCard from "../../../components/MediaCard";
import BlogIcon from "../../../assets/icons/BlogIcon";
import LoadMore from "../../../components/LoadMore";
import EmptySearch from "../../../components/SearchEmpty";



function MediaSection({ id, featuredEvents, allArticles, isLoading, searchTerm, filteredItem }) {

  const PAGE_SIZE = 15
  const [rows, setRows] = React.useState([])
  const [list, setList] = React.useState([])
  const [remainingArticles, setRemainingArticles] = React.useState([]);

  const [addMoreViz, setAddMoreViz] = React.useState(true)

  React.useEffect(() => {
    setRemainingArticles(allArticles.slice(6))
  }, [allArticles])

  React.useEffect(() => {
    if (remainingArticles) {
      setRows(remainingArticles)
      setList(remainingArticles.slice(0, 3))
    }
    if (list.length >= rows.length) {
      setAddMoreViz(false)
    } else {
      setAddMoreViz(true)
    }
    // eslint-disable-next-line
  }, [rows, remainingArticles])

  const handleOnAddMoreBtnClick = (e) => {
    setList(rows.slice(0, list.length + PAGE_SIZE))
  }

  useEffect(() => {
    if (list.length >= rows.length)
      setAddMoreViz(false)
    // eslint-disable-next-line 
  }, [list])


  return (
    <MediaWrap id={id}>
      {!searchTerm || filteredItem !== 'all' ?
        <Row>
          <Col12 className={'mediaCol'}>
            {
              featuredEvents?.map((item, i) => {

                return (
                  <React.Fragment key={i}>
                    <MediaCard
                      isLoading={isLoading}
                      padding="0 0 115px"
                      flex="0 0 45%"
                      cardType={item.type}
                      link={item.detailLink}
                      icon={<BlogIcon />}
                      mediaPadMobile={'0px 20px 0'}
                      btnText={item.label}
                      ctaText={'read more'}
                      date={item.date}
                      img={item.image}
                      bodyText={item.title}
                      borderTop={'none'}
                      height={'500px'}
                    />
                  </React.Fragment>
                )
              })
            }
          </Col12>


          <Col12 className={'mediaCol'}>
            <Row>
              {
                allArticles.slice(0, 6)?.map((item, i) => {
                  return (
                    <Col4 key={i} className={'mediaCol'}>
                      <MediaCard
                        flexDirection="column"
                        padding="0 12px 115px"
                        flex="0 0 45%"
                        mediaCardHeadPad={"0 0 20px"}
                        mediaBodyPad={"25px 0"}
                        isLoading={isLoading}
                        cardType={item.type}
                        link={item.detailLink}
                        icon={<BlogIcon />}
                        btnText={item.label}
                        ctaText={'read more'}
                        date={item.date}
                        img={item.image}
                        bodyText={item.title}
                        borderLeft={'none'}
                        borderTop={'none'}
                      />
                    </Col4>
                  )
                })
              }
            </Row>
          </Col12>

          {
            list?.map((item, i) => {
              return (
                <Col4 key={i} className={'mediaCol'}>
                  <MediaCard
                    flexDirection="column"
                    padding="0 12px 115px"
                    flex="0 0 45%"
                    mediaCardHeadPad={"0 0 20px"}
                    mediaBodyPad={"25px 0"}
                    tabletPad={"0 0"}
                    isLoading={isLoading}
                    cardType={item.type}
                    link={item.detailLink}
                    icon={<BlogIcon />}
                    btnText={item.label}
                    ctaText={'read more'}
                    date={item.date}
                    img={item.image}
                    bodyText={item.title}
                    borderLeft={'none'}
                    borderTop={'none'}
                  />
                </Col4>
              )
            })
          }

        </Row>

        :

        <Row>
          {
            allArticles.length > 0 ? allArticles?.map((item, i) => {
              return (
                <Col4 key={i} className={'mediaCol'}>
                  <MediaCard
                    flexDirection="column"
                    padding="0 20px 115px"
                    mediaCardHeadPad={"0 0 20px"}
                    mediaBodyPad={"25px 0"}

                    isLoading={isLoading}
                    cardType={item.type}
                    link={item.detailLink}
                    icon={<BlogIcon />}
                    btnText={item.label}
                    ctaText={'read more'}
                    date={item.date}
                    img={item.image}
                    bodyText={item.title}

                  />
                </Col4>
              )
            })
              : <EmptySearch />
          }

        </Row>
      }

      {
        addMoreViz ?
          <LoadMore
            onClick={handleOnAddMoreBtnClick}
            text={'Load more'}
            list={list?.length}
            borderTop={'none'}
            borderBottom={'none'}
            totalLength={allArticles?.length}
          /> : ""
      }
    </MediaWrap>
  )
}

export default MediaSection

