import { Link } from "react-router-dom";
import styled, { css } from 'styled-components';
import {
    BodyRg,
    TitleXs, 
    BodySm
} from "components/elements";
import {
    sizeTabletLg,
    sizeLaptopLg,
    sizeWideScreen,
    sizeMobileLg
} from "components/devices";

export const JobDetailPageWrap = styled.div`
    padding: 0;
    position: relative;
    ${sizeLaptopLg(`
        padding: 0;
    `)}

    ${sizeWideScreen(`
        padding-top: 70px;
    `)}

    .jobs-hero-wrap {
        position: relative;
        width: 100vw;
        height: auto;
        // max-height: 1000;
        background-image: url(/images/jobs-header.svg);
        background-size: cover;
        // animation: 2s ease 0s normal none running zoomin;
        // @keyframes zoomin {
        //     0% { transform: scale(1.3, 1.7) }
        //     100% { transform: scale(1.0, 1.0) }
        // }
        // @-webkit-keyframes zoom{
        //     0% { transform: scale(1.3, 1.7) }
        //     100% { transform: scale(1.0, 1.0) }
        // }
        // @-moz-keyframes zoom{
        //     0% { transform: scale(1.3, 1.7) }
        //     100% { transform: scale(1.0, 1.0) }
        // }
        .back {
            ${sizeMobileLg(`
                    padding: 10px 0 0;
                `)}
            svg {
                line,path {
                    stroke: #fff;
                }
                           
            }
            h5 {
                color: #fff;
                font-size: 24px;
                line-height: 29px;
    
                ${sizeMobileLg(`
                    font-size: 20px;
                    line-height: 24px;
                `)}
            }
        }
        > div {
            p, h2 {
                color: #fff;
            }
        }
    }
`;

export const ViewAllContainer = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    padding: 0 0 40px;
`
export const JobDetail = styled.div`
    ${BodyRg} {
        margin-bottom: 30px;
        &:last-child {
            margin-bottom: 50px;
        }
    }
`;
export const JobDetailContiner = styled.div`
    padding: 50px 25px 0 25px;
    max-width: 900px;
    margin: 0 auto;
    p{
        margin: 0 0 30px 0;
        font-size: 16px;
        line-height: 19px;
    }
    i {
        b {
            font-size: 24px;
            line-height: 28px;
            font-weight: 400;
            font-style: normal;
        }
    }
    a {
        color: #D12FFF;
        text-decoration: none !important;
    }
  
    ul{
        list-style-type: disc;
        margin-left: 45px;
        font-size: 16px;
        line-height: 19px;
        p{
            margin-bottom: 20px;
        }
        li{
            font-size: 16px;
            line-height: 19px;
        }
    }
    ${sizeWideScreen(css`
        max-width: 100%;
        padding: 05%;
        width: 100%;
        box-sizing: border-box;
        p, ul, li {
            font-size: 0.7vw !important;
        }
        ul {
            li {
                p {
                    font-size: 0.7;
                }
            }
        }
    `)}
    
    
`;

export const ButtonRow = styled.div`
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
`;

export const ApplyBtn = styled.a`
    width: 100%;
    border-top: ${({ borderTop }) => borderTop || "1px solid #707070"};
    border-right: ${({ borderRight }) => borderRight || "1px solid #707070"};
    border-bottom: ${({ borderBottom }) => borderBottom || "1px solid #707070"};
    border-left: ${({ borderLeft }) => borderLeft || "1px solid #707070"};
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    height: 130px;
    -webkit-transition: .4s all ease;
    transition: .4s all ease;
    box-sizing: border-box;
    ${sizeMobileLg(`
        height: 170px;
        padding: 25px;
        box-sizing: border-box;
    `)}
    svg {
        width: 52px;
        transition: .4s all ease;
        position: relative;
        right: -15px;
        transform: rotate(180deg);
        ${sizeMobileLg(`
            right: 0px;
        `)}
    }
    ${TitleXs} {
        ${sizeMobileLg(`
            font-size: 42px;
            margin-right: 15px;
        `)}
    }
    &:hover {
        line, path {
            stroke: #D12FFF;
        }
        ${TitleXs} {
            color: #D12FFF;
        }
        svg {
            right: -25px;
        }
    }

        
`;
export const PageBtn = styled(Link)`
    font-size: 18px;
    font-weight: 400;
    color: #000000;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 50px 15px;
    text-transform: uppercase;
    width: 100%;
    box-sizing: border-box;
    transition: .4s all ease;
    &:hover {
        color: #D12FFF;
    }
`;

export const BladeContainer = styled.div`
    padding: 25px;
    max-width: 850px;
    margin: 20px auto;
    border: 1px solid #D12FFF;
    ${TitleXs}{
        font-size: 24px;
        line-height: 27px;
    }
    ${BodySm}{
        font-size: 16px;
        line-height: 19px;
    }
    ${sizeTabletLg(`
        max-width: 95%;
        padding: 30px 15px;
    `)}
`;

export const BladeButton = styled.a`
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-transition: .4s all ease;
    transition: .4s all ease;
    box-sizing: border-box;

    ${sizeMobileLg(`
        height: 200px;
        box-sizing: border-box;
        flex-direction: column;
        align-items: flex-start;

    `)}
    svg {
        width: 52px;
        transition: .4s all ease;
        position: relative;
        right: -20px;
        transform: rotate(180deg);
        ${sizeMobileLg(`
            right: 0px;
            width: 70px;
        `)}
    }
    ${TitleXs} {
        font-size: 32px;
        line-height: 38px;
        font-weight: 400;
        color: #000;
        display: inline-block;
        width: 80%;
        ${sizeMobileLg(`
            width: 100%;
        `)}

    }
    &:hover {
        svg {
            right: -25px;
        }
    }

        
`;