import React, {useCallback} from 'react';
import { Container, HeaderPill, RichTextContainer, CTAContainer } from './elements';
import { BodySm, TitleSm } from 'components/elements';
import {withRouter} from "react-router-dom"
import RichText from 'components/RichText';


function ProductCards({pillText, backgroundColor, title, richTextBody, subText, ctaText, openDemoModal, onDemand, id}){

    const openReportPage = useCallback(() => {
        window.open('https://shop.realchemistry.com/collections/iris-market-view-reports', '_blank')
    },[])

    return(
        <Container backgroundColor={backgroundColor}>
            <HeaderPill>
                {pillText}
            </HeaderPill>
            <TitleSm>
                {title}
            </TitleSm>
            <RichTextContainer>
                <RichText body={richTextBody && richTextBody}/>
            </RichTextContainer>
            <CTAContainer>
                <BodySm>
                    {subText}
                </BodySm>
                {!onDemand && (
                    <button type="button" onClick={openDemoModal}>                  
                           {ctaText}               
                    </button>
                )}
                {onDemand && (
                      <button type="button" onClick={openReportPage}>                  
                            GET A REPORT       
                      </button>
                )}
            </CTAContainer>
        </Container>
    )
}

export default withRouter(ProductCards);
 