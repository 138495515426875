import styled from 'styled-components';

import {
    sizeLaptopMd,
    sizeTabletLg
} from "components/devices";

export const BlogSliderWrap = styled.div`
    margin: 20px 0 20px 0;
    overflow: hidden;
    width: 100%;
`;

export const BlogSlide = styled.div`

`;
export const SlideImage = styled.img`
    width: calc(100% - 15px);
    object-fit: cover;
    height: 650px;
    ${sizeLaptopMd(`
        height: 500px;
    `)}
    ${sizeTabletLg(`
        height: 380px;
    `)}
`;