import React from 'react';
import styled from 'styled-components';

import {
    Label
} from "components/elements";

// Select options Style

export const SelectField = styled.select`
    width: auto;
    border: none;
    padding: 0;
    color:  ${(props) => props.isSelected ? '#000000' : '#999999'};
    font-size:  ${(props) => props.isSelected ? '22px' : '20px'};
    line-height: ${(props) => props.isSelected ? '44px' : '34px'};
    padding-right: 50px;
    background-color: transparent;
    opacity: ${(props) => props.isSelected ? '1' : '0.73'};
    outline: none;
    box-shadow: none;
    display: inline-block;
    padding-left: ${(props) => props.isSelected ? '0' : '15px'} !important;
    outline: none !important;
    box-shadow: none !important;
    background-image: url(${({ isFocus, backgroundImage, rotateIcon }) => isFocus ? backgroundImage : rotateIcon}) !important;
    background-repeat: no-repeat;
    background-size: 40px 40px;
    background-position: right center;
    -webkit-appearance: none;
    max-width: 430px;
    font-family: ${({ fontFamily }) => fontFamily || "HelveticaNowDisplay"};
    &:focus,
    &:active {
        outline: none !important;
        box-shadow: none !important;
    }
    & + ${Label} {
        color: #FF0000;
        font-size: 16px; 
        line-height: 19px;
        position: relative;
        margin-bottom: 0;
        display: block;
        text-align: left;
        top: 15px;
    }

`;

export const Option = styled.option`

`;

export const Select = ({ value, options, backgroundImage, rotateIcon, focus, change, required, click, ...rest }) => (
    <SelectField required={required} isFocus={focus} onClick={click} onChange={(e) => { change(e) }} rotateIcon={rotateIcon} backgroundImage={backgroundImage} {...rest}>
        {options.map((item, index) => {
            return <Option key={index} value={item.value} >
                        {item.label}
                    </Option>
        })}
    </SelectField>
);

export default Select;
