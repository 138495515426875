import React from 'react'

import {
	TitleXs,
	WideScreenContainer, BackArrowLink, BodySm, 
} from 'components/elements'


import {
	JobDetailPageWrap,
	JobDetailContiner,
	ButtonRow, BladeContainer, BladeButton, ViewAllContainer
} from './elements'
import ViewAll from 'components/ViewAll'

import {r3cJobDetail} from 'constants/index'

import JobDetailCard from 'components/JobDetailCard'
import FooterAnimation from 'components/FooterAnimation'
import {useHistory} from "react-router-dom";
import LoadingHoc from "../../hoc/LoadingHOC";
import BackArrowSvg from "../../assets/icons/BackArrowSvg";


function JobDetailPage(props) {
	const {setLoading} = props;
	const history = useHistory();
	const [job, setJob] = React.useState([])

	React.useEffect(() => {
		window.scrollTo(0, 0)

		let id = null

		if (props?.jobData)
			setJob(props.jobData)
		else {
			let a = props.location.pathname.split('/')
			if (a && a.length > 2) {
				id = a[2]
				fetch(r3cJobDetail + '?id=' + id).then((response) => response.json()).then((pgData) => {
					pgData.city = pgData.primaryLocation.split(",")[0]
					setJob(pgData)
					setLoading(false)
				})
					.catch(err => {
						setLoading(false)
						history.push('/404')
					})
			}
		}
		// eslint-disable-next-line
	}, [props?.jobData])

	return (
		<JobDetailPageWrap className="pageWrapper">
			<WideScreenContainer
				borderLeft="1px solid #707070"
				borderRight="1px solid #707070"
				borderTop="1px solid #707070"
			>
				
				<div className="jobs-hero-wrap">
					<BackArrowLink  className="cta arrow back" to={"/job-category"}>
						<BackArrowSvg/>
						<TitleXs textTransform={"uppercase"} margin={"0 0 0 15px"}>Back to All Job Opportunities</TitleXs>
					</BackArrowLink>
					<JobDetailCard
						capability={job.department}
						location={job.primaryLocation}
						locations={job.locations}
						jobTitle={job.title}
						date={job.date}
						link={job.ctaLink}
						target="_blank"
					/>
				</div>

				<JobDetailContiner dangerouslySetInnerHTML={{__html: job.detail}}>

				</JobDetailContiner>
				<ButtonRow>				
						<ViewAllContainer>
							<ViewAll              
									title="APPLY NOW"
									textTransform="uppercase"
									borderTop="none"
									borderColor="#000"
									titleColor="#000"
									hoverBackground="#000"
									svgColor="#000"
									width="200px"
									borderColorHover="#fff"
									height="38px"
									link={job.ctaLink}
									target={"_blank"}
									rel="noopener noreferrer"
									className="btn-apply-now"
								/>
						</ViewAllContainer>	
					</ButtonRow>
				{job.bucketDescription &&
					<BladeContainer>
						<TitleXs>A Bit About the {job.bucket} Team</TitleXs>
						<BodySm style={{padding:"25px 0 25px 0"}}>{job.bucketDescription}</BodySm>
						<BladeButton
							borderLeft="none"
							borderRight="none"
							href={'/job-category/c:' + job.bucket} rel="noopener noreferrer"
						>
							<TitleXs color="#D12FFF" textTransform="uppercase" style={{display: 'flex', alignItems: 'center'}}><span>see all {job.bucket} opportunities</span></TitleXs>
							<svg xmlns="http://www.w3.org/2000/svg" width="86" height="47.114" viewBox="0 0 86 47.114">
								<g id="M_Arrow" data-name="M Arrow" transform="translate(86 45.7) rotate(180)">
									<line id="Line_18" data-name="Line 18" x2="83.905" transform="translate(0 21.967)"
										  fill="none" stroke="#D12FFF" strokeWidth="4"/>
									<path id="Path_592" data-name="Path 592" d="M0,0,22.143,22.143,0,44.286"
										  transform="translate(61.857)"
										  fill="none" stroke="#D12FFF" strokeLinejoin="round" strokeWidth="4"/>
								</g>
							</svg>
						</BladeButton>
					</BladeContainer>
				}

				{job.primaryLocationDescription &&
					<BladeContainer>
						<TitleXs>A Bit About the {job.city} Office</TitleXs>
						<BodySm style={{padding:"25px 0 25px 0"}}>{job.primaryLocationDescription}</BodySm>
						<BladeButton
							borderLeft="none"
							borderRight="none"
							href={'/job-category/l:' + job.primaryLocation} target="_blank" rel="noopener noreferrer"
						>
							<TitleXs color="#000" textTransform="uppercase" style={{display: 'flex', alignItems: 'center'}}><span>see all opportunities in {job.city}</span></TitleXs>
							<svg xmlns="http://www.w3.org/2000/svg" width="86" height="47.114" viewBox="0 0 86 47.114">
								<g id="M_Arrow" data-name="M Arrow" transform="translate(86 45.7) rotate(180)">
									<line id="Line_18" data-name="Line 18" x2="83.905" transform="translate(0 21.967)"
										  fill="none" stroke="#D12FFF" strokeWidth="4"/>
									<path id="Path_592" data-name="Path 592" d="M0,0,22.143,22.143,0,44.286"
										  transform="translate(61.857)"
										  fill="none" stroke="#D12FFF" strokeLinejoin="round" strokeWidth="4"/>
								</g>
							</svg>
						</BladeButton>
					</BladeContainer>
				}


			</WideScreenContainer>

			<FooterAnimation
				svgDesktop="/images/purple-footer.svg"
				svgMobile="/images/purple-footer-mbl.svg"
				borderTop="none"
			/>
		</JobDetailPageWrap>
	)
}

export default LoadingHoc(JobDetailPage)