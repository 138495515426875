import React  from 'react'
import styled from 'styled-components'
import { useAppContext } from 'store';
import {useHistory} from "react-router-dom";




// Media Button
export const MediaBtn = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #000;
  background-color: transparent;
  padding: 8px 12px;
  text-transform: uppercase;
  cursor: pointer;
  border-radius: 0;
  color: #000;
  transition: 0.3s all ease;
  font-weight: 500;
  font-size: 16px;
  line-height: 1.2;
  font-family: 'HelveticaNowDisplay',serif;

  svg {
    width: 32px;
    margin-right: 7px;

    path, rect {
      fill: #000;
    }
  }
  &.active {
    color: #fff !important;
    background-color: #d12fff !important;
    border-color: #000 !important;

    svg {
      path, rect {
        fill: #fff !important;
      }
    }
  }

  &:hover {
    color: #D12FFF;
    border-color: #0F20FF;
    background-color: transparent;

    svg {
      path, rect {
        fill: #D12FFF;
      }
    }
  }
`;


function MediaLink({id, icon, text, status, filterTerm, link}) {
  const { setActiveMediaFilter } = useAppContext();
  // const history = useHistory();
  const history = useHistory({forceRefresh:true});
  


  const handleFilterChange = (param) => {
    setActiveMediaFilter(param)
    history.push(link)  
  };


    return (
        <MediaBtn onClick={() => handleFilterChange(filterTerm)} id={id} className={status + " article type"}>
            {icon}
            {text}
        </MediaBtn>
    )
}

export default MediaLink;
