import React, {useState} from 'react';
import LoadingHoc from '../../hoc/LoadingHOC';
import {ArticlesPageWrap} from './elements';
import { WideScreenContainer } from 'components/elements';
import FooterAnimation from 'components/FooterAnimation';
import { Helmet } from 'react-helmet';
import {r3cBase} from 'constants/index';
// import {findContent, getImageDimensions} from 'generalHelpers';
import PagedList from 'components/PagedList';
import SubscribeCard from 'components/SubscribeCard'
// import { fetchEntries } from '../../cf/r3c/cf';

import {
    BlogSubscribe,
    SubscibeClose,
} from './elements'


function AllArticles(props) {
    const {setLoading} = props;
    const [subscribe, setSubscribe] = useState(false)
    const [topNewsData, setTopNewsData] = React.useState([])
    // eslint-disable-next-line
    const [seoData, setSeoData] = React.useState({})
    const [mobileView, setMobileView] = useState(false)
    const [count, setCount] = useState(0)

    const sortByDate = (arr) => {
        const sortedArr =  arr.sort(function(a, b) {
            if (new Date(a.date) < new Date(b.date)) return 1;
            if (new Date(a.date) > new Date(b.date)) return -1;
            return 0;
        })

        return sortedArr
    }
    
    React.useEffect(() => {
        fetch(r3cBase + '/all-articles').then((response) => response.json()).then((page) => {
         
            // let seo = page.seo;
            //
            // let image = page?.seo?.image?.split('.')
            //
            // page?.seo?.image ? getImageDimensions(page?.seo?.image, function (width, height) {
            //     setSeoData({
            //         ...seo,
            //         mimeType: image[image.length - 1],
            //         seoImageHeight: height,
            //         seoImageWidth: width
            //     })
            //
            // }) : setSeoData(seo)
            let obj = {};
            obj.items = sortByDate(page);
            setTopNewsData(obj)
            setMobileView((window.innerWidth < 993))
            setLoading(false)
        })
        // eslint-disable-next-line
    }, [])

    setTimeout(()=>{
        let x = sessionStorage.getItem('check')
        if (x !== 'showFalse' && subscribe === false && count === 0)
        setSubscribe(true)
    },4000)

    const onSubscribeCross = () => {
        console.log(">>>>>>>>> all articles >>>>>>>>>")
        setSubscribe(false)
        setCount(1)
        sessionStorage.setItem('check', 'showFalse')
    }

    return (
        <ArticlesPageWrap className="pageWrapper">
            <Helmet>
                <title>{seoData.title}</title>
                <link rel="canonical" href={window.location.pathname}/>
                <meta name="description" content={seoData.description}/>
                <meta name="og:description" content={seoData.description}/>
                <meta name="og:title" content={seoData.title}/>
                <meta name="og:url" content={window.location.protocol + "//" + window.location.host + window.location.pathname}/>
                <meta name="og:type" content="website"/>
                <meta name="og:site_name" content="Real Chemistry"/>

                <meta name="og:image" content={window.location.protocol + seoData.image}/>
                <meta name="og:image:type" content={"image/" + seoData?.mimeType}/>
                <meta name="og:image:width" content={seoData?.seoImageWidth}/>
                <meta name="og:image:height" content={seoData?.seoImageHeight}/>
            </Helmet>
            <WideScreenContainer>

            <PagedList
                fromInsights={true}
                title={'All Articles'}
                icon={'/images/eye-icon.svg'}
                iconClassName="wide"
                rows={topNewsData.items}
                borderTop="none"
                borderWideScreen="none"
                listBorder="1px solid #707070"
                id="news"
                no={10}
            />

             {/* Popup Subscribe */}
             <BlogSubscribe
                            className={
                                !mobileView ? (subscribe ? "showSubcribe" : "") : "showSubscribe"
                            }
                        >
                            <SubscibeClose onClick={() => onSubscribeCross()}>
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    xlink="http://www.w3.org/1999/xlink"
                                    width="84"
                                    height="84"
                                    viewBox="0 0 84 84"
                                >
                                    <defs>
                                        <clipPath id="clip-path">
                                            <rect
                                                id="Rectangle_1707"
                                                data-name="Rectangle 1707"
                                                width="84"
                                                height="84"
                                                fill="none"
                                                stroke="#000"
                                                strokeWidth="4"
                                            />
                                        </clipPath>
                                    </defs>
                                    <g
                                        id="Group_1731"
                                        data-name="Group 1731"
                                        clipPath="url(#clip-path)"
                                    >
                                        <path
                                            id="Path_822"
                                            data-name="Path 822"
                                            d="M46.855,85.21h0A38.355,38.355,0,0,1,8.5,46.855h0a38.355,38.355,0,0,1,76.71,0h0A38.355,38.355,0,0,1,46.855,85.21Z"
                                            transform="translate(-4.855 -4.855)"
                                            fill="none"
                                            stroke="#000"
                                            strokeLinejoin="bevel"
                                            strokeWidth="4"
                                        />
                                        <line
                                            id="Line_454"
                                            data-name="Line 454"
                                            x2="27.443"
                                            y2="27.443"
                                            transform="translate(28.279 28.279)"
                                            fill="none"
                                            stroke="#000"
                                            strokeLinejoin="bevel"
                                            strokeWidth="4"
                                        />
                                        <line
                                            id="Line_455"
                                            data-name="Line 455"
                                            x1="27.443"
                                            y2="27.443"
                                            transform="translate(28.278 28.279)"
                                            fill="none"
                                            stroke="#000"
                                            strokeLinejoin="bevel"
                                            strokeWidth="4"
                                        />
                                    </g>
                                </svg>
                            </SubscibeClose>
                            <SubscribeCard from="all-articles" />
                        </BlogSubscribe>

            </WideScreenContainer>
            <FooterAnimation
				svgDesktop="/images/purple-footer.svg"
				svgMobile="/images/purple-footer-mbl.svg"
				borderTop="none"
			/>
        </ArticlesPageWrap>
    )
}

export default LoadingHoc(AllArticles);

