import React, { useState, useEffect, useRef } from "react";
import InputRange from "react-input-range";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { Draggable } from "gsap/all";
import { InertiaPlugin } from "../../assets/gsap-club/InertiaPlugin";

import {
  InputSliderWrapper,
  SliderContainer,
  SlideInnerWrap,
  DragButton,
  DragIcon,
  Desktop
} from "./elements";


gsap.registerPlugin(ScrollTrigger);
gsap.registerPlugin(Draggable);
gsap.registerPlugin(InertiaPlugin);
let arrowKeyTracker = 0;

function Slider(props) {
  const draggedElementRef = useRef(null);
  const inputSliderRef = useRef(null);
  const draggedContainerRef = useRef(null);
  const dragabbleButtonRef = useRef(null);
  const [inputSlider, setInputSlider] = useState(0);
  const [showDragButton, setShowDragButton] = useState(false);
  const [dragButtonMouseDown, setDragButtonMouseDown] = useState(false);
  let windowWidth = window.innerWidth;
  const windowHeight = window.innerHeight;

  let waiting = false;
  function updateInput(currX, limit) {
    if (!waiting) {
      waiting = true;
      setTimeout(function () {
        waiting = false;
        let scrollPercentage = 100 * -currX / (draggedElementRef?.current?.scrollWidth - windowWidth);
        scrollPercentage = scrollPercentage > 100 || scrollPercentage < 0 ? (scrollPercentage > 100 ? 100 : 0) : scrollPercentage;
        if (scrollPercentage >= 0 && scrollPercentage <= 100) {
          setInputSlider(Math.round(scrollPercentage));
        }
      }, limit);
    }
  }

  useEffect(() => {
    const draggedContainer = draggedContainerRef;
    window.addEventListener('keyup', handleArrowKeys, true);
    return () => {
      window.removeEventListener('keyup', handleArrowKeys);
      if (draggedContainer.current) {
        draggedContainer.current.removeEventListener("mouseover", handleShowHideDragBtn);
        draggedContainer.current.removeEventListener("mouseleave", handleShowHideDragBtn);
        draggedContainer.current.removeEventListener('mousedown', handleMouseUpDown);
        draggedContainer.current.removeEventListener('mouseup', handleMouseUpDown);
        draggedContainer.current.removeEventListener('mousemove', handleMouseOver);
      }
    }
    /* eslint-disable react-hooks/exhaustive-deps */
  }, []);

//reset slider input value on tab change.
  useEffect(() => {
    // arrowKeyTracker = 0;
    updateSliderValue(0);
  }, [props.isUpdated])

  const handleArrowKeys = (e) => {
    if (!draggedContainerRef.current) return;
    const sliderOffset = draggedContainerRef.current.getBoundingClientRect();
    if (sliderOffset.top < windowHeight && (100 - sliderOffset.height) < sliderOffset.top) {
      if (e.keyCode === 39 || e.keyCode === 37) {
        if (e.keyCode === 39) {
          const newValue = arrowKeyTracker + 10;
          arrowKeyTracker = newValue < 100 ? newValue : 100;
        }
        else {
          const newValue = arrowKeyTracker - 10;
          arrowKeyTracker = newValue > 0 ? newValue : 0;
        }

        updateSliderValue(arrowKeyTracker);
      }
    }
  }

  useEffect(() => {

    draggedContainerRef.current.removeEventListener('mousemove', handleMouseOver);
    Draggable.create(draggedElementRef.current, {
      type: "x",
      autoScroll: false,
      edgeResistance: 0.65,
      bounds: draggedContainerRef.current,
      lockAxis: true,
      inertia: true,
      scale: 1,
      onMove: function (e) {
        e.preventDefault();
        updateInput(this.x, 200)
      },
      onThrowUpdate: function () {
        updateInput(this.x, 200)
      },
      onThrowComplete: function () {
        updateInput(this.x, 0)
      },
    });


    draggedContainerRef.current.addEventListener("mouseover", handleShowHideDragBtn.bind(null, true));

    draggedContainerRef.current.addEventListener("mouseleave", handleShowHideDragBtn.bind(null, false));

    draggedContainerRef.current.addEventListener('mousedown', handleMouseUpDown.bind(null, true));

    draggedContainerRef.current.addEventListener('mouseup', handleMouseUpDown.bind(null, false));

    draggedContainerRef.current.addEventListener('mousemove', handleMouseOver);
    /* eslint-disable react-hooks/exhaustive-deps */
  }, [windowWidth, props.isUpdated]);

  const handleShowHideDragBtn = (state) => {
    if (state) {
      setShowDragButton(true);
      setDragButtonMouseDown(false);
    } else {
      setShowDragButton(false);
    }
  }
  const handleMouseUpDown = (state) => {
    setDragButtonMouseDown(state)
  }
  const handleMouseOver = (e) => {
    if (dragabbleButtonRef.current) {
      dragabbleButtonRef.current.style.left = e.clientX + 'px';
      dragabbleButtonRef.current.style.top = e.clientY + 'px';
    }
  }

  const updateSliderValue = (inputSlider) => {
    setInputSlider(inputSlider);
    arrowKeyTracker = inputSlider;
    const multiplyer = (draggedElementRef.current.scrollWidth - windowWidth) / 100
    const scrollValue = -multiplyer * inputSlider;
    gsap.to(draggedElementRef.current, { x: scrollValue });
  }
  return (
    <Desktop>
      <InputSliderWrapper className={inputSlider > 70 ? 'input-range__slider__right' : inputSlider < 20 ? 'input-range__slider__left' : ''}>
        <InputRange
          maxValue={100}
          minValue={0}
          value={inputSlider}
          ref={inputSliderRef}
          onChange={updateSliderValue}
        />
      </InputSliderWrapper>
      <SliderContainer ref={draggedContainerRef}>
        <SlideInnerWrap
          className="horizontalScroll"
          ref={draggedElementRef}
        >
          {props.children}
        </SlideInnerWrap>
        {showDragButton ? (
          <DragButton
            ref={dragabbleButtonRef}
            customCursor={props.customCursor}
            className="horizontal-drag-button">

            {
              props.customCursor ?
                dragButtonMouseDown
                  ?
                  <DragIcon src="/images/drag-icon-click.svg" />
                  :
                  <DragIcon src="/images/drag-icon.svg" />
                : null
            }
          </DragButton>
        ) : null}

      </SliderContainer>
    </Desktop>
  );
}

export default Slider;
