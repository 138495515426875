import React, {useEffect, useRef, useState} from 'react'
import styled from 'styled-components'
import {useAppContext} from "../store";
import {useHistory} from "react-router-dom";

// Media Search
export const MediaSearchBlock = styled.div`
  width: 100%;
  position: relative;
`;
export const MediaSearchBtn = styled.button`
  background: transparent;
  border: none;
  position: absolute;
  left: 0;
  top: 0;
  width: 45px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;

export const MediaSearchInput = styled.input`
  padding: 16px 50px 16px 45px;
  box-sizing: border-box;
  border-radius: 0;
  width: 100%;
  background-color: #F2F2F2;
  border: none;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 140%;
  color: #000000;
  background-image: ${({backgroundImage}) => `url('${backgroundImage}')` || ""};
  background-position: 15px center;
  background-repeat: no-repeat;
  background-size: 24px 24px;
  &::placeholder {
    color: #000;
  }
    &:focus {
    &::placeholder {
      display: none;
      font-size: 0;
    }
  }
`;

const CloseIcon = styled.img`
  position: absolute;
  width: 26px;
  height: 26px;
  right: 16px;
  top: 50%;
  z-index: 1;
  transform: translateY(-50%);
  cursor: pointer;
`;

function MediaSearch({text,mobileText}) {
    const {setArticleSearchTerm} = useAppContext();
    const { setActiveMediaFilter } = useAppContext();
    const [term, setTerm] = useState('')
    const [inputWidth, setInputWidth] = useState(700)
    const inputRef = useRef(null)
    const history = useHistory();

    useEffect(() => {
        const calcInputWidth = function() {
            setInputWidth(inputRef.current.offsetWidth)
        }

        window.addEventListener('resize', calcInputWidth);
        return () => window.removeEventListener('resize', calcInputWidth);
    }, []);

    const handleChange = (e) => {
        setTerm(e.target.value)
        if(e.target.value === ''){
          inputRef.current.value = ''
          setArticleSearchTerm('')
        }
    }

    const handleSearch = (e) => {
        const search = e.target.value;
        if (e.key === 'Enter' && search.length > 2) {
            setArticleSearchTerm(search)
            setActiveMediaFilter('all')
            history.push('/news-events')
        }
    }

    const runSearch = () => {
        const search = inputRef.current.value;
        if (search.length > 2) {
            setArticleSearchTerm(search)
            setActiveMediaFilter('all')
            history.push('/news-events')
        }
    }

    const handleClose = () => {
        inputRef.current.value = ''
        setArticleSearchTerm('')
        setTerm('')
    }
    return (
        <MediaSearchBlock>
            <MediaSearchBtn onClick={runSearch}>
                <img src={'/images/RC_icon_digital_ search.svg'} alt={'Search media icon'}/>
            </MediaSearchBtn>
            <MediaSearchInput onChange={handleChange} ref={inputRef} onKeyDown={handleSearch} type={'text'}
                                     placeholder={inputWidth > 480 ? text : mobileText}/>
            {
                term.length > 0 ? <CloseIcon onClick={handleClose} src={'/images/RC_ Icon_Cross.svg'} alt={''}/> : ''
            }

        </MediaSearchBlock>
    )
}

export default MediaSearch;
