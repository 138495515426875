import styled from 'styled-components';

import {

} from "components/elements";


// CSS Start
export const AccordionSectionWrap = styled.div`
    border-bottom: 1px solid #000;
`;
