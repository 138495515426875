import React, { useEffect} from 'react';
import {withRouter} from "react-router-dom"


import { NewsletterSignupWrap, CopyAndFormContainer, PhoneContainer, FormContainer} from './elements';


function NewsletterSignup({phoneDesktop, phoneMobile, pardotLink}) {


useEffect(() => {
    let iframe = document.getElementById('pardotForm');

    iframe.style.height = 250 + 'px';
	
	window.addEventListener('message', e => {
        const key = e.message ? 'message' : 'data';
        const data = e[key];
        iframe.style.height = data.height + 75 + 'px';
        if(data.height < 300){
            window.scrollTo(0, 400)
        }
    },false);

    addEvent(window, 'message', function(message) {
         if (message.data === 'weekly') {
            let  dataLayer = window.dataLayer|| (window.dataLayer = []);
             dataLayer.push({
                 'event': 'e_form_submit_weekly_value_newsletter',
                 'eventCategory': 'Form',
                 'eventAction': 'Submit',
                 'eventLabel': 'Weekly Value Newsletter'         
           });
         }else if(message.data === 'monthly'){
            let  dataLayer = window.dataLayer|| (window.dataLayer = []);
             dataLayer.push({
                 'event': 'e_form_submit_monthly_digest_newsletter',
                 'eventCategory': 'Form',
                 'eventAction': 'Submit',
                 'eventLabel': 'Monthly Digest Newsletter'
                 
             });
         }else if(message.data === 'health'){
            let  dataLayer = window.dataLayer|| (window.dataLayer = []);
             dataLayer.push({
                 'event': 'e_form_submit_health_equity_digest_newsletter',
                 'eventCategory': 'Form',
                 'eventAction': 'Submit',
                 'eventLabel': 'Health Equity Digest Newsletter'
                 
             });
         }else if(message.data === 'daily'){
            let  dataLayer = window.dataLayer|| (window.dataLayer = []);
             dataLayer.push({
                 'event': 'e_form_submit_daily_value_newsletter',
                 'eventCategory': 'Form',
                 'eventAction': 'Submit',
                 'eventLabel': 'Daily Value Newsletter'
            });
         }			
     },[]);
 
 
     function addEvent(el, evt, fn) {
         if (el.addEventListener) {
             el.addEventListener(evt, fn);
         } else if (el.attachEvent) {
             el.attachEvent('on' + evt, function(evt) {
                 fn.call(el, evt);
             });
         } else if (typeof el['on' + evt] === 'undefined' || el['on' + evt] === null) {
             el['on' + evt] = function(evt) {
                 fn.call(el, evt);
             };
         }
     }


},[]);


    return(
        <NewsletterSignupWrap className='newsletterWrap'>
            <CopyAndFormContainer>
                <FormContainer >
                    <iframe title="Newsletter signup form" src={pardotLink} scrolling="no" width="100%" type="text/html" style={{border: 'none'}} id="pardotForm" className={"pardotForm"}></iframe>        
                </FormContainer> 
            </CopyAndFormContainer>
            <PhoneContainer>
                <img src={phoneDesktop} alt="Phone" className='phone'/>
            </PhoneContainer>
        </NewsletterSignupWrap>
    )
}

export default withRouter(NewsletterSignup);
