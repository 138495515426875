import { Link } from "react-router-dom";
import styled from "styled-components";

import { BodyRg } from "components/elements";
import { sizeDesktopMd, sizeTabletLg } from "components/devices";

// CSS Start
export const InformCardWrap = styled.div`
  border-top: ${({ borderTop }) => borderTop || "1px solid #707070"};
  border-right: ${({ borderRight }) => borderRight || "1px solid #707070"};
  border-bottom: ${({ borderBottom }) => borderBottom || "1px solid #707070"};
  border-left: ${({ borderLeft }) => borderLeft || "1px solid #707070"};
  box-sizing: border-box;
  height: 100%;
  background-color: #f8f8f8;
  ${sizeTabletLg(`
        height: auto;
        min-height: 200px;
        border-bottom: none;
    `)}
  a {
    line-height: 1.3;
    color: #D12FFF;
    border-bottom: 1.3px solid #D12FFF;
    word-break: break-word;
  }
`;
export const InformBody = styled.div`
  padding: 50px 80px;
  height: 100%;
  box-sizing: border-box;
  ${sizeDesktopMd(`
        padding: 30px 50px;
    `)}
  ${sizeTabletLg(`
        padding: 30px 25px;
    `)}
    ${BodyRg} {
    font-size: 20px;
    margin-bottom: 15px;
    &:last-child {
      margin-bottom: 0;
    }
    /* ${sizeTabletLg(`
            font-size: 16px;
        `)} */
  }
`;
export const InfoLink = styled(Link)`
  text-decoration: underline;
  color: #D12FFF;
`;
