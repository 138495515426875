import React, { useEffect } from 'react'

import {
   TitleSm, BodySm
} from 'components/elements'

import {
  LinkCardWrap,
  LinkCardFooter,
  LinkCardIcon, 
  TitleParagraphContainer,
  BodySmContainer,
  UnorderedList,
  ListItem, 
  CopyListContainer
} from './elements'



function LinkCard({ link, backgroundColor, title, bodySm, cta, margin, orderedList }) {

  const [noHover, setNoHover] = React.useState('');

  useEffect(() => {
    if(backgroundColor === '#0F20FF' ){
      setNoHover('none');
    }
  },[backgroundColor])


  return (
    <LinkCardWrap href={link !== " " ? link : ""} target={'_blank'} backgroundColor={backgroundColor} margin={margin} noHover={noHover}>
      <CopyListContainer>
      <TitleParagraphContainer>
        <TitleSm color="#FFF" className="brand title">{title}</TitleSm>
        <BodySm color="#FFF" >{bodySm}</BodySm>
      </TitleParagraphContainer>
      <UnorderedList>
            {orderedList && (
              orderedList?.content[1]?.content?.map((listItem, index) => (
                  <ListItem key={index}>
                    {listItem.content[0]?.content[0].value}
                  </ListItem>
              ))
            )}
      </UnorderedList>
      </CopyListContainer>
      {link && (
      <LinkCardFooter>
        <BodySmContainer>
          <BodySm fontWeight={700} textTransform="uppercase" color="#FFF">{cta}</BodySm>
        </BodySmContainer>
        <LinkCardIcon src="/images/lg-link-arrow.svg" alt="Click arrow to go to page"/>
    </LinkCardFooter>
      )}

    </LinkCardWrap>
  )
}

export default LinkCard
