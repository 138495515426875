
import React, { useEffect, useState } from 'react';
import { Row, TitleXs } from 'components/elements'
import RichText from 'components/RichText'
import gsap from 'gsap'

import {
	Accordion,
	AccordionBody,
	AccordionHeader,
	AccordionLink,
	AccordionWrap,
	BodyInner,
	Col6Custom,
	GalaxyWrapper,
	GalaxySvgWrap,
	GalaxySvg,
	GalaxyVideo,
	Video
} from './elements'

import MorphSVGPlugin from '../../assets/gsap-club/MorphSVGPlugin'

function Galaxy({ items }) {
	const bgColors = ["#6900ff", "#d54639", "#0f20ff", "#ff9c30", "#d12fff"]
	const [currentItem, setCurrentItem] = useState(undefined)
	// eslint-disable-next-line
	const [hidden, setHidden] = useState('')
	// eslint-disable-next-line
	let [stateDef, setStateDef] = useState(gsap.timeline(
		{
			defaults:
			{
				duration: 3,
				yoyo: true,
				repeat: -1,
				ease: 'power2.inOut'
			}
		}))

	useEffect(() => {
		if (items?.length > 0) {
			for (let i = 0; i < bgColors.length; i++) {
				if (i > items.length)
					break;

				if (items[i].backgroundColor)
					bgColors[i] = items[i].backgroundColor
			}
		}
	}, [bgColors, items])

	useEffect(() => {
		let commercial = document.querySelector('#commercial');
		let clinical = document.querySelector('#clinical');

		gsap.registerPlugin(MorphSVGPlugin)
		gsap.set(['#state2', '#state3'], { autoAlpha: 0 });
		gsap.set('#orb', { transformOrigin: '50% 50%' });

		stateDef.from(commercial, {
			rotation: 12,
			transformOrigin: '50% 50%'
		}, 0)
			.from(clinical, {
				rotation: -12,
				transformOrigin: '50% 50%'
			}, 0);
		// eslint-disable-next-line
	}, [])

	const handleToggleAccordion = (aNum) => {
		setCurrentItem((currentItem === aNum) ? undefined : aNum)
		setHidden((currentItem === aNum) ? undefined : 'hidden accordion')
	}

	useEffect(() => {
		handleOrbStateChange()
		// eslint-disable-next-line
	}, [currentItem])

	const handleOrbStateChange = () => {
		let technology = document.querySelector('#technology')
		let commercial = document.querySelector('#commercial')
		let clinical = document.querySelector('#clinical')

		switch (currentItem) {
			case 0:
				orbState0(technology, commercial, clinical)
				break
			case 1:
				orbState1(technology, commercial, clinical)
				break
			case 2:
				orbState2(technology, commercial, clinical)
				break
			case 3:
				orbState3(technology, commercial, clinical)
				break
			case 4:
				orbState4(technology, commercial, clinical)
				break
			default:
				orbStateInit(technology, commercial, clinical)
				break

		}
	}

	const orbState0 = (technology, commercial, clinical) => {
		var state2 = gsap.timeline({
			paused: true,
			defaults: {
				duration: 1,
				ease: 'power2.inOut',
				stroke: "#691BFF",
				strokeOpacity: 0.5
			},
			onComplete: function () { this.invalidate(); stateDef.pause(3); }
		})

		state2
			.to('#orb', { rotation: 0 })
			.to(commercial, { rotation: 0, morphSVG: "#commercial-2" }, 0)
			.to(clinical, { rotation: 0, morphSVG: "#clinical-2" }, 0)
			.to(technology, { morphSVG: "#technology-2" }, 0)
		state2.play()
	}

	const orbState1 = (technology, commercial, clinical) => {
		var state3 = gsap.timeline({
			paused: true,
			defaults: {
				duration: 1,
				ease: 'power2.inOut',
				stroke: "#FFFFFF",
				strokeOpacity: 0.5
			},
			onComplete: function () { this.invalidate(); stateDef.pause(3); }
		});

		state3
			.to('#orb', { rotation: 30 })
			.to(commercial, { rotation: 0, strokeOpacity: 1, stroke: "#D54639", zIndex: 2, morphSVG: "#commercial-3" }, 0)
			.to(clinical, { rotation: 0, morphSVG: "#clinical-3" }, 0)
			.to(technology, { morphSVG: "#technology-3" }, 0);
		state3.play()
	}

	const orbState2 = (technology, commercial, clinical) => {
		var state4 = gsap.timeline({
			paused: true,
			defaults: {
				duration: 1,
				ease: 'power2.inOut',
				stroke: "#FFFFFF",
				strokeOpacity: 0.5
			},
			onComplete: function () { this.invalidate(); stateDef.pause(3); }
		});


		state4
			.to('#orb', { rotation: 60 })
			.to(commercial, { rotation: 0, morphSVG: "#commercial-3" }, 0)
			.to(clinical, { rotation: 0, strokeOpacity: 1, stroke: "#0F20FF", zIndex: 2, morphSVG: "#clinical-3" }, 0)
			.to(technology, { morphSVG: "#technology-3" }, 0);
		state4.play()
	}

	const orbState3 = (technology, commercial, clinical) => {
		var state5 = gsap.timeline({
			paused: true,
			defaults: {
				duration: 1,
				ease: 'power2.inOut',
				stroke: "#FFFFFF",
				strokeOpacity: 0.5
			},
			onComplete: function () { this.invalidate(); stateDef.pause(3); }
		})

		state5
			.to('#orb', { rotation: 90 })
			.to(commercial, { rotation: 0, morphSVG: "#commercial-3" }, 0)
			.to(clinical, { rotation: 0, morphSVG: "#clinical-3" }, 0)
			.to(technology, { strokeOpacity: 1, stroke: "#FF9C30", zIndex: 2, morphSVG: "#technology-3" }, 0);
		state5.play()
	}

	const orbState4 = (technology, commercial, clinical) => {
		var state1 = gsap.timeline({
			paused: true,
			defaults: {
				duration: 1,
				ease: 'power2.inOut',
				stroke: "#D12FFF",
				strokeOpacity: 1
			},
			onComplete: function () { this.invalidate(); stateDef.pause(3); }
		});

		state1
			.to('#orb', { rotation: 0 })
			.to(commercial, { rotation: 0, morphSVG: "#commercial" }, 0)
			.to(clinical, { rotation: 0, morphSVG: "#clinical" }, 0)
			.to(technology, { morphSVG: "#technology" }, 0);
		state1.play()

	}

	const orbStateInit = (technology, commercial, clinical) => {
		var tlReturnDef = gsap.timeline({
			paused: true,
			defaults: {
				duration: 1,
				ease: 'power2.inOut',
				stroke: "#FFFFFF",
				strokeOpacity: 1
			},
			onComplete: function () { this.invalidate(); stateDef.resume(); }
		});

		tlReturnDef
			.to('#orb', { rotation: 0 })
			.to(commercial, { rotation: 0, morphSVG: "#commercial" }, 0)
			.to(clinical, { rotation: 0, morphSVG: "#clinical" }, 0)
			.to(technology, { morphSVG: "#technology" }, 0);
		tlReturnDef.play(0);
	}

	return (
		<GalaxyWrapper> <Row alignItems="center">
			<Col6Custom >
				<GalaxySvgWrap>
					<GalaxySvg>
						<svg width="100%" viewBox="0 0 673 678" fill="none" xmlns="http://www.w3.org/2000/svg">
							<g id="orb">
								<g id="state1">
									<path id="technology" d="M604.773 523.371C583.744 553.569 543.46 573.745 493.66 585.904C443.932 598.045 385.083 602.101 327.412 600.358C269.748 598.614 213.36 591.076 168.58 580.084C146.187 574.588 126.746 568.239 111.517 561.345C96.221 554.42 85.4316 547.056 80.045 539.663C57.7101 509.007 54.3032 471.651 65.9159 425.601C77.5427 379.495 104.163 324.951 141.519 260.249C178.881 195.536 210.366 148.076 241.977 116.255C273.523 84.4985 305.104 68.4057 342.729 66.2676C351.63 65.7618 363.806 70.3225 378.358 79.4407C392.824 88.5044 409.323 101.868 426.775 118.473C461.672 151.677 500.209 197.675 533.698 247.671C567.193 297.677 595.568 351.579 610.227 400.583C624.915 449.681 625.684 493.342 604.773 523.371Z" stroke="#FFF" strokeWidth="4"></path>
									<path id="clinical" d="M95.9902 556.932C72.5515 527.305 68.0913 482.489 76.0703 431.303C84.0367 380.198 104.337 323.189 129.826 269.645C155.311 216.11 185.933 166.141 214.466 129.119C228.736 110.604 242.448 95.3729 254.7 84.5596C267.041 73.6681 277.598 67.5341 285.575 66.674C319.533 63.0128 354.99 73.5819 393.522 99.8592C432.079 126.152 473.603 168.1 519.602 226.976C565.678 285.951 600.752 334.677 618.606 377.82C636.405 420.828 637.004 458.052 614.408 494.324C608.88 503.197 598.197 512.81 583.301 522.602C568.447 532.367 549.57 542.203 527.852 551.582C484.42 570.337 429.776 587.195 373.566 597.972C317.345 608.751 259.661 613.426 210.123 607.887C160.527 602.342 119.466 586.606 95.9902 556.932Z" stroke="#FFF" strokeWidth="4"></path>
									<path id="commercial" d="M384.626 71.2252C420.695 77.5967 454.317 107.301 483.703 149.793C513.038 192.213 537.895 247.011 556.549 302.86C575.2 358.702 587.614 415.489 592.114 461.84C594.365 485.019 594.63 505.526 592.732 521.97C590.824 538.504 586.765 550.575 580.684 557.248C529.291 613.643 438.938 624.761 291.448 598.707C217.646 585.67 162.806 574.278 122.594 554.733C82.5603 535.275 57.049 507.743 41.9457 462.202C38.2849 451.163 38.4388 436.369 42.0063 418.797C45.5659 401.264 52.4835 381.16 62.1353 359.621C81.4374 316.546 111.586 267.927 147.307 222.968C183.032 178.004 224.261 136.787 265.686 108.455C307.17 80.0813 348.523 64.8476 384.626 71.2252Z" stroke="#FFF" strokeWidth="4"></path>
								</g>
								<g id="state2">
									<path id="clinical-2" d="M602.032 553.277C593.517 558.217 581.461 557.031 566.429 549.659C551.477 542.326 534.081 529.103 515.349 510.953C477.905 474.673 435.457 419.035 396.936 352.631C358.416 286.227 331.19 221.759 318.284 171.244C311.828 145.973 308.985 124.308 310.042 107.688C311.105 90.9789 316.06 79.925 324.576 74.9851C333.091 70.0451 345.147 71.2317 360.179 78.6035C375.131 85.9362 392.527 99.1591 411.259 117.309C448.703 153.59 491.151 209.228 529.672 275.632C568.192 342.035 595.418 406.504 608.324 457.018C614.78 482.29 617.623 503.955 616.566 520.575C615.503 537.284 610.548 548.337 602.032 553.277Z" strokeWidth="4"></path>
									<path id="technology-2" d="M71.7491 553.277C80.2648 558.217 92.3201 557.031 107.352 549.659C122.305 542.326 139.7 529.103 158.433 510.953C195.876 474.673 238.324 419.035 276.845 352.631C315.366 286.227 342.591 221.759 355.497 171.244C361.954 145.973 364.797 124.308 363.739 107.688C362.676 90.9789 357.722 79.925 349.206 74.9851C340.69 70.0451 328.635 71.2317 313.603 78.6035C298.65 85.9362 281.255 99.1591 262.522 117.309C225.079 153.59 182.631 209.228 144.11 275.632C105.589 342.035 78.3637 406.504 65.4576 457.018C59.001 482.29 56.1582 503.955 57.2157 520.575C58.2788 537.284 63.2334 548.337 71.7491 553.277Z" strokeWidth="4"></path>
									<path id="commercial-2" d="M335.267 606.376C257.86 605.862 187.914 596.569 137.42 582.001C112.158 574.714 91.8787 566.138 77.9782 556.759C64.002 547.329 56.9342 537.415 57.0005 527.539C57.0667 517.663 64.267 507.843 78.3685 498.6C92.3936 489.406 112.787 481.1 138.143 474.149C188.829 460.254 258.893 451.89 336.3 452.404C413.707 452.918 483.652 462.212 534.147 476.779C559.408 484.067 579.688 492.642 593.589 502.021C607.565 511.452 614.633 521.366 614.566 531.242C614.5 541.118 607.3 550.937 593.198 560.181C579.173 569.374 558.78 577.68 533.423 584.631C482.738 598.527 412.673 606.89 335.267 606.376Z" strokeWidth="4"></path>
								</g>
								<g id="state3">
									<path id="clinical-3" d="M342.382 367.803C304.532 304.014 282.197 239.792 276.489 187.583C270.757 135.166 281.832 95.7352 309.57 80.153C323.444 72.3586 340.493 71.0806 359.624 75.6955C378.764 80.3128 399.862 90.8054 421.627 106.357C465.153 137.457 511.048 188.573 548.899 252.364C586.751 316.156 609.294 380.38 615.209 432.591C621.149 485.014 610.274 524.435 582.543 540.014C568.669 547.808 551.568 549.087 532.357 544.471C513.137 539.853 491.935 529.359 470.066 513.807C426.332 482.706 380.231 431.591 342.382 367.803Z" strokeWidth="4"></path>
									<path id="commercial-3" d="M336.339 377.468C412.305 377.468 480.986 390.63 530.602 411.708C555.413 422.248 575.364 434.729 589.083 448.451C602.794 462.166 610.173 477.003 610.173 492.339C610.173 507.674 602.795 522.46 589.086 536.098C575.37 549.743 555.42 562.123 530.608 572.563C480.992 593.44 412.309 606.4 336.339 606.4C260.37 606.4 191.687 593.44 142.071 572.563C117.259 562.123 97.3092 549.743 83.5925 536.098C69.8835 522.46 62.5057 507.674 62.5057 492.339C62.5057 477.003 69.8849 462.166 83.5965 448.451C97.3148 434.729 117.266 422.248 142.078 411.708C191.693 390.63 260.374 377.468 336.339 377.468Z" strokeWidth="4"></path>
									<path id="technology-3" d="M319.411 361.936C281.608 425.71 236.524 477.237 194.265 509.013C173.132 524.904 152.785 535.795 134.476 540.854C116.171 545.912 100.069 545.104 87.1885 537.861C74.3172 530.623 65.5653 517.467 60.9184 499.529C56.268 481.578 55.7769 458.987 59.3389 433.223C66.4617 381.705 89.7319 317.92 127.536 254.144C165.34 190.371 210.424 138.843 252.682 107.067C273.815 91.1766 294.162 80.2855 312.472 75.2262C330.776 70.1683 346.878 70.9768 359.758 78.2198C372.629 85.4576 381.381 98.6137 386.028 116.551C390.679 134.502 391.17 157.093 387.608 182.857C380.485 234.376 357.216 298.161 319.411 361.936Z" strokeWidth="4"></path>
								</g>
							</g>
						</svg>
					</GalaxySvg>
					<GalaxyVideo>
						<Video autoPlay muted loop playsInline>
							<source src="/videos/RC_Amoeba_1_1440p_1Mbps.mp4" />
						</Video>
					</GalaxyVideo>
				</GalaxySvgWrap>
			</Col6Custom>

			<Col6Custom>
				<AccordionWrap>
					{items && items.map((item, index) => {
						const aId = 'accordion' + index
						const cname = (currentItem === index) ? 'accordion active' : "accordion"
						const color = item.backgroundColor ? item.backgroundColor : bgColors[index % bgColors.length]

						return <Accordion key={index} id={aId} className={cname} color={color}>
							<AccordionHeader onClick={() => { handleToggleAccordion(index) }} className="section header">
								<TitleXs lineHeight={"1.1"} color="#FFF">{item.title}</TitleXs>
								<svg id="M_Plus" data-name="M Plus" xmlns="http://www.w3.org/2000/svg" width="100%" viewBox="0 0 32 32">
									<path id="Path_596" data-name="Path 596" d="M-23839.43,1932.763v32"
										transform="translate(23855.428 -1932.763)" fill="none" stroke="#fff" strokeWidth="4">
									</path>
									<path id="Path_595" data-name="Path 595" d="M0,0V32"
										transform="translate(32 16) rotate(90)" fill="none" stroke="#fff" strokeWidth="4"></path>
								</svg>
							</AccordionHeader>
							<AccordionBody>
								<BodyInner>
									<RichText body={item.detail} options={{ color: '#fff' }} />
									<AccordionLink to={item.ctaLink}>{item.ctaText}</AccordionLink>
								</BodyInner>
							</AccordionBody>
						</Accordion>
					})}
				</AccordionWrap>
			</Col6Custom>
		</Row> </GalaxyWrapper>
	)
}

export default Galaxy

