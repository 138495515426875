import React, { useState, useEffect } from 'react';
import { BodySm, TitleXl, TitleLg, TitleMd, WideScreenContainer } from 'components/elements';
import { AvantHeader, ResponsiveWideScreenContainer, ImageContainer, StyledImage, ContentContainer, BodyBoldContainer, BodyContainer, TitleLgContainer, BodySmContainer } from './elements';
import { withRouter } from 'react-router-dom';

function PreLaunch({ data }) {
  const [content, setContent] = useState([]);

  useEffect(() => {
    if (data?.elements) {
      setContent(data.elements);
    }
  }, [data]);

  const LineBreakText = ({ text }) => {
    return text ? text.split('\n').map((item, index) => (
      <React.Fragment key={index}>
        {item}
        <br />
      </React.Fragment>
    )) : null;
  };

  return (
    <ResponsiveWideScreenContainer>
      <ImageContainer>
        <StyledImage src={content[0]?.imageAsset || ''} alt="PDUFA + Launch" />
      </ImageContainer>
      <ContentContainer>
        <AvantHeader>
          <TitleLgContainer>
            <TitleLg textTransform='unset' color='#FFF' fontSize='48px !important' weight='700'>
              {content[1]?.text?.content[0]?.content[0]?.value || ''}
              <span className="magenta">.</span>
            </TitleLg>
          </TitleLgContainer>
          <BodySmContainer>
            <BodySm fontWeight='400'>
              {content[2]?.text?.content[0]?.content[0]?.value || ''}
            </BodySm>
          </BodySmContainer>
          <BodyBoldContainer>
            <TitleXl lineHeight='24px' fontSize='20px !important'>
              <LineBreakText text={content[3]?.text?.content[0]?.content[0]?.value || ''} />
            </TitleXl>
          </BodyBoldContainer>
          <BodyContainer>
            <TitleMd
              fontSize="20px !important"
              fontWeight="900"
              lineHeight="24px"
              textAlign="left"
              color="#FFF"
            >
              <LineBreakText text={content[4]?.text?.content[0]?.content[0]?.value || ''} />
            </TitleMd>
          </BodyContainer>
        </AvantHeader>
      </ContentContainer>
    </ResponsiveWideScreenContainer>
  );
}

export default withRouter(PreLaunch);
