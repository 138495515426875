import React from 'react';
import {
	NextWrap,
	NextArrows,
	NextRow,
	NextLink,
	ArrowLongWrap,
	ArrowAngle,
	ArrowLine
} from "./elements";

import {
	TitleXl,
	BodySm
} from "components/elements";



// Components

function NextPage({ pageName, pageLink, back, label, color, borderColor, borderLeft, borderRight }) {
	if (!label)
		label = 'Next Section'
	return (
		<NextWrap className={back ? "back" : ""} borderLeft={borderLeft} borderRight={borderRight}>

			<NextLink className="cta next page" to={pageLink ? pageLink : '#'}>
				<BodySm color={color} textTransform="uppercase">{back ? "back" : label}</BodySm>
				<NextRow>
					<TitleXl color={color} textTransform="uppercase">{back ? "back" : "Next Section"}</TitleXl>
					<TitleXl color={color} textTransform="uppercase">{pageName}</TitleXl>
				</NextRow>
				<NextArrows borderColor={borderColor}>
					<ArrowLongWrap>
						<ArrowAngle />
						<ArrowLine />
					</ArrowLongWrap>
				</NextArrows>
			</NextLink >
		</NextWrap>
	)
}


export default NextPage;
