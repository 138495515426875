import { Link } from "react-router-dom";
import styled, { css } from 'styled-components';

import {
    BodySm,
    BodyRg,
    TitleSm
} from "components/elements";
import {
    sizeLaptop,
    sizeTabletLg,
    sizeWideScreen,
} from "components/devices";

export const CardImg = styled.img`
    width: 100%;
    height: 100%;
    transition: 0.4s all ease;
    object-fit: cover;
    ${sizeTabletLg(`
        display: none;
    `)}
`;
export const CardImgMobile = styled.img`
    width: 100%;
    height: 100%;
    transition: 0.4s all ease;
    object-fit: cover;
    display: none;
    ${sizeTabletLg(`
        display: block;
    `)}
`;
export const ImageDiv = styled.div`
    overflow: hidden;
    transition: 0.4s all ease;
    height: 100%;
    box-sizing: border-box;
    width: 100%;
`;

export const CardIcon = styled.img`
    width: 56px;
`;
export const CardWhite = styled.img`
    display: none;
    margin-right: 20px;
`;
export const CardTextCol = styled.div`
    width: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
    height: 100%;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    padding: ${({ padding }) => padding || "30px 30px"};
    border-right: 1px solid #707070;
    box-sizing: border-box;
    position: relative;
    transition: 0.5s all ease;
    &:last-child {
        border-right: none;
    }
    ${sizeLaptop(css`
        padding: 25px;
    `)}
    ${sizeTabletLg(css`
        align-items: center;
        text-align: center;
        border-right: none;
        padding: 30px 20px;
    `)}
`;
export const CardsLink = styled(Link)`
    font-size: 16px;
    font-weight: 500;
    text-transform: uppercase;
    color: #000;

    /* Wide Screen CSS */
    ${sizeWideScreen(`
        font-size: 0.7vw;
    `)}
`;

export const CardLabel = styled.div`
    width: fit-content !important;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    margin-bottom: 20px;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    ${sizeTabletLg(css`
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
        -webkit-box-orient: vertical;
        -webkit-box-direction: reverse;
        -ms-flex-direction: column-reverse;
        flex-direction: column-reverse;
        text-align: center;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        margin-right: 0;
        margin-bottom: 40px;
    `)}
    ${CardIcon},
    ${CardWhite} {
        margin-right: 20px;
        ${sizeTabletLg(css`
            margin-right: 0;
            margin-top: 17px;
        `)}
    }


    ${BodySm} {
         /* Wide Screen CSS */
         ${sizeWideScreen(css`
            font-size: 0.55vw;
        `)}
    }
`;

export const CardWrap = styled.div`
    background-color: ${({ bgColor }) => bgColor || "#FFFFFF"};
    height: ${({ height }) => height || "100%"};
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    padding: ${({ padding }) => padding || "40px 30px"};
    box-sizing: border-box;
    width: 100%;
    position: relative;
    border-top: ${({ borderTop }) => borderTop || "1px solid #707070"};
    border-right: ${({ borderRight }) => borderRight || "1px solid #707070"};
    border-bottom: ${({ borderBottom }) => borderBottom || "1px solid #707070"};
    border-left: ${({ borderLeft }) => borderLeft || "1px solid #707070"};
    &:hover {
        ${CardImg},
        ${CardImgMobile} {
            transform: scale(${props => props.link ? "1.2" : ""});
        }
    }
    ${sizeTabletLg(css`
        height: auto !important;
        padding: 20px 0 0 0;
        border-bottom: 1px solid #707070;
        border-left: none;
        border-right: none;
    `)}

    ${ImageDiv} {
        ${sizeTabletLg(css`
            padding: 0 20px 20px 20px;
        `)} 
    }
    
    ${CardImg} {
        flex: 0 0 60%;
    }
    ${CardLabel} {
        width: 100%;
        ${sizeTabletLg(css`
            flex-direction: column;
            align-items: center;
        `)} 
    }
    ${BodyRg} {
        margin-bottom: 25px;
    }
    ${BodySm} {
        &:last-child {
            margin-left: auto;
            ${sizeTabletLg(css`
                margin-left: 0;
            `)} 
        }

        /* Wide Screen CSS */
        ${sizeWideScreen(`
            font-size: 0.7vw;
        `)}
    }
    ${TitleSm} {
        margin: 25px 0 25px;
        font-size: 32px;

        /* Wide Screen CSS */
        ${sizeWideScreen(`
            font-size: 1vw;
        `)}
    }
    ${CardIcon} {
        ${sizeTabletLg(css`
            margin-bottom: 30px;
            margin-top: 0;
        `)} 
    }
`;

export const CardInfo = styled.div`
    padding: 30px;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 40%;
    flex: 0 0 40%;
    width: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    box-sizing: border-box;
    ${sizeTabletLg(css`
        padding: 35px 25px 50px 25px;
        -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 100%;
        text-align: center;
        -webkit-box-align: center;
        -ms-flex-align: center;
        -ms-grid-row-align: center;
        align-items: center;
    `)}
    ${BodySm} {
        &.label {
            ${sizeTabletLg(css`
                display: none;
            `)}
        }
    }
`;
