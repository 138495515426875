
import React from 'react'

import { AwardWrap, AwardWrapWithoutLink, AwardInner, AwardLogo } from './elements'
import { TitleXs, BodyRg } from 'components/elements'
import ArrowIcon from "../../assets/icons/ArrowIcon";

function AwardCard(props) {
  if (props.url) {
    return (
      <AwardWrap
        borderTop={props.borderTop}
        borderRight={props.borderRight}
        borderBottom={props.borderBottom}
        borderLeft={props.borderLeft}
        height={props.height}
        padding={props.padding}
        onClick={props.onClick}
      >
        <AwardInner>
          <AwardLogo src={props.awardLogo} alt={`${props.title} logo`}/>
          <TitleXs>{props.title}</TitleXs>
          <BodyRg className={'award-link'}>
            {props.date}
            <ArrowIcon/>
          </BodyRg>
        </AwardInner>
      </AwardWrap>
    )
  } else {
    return (
      <AwardWrapWithoutLink
        borderTop={props.borderTop}
        borderRight={props.borderRight}
        borderBottom={props.borderBottom}
        borderLeft={props.borderLeft}
        height={props.height}
        padding={props.padding}
        onClick={props.onClick}
      >
        <AwardInner>
          <AwardLogo src={props.awardLogo} alt={`${props.title} logo`}/>
          <TitleXs>{props.title}</TitleXs>
          <BodyRg className={'award-link'}>
            {props.date}
            <ArrowIcon/>
          </BodyRg>
        </AwardInner>
      </AwardWrapWithoutLink>
    )
  }
}

export default AwardCard

