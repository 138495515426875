import React, { useEffect, useState } from "react";
import { withRouter } from 'react-router-dom'
// import { gsap } from "gsap";
// import ScrollTrigger from "gsap/ScrollTrigger";
import {
  NavHeader,
  NavContainer,
  NavBrand,
  NavList,
  LogoDesktop,
  NavItem,
  MenuLink,
  MenuBtnBg,
  MenuButton,
  BtnLine,
  DropdownIcon,
  THMNavHeader
} from "./elements";
import {
  r3cContent,
} from "constants/index";
import { useAppContext } from "store/index";
import { useLocation } from "react-router-dom";
import CareersMenu from "./components/CareersMenu";
import AlchemyMenu from "./components/AlchemyMenu";
import CareersMobSubMenu from "./components/mobile/CareersMobSubMenu";
import { TitleSm } from "components/elements";
import AboutUsMenu from "./components/AboutUsMenu";
import AboutUsMobSubMenu from "./components/mobile/AboutUsMobSubMenu";

// gsap.registerPlugin(ScrollTrigger);

/* eslint-disable no-unused-vars */

function Header(props) {
  let route = useLocation().pathname;
  const { logoAniComplete, blackHeader, updateLogoState } = useAppContext();
  const [isHomePage, setIsHomePage] = useState(true);
  const [isErrorPage, setIsErrorPage] = useState(true);
  const [aboutData, setAboutData] = useState({})
  const [workWithUsData, setWorkWithUsData] = useState({})
  const [insightsSubMenuData, setInsightsSubMenuData] = useState({})
  const [ourAlchemySubmenuData, setOurAlchemySubMenuData] = useState({})
  const [careersSubMenuData, setCareersSubMenuData] = useState({})
  const [contactUsMenuData, setContactUsMenuData] = useState({})
  const [showAboutDropdown, setShowAboutDropdown] = useState(false);

  useEffect(() => {
    fetch(r3cContent + '/header').then((response) => response.json()).then((pgData) => {

      setAboutData(pgData.navigationItems[0])
      setOurAlchemySubMenuData(pgData.navigationItems[1])
      setInsightsSubMenuData(pgData.navigationItems[2])
      setWorkWithUsData(pgData.navigationItems[3])
      setCareersSubMenuData(pgData.navigationItems[4])
      setContactUsMenuData(pgData.navigationItems[5])
    })
  }, [])


  useEffect(() => {
    if (route === "/") {
      window.scrollTo(0, 0);
      // setMarginTop(-19);
      setIsHomePage(true);
    } else {
      setIsHomePage(false);
    }
    if (route === "/404") {
      setIsErrorPage(true);
    } else {
      setIsErrorPage(false);
    }
  }, [route]);
  // Desktop sub menus
  const [showAboutMenu, setAboutMenu] = React.useState(false);
  const [showSolutionsSubMenu, setSolutionsSubMenu] = React.useState(false);
  const [showWWUsMenu, setWWUsMenu] = React.useState(false);
  const [showInsightsMenu, setInsightsMenu] = React.useState(false);
  const [showOurAlchemyMenu, setOurAlchemyMenu] = React.useState(false);
  const [showCareersMenu, setCareersMenu] = React.useState(false);


  const openAboutMenu = () => {
    setAboutMenu(true);
  };
  const closeAboutMenu = () => {
    setAboutMenu(false);
  };
  const openSolutionsMenu = () => {
    setSolutionsSubMenu(true);
  };
  const closeSolutionsMenu = () => {
    setSolutionsSubMenu(false);
  };
  const openWWUsMenuMenu = () => {
    setWWUsMenu(true);
  };
  const closeWWUsMenuMenu = () => {
    setWWUsMenu(false);
  };
  const openInsightsMenu = () => {
    setInsightsMenu(true);
  };
  const closeInsightsMenu = () => {
    setInsightsMenu(false);
  };
  const openOurAlchemyMenu = () => {
    setOurAlchemyMenu(true);
  };
  const closeOurAlchemyMenu = () => {
    setOurAlchemyMenu(false);
  };

  const openAboutDropdown = () => {
    setShowAboutDropdown(true);
  };

  const closeAboutDropdown = () => {
    setShowAboutDropdown(false);
  };
  const openCareersMenu = () => {
    setCareersMenu(true);
  };

  const openAlchemyMenu = () => {
    setOurAlchemyMenu(true);
  }

  const closeCareersMenu = () => {
    setCareersMenu(false);
  };

  const closeAlchemyMenu = () => {
    setOurAlchemyMenu(false);
  };

  // Mobile sub menus
  const [openMobileSubmenu, setOpenMobileSubmenu] = React.useState(false);
  const [openOurAlchemySubM, setOpenOurAlchemySubM] = React.useState(false);
  const [openInsightsSubM, setOpenInsightsSubM] = React.useState(false);
  const [openCareersSubM, setOpenCareersSubM] = React.useState(false);
  const [openAboutSubM, setOpenAboutSubM] = React.useState(false);
  const [openAlchemySubM, setOpenAlchemySubM] = React.useState(false);
  const [openContactUsM, setOpenContactUsM] = React.useState(false);

  // var body = document.getElementByQuerySelector();

  const handleMobileSubmenu = () => {
    setOpenMobileSubmenu(!openMobileSubmenu);
    if (!openMobileSubmenu === true) {
      document.body.className += "bodyFixed";
    } else {
      document.body.className = "";
    }

  };
  const handleOurAlchemySubM = () => {
    setOpenOurAlchemySubM(!openOurAlchemySubM);
    // also close the desktop version as we are calling the same method on both
    closeOurAlchemyMenu();
  };
  const handleAboutSubM = () => {
    setOpenAboutSubM(!openAboutSubM);
    // also close the desktop version as we are calling the same method on both
    closeAboutMenu();
  };

  const handleInsightsSubM = () => {
    setOpenInsightsSubM(!openInsightsSubM);
    // also close the desktop version as we are calling the same method on both
    closeInsightsMenu();
  };
  const handleCareersSubM = () => {
    setOpenCareersSubM(!openCareersSubM);
    // also close the desktop version as we are calling the same method on both
    closeCareersMenu();
  };

  const handleAlchemySubM = () => {
    setOpenAlchemySubM(!openAlchemySubM);
    // also close the desktop version as we are calling the same method on both
    closeAlchemyMenu();
  };



  const closeDesktopSubMenu = () => {
    closeAboutMenu();
    closeWWUsMenuMenu();
    closeInsightsMenu();
    setOpenMobileSubmenu(false);
  };


  const closeMobileSubMenu = () => {
    document.body.className = ""
    setOpenMobileSubmenu(false);
  };

  let track1 = 0;
  const [showShadow, setShowShadow] = useState(false);
  const [isFixed, setFixed] = React.useState(false);


  const bodyPosition = () => {
    document.body.className = "";

  }

  const render = () => {
    if (props.location.pathname.includes('/transformative-healthcare-markets')) {
      return (
        <React.Fragment>
          <NavHeader thm={true}>
            <NavContainer className="main nav thmNavCont">
              <img src="./images/small-rc-logo.png" alt="Real Chemistry Logo" width="19" style={{ width: '19px' }} />
              <TitleSm >
                <a href="/"> REAL CHEMISTRY</a> <span style={{ fontWeight: '700' }}>/ TRANSFORMATIVE HEALTHCARE MARKETS</span>
              </TitleSm>
            </NavContainer>
          </NavHeader>
        </React.Fragment>
      )
    } else {

      return (
        <React.Fragment>
          <NavHeader
            showShadow={showShadow}
            open={openMobileSubmenu}
            className={blackHeader || isErrorPage ? "black-header" : ""}
            isFixed={isFixed}
          >
            <NavContainer className="main nav">
              <NavList
                logoAniComplete={logoAniComplete}
                paddingLeft={"0"}
                open={openMobileSubmenu}
                className={openMobileSubmenu ? "navSlide" : ""}
              >
                <NavItem onClick={() => bodyPosition()}>
                  <NavBrand aria-label="Return to home page" to="/" onClick={closeDesktopSubMenu}>
                    {" "}
                    {isErrorPage ? (
                      <LogoDesktop aria-label="Return to home page" onClick={() => props.history.push("/")} src="/images/logo/logo-new-white.svg" alt="Real Chemistry logo" />
                    ) : (
                      <LogoDesktop src="/images/logo/logo-new-black.svg" alt="Real Chemistry logo" />
                    )}{" "}
                  </NavBrand>{" "}
                </NavItem>{" "}
                {/* <NavItem */}
                {/*   onMouseEnter={openAboutDropdown} */}
                {/*   onMouseLeave={closeAboutDropdown} */}
                {/* > */}
                {/*   <MenuLink */}
                {/*     activeClassName="active" */}
                {/*     to={`${aboutData ? aboutData.destination : "/about"}`} */}
                {/*   > */}
                {/*     {aboutData?.label} */}
                {/*     {aboutData?.label && ( */}
                {/*       <img src="/images/black-cone.png" width="13px" className="navCone" alt="Dropdown" /> */}
                {/*     )} */}
                {/*   </MenuLink> */}
                {/*   <DropdownIcon */}
                {/*     changeDeg={showAboutDropdown} */}
                {/*     onClick={() => setShowAboutDropdown(!showAboutDropdown)} */}
                {/*     src="/images/Dropdown.svg" */}
                {/*     alt="Dropdown to sub menu" */}
                {/*   /> */}
                {/*   {showAboutDropdown && ( */}
                {/*     <div className="dropdown-menu"> */}
                {/*       <MenuLink to="/about/history">History</MenuLink> */}
                {/*       <MenuLink to="/about/team">Team</MenuLink> */}
                {/*       <MenuLink to="/about/contact">Contact</MenuLink> */}
                {/*     </div> */}
                {/*   )} */}
                {/* </NavItem>{" "} */}
                <NavItem
                  onClick={handleAboutSubM}
                  onMouseEnter={openAboutMenu}
                  onMouseLeave={closeAboutMenu}
                >
                  <MenuLink
                    activeClassName="active"
                    to={`${window.innerWidth > 991 ? (aboutData ? aboutData.destination : "/about") : '#'}`}
                  >
                    {aboutData?.label}{" "}
                    {aboutData?.label && (
                      <img src="/images/black-cone.png" width="13px" className={'navCone'} alt="Dropdown" />
                    )}

                  </MenuLink>{" "}
                  <DropdownIcon
                    changeDeg={openAboutSubM}
                    onClick={() => handleAboutSubM()}
                    src="/images/Dropdown.svg"
                    alt="Dropdown to sub menu"
                  />
                  <AboutUsMobSubMenu
                    open={openAboutSubM}
                    data={aboutData}
                    close={closeMobileSubMenu}
                  />
                </NavItem>

                <NavItem
                  onClick={handleAlchemySubM}
                  onMouseEnter={openAlchemyMenu}
                  onMouseLeave={closeAlchemyMenu}
                >
                  <MenuLink
                    activeClassName="active"
                    to={`${window.innerWidth > 991 ? (ourAlchemySubmenuData ? ourAlchemySubmenuData.destination : "/what-we-do") : '#'}`}
                  >
                    {ourAlchemySubmenuData?.label}{" "}
                    {ourAlchemySubmenuData?.label && (
                      <img src="/images/black-cone.png" width="13px" className="navCone" alt="Dropdown" />
                    )}
                  </MenuLink>{" "}
                  <DropdownIcon
                    changeDeg={openAlchemySubM}
                    onClick={() => handleAlchemySubM()}
                    src="/images/Dropdown.svg"
                    alt="Dropdown to sub menu"
                  />
                  <CareersMobSubMenu
                    open={openAlchemySubM}
                    data={ourAlchemySubmenuData}
                    close={closeMobileSubMenu}
                  />
                </NavItem>

                <NavItem
                  onClick={closeDesktopSubMenu}
                >
                  <MenuLink
                    activeClassName="active"
                    to="/news-events"
                  >
                    {insightsSubMenuData?.label}{" "}
                  </MenuLink>{" "}

                </NavItem>
                <NavItem
                  onClick={handleCareersSubM}
                  onMouseEnter={openCareersMenu}
                  onMouseLeave={closeCareersMenu}
                >
                  <MenuLink
                    activeClassName="active"
                    to={`${window.innerWidth > 991 ? (careersSubMenuData ? careersSubMenuData.destination : "/careers") : '#'}`}
                  >
                    {careersSubMenuData?.label}{" "}
                    {careersSubMenuData?.label && (
                      <img src="/images/black-cone.png" width="13px" className={'navCone'} alt="Dropdown" />
                    )}

                  </MenuLink>{" "}
                  <DropdownIcon
                    changeDeg={openCareersSubM}
                    onClick={() => handleCareersSubM()}
                    src="/images/Dropdown.svg"
                    alt="Dropdown to sub menu"
                  />
                  <CareersMobSubMenu
                    open={openCareersSubM}
                    data={careersSubMenuData}
                    close={closeMobileSubMenu}
                  />
                </NavItem>
                <NavItem
                  onClick={closeDesktopSubMenu}
                >
                  <MenuLink
                    activeClassName="active"
                    to="/contact"
                  >
                    {contactUsMenuData?.label}{" "}
                  </MenuLink>{" "}

                </NavItem>
              </NavList>


              {/* Sub Menu */}
              <CareersMenu open={showCareersMenu} data={careersSubMenuData} />
              <AboutUsMenu open={showAboutMenu} data={aboutData} />
              <AlchemyMenu open={showOurAlchemyMenu} data={ourAlchemySubmenuData} />


              {/* Menu Btn */}
              <MenuBtnBg>
                <a href="/"><img src="/images/logo/logo.png" className="mobile-logo" alt="Real Chemistry logo" /></a>
                <MenuButton
                  className={openMobileSubmenu ? "btnToggle mobile menu open" : "mobile menu open"}
                  onClick={handleMobileSubmenu}
                >
                  <BtnLine> </BtnLine> <BtnLine> </BtnLine>{" "}
                </MenuButton>{" "}
              </MenuBtnBg>{" "}
            </NavContainer>{" "}
          </NavHeader>
        </React.Fragment>
      )
    }

  }

  if (!props.location.pathname.includes('/content-preview/'))
    return render()

  return <React.Fragment />
}

export default withRouter(Header);
