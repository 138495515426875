import React from "react";

import {
  TitleXl,
  BodyRg,
  TitleSm,
  Bold,
  SectionTitle,
} from "components/elements";

import {
  ScamAlertWrap,
  ScamAlertContainer,
  UnorderedList,
  UnorderedListItem,
  AnchorLink,
} from "./elements";

function ScamAlertDetail() {
  return (
    <ScamAlertWrap>
      <ScamAlertContainer>
        <SectionTitle textAlign="center">
          <TitleXl textAlign="center">ALERT: ONLINE RECRUITING SCAMS</TitleXl>
        </SectionTitle>

        <BodyRg>
          In our new ever-online world, we’ve seen an alarming uptick in online scammers who pose as job recruiters to take advantage of potential applicants.  Real Chemistry has been informed that scammers are fraudulently using its name on unassociated job sites. Therefore, we ask that you please protect your personal information and prevent fraud by validating where recruiting communications are coming from.
        </BodyRg>

        <TitleSm>
          Real Chemistry’s Recruiting Practice:
        </TitleSm>

        <UnorderedList>
          <UnorderedListItem>
            <Bold>Real Chemistry has a formal application process that requires all applicants to apply on our website (<AnchorLink href='/'>www.realchemistry.com</AnchorLink>).</Bold>
          </UnorderedListItem>

          <UnorderedListItem>
            <Bold>Real Chemistry will always require at least one interview with a Talent Acquisition representative, either in person or using video conferencing technology like Zoom.</Bold>
          </UnorderedListItem>

          <UnorderedListItem>
            <Bold>Real Chemistry does not hire candidates after only text interviews (chatrooms, emails, etc). Our recruiting process involves phone and Zoom interviews as well prior to extending a job offer to candidates.</Bold>
          </UnorderedListItem>

          <UnorderedListItem>
            <Bold>Real Chemistry will never require you to make a payment, application fee, or need any other financial information in the application process. Real Chemistry will never require you to transfer funds or pay a third party.</Bold>
          </UnorderedListItem>
        </UnorderedList>

        <BodyRg>
          We understand such scammers can disguise themselves, so we have outlined a few tips below to help distinguish whether the recruiter communications is coming from Real Chemistry or is a scammer.
        </BodyRg>

        <TitleSm>
          Do Not Trust If...
        </TitleSm>

        <UnorderedList>
          <UnorderedListItem>
            You are contacted by someone claiming to be from Real Chemistry on a third-party platform other than LinkedIn
          </UnorderedListItem>

          <UnorderedListItem>
            You are contacted by someone claiming to be from Real Chemistry, but the communication is from a non-Real Chemistry email address (ie. Gmail.com, Yahoo.com, Aol.com, Hotmail.com)
          </UnorderedListItem>

          <UnorderedListItem>
            You did not apply for a job at Real Chemistry but immediately received an employment offer (with no interview)
          </UnorderedListItem>

          <UnorderedListItem>
            You receive an unsolicited employment contract from Real Chemistry
          </UnorderedListItem>

          <UnorderedListItem>
            The communication has poor grammar and significant misspellings
          </UnorderedListItem>

          <UnorderedListItem>
            All your interviews were over text only (chatrooms, email).
          </UnorderedListItem>

          <UnorderedListItem>
            You are asked to provide sensitive confidential information such as your Social Security Number or bank account numbers
          </UnorderedListItem>

          <UnorderedListItem>
            You are told you must pay an application fee, transfer funds, make a payment to a third party or purchase any equipment
          </UnorderedListItem>
        </UnorderedList>

        <TitleSm>
          Additional Resources:
        </TitleSm>

        <BodyRg>
          The Federal Trade Commission closely monitors online scamming and provides consumers with free information on 1) how to avoid being scammed and 2) what to do if you’re a victim of a scam. For more information, please visit: <AnchorLink href='https://www.consumer.ftc.gov/' target='_blank' className='external'>https://www.consumer.ftc.gov/</AnchorLink>.  If you believe you have been the victim of an online scam, you can also report the crime through the Federal Bureau of Investigation’s Internet Crime Complaint Center at <AnchorLink href='https://www.ic3.gov/' target='_blank' className='external'>www.ic3.gov</AnchorLink>.
        </BodyRg>

        <TitleSm>
          Questions? Ask Us!
        </TitleSm>

        <BodyRg>
          Take the guesswork out of whether or not the recruiting communication you’re receiving is authentic. Simply reach out to us and we will confirm whether the communication you have received is from Real Chemistry’s recruiting team. Please contact us at <AnchorLink href='mailto:tasupport@realchemistry.com' target='_blank'>tasupport@realchemistry.com</AnchorLink> directly.
        </BodyRg>
      </ScamAlertContainer>
    </ScamAlertWrap>
  );
}

export default ScamAlertDetail;
