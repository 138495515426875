import React from 'react'

import
{
  BodySm,
  Col6,
  Row,
  TitleMd
} from 'components/elements'

import {CtaWrapper} from './elements'
import RichText from 'components/RichText'

function CallToAction({label, title, body, ctaLink, ctaText}) {
  return (
    <CtaWrapper>
      <BodySm margin={'0 0 20px 0'} textTransform="uppercase">{label}</BodySm>
      <Row>

        <Col6 padding={'0 50px 0 0 '}>

          <TitleMd textTransform={'uppercase'}>{title}</TitleMd>
        </Col6>
        <Col6>
          <RichText body={body}/>
        </Col6>
      </Row>
    </CtaWrapper>
  )
}

export default CallToAction

