import styled, {css} from 'styled-components';
import {NavLink, Link} from 'react-router-dom';
import {
    BodySm,
    OverlayBase,
    BodyRg,
    TitleSm,
} from "components/elements";

import {
    sizeWideScreen,
    sizeXlDevice,
    sizeLaptopLg,
    sizeLaptopMd,
    sizeTabletLg,
    sizeDesktopSm,
    sizeMobileLg,
    sizeTablet
} from "components/devices";


export const NavContainer = styled.div`
  padding: 0;
  &.thmNavCont{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 30px 0 30px 50px;
    ${sizeTablet(css`
      padding: 20px 15px;
    `)}
  }
    ${TitleSm}{
      font-size: 14px;
      padding-left: 5px;
      font-family: Helvetica;
      font-weight: 300;
      letter-spacing: 0.13rem;
      a {
        color: #000000;
        text-decoration: none;
        cursor: pointer;
      }
   }
  ${sizeTabletLg(css`
    text-align: center;
  `)} /* WideScreen CSS */ ${sizeWideScreen(`
        position: relative;
        max-width: 2500px;
        margin: 0 auto;
    `)}
`;

export const NavItem = styled.li`
  position: relative;
  transition: 0.4s all ease;

  &:first-child {
    display: ${({isHomePage}) => isHomePage ? "none" : "block"};
    padding-left: 22px;
    ${sizeTabletLg(css`
      display: block;
      position: static;
      width: 100%;
      opacity: 1;
      border-bottom: none;
      padding-left: 0;
    `)}
  }

  &:last-child {
    padding-right: 22px;
    ${sizeTabletLg(css`
      padding-right: 0;
    `)}
  }

  ${sizeTabletLg(css`
    border-bottom: 1px solid #707070;
    width: 100%;
    box-sizing: border-box;
    background-color: #FFF;
  `)}

`;
export const NavBrand = styled(NavLink)`
  display: block;
  ${sizeTabletLg(css`
    display: block;
  `)}
`;
export const LogoDesktop = styled.img`

  width: 216px;
  height: 36px;
  display: block;
  ${sizeTabletLg(css`
    display: none;
  `)} /* Wide Screen CSS */ ${sizeWideScreen(`
        width: 397px;
        height: 67px;
    `)}
`;

export const LogoMobile = styled.img`
  max-width: 480px;
  width: 100%;
  margin: 0 auto;
  display: none;
  ${sizeTabletLg(css`
    display: block;
  `)}
`;

export const MenuLink = styled(NavLink)`
  color: #000;
  font-size: 1em;
  font-weight: 500;
  position: relative;
  transition: .5s all ease;
  padding: 27px 45px;
  display: inline-block;
  text-transform: uppercase;
  box-sizing: border-box;
  height: 80px;
  display: flex;
  align-items: center;
  .navCone {
    margin-left: 15px;
    transform: rotate(180deg);
    ${sizeTabletLg(css`
      display: none;
  `)}
  }

  &:before {
    content: "";
    width: 100%;
    height: 10px;
    background-color: #D12FFF;
    display: inline-block;
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0;
    transition: 0.4s all ease;
    ${sizeTabletLg(css`
      content: none;
    `)}
  }

  &:hover {
    .navCone {
      transform: rotate(0);
    }
    &:before {
      opacity: 1;
      ${sizeTabletLg(css`
        content: none;
      `)}
    }
  }

  &.${props => props.activeClassName} {
    &:before {
      opacity: 1;
      ${sizeTabletLg(css`
        content: none;
      `)}
    }
  }

  ${sizeDesktopSm(css`
    padding-left: 30px;
    padding-right: 30px;
  `)}
  ${sizeLaptopLg(css`
    padding: 30px;
  `)}
  ${sizeLaptopMd(css`
    padding: 30px 15px;
  `)}
  ${sizeTabletLg(css`
    color: #000 !important;
    padding: 25px 15px;
    display: block;
    width: 100%;
    margin-left: 10px;
    opacity: 0;
    transition: .6s all ease;
  `)} /* WideScreen CSS */ ${sizeWideScreen(`
        font-size: 1.5em;
        height: 130px;
        display: flex;
        align-items: center;
        justify-content: center;
    `)}
`;
export const DropdownIcon = styled.img`
  position: absolute;
  right: 20px;
  top: 28px;
  width: 24px;
  height: 24px;
  display: none;
  transition: 0.3s all ease;
  transform: ${({changeDeg}) => changeDeg ? "rotate(90deg)" : "rotate(0)"};
  ${sizeTabletLg(css`
    display: block;
    right: 30px;
    opacity: 0;
    transition: .4s all ease;
  `)}
`;


export const NavList = styled.ul`
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-transition: all .2s ease;
  transition: all .2s ease;
  -webkit-transition-timing-function: ease-in-out;
  transition-timing-function: ease-in-out;
  width: 100%;
    /* padding-left: ${({paddingLeft}) => paddingLeft > 210 ? `330px` : `${paddingLeft}px`}; */
  padding-left: ${({paddingLeft}) => `${paddingLeft}px`};
  box-sizing: border-box;

  ${sizeTabletLg(css`
    display: block;
    background: #fff;
    position: fixed;
    left: 0;
    top: ${({open}) => open ? "69px" : "-120vh"};
    z-index: 100;
    width: 100%;
    height: calc(100vh - 68px);
    overflow-y: scroll;
    backdrop-filter: blur(12px);
    background-color: transparent;
    padding-left: 0 !important;
    transition: .7s all ease;
    padding-bottom: 110px;
  `)}
  &.navSlide {
    ${sizeTabletLg(css`
      ${MenuLink},
      ${DropdownIcon} {
        margin-left: 0;
        opacity: 1;
        transition-delay: .6s;
      }

      ${DropdownIcon} {
        right: 20px;
      }
    `)}
  }
`;


// Logo CSS
export const HeroLogo = styled(NavLink)`
  position: absolute;
  left: 0;
  width: 1200px;
  top: 289px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;

  // For 4k Device
  ${sizeXlDevice(`
        transform: scale(1.22);
        left: 5%;
    `)}

  ${sizeDesktopSm(`
        top: 289px
    `)}
  ${sizeLaptopLg(`
        top: 285px;
        width: 940px;
    `)}
  ${sizeTabletLg(`
        display: none;
    `)} /* Wide Screen CSS */ ${sizeWideScreen(`
        width: 1590px;
        top: 366px;
    `)}
`;

export const LogoLg = styled.img`
  width: 100%;
`;

const Logo = styled.svg`
  height: 200px;
  transition: 0.5s all ease;
  fill: #000;
  ${sizeLaptopLg(css`
    height: 160px;
  `)} /* Wide Screen CSS */ ${sizeWideScreen(`
        height: 270px;
    `)}
`;

export const LogoReal = styled(Logo)`
  width: 406px;
  ${sizeLaptopLg(css`
    width: 323px;
  `)} /* Wide Screen CSS */ ${sizeWideScreen(`
        width: 547px;
    `)}
`;

export const LogoChemistry = styled(Logo)`
  width: 790px;
    // margin-top: ${({logoAniComplete}) => logoAniComplete ? "-16px" : "-3px"}; 
  margin-left: ${({marginLeft}) => `${marginLeft}px`};
  margin-top: ${({
                   marginTop,
                   logoAniComplete,
                   maxTopMargin
                 }) => (logoAniComplete) ? maxTopMargin + 'px' : marginTop + 'px'};
  ${sizeLaptopLg(css`
    width: 632px;
  `)} /* Wide Screen CSS */ ${sizeWideScreen(`
        width: 1070px;
        margin-left: 520px !important;
    `)}
`;


// Mobile HEADER CSS
export const MenuBtnBg = styled.div`
  width: 100%;
  height: 70px;
  background-color: #FFF;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 100;
  justify-content: space-between;
  align-items: flex-end;
  display: none;
  border-bottom: 1px solid #707070;
  padding: 15px 25px;
  ${sizeTabletLg(css`
    display: flex;
  `)}
  > a {
    display: flex;
  }
  .mobile-logo {
    width: 130px;
    height: auto;
  }
`;
export const BtnLine = styled.span`
  height: 4px;
  width: 100%;
  background-color: #000;
  margin: 10px 0;
  display: block;
  position: relative;
  transition: 0.5s all ease;
  transition-timing-function: ease-in-out;
  transform: rotate(0);
`;
export const MenuButton = styled.div`
  width: 45px;
  transition: 0.5s all ease;
  transition-timing-function: ease-in-out;

  &.btnToggle {
    transform: scale(0.75);
    transition: 0.5s all ease;
    transition-timing-function: ease-in-out;

    ${BtnLine} {
      &:first-child {
        transform: rotate(135deg);
        top: 7px;
      }

      &:nth-child(2) {
        transform: rotate(-135deg);
        top: -7px;
      }
    }
  }
`;


// Header
export const NavHeader = styled.header`
  background-color: ${({open}) => open ? "#fff" : "#fff"};
  height: 80px;
  position: fixed;
  left: 0;
  transform: ${(props) => props.isFixed ? "translateY(-120vh)" : "translateY(0)"};
  top: 0;
  width: 100%;
  z-index: 999999;
  transition: .5s all ease;
  -webkit-box-shadow: ${({showShadow}) => showShadow ? "0px 0px 10px 0px rgba(0,0,0,0.2)" : ""};
  -moz-box-shadow: ${({showShadow}) => showShadow ? "0px 0px 10px 0px rgba(0,0,0,0.2)" : ""};
  box-shadow: ${({showShadow}) => showShadow ? "0px 0px 10px 0px rgba(0,0,0,0.2)" : ""};

  ${sizeTabletLg(css`
    height: ${({thm}) => thm ? "80px" : "64px"};

  `)}
  path, line, polygon {
    fill: #000;
  }

  // White Header CSS
  &.black-header {
    background-color: #000;
    box-shadow: none;

    ${MenuLink} {
      color: #fff;
    }

    ${LogoChemistry},
    ${LogoReal} {
      fill: #fff;
    }

    path, line, polygon {
      fill: #FFF;
    }

    ${MenuBtnBg} {
      background-color: ${({open}) => open ? "#fff" : "#000"};
      border-color: ${({open}) => open ? "#707070" : "transparent"}
    }

    ${BtnLine} {
      // background-color: #fff;
      background-color: ${({open}) => open ? "#000" : "#fff"};
    }
  }

  &.headerFixed {
    position: fixed;
  }


  /* WideScreen CSS */
  ${sizeWideScreen(`
        height: 132px;
    `)}
`;

// Sub Menu Style
export const RightGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
`;
// Page Info Card Style
export const PageLink = styled.div`
  font-size: 1em;
  text-transform: uppercase;
  color: #000;
  cursor: pointer;
  display: inline-block;
  transition: 0.8s all ease;

  /* WideScreen CSS */
  ${sizeWideScreen(`
        font-size: 0.5vw;
    `)}
`;
export const PageInfo = styled(Link)`
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  position: relative;
    /* grid-column-end: ${({cols}) => `span ${cols}` || "span 1"};
    grid-row-end: ${({rows}) => `span ${rows}` || "span 1"}; */
  width: 100%;
  height: 100%;
  box-sizing: border-box;
margin-bottom: -1px;

  ${BodyRg} {
    margin-bottom: 0.938;
    transition: 0.9s all ease;

    /* WideScreen CSS */
    ${sizeWideScreen(`
            font-size: 0.7vw;
        `)}
  }

  ${BodySm} {
    font-size: 1.1251em;
    margin-bottom: 15px;
    text-transform: inherit;
    transition: 0.9s all ease;

    /* WideScreen CSS */
    ${sizeWideScreen(`
            font-size: 0.5vw;
        `)}
  }

  &:before {
    content: "";
    background-color: ${({backgroundColor}) => backgroundColor || "#D12FFF"};
    width: 0;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    z-index: -1;
    transition: 0.5s all ease;
  }

  &:hover {
    &:before {
      width: 100%;
    }

    & * {
      color: #fff;
    }
  }
`;
export const InfoContainer = styled.div`
  padding: 30px 30px;
  width: 100%;
`;


// Content Type Card Style
export const TypeContainer = styled.div`
  text-decoration: none;
  color: #000;
  padding: 15px;
  max-width: 340px;
  width: 100%;
  ${sizeWideScreen(`
        max-width: 77%;
    `)}
`;
export const ContentHeadline = styled.div`
  font-size: 1rem;
  font-weight: 400;
  margin-bottom: 15px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  line-height: 1.2;
  position: relative;
  padding-left: 40px;
  -webkit-transition: 0.9s all ease;
  transition: 0.9s all ease;
`;

export const ArrowIcon = styled.img`
  position: absolute;
  left: 0;
  top: 45%;
  transform: translateY(-50%);
  visibility: visible;
  transition: 0.9s all ease;
`;

export const ArrowIconWhite = styled(ArrowIcon)`
  visibility: hidden;
  transition: 0.9s all ease;
`;
export const ContentDesc = styled.div`
  padding-left: 40px;
  transition: 0.9s all ease;


  /* WideScreen CSS */
  ${sizeWideScreen(`
        font-size: 0.55vw;
    `)}
`;

export const ContentType = styled(Link)`
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  border-top: ${({borderTop}) => borderTop || "none"};
  border-right: ${({borderRight}) => borderRight || "none"};
  border-bottom: ${({borderBottom}) => borderBottom || "1px solid #7E7E7E"};
  border-left: ${({borderLeft}) => borderLeft || "1px solid #7E7E7E"};
  z-index: 1;
  position: relative;
  overflow: hidden;
    // grid-column-end: ${({cols}) => `span ${cols}` || "span 1"};
    // grid-row-end: ${({rows}) => `span ${rows}` || "span 1"};
  min-height:  ${({minHeight}) => minHeight || "160px"};
  margin-bottom: -1px;

  ${sizeWideScreen(`
        min-height: 200px;
    `)}
  &:before {
    content: "";
    background-color: ${({backgroundColor}) => backgroundColor || "#D12FFF"};
    width: 0;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    z-index: -1;
    transition: 0.5s all ease;
  }

  &:hover {
    &:before {
      width: 100%;
    }

    & * {
      color: #fff;
    }

    ${ArrowIcon} {
      visibility: hidden;
    }

    ${ArrowIconWhite} {
      visibility: visible;
    }
  }

  ${BodySm} {
    ${sizeWideScreen(`
            font-size: 0.5vw;
        `)}
  }

  ${ContentHeadline} {
    ${sizeWideScreen(`
            font-size: 0.6vw;
        `)}
  }
`;

// Type Card Style
export const LinkCard = styled(Link)`
  position: absolute;
  top: 0.75;
  right: 15px;
`;
export const DirectionArrow = styled.img`
  transform: rotate(-50deg);
  transition: 0.9s all ease;
  position: absolute;
  right: 0;
  top: 0;
  visibility: visible;
`;
export const DirectionArrowWhite = styled(DirectionArrow)`
  visibility: hidden;
`;

export const TypeCardWrapper = styled(OverlayBase)`
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  border-top: ${({borderTop}) => borderTop || "none"};
  border-right: ${({borderRight}) => borderRight || "none"};
  border-bottom: ${({borderBottom}) => borderBottom || "1px solid #7E7E7E"};
  border-left: ${({borderLeft}) => borderLeft || "1px solid #7E7E7E"};
  z-index: 1;
  position: relative;
  overflow: hidden;
  grid-column-end: ${({cols}) => `span ${cols}` || "span 1"};
  grid-row-end: ${({rows}) => `span ${rows}` || "span 1"};
  min-height: 60px;

  ${BodySm} {
    transition: 0.9s all ease;
    ${sizeWideScreen(`
            font-size: 0.5vw;
        `)}
  }

  &:hover {
    &:before {
      width: 100%;
      transition: 0.3s all ease;
    }

    & * {
      color: #fff;
    }

    ${DirectionArrow} {
      visibility: hidden;
    }

    ${DirectionArrowWhite} {
      visibility: visible;
    }
  }
`;
export const SmallCardContainer = styled.div`

`;


export const SubMenu = styled.div`
  position: fixed;
  left: 0;
  top: 80px;
  background-color: #fff;
  width: 100%;
  display: ${props => props.showSubMenu ? "flex" : "flex"};
  overflow: auto;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  transition: height 1s ease, max-height 1s ease;
  /* grid-template-columns: repeat(auto-fill, minmax(15%, 1fr));
  grid-auto-rows: minmax(60px, auto); */
  grid-auto-flow: dense;
  -webkit-box-shadow: 0px 0px 7px 0px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0px 0px 7px 0px rgba(0, 0, 0, 0.2);
  box-shadow: 0px 0px 7px 0px rgba(0, 0, 0, 0.2);

  max-height: 0;
  height: ${props => props.showSubMenu ? "auto" : "auto"};
  max-height: ${props => props.showSubMenu ? "calc(100vh - 80px)" : "0"};

  transition-delay: 250ms;
  transition-property: height, max-height;
  transition: all 0.5s ease-in-out;

  /* wide Screen */
  max-width: 2500px;
  left: 50%;
  transform: translateX(-50%);

  &::-webkit-scrollbar {
    display: none;
  }

  &:hover {
    opacity: 1;
    transition: max-height .5s;
    max-height: calc(100vh - 80px);
  }

  ${sizeTabletLg(css`
    display: none;
  `)}

  ${sizeWideScreen(`
        top: 132px;
    `)}
`;

export const grid12 = {
    // width: "100%",
    // flex: "0 0 100%",
    // maxWidth: "100%",
};
export const grid4 = {
    // width: "100%",
    // flex: "0 0 33.333333%",
    // maxWidth: "33.333333%",
};
export const grid2 = {
    // width: "100%",
    // flex: "0 0 16.5%",
    // maxWidth: "16.5%",
};


// Empty Grid
export const EmptyGrid = styled.div`
    // grid-column-end: ${({cols}) => `span ${cols}` || "span 1"};
    // grid-row-end: ${({rows}) => `span ${rows}` || "span 1"};
  border-left: 1px solid #7E7E7E;
  min-height: 242px;
`;


// Mobile HEADER CSS
export const NavBrandMobile = styled(Link)`
  display: inline-block;
  text-align: center;
  height: 65px;
  display: none;

  ${LogoMobile} {
    width: 100%;
  }

  ${sizeTabletLg(css`
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  `)}
`;


// Mobile Sub Menu CSS Start
export const SubMenuMobile = styled.div`
  position: relative;
  left: 0;
  top: 0;
  width: 100%;
  background-color: #000;
  font-size: 20px;
  display: none;
  ${sizeTabletLg(css`
    display: ${({open}) => open ? "block" : "none"};
  `)}
`;

export const SubList = styled.div`

`;
export const MoreInfoIcon = styled.img`
  position: absolute;
  left: 15px;
  top: 20px;
`;
export const SubLink = styled(Link)`
  text-align: center;
  padding: 25px 15px;
  color: #fff;
  position: relative;
  display: block;
  width: 100%;
  box-sizing: border-box;
  border-bottom: 1px solid #707070;

  &:first-child {
    text-transform: uppercase;

    ${MoreInfoIcon} {
      display: none;
    }
  }

  &:last-child {
    border-bottom: none;
  }
`;
export const ItemText = styled.p`
  ${sizeMobileLg(`
    font-size: 16px;
  `)}

`;


