import React, {useState} from 'react'
import styled from 'styled-components'
import {BodyRg, TitleLg} from "./elements";
import PlusIconSvg from "../assets/icons/PlusIconSvg";
import BackArrowSvg from "../assets/icons/BackArrowSvg";
import {Link} from "react-router-dom";
import RichText from "./RichText";
import {sizeLaptop, sizeMobileLg, sizeTabletLg} from "./devices";


export const DaHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #F0F0F0;
  cursor: pointer;
  transition: 0.25s all ease;
  .plus-icon {
    path {
      stroke: #D12FFF;
    }
  }


  // &:hover {
  //   .plus-icon {
  //     path {
  //       stroke: #fff;
  //     }
  //   }
  // }
`;
export const DaBody = styled.div`
  padding: 40px 0 0 0;
  display: none;
  a {
    color: #000;
    &:hover {
      color: #D12FFF;
    }
  }

  ${BodyRg} {
    color: #000;
    font-size: 20px;
    line-height: 24px;
    margin-bottom: 28px;
    ${sizeMobileLg(`
      font-size: 16px;
      line-height: 19px;
  `)}
  }
`;
export const AccLink = styled(Link)`
  display: flex;
  align-items: center;
  color: #fff;
  font-size: 32px;
  line-height: 38px;
  text-transform: uppercase;

  ${sizeLaptop(`
        font-size: 26px;
  `)}
  ${sizeMobileLg(`
        font-size: 20px;
  `)}
  .arrow-icon {
    transform: rotate(180deg);
    width: 70px;
    height: 39px;
    margin-left: 24px;

    ${sizeLaptop(`
        width: 50px;
        height: 30px;
        margin-left: 15px;
  ` )}
    line, path {
      stroke: #fff;
    }
  }
  .arrow-icon {
    transition: 0.25s all ease;
    position: relative;
    left: 0;
    line, path {
      stroke: #000;
    }
  }

  &:hover {
    color: #0F20FF;
    .arrow-icon {
      left: 10px;
      line, path {
        stroke: #D12FFF;
      }
    }
  }
`;
export const DarkAccordionBlock = styled.div`
  background-color: #F0F0F0;
  padding: 64px 104px;
  margin: ${({margin}) => margin || "0"};
  &:last-child {
    margin-bottom: 0 !important;
  }

  ${sizeLaptop(`
    padding: 35px 25px;
  `)}
  ${sizeTabletLg(`
    padding: 25px 25px;
    margin-bottom: 15px;
  `)}
  ${TitleLg} {
    color: #000;
    font-weight: 400;
    font-size: 48px;
    line-height: 57px;
    ${sizeMobileLg(`
        font-size: 20px;
        line-height: 24px;
    `)}
  }

  .plus-icon {
    width: 68px;
    height: 68px;
    transition: 0.35s all ease;
    transform: rotate(0);

    ${sizeLaptop(`
        width: 45px;
        height: 45px;
  `)}
    path {
      stroke: #D12FFF;
    }
  }

  &.show {
    ${DaBody} {
      display: block
    }

    .plus-icon {
      transform: rotate(45deg);
    }
  }
`;


function DarkAccordion({mainTitle, richText, ctaLink, ctaText, margin}) {
    const [acc, setAcc] = useState(false);

    const handleAcc = () => {
        setAcc(!acc);
    }


    return (
        <DarkAccordionBlock className={acc ? 'show' : ''} margin={margin}>
            <DaHeader onClick={handleAcc}>
                <TitleLg>{mainTitle}</TitleLg>
                <PlusIconSvg className={'plus-icon'}/>
            </DaHeader>
            <DaBody>
                {richText ? <RichText body={richText} options={{color: '#fff'}}/> : ''}
                {
                    ctaLink && <AccLink to={ctaLink ? ctaLink : '#'}>
                        {ctaText}
                        <BackArrowSvg className={'arrow-icon'}/>
                    </AccLink>
                }
            </DaBody>
        </DarkAccordionBlock>
    )
}

export default DarkAccordion;
