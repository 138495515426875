import styled from 'styled-components';

import {

    sizeTabletLg
} from "components/devices";
import {
    TitleXs
} from "components/elements";


export const PlayMarqueeWrap = styled.div`
  overflow: hidden;
  opacity: ${({ opacity }) => opacity};
    position: absolute;
    left: 0;
    bottom: 32px;
    width:100%;
    padding-top: 0;
    z-index: 555;
    mix-blend-mode: difference;
    &:hover {
        mix-blend-mode: unset;
    }
    ${sizeTabletLg(`
        bottom: 20px;
    `)};
`;

export const PlayArrow = styled.svg`
   margin-right: 32px;
   flex: 1 0 49px;
`;

export const MarqueeRow = styled.div`
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-transition: .5s all ease;
    transition: .5s all ease;
    position: relative;
    cursor: pointer;
`;
export const MarqueeCol = styled.div`
   display: flex;
    align-items: center;
    padding-right: 32px;
`;

export const Marquee = styled.div`
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    overflow: hidden;
    width: 100%;
    ${TitleXs} {
        white-space: nowrap;
    }
    &:hover {
        ${TitleXs} {
            color: #D12FFF;
        }
        ${PlayArrow} {
            g,line,path{
                stroke:#D12FFF;
            }
            
        }
       
    }
`;
