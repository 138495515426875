import React from 'react'


function PodcastIcon() {

    return (
        <svg viewBox="0 0 48 48" xmlns="http://www.w3.org/2000/svg">
            <path d="m33.5 31h-19l-1.5-1.5v-26l1.5-1.5h19l1.5 1.5v26zm-17.5-3h16v-23h-16z"/>
            <path d="m24.18 40.49h-.36l-16.99-2-1.33-1.49v-18h3v16.67l15.5 1.82 15.5-1.82v-16.67h3v18l-1.33 1.49z"/>
            <path d="m22.5 39h3v6h-3z"/>
            <path d="m16 43h16v3h-16z"/>
            <path d="m15 15h18v3h-18z"/>
        </svg>

    )
}

export default PodcastIcon;
