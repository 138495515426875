
import styled from 'styled-components';

import {
    BodySm,
    TitleXs,
    BodyRg
} from "components/elements";

import {
    sizeWideScreen,
    sizeLaptopMd,
    sizeTabletLg
} from "components/devices";

export const BlogCardWrap = styled.div`
  cursor: pointer;
  position: relative;
    border-top: ${({ borderTop }) => borderTop || "1px solid #707070"};
    border-right: ${({ borderRight }) => borderRight || "1px solid #707070"};
    border-bottom: ${({ borderBottom }) => borderBottom || "1px solid #707070"};
    border-left: ${({ borderLeft }) => borderLeft || "none"};
    margin: ${({ margin }) => margin || "0"};
    padding: 40px;
    -webkit-transition: 0.4s all ease;
    transition: 0.4s all ease;
  -webkit-tap-highlight-color: rgba(0,0,0,0) !important;
    ${sizeLaptopMd(`
        padding: 25px;
    `)}

    ${BodySm} {
        margin-bottom: 25px;

        /* Wide Screen CSS */
        ${sizeWideScreen(`
            font-size: 0.85vw;
        `)}
    }
    ${TitleXs} {
        margin-bottom: 25px;
        line-height: 1.1;
        font-size: 32px;
      ${sizeLaptopMd(`
        font-size: 28px;
      `)}
        ${sizeTabletLg(`
            font-size: 20px;
        `)}

        /* Wide Screen CSS */
        ${sizeWideScreen(`
            font-size: 0.7vw;
        `)}
    }
    ${BodyRg} {
        display: none;
        margin-bottom: 25px;
      ${sizeLaptopMd(`
        font-size: 28px;
      `)}
        font-size: 32px;
        ${sizeTabletLg(`
            font-size: 20px;
        `)}
        
    }

    img {
        -webkit-transition: 0.4s all ease;
        transition: 0.4s all ease;
    }
    &:hover {
        img {
            transform: scale(1.2);
        }
    }
`;
export const BlogImgWarp = styled.div`
    overflow: hidden;
    margin-bottom: 40px;
`;
export const BlogCardBottom = styled.div`
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    min-height: 250px;
    ${sizeTabletLg(`
        min-height: 150px;
    `)}
;`
export const BlogCardImg = styled.img`
    width: 100%;
    height: 260px;
    object-fit: cover;
    ${sizeTabletLg(`
        display: none !important;
    `)}
`;
export const BlogCardImgMobile = styled.img`
    display: none !important;
    margin-bottom: 40px;
    width: 100%;
    height: 270px;
    object-fit: cover;
    ${sizeTabletLg(`
        display: block !important;
    `)}
`;
export const BlogLink = styled.div`
    font-size: 16px;
    color: #000;
    text-transform: uppercase;
      //&:after {
      //  content: '';
      //  width: 100%;
      //  height: 100%;
      //  position: absolute;
      //  left: 0;
      //  top: 0;
      //  background-color: transparent;
      //}
  

    /* Wide Screen CSS */
    ${sizeWideScreen(`
        font-size: 0.6vw;
    `)}
`;
