import React from 'react';
import {withRouter} from "react-router-dom";
import { CircleContainer, Circle, CopyContainer, TitleContainer, HeaderBanner, TopContentContainer, CenterContentContainer, BottomContentContainer, CopyContainerPurple, BodyContainer} from './elements';
import { TitleXl, TitleMd, TitleSm, BodySm} from 'components/elements';


function OverlapCircles ({borderColor, textTransform, titleSize, titleLineH, header, mobileBorder, dimensionsDesktop, dimensionsMobile, subHead, bodyCopy, copyHeader, largePurpleCircles, imageSource, alt, titleMd, titleSmTop, titleSmBottom}) {

    return (
        <CircleContainer>
          {header ==="true"  && ( <HeaderBanner backgroundColor={borderColor}>{header}</HeaderBanner> )}
            <Circle borderColor={borderColor} dimensionsDesktop={dimensionsDesktop} dimensionsMobile={dimensionsMobile} mobileBorder={mobileBorder} >
                {copyHeader && (
                    <CopyContainer >
                        <TitleContainer color={borderColor} titleSize={titleSize} titleLineH={titleLineH}>
                            <TitleXl textTransform={textTransform} fontWeight="700" fontFamily="HelveticaNowDisplay">
                                {copyHeader}
                            </TitleXl>
                        </TitleContainer>
                         {subHead && (
                            
                                <TitleSm>
                                    {subHead}
                                </TitleSm>
                       
                         )}
                            {bodyCopy && (
                                <BodyContainer>
                                    <BodySm>
                                        {bodyCopy}
                                    </BodySm>
                                </BodyContainer>
                        )}
                    </CopyContainer>
                )}
                {largePurpleCircles && (
                        <CopyContainerPurple>
                           <TopContentContainer>
                               <TitleMd>
                                   {titleMd}
                               </TitleMd>
                               <TitleSm>
                                    {titleSmTop}
                               </TitleSm>
                           </TopContentContainer>
                            <CenterContentContainer>
                               <img src={imageSource} alt={alt} className="logo" />
                           </CenterContentContainer>
                           <BottomContentContainer>
                               <TitleSm >
                                    {titleSmBottom}
                               </TitleSm>
                           </BottomContentContainer>
                       </CopyContainerPurple>
                )}
    
            </Circle>
        </CircleContainer>
)
    
}

export default withRouter(OverlapCircles);
