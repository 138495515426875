
import React, { useState, useEffect } from 'react'

import {
	PopupCardsWrapper,
	PopupCardsRow,
	PopupCardsCol
} from './elements'

import {
	BodySm,
	TitleXl,
	SectionTitle
} from 'components/elements'

import PopupCard from './PopupCard'
import ViewAll from 'components/ViewAll'

function PopupCards(props)
{
	const [data, setData] = useState({});
	const [items, setItems] = useState(null);
	const [clickTrack, setTrack] = useState('');
	const [hoverTrack, setHoverTrack] = useState("");
	const [openOverlay, setOpenOverlay] = useState(false);

	useEffect(() =>
	{
		if (props?.componentData)
		{
			setData(props.componentData)
			setItems(props.componentData.items)
		}
	}, [items, props])

	useEffect(() =>
	{
		setHoverTrack(clickTrack)
	}, [clickTrack, hoverTrack])

	const onCardClick = (value) =>
	{
		setTrack(value)
	}

	const handleShowHideOverlay = (state) =>
	{
		setOpenOverlay(state)
	}

	return (
	    <PopupCardsWrapper>
		<SectionTitle>
			<BodySm>{data.label}</BodySm>
			<TitleXl>{data.title}</TitleXl>
		</SectionTitle>

		<PopupCardsRow>
			{ items && items.map((item, index) => {
			    return <PopupCardsCol key={index}>
				<PopupCard
				      number={index}
				      cardNumber={index + 1}
				      cardImg={item.image}
				      cardText={item.title}
				      icon="plus.svg"
				      detail={item.detail}
				      link={item.link}
				      linkText={item.linkText}
				      clickTrack={clickTrack}
				      onCardClick={onCardClick}
				      hoverTrack={hoverTrack}
				      openOverlay={openOverlay}
				      handleShowHideOverlay={handleShowHideOverlay}
				/>
			    </PopupCardsCol>
			})}
		</PopupCardsRow>

		<ViewAll  title={data.ctaText} borderColor="#000" titleColor="#000" hoverBackground="#000" svgColor="#000" width="290px" height="38px"/>

	    </PopupCardsWrapper>
	)
}

export default PopupCards

