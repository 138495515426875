import styled from 'styled-components';
import {Link} from "react-router-dom";
import {Desktop, TitleSm, TitleXs} from "components/elements";
import { sizeLaptopMd, sizeMobileLg,sizeDesktopLg, sizeTabletLg, sizeTablet} from "components/devices";
import { Row, BodySm } from 'components/elements';


export const MediaImg = styled.img`
  width: 100%;
  height: 100%;
  -webkit-transition: 0.4s all ease;
  transition: 0.4s all ease;
  object-fit: cover;
`;


export const MediaImgWrap = styled.div`
overflow: hidden;
width: 100%;
height: 330px;
flex: ${({flex}) => flex};
padding: ${({imgPadding}) => imgPadding || "0 0 0"};
${sizeLaptopMd(`
  height: 250px !important;
`)}
${sizeMobileLg(`
  padding: 0 0 0;
`)}
`;

export const ContentContainer = styled.div`
  flex: 0 0 55%;
  width: 100%;
  height: 100%;
`
export const BlogLogo = styled.img`
  margin-bottom: 20px;
  ${sizeMobileLg(`
      width: 100px;
  `)}
`;

export const MediaCardBody = styled.div`
  padding:  ${({mediaBodyPad}) => mediaBodyPad || "25px 48px"};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  min-width: 100%;
  max-width: 100%;
  &.media-body-lg {
     height: 330px !important;
  }
  
  ${sizeMobileLg(`
     padding: 25px 0 ;
  `)}
  ${sizeLaptopMd(`
 
    ${TitleXs},
    ${TitleSm} {
      font-size: 26px;
      line-height: 122%;
    }
  `)}
  ${sizeMobileLg(`
    ${TitleXs},
    ${TitleSm} {
      font-size: 20px;
      line-height: 122%;
    }
  `)}
`;
export const MediaCardWrap = styled.div.attrs(({ clickable }) => ({
  role: clickable ? 'button' : undefined,
  tabIndex: clickable ? 0 : undefined,
}))`
padding: 30px 0;
height: ${({height}) => height || "686px"};
background-color: ${({background}) => background || "#fff"};
position: relative;
  margin-bottom: 50px;
${sizeDesktopLg(`
margin-bottom: -1px;
`)}
${sizeTabletLg(`
  max-height: 600px;
  height: 100%;
`)}

${sizeMobileLg(`
    padding: 15px 20px 30px;
`)}

  &:hover {
    ${MediaImg} {
      transform: scale(1.05);
    }
    .arrow-icon {
      path {
        fill: #D12FFF;
      }
    }
  }

  button {
    padding: 7px 15px;
  }
  ${Row}{
    flex-direction: ${({flexDirection}) => flexDirection || "row"};
    ${sizeMobileLg(`
        flex-direction: column;
    `)}
  }
`;
export const MediaCardHeader = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  z-index: 2;
  padding: ${({mediaCardHeadPad}) => mediaCardHeadPad || "0 48px;"};
  ${sizeMobileLg(`
    padding: 0 0 0;
    height: auto;
  `)}
  ${Desktop} {
    width: auto;
    height: auto;
    ${BodySm}{
      font-size: 16px !important;
      line-height: 19px !important;
    }
  }
`;

export const MediaLinkWrap = styled(Link)`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: transparent;
  z-index: 1;
`;

export const MediaLinkWrapExt = styled.a`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: transparent;
  z-index: 1;
`;

export const MediaCta = styled(Link)`
  font-size: 16px;
  line-height: 1.2;
  font-weight: 500;
  text-transform: uppercase;
  color: #000;
  display: inline-block;
  z-index: 2;
  ${sizeTablet(`
    padding: 30px 0 0;
  `)}
  &:hover {
    color: #D12FFF;
  }
`;
export const MediaCtaExt = styled.a`
  font-size: 16px;
  line-height: 1.2;
  font-weight: 500;
  padding-right: 20px;
  text-transform: uppercase;
  color: #000;
  display: inline-block;
  z-index: 2;
  ${sizeLaptopMd(`
    font-size: 16px;
  `)}
  ${sizeMobileLg(`
      align-self: center;
  `)}
  &:hover {
    color: #D12FFF;
  }
`;

export const CtaArrowContainer = styled.div`
display: flex;
align-items: center;
justify-content: space-between;
`

export const MediaCardFooter = styled.div`
  width: 100%;
  margin-top: 25px;
`;