import React from 'react'
import styled from 'styled-components'

import {
    BodyRg,
    BodySm
} from "components/elements";
import {
    sizeTabletLg,
    sizeMobileLg
} from 'components/devices'

// Tooltip CSS
export const Tooltip = styled.div`
  position: absolute;
  left: 0;
  top: calc(100% + 5px);
  bottom: 0;
  z-index: -1;
  opacity: 0 !important;
  transition: .7s all ease;
  width: 400px;
  ${sizeMobileLg(`
        width: 290px;
        height: 250px;
        left: -20px;
        box-sizing: border-box;
        max-height: 500px;
        padding: 13px;
        top: 100%;
    `)}
`;
export const TooltipInner = styled.div`
  background-color: #fff;
  border: 1px solid #707070;
  padding: 15px;
  min-height: 50px;
  box-sizing: border-box;
  height: auto;
`;
export const TooltipWrap = styled.div`
  position: relative;
  display: inline-block;
  transition: .5s all ease;
  width: ${({width}) => width || "auto"};
  box-sizing: border-box;
  -webkit-user-select: none; /* Chrome/Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+ */
  -o-user-select: none;
  user-select: none;

  &::selection {
    color: inherit !important;
    background: transparent !important;
  }

  /* For Mozilla Firefox */

  &::-moz-selection {
    color: inherit !important;
    background: transparent !important;
  }

  ${BodyRg} {
    transition: .5s all ease;
    display: inline-block;
    text-decoration: ${({underline = true}) => underline ? 'underline' : 'none'};
    cursor: pointer;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0) !important;
    -webkit-user-select: none; /* Chrome/Safari */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* IE10+ */
    -o-user-select: none;
    user-select: none;

    &:hover {
      text-decoration: underline;
    }

    &::selection {
      color: inherit !important;
      background: transparent !important;
    }

    /* For Mozilla Firefox */

    &::-moz-selection {
      color: inherit !important;
      background: transparent !important;
    }

    ${sizeTabletLg(`
        opacity: 1;
        z-index: 1;
        font-size: 16px;
    `)}
    &:hover {
      & + ${Tooltip} {
        z-index: 1;
        opacity: 1 !important;
      }
    }
  }

  ${Tooltip} {
    &:hover {
      z-index: 1;
      opacity: 1 !important;
    }
  }
`;


export const TooltipComponent = ({underline, tooltipInfo, tootltipTitle, tootltipTitleColor, width}) => {


    return (
        <TooltipWrap underline={underline} width={width}>
            <BodyRg color={tootltipTitleColor}>{tootltipTitle}</BodyRg>
            <Tooltip>
                <TooltipInner>
                    <BodySm>{tooltipInfo}</BodySm>
                </TooltipInner>
            </Tooltip>
        </TooltipWrap>
    )
}

export default TooltipComponent;
