import styled from 'styled-components';
import {
  sizeTabletLg,
  sizeWideScreen
} from "components/devices";
import { TitleXl} from 'components/elements';


export const BenefitsSectionWrap = styled.div`
    background-color: ${({backgroundColor}) => backgroundColor || "none"};
    ${sizeTabletLg(`
        padding: 100px 0 0;
`)}

`;
export const Tabs = styled.div`

`;
export const TabsList = styled.div`
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    border-top: 1px solid #707070;
    border-bottom: 1px solid #707070;
    ${sizeTabletLg(`
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    `)}

    ${TitleXl} {
        width: 500px;
        font-weight: 900px;
    }
`;
export const TabsLink = styled.button`
    width: 100%;
    border: none;
    background: #EAEAEA;
    height: 100px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    border-right: 1px solid #707070;
    cursor: pointer;
    font-size: 16px;
    text-transform: uppercase;
    font-weight: 600;
    color: rgba(0, 0, 0, 0.3);
    position: relative;
    z-index: 1;
    overflow: hidden;
    &:last-child {
        border-right: none;
    }
    &:hover {
        background-color: #D12FFF;
        color: #fff;
    }
    &.active {
        background-color: #000000;
        color: #fff;
        &:before {
            content: none;
        }
    }
    &:focus {
        outline: none;
        box-shadow: none;
    }
    ${sizeTabletLg(`
        height: 80px;
        &:before {
            content: none;
        }
    `)}

    ${sizeWideScreen(`
        font-size: 0.7vw;
    `)}
`;


export const TabContents = styled.div`

`;
export const TabsContent = styled.div`
    transition: opacity .15s linear;
    padding: 1em;
    &.hide-contents {
        display: none;
    }
    &.show-contents {
        display: block;
    }
    &.fade {
        transition: opacity .15s linear;
    }
`;
