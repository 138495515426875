import React from 'react'

import {
	ItemsSectionWrap, 
	TitleBodyContainer
} from './elements'

import LinkCard from "../../LinkCard";
import { BodySm, Col6, Row, TitleXl } from 'components/elements';

function TabbedItems({ items, infoSectionData }) {


	
	return (
		<ItemsSectionWrap>
			<TitleBodyContainer>
				<TitleXl fontFamily="HelveticaNowDisplay" fontWeight="400">{infoSectionData?.title}</TitleXl>
				<BodySm >
					{infoSectionData?.detail?.content[0].content[0].value}
				</BodySm>
				<BodySm>
				In conjunction with our tech suite, our teams partner with leading healthcare companies to understand and define their markets using a value-driven lens.
				</BodySm>
				<BodySm >
				For a demonstration of Real Chemistry’s Insight System (RCIS), proprietary audience segmentation or one-of-a-kind end-to-end healthcare experience solution, drop us a note.
				</BodySm>
			</TitleBodyContainer>
			<Row>
				{items && items.map((item, index) => {
					return <Col6 key={'tabelem-' + index} padding="0 15px" >
						<LinkCard
							title={item.title}
							bodySm={item?.detail?.content[0].content[0].value}
							index={index + 1}
							cta={item.ctaText}
							link={item.ctaLink}
							backgroundColor={"#6900FF"}
						/>
					</Col6>
				})}
			</Row>
		</ItemsSectionWrap>
	)
}

export default TabbedItems
