import React from 'react'


function SkipForwardIcon() {

    return (
        <svg width="36" height="37" viewBox="0 0 36 37" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M15.6004 23.2112H13.6804V16.2932H11.3164V14.9252H11.5444C12.8704 14.8412 13.7644 14.4392 14.0824 12.9692H15.6004V23.2112Z"
                fill="black"/>
            <path
                d="M24.6845 19.8811C24.6845 22.0171 23.1185 23.4391 20.8505 23.4391C18.7805 23.4391 17.2085 22.2391 17.0645 20.2831H18.9245C18.9789 20.7447 19.2097 21.1674 19.5687 21.4627C19.9277 21.7579 20.387 21.9028 20.8505 21.8671C21.1127 21.8785 21.3744 21.8347 21.6185 21.7385C21.8627 21.6422 22.0839 21.4957 22.2678 21.3084C22.4517 21.1212 22.5941 20.8973 22.6859 20.6514C22.7777 20.4055 22.8167 20.1431 22.8005 19.8811C22.8159 19.6183 22.7759 19.3552 22.6831 19.1089C22.5902 18.8626 22.4467 18.6385 22.2617 18.4512C22.0767 18.264 21.8544 18.1177 21.6092 18.0219C21.364 17.9261 21.1014 17.8829 20.8385 17.8951C20.4692 17.8694 20.1007 17.9544 19.78 18.1393C19.4593 18.3242 19.2011 18.6006 19.0385 18.9331H17.2385L18.1925 12.9331H24.1265V14.5951H19.7465L19.2965 17.1451C19.8213 16.706 20.4841 16.466 21.1685 16.4671C23.5325 16.4671 24.6845 18.0631 24.6845 19.8811Z"
                fill="black"/>
            <path
                d="M18.0008 4.99512L19.0808 5.83512L18.0008 6.79512C15.7461 6.79512 13.542 7.46372 11.6673 8.71636C9.79257 9.96901 8.3314 11.7494 7.46856 13.8325C6.60572 15.9156 6.37996 18.2078 6.81983 20.4191C7.25971 22.6305 8.34545 24.6618 9.93977 26.2561C11.5341 27.8505 13.5654 28.9362 15.7768 29.3761C17.9881 29.8159 20.2803 29.5902 22.3634 28.7273C24.4465 27.8645 26.2269 26.4033 27.4795 24.5286C28.7322 22.6539 29.4008 20.4498 29.4008 18.1951H31.2008C31.2008 20.8058 30.4266 23.3579 28.9762 25.5286C27.5257 27.6994 25.4642 29.3913 23.0522 30.3903C20.6402 31.3894 17.9861 31.6508 15.4256 31.1415C12.865 30.6322 10.513 29.375 8.66698 27.5289C6.82092 25.6829 5.56375 23.3309 5.05442 20.7703C4.5451 18.2098 4.8065 15.5557 5.80558 13.1437C6.80465 10.7317 8.49653 8.67015 10.6673 7.21972C12.838 5.76928 15.3901 4.99512 18.0008 4.99512Z"
                fill="black"/>
            <path
                d="M18.8401 5.86531L15.9121 2.94931L17.1721 1.69531L20.0941 4.61131L20.4661 4.97731V6.75331L20.0941 7.11931L17.1721 10.0353L15.9121 8.78131L18.8401 5.86531Z"
                fill="black"/>
        </svg>


    )
}

export default SkipForwardIcon;
