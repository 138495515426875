import React, { useEffect, useRef, useState } from 'react';

import {
  BodyRg,
  TitleXl,
  TitleSm,
  Desktop,
  Mobile,
  Label,
  WideScreenContainer
} from "components/elements";

import {
  ContactModalWrap,
  ContactModalWrapInner,
  ContactModalVideo,
  ModalContainer,
  CloseModal,
  CloseIcon,
  ContactForm,
  InputField,
  ContactFooter,
  SubmitButton,
  ArrowSubmit,
  InputDiv,
  FormGroup,
  ThanksContainer,
  ThanksInner,
  CheckIcon,
  ExploreLink,
  ArrowIcon,
  ErrorImg
} from "./elements";

import Checkbox from "components/Checkbox";
import Select from "components/SelectField";
import useFormManager from '../../hooks/useFormManager';
import {recaptchaHelper} from "../../generalHelpers";

var _isMounted = false;
const OPTIONAL = ["additionalInfo","test"]
function ContactModal(props) {
  const firstInputRef = useRef(null);
  const contactFormRef = useRef(null);
  const contactModalHeight = useRef(null);
  const inputElEmail = useRef(null);
  const inputElLastName = useRef(null);
  const [isSafari, setIsSafari] = useState(false)
  const [keyboard, setKeyboard] = useState(false)
  const [validRecaptcha,setValidRecaptcha] = useState(true)


  const {
    handleOnInputChange,
    handleSubmit,
    setIsSubmitted,
    isSubmitted,
    subscriptionFormData
  } = useFormManager({
    data: {
      email: "",
      firstName: "",
      lastName: "",
      knowAbout: "",
      additionalInfo: "",
      test: "",
      from:'contact-us'
    },
    errors: {
      email: "",
      firstName: "",
      lastName: "",
      knowAbout: "",
    }
  }, OPTIONAL);


  useEffect(() => {

    if (firstInputRef.current && !_isMounted && props.show) {
      _isMounted = true;
      if (!isSafari) {
        firstInputRef.current.focus();
      }
    }
    if (!props.show) _isMounted = false;

    if (isSubmitted) {
      contactFormRef.current.reset();
    }
  });

  useEffect(() => {
    let checkSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
    setIsSafari(checkSafari)
    if (checkSafari) {
      document.addEventListener('focusin', keyboardAdded);
      document.addEventListener('focusout', keyboardRemoved);
    }
    return () => {
      if (checkSafari) {
        document.removeEventListener('focusin', function () {

        })
        document.removeEventListener('focusout', function () {

        })
      }
    }

  }, []);

  const keyboardAdded = () => {
    setKeyboard(true)
  }
  const keyboardRemoved = () => {
    setKeyboard(false)
  }

  const closeModal = () => {
    setFocus(false)
    if (!!props.closeContactUsModal) props.closeContactUsModal();
    setIsSubmitted(false);

    document.body.className = "";
  }

  // handle submit
  // const [isSubmitted, setIsSubmitted] = React.useState(false);
  // const handleSubmit = () => { setIsSubmitted(true); }

  const [focus, setFocus] = React.useState(false)
  const [isSelected, setSelected] = React.useState(false);

  // window resize function
  const vidRef = useRef(null);


  let size = useWindowSize();

  function useWindowSize() {
    const [windowSize, setWindowSize] = useState({
      width: undefined,
      height: undefined,
    });

    useEffect(() => {
      function handleResize() {
        setWindowSize({
          width: window.innerWidth,
          height: window.innerHeight,
        });
      }

      window.addEventListener("resize", handleResize);

      handleResize();

      return () => window.removeEventListener("resize", handleResize);
    }, []);

    return windowSize;
  }

  useEffect(() => {
        vidRef.current.src = "/videos/contact-form-overlay/RealChem_ContactOverlay_1920.mp4";
  }, [size.width])
  
  useEffect(() => {
    if (
      (subscriptionFormData.errors.email.length > 0
        && subscriptionFormData.errors.lastName.length > 0
        && subscriptionFormData.errors.firstName.length > 0)
      || (subscriptionFormData.errors.email.length > 0
        && subscriptionFormData.errors.lastName.length === 0
        && subscriptionFormData.errors.firstName.length > 0)
    ) {
      firstInputRef.current.focus()
    }
    if (subscriptionFormData.errors.email.length > 0
      && subscriptionFormData.errors.lastName.length > 0
      && subscriptionFormData.errors.firstName.length === 0
      && !(firstInputRef.current === document.activeElement)
    ) {
      inputElLastName.current.focus()
    } else if (
      //eslint-disable-next-line
      subscriptionFormData.errors.email.length > 0 &&
      (!(firstInputRef.current === document.activeElement || inputElLastName.current === document.activeElement))
    ) {
      inputElEmail.current.focus()
    }


    //eslint-disable-next-line
  }, [
    subscriptionFormData.errors.email,
    subscriptionFormData.errors.firstName,
  ])


  const handleSubmitClick = async ()=>{
    let proceed = await recaptchaHelper();
    if(proceed){
      handleSubmit('Contact')
    }else{
      setValidRecaptcha(false)
    }

  }


  return (
    <ContactModalWrap ref={contactModalHeight} show={props.show} backgroundImage="/images/contact-form-bg.jpg">
      <ContactModalWrapInner style={{ paddingBottom: `${keyboard ? '250px' : ''}` }} >
        <ContactModalVideo
          height={contactModalHeight && contactModalHeight.current && contactModalHeight.current.offsetHeight}
          loop
          muted
          ref={vidRef}
          playsInline
          autoPlay
          poster="/images/contact-form-bg.jpg">
          src={''}

        </ContactModalVideo>
        <WideScreenContainer>
          <CloseModal onClick={closeModal}>
            <CloseIcon src="/images/close-circle.svg" />
          </CloseModal>
          <BodyRg color="#FFF" textTransform="uppercase">Contact Us</BodyRg>
        </WideScreenContainer>


        <ModalContainer style={{ display: `${isSubmitted ? 'none ' : 'flex '}` }}>

          <ContactForm className="contact us" ref={contactFormRef} action="">

            <InputDiv>
              Hello! My name is
              <FormGroup>
                <InputField
                  spellCheck={'false'}
                  required={subscriptionFormData.errors.firstName.length > 0}
                  className="names"
                  name="firstName"
                  value={subscriptionFormData.data.firstName}
                  ref={firstInputRef}
                  placeholder="First*" type="text"
                  onChange={handleOnInputChange}
                  errorIcon="/images/error-icon.png"
                />
                {
                  subscriptionFormData.errors.firstName.length > 0 ?
                    <Label>First Name Required*</Label>
                    : null
                }
              </FormGroup>
              <FormGroup>
                <InputField
                  spellCheck={'false'}
                  required={subscriptionFormData.errors.lastName.length > 0}
                  className="names"
                  name="lastName"
                  placeholder="Last*"
                  type="text"
                  ref={inputElLastName}
                  value={subscriptionFormData.data.lastName}
                  onChange={handleOnInputChange}
                  errorIcon="/images/error-icon.png"
                />
                {
                  subscriptionFormData.errors.lastName.length > 0 ?
                    <Label>Last Name Required*</Label>
                    : null
                }
              </FormGroup>
              .
            </InputDiv>
            <InputDiv>
              My email is
              <FormGroup
                className={
                  subscriptionFormData.errors.email === 'Please enter valid email' ? "email_input email_invalid" : "email_input"
                    && subscriptionFormData.errors.email.length > 0 ? 'email_input email_required' : 'email_input'
                }
              >
                <InputField
                  spellCheck={'false'}
                  required={subscriptionFormData.errors.email.length > 0}
                  className={subscriptionFormData.errors.email === 'Please enter valid email' ? "email error" : "email"}
                  name="email"
                  ref={inputElEmail}
                  placeholder="example@email.com*"
                  type="mail"
                  value={subscriptionFormData.data.email}
                  onChange={handleOnInputChange}
                  errorIcon=""
                />
                {
                  subscriptionFormData.errors.email === 'Please enter valid email' ?
                    <Label>Valid Email Required*</Label>
                    : subscriptionFormData.errors.email === 'Required Field' || subscriptionFormData.errors.email.length > 0 ?
                      <Label>Email Required*</Label> : null

                }
                {
                  subscriptionFormData.errors.email === 'Please enter valid email' ?
                    <ErrorImg src="/images/error-icon.png" /> :
                    ""
                }
              </FormGroup>
              .
            </InputDiv>
            <InputDiv>
              I'd like to learn more about
              <FormGroup>
                <Select
                  isSelected={isSelected}
                  required={subscriptionFormData.errors.knowAbout.length > 0}
                  name="knowAbout"
                  focus={focus}
                  change={(e) => {
                    setSelected(true);
                    handleOnInputChange(e);
                  }}
                  click={() => setFocus(!focus)}
                  rotateIcon="/images/arrow-circle-top.svg"
                  backgroundImage="/images/arrow-circle-rotate.svg"
                  options={
                    [
                      {
                        label: "Select one*",
                        value: ""
                      },
                      {
                        label: "Career opportunities",
                        value: "Career opportunities"
                      },
                      {
                        label: "Exploring business with Real Chemistry",
                        value: "Exploring business with Real Chemistry"
                      },
                      {
                        label: "Employment verification",
                        value: "Employment verification"
                      },
                      {
                        label: "Something else",
                        value: "Something else"
                      }

                    ]
                  }>
                </Select>
                {
                  subscriptionFormData.errors.knowAbout.length > 0 ?
                    <Label>Selection Required*</Label>
                    : null
                }
              </FormGroup>
            </InputDiv>
            <InputDiv className="learn_more">
              Anything else you want us to know?
              <InputField
                placeholder={window.innerWidth > 991 ? "Message" : "Message"}
                type="text"
                className="textarea"
                name="additionalInfo"
                value={subscriptionFormData.data.additionalInfo}
                onChange={handleOnInputChange}
              />
            </InputDiv>
          </ContactForm>

        </ModalContainer>

        {/* Thanks Componment */}
        <ThanksContainer style={{ display: `${isSubmitted ? 'flex' : 'none'}` }}>
          <ThanksInner>
            <CheckIcon src="/images/check-circle-white.svg" />
            <Desktop>
              <TitleXl color="#fff">Thank you. <br /> We will reach out shortly. </TitleXl>
            </Desktop>
            <Mobile>
              <TitleXl color="#fff">Thank you.</TitleXl>
            </Mobile>
            <ExploreLink onClick={() => { document.body.className = ""; }} to="/news-events">
              <TitleSm color="#fff" textTransform="uppercase">Explore our Views</TitleSm>
              <ArrowIcon
                xmlns="http://www.w3.org/2000/svg"
                width="86" height="47.114"
                viewBox="0 0 86 47.114"
              >
                <g id="M_Arrow" data-name="M Arrow" transform="translate(0 1.414)">
                  <line id="Line_18" data-name="Line 18" x2="83.905" transform="translate(0 21.967)" fill="none"
                    stroke="#FFF" strokeWidth="4" />
                  <path id="Path_592" data-name="Path 592" d="M0,0,22.143,22.143,0,44.286"
                    transform="translate(61.857 0)" fill="none" stroke="#FFF" strokeLinejoin="round"
                    strokeWidth="4" />
                </g>
              </ArrowIcon>
            </ExploreLink>
          </ThanksInner>
        </ThanksContainer>

        <ContactFooter>
          <WideScreenContainer>
            {
              isSubmitted ?
                null : <Checkbox handleCheck={handleOnInputChange} name="test" id="test" checkDotColor=" #FFF" circleColor="#FFF">
                  I agree to receive relevant emails from Real Chemistry, including exclusive event
                  invitations and a monthly digest of key healthcare news.
              </Checkbox>
            }
            <Label display={"block"} textTransform={'inherit'} color={"#7f7f7f !important"}>
              <p color="#7f7f7f" className="sc-iCfLBT lcUpIw recaptcha-copy"><span>This site is protected by reCAPTCHA, and the Google&nbsp;
                <a target="_blank" rel="noopener noreferrer" href="https://policies.google.com/privacy">Privacy Policy</a>&nbsp; and&nbsp;
                <a target="_blank" rel="noopener noreferrer" href="https://policies.google.com/terms">Terms of Service</a>&nbsp; apply.</span>
              </p>
            </Label>
            {
              !validRecaptcha? <Label color={"red"}>Sorry, Google reCAPTCHA has denied your submission. If you believe this is an error, please contact marketingteam@realchemistry.com for assistance.</Label> : ''
            }

            {
              isSubmitted ? <SubmitButton activeSent={isSubmitted} onClick={handleSubmitClick}>
                <TitleSm color="#FFF" textTransform="uppercase">sent</TitleSm>
              </SubmitButton>
                : <SubmitButton onClick={() => {
                  window.dataLayer = window.dataLayer || [];
                  window.dataLayer.push({
                    'event': 'e_form_submit04'
                  }
                  );
                  handleSubmitClick()

                }}>
                  <TitleSm color="#FFF" textTransform="uppercase">Submit</TitleSm>
                  <ArrowSubmit src="/images/arrow-lg-marquee.svg" />
                </SubmitButton>
            }
          </WideScreenContainer>
        </ContactFooter>
      </ContactModalWrapInner>
    </ContactModalWrap>
  )
}


export default ContactModal;
