
import React from 'react'

import { BodyRg, BodySm, TitleSm } from 'components/elements'
import RichText from 'components/RichText'

import {
    CardImg,
    CardImgMobile,
    CardIcon,
    CardInfo,
    CardLabel,
    CardsLink,
    CardWrap,
    ImageDiv
} from './elements'

function GenericCard(props)
{
	return (
		<CardWrap bgColor={props.backgroundColor ? props.backgroundColor : null}>
			<CardLabel>
			    <CardIcon src={props.icon} />
			    <BodySm textTransform="uppercase">{props.label}</BodySm>
			</CardLabel>

			<ImageDiv>
				<CardImg src={props.image} />
				<CardImgMobile src={props.mobileImage} />
			</ImageDiv>

			<CardInfo>
				<TitleSm>{props.title}</TitleSm>
				<BodyRg>{props.summary}</BodyRg>
				<RichText body={props.detail} />
				<CardsLink to={props.link ? props.link : '#'}>{props.ctaText ? props.ctaText : 'Read More'}</CardsLink>
			</CardInfo>
		</CardWrap>
	)
}

export default GenericCard

