import styled, { css } from 'styled-components';

import {
    SectionTitle,
    TitleXl,
} from "components/elements";
import {
    sizeTabletLg,
    sizeMobileLg,
    sizeWideScreen
} from "components/devices";
import {
    ViewAllWrap
} from "components/ViewAll";

import { Link } from 'react-router-dom';

// Inner Hero Style Start
export const InnerHeroWrap = styled.div`
    box-sizing: border-box;
    ${TitleXl} {
        line-height: 1.15;
    }
    ${SectionTitle}{
        padding-top:66px
    }
    ${ViewAllWrap} {
        padding: 40px 0 0 0;
        ${sizeTabletLg(`
            display: none;
        `)}
    }
`;

export const HeroTitleContainer = styled.div`
    max-width: 2500px;
    margin: 0 auto;
`;
export const InnerHeroImg = styled.img`
    width: 100%;
    object-fit: cover;
    box-sizing: border-box;
    height: 600px;
    ${sizeMobileLg(css`
        display: none;
    `)}

    /* Wide Screen CSS */
    ${sizeWideScreen(`
        height: 1000px;
    `)}
`;
export const InnerHeroMobileImg = styled.img`
    width: 100%;
    object-fit: cover;
    box-sizing: border-box;
    display: none;
    height: 500px;
    ${sizeMobileLg(css`
        display: block;
    `)}
`;
export const MobileHeroCta = styled(Link)`
    background-color: transparent;
    border: none;
    border-top: 1px solid #707070;
    border-bottom: 1px solid #707070;
    padding: 0;
    text-align: center;
    padding: 25px;
    width: 100%;
    box-sizing: border-box;
    display: none;
    max-width: 100%;
    color: #000;
    font-size: 24px;
    text-transform: uppercase;
    margin-bottom: -1px;
    &:focus {
        outline: none;
        box-shadow: none;
    }
    ${sizeTabletLg(css`
        display: block;
    `)}
`;
