import styled from "styled-components"
import { TitleXl, BodySm } from "components/elements"
import { sizeMobileLg } from "components/devices"

export const TitleXlContainer = styled.div`
    max-width: 60%;
    padding: 5% 2% 0 ;
    ${sizeMobileLg(`
         max-width: 100%;
         padding: 15% 15px 0;
    `)}
    ${TitleXl} {
        font-size: 48px;
        font-weight: 900;
        line-height: 57px;
        ${sizeMobileLg(`
            font-size: 36px;
            line-height: 43px; 
       `)}
    }
  
`

export const BodySmContainer = styled.div`
    padding: 2% 2% 5%;
    max-width: 50%;
    width: 100%;
${sizeMobileLg(`
   padding-top: 30px;
   max-width: 100%;
    padding: 10% 15px 4%;
`)} 
${BodySm}{
   font-size: 20px;
   line-height: 24px;
   ${sizeMobileLg(`
       font-size:16px;
       line-height: 19px;
`)} 
`