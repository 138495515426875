import styled from 'styled-components';
import {
    Caption
} from "components/elements";


import {
    sizeXlDevice,
    sizeTabletLg,
    sizeMobileLg,
    sizeWideScreen
} from "components/devices";

export const BlogHeroWrap = styled.div`
    margin: 30px 0;
    ${Caption} {
        opacity: 0.5;

        /* For 4k Device */
        ${sizeXlDevice(`
            font-size: 0.8vw;
        `)}
    }
    ${sizeTabletLg(`
        margin: 20px 0;
    `)}
    
`;
export const BlogHeroImg = styled.img`
    width: 100%;
    height: 100%;
    object-fit: cover;
    margin-bottom: 5px;
    max-height: 630px;
    ${sizeTabletLg(`
        height: auto;
        margin-bottom: 10px;
    `)}
    ${sizeMobileLg(`
        display: none;
    `)}

    ${sizeWideScreen(`
        max-height: 950px;
    `)}
`;
export const BlogHeroImgMobile = styled(BlogHeroImg)`
    display: none;
    ${sizeMobileLg(`
        display: block !important;
    `)}
`;
