import styled from 'styled-components';

import {
    sizeTabletLg,
} from "components/devices";

export const PrivacyPolicyPage = styled.div`
    padding-top: 120px;
    ${sizeTabletLg(`
        padding-top: 75px;
    `)}
`;
export const PvInfoContainer = styled.div`
    max-width: 915px;
    margin: 0 auto;
    padding: 0 25px;
`;

export const ContactUsWrap = styled.div`
 width: 100%;
 display: flex;
 justify-content: center;
 padding: 0 0 50px;
 
`
