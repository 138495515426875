import React, {useState, useEffect, useRef} from 'react'

import {
	TitleXs,
    WideScreenContainer,
	TitleMd,
	Col6,
	Row,
	Col5,
	BodySm, 
} from 'components/elements'
import WistiaPlayer from "../../components/WistiaPlayer/WistiaPlayer";
import LoadingHoc from 'hoc/LoadingHOC'
import FooterAnimation from 'components/FooterAnimation'
import { r3cPageContent } from "../../constants";
import {findContent, getImageDimensions} from "../../generalHelpers";
import {THMHeader,WhoWeAreContainer, AnchorLinksContainer, HeroIntroContainer, Divider, LeadershipContainer, ContactUsContainer, HowWeHelpContainer, OurProductsContainer} from "./elements";
import SpecialtyLeadershipSection from 'components/SpecialtyLeadershipSection';
import { TitleSm } from 'components/elements'
import RichText from 'components/RichText'
import {Helmet} from "react-helmet";
import ProductCards from 'components/ProductCards'
import THMContactModal from 'components/THMContactModal'
import useRememberScrollPosition from 'hooks/useRememberScrollPosition'


function THM(props) {

	const {setLoading} = props	
    const [seoData, setSeoData] = useState({})
	const [heroSectionData, setHeroSectionData] = useState({})
	const [textSectionData, setTextSectionData] = React.useState({})
	const [twoColumnCopyImgData, setTwoColumnCopyImgData] = useState({})
	const [leadershipTeam, setLeadershipTeam] = React.useState({})
	const [tabbedSectionData, setTabbedSectionData] = React.useState({})
	const [openModal, setOpenModal] = useState(false)
	const [windowWidth, setWindowWidth] = useState(false)
	const [iframeHeight, setIframeHeight] = useState()
	const contactIframe = useRef();


    React.useEffect(() => {
        fetch(r3cPageContent + '?route=/transformative-healthcare-markets').then((response) => response.json()).then((page) => {
            let seo = page.seo;
            let image  = page?.seo?.image?.split('.')

            page?.seo?.image ?  getImageDimensions(page?.seo?.image,function (width,height){
                setSeoData({
                    ...seo,
                    mimeType:image[image.length - 1],
                    seoImageHeight:height,
                    seoImageWidth:width
                })

            }) :    setSeoData(seo)

            setHeroSectionData(findContent(page.contents, 'heroArea'))
			setTextSectionData(findContent(page.contents, 'textArea'))
			setLeadershipTeam(findContent(page.contents, 'cardSelector'))
			setTwoColumnCopyImgData(findContent(page.contents, 'twoColumnCopyImage'))
			setTabbedSectionData(findContent(page.contents, 'tabbedLinks'))

            setLoading(false)
        })
        // eslint-disable-next-line
    }, [])


	useEffect(() => {
        const handleResize = () => {
            window.innerWidth < 768 ? setWindowWidth(true) : setWindowWidth(false)
			document.documentElement.clientWidth < 768 ? setWindowWidth(true) : setWindowWidth(false)
        }
    
        window.addEventListener("resize", handleResize);
		document.documentElement.addEventListener("resize", handleResize);
        handleResize();
        return () => {
			window.removeEventListener("resize", handleResize);
			document.documentElement.removeEventListener("resize", handleResize);
		}
     },[])

	
	 useEffect(() => {

		window.addEventListener('message', e => {
			const keyContact = e.message ? 'message' : 'data';
			const data = e[keyContact];

			if(data.location === 'https://go.realchemistry.com/l/603341/2024-03-20/79b45'){
				contactIframe.current.style.height = data.height + 200 + 'px';

				if(data.height < 300){
					window.scrollTo(0, window.scrollY - 400)
				}
			  }

			
		},false);

	},[]);
		

	const onLoadTHM = () => {
        setIframeHeight(890 + "px");
      };

      useEffect(() => {
        onLoadTHM();
      }, []);


	const openDemoModal = () => {
		setOpenModal(true)
		document.body.style.overflow = "hidden"; 
		document.body.style.height = "100%"; 
	}

	const closeDemoModal = () => {
		setOpenModal(false)
		document.body.style.overflow = "auto";
		document.body.style.height = "auto";
	}


	return (
		<div className="pageWrapper thmPageWrapper" >
			<Helmet>
				<title>{seoData.title}</title>
				<link rel="canonical" href={window.location.pathname}/>
				<meta name="description" content={seoData.description}/>
				<meta name="og:description" content={seoData.description}/>
				<meta name="og:title" content={seoData.title}/>
				<meta name="og:url" content={window.location.protocol + "//" + window.location.host + window.location.pathname}/>
				<meta name="og:type" content="website"/>
				<meta name="og:site_name" content="Real Chemistry"/>
				<meta name="og:image" content={window.location.protocol + seoData.image}/>
				<meta name="og:image:type" content={"image/" + seoData?.mimeType}/>
				<meta name="og:image:width" content={seoData?.seoImageWidth}/>
				<meta name="og:image:height" content={seoData?.seoImageHeight}/>
			</Helmet>

			<THMHeader>
				<AnchorLinksContainer>
					<a aria-label="link to who we are" aria-describedby="who we are" href="#whoweare">
						<TitleSm>
							 WHO WE ARE
						</TitleSm>
					</a>
					<a aria-label="link to how we help" aria-describedby="how we help" href="#howwehelp">
						<TitleSm>
							HOW WE HELP
						</TitleSm>
					</a>
					<a aria-label="link to our products" aria-describedby="our products" href="#ourproducts">
						<TitleSm>
							OUR PRODUCTS
						</TitleSm>
					</a>
				</AnchorLinksContainer>
				<HeroIntroContainer>
					<Divider />
					<RichText body={heroSectionData?.textArea && heroSectionData?.textArea}/>
					<a className="shopProducts" aria-label="link to shop our products" aria-describedby="shop our products" href="#ourproducts">
						<TitleSm>
							{heroSectionData?.linkText}
						</TitleSm>
					</a>
				</HeroIntroContainer>
				 	<div style={{width: '100%'}}>
					 {windowWidth ?
					 <dotlottie-player src="https://lottie.host/82d2e835-298e-440e-9060-a478e824a0f2/R6L89F0yvW.json" background="transparent" speed="1" style={{width: '100%', height: '100%'}} loop autoplay></dotlottie-player>
					 : 
					 <dotlottie-player src="https://lottie.host/4d8c1c89-3a90-440b-9c5b-78098aba5edc/jZZsZBQp0r.json" background="transparent" speed="1" style={{width: '100%', height: '100%'}} loop autoplay></dotlottie-player>
					}
					</div>
	
			</THMHeader>
			<WideScreenContainer> 
				<WhoWeAreContainer id="whoweare">
					<Divider className='mblShort'/>
					<TitleSm fontSize="16px">
						WHO WE ARE
					</TitleSm>

					<TitleMd>
						{textSectionData?.name}
					</TitleMd>
					<div className={"twoColumns"}>
						<div>
							<RichText body={textSectionData && textSectionData?.text}/>
						</div>
					</div>
					<TitleMd className={'leaderTitle'}>
						{leadershipTeam?.title}
					</TitleMd>	
				</WhoWeAreContainer>
				 <LeadershipContainer>
					<SpecialtyLeadershipSection leadership={leadershipTeam} thm={true} backgroundColor={'#ffffff'} />
				</LeadershipContainer>
				<HowWeHelpContainer id="howwehelp">
					<Divider className='mblShort'/>
					<TitleSm fontSize="16px">
						{twoColumnCopyImgData?.title}
					</TitleSm>
					<Row>
						<Col6>
							<RichText body={twoColumnCopyImgData && twoColumnCopyImgData?.columnCopy}/>
					 	</Col6>
						<Col5>
						<WistiaPlayer
										overlay={true}
                                        controlsVisibleOnLoad={false}
                                        customClass="wistia_custom"
                                        hashedId={'otglz85291'}
                                        isChannel={false}
                                    />
						</Col5>
					</Row>		
				</HowWeHelpContainer>

				<OurProductsContainer id="ourproducts">
					<Divider className={"white mobileShort"}/>
					<TitleSm fontSize="16px" color="#ffffff" className={"title"}>
						{tabbedSectionData?.label}
					</TitleSm>
					<TitleMd>
						{tabbedSectionData?.title}
					</TitleMd>
					<Row rowGap="20px">
						<Col6>
							<ProductCards  openDemoModal={openDemoModal} backgroundColor="#D2B8F8" pillText={tabbedSectionData?.items?.[0].name} title={tabbedSectionData?.items?.[0].elements?.[0].title} richTextBody={tabbedSectionData?.items?.[0].elements?.[0].detail} subText={tabbedSectionData?.items?.[0].elements?.[0].summary} ctaText={tabbedSectionData?.items?.[0].elements?.[0].ctaText} ctaLink={tabbedSectionData?.items?.[0].elements?.[0].ctaLink}/>
						</Col6>
						<Col6>
							<ProductCards backgroundColor="#F0F0F0" pillText={tabbedSectionData?.items?.[1].name} title={tabbedSectionData?.items?.[1].elements?.[0].title} richTextBody={tabbedSectionData?.items?.[1].elements?.[0].detail} subText={tabbedSectionData?.items?.[1].elements?.[0].summary} onDemand={true} id={'product-component-1712874510405'}/>
						</Col6>
					</Row>
					<BodySm>
						Notice: IRIS by Real Chemistry is an insights-as-a-service platform providing U.S. market-level intelligence for the obesity market. The platform, reports, and associated website page(s) are intended for U.S. audiences and organizations with operations in the U.S. The analysis is conducted by Real Chemistry subject matter experts and is independent and not representative of or sponsored by clients.
					</BodySm>
				</OurProductsContainer>
				<ContactUsContainer id="contactDiv">
					<Row alignItems="flex-start" justifyContent="space-between">
						<Col6>
							<TitleXs>
								 Contact Us
							</TitleXs>
							<TitleMd>
								Got questions? <br/> We’ve got answers.
							</TitleMd>
							<BodySm>
								Need a complete view of the obesity and GLP-1 market?
							</BodySm>
							<BodySm>
								Email us at <br/>
								<a href="mailto:TransformativeHealthcareMarkets@realchemistry.com" target="_blank">TransformativeHealthcareMarkets@realchemistry.com</a>
								<br/> or fill out this form. We can help.  
							</BodySm>
						</Col6>
						<Col5>
							<iframe ref={contactIframe} title="Contact us form" src="https://go.realchemistry.com/l/603341/2024-03-20/79b45" id="contactUsFormTHM" width="100%"  height={iframeHeight} type="text/html" frameborder="0" allowTransparency="true" style={{border: '0', maxHeight: '900px'}}></iframe>
						</Col5>
					</Row>
				</ContactUsContainer> 


				<THMContactModal show={openModal} closeDemoModal={closeDemoModal}/>
			</WideScreenContainer>
			<FooterAnimation
				svgDesktop="/images/purple-footer.svg"
				svgMobile="/images/purple-footer-mbl.svg"
				borderTop="none"
			/>
		</div>
	)
}

export default LoadingHoc(THM);


