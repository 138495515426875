import React, {useEffect} from 'react'
import {BackArrowLink, WideScreenContainer, TitleSm, TitleXl, TitleMd,  BodySm, Row, Col4, Col8, Col12} from 'components/elements'
import {Container, HeroContainer, MediaContainer, ContentContainer, SectionsContainer, GreyContainer } from './elements';
import BackArrowSvg from "../../assets/icons/BackArrowSvg";
import LoadingHoc from '../../hoc/LoadingHOC'
import FooterAnimation from 'components/FooterAnimation'
import {Helmet} from "react-helmet";
import {useHistory, useParams} from "react-router-dom";
import useContentful from 'store/useContentful'
import { BlogWistiaVideo } from 'pages/blog-details/BlogDetail/elements';
import WistiaPlayer from 'components/WistiaPlayer/WistiaPlayer';
import RichText from 'components/RichText';
import CaseStudiesContainer from 'components/CaseStudiesContainer';


const buPages = [
    {
        title: "Integrated Communications",
        link: "/integrated-communications"
    },
    {
        title: "Advertising",
        link: "/advertising"
    },
    {
        title: "Activation",
        link: "/activation"
    },
    {
        title: "Medical Communications",
        link: "/medical"
    },
    {
        title: "Analytics + Insights",
        link: "/analytics-and-insights"
    },
    {
        title: "Targeting + Engagement",
        link: "/targeting-and-engagement"
    }
]

function CaseStudyDetails(props) {
    const {setLoading} = props
    const history = useHistory();
    const { slug} = useParams();
    const {getCaseStudyEntry, getAllCaseStudiesForBu } = useContentful();
    const [seoData, setSeoData] = React.useState({})
    const [type, setType] = React.useState('')
    const [link, setLink] = React.useState('')
    const [title, setTitle] = React.useState('')
    const [client, setClient] = React.useState('')
    const [heroImage, setHeroImage] = React.useState()
    const [content, setContent] = React.useState()
    const [videoHash, setVideoHash] = React.useState()
    const [allCStudiesForBu, setAllCStudiesForBu] = React.useState()


    useEffect(() => {
            window.scrollTo(0, 0);
    })

    useEffect(() => {

        const unlisten = history.listen(() => {
            window.scrollTo(0, 0);
        });
        return () => {
            unlisten();
        }
    },[history, window.location.pathname])


    useEffect(() => {
        
        (async function() {
            setLoading(true)

            try {
                let a = props ? props.location?.pathname.split('/') : []
                if (a && a.length === 3) {
                let slug = '/' + a[2]
                const caseStudy = await getCaseStudyEntry(slug);

                const allCStudies = await getAllCaseStudiesForBu(caseStudy.items[0].fields.type)

                let filteredStudies = allCStudies?.items?.filter((study) => study?.fields?.slug !== slug)
                setAllCStudiesForBu(filteredStudies || undefined)
                setSeoData(caseStudy.items[0].fields.seo.fields)
                setType(caseStudy.items[0].fields.type)
                setTitle(caseStudy.items[0].fields.title)
                setClient(caseStudy.items[0].fields.client)
                setHeroImage(caseStudy?.items[0]?.fields?.media?.fields)
                setContent(caseStudy?.items[0]?.fields?.content)

                let heroVideoUrlHash = caseStudy?.items[0]?.fields?.video?.fields?.videoUrl?.split("/")
                if(heroVideoUrlHash){
                    setVideoHash(heroVideoUrlHash[heroVideoUrlHash?.length - 1])
                }else {
                    setVideoHash(undefined)
                }
                }
            } catch (e) {
                console.error(e);
            }
        })();

        setLoading(false)
     
    },[slug])



    useEffect(() => {
      let getLink =  buPages.find((a) => a.title === type);
      setLink(getLink?.link)
    },[type]);   


    return (
        <Container className='pageWrapper'>         
                <Helmet>
                    <title>{seoData.title}</title>
                    <link rel="canonical" href={ window.location.pathname} />
                    <meta name="description" content={seoData.description} />
                    <meta name="og:description" content={seoData.description} />
                    <meta name="og:title" content={seoData.title} />
                    <meta name="og:url" content={window.location.protocol + "//" + window.location.host + window.location.pathname} />
                    <meta name="og:type" content="website" />
                    <meta name="og:site_name" content="Real Chemistry" />
                    <meta name="og:image" content={window.location.protocol +seoData?.image?.fields?.file?.url} />
                    <meta name="og:image:type" content={"image/"+seoData?.image?.fields?.file?.contentType} />
                    <meta name="og:image:width" content={seoData?.image?.fields?.file?.details?.image?.width} />
                    <meta name="og:image:height" content={seoData?.image?.fields?.file?.details?.image?.height} />
                </Helmet>
                {!!setLoading && (
                    <WideScreenContainer>
                    <HeroContainer>
                        <BackArrowLink className="cta arrow back" to={link ? link : ''}>
                            <BackArrowSvg />
                            <TitleMd textTransform={"uppercase"} margin={"0 0 0 15px"}>BACK TO {type}</TitleMd>
                        </BackArrowLink>
                    
                        {title && (
                            <TitleXl fontWeight={"900"}>
                                {title}<span className="magenta">.</span>
                            </TitleXl>
                        )}                   
                        {heroImage ? ( 
                            <MediaContainer>
                                <img src={heroImage.file.url} className="hero" alt={heroImage.title} />
                            </MediaContainer>
                        ) : (
                            videoHash && (
                            <BlogWistiaVideo>
                            <WistiaPlayer
                                controlsVisibleOnLoad={false}
                                customClass="wistia_custom"
                                hashedId={videoHash}
                                isChannel={false}
                            />
                        </BlogWistiaVideo>
                            )
                        )}
                    </HeroContainer>
                    <SectionsContainer>
                        <Row>
                            <Col8>
                                <ContentContainer>
                                    <TitleSm fontFamily={"HelveticaNowDisplay Black"} fontWeight="900" padding={"0 0 25px"} textTransform="uppercase">
                                        {content && content[0]?.fields?.name}
                                    </TitleSm>
                                    <RichText body={content && content[0]?.fields?.text}/>
                                </ContentContainer>
                            </Col8>
                            <Col4>
                                <ContentContainer>
                                    <TitleSm fontFamily={"HelveticaNowDisplay Black"} fontWeight="900" padding={"0 0 25px"}>
                                        CLIENT
                                    </TitleSm>
                                    <BodySm>
                                        {client}
                                    </BodySm>
                                </ContentContainer>
                            </Col4>
                        </Row>
                        <GreyContainer>
                            {content && content.slice(1).map((c) => (
                            <Row justifyContent="center"> 
                                <Col12>
                                    <ContentContainer className='bottom'>
                                        <TitleSm fontFamily={"HelveticaNowDisplay Black"} fontWeight="900" padding={"0 0 25px"} textTransform="uppercase">
                                            {c.fields.name}
                                        </TitleSm>
                                        <RichText body={c.fields.text}/>
                                    </ContentContainer>
                                </Col12>
                                </Row>
                            ))}        
                        </GreyContainer>                       
                    </SectionsContainer>
                    {allCStudiesForBu?.length > 0 && (
                        <CaseStudiesContainer filteredStudies={allCStudiesForBu}/>                 

                    )}


                    </WideScreenContainer>
                )}


            <FooterAnimation
				svgDesktop="/images/purple-footer.svg"
				svgMobile="/images/purple-footer-mbl.svg"
				borderTop="none"
			/>
        </Container>
    )
}

export default LoadingHoc(CaseStudyDetails)

