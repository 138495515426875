import React, { useEffect, useRef } from 'react'
import {withRouter} from "react-router-dom"
import { THMModalContainer, ContactUsFormContainer } from './elements';

function THMContactModal({show, closeDemoModal}){

  const subscribeIframe = useRef();
  const [height, setHeight] = React.useState("0px");


  useEffect(() => {
    window.onclick = function(event) {
      if(show){
        closeDemoModal()
        document.body.style.overflow = "auto"; 
        document.body.style.height = "auto";  
      }
    }
  })

  useEffect(() => {

    if(show){

      window.addEventListener('message', e => {
        const keySub = e.message ? 'message' : 'data';
        const dataSub = e[keySub];

        if(dataSub.location === 'https://go.realchemistry.com/l/603341/2024-03-20/79bjk'){
          subscribeIframe.current.style.height = dataSub.height + 200 + 'px';

        }

      },false);
    }

	},[show]);

      const onLoad = () => {
        setHeight(615 + "px");
      };

      useEffect(() => {
        onLoad();
      }, []);

    return(
      <THMModalContainer show={show}>
            <ContactUsFormContainer>
                <img className={'exitIcon'} src={'images/exit-vector.png'} width="24px" alt="close modal form" onClick={closeDemoModal} />
                <iframe ref={subscribeIframe} onLoad={onLoad} title="Subscription form" src="https://go.realchemistry.com/l/603341/2024-03-20/79bjk" id="subscribeFormTHM" width="100%" height={height} type="text/html" frameborder="0" allowTransparency="true" style={{border: '0', maxHeight:'615px'}}></iframe>
            </ContactUsFormContainer>
      </THMModalContainer>
    )
}

export default withRouter(THMContactModal);
