import { Row, TitleXl, Col4, TitleSm } from "components/elements";
import styled from "styled-components";
import { sizeMobileLg, sizeLaptop, sizeTabletLg, } from "components/devices";

export const OurOfferingsContainer = styled.div`
    ${Row}{
      padding: 5% 8% 8%;
      flex-wrap: wrap;
      justify-content: flex-start !important;
      ${sizeLaptop(`
        padding: 5% 6% 8%;
      `)}
      ${sizeTabletLg(`
        padding: 5% 2.5% 8%;
    `)}
    ${sizeMobileLg(`
      flex-direction: column;
    `)}
    ${Col4}{
      ${sizeMobileLg(`
        flex: 0 0 100%;
        max-width: 100%;
        padding: 0 0 0;
    `)}
    }
  }

  
`;


export const TitleXlContainer  = styled.div`
   padding: 6% 9% 0 ;

   p {
    font-weight: 700 !important;
    padding: 20px 0 0 !important;
    max-width: 65%;
    ${sizeTabletLg(`
      max-width: 100%;
  `)}
   }
   ${sizeTabletLg(`
     padding: 13% 4.5% 0;
   `)}
   ${TitleXl}{
        font-size: 48px;
        line-height: 57px;
        ${sizeMobileLg(`
        font-size: 36px;
        line-height: 43px;
    `)}
   }
`;

export const CollaborateContainer = styled.div`
  width: 100%;
  padding: 6% 9% 8%;
  ${sizeTabletLg(`
   padding: 15% 4.5% 20%;
  `)}
  ${TitleSm}{
      font-size: 16px;
      line-height: 1.2;
      color: #000;
      ${sizeMobileLg(
        `
          font-size: 15px;

        `
      )}
  }
  ${TitleXl}{
      font-size: 70px;
      line-height: 1.2;
      padding: 15px 0 0;
      
      ${sizeTabletLg(`
         font-size: 48px;
      `)}
      ${sizeMobileLg(`
        font-size: 36px;
          padding: 5px 0 0;
      `)}
  }
`

export const OurServicesContainer = styled.div` 
  

 
`