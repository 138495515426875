import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';

import {
  sizeDesktopMd,
  sizeLaptopLg,
  sizeLaptop,
  sizeTabletLg,
  sizeMobileMd,
  sizeLaptopMd,
  sizeMobileLg,
  sizeWideScreen,
  sizeMobileXs,
  device
} from './devices'

// Global Elements Start
export const TitleXl = styled.h1`
  font-size: ${({ fontSize }) => fontSize || "4.75em"};
  font-weight: ${({ fontWeight }) => fontWeight || "900"};
  text-transform: ${({ textTransform }) => textTransform || "none"};
  background: ${({ background }) => background || "none"};
  -webkit-background-clip: ${({ webkitBackground }) => webkitBackground || "none"};
  -webkit-text-fill-color: ${({ webkitTextFill }) => webkitTextFill || "none"};
  background-clip: ${({ backgroundClip }) => backgroundClip || "none"};
  text-fill-color: ${({ textFillColor }) => textFillColor || "none"};
  font-family: ${({ fontFamily }) => fontFamily || "HelveticaNowDisplay Black"};
  font-style: ${({ fontStyle }) => fontStyle || "inherit"};
  line-height: ${({ lineHeight }) => lineHeight || ".98"};
  margin: ${({ margin }) => margin || "0"};
  padding: ${({ padding }) => padding || "0"};
  text-align: ${({ textAlign }) => textAlign || "inherit"};
  display: ${({ display }) => display || "block"};
  ${sizeDesktopMd(`
    font-size: 4.2em;
  `)}
  ${sizeLaptopLg(`
    font-size: 3.75em;
  `)}
  ${sizeTabletLg(`
    font-size: 2.8em;
    line-height: .95;
  `)}
  ${sizeMobileMd(`
    font-size: 1.4em;
  `)}

  ${sizeMobileXs(`
    font-size: 1.125em;
  `)}

`;


export const TitleXlBlack = styled.h1`
  font-size: ${({ fontSize }) => fontSize || "4.75em"};
  font-weight: ${({ fontWeight }) => fontWeight || "900"};
  text-transform: ${({ textTransform }) => textTransform || "none"};
  font-family: ${({ fontFamily }) => fontFamily || "HelveticaNowDisplay"};
  color: ${({ color }) => color || "#000"};
  font-style: ${({ fontStyle }) => fontStyle || "inherit"};
  line-height: ${({ lineHeight }) => lineHeight || "1.2"};
  margin: ${({ margin }) => margin || "0"};
  padding: ${({ padding }) => padding || "0"};
  text-align: ${({ textAlign }) => textAlign || "inherit"};
  display: ${({ display }) => display || "block"};

  ${sizeDesktopMd(`
        font-size: 4.125em;
    `)}
  ${sizeTabletLg(`
        font-size: 3.5em;
    `)}
  ${sizeMobileMd(`
        font-size: 2em;
    `)}

`;



export const TitleLg = styled.h2`
  font-size: ${({ fontSize }) => fontSize || "4.375em"};
  font-weight: ${({ fontWeight }) => fontWeight || "400"};
  text-transform: ${({ textTransform }) => textTransform || "uppercase"};
  color: ${({ color }) => color || "#000"};
  font-family: ${({ fontFamily }) => fontFamily || "HelveticaNowDisplay"};
  font-style: ${({ fontStyle }) => fontStyle || "inherit"};
  line-height: ${({ lineHeight }) => lineHeight || "1.2"};
  margin: ${({ margin }) => margin || "0"};
  padding: ${({ padding }) => padding || "0"};
  text-align: ${({ textAlign }) => textAlign || "inherit"};
  display: ${({ initialHidden }) => initialHidden ? "none" : "block"};
  opacity: ${({ initialOpacityZero }) => initialOpacityZero ? 0 : 1};

  ${sizeDesktopMd(`
        font-size: 3.75em;
    `)}
  ${sizeLaptopLg(`
        font-size: 3.125em;
    `)}
  ${sizeLaptop(`
        font-size: 2.5em;
    `)}
  ${sizeMobileMd(`
        font-size: 2.25em;
        text-align: left;
    `)};
  ${sizeMobileXs(`
        font-size: 1.7em;
        text-align: left;
    `)}
`;

export const TitleMd = styled.h3`
  font-size: ${({ fontSize }) => fontSize || "1.25em"};
  font-weight: ${({ fontWeight }) => fontWeight || "400"};
  text-transform: ${({ textTransform }) => textTransform || "inherit"};
  color: ${({ color }) => color || "#000"};
  font-family: ${({ fontFamily }) => fontFamily || "HelveticaNowDisplay"};
  font-style: ${({ fontStyle }) => fontStyle || "inherit"};
  line-height: ${({ lineHeight }) => lineHeight || "1.2"};
  margin: ${({ margin }) => margin || "0"};
  padding: ${({ padding }) => padding || "0"};
  display: ${({ displayTitleMd }) => displayTitleMd || "block"};
  text-align: ${({ textAlign }) => textAlign || "inherit"};font-size: .55em
  ${sizeDesktopMd(`
    font-size: 1em;
  `)}

  ${sizeMobileMd(`
      font-size: .7em;
      line-height: 1.2;
  `)} 

  ${sizeMobileXs(`
    font-size: .6em;
  `)}
`;


export const TitleSm = styled.h4`
  font-size: ${({ fontSize }) => fontSize || "2.25em"};
  font-weight: ${({ fontWeight }) => fontWeight || "400"};
  text-transform: ${({ textTransform }) => textTransform || "inherit"};
  letter-spacing: ${({ letterSpacing }) => letterSpacing || "normal"};
  color: ${({ color }) => color || "#000"};
  font-family: ${({ fontFamily }) => fontFamily || "HelveticaNowDisplay"};
  font-style: ${({ fontStyle }) => fontStyle || "inherit"};
  line-height: ${({ lineHeight }) => lineHeight || "1.2"};
  margin: ${({ margin }) => margin || "0"};
  padding: ${({ padding }) => padding || "0"};
  text-align: ${({ textAlign }) => textAlign || "inherit"};
  display: ${({ display }) => display || "block"};

  ${sizeDesktopMd(`
      font-size: 2em;
  `)}
  ${sizeLaptop(`
        font-size: 1.7em;
    `)}
`;
export const TitleXs = styled.h5`
  font-size: ${({ fontSize }) => fontSize || "1.5em"};
  font-weight: ${({ fontWeight }) => fontWeight || "400"};
  text-transform: ${({ textTransform }) => textTransform || "inherit"};
  color: ${({ color }) => color || "#000"};
  font-family: ${({ fontFamily }) => fontFamily || "inherit"};
  font-style: ${({ fontStyle }) => fontStyle || "inherit"};
  line-height: ${({ lineHeight }) => lineHeight || "inherit"};
  height: ${({ titleHeight }) => !!titleHeight ? titleHeight : "inherit"};
  margin: ${({ margin }) => margin || "0"};
  padding: ${({ padding }) => padding || "0"};
  display: ${({ displayTitleXs }) => displayTitleXs || "block"};
  text-align: ${({ textAlign }) => textAlign || "inherit"};
  opacity: ${({ initialOpacityZero }) => initialOpacityZero ? 0 : 1};
  ${sizeDesktopMd(`
        font-size: 1.5em;
    `)}
  ${sizeLaptopLg(`
        font-size: 1.375em;
    `)}
  ${sizeTabletLg(`
      font-size: 1.25em;
    `)}
`;
export const H6 = styled.h6`
  font-size: ${({ fontSize }) => fontSize || "1.375em"};
  font-weight: ${({ fontWeight }) => fontWeight || "400"};
  text-transform: ${({ textTransform }) => textTransform || "inherit"};
  color: ${({ color }) => color || "#000"};
  font-family: ${({ fontFamily }) => fontFamily || "inherit"};
  font-style: ${({ fontStyle }) => fontStyle || "inherit"};
  line-height: ${({ lineHeight }) => lineHeight || "inherit"};
  margin: ${({ margin }) => margin || "0"};
  padding: ${({ padding }) => padding || "0"};
  text-align: ${({ textAlign }) => textAlign || "inherit"};
  display: ${({ display }) => display || "block"};

  ${sizeLaptopLg(`
        font-size: 1.125em;
    `)}
  ${sizeLaptop(`
        font-size: 1em;
    `)}
`;

export const BodyRg = styled.p`
  font-size: ${({ fontSize }) => fontSize || "1.25em"};
  font-weight: ${({ fontWeight }) => fontWeight || "400"};
  text-transform: ${({ textTransform }) => textTransform || "inherit"};
  color: ${({ color }) => color || "#000"};
  font-family: ${({ fontFamily }) => fontFamily || "inherit"};
  font-style: ${({ fontStyle }) => fontStyle || "inherit"};
  line-height: ${({ lineHeight }) => lineHeight || "inherit"};
  margin: ${({ margin }) => margin || "0"};
  padding: ${({ padding }) => padding || "0"};
  text-align: ${({ textAlign }) => textAlign || "inherit"};
  display: ${({ display }) => display || "block"};
  letter-spacing: ${({ letterSpacing }) => letterSpacing || "normal"};

  ${sizeLaptopLg(`
        font-size: 1.125em;
    `)}
  ${sizeLaptop(`
        font-size: 1em;
    `)}
`;

export const BodySm = styled.p`
  font-size: ${({ fontSize }) => fontSize || ".7em"};
  font-weight: ${({ fontWeight }) => fontWeight || "400"};
  text-transform: ${({ textTransform }) => textTransform || "inherit"};
  color: ${({ color }) => color || "#000"};
  font-family: ${({ fontFamily }) => fontFamily || "inherit"};
  font-style: ${({ fontStyle }) => fontStyle || "inherit"};
  line-height: ${({ lineHeight }) => lineHeight || "1.3"};
  margin: ${({ margin }) => margin || "0"};
  padding: ${({ padding }) => padding || "0"};
  text-align: ${({ textAlign }) => textAlign || "inherit"};
  display: ${({ display }) => display || "inherit"};
  display: ${({ display }) => display || "block"};
  text-transform: ${({ textTransform }) => textTransform || "inherit"};

${sizeMobileMd(`
    font-size: .56em;
    line-height: 1.2;
`)} 

${sizeMobileXs(`
  font-size: .5em;
`)}

`;
export const Caption = styled.span`
  font-size: ${({ fontSize }) => fontSize || "12px"};
  font-weight: ${({ fontWeight }) => fontWeight || "400"};
  text-transform: ${({ textTransform }) => textTransform || "inherit"};
  color: ${({ color }) => color || "#000"};
  font-family: ${({ fontFamily }) => fontFamily || "inherit"};
  font-style: ${({ fontStyle }) => fontStyle || "inherit"};
  line-height: ${({ lineHeight }) => lineHeight || "inherit"};
  margin: ${({ margin }) => margin || "0"};
  padding: ${({ padding }) => padding || "0"};
  text-align: ${({ textAlign }) => textAlign || "inline-block"};
`;

export const Label = styled.span`
  font-size: ${({ fontSize }) => fontSize || "12px"};
  font-weight: ${({ fontWeight }) => fontWeight || "400"};
  text-transform: ${({ textTransform }) => textTransform || "uppercase"};
  color: ${({ color }) => color || "#000"};
  font-family: ${({ fontFamily }) => fontFamily || "inherit"};
  font-style: ${({ fontStyle }) => fontStyle || "inherit"};
  line-height: ${({ lineHeight }) => lineHeight || "inherit"};
  margin: ${({ margin }) => margin || "0"};
  padding: ${({ padding }) => padding || "0"};
  text-align: ${({ textAlign }) => textAlign || "inherit"};
  display: ${({ display }) => display || "inline-block"};
`;
export const Bold = styled.b`
  font-weight: bold;
`;


// HoverEffect
export const OverlayBase = styled.div`
  position: relative;
  overflow: hidden;
  &:before {
    content: "";
    background-color: #D12FFF !important;
    width: 0;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    z-index: 2;
    transition: 0.5s all ease;
  }


`;

export const OverlayButton = styled.button`
  position: relative;
  overflow: hidden;

  &:before {
    content: "";
    background-color: ${({ backgroundColor }) => backgroundColor || "#D12FFF"};
    width: 100%;
    height: 100%;
    position: absolute;
    left: -150%;
    top: 0;
    z-index: -1;
    transition: 0.5s all ease;
  }
`;
export const OverlayAnchor = styled.a`
  position: relative;
  overflow: hidden;

  &:before {
    content: "";
    background-color: ${({ backgroundColor }) => backgroundColor || "#0F20FF"};
    width: 100%;
    height: 100%;
    position: absolute;
    left: -150%;
    top: 0;
    z-index: -1;
    transition: 0.5s all ease;
  }
`;

export const OverlayLink = styled(Link)`
  position: relative;
  overflow: hidden;

  &:before {
    content: "";
    background-color: ${({ backgroundColor }) => backgroundColor || "#0F20FF"};
    width: 100%;
    height: 100%;
    position: absolute;
    left: -150%;
    top: 0;
    z-index: -1;
    transition: 0.5s all ease;
  }
`;


// Main title Container
export const TitleContainer = styled.div`
  box-sizing: border-box;
  padding: ${({ padding }) => padding || "1.5em 6.5em"};
  border-bottom: ${({ borderBottom }) => borderBottom || "none"};
  border-top: ${({ borderTop }) => borderTop || "none"};
  ${sizeLaptop(`
        padding: 2.5em 1.563em;
    `)}
  ${sizeTabletLg(`
        padding: 2.5em 1.25em 1.5em;
    `)};
`;

export const SectionTitle = styled.div`
  padding: ${({ padding }) => padding || "0px 104px 56px"};
  border-bottom: ${({ borderBottom }) => borderBottom || "none"};
  border-top: ${({ borderTop }) => borderTop || "none"};
  display: ${({ displayTitle }) => displayTitle || "block"};
  text-align: ${({ textAlign }) => textAlign || "inherit"};
  width: ${({ width }) => width};

  ${sizeLaptop(`
        padding: 0px 25px 50px 25px;
    `)};

  ${sizeTabletLg(`
        padding: 0px 15px 40px;
    `)}
  ${TitleSm} {
    max-width: 100em;
  }
  ${TitleXl}{
    font-size: 40px;
    ${sizeTabletLg(`
       font-size: 36px;
`)}
  }


  ${BodySm} {
    margin-bottom: 25px;
  }
  a {
    color: #0F20FF;
    text-decoration: underline;
  }
  .cta {
    text-decoration: none;
  }
`;

export const SectionContent = styled.div`
  padding: 56px 104px;

  ul {
    padding: 0;
    margin: 20px 0 20px 64px;
  }

  ${sizeLaptop(`
    padding: 40px 25px;
  `)}
  ${sizeWideScreen(`
    border-left: 1px solid #707070;
    border-right: 1px solid #707070;
  `)}
  &.services {
    p, h2, h3, h4, h5, h6 {
      margin-bottom: 20px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
`;

export const SectionContentContainer = styled.div`
  max-width: 900px;
  padding: 0;

  p {
    margin: 15px 0;
  }

  margin: 0 auto;

  ${sizeWideScreen(`
        max-width: 2500px;
        padding: 80px 104px;
    `)}
    // h4
  ${TitleSm} {
    ${sizeMobileMd(`
        font-weight:600;
        font-size:1.3em;
    `)};
  }

`;
export const TitleIcon = styled.img`
  margin-left: 15px;
  object-fit: contain;
  width: 70px;
  height: 70px;

  &.wide {
    width: auto;
    height: 70px;
  }

  ${sizeTabletLg(`
        margin-bottom: 15px;
        margin-left: 0;
        width: 55px;
        height: 55px;
    `)}
`;

export const ScrollStoper = styled.div`
  width: 100%;
  height: 100px;
  background: #000;
  position: absolute;
  bottom: 0;
  z-index: -1;
  visibility: hidden;
`;

// Grid  Start
export const Row = styled.div`
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: ${({ flexWrap }) => flexWrap || "wrap"};
  flex-wrap: ${({ flexWrap }) => flexWrap || "wrap"};
  -webkit-box-pack: ${({ justifyContent }) => justifyContent || "start"};
  -ms-flex-pack: ${({ justifyContent }) => justifyContent || "start"};
  justify-content: ${({ justifyContent }) => justifyContent || "space-between"};
  -webkit-box-align: ${({ alignItems }) => alignItems || "start"};
  -ms-flex-align: ${({ alignItems }) => alignItems || "start"};
  -ms-grid-row-align: ${({ alignItems }) => alignItems || "flex-start"};
  align-items: ${({ alignItems }) => alignItems || "flex-start"};
  margin: ${({ margin }) => margin || "0"};
  column-gap: ${({ colGap }) => colGap || "0"};
  row-gap: ${({ rowGap }) => rowGap || "0"};

  ${props => props.mobileColumn && css`
 
    @media only screen and (max-width: ${device.mobileLg}) {
        flex-direction: ${props.mobileColumn};

    }
`}
`;
export const Col = styled.div`
  width: 100%;
  max-width: 100%;
  -webkit-box-flex: 1;
  -ms-flex: 1 1 0;
  flex: 1 1 0;
  padding: ${({ padding }) => padding || "0"};
  margin: ${({ margin }) => margin || "0"};
  box-sizing: border-box;
`;
export const Col1 = styled.div`
  -webkit-box-flex: 0;
  -ms-flex: 0 0 8.333333%;
  flex: 0 0 8.333333%;
  max-width: 8.333333%;
  width: 100%;
  padding: ${({ padding }) => padding || "0"};
  box-sizing: border-box;
`;
export const Col2 = styled.div`
  -webkit-box-flex: 0;
  -ms-flex: 0 0 16.666667%;
  flex: 0 0 16.666667%;
  max-width: 16.666667%;
  width: 100%;
  padding: ${({ padding }) => padding || "0"};
  box-sizing: border-box;
`;
export const Col3 = styled.div`
  -webkit-box-flex: 0;
  -ms-flex: 0 0 25%;
  flex: 0 0 25%;
  max-width: 25%;
  width: 100%;
  padding: ${({ padding }) => padding || "0"};
  box-sizing: border-box;
`;
export const Col4 = styled.div`
  -webkit-box-flex: 0;
  -ms-flex: 0 0 33.333333%;
  flex: 0 0 33.333333%;
  max-width: 33.333333%;
  width: 100%;
  padding: ${({ padding }) => padding || "0"};
  box-sizing: border-box;
`;

export const Col5 = styled.div`
  -webkit-box-flex: 0;
  -ms-flex: 0 0 41.666667%;
  flex: 0 0 41.666667%;
  max-width: 41.666667%;
  width: 100%;
  padding: ${({ padding }) => padding || "0"};
  margin: ${({ margin }) => margin || "0"};
  box-sizing: border-box;
`;
export const Col6 = styled.div`
  -webkit-box-flex: 0;
  -ms-flex: 0 0 50%;
  flex: 0 0 50%;
  max-width: 50%;
  width: 100%;
  padding: ${({ padding }) => padding || "0"};
  box-sizing: border-box;
  height: ${({ height }) => height || "auto"};
  margin: ${({ margin }) => margin || "0"};

  &.stretch {
    align-self: stretch;
  }
`;
export const Col7 = styled.div`
  -webkit-box-flex: 0;
  -ms-flex: 0 0 58.333333%;
  flex: 0 0 58.333333%;
  max-width: 58.333333%;
  width: 100%;
  padding: ${({ padding }) => padding || "0"};
  box-sizing: border-box;
  text-align: ${({ textAlign }) => textAlign || undefined};
`;
export const Col8 = styled.div`
  -webkit-box-flex: 0;
  -ms-flex: 0 0 66.666667%;
  flex: 0 0 66.666667%;
  max-width: 66.666667%;
  width: 100%;
  padding: ${({ padding }) => padding || "0"};
  box-sizing: border-box;
`;
export const Col9 = styled.div`
  -webkit-box-flex: 0;
  -ms-flex: 0 0 75%;
  flex: 0 0 75%;
  max-width: 75%;
  width: 100%;
  padding: ${({ padding }) => padding || "0"};
  box-sizing: border-box;
`;
export const Col10 = styled.div`
  -webkit-box-flex: 0;
  -ms-flex: 0 0 83.333333%;
  flex: 0 0 83.333333%;
  max-width: 83.333333%;
  width: 100%;
  padding: ${({ padding }) => padding || "0"};
  box-sizing: border-box;
`;
export const Col11 = styled.div`
  -webkit-box-flex: 0;
  -ms-flex: 0 0 91.666667%;
  flex: 0 0 91.666667%;
  max-width: 91.666667%;
  width: 100%;
  padding: ${({ padding }) => padding || "0"};
  box-sizing: border-box;
`;
export const Col12 = styled.div`
  -webkit-box-flex: 0;
  -ms-flex: 0 0 100%;
  flex: 0 0 100%;
  max-width: 100%;
  width: 100%;
  padding: ${({ padding }) => padding || "0"};
  box-sizing: border-box;
`;
// Grid  End


export const CtaWrapper = styled.div`
  border-top: 1px solid #707070;
  border-bottom: 1px solid #707070;
  padding: 64px 104px;

  ${sizeLaptop(`
    padding: 32px 25px;
  `)}
  ${Col6} {
    ${sizeTabletLg(`
        -webkit-box-flex: 0;
        -ms-flex: 0 0 100%;
        flex: 0 0 100%;
        max-width: 100%;
        width: 100%;
        box-sizing: border-box;
        padding:1em 0;
      `)}
  }
`;


export const Br = styled.br`

`;


export const BackArrow = styled.button`
  position: absolute;
  left: 40px;
  top: 40px;
  z-index: 50;
  transition: 0.4s all ease;
  max-width: 360px;
  background-color: transparent;
  cursor: pointer;
  border: none;
  display: flex;
  align-items: center;
  ${TitleXs} {
    font-size: 14px;
    color: #fff;
    font-size: 24px;
    line-height: 28px;
    font-weight: 400;
  }
  
  ${sizeMobileLg(`
        left: 25px;
        top: 30px;
        max-width: 200px;
        ${TitleXs}{
          font-size: 20px;
          line-height: 24px;
          margin: 0 0 0 0 !important;
        }
        svg {
          width: 52px; 
        }        
    `)}

  ${sizeMobileXs(`
       left: 20px;
       ${TitleXs}{
        font-size: 16px;
        line-height: 19px;
      }
  `)}

    line, path { 
      stroke: #fff;
    }
  &:hover {
    left: 30px;
    ${TitleXs} {
      // color: #D12FFF;
    }
    ${sizeMobileLg(`
        left: 20px;
    `)}
    line, path {
      // stroke: #D12FFF;
    }
  }
`;

export const BackArrowLink = styled(Link)`
  position: absolute;
  left: 40px;
  top: 40px;
  z-index: 50;
  transition: 0.4s all ease;
  max-width: 600px;
  background-color: transparent;
  cursor: pointer;
  border: none;
  display: flex;
  align-items: center;
  ${TitleXs} {
    font-size: 14px;
  }
  
  ${sizeMobileLg(`
        left: 25px;
        top: 25px;
        svg {
          width: 50px; 
        }        
    `)}
  &:hover {
    left: 30px;

    ${TitleXs} {
      color: #D12FFF;
    }

    ${sizeMobileLg(`
      left: 20px;
    `)}
    
    line, path {
      stroke: #D12FFF;
    }
  }
`;
export const BackArrowIcon = styled.svg`
  width: 100%;
`;


// ul & ol
export const UL = styled.ul`
  padding: ${({ padding }) => padding || "inherit"};
  margin: ${({ margin }) => margin || "0 0 0 48px"};
  list-style: disc;
`;
export const OL = styled.ol`
  padding: ${({ padding }) => padding || "0"};
  margin: ${({ margin }) => margin || "0 0 0 48px"};
  font-variant-numeric: normal;
  list-style-type: decimal;
  li {
    padding-left: 7px;
    p {
      display: block;
    }
    &::marker {
      font-variant-numeric: normal;
    }
  }
`;
export const LI = styled.li`
  padding: ${({ padding }) => padding || "0"};
  margin: ${({ margin }) => margin || "0 0 20px 0"};
  p {
    margin: 0 !important;
    display: block;
  }
`;

// blockquote
export const Quote = styled.blockquote`
  font-size: ${({ fontSize }) => fontSize || "2.125em"};
  font-weight: ${({ fontWeight }) => fontWeight || "400"};
  text-transform: ${({ textTransform }) => textTransform || "inherit"};
  color: ${({ color }) => color || "#000"};
  font-family: ${({ fontFamily }) => fontFamily || "HelveticaNowDisplay"};
  font-style: ${({ fontStyle }) => fontStyle || "inherit"};
  line-height: ${({ lineHeight }) => lineHeight || "1.2"};
  text-align: ${({ textAlign }) => textAlign || "inherit"};

  ${sizeLaptopMd(`
        font-size: 1.625em;
    `)}
  p {
    font-size: inherit;
    ${sizeLaptopMd(`
            font-size: inherit;
        `)}
  }
`;

// img
export const Image = styled.img`
  width: 100%;
`;

// Link
export const BlogLink = styled.a`
  color: #0F20FF;
`;


export const WideScreenContainer = styled.div`
  max-width: 2500px;
  margin: 0 auto;
  padding: ${({ padding }) => padding || "0"};
  width: 100%;

  ${sizeWideScreen(css`
    border-left: ${({ borderLeft }) => borderLeft || "inherit"};
    border-right: ${({ borderRight }) => borderRight || "inherit"};
    border-top: ${({ borderTop }) => borderTop || "inherit"};
    border-bottom: ${({ borderBottom }) => borderBottom || "inherit"};
    position: relative;
  `)}
`;
// Global Elements End


export const Desktop = styled.div`
  display: block;
  width: 100%;
  height: 100%;
  padding: ${({ headerPad }) => headerPad || "0 0 0"};
  ${sizeTabletLg(`
        display: none;
    `)}
`;
export const Mobile = styled.div`
  display: none;
  width: 100%;
  height: 100%;
  ${sizeTabletLg(`
        display: block;
    `)}
`;


export const ReleventContainer = styled.div`
  ${sizeWideScreen(`
    border-left: 1px solid #707070;
    border-right: 1px solid #707070;
  `)}
`;


export const PageWrapper = styled.div`
  border-left: ${({ borderLeft }) => borderLeft || "none"};
  border-right: ${({ borderRight }) => borderRight || "none"};
  border-top: ${({ borderTop }) => borderTop || "none"};
  border-bottom: ${({ borderBottom }) => borderBottom || "none"};
  padding: ${({ padding }) => padding || "0"};
  margin: ${({ margin }) => margin || "0"};

  &.blog-detail {
    ${sizeWideScreen(`
        margin-top: 70px;
      `)}
  }
`;



export const GraphImage = styled.img`
  width: 100%;
`;
export const GraphWrap = styled.div`
  position: relative;
  font-size: 0;
`;

export const DropdownIcon = styled.img`
  width: 22.5px;
  position: relative;
  cursor: pointer;
  height: 12.16px;
  margin: 10px 20px 10px 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  background-color: #FFF;
  transform: ${({ clicked }) => clicked ? "" : "rotate(180deg)"};
  transition: .5s all ease;
`;


//  form inputs CSS
export const InputWrap = styled.div`
  margin-bottom: 40px;
  display: flex;
  flex-direction: column;
  ${Label} {
    margin-bottom: 8px;
    text-transform: uppercase;
  }
`;
export const FormInput = styled.input`
  border: 1px solid #999999;
  box-sizing: border-box;
  padding: 16px 16px;
  font-weight: 400;
  font-size: 20px;
  color: #000000;
  `;

export const Video = styled.video`
  object-fit: cover;
  width: auto;
  max-width: 100%;

  height: 100%;

    ${sizeTabletLg(`
        position: relative;
        width: 100%;
        height: 100%; 
    `)}

`;
