import styled, { css } from "styled-components";
import {BodyRg, TitleLg, TitleXs} from "components/elements";
import { sizeMobileLg, sizeMobileSm, sizeWideScreen } from "components/devices";

export const ServicesProvidersPageWrap = styled.div`
  padding: 70px 0 50px;
  ${TitleLg} {
    margin-bottom: 25px;
  }
`;
export const ServicesContainer = styled.div`
  max-width: 915px;
  padding: 0 25px;
  width: 100%;
  margin: 0 auto;
  ${sizeMobileLg(`
        padding: 0 15px;
    `)}


    ${sizeWideScreen(css`
      max-width: 2500px;
      width: 100%;
      margin: 0 auto;
     
      ${BodyRg} {
        width: 100%;
    font-size: 0.7vw !important;
      }
    `)}
`;
export const ServicesTable = styled.div`
  margin-top: 50px;
  ${sizeMobileLg(`
        overflow-x: scroll;
    `)}
`;
export const Table = styled.table`
  border-collapse: collapse;
  border-spacing: 0;
  ${sizeMobileSm(`
        width: 375px;
    `)}

${sizeWideScreen(`
  width: 100%;
  font-size: 0.8vw !important;
`)}
`;
export const TableHead = styled.thead``;
export const TableBody = styled.tbody``;
export const TableRow = styled.tr``;
export const Th = styled.th`
  border: 1px solid #707070;
  width: 33%;
  padding: 13px;
  box-sizing: border-box;
  vertical-align: top;
  text-align: left;
  ${sizeMobileLg(`
        padding: 10px;
    `)}
`;
export const TableData = styled.td`
  border: 1px solid #707070;
  width: 33%;
  padding: 13px;
  box-sizing: border-box;
  vertical-align: top;
  ${sizeMobileLg(`
        padding: 10px;
    `)}
`;

export const PreviousVersions = styled.div`
  display: flex;
  margin-top: 10px;
  a {
    margin-left: 5px;
    ${TitleXs} {
      color: blue;
      height: 100%;
      display: flex;
      align-items: flex-end;
    }
  }

`
