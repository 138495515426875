import React from 'react'


function CloseIconSvg() {

    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            xlink="http://www.w3.org/1999/xlink"
            width="84"
            height="84"
            viewBox="0 0 84 84"
        >
            <defs>
                <clipPath id="clip-path">
                    <rect
                        id="Rectangle_1707"
                        data-name="Rectangle 1707"
                        width="84"
                        height="84"
                        fill="none"
                        stroke="#000"
                        strokeWidth="4"
                    />
                </clipPath>
            </defs>
            <g
                id="Group_1731"
                data-name="Group 1731"
                clipPath="url(#clip-path)"
            >
                <path
                    id="Path_822"
                    data-name="Path 822"
                    d="M46.855,85.21h0A38.355,38.355,0,0,1,8.5,46.855h0a38.355,38.355,0,0,1,76.71,0h0A38.355,38.355,0,0,1,46.855,85.21Z"
                    transform="translate(-4.855 -4.855)"
                    fill="none"
                    stroke="#000"
                    strokeLinejoin="bevel"
                    strokeWidth="4"
                />
                <line
                    id="Line_454"
                    data-name="Line 454"
                    x2="27.443"
                    y2="27.443"
                    transform="translate(28.279 28.279)"
                    fill="none"
                    stroke="#000"
                    strokeLinejoin="bevel"
                    strokeWidth="4"
                />
                <line
                    id="Line_455"
                    data-name="Line 455"
                    x1="27.443"
                    y2="27.443"
                    transform="translate(28.278 28.279)"
                    fill="none"
                    stroke="#000"
                    strokeLinejoin="bevel"
                    strokeWidth="4"
                />
            </g>
        </svg>


    )
}

export default CloseIconSvg;
