import React from 'react';

import {
    BodyRg,
    BodySm
} from "components/elements";

import {
    CopyrightsWrap,
    CopyContainer
} from "./elements";


function Copyrights({ body }) {
    return (
        <CopyrightsWrap>
            <CopyContainer>
                <BodyRg dangerouslySetInnerHTML={{ __html: body }}></BodyRg>
                <BodySm fontSize="24px">&copy; {(new Date().getFullYear())} Real Chemistry. All rights reserved.</BodySm>
            </CopyContainer>
        </CopyrightsWrap>
    );
}

export default Copyrights;
