import React, { useEffect } from 'react';


import {
    CookieDetailWrap,
    CookieContainer,

} from "./elements";


function TermsOneTrust() {

    useEffect(() => {
        window.OneTrust.NoticeApi.Initialized.then(function () {
            window.OneTrust.NoticeApi.LoadNotices(["https://privacyportal-cdn.onetrust.com/6cdc31d6-1e41-47c4-9fcc-e505fd9cbc62/privacy-notices/draft/5e558c6f-9640-49c0-a83c-1b22991b63b5.json"], false);
        });
    }, [])
    return (
        <CookieDetailWrap>
            <CookieContainer>


                <div id="otnotice-5e558c6f-9640-49c0-a83c-1b22991b63b5" className="otnotice"></div>
                {/* <SectionTitle textAlign="center">
                    <TitleXl>Terms of Service and Ad & Cookie Policy</TitleXl>
                </SectionTitle>

                <TitleSm>Acceptance of terms.</TitleSm>
                <BodyRg>
                    By using any of our web Properties (as defined in our <PrivacyLink href="/privacy-policy">Privacy Policy</PrivacyLink>),
                    you agree to the terms of this Terms of Service and Ad & Cookie Policy (the “Agreement”).
                    We may make changes to this Agreement from time to time and will notify you of any material changes.
                    Your use of the Properties constitutes your acceptance of the terms of this Agreement.
                </BodyRg>

                <TitleSm>We use various tracking technologies.</TitleSm>
                <BodyRg>
                    We collect personal information about users over time and across different websites
                    when you use this website or service. We also have third parties that collect
                    personal information this way. To do this, we use several tracking tools.
                    Our vendors may also use these tools. In this policy “we” and “us” refers to both W2O Group and our vendors.
                    These tools may include browser cookies. We may also use web beacons, flash cookies, and similar technologies.
                </BodyRg>
                <BodyRg>
                    Our Data Warehouse also houses media performance information which we collect through
                    various websites as part of rendering services to our clients. For example, we may
                    collect metrics on the performance of an advertising campaign done through Facebook,
                    which will be collected through tracking tools provided via the Facebook platform.
                </BodyRg>

                <TitleSm>We use tracking technologies for a variety of reasons.</TitleSm>
                <BodyRg>
                    We use tracking tools, including cookies, on our websites. Third parties also use cookies on our sites.
                    Cookies are small files that download when you access certain websites. To learn more,
                    visit <PrivacyLink target="_blank" href="http://www.allaboutcookies.org/">here</PrivacyLink> or
                    <PrivacyLink target="_blank" href="http://www.youronlinechoices.eu/"> here</PrivacyLink>.
                    These pages also explain how you can manage and delete cookies.
                </BodyRg>

                <BodyRg>We use tracking tools:</BodyRg>
                <List>
                    <ListItem>
                        <BodyRg>To recognize new or past users.</BodyRg>
                    </ListItem>
                    <ListItem>
                        <BodyRg>To store your password if you are registered on our site.</BodyRg>
                    </ListItem>
                    <ListItem>
                        <BodyRg>To improve our website and to better understand your visits on our platforms.</BodyRg>
                    </ListItem>
                    <ListItem>
                        <BodyRg>To integrate with third party social media websites.</BodyRg>
                    </ListItem>
                    <ListItem>
                        <BodyRg>To serve you with interest-based or targeted advertising on social media platforms.</BodyRg>
                    </ListItem>
                    <ListItem>
                        <BodyRg>To observe your behaviors and browsing activities over time across multiple websites or other platforms.</BodyRg>
                    </ListItem>
                    <ListItem>
                        <BodyRg>To better understand the interests of our customers and our website visitors.</BodyRg>
                    </ListItem>
                    <ListItem>
                        <BodyRg>To track which recipients open the emails we send and whether they view links and other content.</BodyRg>
                    </ListItem>
                </List>

                <TitleSm>You can control certain tracking tools.</TitleSm>
                <BodyRg>
                    Your browser may give you the ability to control cookies. How you do so
                    depends on the type of cookie. Certain browsers can be set to reject browser cookies.
                    To find out more about how to enable, disable, or delete cookies from your web browser,
                    please visit <PrivacyLink target="_blank" href="http://www.allaboutcookies.org/">here</PrivacyLink>.
                    To control flash cookies, which we may use on certain websites from time to time,
                    you can go <PrivacyLink target="_blank" href="http://www.macromedia.com/support/documentation/en/flashplayer/help/settings_manager07.html">here</PrivacyLink>.
                    Why? Because flash cookies cannot be controlled through your browser settings.
                </BodyRg>
                <BodyRg>
                    <Bold>Our Do Not Track Policy: Some browsers have “do not track” features that allow you to tell a website not to track you.
                    These features are not all uniform. We do not currently respond to those signals.</Bold> If you block cookies, certain features
                    on our sites may not work.
                    If you block or reject cookies, not all of the tracking described here will stop.
                </BodyRg>
                <BodyRg>Certain options you select are browser and device specific.</BodyRg>

                <TitleSm>You can opt-out of online behavioral advertising.</TitleSm>
                <BodyRg>
                    The Self-Regulatory Program for Online Behavioral Advertising program provides consumers
                    with the ability to opt-out of having their online behavior recorded and used for advertising purposes.
                    To opt out of having your online behavior collected for advertising purposes, click <PrivacyLink target="_blank" href="http://www.aboutads.info/choices/">here</PrivacyLink>
                </BodyRg>
                <BodyRg>
                    Certain choices you make are both browser and device-specific.
                </BodyRg>

                <TitleSm>We use specific tracking technologies.</TitleSm>
                <BodyRg>
                    Here is more information about the tracking technologies and cookies we
                    currently use on <PrivacyLink target="_blank" className="external" href="https://www.w2ogroup.com">www.w2ogroup.com</PrivacyLink> and our other Properties.
                </BodyRg>
                <BodyRg>To access cookie settings, please visit the Cookie Preference Center here.</BodyRg>
                <List>
                    <ListItem>
                        <BodyRg>
                            <Bold>DoubleClick:</Bold> Google’s Doubleclick re-targeting cookie lets us serve
                            personalized adverts to you when you’re browsing other websites and social
                            media platforms You can control advert personalization on Google and partner websites here: <PrivacyLink target="_blank" href="https://adssettings.google.com.">https://adssettings.google.com.</PrivacyLink>
                        </BodyRg>
                    </ListItem>
                    <ListItem>
                        <BodyRg>
                            <Bold>Disqus:</Bold> You may publish your own comments to our Properties using Disqus, our comments platform.
                                Comments published to our Properties are subject to our privacy policy as well as the Disqus privacy policy,
                                which may be found at the following url: <PrivacyLink className="external" target="_blank" href="https://help.disqus.com/customer/portal/articles/466259-privacy-policy">https://help.disqus.com/customer/portal/articles/466259-privacy-policy</PrivacyLink>.
                                You may opt-out of Disqus targeting by visiting the following url: <PrivacyLink className="external" target="_blank" href="https://help.disqus.com/customer/portal/articles/1657951">https://help.disqus.com/customer/portal/articles/1657951</PrivacyLink>.
                            </BodyRg>
                    </ListItem>
                    <ListItem>
                        <BodyRg>
                            <Bold>Facebook Connect:</Bold> We may allow you to sign up and log in using your Facebook account.
                            If you sign up using Facebook Connect, Facebook will ask your permission to share
                            certain information from your Facebook account with us. This may include your first name,
                            last name, email address in order for us to verify your identity and gender, general location,
                            a link to your Facebook profile, your timezone, birthday, profile picture, your “likes”
                            and your list of friends. This information is collected by
                            Facebook and is provided to us under the terms of Facebook’s privacy policy which you can find here: <PrivacyLink
                                className="external"
                                target="_blank"
                                href="https://www.facebook.com/policy.php."
                            >https://www.facebook.com/policy.php</PrivacyLink>.
                            You can control the information that we receive from Facebook using the privacy settings in your Facebook account.
                        </BodyRg>
                    </ListItem>
                    <ListItem>
                        <BodyRg><Bold>Facebook Impressions:</Bold> We use Facebook Impressions to track the number of people that interact with our content on Facebook.</BodyRg>
                    </ListItem>
                    <ListItem>
                        <Bold>Google Tag Manager:</Bold> Our Properties also use Google Tag Manager.
                        This service allows website tags to be managed via an interface. Tags are small elements of
                        code that are used, for example, to measure traffic and visitor behavior, to understand the
                        effect of online advertising and social channels, to set up remarketing and orientation towards
                        target groups, and to test and optimize websites. Google Tag Manager only implements tags.
                        This means that no cookies are used and, as a result, no personal data is recorded. We list it
                        here for completeness and to be transparent about the tools we use. If deactivation has been performed at a domain or cookie level,
                        this remains in place for all tracking tags if these are implemented with Google Tag Manager.
                    </ListItem>
                    <ListItem>
                        <BodyRg>
                            <Bold>Google Analytics:</Bold> Our Properties use Google Analytics, a web analytics service.
                            Google Analytics uses cookies to help the website analyze how visitors use the site.
                            The information generated by the cookie about your use of the website (including your IP address)
                            will be transmitted to and stored by a Google server in the United States. Google uses this
                            information for the purpose of evaluating your use of the website, compiling reports on website activity
                            for website operators and providing website operators with other services relating to website activity
                            and internet usage. You can prevent the storage of data relating to your use of the website and created
                            via the cookie (including your IP address) by Google as well as the processing
                            of this data by Google by downloading and installing the browser plug-in available at the following link: <PrivacyLink
                                className="external"
                                target="_blank"
                                href="https://tools.google.com/dlpage/gaoptout?hl=en."
                            >
                                https://tools.google.com/dlpage/gaoptout?hl=en.</PrivacyLink>
                        </BodyRg>
                    </ListItem>
                    <ListItem>
                        <BodyRg>
                            <Bold>LinkedIn Widgets:</Bold> This tool enables visitors to engage with us via
                            LinkedIn and show visitors relevant ads and personalized
                            content on LinkedIn. To learn more about LinkedIn’s practices and to opt out,
                            please visit <PrivacyLink
                                className="external"
                                target="_blank"
                                href="https://www.linkedin.com/legal/privacy-policy">
                                https://www.linkedin.com/legal/privacy-policy
                            </PrivacyLink> and <PrivacyLink
                                className="external"
                                target="_blank"
                                href="https://www.linkedin.com/settings/?tab=account&modal=nsettings-enhanced-advertising.">
                                https://www.linkedin.com/settings/?tab=account&modal=nsettings-enhanced-advertising.</PrivacyLink>.
                        </BodyRg>
                    </ListItem>
                    <ListItem>
                        <BodyRg>
                            <Bold>Twitter Button:</Bold> This enables visitors to engage with us via Twitter and allows us
                            to show visitors relevant ads and personalized content on Twitter.
                            To learn more about Twitter’s practices and to opt out, please visit <PrivacyLink
                                className="external"
                                target="_blank"
                                href="https://twitter.com/privacy?lang=en"
                            >https://twitter.com/privacy?lang=en</PrivacyLink>
                            and select “Do Not Track” in certain browser settings and here: <PrivacyLink
                                className="external"
                                target="_blank"
                                href="https://help.twitter.com/en/rules-and-policies/twitter-cookies#"
                            >
                                https://help.twitter.com/en/rules-and-policies/twitter-cookies#
                            </PrivacyLink>.
                        </BodyRg>
                    </ListItem>
                    <ListItem>
                        <BodyRg>
                            <Bold>Twitter Syndication:</Bold> We use this to provide a Twitter button on our Properties.
                            Twitter will be collecting additional data. View their Privacy Policy for more details: <PrivacyLink
                                className="external"
                                target="_blank"
                                href="https://twitter.com/privacy?lang=en"
                            >
                                https://twitter.com/privacy?lang=en
                            </PrivacyLink> and here: <PrivacyLink
                                className="external"
                                target="_blank"
                                href="https://help.twitter.com/en/rules-and-policies/twitter-cookies#"
                            >
                                https://help.twitter.com/en/rules-and-policies/twitter-cookies#
                            </PrivacyLink>.
                        </BodyRg>
                    </ListItem>
                </List> */}

            </CookieContainer>
        </CookieDetailWrap>
    );
}

export default TermsOneTrust;
