import styled from "styled-components";

import {

    sizeMobileLg,

} from "components/devices";


export const NewsletterSignupWrap = styled.div`
 width: 100%;
 height: 100%;
 padding: 5% 0 0 2%;
 display: flex;
 flex-direction: row;
 align-items: flex-start;
 justify-content: space-between;
 position: relative;

 ${sizeMobileLg(`
    padding: 15% 0 0 15px;
    flex-direction: column;
`)}
`

export const CopyAndFormContainer = styled.div`
    flex: 0 0 50%;
    ${sizeMobileLg(`
        width: 100%;
         padding: 0 15px 0 0;
    `)}

`


export const FormContainer = styled.div`
    padding: 1% 0 0;


`

export const PhoneContainer = styled.div`
    flex: 0 0 40%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
        .phone {
            width: 95%;
        }
        ${sizeMobileLg(`
            width: 100%;

     `)}
`