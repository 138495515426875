import React from 'react'


function AudioPlayIcon() {

    return (
        <svg className={'audioPlayIcon'}  width="60" height="61" viewBox="0 0 60 61" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M23.6992 17.6953V42.6953" stroke="black" strokeWidth="3" strokeMiterlimit="10"/>
            <path d="M36.3008 17.6953V42.6953" stroke="black" strokeWidth="3" strokeMiterlimit="10"/>
            <path
                d="M30 56.1953C44.3594 56.1953 56 44.5547 56 30.1953C56 15.8359 44.3594 4.19531 30 4.19531C15.6406 4.19531 4 15.8359 4 30.1953C4 44.5547 15.6406 56.1953 30 56.1953Z"
                stroke="black" strokeWidth="3" strokeMiterlimit="10" strokeLinecap="square"/>
        </svg>


    )
}

export default AudioPlayIcon;
