import React, { useState, useEffect } from 'react';
import { Helmet } from "react-helmet";
import {

    WideScreenContainer, 
} from 'components/elements'

import { findContent, getImageDimensions } from "../../generalHelpers";
import { r3cPageContent } from "../../constants";
import PurpleAnimHeader from 'components/PurpleAnimHeader';

import FooterAnimation from 'components/FooterAnimation'
import LoadingHoc from '../../hoc/LoadingHOC'
import { NewslettersPageWrap } from './elements';
// import useRememberScrollPosition from "../../hooks/useRememberScrollPosition";
import ExploreOtherNewsletters from 'components/ExploreOtherNewsletters';



function Newsletters(props) {

	const {setLoading} = props		 //receive the loading trigger change function from the HOC
	const [heroSectionData, setHeroSectionData] = useState({});
	const [textSectionData, setTextSectionData] = useState({});
	const [cards, setCards] = useState({});
	const [header, setHeader] = useState({});
	const [seoData, setSeoData] = useState({});

	useEffect(() => {
		fetch(r3cPageContent + '?route=/newsletters')
		  .then(response => response.json())
		  .then(page => {
			let seo = page.seo;
			let image = page?.seo?.image?.split('.');
	
			if (page?.seo?.image) {
			  getImageDimensions(page?.seo?.image, (width, height) => {
				setSeoData({
				  ...seo,
				  mimeType: image[image.length - 1],
				  seoImageHeight: height,
				  seoImageWidth: width
				});
			  });
			} else {
			  setSeoData(seo);
			}
	
			setHeroSectionData(findContent(page.contents, 'heroArea'));
			setTextSectionData(findContent(page.contents, 'textArea'));
			setHeader(findContent(page.contents, 'region', 1));
			setCards(findContent(page.contents, 'region', 2));
	
			setLoading(false);
		  });
		  setLoading(false)
	  }, [setLoading]);
	return (
		<NewslettersPageWrap className="pageWrapper">
			<Helmet>
				<title>{seoData.title}</title>
				<link rel="canonical" href={window.location.pathname}/>
				<meta name="description" content={seoData.description}/>
				<meta name="og:description" content={seoData.description}/>
				<meta name="og:title" content={seoData.title}/>
				<meta name="og:url"
					  content={window.location.protocol + "//" + window.location.host + window.location.pathname}/>
				<meta name="og:type" content="website"/>
				<meta name="og:site_name" content="Real Chemistry"/>

				<meta name="og:image" content={window.location.protocol + seoData.image}/>
				<meta name="og:image:type" content={"image/" + seoData?.mimeType}/>
				<meta name="og:image:width" content={seoData?.seoImageWidth}/>
				<meta name="og:image:height" content={seoData?.seoImageHeight}/>
			</Helmet>
			<PurpleAnimHeader
                sectionLabel={heroSectionData.label}
                sectionTitle={undefined}
				heroIntro={undefined}
				headerSplit={"2"}
        		backgroundImage={heroSectionData.heroImage}
				backgroundImgMbl={heroSectionData.heroImage}
				backgroundImgTitle={""}
				header={heroSectionData?.textArea?.content?.[0]?.content?.[0]?.value || undefined}
				introCopy={textSectionData?.text?.content?.[0]?.content?.[0]?.value || undefined}
                titleMdPadding={"25% 0 0"}
				maxWidth={"30%"}
				marginRight={"15%"}
				maxHeight={"700px"}
				headerMaxWidth="40%"

            />
			<WideScreenContainer>
				<ExploreOtherNewsletters header={header} cards={cards}/>
			</WideScreenContainer>

			<FooterAnimation
				svgDesktop="/images/purple-footer.svg"
				svgMobile="/images/purple-footer-mbl.svg"
				borderTop="none"
			/>
		</NewslettersPageWrap>
	)
}

// Wrap the component with LoadingHOC and send in the component.

export default LoadingHoc(Newsletters)
