import styled from "styled-components";
import {

    TitleXl,

    TitleSm,
    BodySm,

} from "components/elements";
import {

    sizeLaptop,
    sizeMobileLg,

} from "components/devices";




export const ContactUsHeader = styled.div`
    flex: 0 0 45%;
    width: 100%;
    min-height: 1000px;
    background-image: url("/images/contact-us-desktop.png");
    -webkit-background-size: cover;
    -moz-background-size: cover;
    background-size: cover; 
    padding: 10% 3%;
    ${sizeLaptop(`
        min-height: 870px;
    `)}
    ${sizeMobileLg(`
        min-height: 500px;
        padding: 35% 15px 0;
    `)}
`


export const TitleSmContainer= styled.div`
    font-size: .5em;
    ${TitleSm} {
        color: #fff;
        ${sizeMobileLg(`
        font-size: 15px !important;
        line-height: 20px !important;
   `)}
}

`

export const BodySmContainer= styled.div`
    padding: 6% 0 0;
    max-width: 75%;
    ${BodySm} {
        color: #fff;
        font-weight: 400;
        font-size: 20px;
        line-height: 24px;
    ${sizeMobileLg(`
        font-size: 16px !important;
        line-height: 19px !important;
    `)}
    }
    ${sizeMobileLg(`
        max-width: 100%;
    `)}

}

`

export const TitleXlContainer= styled.div`
    margin: 3% 0 0;
    font-size: 1.125em;
    ${sizeMobileLg (`
        margin: 5% 0 0;
        width: 100%;
    `)}
${TitleXl} {
    font-weight: 900;
    color: #fff;
    padding: 0 0 5px;
    .magenta {
        color: #D12FFF;
    }
    .hide-mobile {
        display: none;
    }
    ${sizeMobileLg(`
        font-size: 40px !important;
        padding: 0 0 0;
   `)}
}

`