
import styled, { css } from 'styled-components'

import {
    BodyRg,
    BodySm
} from 'components/elements'

import {
    sizeMobileLg,
    sizeTabletLg,
    sizeWideScreen,
    sizeXlDevice
} from 'components/devices'

export const AuthorInfoWrap = styled.div`
    margin-top: 20px;
    border-top: 1px solid #000;
    border-bottom : 1px solid #000;
    padding: 20px 15px 0 15px;
    width: 100%;
    align-self: center;
    
    ${BodyRg} {
        /* For 4k Device */
        ${sizeXlDevice(`
            font-size: 1vw;
        `)}

        /* Wide Screen CSS */
        ${sizeWideScreen(`
            font-size: 1vw;
        `)}
    }
    ${sizeTabletLg(`
        text-align: center;
        padding: 15px;
    `)}
    ${BodySm} {
        /* For 4k Device */
        ${sizeXlDevice(`
            font-size: 0.7vw;
        `)}

        /* Wide Screen CSS */
        ${sizeWideScreen(`
            font-size: 0.7vw;
        `)}
    }
    ${sizeTabletLg(css`
    margin-bottom: 45px;
        p {
            margin: 0;
        }
    `)}
`;
export const AuthorRow = styled.div`
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    ${sizeTabletLg(`
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding-bottom: 25px;
        &:last-child {
            padding-bottom: 0;
        }
    `)}
`;
export const AuthorColLeft = styled.div`
    align-self: center;
    box-sizing: border-box;
    flex: 1 !important;
    flex-wrap: no-wrap;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 30%;
            flex: 0 0 30%;
            align-self: flex-start;
    ${sizeTabletLg(`
        width: 100%;
        -webkit-box-flex: 0;
        -ms-flex: 0 0 100%;
                flex: 0 0 100%;
    `)}

    ${BodyRg} {
        ${sizeMobileLg(`
            margin-bottom: 0;
            margin-top: 0;
            font-size: 1.3em;
        `)}
    }
`;
export const AuthorColRight = styled.div`
    align-self: center;
    flex: 1.25 !important;
    box-sizing: border-box;
    -webkit-box-flex: 0;
    padding-left: 20px;
    -ms-flex: 0 0 70%;
            flex: 0 0 70%;
            
    ${sizeTabletLg(`
    padding-left: 0;
        width: 100%;
        -webkit-box-flex: 0;
        -ms-flex: 0 0 100%;
                flex: 0 0 100%;
                margin-top: 15px;
    `)}
`;
