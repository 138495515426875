import React, { useEffect, useRef } from 'react'
import { TweenLite } from 'gsap'

import {
    AccordionBody,
    AccordionCardWrap,
    AccordionHeader,
    AccordionToggle,
    Bar
} from './element'

import { SectionContentContainer, TitleSm} from 'components/elements'
import RichText from 'components/RichText'

function GPAccordion( { title, body, cardId, handleStateChange, accordionState, handleOpenAccordion, children, content })
{
	const contentRef = useRef(null)
	const accordionRef = useRef(null)

	const itsMe = (cid) =>
	{
		return ( accordionState[0] && accordionState[0].state && accordionState[0].id === cid )
	}

	useEffect(() =>
	{
		if ( itsMe(cardId) )
		{
			TweenLite.to(contentRef.current, 0.25,
			{
				height: 'auto',
				overflow: 'visible'
			})
		}
		else
		{
			TweenLite.to(contentRef.current, 0.25,
			{
				height: 0,
				overflow: 'hidden'
			})
		}
		/* eslint-disable react-hooks/exhaustive-deps */
	}, [ accordionState ])

	useEffect(() =>
	{
		const accordionEvent = accordionRef.current
		accordionEvent.addEventListener('click', handleOpenAccordion, true)
		return () =>
		{
			accordionEvent.removeEventListener('click', handleOpenAccordion)
		}
		/* eslint-disable react-hooks/exhaustive-deps */
	}, [])

	const handleOnClick = (id) =>
	{
		handleStateChange( id, ! accordionState[0].state )
	}

	return (
	    <AccordionCardWrap id={cardId ? cardId : ''} className="accordion">
		    <AccordionHeader className={ itsMe(cardId) ? 'expanded' : null } onClick={ handleOnClick.bind(null, cardId) } >
			<TitleSm>{title}</TitleSm>
			<AccordionToggle>
			    <Bar />
			    <Bar />
			</AccordionToggle>
		    </AccordionHeader>

		    <AccordionBody ref={contentRef}>
			<div>
			    <div ref={accordionRef}>
				{ children.length > 0 ? children : (content ? <SectionContentContainer><RichText body={content}/></SectionContentContainer> : null) }
			    </div>
			</div>
		    </AccordionBody>
	    </AccordionCardWrap>
	)
}

export default GPAccordion
