import React, { useEffect, useRef, useState, useLayoutEffect } from 'react';
import { withRouter } from "react-router-dom"
import { BodySm, TitleXl } from "components/elements";
import gsap, { Linear } from 'gsap';
import { PainterFourContainer, PinkBorder, Painter4, PainterTwoContainer, Painter3, SlideFiveContainer, InnerContainer5, BgImageFive, TitleXlContainer5, BodySmContainer5, ContentContainer5, BodySmContainer4, PainterThreeContainer, Painter2, SlideFourContainer, InnerContainer4, BgImageFour, ContentContainer4, TitleXlContainer4, HomeAnimationContainer, SlideOneContainer, SlideTwoContainer, SlideThreeContainer, InnerContainer1, InnerContainer2, InnerContainer3, BgImageOne, BgImageThree, BgImageTwo, ContentContainer1, ContentContainer2, ContentContainer3, TitleXlContainer1, BodySmContainer1, BodySmContainer2, TitleXlContainer2, TitleXlContainer3, Painter, PainterOneContainer, ViewAllContainer } from './elements';
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { ScrollToPlugin } from 'gsap/ScrollToPlugin';

import { ExpoScaleEase } from 'gsap/all';
import SplitType from 'split-type'
import ViewAll from 'components/ViewAll';
import { useGSAP } from "@gsap/react";

function HomeAnimationSection(props) {
    gsap.registerPlugin(ScrollTrigger);
    gsap.registerPlugin(ScrollToPlugin);


    ScrollTrigger.refresh();
    ScrollTrigger.clearScrollMemory();

    ScrollTrigger.config({ ignoreMobileResize: true})

    const container = useRef();

    useEffect(() => {
        const handleOnBeforeUnload = (e) => {
          // Perform actions before the component unloads
         e.stopImmediatePropagation();
          window.scrollTo(0, 0);
        };

        window.addEventListener('beforeunload', handleOnBeforeUnload);

        return () => {
          window.removeEventListener('beforeunload', handleOnBeforeUnload);
        };
      },[]);


     useGSAP(() => {

        const slide1SplitText = new SplitType('.slide1SplitText')
        const slide2SplitText = new SplitType('.slide2SplitText')
        const slide5SplitText = new SplitType('.slide5SplitText')
        const slide6SplitText = new SplitType('.slide6SplitText')
        const slide3SplitText = new SplitType('.slide3SplitText')
        const sections = gsap.utils.toArray(".section")
        const group1 = container.current
        let tl;
        let direction;  
        const mm = gsap.matchMedia();


        const scrollPostion = () => {
        // Log the current vertical scroll position to the console
            if (window.scrollY < 80) {
                tl = gsap.timeline({
                    paused: true
                })
            } else {
                // tl.restart() 
            }
        }

            const ctx = gsap.context(() => {

                // Run your GSAP animations
                mm.add("(max-width: 824px)", () => {

                    tl = gsap.timeline({
                        defaults: { ease: 'none' },
                        scrollTrigger: {
                            trigger: group1,
                            id: 'scrollTrig',
                            start: "top +=80px",
                            end: "+=700%",
                            scrub: 1,
                            pin: true,
                            onUpdate: self => {
                            if (self.direction === 1) {
                                direction = self.direction
                            } else {
                                direction =  self.direction
                            }
                            },
                            snap: {
                            snapTo(value, tl, snap) {
                            //  const direction = tl.scrollTrigger ? tl.scrollTrigger.direction : 1;
                                if(group1){
                                    if (direction === 1) {
                                        // Snapping for scrolling down
                                        if (window.scrollY >= 0 && window.scrollY < 50) {
                                            return 0;
                                        } else if (window.scrollY > 100 && window.scrollY < 1608) {
                                            return 0.280;
                                        } else if (window.scrollY > 1609 && window.scrollY < 3238) {
                                            return 0.560;
                                        } else if (window.scrollY > 3239 && window.scrollY < 4560) {
                                            return 0.850;
                                        }
                                        } else {
                                        // Snapping for scrolling up
                                        if (window.scrollY < 1609) {
                                            return 0.02;
                                        } else if (window.scrollY < 3239) {
                                            return 0.280;
                                        } else if (window.scrollY < 4561) {
                                            return 0.560;
                                        } else {
                                            return value;
                                        }
                                        }
                                }
                        
                            }
                            }
                        }
                        });
                        
                
                    tl.add(() => {
                        gsap.to('.slide1SplitText .word', {
                            opacity: 1,
                            delay: 0,
                            duration: .1,
                            y: 0,
                            ease: Linear.easeNone,
                            stagger: {
                                amount: .5, //  the total amount of time (in seconds) that gets split up among all the staggers. 
                                each: 2.4, //The amount of time (in seconds) between each sub-tween's start time.
                                from: "start" // the position in the array from which the stagger will emanate
                            }
                        })
                    })
                        .fromTo('.scaleText', { scale: 1, transform: 'translateY(0)' },
                            { scale: 12, transform: 'translateY(-150px)', ease: ExpoScaleEase.config(1, 12), duration: 8 }, ">2")
                        .fromTo('.scaleDown', { scale: 1 }, { scale: .2, ease: ExpoScaleEase.config(1, .2), duration: 8 }, "<")
                        .fromTo('.slide1SplitText', { scale: 1 }, { scale: .95, duration: 5, ease: Linear.easeOut }, "<")
                        .fromTo('.bgImage', { scale: 1 }, { scale: 1.05, duration: 5, ease: Linear.easeOut }, "<")
                        .fromTo('.bgImage', { opacity: 1 }, { opacity: 0, duration: 5 }, "<30%")
                        .fromTo('.slide2', { backgroundColor: 'none' }, { backgroundColor: '#A036F5', duration: 5 }, "<")
                        .fromTo('.painter2Slide', { visibility: 'hidden', xPercent: 100 },
                            { visibility: 'visible', xPercent: 0, ease: Linear.easeOut, duration: 8, }, ">2")
                        .fromTo('.painter2', { width: '35%' }, { width: '5px', duration: 8 }, "<")
                        .fromTo('.painter', { opacity: 1 }, { opacity: 0, duration: 1 }, "<")
                        .fromTo('.slide', { opacity: 1 }, { opacity: 0, duration: 1 }, "<")
                        .fromTo('.slide2', { width: '100%' }, { width: '0%', duration: 8 }, "<")
                        .fromTo('.slide3', { visibility: 'hidden' }, { visibility: 'visible', duration: 8 }, "<")
                        .add(() => {
                            const scrollDirection = tl.scrollTrigger ? tl.scrollTrigger.direction : 1;
                            if (scrollDirection === -1) {
                                gsap.to('.slide2SplitText .word', {
                                    opacity: 0,
                                    duration: .6,
                                    y: 75,
                                    ease: "back.out(0)",
                                    stagger: {
                                        amount: .5, //  the total amount of time (in seconds) that gets split up among all the staggers. 
                                        each: .4, //The amount of time (in seconds) between each sub-tween's start time.
                                        from: "start" // the position in the array from which the stagger will emanate
                                    }
                                })

                            } else {
                                gsap.to('.slide2SplitText .word', {
                                    opacity: 1,
                                    duration: .6,
                                    y: 0,
                                    ease: "back.out(0)",
                                    stagger: {
                                        amount: .5, //  the total amount of time (in seconds) that gets split up among all the staggers. 
                                        each: .4, //The amount of time (in seconds) between each sub-tween's start time.
                                        from: "start" // the position in the array from which the stagger will emanate
                                    }
                                })
                            }
                        }, "<90%")
                        .fromTo('.slide3scale', { scale: 1, transform: 'translateX(0)' },
                            {
                                scale: 12, transform: 'translateX(200px)',
                                ease: ExpoScaleEase.config(1, 12), duration: 8
                            }, ">5")
                        .fromTo('.slide3scaleOut', { scale: 1 }, { scale: .2, ease: ExpoScaleEase.config(1, .2), duration: 8 }, "<")
                        .fromTo('.slide2SplitText', { scale: 1 }, { scale: .95, duration: 5, ease: Linear.easeOut }, "<")
                        .fromTo('.bgImage3', { scale: 1 }, { scale: 1.05, duration: 5, ease: Linear.easeOut }, "<")
                        .fromTo('.bgImage3', { opacity: 1 }, { opacity: 0, duration: 5 }, "<30%")
                        .fromTo('.slide3', { backgroundColor: 'none' }, { backgroundColor: '#000000', duration: 5 }, "<")
                        .fromTo('.slide4', { visibility: 'hidden' }, { visibility: 'visible', duration: 1 }, ">")
                        .fromTo('.slide3SplitText .word', { opacity: 0, y: 75 }, { opacity: 1, y: 0, stagger: 0.5, ease: "back.out(0)", }, "<75%")
                        .fromTo('.painter3Slide', { visibility: 'hidden', xPercent: -39.5 }, { visibility: 'visible', xPercent: 99, ease: Linear.easeOut, duration: 8 }, "<100%")
                        .fromTo('.painter3', { width: '35%' }, { width: '5px', ease: Linear.easeOut, duration: 8 }, "<")
                        .fromTo('.painter2', { opacity: 1 }, { opacity: 0, duration: 1 }, "<")
                        .fromTo('.painter2Slide', { opacity: 1 }, { opacity: 0, duration: 1 }, "<")
                        .fromTo('.slide4', { xPercent: 0 }, { xPercent: 100, ease: Linear.easeOut, duration: 8 }, "<8%")
                        .fromTo('.slide3TextCont', { xPercent: 0 }, { xPercent: -55, duration: 8 }, "<")
                        .fromTo('.slide4TextCont', { xPercent: 60 }, { xPercent: 0, duration: 8 }, "<")
                        .fromTo('.slide5', { visibility: 'hidden', }, { visibility: 'visible', duration: 8 }, "<")
                        .add(() => {
                            const scrollDirection = tl.scrollTrigger ? tl.scrollTrigger.direction : 1;
                            if (scrollDirection === -1) {
                                gsap.to('.slide5SplitText .word', {
                                    opacity: 0,
                                    duration: .6,
                                    y: 75,
                                    ease: "back.out(0)",
                                    stagger: {
                                        amount: .5, //  the total amount of time (in seconds) that gets split up among all the staggers. 
                                        each: .4, //The amount of time (in seconds) between each sub-tween's start time.
                                        from: "start" // the position in the array from which the stagger will emanate
                                    }
                                })

                            } else {
                                gsap.to('.slide5SplitText .word', {
                                    opacity: 1,
                                    duration: .6,
                                    y: 0,
                                    ease: "back.out(0)",
                                    stagger: {
                                        amount: .5, //  the total amount of time (in seconds) that gets split up among all the staggers. 
                                        each: .4, //The amount of time (in seconds) between each sub-tween's start time.
                                        from: "start" // the position in the array from which the stagger will emanate
                                    }
                                })
                            }
                        }, "<95%")
                        .fromTo('.slide4ScaleText ', { scale: 1, transform: 'translateX(0)' }, { scale: 12, transform: 'translateX(200px)', ease: ExpoScaleEase.config(1, 12), duration: 10 }, ">5")
                        .fromTo('.slide4ScaleDown', { scale: 1 }, { scale: .2, ease: ExpoScaleEase.config(1, .2), duration: 10 }, "<")
                        .fromTo('.slide5SplitText', { scale: 1 }, { scale: .95, duration: 5, ease: Linear.easeOut }, "<")
                        .fromTo('.bgImage5', { scale: 1 }, { scale: 1.05, duration: 5, ease: Linear.easeOut }, "<")
                        .fromTo('.bgImage5', { opacity: 1 }, { opacity: 0, duration: 5 }, "<30%")
                        .fromTo('.slide5', { backgroundColor: 'none' }, { backgroundColor: '#ffffff', duration: 5 }, "<")
                        .fromTo('.painter4Slide', { visibility: 'hidden', xPercent: 100 }, { visibility: 'visible', xPercent: 0, ease: Linear.easeOut, duration: 8 }, ">")
                        .fromTo('.painter4', { width: '35%' }, { width: '5px', duration: 8 }, "<")
                        .fromTo('.painter3Slide', { opacity: 1 }, { opacity: 0, duration: 1 }, "<")
                        .fromTo('.slide5', { width: '100%' }, { width: '0%', duration: 8 }, "<")
                        .fromTo('.slide6', { visibility: 'hidden' }, { visibility: 'visible', duration: 8 }, "<")
                        .add(() => {
                            const scrollDirection = tl.scrollTrigger ? tl.scrollTrigger.direction : 1;
                            if (scrollDirection === -1) {
                                gsap.to('.slide6SplitText .word', {
                                    opacity: 0,
                                    duration: .6,
                                    y: 75,
                                    ease: "back.out(0)",
                                    stagger: {
                                        amount: .5, //  the total amount of time (in seconds) that gets split up among all the staggers. 
                                        each: .4, //The amount of time (in seconds) between each sub-tween's start time.
                                        from: "start" // the position in the array from which the stagger will emanate
                                    }
                                })
                            } else {
                                gsap.to('.slide6SplitText .word', {
                                    opacity: 1,
                                    duration: .6,
                                    y: 0,
                                    ease: "back.out(0)",
                                    stagger: {
                                        amount: .5, //  the total amount of time (in seconds) that gets split up among all the staggers. 
                                        each: .4, //The amount of time (in seconds) between each sub-tween's start time.
                                        from: "start" // the position in the array from which the stagger will emanate
                                    }
                                })
                            }
                        }, "<95%")
                        .add(() => {
                            const scrollDirection = tl.scrollTrigger ? tl.scrollTrigger.direction : 1;
                            if (scrollDirection === -1) {
                                gsap.to('.cta', {
                                    opacity: 0,
                                    duration: .6,
                                    y: 75,
                                    ease: "back.out(0)"
                                })
                            } else {
                                gsap.to('.cta', {
                                    opacity: 1,
                                    duration: .6,
                                    y: 0,
                                    ease: "back.out(0)"
                                })
                            }
                        }, "<")
                        .fromTo('.slide6', { zIndex: '-15' }, { zIndex: '99', duration: 0 }, ">2")
                        .fromTo('.pinkBorder', { visibility: 'hidden', display: 'none' }, { visibility: 'visible', display: 'inline-block', duration: 1 }, "<")
                        .fromTo('.slide6ScaleUp ', { scale: 1 }, { scale: 1, duration: 8 }, ">6")
                        .fromTo('.slide6ScaleDown', { scale: 1 }, { scale: 1, duration: 8 }, "<")
                })

                            
    
                window.addEventListener("scroll", scrollPostion)
    
                mm.add("(min-width: 826px)", () => {
    
                    const tl = gsap.timeline({
                        defaults: { ease: 'none' },
                        scrollTrigger: {
                            trigger: group1,
                            id: 'scrollTrig',
                            start: "top +=80px",
                            end: "+=700%",
                            scrub: 10,
                            pin: true,
                            snap: .275
    
                        }
                    })
    
                    tl.add(() => {
                        gsap.to('.slide1SplitText .word', {
                            opacity: 1,
                            delay: 0,
                            duration: .1,
                            y: 0,
                            ease: Linear.easeNone,
                            stagger: {
                                amount: .5, //  the total amount of time (in seconds) that gets split up among all the staggers. 
                                each: 2.4, //The amount of time (in seconds) between each sub-tween's start time.
                                from: "start" // the position in the array from which the stagger will emanate
                            }
                        })
                    })
                        .fromTo('.scaleText', { scale: 1, transform: 'translateY(0)' },
                            { scale: 12, transform: 'translateY(-250px)', ease: ExpoScaleEase.config(1, 12), duration: 8 }, ">2")
                        .fromTo('.scaleDown', { scale: 1 }, { scale: .2, ease: ExpoScaleEase.config(1, .2), duration: 8 }, "<")
                        .fromTo('.slide1SplitText', { scale: 1 }, { scale: .95, duration: 5, ease: Linear.easeOut }, "<")
                        .fromTo('.bgImage', { scale: 1 }, { scale: 1.05, duration: 5, ease: Linear.easeOut }, "<")
                        .fromTo('.bgImage', { opacity: 1 }, { opacity: 0, duration: 5 }, "<30%")
                        .fromTo('.slide2', { backgroundColor: 'none' }, { backgroundColor: '#A036F5', duration: 5 }, "<")
                        .fromTo('.painter2Slide', { visibility: 'hidden', xPercent: 100 },
                            { visibility: 'visible', xPercent: 0, ease: Linear.easeOut, duration: 8, }, ">2")
                        .fromTo('.painter2', { width: '40%' }, { width: '10px', duration: 8 }, "<")
                        .fromTo('.painter', { opacity: 1 }, { opacity: 0, duration: 1 }, "<")
                        .fromTo('.slide', { opacity: 1 }, { opacity: 0, duration: 1 }, "<")
                        .fromTo('.slide2', { width: '100%' }, { width: '0%', duration: 8 }, "<")
                        .fromTo('.slide3', { visibility: 'hidden' }, { visibility: 'visible', duration: 8 }, "<")
                        .add(() => {
                            if (tl.scrollTrigger.direction === -1) {
                                gsap.to('.slide2SplitText .word', {
                                    opacity: 0,
                                    duration: .6,
                                    y: 75,
                                    ease: "back.out(0)",
                                    stagger: {
                                        amount: .5, //  the total amount of time (in seconds) that gets split up among all the staggers. 
                                        each: .4, //The amount of time (in seconds) between each sub-tween's start time.
                                        from: "start" // the position in the array from which the stagger will emanate
                                    }
                                })
    
                            } else {
                                gsap.to('.slide2SplitText .word', {
                                    opacity: 1,
                                    duration: .6,
                                    y: 0,
                                    ease: "back.out(0)",
                                    stagger: {
                                        amount: .5, //  the total amount of time (in seconds) that gets split up among all the staggers. 
                                        each: .4, //The amount of time (in seconds) between each sub-tween's start time.
                                        from: "start" // the position in the array from which the stagger will emanate
                                    }
                                })
                            }
                        }, "<90%")
                        .fromTo('.slide3scale', { scale: 1, transform: 'translateX(0)' },
                            { scale: 12, transform: 'translateX(800px)', ease: ExpoScaleEase.config(1, 12), duration: 8 }, ">4")
                        .fromTo('.slide3scaleOut', { scale: 1 }, { scale: .2, ease: ExpoScaleEase.config(1, .2), duration: 8 }, "<")
                        .fromTo('.slide2SplitText', { scale: 1 }, { scale: .95, duration: 5, ease: Linear.easeOut }, "<")
                        .fromTo('.bgImage3', { scale: 1 }, { scale: 1.05, duration: 5, ease: Linear.easeOut }, "<")
                        .fromTo('.bgImage3', { opacity: 1 }, { opacity: 0, duration: 5 }, "<30%")
                        .fromTo('.slide3', { backgroundColor: 'none' }, { backgroundColor: '#000000', duration: 5 }, "<")
                        .fromTo('.slide4', { visibility: 'hidden' }, { visibility: 'visible', duration: 1 }, ">")
                        .from('.slide3SplitText .word', { opacity: 0, y: 75, stagger: 0.4, ease: "back.out(0)", }, "<75%")
                        .fromTo('.painter3Slide', { visibility: 'hidden', xPercent: -39.5 },
                            { visibility: 'visible', xPercent: 99.5, ease: Linear.easeOut, duration: 8 }, "<95%")
                        .fromTo('.painter3', { width: '40%' }, { width: '15px', duration: 8 }, "<")
                        .fromTo('.painter2', { opacity: 1 }, { opacity: 0, duration: 1 }, "<")
                        .fromTo('.painter2Slide', { opacity: 1 }, { opacity: 0, duration: 1 }, "<")
                        .fromTo('.slide4', { xPercent: 0 }, { xPercent: 100, ease: Linear.easeOut, duration: 8 }, "<6%")
                        .fromTo('.slide3TextCont', { xPercent: 0 }, { xPercent: -55, duration: 8 }, "<")
                        .fromTo('.slide4TextCont', { xPercent: 60 }, { xPercent: 0, duration: 8 }, "<")
                        .fromTo('.slide5', { visibility: 'hidden', }, { visibility: 'visible', duration: 8 }, "<")
                        .add(() => {
                            if (tl.scrollTrigger.direction === -1) {
                                gsap.to('.slide5SplitText .word', {
                                    opacity: 0,
                                    duration: .6,
                                    y: 75,
                                    ease: "back.out(0)",
                                    stagger: {
                                        amount: .5, //  the total amount of time (in seconds) that gets split up among all the staggers. 
                                        each: .4, //The amount of time (in seconds) between each sub-tween's start time.
                                        from: "start" // the position in the array from which the stagger will emanate
                                    }
                                })
    
                            } else {
                                gsap.to('.slide5SplitText .word', {
                                    opacity: 1,
                                    duration: .6,
                                    y: 0,
                                    ease: "back.out(0)",
                                    stagger: {
                                        amount: .5, //  the total amount of time (in seconds) that gets split up among all the staggers. 
                                        each: .4, //The amount of time (in seconds) between each sub-tween's start time.
                                        from: "start" // the position in the array from which the stagger will emanate
                                    }
                                })
                            }
                        }, "<95%")
                        .fromTo('.slide4ScaleText ', { scale: 1, transform: 'translateX(0)' }, { scale: 12, transform: 'translateX(500px)', ease: ExpoScaleEase.config(1, 12), duration: 8 }, ">4")
                        .fromTo('.slide4ScaleDown', { scale: 1 }, { scale: .2, ease: ExpoScaleEase.config(1, .2), duration: 8 }, "<")
                        .fromTo('.slide5SplitText', { scale: 1 }, { scale: .95, duration: 5, ease: Linear.easeOut }, "<")
                        .fromTo('.bgImage5', { scale: 1 }, { scale: 1.05, duration: 5, ease: Linear.easeOut }, "<")
                        .fromTo('.bgImage5', { opacity: 1 }, { opacity: 0, duration: 5 }, "<30%")
                        .fromTo('.slide5', { backgroundColor: 'none' }, { backgroundColor: '#ffffff', duration: 5 }, "<")
                        .fromTo('.painter4Slide', { visibility: 'hidden', xPercent: 100 }, { visibility: 'visible', xPercent: 0, ease: Linear.easeOut, duration: 8 }, ">")
                        .fromTo('.painter4', { width: '40%' }, { width: '10px', duration: 8 }, "<")
                        .fromTo('.painter3Slide', { opacity: 1 }, { opacity: 0, duration: 1 }, "<")
                        .fromTo('.slide5', { width: '100%' }, { width: '0%', duration: 8 }, "<")
                        .fromTo('.slide6', { visibility: 'hidden' }, { visibility: 'visible', duration: 8 }, "<")
                        .add(() => {
                            if (tl.scrollTrigger.direction === -1) {
                                gsap.to('.slide6SplitText .word', {
                                    opacity: 0,
                                    duration: .6,
                                    y: 75,
                                    ease: "back.out(0)",
                                    stagger: {
                                        amount: .5, //  the total amount of time (in seconds) that gets split up among all the staggers. 
                                        each: .4, //The amount of time (in seconds) between each sub-tween's start time.
                                        from: "start" // the position in the array from which the stagger will emanate
                                    }
                                })
                            } else {
                                gsap.to('.slide6SplitText .word', {
                                    opacity: 1,
                                    duration: .6,
                                    y: 0,
                                    ease: "back.out(0)",
                                    stagger: {
                                        amount: .5, //  the total amount of time (in seconds) that gets split up among all the staggers. 
                                        each: .4, //The amount of time (in seconds) between each sub-tween's start time.
                                        from: "start" // the position in the array from which the stagger will emanate
                                    }
                                })
                            }
                        }, "<95%")
                        .add(() => {
                            if (tl.scrollTrigger.direction === -1) {
                                gsap.to('.cta', {
                                    opacity: 0,
                                    duration: .6,
                                    y: 75,
                                    ease: "back.out(0)"
                                })
                            } else {
                                gsap.to('.cta', {
                                    opacity: 1,
                                    duration: .6,
                                    y: 0,
                                    ease: "back.out(0)"
                                })
                            }
                        }, "<")
                        .fromTo('.slide6', { zIndex: '-15' }, { zIndex: '99', duration: 0 }, ">2")
                        .fromTo('.pinkBorder', { visibility: 'hidden', display: 'none' }, { visibility: 'visible', display: 'inline-block', duration: 1 }, "<")
                        .fromTo('.slide6ScaleUp ', { scale: 1 }, { scale: 1, duration: 8 }, ">6")
                        .fromTo('.slide6ScaleDown', { scale: 1 }, { scale: 1, duration: 8 }, "<")
    
    
                })
    
            }, container)

        
            return () => {
                ctx.revert();
                mm.revert();
                window.removeEventListener('scroll', scrollPostion);
                ScrollTrigger.getAll().forEach((instance) => {
                    instance.kill();
                 });

                gsap.killTweensOf(window);
            }
  
    }, { scope: container }); 


    return (
        <HomeAnimationContainer ref={container} id={"group1"}>
            <PainterOneContainer className={"slide painter1Slide section"}>
                <Painter className='painter'>
                </Painter>
            </PainterOneContainer>
            <SlideOneContainer className={"slide2 section mblSlide"} >
                <InnerContainer1 className={"slide-wrapper"} id={"innerCont1"}>
                    <BgImageOne className="bgImage" tilte="">
                        <ContentContainer1>
                            <TitleXlContainer1 className={"titleXlCont1"}>
                                <TitleXl fontWeight="900">
                                    <span className="scaleDown healthcare" style={{ display: 'inline-block' }}>Healthcare,</span><br /> <span className="scaleDown weHave" style={{ display: 'inline-block' }}> we have </span><br /> <span className="scaleDown" style={{ display: 'inline-block' }}>a</span> <span className='magenta scaleText' style={{ display: 'inline-block' }}>problem</span>.
                                </TitleXl>
                            </TitleXlContainer1>

                            <BodySmContainer1 >
                                <BodySm className="slide1SplitText">
                                    <span>Modern Medicine is advancing at ludicrous speeds.<br className='displayNone' /> Yet the world still sees healthcare as difficult and dated.</span>
                                </BodySm>
                            </BodySmContainer1>

                        </ContentContainer1>
                    </BgImageOne>
                </InnerContainer1>
            </SlideOneContainer>
            <PainterTwoContainer className={"painter2Slide section"}>
                <Painter2 className='painter2'>
                </Painter2>
            </PainterTwoContainer>
            <SlideTwoContainer className={"slide3 section mblSlide"} >
                <InnerContainer2 className={"slide-wrapper"} id={"slide-wrapper-2"}>
                    <BgImageTwo className='bgImage3' title="">
                        <ContentContainer2>
                            <TitleXlContainer2>
                                <TitleXl fontWeight="900">
                                    <span className='slide3scaleOut' style={{ display: 'inline-block' }}>How do<br /> you get</span> <br /> <span className='slide3scale black' style={{ display: 'inline-block' }}>noticed</span> <br /> <span className='slide3scaleOut' style={{ display: 'inline-block' }}>in a world <br /> so complex</span><span className='slide3scale black' style={{ display: 'inline-block' }}>?</span>
                                </TitleXl>
                                <BodySmContainer2>
                                    <BodySm className="slide2SplitText">
                                        The health <br className='displayNone' /> system is <br className='displayNone' /> anything <br className='displayNone' /> but simple. <br /> And it stops <br className='displayNone' /> health brands <br className='displayNone' /> from reaching <br className='displayNone' /> potential.
                                    </BodySm>
                                </BodySmContainer2>
                            </TitleXlContainer2>
                        </ContentContainer2>
                    </BgImageTwo>
                </InnerContainer2>
            </SlideTwoContainer>
            <SlideThreeContainer className={"slide4 section mblSlide"}>
                <InnerContainer3 className={"slide-wrapper"} >
                    <BgImageThree className='bgImage4' title="">
                        <ContentContainer3>
                            <TitleXlContainer3 className='slide3TextCont'>
                                <TitleXl fontWeight="900" className={'slide3SplitText'}>
                                    Modern medicine <br /> <span className='needsA'>needs a</span> <br /> modern partner.
                                </TitleXl>
                            </TitleXlContainer3>
                        </ContentContainer3>
                    </BgImageThree>
                </InnerContainer3>
            </SlideThreeContainer>
            <PainterThreeContainer className={"painter3Slide section"}>
                <Painter3 className='painter3'>
                </Painter3>
            </PainterThreeContainer>
            <SlideFourContainer className={"slide5 section mblSlide"}>
                <InnerContainer4 className={"slide-wrapper"} >
                    <BgImageFour className='bgImage5' title="">
                        <ContentContainer4>
                            <TitleXlContainer4 className='slide4TextCont'>
                                <TitleXl fontWeight="900">
                                    <span className='slide4ScaleDown' style={{ display: 'inline-block' }}>Modern</span> <span className='magenta slide4ScaleText' style={{ display: 'inline-block' }}>medicine</span> <br /> <span className='slide4ScaleDown needsA' style={{ display: 'inline-block' }}>needs a </span> <br /> <span className='slide4ScaleDown' style={{ display: 'inline-block' }}> modern</span> <span className='magenta slide4ScaleText' style={{ display: 'inline-block' }}>partner</span>.
                                </TitleXl>
                                <BodySmContainer4>
                                    <BodySm className="slide5SplitText">
                                        Experts in all aspects of health who <br /> can give brands a healthier future.
                                    </BodySm>
                                </BodySmContainer4>
                            </TitleXlContainer4>

                        </ContentContainer4>
                    </BgImageFour>
                </InnerContainer4>
            </SlideFourContainer>
            <PainterFourContainer className={"painter4Slide section"}>
                <Painter4 className='painter4'>
                </Painter4>
            </PainterFourContainer>
            <SlideFiveContainer className={"slide6 section mblSlide"}>
                <InnerContainer5 className={"slide-wrapper"} >
                    <PinkBorder className="pinkBorder" />
                    <BgImageFive className='bgImage6' title="">
                        <ContentContainer5>
                            <TitleXlContainer5>
                                <TitleXl fontWeight="900">
                                    <span className='slide6ScaleDown purple' style={{ display: 'inline-block' }}>Modern <br /> medicine <br /> needs</span><br /><span style={{ display: 'inline-block' }} className="slide6ScaleUp">A</span><span style={{ display: 'inline-block' }} className="slide6ScaleDown purple">rtificial</span> <br /><span style={{ display: 'inline-block' }} className="slide6ScaleUp">I</span><span style={{ display: 'inline-block' }} className='slide6ScaleDown purple'>ntelligence</span><br /> <span style={{ display: 'inline-block' }} className='slide6ScaleDown purple'>with a <br /> human <br /> touch<span style={{ color: 'black' }}>.</span></span>
                                </TitleXl>
                            </TitleXlContainer5>
                            <BodySmContainer5>
                                <BodySm className="slide6SplitText" >
                                    Insights with unprecedented relevance.<br /> <span className='purple'>+</span> <br /> Thinking that connects on a deeper level. <br /> <span className='purple'>=</span> <br /> Health brands that feel more relatable.
                                </BodySm>
                                <ViewAllContainer className="cta">
                                    <ViewAll link={'/about'} title={"ABOUT US"} borderColor="#000" titleColor="#000" hoverBackground="#000" svgColor="#000" width="300px" height="44px" />
                                </ViewAllContainer>
                            </BodySmContainer5>
                       </ContentContainer5>
                    </BgImageFive>
                </InnerContainer5>
            </SlideFiveContainer>
        </HomeAnimationContainer>
    )
}

export default withRouter(HomeAnimationSection);