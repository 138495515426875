import {  sizeDesktopMd, sizeMobileLg, sizeTablet, sizeWideScreenMax } from 'components/devices';
import { BodySm, TitleMd, TitleSm, TitleXl, Row , Col8, Col4} from 'components/elements';
import styled from 'styled-components';



export const Container = styled.div`
 position: relative;
 width: 100%;
 height: 100%;
`

export const HeroContainer = styled.div`
position: relative;
width: 100%;
height: 100%;
padding: 6% 4%;
${sizeTablet(`
    padding: 6% 3%;
`)} 
.cta{
    left: 4%;
    ${sizeMobileLg(`
        left: 2.5%;
    `)}  
}
 ${TitleMd}{
    font-size: 16px;
    padding-left: .5em;
    ${sizeMobileLg(`
        font-size: 14px;
        padding-left: 3px;
    `)}  
 }

 ${TitleXl}{
    padding: 6% 0;
    text-align: left;
    font-size: 4vw;
    line-height: 1.1;
    .magenta {
     color: #D12FFF;
    }
    ${sizeTablet(`
        font-size: 8vw;
        padding: 10% 0;
    `)}  
    ${sizeMobileLg(`
        padding: 30% 0 10%;
    `)}
 }

`

export const MediaContainer = styled.div`
 display: flex;
 justify-content: center;
.hero {
    width: 100%;
    height: auto;
    object-fit: cover;
    max-height: 680px;
    object-position: 50% 40%;
    ${sizeWideScreenMax(`
        max-height: 930px;
    `)}
    ${sizeDesktopMd(`
        max-height: 630px;
    `)}
`

export const SectionsContainer = styled.div`
    width: 100%
    height: auto;
    padding: 4% 0% 0;
    text-align: center;
    ${sizeTablet(`
        padding: 6% 0%;
    `)} 
    ${Row}{
        padding: 0 10%;
        ${sizeMobileLg(`
            flex-direction: column;
            padding: 0 2.5%;
            ${Col8}{
                flex: 0 0 100%;
                max-width: 100%;
                width: 100%;
                padding: 10% 0;
            }
            ${Col4}{
                flex: 0 0 100%;
                max-width: 100%;
                width: 100%
            }
        `)}
    }
`

export const ContentContainer = styled.div`
 display: flex;
 flex-direction: column;
 align-items: flex-start;
 justify-content: flex-start;
 text-align: left;
 max-width: 65%;
 height: auto;
 &.bottom {
    align-items: flex-start;
    margin: 0 auto;
    max-width: 60%;

    ${sizeTablet(`
        max-width: 75%;
    `)}
    ${sizeMobileLg(`
        max-width: 100%;
    `)}
    ${TitleSm}{
        align-self: flex-start;
    }
 }
 ${sizeTablet(`
    max-width: 85%;
 `)}
${sizeMobileLg(`
    max-width: 100%;
`)} 
${TitleSm}{
    font-size: 24px;
    line-height: 1.1;
    padding: 0 0 15px;
 }
 ${BodySm}{
    font-size: 20px;
 }
 a {
    color: #D12FFF;
    text-decoration: underline;
 }
 p{
    padding: 10px 0;
    font-size: 20px;
    &:first-of-type{
        padding: 0 0;
    }
    ${sizeMobileLg(`
        font-size: 16px;
    `)} 
 }
 ul {
    padding: 15px 0 10px;
    margin: 10px 0 0 30px;
    &:first-of-type {
        padding: 0 0 10px;
    }
    
 }

 ul li{
    margin: 0 0;
 }

img{
    padding: 15px 0;
}

video {
    padding: 15px 0;
}
`

export const GreyContainer = styled.div`
    background-color: #F0F0F0;
    margin: 5% 0 0;
    padding: 8% 0;
    ${sizeMobileLg(`
        padding: 15% 0;
        margin: 15% 0 0;

    `)}

    ${Row}{
        &:last-of-type {
            margin: 5% 0 0;
            ${sizeMobileLg(`
                margin: 15% 0 0;
        `)}
        }
    }


`