import React from 'react';
import {
    TitleLg,
    BodyRg,
    TitleSm,
    Bold,
    BodySm,
    SectionTitle
} from "components/elements"

import {
    GlobalDpaPageWrap,
    GlobalDpaContainer,
    OrderedList,
    OrderedListItem,
    RomanList
} from "./elements";

import { Helmet } from "react-helmet";


function GlobalDpaPage() {

    // let date = new Date();

    // let todayDate = date.toLocaleString('default', { month: 'long' }) + " " + date.getDate() + ", " + date.getFullYear();
    return (
        <GlobalDpaPageWrap className="pageWrapper">

            <Helmet>
                <title>{"Global Client DPA | Real Chemistry"}</title>
                <link rel="canonical" href={window.location.pathname} />
                <meta name="description" content={"Global Client DPA"} />
                <meta name="og:description" content={"Global Client DPA"} />
                <meta name="og:title" content={"Global Client DPA | Real Chemistry"} />
                <meta name="og:url" content={window.location.protocol + "//" + window.location.host + window.location.pathname} />
                <meta name="og:type" content="website" />
                <meta name="og:site_name" content="Real Chemistry" />

            </Helmet>
            <GlobalDpaContainer>
                <SectionTitle textAlign="center">
                    <BodySm textTransform="uppercase" textAlign="center">LAST UPDATED: JANUARY 3, 2022</BodySm>
                    <TitleLg textAlign="center">Global Client DPA</TitleLg>
                </SectionTitle>

                <TitleSm>DATA PROCESSING AGREEMENT (THE, “DPA”)</TitleSm>

                <BodyRg>
                    In connection with a master agreement for the provision of certain services (the “<Bold>Main Agreement</Bold>”) by and between the applicable client (“<Bold>Client</Bold>”) and The WeissComm Group Ltd., d/b/a Real Chemistry (“<Bold>Agency</Bold>”), Agency will provide to Client certain services (collectively, the “<Bold>Services</Bold>”). This DPA is effective as of the effective date of the Main Agreement, and amends and is made part of the applicable Main Agreement.
                </BodyRg>

                <BodyRg>
                    The purpose of this DPA is to ensure that the parties Process all Personal Data in a manner that complies with the parties’ respective duties under this DPA and applicable Data Privacy Laws.
                </BodyRg>

                <BodyRg>
                    Each party agrees to appoint a single point of contact (“<Bold>Representative</Bold>”) as soon as this DPA has been entered into and provide the other party with the contact details of its Representative as soon as he/she has been appointed. The parties shall procure that the Representatives work together to reach an agreement with regard to any issues arising from the data sharing described in this DPA and to actively monitor the effectiveness of the data sharing.
                </BodyRg>

                <BodyRg>
                    <Bold>1. Definitions and interpretation </Bold>
                </BodyRg>

                <BodyRg>
                    Capitalized terms used in this DPA shall have the following meanings:
                </BodyRg>
                <BodyRg>
                    <Bold>“Adequate Country”</Bold> means a country or territory that is recognized under European Union Data Privacy Laws as providing adequate protection for Personal Data.
                </BodyRg>
                <BodyRg>
                    <Bold>“Affiliate”</Bold> means, with respect to a party, any corporate entity that, directly or indirectly, Controls, is Controlled by, or is under Common Control with such party (but only for so long as such Control exists).
                </BodyRg>
                <BodyRg>
                    <Bold>“Client Group”</Bold> means Client and any of its Affiliates.
                </BodyRg>
                <BodyRg>
                    <Bold>“EU Controller-to-Processor Clauses”</Bold> means the Standard Contractual Clauses between controllers and processors for Data Transfers, as approved by the European Commission Implementing Decision (EU) 2021/914 of 4 June 2021, as may be amended or replaced from time to time by a competent authority under the relevant Data Protection Laws.
                </BodyRg>
                <BodyRg>
                    <Bold>“Data Controller”</Bold> means the natural or legal person, public authority, agency or any other body that alone or jointly with others determines the purposes and means of the Processing of Personal Data.
                </BodyRg>
                <BodyRg>
                    <Bold>“Data Processor”</Bold> means a natural or legal person, public authority, agency or any other body that Processes Personal Data on behalf of a Data Controller.
                </BodyRg>
                <BodyRg>
                    <Bold>“Data Privacy Laws”</Bold> shall mean all laws, rules, regulations, and orders of any jurisdiction or subdivision thereof relating to the privacy, security, confidentiality and/or integrity of Personal Data that are applicable to Services under the Main Agreement.
                </BodyRg>
                <BodyRg>
                    <Bold>“Data Subject”</Bold> means any individual about whom Personal Data may be Processed under this DPA.
                </BodyRg>
                <BodyRg>
                    <Bold>“Data Subject Request”</Bold> means any communication from a Data Subject or authorized agent regarding the exercise of Data Subject rights pursuant to Data Privacy Laws.
                </BodyRg>
                <BodyRg>
                    <Bold>"Personal Data"</Bold> means that information, whether alone or in combination with other information that directly or indirectly identifies a Data Subject or household, or any data defined as Personal Data under Data Privacy Laws, which is Processed by Agency on behalf of Client as part of this DPA and the under the Main Agreement.
                </BodyRg>
                <BodyRg>
                    <Bold>“Process”, “Processed”, “Processes”</Bold> or <Bold>“Processing”</Bold> means any operation or set of operations which is performed upon Personal Data, whether or not by automated means, including but not limited to the collection, recording, organization, structuring, storage, adaptation or alteration, retrieval, consultation, use, access, disclosure, copying, transfer, dissemination or otherwise making available, deletion, alignment or combination, restriction, anonymization, disposal, erasure, destruction, or other use of Personal Data.
                </BodyRg>
                <BodyRg>
                    <Bold>“Protected Health Information”</Bold> or <Bold>(“PHI”)</Bold> means any individually identifiable health information created, collected, or transmitted by a Covered Entity or Business Associate of a Covered Entity, as defined by the Health Insurance Portability and Accountability Act (“<Bold>HIPAA</Bold>”)
                </BodyRg>
                <BodyRg>
                    <Bold>“Security Breach”</Bold> means any actual and confirmed breach of (i) security leading to the accidental or unlawful destruction, loss, alteration, unauthorized disclosure of, or access to, Personal Data transmitted, stored or otherwise Processed in connection with this Agreement by Agency or any of Agency’s Sub-processors, or any other identified or unidentified third party, or (ii) this DPA or any data protection, confidentiality or security provisions of the Main Agreement.
                </BodyRg>
                <BodyRg>
                    <Bold>“Standard Contractual Clauses”</Bold> means (i) the EU Controller-to-Processor Clauses, or (ii) the UK Controller-to-Processor Clauses, as may be amended or replaced from time to time by a competent authority under the relevant Data Protection Laws.
                </BodyRg>
                <BodyRg>
                    <Bold>“Sub-processor”</Bold> means a third-party engaged by Agency to Process Personal Data on behalf of Client, in accordance with Client instructions.
                </BodyRg>
                <BodyRg>
                    <Bold>"Supervisory Authority"</Bold> means any competent regulatory authority responsible for monitoring the application of the Data Privacy Laws, including but not limited to the UK Information Commissioner's Office.
                </BodyRg>
                <BodyRg>
                    <Bold>“UK Controller-to-Processor Clauses”</Bold> means the standard contractual clauses (processors) set out in Decision 2010/87/EC as amended or replaced from time to time, pursuant to Article 46 of the UK GDPR.
                </BodyRg>
                <BodyRg>
                    1.1 An entity <Bold>“Controls”</Bold> another entity if it: (a) holds a majority of the voting rights in it; (b) is a member or shareholder of it and has the right to remove a majority of its board of directors or equivalent managing body; (c) is a member or shareholder of it and controls alone or pursuant to an agreement with other shareholders or members, a majority of the voting rights in it; or (d) has the right to exercise a dominant influence over it pursuant to its constitutional documents or pursuant to a contract; and two entities are treated as being in “Common Control” if either controls the other (directly or indirectly) or both are controlled (directly or indirectly) by the same entity.
                </BodyRg>
                <BodyRg>
                    <Bold>
                        2. Status of the parties
                    </Bold>
                </BodyRg>
                <BodyRg>
                    2.1 In respect of the parties' rights and obligations regarding Personal Data, the parties hereby acknowledge and agree that Client and Client Group are the Data Controllers and that Agency is the Data Processor, and accordingly the parties agree that they shall Process all Personal Data in accordance with their obligations pursuant to this DPA and applicable Data Privacy Laws.
                </BodyRg>
                <BodyRg>
                    2.2 Except with respect to Agency’s obligations as a Data Processor under this DPA, as between the parties, Client shall have the sole responsibility for the accuracy, quality and legality of Personal Data and the means by which Client acquired Personal Data, and for obtaining all appropriate consents and releases as may be required as may be required by applicable Data Privacy Laws. Client shall prepay and/or reimburse Agency for its reasonable costs and expenses in providing assistance to Client in fulfilling Client’s obligations under this DPA and/or Data Privacy Laws, including Agency’s employees’ time or other costs, as such costs are outlined in a separately negotiated and executed statement of work between the parties.
                </BodyRg>
                <BodyRg>
                    <Bold>
                        3. Agency obligations
                    </Bold>
                </BodyRg>
                <BodyRg>
                    3.1 With respect to all Personal Data, Agency shall:
                </BodyRg>
                <OrderedList>
                    <OrderedListItem>
                        <BodyRg>
                            only Process Personal Data in order to provide the Services, and shall act in accordance with this DPA, Data Privacy Laws, and Client’s lawful written instructions. Agency is prohibited from retaining, using or disclosing Clients Personal Data for any purpose other than providing the Services to Client, unless required to do so by applicable Data Privacy Laws, in which case the Agency shall inform Client of such legal requirement before processing, unless such disclosure is prohibited by law;
                        </BodyRg>
                    </OrderedListItem>
                    <OrderedListItem>
                        <BodyRg>
                            in cases where pseudonymous, tokenized or encrypted data is used, make no attempt to re-identify the data or link data with other data sources, pseudonymous profiles, or identifiable persons in any way;
                        </BodyRg>
                    </OrderedListItem>
                    <OrderedListItem>
                        <BodyRg>
                            as soon as reasonably practicable upon becoming aware, inform Client if, in Agency’s opinion, any instructions provided by Client violates Data Privacy Laws;
                        </BodyRg>
                    </OrderedListItem>
                    <OrderedListItem>
                        <BodyRg>
                            implement appropriate technical and organizational measures to ensure a level of security appropriate to the risks that are presented by the Processing of Personal Data, in particular protection against accidental or unlawful destruction, loss, alteration, unauthorized disclosure of, or access to Personal Data;
                        </BodyRg>
                    </OrderedListItem>
                    <OrderedListItem>
                        <BodyRg>
                            take reasonable steps to ensure that only authorized personnel have access to Personal Data and that any persons whom it authorizes to have access to the Personal Data are subject to these same obligations along with any confidentiality obligation set forth in the Main Agreement;
                        </BodyRg>
                    </OrderedListItem>
                    <OrderedListItem>
                        <BodyRg>
                            as soon as reasonably practicable upon becoming aware, and in any event within two working days notify Client of a Security Breach;
                        </BodyRg>
                    </OrderedListItem>
                    <OrderedListItem>
                        <BodyRg>
                            promptly provide Client with reasonable cooperation and assistance in respect of a Security Breach and information in Agency’s possession concerning such Security Breach insofar as it affects Client or member of a Client Group;
                        </BodyRg>
                    </OrderedListItem>
                    <OrderedListItem>
                        <BodyRg>
                            not make any public announcement about a Security Breach (a “<Bold>Breach Notice</Bold>”) without the prior written consent of Client, unless required by Data Privacy Laws;
                        </BodyRg>
                    </OrderedListItem>
                    <OrderedListItem>
                        <BodyRg>
                            promptly (and in any event within two working days of receipt) notify Client if it receives a Data Subject Request. Agency shall not respond to a Data Subject Request without Client’s prior written consent except to confirm that such request relates to Client, to which Client hereby agrees.  To the extent that Client does not have the ability to address a Data Subject Request, upon Client’s written request, Agency shall provide reasonable assistance to the extent Agency is able to consistent with Data Privacy Laws to Client to facilitate Client responding to a Data Subject Request within the deadlines set out under Data Privacy Laws;
                        </BodyRg>
                    </OrderedListItem>
                    <OrderedListItem>
                        <BodyRg>
                            other than to the extent required to comply with Data Privacy Laws, as soon as reasonably practicable following termination or expiry of the Main Agreement or completion of the Services, Agency will delete all Personal Data (including copies thereof) Processed pursuant to this DPA;
                        </BodyRg>
                    </OrderedListItem>
                    <OrderedListItem>
                        <BodyRg>
                            provide to Client such reasonable assistance as Client reasonably requests in writing in relation to Client’s and/or any Client Group(s)' obligations under Data Privacy Laws, in  each  case  solely  in  relation  to Processing of Personal Data by the Agency (as Data Processor) on behalf of Client (as Data Controller) and taking into account the nature of the Processing and information available to Agency with respect to:
                        </BodyRg>
                    </OrderedListItem>
                    <RomanList>
                        <OrderedListItem>
                            <BodyRg>
                                data protection impact assessments (as such term is defined in Data Privacy Laws);
                            </BodyRg>
                        </OrderedListItem>
                        <OrderedListItem>
                            <BodyRg>
                                notifications to the Supervisory Authority under Data Privacy Laws and/or communications to data subjects by Client in response to any Security Breach; and
                            </BodyRg>
                        </OrderedListItem>
                        <OrderedListItem>
                            <BodyRg>
                                Client’s compliance with their respective obligations under Data Privacy Laws with respect to the security of Processing.
                            </BodyRg>
                        </OrderedListItem>
                    </RomanList>
                </OrderedList>

                <BodyRg>
                    <Bold>
                        4. Specification of the Personal Data and Processing Activities
                    </Bold>
                </BodyRg>
                <BodyRg>
                    4.1 The subject-matter, nature, purpose and duration of the Processing are defined in Data Processing Annex 1.
                </BodyRg>
                <BodyRg>
                    4.2 The parties agree that no Protected Health Information (“<Bold>PHI</Bold>”) as defined by HIPAA will be Processed as part of this DPA. Should the Services provided to Client by Agency under the Main Agreement contemplate the Processing of PHI, the parties agree to negotiate a separate agreement in good faith.
                </BodyRg>
                <BodyRg>
                    <Bold>
                        5. United Kingdom (“UK”) and European Economic Area (“EEA”) data transfers
                    </Bold>
                </BodyRg>
                <BodyRg>
                    5.1 To the extent any Processing of Personal Data requires the transfer of Personal Data of Data Subjects who are in the EEA or UK to outside the EEA or UK except if in an Adequate Country, the parties agree that the applicable Standard Contractual Clauses approved by the EU or UK authorities under Data Privacy Laws will apply in respect of that Processing, and are incorporated into this DPA by reference.
                </BodyRg>
                <RomanList>
                    <OrderedListItem>
                        <BodyRg>
                            Where the transfer concerns the Personal Data of Data Subjects who are in the EEA, Client hereby enters into Module 2 of the EU Controller-to-Processor Clauses (as Controller) with the Agency (as Processor). Module 2 of the EU Controller-to-Processor Clauses is incorporated into this DPA by reference and takes effect from the commencement of an applicable data transfer. The Parties make the following selections for the purposes of Module 2:
                        </BodyRg>
                        <OrderedList>
                            <OrderedListItem>
                                <BodyRg>
                                    Clause 7 –Docking clause shall not apply;
                                </BodyRg>
                            </OrderedListItem>
                            <OrderedListItem>
                                <BodyRg>
                                    Clause 9 –Use of subprocessors: “Option 2” shall apply, Client agrees to the list of subprocessors located at www.realchemistry.com/service-providers,and the time period shall be a minimum of 30 days;
                                </BodyRg>
                            </OrderedListItem>
                            <OrderedListItem>
                                <BodyRg>
                                    Clause 11(a) –Redress the optional language shall not apply;
                                </BodyRg>
                            </OrderedListItem>
                            <OrderedListItem>
                                <BodyRg>
                                    Clause 13(a) –Supervision
                                </BodyRg>
                            </OrderedListItem>
                            <RomanList>
                                <OrderedListItem>
                                    <BodyRg>
                                        Where Client is established in an EU Member State, the following shall apply: “The supervisory authority with responsibility for ensuring compliance by the data exporter with Regulation (EU) 2016/679 as regards the data transfer, as indicated in Data Processing Data Processing Annex 1, shall act as competent supervisory authority. OR
                                    </BodyRg>
                                </OrderedListItem>
                                <OrderedListItem>
                                    <BodyRg>
                                        Where Client is not established in an EU Member State, but falls within the territorial scope of application of the GDPR in accordance with Article 3(2) and has appointed a representative pursuant to Article 27(1) of the GDPR the following shall apply: “The supervisory authority of the Member State in which the representative within the meaning of Article 27(1) of Regulation (EU) 2016/679 is established, shall act as competent supervisory authority.”;
                                    </BodyRg>
                                </OrderedListItem>
                            </RomanList>
                            <OrderedListItem>
                                <BodyRg>
                                    Governing law Option 1 shall apply and the “Member State” shall be as indicated in Data Processing Annex 1;
                                </BodyRg>
                            </OrderedListItem>
                            <OrderedListItem>
                                <BodyRg>
                                    Clause 18 –Choice of forum and jurisdiction the Member State shall be as indicated in Data Processing Annex 1.
                                </BodyRg>
                            </OrderedListItem>
                            <OrderedListItem>
                                <BodyRg>
                                    Annex 1 –shall be deemed pre-populated with the relevant sections of Data Processing Data Processing Annex 1 to this DPA;
                                </BodyRg>
                            </OrderedListItem>
                            <OrderedListItem>
                                <BodyRg>
                                    Annex 2 –shall be deemed pre-populated with the relevant sections of Data Processing Annex 2 to this DPA; and
                                </BodyRg>
                            </OrderedListItem>
                            <OrderedListItem>
                                <BodyRg>
                                    Annex 3 –shall be deemed pre-populated with the relevant sections of Data Processing Annex 3 to this DPA.
                                </BodyRg>
                            </OrderedListItem>
                        </OrderedList>
                    </OrderedListItem>
                    <OrderedListItem>
                        <BodyRg>
                            Where the transfer concerns the Personal Data of Data Subjects who are in the United Kingdom, Client hereby enters into the UK Controller-to-Processor Clauses(as Controller) with the Agency (as Processor). The UK Controller-to-Processor Clauses are incorporated into this DPA by reference and take effect from the commencement of an applicable transfer. Appendix 1 to the UK Controller-to-Processor Clauses shall be deemed to be pre-populated with the relevant sections of Data Processing Annex 1 to this DPA. Appendix 2 to the UK Controller-to-Processor Clauses shall be deemed to be pre-populated with the relevant sections of Data Processing Annex 2 to this DPA. If at any time the UK Government approves the EU Controller-to-Processor Clauses for use under the UK Data Protection Laws, the provisions of Clause 5.1(i) shall apply in place of this clause 5.1(ii) in respect transfers concerning the Personal Data of Data Subjects who are in the United Kingdom, subject to any modifications to the EU Controller-to-Processor Clauses required by the UK Data Protection Laws (and subject to the governing law of the EU Controller-to-Processor Clauses being English law and the supervisory authority being the Information Commissioner’s Office).
                        </BodyRg>
                    </OrderedListItem>
                </RomanList>
                <BodyRg>
                    5.2 In the event of any inconsistency between this DPA and the Standard Contractual Clauses executed between the Parties, the Standard Contractual Clauses shall govern.
                </BodyRg>
                <BodyRg>
                    5.3 In the event that the Standard Contractual Clauses are amended, replaced, or repealed under Data Privacy Laws, the Parties shall work together in good faith to enter into an updated version of such data transfer mechanism, or to negotiate a solution to enable compliant transfers of Personal Data.
                </BodyRg>
                <BodyRg>
                    5.4 Client acknowledges and accepts that the provision of the Services under the Main Agreement may require the Processing of Personal Data by Sub-processors in countries outside the EEA or UK.
                </BodyRg>
                <BodyRg>
                    5.5 Client will ensure that it is lawful to enable the transfer of Personal Data to Agency for the duration and for the purposes of the Agreement.
                </BodyRg>
                <BodyRg>
                    5.6 If, in the performance of this DPA Agency transfers any Personal Data of EU or UK Data Subjects to a Sub-processor located, or permits Processing of any Personal Data by a Sub-processor outside of the EEA except if in an Adequate Country (without prejudice to clause 6), Agency shall in advance of any such transfer ensure that a legal mechanism to achieve adequacy in respect of that Processing is in place, such as the requirement for Agency to execute or procure that the Sub-processor execute on behalf of Client Standard Contractual Clauses approved by the appropriate authorities under EU or UK Data Privacy Laws.
                </BodyRg>
                <BodyRg>
                    <Bold>
                        6. Other data transfers
                    </Bold>
                </BodyRg>
                <BodyRg>
                    In the event that the Services require the transfer of Personal Data from a jurisdiction requiring a different legal transfer mechanism than is otherwise described in this DPA, the Parties shall work together in good faith to enter into such a data transfer mechanism, or to negotiate a solution to enable compliant transfers of Personal Data.
                </BodyRg>
                <BodyRg>
                    <Bold>
                        7. Sub-processing
                    </Bold>
                </BodyRg>
                <BodyRg>
                    <Bold>7.1 GENERAL CONSENT TO AGENCY AFFILIATES:</Bold> Client grants a general authorization to Agency to appoint other members of the Agency Affiliates as Sub-processors. Client hereby provides general authorization for Agency use of Sub-processors as needed to provide the Services.
                </BodyRg>
                <BodyRg>
                    7.2 Agency will ensure that any Sub-processor is bound by written agreement requiring such Sub-processor to adhere to the same data protection obligations as those applicable to Agency under this DPA and Data Privacy Laws.
                </BodyRg>
                <BodyRg>
                    <Bold>
                        8. Audit
                    </Bold>
                </BodyRg>
                <BodyRg>
                    8.1 At Client’s sole cost and expense, and upon providing Agency 30 days’ written notice, Client may audit Company's activities in relation to the Processing of Personal Data covered by this DPA. Client shall provide Agency with all reasonable information and assistance in connection with any such audit. All auditors must sign a non-disclosure form as provided by Agency prior to an audit.
                </BodyRg>
                <BodyRg>
                    <Bold>
                        9. Resolution of disputes with data subjects or the Supervisory Authority
                    </Bold>
                </BodyRg>
                <BodyRg>
                    9.1 Each party agrees to provide reasonable assistance as is necessary to the other party to respond within a reasonable time to any enquiries from the Supervisory Authorities in relation to their handling of Personal Data.
                </BodyRg>
                <BodyRg>
                    9.2 In the event of a dispute or claim brought by a Data Subject or a Supervisory Authority concerning the Processing of Personal Data against either or both parties, the parties will inform each other about any such disputes or claims, and will cooperate with a view to settling them amicably in a timely fashion.
                </BodyRg>
                <BodyRg>
                    <Bold>
                        10. General
                    </Bold>
                </BodyRg>
                <BodyRg>
                    10.1 Notwithstanding anything to the contrary in this DPA, or the Main Agreement, if a claim is brought directly against a party for damages suffered in relation to a party’s breach of applicable Data Privacy Laws, the breaching party shall indemnify the non-breaching party for any cost, charge, damages, expenses or loss arising from such a claim, as finally adjudicated.
                </BodyRg>
                <BodyRg>
                    10.2 This DPA is without prejudice to the rights and obligations of the parties under the Main Agreement which shall continue to have full force and effect. In the event of any conflict between the terms of this DPA and the terms of the Main Agreement, the terms of this DPA shall prevail only to the extent the subject matter concerns the Processing of Personal Data. Nothing in this document varies or modifies the Standard Contractual Clauses.
                </BodyRg>
                <BodyRg>
                    10.3 This DPA sets out all of the terms that have been agreed between the parties in relation to the subjects covered by it.  Other than in respect of statements made fraudulently, no other representations or terms shall apply or form part of this DPA.
                </BodyRg>
                <BodyRg>
                    10.4 The applicable annexes as executed by the parties are hereby incorporated by reference and made part of this DPA.
                </BodyRg>

            </GlobalDpaContainer>
        </GlobalDpaPageWrap>
    );
}

export default GlobalDpaPage;
