import React, {useState, useEffect} from 'react';
import FooterAnimation from "components/FooterAnimation";
import {Col4, Row, TitleXl, TitleSm, WideScreenContainer} from "../../../components/elements";
import {r3cPageContent} from "../../../constants";
import {findContent, getImageDimensions} from "../../../generalHelpers";
import LoadingHoc from "../../../hoc/LoadingHOC";
import {Helmet} from "react-helmet";
import useRememberScrollPosition from "../../../hooks/useRememberScrollPosition";
import PurpleAnimHeader from 'components/PurpleAnimHeader';
import SpecialtyLeadershipSection from 'components/SpecialtyLeadershipSection';
import OurOfferingsAccordian from 'components/OurOfferingsAccordian';
import {OurOfferingsContainer, TitleXlContainer, CollaborateContainer} from '../integrated-communications/elements';
import ViewAll from 'components/ViewAll';
import CaseStudiesContainer from 'components/CaseStudiesContainer';
import RichText from 'components/RichText';


function AnalyticsInsights(props) {
    const {setLoading} = props
    const [heroSectionData, setHeroSectionData] = useState({})
    const [textSectionData, setTextSectionData] = React.useState({})
    const [leadershipTeam, setLeadershipTeam] = React.useState({})
    const [ourOfferingsData, setOurOfferingsData] = React.useState({})
    const [ourOfferingsMobile, setOurOfferingsMobile] = React.useState()
    const [seoData, setSeoData] = useState({})
    const [windowWidth, setWindowWidth] = React.useState();
    const [caseStudies, setCaseStudies] = React.useState()



    React.useEffect(() => {
        fetch(r3cPageContent + '?route=/analytics-and-insights').then((response) => response.json()).then((page) => {

        let seo = page.seo; 
        let image  = page?.seo?.image?.split('.')

        page?.seo?.image ?  getImageDimensions(page?.seo?.image,function (width,height){
            setSeoData({
                ...seo,
                mimeType:image[image.length - 1],
                seoImageHeight:height,
                seoImageWidth:width
            })

        }) :    setSeoData(seo)

        setHeroSectionData(findContent(page.contents, 'heroArea'))
        setTextSectionData(findContent(page.contents, 'textArea'))
        setLeadershipTeam(findContent(page.contents, 'cardSelector'))
        setOurOfferingsData(findContent(page.contents, 'accordion'))
        let pagedContent = findContent(page.contents, 'pagedList')
        if(pagedContent.length > 0 || Object.keys(pagedContent).length > 0){
            setCaseStudies(pagedContent)
        }else{
            setCaseStudies(undefined)
        }


        setLoading(false)
    })
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        const sortedAlphabeticallyMobile =  ourOfferingsData?.items?.slice().sort((a, b) => {
            const nameA = a.name.toUpperCase();
            const nameB = b.name.toUpperCase(); 

            return nameA < nameB ? -1 : 1
        })
        
           setOurOfferingsMobile(sortedAlphabeticallyMobile)
                
    },[ourOfferingsData])
        
        
        
    useEffect(() => {
        const handleResize = () => {
            window.innerWidth < 570 ? setWindowWidth(true) : setWindowWidth(false)
        }
    
        window.addEventListener("resize", handleResize);
        handleResize();
        return () => window.removeEventListener("resize", handleResize);
     })


    useRememberScrollPosition('data-and-ai-solutions');

    return (
        <div className="pageWrapper about">
            <Helmet>
                <title>{seoData.title}</title>
                <link rel="canonical" href={ window.location.pathname} />
                <meta name="description" content={seoData.description} />
                <meta name="og:description" content={seoData.description} />
                <meta name="og:title" content={seoData.title} />
                <meta name="og:url" content={window.location.protocol + "//" + window.location.host + window.location.pathname} />
                <meta name="og:type" content="website" />
                <meta name="og:site_name" content="Real Chemistry" />

                <meta name="og:image" content={window.location.protocol +seoData.image} />
                <meta name="og:image:type" content={"image/"+seoData?.mimeType} />
                <meta name="og:image:width" content={seoData?.seoImageWidth} />
                <meta name="og:image:height" content={seoData?.seoImageHeight} />

            </Helmet>
            <PurpleAnimHeader
                sectionLabel={heroSectionData.label}
                heroIntro={textSectionData.text}
                headerSplit={"3"}
                maxWidth="40%"
                marginRight="10%"
                backgroundImage={"/images/analytics-insights-header.png"}
                backgroundImgMbl={"/images/analytics-insights-mbl.png"}
                backgroundImgTitle={""}
                header={heroSectionData?.textArea?.content[0].content[0].value}
                titleMdPadding={"1% 0 0"}
                headerMaxWidth="66%"
            />
            <WideScreenContainer>
                <OurOfferingsContainer>
                 <TitleXlContainer>
                 <TitleXl fontWeight={900}>
                    Our Offerings
                 </TitleXl>  
                 {ourOfferingsData?.textArea?.content[0] && (
                     <RichText body={ourOfferingsData && ourOfferingsData?.textArea?.content[0]} options={{color: '#212121', fontSize: '20px'}}/>
                 )}
                 </TitleXlContainer>
                 {windowWidth && (
                    <Row>
                    {ourOfferingsMobile?.map((item, index) => (
                        <Col4  key={index}>
                            <OurOfferingsAccordian offeringsData={item}/>
                        </Col4>
                    ))}              
                    </Row>  
                 )}
                {!windowWidth && ourOfferingsData?.items?.length <= 4 && (
                    <Row>
                        {ourOfferingsData?.items?.map((item, index) => (
                            <Col4>
                                 <OurOfferingsAccordian offeringsData={item}/>
                            </Col4>
                        ))}              
                    </Row>
                )}
                {!windowWidth && ourOfferingsData?.items?.length > 4 &&  (  
                <Row justifyContent="flex-start">
                    <Col4>
                        <div style={{display: 'flex', flexDirection: 'column'}}>
                            {ourOfferingsData?.items?.slice(0,2).map((item, index) => (                      
                                    <OurOfferingsAccordian offeringsData={item}/>                        
                            ))}  
                        </div>  
                    </Col4>
                    <Col4>
                        <div style={{display: 'flex', flexDirection: 'column'}}>
                            {ourOfferingsData?.items?.slice(2,4).map((item, index) => (
                        
                                    <OurOfferingsAccordian offeringsData={item} />                        
                            ))}  
                        </div>  
                    </Col4>
                    <Col4>
                        <div style={{display: 'flex', flexDirection: 'column'}}>
                            {ourOfferingsData?.items?.slice(4, 6).map((item, index) => (                   
                                    <OurOfferingsAccordian offeringsData={item}/>                        
                            ))}  
                        </div>  
                    </Col4>
                    <Col4>
                        <div style={{display: 'flex', flexDirection: 'column'}}>
                            {ourOfferingsData?.items?.slice(6, 7).map((item, index) => (                   
                                    <OurOfferingsAccordian offeringsData={item}/>                        
                            ))}  
                        </div>  
                    </Col4>
                    <Col4>
                        <div style={{display: 'flex', flexDirection: 'column'}}>
                            {ourOfferingsData?.items?.slice(7, 8).map((item, index) => (                   
                                    <OurOfferingsAccordian offeringsData={item}/>                        
                            ))}  
                        </div>  
                    </Col4>
                    <Col4>
                        <div style={{display: 'flex', flexDirection: 'column'}}>
                            {ourOfferingsData?.items?.slice(8, 9).map((item, index) => (                   
                                    <OurOfferingsAccordian offeringsData={item}/>                        
                            ))}  
                        </div>  
                    </Col4>
                </Row>  
             )}     
                </OurOfferingsContainer> 
            </WideScreenContainer>
            <WideScreenContainer>
                <SpecialtyLeadershipSection leadership={leadershipTeam} maxWidth={'100%'}/>
                {caseStudies && (
                    <CaseStudiesContainer caseStudies={caseStudies}/>
                )}


                <CollaborateContainer>
                    <TitleSm>
                        WANT TO COLLABORATE?
                    </TitleSm>
                    <TitleXl fontWeight="900">
                        Let’s get to work
                    </TitleXl>
                    <ViewAll link={"/contact"} title="GET IN TOUCH" borderColor="#000" titleColor="#000" hoverBackground="#000" width="216px" height="38px" svgColor="#000" />
                </CollaborateContainer>
            </WideScreenContainer>
            <FooterAnimation
				svgDesktop="/images/purple-footer.svg"
				svgMobile="/images/purple-footer-mbl.svg"
				borderTop="none"
			/>
        </div>
    );
}

export default LoadingHoc(AnalyticsInsights);

