
import styled, { css } from 'styled-components'
import { SectionTitle, TitleXl } from '../elements'
import { sizeTabletLg, sizeWideScreen, sizeMobileMd } from 'components/devices'

export const PagedListWrap = styled.div`

    /* Wide Screen CSS */
    ${sizeWideScreen(css`
        border-right: ${({ borderWideScreen }) => borderWideScreen || "1px solid #707070"};
        border-left: ${({ borderWideScreen }) => borderWideScreen || "1px solid #707070"};
    `)}
    ${SectionTitle} {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: justify;
        -ms-flex-pack: justify;
        justify-content: space-between;
        ${sizeTabletLg(`
            padding: 80px 45px;
            -webkit-box-orient: vertical;
            -webkit-box-direction: reverse;
            -ms-flex-direction: column-reverse;
            flex-direction: column-reverse;
            -webkit-box-align: start;
            -ms-flex-align: start;
            -ms-grid-row-align: flex-start;
            align-items: flex-start;
        `)}
        ${sizeMobileMd(`
            padding: 40px 18px;
        `)}
        ${TitleXl}{
            font-size: 48px;
            line-height: 57px;
            font-weight: 900;
            ${sizeMobileMd(`
                font-size: 40px;
                line-height: 48px;
            `)}
        }
    }
`;

export const PagedListRowsWrap = styled.div`
    ${sizeWideScreen(css`
        border-left: ${({ listBorder }) => listBorder || "none"};
        border-right: ${({ listBorder }) => listBorder || "none"};
    `)}
`;

export const AddMore = styled.button`
    padding: 40px 0;
    text-align: center;
    cursor: pointer;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    background-color: transparent;
    width: 100%;
    border: none;
    border-top: ${({ borderTop }) => borderTop || "1px solid #707070"};
    border-bottom: ${({ borderBottom }) => borderBottom || "none"};
    margin-top: -1px;
    &:focus {
        outline: none;
        box-shadow: none;
    }
`;

export const AddIcon = styled.img`
`;
