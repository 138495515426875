import React, { useEffect } from "react";
import { TweenLite } from "gsap";
import {
  TitleMd,
  Row,
  Col12,
  BodyRg,
  Desktop,
  Mobile,
} from "components/elements";

import {
  AccordionCardWrap,
  AccordionHeader,
  AccordionToggle,
  Bar,
  AccordionBody,
  BodyInner,
  BodyContainer,
  InformHeader,
  Table,
  THead,
  Tr,
  Th,
  TBody,
  Td,
} from "./elements";

import InformCard from "components/InformCard";

function AccordionCard({
  title,
  body,
  openInfoAccordion,
  lowerBody,
  handleToggleInfoAccordion,
}) {
  // const [collapse, setCollapse] = React.useState(false);
  useEffect(() => {
    openInfoAccordion
      ? TweenLite.to(".content2", 0.25, { height: "auto", overflow: "visible" })
      : TweenLite.to(".content2", 0.25, { height: 0, overflow: "hidden" });
  }, [openInfoAccordion]);

  const handleOnClick = () => {
    handleToggleInfoAccordion(!openInfoAccordion);
  };
  return (
    <AccordionCardWrap id="information-we-collect">
      <AccordionHeader
        className={openInfoAccordion ? "expanded" : null}
        onClick={handleOnClick}
      >
        <TitleMd textTransform="uppercase">{title}</TitleMd>
        <AccordionToggle>
          <Bar />
          <Bar />
        </AccordionToggle>
      </AccordionHeader>
      <AccordionBody className="content2">
        <BodyInner>
          <BodyContainer>
            {body?.map((item, idx) => {
              return (
                <BodyRg
                  key={idx}
                  dangerouslySetInnerHTML={{ __html: item }}
                ></BodyRg>
              );
            })}
          </BodyContainer>
        </BodyInner>

        {/* For Desktop */}
        <Desktop>
          <Table cellPadding="0" cellSpacing="0" justify="start">
            <THead>
              <Tr>
                <Th>
                  <InformHeader
                    borderTop="none"
                    borderRight="none"
                    borderBottom="none"
                    borderLeft="none"
                  >
                    <BodyRg textTransform="uppercase">
                      Category of Information
                    </BodyRg>
                  </InformHeader>
                </Th>
                <Th>
                  <InformHeader
                    borderTop="none"
                    borderRight="none"
                    borderBottom="none"
                    borderLeft="none"
                  >
                    <BodyRg textTransform="uppercase">
                      Source of information and Business Purpose
                    </BodyRg>
                  </InformHeader>
                </Th>
                <Th>
                  <InformHeader
                    borderTop="none"
                    borderRight="none"
                    borderBottom="none"
                    borderLeft="none"
                  >
                    <BodyRg textTransform="uppercase">
                      Categories of Third Parties with whom we share
                    </BodyRg>
                  </InformHeader>
                </Th>
              </Tr>
            </THead>
            <TBody>
              <Tr>
                <Td>
                  <InformCard
                    borderTop="none"
                    borderRight="none"
                    borderBottom="none"
                    borderLeft="none"
                    subTitle="About you:"
                    body={[
                      "Identifiers such as a real name, postal address, unique personal identifier, email address, social security number, professional / employment - related information, phone number, authorized users of services, financial / billing information, or other similar identifiers." +
                      "<br/></br> Information you may provide directly to us based upon your consent, including health related information, as part of our research activities and services.",
                    ]}
                  />
                </Td>
                <Td>
                  <InformCard
                    borderTop="none"
                    borderRight="none"
                    borderBottom="none"
                    borderLeft="none"
                    body={[
                      "Directly from you in the case of sign-ups for newsletter or events, participation in research activities, applications for employment, or communication you send to us.",
                      "From service providers and vendors for the purposes of direct marketing such as online advertising where permitted by applicable law.",
                      "From service providers and vendors for the purposes of research and analytics",
                      "From our clients for the purposes of providing marketing and communications services on the client’s behalf.",
                       "From our customers for the purposes of providing our services."
                    ]}
                  />
                </Td>
                <Td>
                  <InformCard
                    borderTop="none"
                    borderRight="none"
                    borderBottom="none"
                    borderLeft="none"
                    body={[
                      "Our affiliates and subsidiaries",
                      "Service providers and vendors who provide services on our behalf",
                      "Advertising networks",
                      "Real Chemistry clients and business partners",
                      "Government entities, as required by law",
                    ]}
                  />
                </Td>
              </Tr>
              <Tr>
                <Td>
                  <InformCard
                    borderTop="none"
                    borderRight="none"
                    borderBottom="none"
                    borderLeft="none"
                    subTitle="From your devices, such as when you visit our Properties:"
                    body={[
                      "Internet or other electronic network activity information, including, but not limited to, browsing history, search history, and information regarding a consumer’s interaction with an Internet Web site, application, or advertisement, browser and device information including mobile device type, mobile network, IP address used to connect your computer or device to the Internet, browser type and version, time zone setting, browser plug-in types and versions and operating system.",
                      "Information about how you use the Properties.",
                      "Geographic location information – identifying only city level, state and country.",
                      "Information collected through cookies, pixel tags and other tracking technologies.",
                    ]}
                  />
                </Td>
                <Td>
                  <InformCard
                    borderTop="none"
                    borderRight="none"
                    borderBottom="none"
                    borderLeft="none"
                    body={[
                      "Collected when you use any part of our services, interact with our internet properties, or the internet properties of Real Chemistry Clients, or interact with messages we send you, for the purposes of providing our services, customizing our users’ visits to our Properties, research, direct marketing, and managing our business.",
                      "From service providers and vendors for the purposes of direct marketing such as online advertising"
                    ]}
                  />
                </Td>
                <Td>
                  <InformCard
                    borderTop="none"
                    borderRight="none"
                    borderBottom="none"
                    borderLeft="none"
                    body={[
                      "Our affiliates and subsidiaries",
                      "Service providers and vendors who provide services on our behalf",
                      "Advertising networks",
                      "Real Chemistry clients and business partners",
                    ]}
                  />
                </Td>
              </Tr>
              <Tr>
                <Td>
                  <InformCard
                    borderTop="none"
                    borderRight="none"
                    borderBottom="none"
                    borderLeft="none"
                    subTitle="From publicly available sources where permitted by law: "
                    body={[
                      "Personal data, identifiers and content manifestly made public by individuals including social media identifiers, social media quotes, demographic information, health related information, location information, interest information, professional / employment related information, and any other information you publish on an Internet website that is publicly available.",
                    ]}
                  />
                </Td>
                <Td>
                  <InformCard
                    borderTop="none"
                    borderRight="none"
                    borderBottom="none"
                    borderLeft="none"
                    body={[
                      "From publicly accessed sites, such as social media platforms, or public databases where permitted by law for the purposes of providing Real Chemistry Research Services.",
                    ]}
                  />
                </Td>
                <Td>
                  <InformCard
                    borderTop="none"
                    borderRight="none"
                    borderBottom="none"
                    borderLeft="none"
                    body={[
                      "Our affiliates and subsidiaries",
                      "Service providers and vendors who provide services on our behalf",
                      "Advertising networks",
                      "Real Chemistry clients and business partners",
                    ]}
                  />
                </Td>
              </Tr>
                <Tr>
                    <Td>
                        <InformCard
                            borderTop="none"
                            borderRight="none"
                            borderBottom="none"
                            borderLeft="none"
                            subTitle="From licensed data sources:"
                            body={[
                                "Identifiers such as a real name, postal address, unique personal identifier, email address, professional / employment-related information, and phone number.",
                                "Demographic information.",
                                "Interest and affinity information.",
                                "Information collected through cookies, pixel tags and other tracking technologies."
                            ]}
                        />
                    </Td>
                    <Td>
                        <InformCard
                            borderTop="none"
                            borderRight="none"
                            borderBottom="none"
                            borderLeft="none"
                            body={[
                                "From service providers and vendors who license data to Real Chemistry, for the purposes of providing Real Chemistry Research Services.",
                            ]}
                        />
                    </Td>
                    <Td>
                        <InformCard
                            borderTop="none"
                            borderRight="none"
                            borderBottom="none"
                            borderLeft="none"
                            body={[
                                "Our affiliates and subsidiaries",
                                "Service providers and vendors who provide services on our behalf",
                                "Advertising networks",
                                "Real Chemistry clients and business partners",
                            ]}
                        />
                    </Td>
                </Tr>
            </TBody>
          </Table>
        </Desktop>

        {/* For Mobile */}
        <Mobile>
          <Row>
            <Col12>
              <InformHeader>
                <BodyRg textTransform="uppercase">
                  Category of Information
                </BodyRg>
              </InformHeader>
              <InformCard
                subTitle="From you:"
                body={[
                  "Identifiers such as a real name, postal address, unique personal identifier, email address, social security number, professional / employment - related information, phone number, authorized users of services, financial / billing information, or other similar identifiers." +
                  "<br/><br/>" +
                  "	Information you may provide directly to us based upon your consent, including health related information, as part of our research activities and services.",
                ]}
              />
              <InformCard
                subTitle="From your devices, such as when you visit our Properties:"
                body={[
                  "Internet or other electronic network activity information, including, but not limited to, browsing history, search history, and information regarding a consumer’s interaction with an Internet Web site, application, or advertisement, browser and device information including mobile device type, mobile network, IP address used to connect your computer or device to the Internet, browser type and version, time zone setting, browser plug-in types and versions and operating system",
                  "Information about how you use the Properties.",
                  "Geographic location information – identifying only city level, state and country.",
                  "Information collected through cookies, pixel tags and other tracking technologies — see our <a href='/ad-cookie-policy'>Ad and Cookie Policy</a> for more detail.",
                ]}
              />
              <InformCard
                subTitle="From publicly available sources: "
                body={[
                  "Personal data, identifiers and content manifestly made public by individuals including social media identifiers, social media quotes, demographic information, health related information, location information, interest information, professional / employment related information, and any other information you publish on an Internet website that is publicly available.",
                ]}
              />
              <InformCard
                subTitle="From licensed data sources: "
                body={[
                  "Identifiers such as a real name, postal address, unique personal identifier, email address, professional / employment-related information, and phone number. <br/><br/> Demographic information. <br/><br/>Interest and affinity information. <br/><br/>Information collected through cookies, pixel tags and other tracking technologies.",
                ]}
              />
            </Col12>
            <Col12>
              <InformHeader>
                <BodyRg textTransform="uppercase">
                  Source of information and Business Purpose
                </BodyRg>
              </InformHeader>
              <InformCard
                body={[
                  "Directly from you in the case of sign-ups for newsletter or events, participation in research activities, applications for employment, or communication you send to us.",
                  "From service providers and vendors for the purposes of direct marketing such as online advertising.",
                  "From service providers and vendors for the purposes of research and analytics",
                  "From our clients for the purposes of providing marketing and communications services on the client’s behalf",
                  "From our customers for the purposes of providing our services",

                ]}
              />
              <InformCard
                body={[
                  "Collected when you use any part of our services, interact with our internet properties, or the internet properties of Real Chemistry Clients, or interact with messages we send you, for the purposes of providing our services, customizing our users’ visits to our Properties, research, direct marketing, and managing our business. <br/><br/> From service providers and vendors for the purposes of direct marketing such as online advertising",
                ]}
              />
              <InformCard
                body={[
                  "From publicly accessed sites, such as social media platforms, or public databases where permitted by for the law for purposes of providing Real Chemistry Research Services.",
                ]}
              />
              <InformCard
                body={[
                  "From service providers and vendors who license data to Real Chemistry,for the purposes of providing Real Chemistry Research Services.",
                ]}
              />
            </Col12>
            <Col12>
              <InformHeader>
                <BodyRg textTransform="uppercase">
                  Categories of Third Parties with whom we share
                </BodyRg>
              </InformHeader>
              <InformCard
                body={[
                  "Our affiliates and subsidiaries",
                  "Service providers and vendors who provide services on our behalf",
                  "Advertising networks",
                  "Real Chemistry clients and business partners",
                  "Government entities, as required by law",
                ]}
              />
              <InformCard
                body={[
                  "Our affiliates and subsidiaries",
                  "Service providers and vendors who provide services on our behalf",
                  "Advertising networks",
                  "Real Chemistry clients and business partners",
                ]}
              />
              <InformCard
                body={[
                  "Our affiliates and subsidiaries",
                  "Service providers and vendors who provide services on our behalf",
                  "Advertising networks",
                  "Real Chemistry clients and business partners",
                ]}
              />
              <InformCard
                body={[
                  "Our affiliates and subsidiaries",
                  "Service providers and vendors who provide services on our behalf",
                  "Advertising networks",
                  "Real Chemistry clients and business partners",
                ]}
              />
            </Col12>
          </Row>
        </Mobile>

        <BodyInner>
          <BodyContainer>
            {lowerBody?.map((item, idx) => {
              return (
                <BodyRg
                  key={idx}
                  dangerouslySetInnerHTML={{ __html: item }}
                ></BodyRg>
              );
            })}
          </BodyContainer>
        </BodyInner>
      </AccordionBody>
    </AccordionCardWrap>
  );
}

export default AccordionCard;
