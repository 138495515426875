import styled, {keyframes} from "styled-components";
import {sizeDesktopMd, sizeLaptopLg, sizeTabletLg } from "components/devices";

export const Input = styled.input`
  height: 0;
  width: 0;
  opacity: 0;
  z-index: -1;
`;

export const Label = styled.label`
  position: relative;
  display: inline-block;
  cursor: ${props => (props.disabled ? "not-allowed" : "pointer")};
  margin: 0.4em 1em;
  display: flex;
  align-items: center;
  font-size: 1.5em;
  font-weight: 400;
  ${sizeDesktopMd(`
        font-size: 1.5em;
    `)}
  ${sizeLaptopLg(`
        font-size: 1.375em;
    `)}
  ${sizeTabletLg(`
      font-size: 1.25em;
    `)}
`;

const rotate = keyframes`
  from {
    opacity: 0;
    transform: rotate(0deg);
  }
  to {
    opacity: 1;
    transform: rotate(45deg);
  }
`;

export const Indicator = styled.div`
  width: 30px;
  height: 30px;
  position: absolute;
  top: 0em;
  left: -1.6em;
  border: 3px solid black;
  background-color: ${props => (props.disabled ? " #ccc" : "white")};

  &.checked{
    background-color: #D12FFF;
  }

  ${Input}:not(:disabled):checked & {
    background: #d1d1d1;
  }

  ${Label}:hover & {
    background: #ccc;
  }

  &::after {
    content: "";
    position: absolute;
    display: none;
    border: solid black;
    border-width: 0 0.2em 0.2em 0;
  }

  ${Input}:checked + &::after {
    display: block;
    top: 2.1px;
    left: 9px;
    width: 35%;
    height: 70%;
    border: solid white;
    border-width: 0 0.2em 0.2em 0;
    animation-name: ${rotate};
    animation-duration: 0.01s;
    animation-fill-mode: forwards;

  }

  &:disabled {
    cursor: not-allowed;
  }
`;