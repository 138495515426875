import React from "react";
import { AccordionSectionWrap } from "./elements";
import AccordionCard from "components/Accordion/accordion";

function AccordionSection(props) {
    return (
        <AccordionSectionWrap className="acc-cont">
            <AccordionCard
                openOtherAccordion={props.openOtherAccordion.filter(
                    (a) => a.id === "downloadCreative"
                )}
                handleOpenAccordion={props.handleOpenAccordion}
                handleToggleOtherAccordion={props.handleToggleOtherAccordion}
                cardId="downloadCreative"
                title="1) Download Creative for Social Posts "
                body={[
                    {
                        paragraphs: [
                            "Chose creative option and download <a target=\"_blank\" href='https://ent.box.com/s/ugp4nn405dfqissr6ankphh1erhyftb8'>here</a>.",
                        ]
                    }
                ]}
                index={1}
            />
            <AccordionCard
                openOtherAccordion={props.openOtherAccordion.filter(
                    (a) => a.id === "linkedinCopy"
                )}
                handleOpenAccordion={props.handleOpenAccordion}
                handleToggleOtherAccordion={props.handleToggleOtherAccordion}
                cardId="linkedinCopy"
                title="2) Select Your LinkedIn Copy "
                body={[
                    {
                        paragraphs: [
                            "<strong>Highlight and copy your preferred LinkedIn post from the options below.</strong>",

                            "<div style='margin-left: 50px'>" +
                            "<strong style='padding: 20px 0'>Option 1</strong><br/>" +
                            "#Health and wellness are now at the center of our culture, and there's nowhere better than #SXSW 2022 to celebrate that with the innovators driving the future of #health.\n" +
                            "</div>",

                            "<div style='margin-left: 50px'>" +
                            "I'm excited to be speaking during SXSW at Real Chemistry's " +
                            "Health Care Media Lounge on Saturday, March 12! Register for this free event to watch virtually " +
                            "or in person in Austin! #RealChemSXSW <a href='https://bit.ly/35jnfQX '>bit.ly/35jnfQX </a></div>",

                            "<div style='margin-left: 50px'>" +
                            "<strong style='padding: 20px 0'>Option 2</strong><br/>" +
                            "Real Chemistry is putting #health at the center of culture " +
                            "at #SXSW 2022, and I'm excited to be a part of it. I'll be speaking about health innovation " +
                            "and the future of our industry — join me for free in Austin or watch virtually on Saturday, " +
                            "March 12! Register today. #RealChemSXSW <a href='https://bit.ly/35jnfQX'>bit.ly/35jnfQX</a></div>",

                            "<div style='margin-left: 50px'>" +
                            "<strong style='padding: 20px 0'>Option 3</strong><br/>" +
                            "Real Chemistry is bringing the future of #health into sharper" +
                            " focus, and I'm honored to be joining them at #SXSW 2022. Don't miss this free event in Austin " +
                            "on Saturday, March 12, or tune in virtually! Register today. #RealChemSXSW " +
                            "<a href='https://bit.ly/35jnfQX'>bit.ly/35jnfQX</a></div>"
                        ]
                    }
                ]}
                index={2}
            />
            <AccordionCard
                openOtherAccordion={props.openOtherAccordion.filter(
                    (a) => a.id === "twitterCopy"
                )}
                handleOpenAccordion={props.handleOpenAccordion}
                handleToggleOtherAccordion={props.handleToggleOtherAccordion}
                cardId="twitterCopy"
                title="3) Select Your Twitter Copy "
                body={[
                    {
                        paragraphs: [
                            "<strong>Highlight and copy your preferred tweet from the options below.</strong>",

                            "<div style='margin-left: 50px'>" +
                            "<strong style='padding: 20px 0'>Option 1</strong><br/>" +
                            "We're bringing the future of #health into sharper focus. " +
                            "Don't miss @RealChemistry_'s Health Care Media Lounge, an official part of #SXSW 2022, on " +
                            "Saturday, March 12! Register here to tune in virtually or join me for free in Austin. " +
                            "#RealChemSXSW <a href='https://bit.ly/35jnfQX'>bit.ly/35jnfQX </a></div>",

                            "<div style='margin-left: 50px'>" +
                            "<strong style='padding: 20px 0'>Option 2</strong><br/>" +
                            "Join me for conversation, connection, and community at " +
                            "#RealChemSXSW, where I'll be sharing my vision for the future of #health care with fellow " +
                            "health innovators. Register here to tune in virtually or join us live in Austin on Saturday, " +
                            "March 12! <a href='https://bit.ly/35jnfQX'>bit.ly/35jnfQX</a></div>",

                            "<div style='margin-left: 50px'>" +
                            "<strong style='padding: 20px 0'>Option 3</strong><br/>" +
                            "The future of #health is coming to life at #SXSW 2022. Join me, " +
                            "@RealChemistry_ & fellow health care leaders at #RealChemSXSW, where I'll be speaking about" +
                            " the innovations transforming our industry. Attend in person or stream this free event on" +
                            " March 12! <a href='https://bit.ly/35jnfQX'>bit.ly/35jnfQX</a></div>"
                        ],
                    },
                ]}
                index={3}
            />
            <AccordionCard
                openOtherAccordion={props.openOtherAccordion.filter(
                    (a) => a.id === "sharingLinkedin"
                )}
                handleOpenAccordion={props.handleOpenAccordion}
                handleToggleOtherAccordion={props.handleToggleOtherAccordion}
                cardId="sharingLinkedin"
                title="4) Instructions for Sharing a LinkedIn Post"
                body={[
                    {
                        numberedlist: [
                            "Save your preferred image to your computer.",
                            "Login to LinkedIn.",
                            "At the top of your homepage, you’ll see a box that says, “Start a post”’.",
                            "Click into the “Start a post” Box.",
                            "Copy and paste your preferred LinkedIn copy into the text box.",
                            "Along the bottom, there’s a rectangle with a mountain landscape. If you hover over the button, it says “Add a photo.”",
                            "Click done.",
                            "Click post!",
                        ],
                    },
                ]}
                index={4}
            />
            <AccordionCard
                openOtherAccordion={props.openOtherAccordion.filter(
                    (a) => a.id === "researchServices"
                )}
                handleOpenAccordion={props.handleOpenAccordion}
                handleToggleOtherAccordion={props.handleToggleOtherAccordion}
                cardId="researchServices"
                title="5) Instructions for Sharing a Tweet"
                body={[
                    {
                        numberedlist: [
                            "Save the image attached to your computer.",
                            "Login to Twitter.",
                            "At the top of your homepage, you’ll see a box that says, “What’s happening?”’",
                            "Copy and paste the Twitter copy above into the “What’s happening?” box",
                            "A thumbnail image will appear – click the small “x” on the top left-hand side of the image to remove.",
                            "Along the bottom, there’s a rectangle with a mountain landscape. If you hover over the button, it says “Media.”",
                            "Click that button and upload the image you saved to your computer.",
                            "Click tweet!",
                        ],
                    },
                ]}
                index={5}
            />
        </AccordionSectionWrap>
    );
}

export default AccordionSection;