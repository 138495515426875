import React, { useEffect, useRef, useState } from 'react'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import { gsap, TweenMax, TimelineMax } from 'gsap'
import { CSSRulePlugin } from 'gsap/CSSRulePlugin'
import { TitleLg, BodySm, TitleMd } from '../elements'
import {
	ExpandingCardsWrapper,
	ContainerCard,
	CardTitle,
	Icon,
	ExpandedContent,
	ContentRight,
	HeadRow,
	CardLink,
	CardNumber,
	CardIcon,
	CardIconCollapse,
	Image,
} from './elements'
import { useAppContext } from 'store/index'
import { richTextOptions } from '../RichText/options'

gsap.registerPlugin(CSSRulePlugin)


function CardSection({ overrideColor, cards, updateCardState, clickedOutSide, wrapperWidth })
{
	useEffect(() =>
	{
		setOpenedEndWidth(wrapperWidth - 570)
	}, [wrapperWidth])

	let isOpening = false
	let timeOut = false

	const { serviceOpenedCard, openedCard } = useAppContext();
	const [data, setData] = useState([])
	const [openedEndWidth, setOpenedEndWidth] = useState(wrapperWidth - 570)
	const tl = new TimelineMax({})
	const closeTl = new TimelineMax()
	const screenWidth = window.innerWidth
	const openRef = useRef(null)
	const cardNumberLeft = useRef(null)
	const [cardWidth, setCardWidth] = useState(0)

	useEffect(() => {
		if (cards)		// once card data shows up, initialize
		{
			setData(cards.map((item, index) =>
			{
				item.state = openedCard === -1 ? 'default' : openedCard === index ? 'open' : 'shrink';
				item.ref = React.createRef(null)
				if ( overrideColor )
					item.backgroundColor = overrideColor
				return item
			}))
		}
		/* eslint-disable react-hooks/exhaustive-deps */
	}, [cards])

	useEffect(() => {
		if (clickedOutSide)
			handleClose()
		/* eslint-disable react-hooks/exhaustive-deps */
	}, [clickedOutSide])

	const handleOpen = (i) => {
		if (isOpening)
			return

		updateCardState(true)

		setData(data.map((item, index) => {
			if (index === i) {
				item.state = 'open'
				serviceOpenedCard(i);
			}
			else
				item.state = 'shrink'
			return item
		}))
	}

	const handleClose = () => {
		serviceOpenedCard(-1);
		updateCardState(false)

		if (openedEndWidth + 570 > 991) {
			animateLeftLine(0)

			closeTl
				.to(".open-title", 0, { x: 0, opacity: 0 })
				.fromTo
				(
					".open-right-title, .open-left-close-arrow, .open-right-image, .open-right-description, .open-right-summary, .open-right-services, .open-right-link ",
					0.1,
					{ opacity: 0, display: "block" },
					{ opacity: 0, display: "block", delay: 0 }
				)
				.to(".defaultNumber, .defaultTitle, .defaultArrow", 0.3, { opacity: 0 })
				.fromTo
				(
					".open",
					0.6,
					{ width: openedEndWidth + "px", height: "auto" },
					{
						width: "calc(25% - 30px)",
						height: "420px",
						flexGrow: "0",
						flexShrink: "0",
						flexBasis: "calc(25% - 30px)"
					}
				)
				.to(".defaultNumber, .defaultTitle, .defaultArrow", 0.3,
					{
						opacity: 1,
					})
		}
		else {
			closeTl
				.to(".open-title", 0, { x: 0, opacity: 0 })
				.fromTo
				(
					".open-right-title, .open-left-close-arrow, .open-right-image, .open-right-description, .open-right-summary, .open-right-services, .open-right-link ",
					0.1,
					{ opacity: 0, display: "block" },
					{ opacity: 0, display: "block", delay: 0 }
				)
				.fromTo(".open", 1, { height: "auto" }, { height: "400px" })
		}

		const target = openRef.current.parentNode.offsetTop
		window.scrollTo({ top: target - 100, behavior: "smooth" })

		setTimeout(() => {
			setData
				(
					data.map((item) => {
						item.state = 'default'
						return item
					})
				)
		}, 900)
	}

	useEffect(() => {

		window.addEventListener("resize", updateContainerWidth)

		return () => {
			window.removeEventListener("resize", updateContainerWidth);
		}
		/* eslint-disable react-hooks/exhaustive-deps */
	}, [])

	const updateContainerWidth = () => {
		clearTimeout(timeOut)
		timeOut = setTimeout(() => {
			setOpenedEndWidth(window.innerWidth - 570)
		}, 750)
	}

	useEffect(() => {
		animateCards()
		/* eslint-disable react-hooks/exhaustive-deps */
	}, [data, openedEndWidth])

	const animateCards = () => {
		isOpening = true
		let cardOpened = { opened: false, idx: -1 }

		data.forEach((card, i) => {
			if (card.ref && card.ref.classList[card.ref.classList.length - 1] === "open") {
				cardOpened.opened = true
				cardOpened.idx = i
			}
		})

		if (data.length) {
			// Check if one card is opened
			if (cardOpened.opened !== true)
				setCardWidth(data[0].ref ? data[0].ref.clientWidth : 0)
		}

		if (screenWidth > 991) {
			if (cardNumberLeft.current)
				animateLeftLine(0)

			TweenMax.to(".shrink", 0, { height: "420px" })
			TweenMax.to(".default", 0,
				{
					height: "420px",
					width: "100%",
					flexGrow: "0",
					flexShrink: "0",
					flexBasis: "calc(25% - 30px)"
				})
		}
		else
			TweenMax.to(".default, .shrink", 0, { height: "auto", width: "100%" })

		if (openedEndWidth + 570 > 991) {
			tl.to(".shrink", 0,
				{
					flexGrow: "1",
					width: "140px",
					flexShrink: "1",
					flexBasis: "140px",
					height: "420px",
				})
			tl.fromTo
				(
					".open",
					0.6,
					{ width: cardWidth + "px", height: "420px" },
					{
						width: openedEndWidth + "px",
						height: "auto",
						flexGrow: "0",
						flexShrink: "0",
						flexBasis: openedEndWidth + "px"
					}
				)
				.fromTo
				(
					".open-title",
					0.3,
					{ x: -25, opacity: 0 },
					{ x: 0, opacity: 1 }
				)
				.fromTo
				(
					".open-right-title, .open-left-close-arrow, .open-right-image, .open-right-description, .open-right-summary, .open-right-services, .open-right-link ",
					0.1,
					{ opacity: 0, display: "block" },
					{ opacity: 1, delay: 0 }
				)

			if (cardNumberLeft.current)
				animateLeftLine(1)
		}
		else {
			tl.to(".shrink, .default", { width: "100%", height: "400px" })
			tl.fromTo
				(
					".open",
					0.6,
					{ height: "400px", width: "100%" },
					{ height: "auto" }
				)
				.fromTo
				(
					".open-title",
					0.3,
					{ x: -25, opacity: 0 },
					{ x: 0, opacity: 1 }
				)
				.fromTo
				(
					".open-right-title, .open-left-close-arrow, .open-right-image, .open-right-description, .open-right-summary, .open-right-services, .open-right-link ",
					0.1,
					{ opacity: 0, display: "block" },
					{ opacity: 1, delay: 0 }
				)

			if (openRef.current) {
				const target = openRef.current.parentNode.offsetTop
				window.scrollTo({ top: target - 64, behavior: "smooth" })
			}
		}

		setTimeout(() => {
			isOpening = false
		}, 1000)
	}

	const animateLeftLine = (direction) => {
		const borderClass = cardNumberLeft.current.classList[cardNumberLeft.current.classList.length - 1]
		const rule = CSSRulePlugin.getRule(`.${borderClass}:after`)

		TweenMax.to(rule, 0.7, { cssRule: { height: direction === 1 ? "100%" : "0" } })
	}

	function colorAdjust(color, amount)
	{
		if ( ! color )
			return '#000'

		return '#' + color.replace(/^#/, '').replace(/../g, color => ('0'+Math.min(255, Math.max(0, parseInt(color, 16) + amount)).toString(16)).substr(-2))
	}

	return data.map((item, index) => {
		return (
			<ExpandingCardsWrapper
				state={item.state}
				className={item.state}
				hoverBg={colorAdjust( item.backgroundColor, -60 )}
				backgroundColor={item.backgroundColor}
				key={`service-${index}`}
				ref={(cardContainer) => { item.ref = cardContainer }} >

				{ item.state === "open" &&
				(
					<ExpandedContent ref={openRef}>
						<CardNumber ref={cardNumberLeft}>
							<TitleMd color="#fff" className="number">{index + 1}</TitleMd>
							<CardIconCollapse className="open-left-close-arrow" onClick={() => handleClose(index)} >
								<Icon src="/images/expand-icon.svg" />
							</CardIconCollapse>
						</CardNumber>
						<ContentRight>
							<HeadRow>
								<TitleLg
									className="open-title open-right-title"
									color="#fff"
									initialHidden={true}
									initialOpacity={true}
								> {item.title} </TitleLg>

								<CardIconCollapse className="open-left-close-arrow" onClick={() => handleClose(index)} >
									<Icon src="/images/expand-icon.svg" />
								</CardIconCollapse>
							</HeadRow>
							<Image
								className="open-right-image"
								src={item.image}
							/>
							<div className="open-right-description new-desc">
								{item.detail ? (documentToReactComponents(item.detail, richTextOptions({ color: "#fff", margin: "0 0 20px 0" }))) : ''}
							</div>

							<BodySm className="open-right-summary" color="#fff"> {item.smallText ? item.smallText : ''} </BodySm>
							<CardLink className="open-right-link" to={item.ctaLink}>
								{item.ctaText}
							</CardLink>
						</ContentRight>
					</ExpandedContent>
				)}

				{ item.state === "default" || item.state === "shrink" ?
				(
					<ContainerCard onClick={() => handleOpen(index)}>
						<TitleMd className="defaultNumber" color="#fff">{index + 1}</TitleMd>
						<CardTitle className="defaultTitle">{item.title}</CardTitle>
						<CardIcon className="defaultArrow">
							<Icon src="/images/expand-icon-rotate.svg" />
						</CardIcon>
					</ContainerCard>
				) : null}
			</ExpandingCardsWrapper>
		)
	})
}

export default CardSection
