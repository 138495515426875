
import React from 'react'

import { PanelTitle } from './elements'
import { TitleMd } from 'components/elements'


function TitleComponent({ borderTop, borderRight, borderBottom, borderLeft, titleNumber, backgroundColor, title, rows, cols, color }) {
    return (
        <PanelTitle
            borderTop={borderTop}
            borderRight={borderRight}
            borderBottom={borderBottom}
            borderLeft={borderLeft}
            backgroundColor={backgroundColor}
            rows={rows}
            cols={cols}
        >
            <TitleMd color={color} textTransform="uppercase" className="index">0{titleNumber}</TitleMd>
            <TitleMd color={color} textTransform="uppercase">{title}</TitleMd>
        </PanelTitle >
    )

}

export default TitleComponent
