import styled from 'styled-components';
import {
    TitleXl,
 
    BodySm,
} from "components/elements";
import {
    sizeMobileLg,
    sizeDesktopLg,
    sizeDesktopSm,
    sizeLaptop,
    sizeMobileXsXs,
    sizeMobileSm,
    sizeTablet,
    sizeWideScreenMax,
    sizeDesktopMd,
    sizeWideScreen,
    sizeLaptopLg,
    sizeTabletLg,
  
} from "components/devices";
import  { ViewAllWrap } from 'components/ViewAll';

//**MAIN CONTAINER: PARENT TO ALL SLIDES**//
export const HomeAnimationContainer = styled.div`   
    height: 100vh;
    width: 100%;
    position: relative;
    font-size: 1.8em;
    overflow: hidden !important;

`

export const PainterOneContainer = styled.div`
    width: 100%;
    height: 100vh;
    position: absolute;
    background: white;
    // top: -70px;
    transform: translateX(99.5%);
     ${sizeMobileLg(`
        transform: translateX(99%);

    `)}


`

export const Painter = styled.div`
    background: black;
    border-right: 15px solid #D12FFF;
    height: 100vh;
    width: 15px;
    ${sizeMobileLg(`
        border-right: 5px solid #D12FFF;
        width: 5px;
    `)}

`
export const PainterTwoContainer = styled.div`
    width: 100%;
    height: 100vh;
    position: absolute;
    z-index: 15;
    background: transparent;
    visibility: hidden;
    right: 0;
    top: 0;



`

export const Painter2 = styled.div`
    height: 100vh; 
    border-left: 10px solid #D12FFF;
    background: #FFFFFF;
    ${sizeMobileLg(`
        border-left: 5px solid #D12FFF;
    `)}
`

export const PainterThreeContainer = styled.div`
    width: 100%;
    height: 100vh;
    position: absolute;
    z-index: 15;
    background: transparent;
    visibility: hidden;
    left: -40;
    top: 0;

`

export const Painter3 = styled.div`
    height: 100vh; 
    border-right: 15px solid #D12FFF;
    background: #FFFFFF;
    ${sizeMobileLg(`
        border-right: 5px solid #D12FFF;
   `)}


`

export const PainterFourContainer = styled.div`
    width: 100%;
    height: 100vh;
    position: absolute;
    z-index: 15;
    background: transparent;
    visibility: hidden;
    right: 0;
    top: 0;

` 

export const Painter4 = styled.div`
    height: 100vh; 
    border-left: 10px solid #D12FFF;
    background: #A036F5;
    ${sizeMobileLg(`
        border-left: 5px solid #D12FFF;
   `)}

`



//**SLIDE CONTAINER: PARENT TO INDIVIDUAL SLIDE CONTENT**//
export const SlideOneContainer = styled.div`
    width: 100%;
    height: 100vh;
    position: absolute;
    z-index: -5;
    top: 0;
    left: 0;
    right:0;
    bottom: 0;
    overflow: hidden;
 
    
`

export const SlideTwoContainer = styled.div`
    width: 100%;
    height: 100vh;
    position: absolute;
    z-index: -15;
    top: 0;
    left: 0;
    right:0;
    bottom: 0;
    overflow: hidden;
    visibility: hidden;
  

 
`
export const SlideThreeContainer = styled.div`
    width: 100%;
    height: 100vh;
    position: absolute;
    z-index: -5;
    top: 0;
    left: 0;
    right:0;
    bottom: 0;
    overflow: hidden;
    visibility: hidden;
   

`
export const SlideFourContainer = styled.div`
    width: 100%;
    height: 100vh;
    position: absolute;
    z-index: -10;
    top: 0;
    left: 0;
    right:0;
    bottom: 0;
    overflow: hidden;
    visibility: hidden;
 

`

export const SlideFiveContainer = styled.div`
    width: 100%;
    height: 100vh;
    position: absolute;
    z-index: -15;
    top: 0;
    left: 0;
    right:0;
    bottom: 0;
    overflow: hidden;
    visibility: hidden;
   
`

//**INNER CONTAINERS: PARENT TO BACKGROUND AND COPY**//
export const InnerContainer1 = styled.div`
    width: 100%;
    height: 100vh;
`
export const InnerContainer2 = styled.div`
    width: 100%;
    height: 100vh;

`
export const InnerContainer3 = styled.div`
    width: 100%;
    height: 100vh;
`

export const InnerContainer4 = styled.div`
    width: 100%;
    height: 100vh;
`

export const InnerContainer5 = styled.div`
width: 100%;
height: 100vh;
`

//**BACKGROUND IMAGES**//
export const BgImageOne= styled.div`
   display: block
    width: 100%;
    height: 100vh;
    background-image: url("/images/home-anm-slide1.jpg");
    -webkit-background-size: cover;
    -moz-background-size: cover;
    background-size: cover;

`

//**CONTENT CONTAINERS: PARENT TO ALL COPY**//
export const ContentContainer1 = styled.div`
    width: 100%;
    height: 100vh;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-top: -40px;


`

//**TITLE XL CONTAINERS: PARENT TO LARGE TOP TITLE**//
export const TitleXlContainer1 = styled.div`
        text-align: center;
        width: 100%;
    ${TitleXl}{
        color: #000;   
        font-weight: 900;
        display: inline-block;
        .magenta {
            color: #D12FFF;
        }
        .healthcare {
            margin-left: -100px;
        }
        .weHave {
            margin-right: -350px;
        }
        ${sizeWideScreenMax(`
            font-size: 6em;
        `)}
        ${sizeDesktopMd(`
            font-size: 3.75em;
            .weHave {
                margin-right: -140px;
            }
        `)}
 
        ${sizeTablet(`
            font-size: 3em;
            .weHave {
                margin-right: -100px;
            }
        `)}
        ${sizeMobileLg(`
            font-size: 15vw;
            .healthcare {
                margin-left: -20px;
            }
            .weHave {
                margin-right: -100px;
            }
        `)}
  
    }
`

//**BODY SM CONTAINERS: PARENT TO SM BODY COPY**//
export const BodySmContainer1 = styled.div`
        text-align: left;
        overflow: hidden;
        margin-left: 100px;
        padding: 50px 0 0;
        ${sizeWideScreenMax(`
            font-size: 1.7em;
         `)}
        ${sizeDesktopMd(`
            font-size: 1.2em;
        `)}
        ${sizeTablet(`
            margin-left: 0;
            padding: 10% 3% 0;
        `)}

        ${sizeMobileLg(`
            padding: 15% 3% 0;
        `)}

        ${sizeMobileSm(`
            font-size: 1.1em;
        `)}
        ${sizeMobileXsXs(`
            font-size: .95em;
        `)}
        .word {
            opacity: 0;
            transform: translateY(75px);
        }
        
   
            ${BodySm}{
                color: #000;  
                ${sizeMobileLg(`
                    .displayNone {
                        display: none;
                    }
            `)}
            }
        `
 
export const ContentContainer2 = styled.div`
    width: 50%;
    height: 100vh;
    ${sizeLaptop(`
        width: 100%;   
    
`)}
`

export const TitleXlContainer2 = styled.div`
    width: 100%;
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-items: center;
    padding: 7% 0 0 8%;
    ${sizeLaptop(`
        padding: 20% 5px 0 6%;
    `)}
    ${sizeTablet(`
        flex-direction: column;
        align-items: flex-start;
        height: 100%;
    `)} 
    ${sizeMobileLg(`
        padding: 20% 5px 0 6%;
    `)}
    ${TitleXl}{
        font-weight: 900;
        display: inline-block;
        color: #fff;
        font-size: 7vw;
        .magenta {
            color: #D12FFF;       
        }
        .black {
            color: #000000;
        }
        ${sizeTabletLg(`
            font-size: 10vw;
        `)}
        ${sizeMobileLg(`
            font-size: 56px;
        `)} 
        ${sizeMobileSm(`
            font-size: 48px;
        `)} 

    }  
`

export const BodySmContainer2 = styled.div`
        overflow: hidden;
        text-align: left;
        margin-left: -170px;
        margin-top: 30px;
        font-size: 1em;
        ${sizeWideScreen(`
            font-size: 1.8em;
            margin-left: -350px;
            margin-top: 80px;
         `)}
         ${sizeWideScreenMax(`
            font-size: 2em;
            margin-left: -260px;
            margin-top: 50px;

        `)}
        ${sizeDesktopLg(`
            font-size: 1.4em;
            margin-left: -240px;
            margin-top: 50px;
        `)}

         ${sizeDesktopMd(`
            font-size: 1.3em;
            margin-left: -200px;
            margin-top: 35px;    
         `)}
         ${sizeDesktopSm(`
            font-size: 1.1em;
            margin-left: -170px;
            margin-top: 30px; 
         `)}
         ${sizeLaptopLg(`
             font-size: 1em;
         `)}
         ${sizeTabletLg(`
            font-size: 1em;
            margin-left: -125px;
            margin-top: 15px;
        `)}
        ${sizeTablet(`
            font-size: 1.2em;
            margin-left: 0;
            padding: 15% 0 0 0;
            .displayNone {
                display: none;
            }
        `)}
        ${sizeMobileSm(`    
            font-size: 1em;
        `)}

        .word {
            opacity: 0;
            transform: translateY(75px);  
        } 
    
       
        ${BodySm}{
            color: #fff;
            line-height: 1.2;
        }
`


export const ContentContainer3 = styled.div`
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-top: -80px;
    ${sizeMobileLg(`
        margin-top: -30px;
    `)}

`

export const ContentContainer4 = styled.div`
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items:  center;
    flex-direction: column;
    margin-top: -80px;
    ${sizeMobileLg(`
      margin-top: -30px;
    `)}

` 

export const BgImageTwo = styled.div`
    display: block
    width: 100%;
    height: 100vh;
    background-image: url("/images/home-anm-slide2.jpg");
    -webkit-background-size: cover;
    -moz-background-size: cover;
    background-size: cover;

`
export const BgImageThree = styled.div`
    width: 100%;
    height: 100vh;
    display: block;
    background-color: black;

  
`
export const BgImageFour = styled.div`
    width: 100%;
    height: 100vh;
    display: block;
    background-image: url("/images/home-anm-slide3.jpg");
    -webkit-background-size: cover;
    -moz-background-size: cover;
    background-size: cover; 
  
`

export const BgImageFive = styled.div`
    width: 100%;
    height: 100vh;
    display: block;
    background-image: url("/images/home-anm-slide4.jpg");
    -webkit-background-size: cover;
    -moz-background-size: cover;
    background-size: cover; 
`

export const TitleXlContainer3 = styled.div`
    width: 100%;
    overflow: hidden;
    display: flex;
    justify-content: center;
    text-align: right;
    ${sizeMobileLg(`
        height: 100%;
        align-items: center;
    `)}

${TitleXl}{
    color: #ffffff;
    font-weight: 900;
    text-align: right !important;
    margin: 0 auto;
    .needsA {
      padding-right: 100px;
        ${sizeMobileLg(`
            padding-right: 30px;
     `)}
    }
    ${sizeMobileLg(`
        font-size: 10vw;
        padding: 0 0 100px;      
    `)}
    .magenta {
        color: #D12FFF;
    }
}
`

export const TitleXlContainer4 = styled.div`
    width: 100%;
    position: relative;
    text-align: center;
    ${sizeMobileLg(`
        display: flex; 
        flex-direction: column;
        height: 100%;
        justify-content: center;
        align-items: center;
        
    `)}

    ${TitleXl}{
        color: #fff;   
        font-weight: 900;
        display: inline-block;
        text-align: right;
        .needsA {
            padding-right: 100px;
            ${sizeMobileLg(`
                padding-right: 30px;
         `)}
        }
        ${sizeMobileLg(`
            font-size: 10vw;
            margin-top: -20px;

        `)}
        .magenta {
            color: #D12FFF;
        }
}
`

export const BodySmContainer4 = styled.div`
        position: absolute;
        bottom: -24%;
        left: 15%;
        font-size: 1.3em;
        max-width: 30%;
        overflow: hidden;
        ${sizeLaptopLg(`
            max-width: 75%;
            bottom: -35%;
        `)}
        ${sizeTabletLg(`
            max-width: 100%;
            bottom: -50%;
        `)}
        ${sizeMobileLg(`
            font-size: 1em;
            text-align: right;
            position: relative;
            bottom: 0;
            left: 0;
            padding: 10% 0 0;       
            max-width: 100%;
        `)}
        .word {
            opacity: 0;
            transform: translateY(75px);
            
        }   
    ${BodySm}{
        color: #fff;
        text-align: left;
    }
`


export const ContentContainer5 = styled.div`
    width: 60%;
    height: 100vh;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    position: relative;
    ${sizeLaptop(`
        width:90%;
    `)}
    ${sizeTablet(`
        width: 95%;
    `)}
    ${sizeMobileLg(`
        flex-direction: column;
        align-items: flex-start;
        padding: 15% 0 0;
        width: 100%;
    `)}
    
`



export const TitleXlContainer5 = styled.div`
    flex: 0 0 73%;
    -ms-flex: 0 0 73%;
    padding: 0 0 0 8%;
    display: block;
    margin-top: -50px;
    ${sizeLaptop(`
    flex: 0 0 85%;
    -ms-flex: 0 0 85%; 
    `)}
    ${sizeMobileLg(`
        width: 100%;
        margin-top: 0;
    `)}
    ${TitleXl}{
        color: #000;   
        font-weight: 900;
        ${sizeWideScreenMax(`
            font-size: 10vh;
        `)}
        ${sizeDesktopSm(`
            font-size: 9vh;
        `)}
        ${sizeLaptop(`
            font-size: 8vh;
        `)}
        ${sizeTabletLg(`
            font-size: 6.8vh;
    `   )}
        ${sizeMobileLg(`
            font-size: 6.3vh;
        `)}
        .magenta {
            color: #D12FFF;
        }
        .purple {
            color: #A036F5;
        }
}
}
`
export const BodySmContainer5 = styled.div`
    font-size: 1.2em;
    max-width: 70%;
    overflow: hidden;
    text-align: left;
    position: absolute;
    bottom: 15%;
    right: 0;
    flex: 0 0 27%;
    -ms-flex: 0 0 27%;
    ${sizeWideScreenMax(`
        font-size: 1.7em;
    `)}
    ${sizeDesktopLg(`
        font-size: 1.4em;
    `)}
    ${sizeDesktopMd(`
        font-size: 1.2em;
    `)}
    ${sizeDesktopSm(`
        font-size: 1em;  
    `)}
    ${sizeLaptop(`
        flex: 0 0 15%;
        -ms-flex: 0 0 15%; 
        bottom: 25%;
    `)}
    ${sizeTablet(`
        bottom: 25%;
    `)}

    ${sizeMobileLg(`
        position: relative;
        left: 0;
        max-width: 100%;
        padding: 0 0 0 8%;
        bottom: 25%;
        flex: 0 0 100%;
        -ms-flex: 0 0 100%; 

    `)}

    .word {
        opacity: 0;
        transform: translateY(75px);  
        ${sizeMobileLg(`
            transform: translateY(100px); 
        `)}
    } 
${BodySm}{
    color: #000;
    padding: 0 0 5px;
    .purple {
        color: #A036F5;
        font-weight: 800;
    }
}



${ViewAllWrap}{

    ${sizeMobileLg(`
        width: 250px !important;
        height: 38px !important;
    `)}
}

`

export const ViewAllContainer = styled.div`
transform: translateY(75px);  
${sizeMobileLg(`
    transform: translateY(150px); 
`)}
`
export const PinkBorder = styled.div`
    height: 100vh;
    width: 10px;
    background: #D12FFF;
    visibility: hidden;
    position: absolute;
    float: left;
    ${sizeMobileLg(`
        width: 5px;
    `       
    )}

`