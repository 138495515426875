
import React from 'react'

import ExpandingCards from 'components/ExpandingCards'
import CardSelector from 'components/CardSelector'
import Galaxy from 'components/Galaxy'
import GenericCard from 'components/GenericCard'
import InnerHero from 'components/InnerHero'
import MegaSlider from 'components/MegaSlider'
import NextPage from 'components/NextPage'
import PagedList from 'components/PagedList'
import ParallaxSection from 'components/ParallaxSection'
import PopupCards from 'components/PopupCards'
import RichText from 'components/RichText'
import TabbedGrid from 'components/TabbedGrid'
import TabNavigation from 'components/TabNavigation'

import BlogSlider from '../../pages/blog-details/BlogSlider'
import BlogDetails from '../../pages/blog-details'
import JobDetailPage from '../../pages/job-detail'

import { PreviewContainer } from './elements'
import { createPreviewClient, processResponse } from './cflib'

function ContentPreview( props )
{
	const [ componentData, setComponentData ] = React.useState( null )
	const [ itemType, setItemType ] = React.useState( null )

	React.useEffect(() =>
	{
		let args = props.location.pathname.split('/')

		if ( args?.length > 3 )
		{
			let cfid = null
			let env = args[2]

			setItemType( args[3] )

			if ( args.length > 4 )
				cfid = args[4]

			const client = createPreviewClient( env )
			client.getEntries( { 'include' : 10, 'sys.id' : cfid } ).then( (rsp) =>
			{
				processResponse(rsp).then( (data) =>
				{
					setComponentData( data )
				})
			})
			.catch( (err) => { console.error( `Error: ${err.message}` ) })
		}
			// eslint-disable-next-line
	}, [ props, itemType ])

	const renderComponent = () =>
	{
		if ( componentData === null )
			return null

		switch ( itemType )
		{
			case 'article' :
			{
				return <BlogDetails articleData={componentData} />
			}
			case 'card' :
			{
				return <GenericCard {...componentData} />
			}
			case 'expanding-cards' :
			{
				return <ExpandingCards
					title={componentData.title}
					cards={componentData.items}
				/>
			}
			case 'card-selector' :
			{
				return <CardSelector data={componentData} />
			}
			case 'galaxy' :
			{
				return <Galaxy items={componentData.items} />
			}
			case 'hero-area' :
			{
				return <InnerHero
					sectionLabel={componentData.label}
					sectionTitle={componentData.textArea}
					image={componentData.heroImage}
					linkTitle={componentData.linkText}
					mobileBtnTitle={componentData.linkText}
					wistiaVidID={componentData.videoWistiaId}
					videoSrc={componentData.bgVideo}
					showWistiaAsBg={true}
				/>
			}
			case 'image-slider' :
			{
				return <BlogSlider slides={componentData.images} />
			}
			case 'mega-slider' :
			{
				return <MegaSlider fromHome={true} sliderType="customer" imagePadding={'30px'} sliderData={componentData} />
			}
			case 'job' :
			{
				return <JobDetailPage jobData={componentData} />
			}
			case 'jump-area' :
			{
				return <NextPage pageName={componentData.title} pageLink={componentData.destination} />
			}
			case 'paged-list' :
			{
				return <PagedList 
					fromInsghts={true}
					title={componentData.title}
					icon={componentData.icon}
					rows={componentData.items}
				/>
			}
			case 'parallax' :
			{
				return <ParallaxSection parallaxSectionData={componentData} title={componentData.title} />
			}
			case 'popup-cards' :
			{
				return <PopupCards componentData={componentData} />
			}
			case 'text-area' :
			{
				return <RichText body={componentData.text} />
			}
			case 'tabbed-grid' :
			{
				return <TabbedGrid gridData={componentData} />
			}
			case 'tabbed-links' :
			{
				return <TabNavigation label={componentData.label} title={componentData.title} items={componentData.items} />
			}
			default :
			{
				console.log( 'unknown item type:', itemType )
				return ''
			}
		}
	}

	return ( <PreviewContainer>{ renderComponent() }</PreviewContainer> )
}

export default ContentPreview
