import React from 'react';
import { withRouter } from 'react-router-dom';
import { BodySm, TitleXl, TitleSm, Row, Col6 } from 'components/elements';
import {
  AboutUsBottomContainer,
  TitleXlContainer,
  TitleSmContainer,
  BodySmContainer,
  ColWrapper,
  RowContainer,
  NumbersContainer,
} from './elements';

function AboutUsBottomWhiteSection({ content }) {
  const title = content?.elements?.[0]?.text?.content[0]?.content[0]?.value || '';
  const elements = content?.elements?.slice(1) || []; // Skipping the first element as it is used for title

  return (
    <AboutUsBottomContainer>
      <TitleXlContainer>
        <TitleXl fontWeight="900">{title}</TitleXl>
      </TitleXlContainer>
      <RowContainer>
        <Row>
          {elements.map((element, index) => (
            <Col6 key={index}>
              <ColWrapper>
                <NumbersContainer>
                  <TitleSm>
                    {index + 1} {/* Assuming 'number' equivalent is in `text` */}
                  </TitleSm>
                </NumbersContainer>
                <TitleSmContainer>
                  <TitleSm>
                    {element?.elements?.[0]?.text?.content[0]?.content[0]?.value || ''}
                  </TitleSm>
                </TitleSmContainer>
                <BodySmContainer>
                  <BodySm>
                    {element?.elements?.[1]?.text?.content[0]?.content[0]?.value || ''}
                  </BodySm>
                </BodySmContainer>
              </ColWrapper>
            </Col6>
          ))}
        </Row>
      </RowContainer>
    </AboutUsBottomContainer>
  );
}

export default withRouter(AboutUsBottomWhiteSection);
