import React from 'react'


function PlusIconSvg({ className }) {

    return (
        <svg className={className} id="M_Plus" data-name="M Plus" xmlns="http://www.w3.org/2000/svg" width="38" height="38"
             viewBox="0 0 38 38">
            <path id="Path_596" data-name="Path 596" d="M-23839.43,1932.763v38"
                  transform="translate(23858.43 -1932.763)" fill="none" stroke="#D12FFF" strokeWidth="3"/>
            <path id="Path_595" data-name="Path 595" d="M0,0V38" transform="translate(38 19) rotate(90)" fill="none"
                  stroke="#D12FFF" strokeWidth="3"/>
        </svg>


    )
}

export default PlusIconSvg;
