import { Link } from 'react-router-dom';
import styled from 'styled-components';
import {
    Label,
    BodySm,
    TitleXs
} from "components/elements";
import {
    sizeTabletLg,
    sizeXlDevice,
    sizeLaptopLg,
    sizeMobileXs,
    sizeWideScreen
} from "components/devices";

export const JobPostContainer = styled.div`
    max-width: 510px;
    z-index: 5;

    /* Wide Screen CSS */
    ${sizeWideScreen(`
        max-width: 75%;
    `)}
    /* For 4k */
    ${sizeXlDevice(`
        max-width: 75%;
    `)}

    ${sizeLaptopLg(`
        max-width: 430px;
    `)}
`;
export const JobBtnRow = styled.div`
    height: 50px;
    margin-top: 30px;
`;
export const JobBtn = styled(Link)`
    color: #000000;
    text-transform: uppercase;
    display: inline-block;
    transition: .2s all ease;
    padding: 12px 40px;
    font-size: 16px;
    font-weight: 700;
    border: 2px solid #000;
    transform: scale(.65);
    margin-left: -33px;
    
    /* For 4k */
    ${sizeXlDevice(`
        transform: scale(.8);
        margin-left: -20px;
    `)}

    &:hover {
        color: #D12FFF;
        border-color: #D12FFF;
        border-radius: 25px;
        transform: scale(1);
        margin-left: 0;
    }
    &:focus,
    &.clicked {
        color: #FFF;
        border-color: #000;
        border-radius: 25px;
        transform: scale(1);
        margin-left: 0;
        background-color: #000;
    }
`;
export const JobArrow = styled.div`
    position: absolute;
    right: 80px;
    top: 50%;
    transform: translateY(-50%);
    width: 64px;
    height: 44px;
    transition: .4s all ease;
    z-index: 2;
    /* background-image: url(${({ backgroundImage }) => backgroundImage || ""});
    background-repeat: no-repeat;
    background-size: 64px 44px;
    background-position: center center; */
    svg {
        width: 64px;
        height: 44px;
    }

    ${sizeLaptopLg(`
        right: 40px;
    `)}
    ${sizeTabletLg(`
        bottom: 30px;
        top: auto;
        transform: none;
        left: 30px;
        right:  auto;
    `)}
`;
export const JobCardLink = styled(Link)`
    padding: 25px 45px;
    display: block;
    width: 100%;
    box-sizing: border-box;
    ${sizeLaptopLg(`
        padding: 25px;
    `)}
    ${sizeTabletLg(`
        padding-bottom: 100px;
    `)}
`;
export const JobPostCardWrap = styled.div`
    position: relative;
    border-top: none;
    transition: .4s all ease;
    background-color: #F0F0F0;
    z-index: 1;
    border-top: ${({ borderTop }) => borderTop || "none"};
    border-right: ${({ borderRight }) => borderRight || "1px solid #fff"};
    border-bottom: ${({ borderBottom }) => borderBottom || "1px solid #fff"};
    border-left: ${({ borderLeft }) => borderLeft || "1px solid #fff"};
    &:first-child {
        border-top: 1px solid #fff;
    }
    ${Label} {
        text-transform: uppercase;
        color: #000;
        transition: .4s all ease;
        margin-bottom: 10px;
        display: block;
        font-size: 20px;
        
        /* For 4k */
        ${sizeXlDevice(`
            font-size: 16px;
        `)}

        ${sizeTabletLg(`
            font-size: 16px;
        `)}

        ${sizeWideScreen(`
            font-size: 0.7vw;
        `)}
    }
    ${BodySm} {
        margin-bottom: 10px;
        transition: .4s all ease;
        /* For 4k */
        ${sizeXlDevice(`
            font-size: 1.1vw;
        `)}

        ${sizeWideScreen(`
            font-size: 0.7vw;
        `)}
    }
    ${TitleXs} {
        margin-bottom: 15px;
        transition: .4s all ease;
        /* For 4k */
        ${sizeXlDevice(`
            font-size: 1.6vw;
        `)}

        ${sizeTabletLg(`
            font-size: 32px;
        `)}
        ${sizeMobileXs(`
            font-size: 24px;
        `)}


        /* Wide Screen CSS */
        ${sizeWideScreen(`
            font-size: 1vw;
        `)}
    }
    &:before {
        content: "";
        width: 0;
        height: 100%;
        z-index: -1;
        background-color: #D12FFF;
        position: absolute;
        left: 0;
        bottom: 0;
        transition: .4s all ease;
        /* transition: width .4s all ease; */
    }
    &:hover {
        line, 
        path {
            stroke: #fff;
        }
        &:before {
            width: 100%;
        }
        ${TitleXs}{
            color: #fff;
        }
        ${Label}{
            color: #fff;
        }
        ${BodySm}{
            color: #fff;
        }
        ${JobArrow} {
            right: 50px;
        }
    }
`;
