import styled from "styled-components";

import {


    sizeLaptopLg,
   
    sizeTablet,
    sizeTabletLg

} from "components/devices";
import { Col6, Col5, Row, TitleMd, TitleSm, TitleXs, BodySm } from "components/elements";


export const THMHeader = styled.div`
    width: 100%;
    height: 100%;
    padding: 40px 0 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-top: 1px solid #E0E0E0;
    ${sizeTablet(`
        margin-top: 20px;

    `)}
    a {
        height: 38px;
        padding: 0 15px;
        border: 2.5px solid #252525;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        ${sizeTablet(`
            width: 100%; 
            padding: 0 15px
           
        `)}
        &.shopProducts {
            ${sizeTablet(`
                width: 239px;
           
        `)}
        }
        &:nth-of-type(2){
            border-left: none;
            border-right:none;
            ${sizeTablet(`
                border-left: 2.5px solid #252525;
                border-right: 2.5px solid #252525;
                margin: 10px 0;
            
         `)}
        }
        ${TitleSm}{
            font-weight: 700;
            font-size: 14px;
            line-height: 1.1;
            color: #252525;
            font-family: Helvetica;
            letter-spacing: 0.13rem;
            text-transform: uppercase;
        }
        &:hover{
            background-color: #000000;
            ${TitleSm}{
                color: #D12FFF;
            }
        }
    }
`

export const AnchorLinksContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    ${sizeTablet(`
        flex-direction: column;
        width: 100%;
        padding: 0 3% 0;
    `)}

  
`

export const HeroIntroContainer = styled.div`
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 30px 0 0;
    padding: 80px 0;
    background-image: url("/images/purple-mask.png");
    mix-blend-mode: hard-light;
    background-size: cover;
    ${sizeLaptopLg(`
        background-position: center center;
    `)}
    ${sizeTablet(`
        background-image: url("/images/purple-mask-mbl.svg");
        background-position: unset;
        padding: 0 0 60px;
        margin: 40px 0 0;
    `)}

    h1 {
        max-width: 55%;
        text-align: center;
        font-size: 48px;
        line-height: 1.15;
        &:first-of-type {
            padding: 30px 0 0;
        }
     
        ${sizeLaptopLg(`
            max-width: 75%;
            &:first-of-type {
                padding: 60px 20px 0;
            }      
        `)}
        ${sizeTablet(`
            font-size: 32px;
            max-width: 100%;
            padding: 0 3% 0;
            &:first-of-type {
                padding:  0 3% 0;
                margin: 80px 0 0;
            }   
        `)}

        b{
            font-family: Helvetica Now Display;
            font-weight: 900 !important;
        }
    
    }
    p {
        font-size: 24px;
        max-width: 40%;
        text-align: center;
        padding: 30px 0;
        ${sizeTablet(`
            font-size: 20px;
            line-height: 24px;
            max-width: 100%;
            padding: 30px 3%;
    `)}
    }
 
`

export const WhoWeAreContainer = styled.div`
    width: 100%;
    padding: 6% 9% 3%;
    scroll-margin-top: 40px;
    .twoColumns {
        display: flex;
        align-items: flex-start;
        justify-content: flex-end;
        margin-right: 5%;
        ${sizeTablet(`
            margin-right: unset;
            justify-content: flex-start;
        `)}
        div {
            flex: 0 0 38%;
            padding: 40px 0 0;
            ${sizeLaptopLg(`
                flex: 0 0 45%;    
            `)}
            ${sizeTablet(`
                flex: 0 0 100%;
                padding: 20px 0 0;

            `)}
            p {
                font-size: 16px;
                line-height: 1.2;
                padding: 20px 0 0;
                ${sizeTablet(`
                    padding: 15px 0 0;

            `)}
            }
        }
    }
    ${TitleSm}{
        font-size: 16px;
        padding: 20px 0 15px;
    }
    ${TitleMd}{
        font-size: 32px;
        line-height: 1.2;
        font-weight: 900;
        font-family: "HelveticaNowDisplay Black";
        max-width: 57%;
        ${sizeTablet(`
           max-width: 100%;
           font-size: 24px;
        `)}
        &.leaderTitle {
            max-width: 60%;
            padding: 150px 0 0;
            ${sizeLaptopLg(`
               max-width: 75%;
            `)}
            ${sizeTablet(`
                max-width: 100%;
                padding: 80px 0 0;
            `)}
        }
    }
    ${sizeTabletLg(`
        padding: 15% 4.5% 3%;
    `)}
`

export const Divider = styled.div`
    background: #000000;
    width: 327px;
    height: 1px;
    &.white {
        background: #ffffff;
        ${sizeTablet(`
            width: 50%;
        `)} 
    }

    ${sizeTablet(`
        width: 94%;
    `)}
    &.mblShort {
        ${sizeTablet(`
        width: 50%;
    `)} 
    }
`

export const LeadershipContainer = styled.div`
     padding: 0 4%;
        ${sizeTablet (`
            padding: 0 0;
        `)}
`

export const HowWeHelpContainer = styled.div`
    width: 100%;
    background: #F0F0F0;
    padding: 6% 9% 3%;
    scroll-margin-top: 40px;
    ${sizeTablet (`
        padding: 13% 4.5% 3%;   
    `)}
    ${TitleSm}{
        font-size: 16px;
        padding: 20px 0 15px;
        text-transform: uppercase;
    }
    ${Row}{
        justify-content: space-between;
        align-items: center;
        ${sizeTablet (`
            flex-direction: column;
            align-items: flex-start;
        `)}
        ${Col6}{
            ${sizeTablet (`
                flex: 0 0 100%;
                max-width: 100%;
            `)}
            h1 {
               font-size: 32px;
               padding: 15px 0 0;
               ${sizeTablet (`
                font-size: 24px;
               `)}
            }
            p {
                font-size: 16px;
                padding: 20px 0 0;
                max-width: 75%;
                ${sizeTablet (`
                    max-width: 100%;
                `)}
            }
            ul {
                list-style-type: square;
                font-size: 12px;
                margin: 0 0 10px 25px;
                &::marker {
                    padding: 0 0 10px 0;
                }
            }
            ul li p {
                padding: 0 0 0;
            }
            ul li {
                margin: 0 0 0;
                vertical-align: text-top;
            }
        }
        ${Col5}{
            text-align: center;
            ${sizeTablet (`
                flex: 0 0 100%;
                max-width: 100%;
            `)}
            img {
                ${sizeTablet (`
                    width: 100%;
                `)}

            }
        }
    }
`

export const OurProductsContainer = styled.div`
    width: 100%;
    background: #000000;
    padding: 6% 9%;
    scroll-margin-top: 40px;
    ${sizeTablet (`
        padding: 13% 4.5%;
    `)}
    ${TitleSm}{
        font-size: 16px;
        padding: 20px 0 15px;
        color: #ffffff;
        &.title {
        text-transform: uppercase;
        }
    }
    ${TitleMd}{
        font-size: 32px;
        line-height: 1.2;
        font-weight: 900;
        font-family: "HelveticaNowDisplay Black";
        color: #ffffff;
        max-width: 57%;
        ${sizeLaptopLg(`
            max-width: 75%;
        `)}
        ${sizeTablet (`
            max-width: 100%;
            font-size: 24px;
        `)}
    }

    ${Row}{
        padding: 40px 0 0;
        ${Col6}{
            max-width: 49%;
            ${sizeTablet (`
                max-width: 100%;
                flex: 0 0 100%;
            `)}
        }
    }
    ${BodySm}{
        color: #fff;
        font-size: 12px;
        padding: 25px 0 0;
    }
`

export const ContactUsContainer = styled.div`
    width: 100%;
    background-color: #F0F0F0;
    padding: 6% 9% 2%;

    
    ${Col6}{
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            ${TitleXs}{
                font-size: 15px;
                line-height: 1.2;
                font-weight: 400;
                font-family: Helvetica;
                color: #000000;
            }
            ${TitleMd}{
                font-size: 48px;
                line-height: 1.2;
                font-weight: 900;
                font-family: "HelveticaNowDisplay Black";
                padding: 10px 0 20px;
            }
            ${BodySm}{
                font-size: 20px;
                font-weight: 400;
                line-height: 1.4;
                color: #000000;
                a {
                    color: #000000;
                    text-decoration: underline;
                }
                &:first-of-type {
                    padding: 0 0 20px;
                }
        }
        
    }
    ${Col5}{
        flex: 0 0 45%;
        max-width: 45%;
    }

    ${sizeTablet (`
        padding: 13% 4.5% 2%;
        ${Row}{
            flex-direction: column;
            align-items: flex-start;

        }
        ${Col6}{
            flex: 0 0 100%;
            max-width: 100%;
            ${TitleMd}{
                font-size: 32px;
            }
            ${BodySm}{
                font-size: 15px;
                
            }
        }
        ${Col5}{
            flex: 0 0 100%;
            max-width: 100%;
            padding: 30px 0 0;
        }
   `)}
    
`