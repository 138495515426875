import React from 'react'
import { SocialIcons } from 'pages/footer/elements';
import { SocialItem } from 'pages/footer/elements';
import { SocialIcon } from 'pages/footer/elements';
import FooterAnimation from 'components/FooterAnimation';
import MediaCard from 'components/MediaCard';
import BlogIcon from 'assets/icons/BlogIcon';
import {  TitleSm } from '../../components/elements';
import ViewAll from 'components/ViewAll';
import {
    BodyRg,
    Col,
    Row,
    TitleMd,
    TitleXl,

    Col6
} from "../../components/elements";
import {
    BlogsSection,
    EventDetails,
    EventsContainer,
    EventsPageWrap,
    EventsReg,
    VideoCopyContainer,
    PromotionalSection,
    HealthcareSection, 
    ViewAllContainer,
    ViewAllContainerTop
} from "./elements";
import {BlogContainer} from "../blog-details/elements";
import BlogHero from "../blog-details/BlogHero";

import EventScheduleCard from "../../components/EventScheduleCard";

import WistiaPlayer from "../../components/WistiaPlayer/WistiaPlayer";
import {BlogWistiaVideo} from "../blog-details/BlogDetail/elements";
import { LogoContainer, CircleSection, LeftCircleContainer, } from 'components/HowWeWorkSection/elements';
import OverlapCircles from 'components/OverlapCircles';
import { LollipopStickLeft, LollipopStickMiddle, LollipopStickRight } from 'components/HowWeWorkSection/elements';
import { MiddleCircleContainer, SmallCirclesContainer, SmLeftCircleContainer, RightCircleContainer, MiddleBorder, AiPlusIdeas, AiPlusIdeasSectionContainer} from 'components/HowWeWorkSection/elements';




const articleData = [
    {
        cardType: "BlogPost",
        link: "/article/an-advocate-for-you-and-you-and-you",
        icon: <BlogIcon/>,
        btnText: "Blog Post",
        ctaText: 'read more',
        date:"January 5, 2023",
        img: "/images/Madeline_Sister.jpeg",
        bodyText:"An Advocate For You, And You, And You…"
    },
    {
        cardType: "Podcast",
        link: "/article/democratizing-access-to-healthcare",
        icon: <BlogIcon/>,
        btnText: "Blog Post",
        ctaText: 'listen',
        date:"December 15, 2022",
        img: "/images/podcast-thumbnail.png",
        bodyText:"Democratizing Access to Healthcare: Pranam Ben, Founder & CEO, The Garage"
    },
    // {
    //     cardType: "BlogPost",
    //     link: "/article/4-esg-healthcare-trends-to-know-for-2023-from-JPM",
    //     icon: <BlogIcon/>,
    //     btnText: "Blog Post",
    //     ctaText: 'read more',
    //     date:"February 10, 2023",
    //     img: "/images/dna-grass.jpeg",
    //     bodyText:"4 ESG Healthcare Trends to Know for 2023 from JPM"
    // },
    // {
    //     cardType: "BlogPost",
    //     link: "/article/6-principles-of-omnichannel-marketing-for-pharma",
    //     icon: <BlogIcon/>,
    //     btnText: "Blog Post",
    //     ctaText: 'read more',
    //     date:"January 18, 2023",
    //     img: "/images/6-principles.jpeg",
    //     bodyText:"6 Principles of Omnichannel Marketing for Pharma"
    // },
    // {
    //     cardType: "BlogPost",
    //     link: "/article/how-gaming-changed-my-postpartum-experience",
    //     icon: <BlogIcon/>,
    //     btnText: "Blog Post",
    //     ctaText: 'read more',
    //     date:"November 17, 2022",
    //     img: "/images/gaming-experience.jpeg",
    //     bodyText:"How Gaming Changed My Postpartum Experience"
    // },
];

function EventsPage() {


    return (
        <EventsPageWrap>
          

            <BlogContainer>
              
                    {/* <EventsColLeft>
                        <EventsSidebar/>
                    </EventsColLeft> */}
          <BlogHero
                    image={'/images/NBC2023_SXSW.jpg'}
                    mobileImage={'/images/NBC2023_SXSW.jpg'}
                />
                        <EventsContainer>
                            <EventsReg id={'overview'}>
                                <TitleXl margin={'0 0 35px 0'} fontWeight="900">
                                    SXSW….and beyond! Let’s stay connected
                                </TitleXl>
                                <BodyRg margin={'0 0 35px 0'}>
                                    Thank you for dropping by our Real Chemistry events at SXSW 2023! We are excited to be back in Austin and supporting our clients in transforming healthcare from what it is, into what it should be. We had a great time, and we hope you did too! 
                                </BodyRg>
                                <BodyRg margin={'0 0 35px 0'}>
                                    At Real Chemistry, we believe more connection is exactly what our industry needs – which is why we’d love to stay in touch. Check out the various options available to stay up to date on all things Real Chemistry.
                                </BodyRg>
                            <SocialIcons>
                                <SocialItem>
                                    <SocialIcon href="https://www.linkedin.com/company/therealchemistry" target="_blank" rel="noopener noreferrer">
                                        <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M11.7862 11.2113H16.4287V13.5238C17.0975 12.1938 18.8125 10.9988 21.3888 10.9988C26.3275 10.9988 27.5 13.6463 27.5 18.5038V27.5H22.5V19.61C22.5 16.8437 21.8313 15.2838 20.1288 15.2838C17.7675 15.2838 16.7862 16.965 16.7862 19.6088V27.5H11.7862V11.2113V11.2113ZM3.2125 27.2875H8.2125V10.9988H3.2125V27.2875V27.2875ZM8.92875 5.6875C8.92894 6.10659 8.84582 6.52154 8.68423 6.90823C8.52265 7.29492 8.28581 7.64564 7.9875 7.94C7.383 8.54078 6.56476 8.87707 5.7125 8.875C4.86174 8.87443 4.04539 8.539 3.44 7.94125C3.14276 7.64589 2.90673 7.29478 2.74543 6.90804C2.58413 6.5213 2.50072 6.10653 2.5 5.6875C2.5 4.84125 2.8375 4.03125 3.44125 3.43375C4.04611 2.8352 4.8628 2.49963 5.71375 2.5C6.56625 2.5 7.38375 2.83625 7.9875 3.43375C8.59 4.03125 8.92875 4.84125 8.92875 5.6875Z" fill="black"/></svg>
                                    </SocialIcon>
                                    <SocialIcon href="https://x.com/RealChemistry_" target="_blank" rel="noopener noreferrer">
                                        <svg width="35" height="35" viewBox="0 0 35 35" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M31.7183 8.69191C30.6724 9.14308 29.5342 9.46779 28.3618 9.59425C29.579 8.87113 30.4905 7.72872 30.9253 6.38136C29.7831 7.06082 28.5319 7.53726 27.227 7.78956C26.6817 7.20655 26.0221 6.74209 25.2894 6.42514C24.5567 6.10818 23.7666 5.94552 22.9683 5.94728C19.7383 5.94728 17.1406 8.56544 17.1406 11.7783C17.1406 12.2295 17.1953 12.6807 17.2842 13.1148C12.4478 12.8618 8.13428 10.5513 5.2666 7.01369C4.74408 7.90617 4.47026 8.92237 4.47363 9.95656C4.47363 11.98 5.50244 13.7642 7.07129 14.8135C6.14674 14.7771 5.24385 14.523 4.43604 14.0718V14.1436C4.43604 16.9771 6.43897 19.3252 9.1084 19.8652C8.60718 19.9954 8.09158 20.062 7.57373 20.0635C7.19434 20.0635 6.83545 20.0259 6.47314 19.9746C7.21143 22.2852 9.36133 23.9634 11.9214 24.0181C9.91846 25.5869 7.40967 26.5098 4.68555 26.5098C4.19678 26.5098 3.74561 26.4927 3.27734 26.438C5.86133 28.0957 8.92725 29.0527 12.229 29.0527C22.9478 29.0527 28.813 20.1729 28.813 12.4653C28.813 12.2124 28.813 11.9595 28.7959 11.7066C29.9307 10.876 30.9253 9.84718 31.7183 8.69191Z" fill="black"/></svg>
                                    </SocialIcon>
                                    <SocialIcon href="https://www.instagram.com/real.chemistry/" target="_blank" rel="noopener noreferrer">
                                        <svg width="35" height="35" viewBox="0 0 35 35" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M17.501 12.9438C14.9922 12.9438 12.9449 14.9911 12.9449 17.4999C12.9449 20.0087 14.9922 22.0561 17.501 22.0561C20.0098 22.0561 22.0572 20.0087 22.0572 17.4999C22.0572 14.9911 20.0098 12.9438 17.501 12.9438ZM31.1661 17.4999C31.1661 15.6132 31.1831 13.7436 31.0772 11.8603C30.9712 9.67279 30.4722 7.73138 28.8726 6.13177C27.2696 4.52874 25.3316 4.03314 23.1441 3.92718C21.2574 3.82122 19.3877 3.83831 17.5044 3.83831C15.6177 3.83831 13.7481 3.82122 11.8648 3.92718C9.67729 4.03314 7.73588 4.53216 6.13627 6.13177C4.53324 7.7348 4.03764 9.67279 3.93168 11.8603C3.82572 13.747 3.84281 15.6166 3.84281 17.4999C3.84281 19.3832 3.82572 21.2563 3.93168 23.1396C4.03764 25.3271 4.53666 27.2685 6.13627 28.8681C7.7393 30.4711 9.67729 30.9667 11.8648 31.0727C13.7515 31.1786 15.6211 31.1616 17.5044 31.1616C19.3912 31.1616 21.2608 31.1786 23.1441 31.0727C25.3316 30.9667 27.273 30.4677 28.8726 28.8681C30.4756 27.2651 30.9712 25.3271 31.0772 23.1396C31.1866 21.2563 31.1661 19.3867 31.1661 17.4999ZM17.501 24.5102C13.6216 24.5102 10.4908 21.3793 10.4908 17.4999C10.4908 13.6205 13.6216 10.4897 17.501 10.4897C21.3804 10.4897 24.5113 13.6205 24.5113 17.4999C24.5113 21.3793 21.3804 24.5102 17.501 24.5102ZM24.7984 11.8398C23.8926 11.8398 23.1612 11.1083 23.1612 10.2026C23.1612 9.29681 23.8926 8.56536 24.7984 8.56536C25.7041 8.56536 26.4356 9.29681 26.4356 10.2026C26.4359 10.4176 26.3937 10.6307 26.3115 10.8294C26.2293 11.0282 26.1087 11.2088 25.9567 11.3609C25.8046 11.5129 25.624 11.6335 25.4252 11.7157C25.2265 11.7979 25.0135 11.84 24.7984 11.8398Z" fill="black"/></svg>
                                    </SocialIcon>   
                                </SocialItem>
                            </SocialIcons>
                            <BodyRg margin={'35px 0 35px 0'}>
                                We deliver news you can use: data-driven insights, thought leadership and real-world case studies of how AI and ideas are helping build incredible human-centric healthcare experiences. Our monthly newsletter has all that (and more); subscribe <a href="https://www.realchemistry.com/healthcare-connection" target="_blank" rel="noopener noreferrer">here</a>. 
                            </BodyRg>
                            <BodyRg margin={'0 0 0px 0'}>
                                Interested in more than just our content? We are always looking to connect with great people who are passionate about their work, and ours! If looking to find the next chapter of your career, submit your information <a href="https://w2o.wd1.myworkdayjobs.com/en-US/RealChemistry/introduceYourself" target="_blank" rel="noopener noreferrer">here</a> with our Talent Acquisition team. 
                            </BodyRg>
                        </EventsReg>
                      

                            <EventDetails id={'event-details'}>
                                <TitleMd
                                    textTransform={'uppercase'}
                                    margin={'0 0 15px 0'}>
                                    Event Details
                                </TitleMd>
                                <TitleXl padding="0 0 35px 0" fontWeight="900">
                                    Join us this weekend and come see what <br className="hide-mobile"/> Real Chemistry can do!
                                </TitleXl>

                                <TitleSm color='#6900FF' padding='15px 0 ' >
                                    Real Chemistry Healthcare Innovation Day
                                </TitleSm>
        
                                <BodyRg margin={'0 0 35px 0'}>
                                Join Real Chemistry, the leading provider of AI-driven insights and human-centric experiences for the healthcare industry, at the luxurious LINE Hotel for a day of insightful panel programming with opportunities build community and meaningful connections with healthcare leaders and innovators. Register for our sessions <a href="https://web.cvent.com/event/4522e735-178d-4670-bab8-e185ce5ae470/summary?RefId=FebHC" target="_blank" rel="noopener noreferrer">here</a>.
                                </BodyRg>
                                <Row margin={'0 -12px 70px -12px'}>
                                    <Col padding={'0 12px'}>
                                        <EventScheduleCard
                                            label={'Date'}
                                            detail={'Saturday, March 11, 10–5pm'}
                                        />
                                    </Col>
                                    <Col padding={'0 12px'}>
                                        <EventScheduleCard
                                            label={'Location'}
                                            detail={'The LINE'}
                                        />
                                    </Col>
                                </Row>

                                <TitleSm color='#6900FF' padding='0 0 15px'>
                                    Real Chemistry Podcast Lounge
                                </TitleSm>
        
                                <BodyRg margin={'0 0 35px 0'}>
                                    Join Real Chemistry, the leading provider of AI-driven insights and human-centric experiences for the healthcare industry, to hear live podcast interviews with innovative healthcare thought leaders who are changing the industry. From generative AI to utilizing innovative data for clinical trial recruitment to the gamification within healthcare, learn about the new advances that are revolutionizes the way we serve our patient communities.
                                </BodyRg>
                                <Row margin={'0 -12px 35px -12px'}>
                                    <Col padding={'0 12px'}>
                                        <EventScheduleCard
                                            label={'Date'}
                                            detail={'Sunday, March 12, 10-3:30pm'}
                                        />
                                    </Col>
                                    <Col padding={'0 12px'}>
                                        <EventScheduleCard
                                            label={'Location'}
                                            detail={'ACC, Podcast Lounge'}
                                        />
                                    </Col>
                                </Row>
                                {/* <EventDetailCard/> */}
                            </EventDetails>
                            {/*  Event details end 
{/* 
                            <FeaturedSpeakersSection id={'featured-speakers'}>
                                <TitleMd
                                    margin={'0 0 24px 0'}
                                    textTransform={'uppercase'}
                                >
                                    Featured Speakers
                                </TitleMd>
                                <Row justifyContent={'space-between'} colGap={'12px'} rowGap={'35px'}>
                                    {
                                        speakersData ? speakersData.map((item, index) => {
                                            return (
                                                <Col key={index} className={'speaker-col'}>
                                                    <SpeakerCard
                                                        image={item.image}
                                                        name={item.name}
                                                        desg={item.desg}
                                                        company={item.company}
                                                        link={item.link}
                                                    />
                                                </Col>
                                            )
                                        }) : ""
                                    }
                                </Row>
                            </FeaturedSpeakersSection> */}
                            {/* Featured Speakers Section End */}

                            {/* <BlogsSection id={'blogs'}>
                                <TitleMd
                                    margin={'0 0 24px 0'}
                                    textTransform={'uppercase'}
                                >
                                    READ MORe on Our Blog
                                </TitleMd>
                                <NewSideImageArticle
                                    image={'images/R02-Card Image.png'}
                                    type={'BlogPost'}
                                    title={'Lorem Ipsum Dolor Sit Amet, Consectetur Adipiscing Elit, Sed Do'}
                                    date={'Month Day, Year'}
                                    link={'#'}
                                />
                            </BlogsSection> */}
                            {/*  Blogs Section End  */}

                            <PromotionalSection id={'promotional'}>
                                <TitleMd
                                    textTransform={'uppercase'}
                                    margin={'0 0 15px 0'}>
                                    Culture Video
                                </TitleMd>
                                <TitleXl padding="0 0 35px 0" fontWeight="900">
                                 Who we are
                                </TitleXl>
                                <VideoCopyContainer>
                                <BlogWistiaVideo>
                                    <WistiaPlayer
                                        controlsVisibleOnLoad={false}
                                        customClass="wistia_custom"
                                        hashedId={'s3hqjyms4x'}
                                        isChannel={false}
                                    />
                                </BlogWistiaVideo>
                                <TitleXl fontWeight="900">
                                    Simply put – our people and purpose make <span style={{color: '#6900FF'}}>#LifeatRealChem</span> great.
                                </TitleXl>
                            </VideoCopyContainer>
                            </PromotionalSection>
                              {/*  Promotional Section end  */}

                            <HealthcareSection>
                                <TitleXl padding="0 0 35px 0" fontWeight="900">
                                Healthcare needs more connection.<br/> We call it Real Chemistry.
                                </TitleXl>
                                <BodyRg margin={'0 0 35px 0'}>
                                It’s not an agency or holding company. It’s an end-to-end healthcare experience group designed to help brands and people reach their potential. With AI at our core and health’s widest range of expertise, we’re able to collaborate without barriers – focused on overcoming the gaps and transforming healthcare from what it is to what it should be.
                                </BodyRg>
                                <LogoContainer>
                                    <TitleXl fontWeight="900" textTransform={"uppercase"}>HOW WE MAKE IT WORK</TitleXl>
                                    <img src={'/images/RC-logo.svg'} alt={'Real Chemistry logo'} className="realChemLogo"/>
                                </LogoContainer>
                                <CircleSection>
                                    <LeftCircleContainer>
                                        <OverlapCircles borderColor="#D12FFF" titleSize="30px" titleLineH="36px" className="leftCircle" dimensionsDesktop="386px" header="false" copyHeader="COMMERCIAL & MEDICAL SOLUTIONS" textTransform="uppercase" bodyCopy=" Advertising, Medical Education, Production + Solutions"/>
                                        <LollipopStickLeft borderColor="#D12FFF" />
                                    </LeftCircleContainer>
                                    <MiddleCircleContainer>
                                        <OverlapCircles borderColor="#6900FF" titleSize="30px" titleLineH="36px" className="leftCircle" dimensionsDesktop="386px" header="false" copyHeader="DATA & AI SOLUTIONS" textTransform="uppercase" bodyCopy="AI-Powered Insights + Conversational AI, Market Access"/>
                                        <LollipopStickMiddle backgroundColor="#6900FF" />
                                        <SmallCirclesContainer>
                                            <SmLeftCircleContainer>
                                                <OverlapCircles borderColor="#6900FF" titleSize="20px" titleLineH="24px" className="leftCircle" dimensionsDesktop="182px" dimensionsMobile="182px" header="false" copyHeader="IPM.ai + swoop" mobileBorder={"6px solid #6900FF"}/>
                                            </SmLeftCircleContainer>
                                            <MiddleBorder/>
                                        </SmallCirclesContainer>
                                    </MiddleCircleContainer>
                                    <RightCircleContainer>
                                        <OverlapCircles borderColor="#0F20FF" titleSize="30px" titleLineH="36px" className="leftCircle" dimensionsDesktop="386px" header="false" textTransform="uppercase" copyHeader="INTEGRATED MARKETING COMMUNICATIONS"  bodyCopy="Marketing Comms, PR, Content Studio, Health Equity + Pop Culture Strategy"/>
                                        <LollipopStickRight borderColor="#0F20FF" />
                                    </RightCircleContainer>
                            </CircleSection>
                            <AiPlusIdeasSectionContainer>
                                <AiPlusIdeas>
                                    <TitleXl>AI</TitleXl>
                                    <img src={'/images/plus-sign-new.svg'} alt={'Plus sign'} className="plusSignImage"/>
                                    <TitleXl>IDEAS</TitleXl>
                                </AiPlusIdeas>
                        </AiPlusIdeasSectionContainer>
                            <ViewAllContainerTop>
                                <ViewAll title={'LEARN MORE'} link="/what-we-do" borderColor="#000" titleColor="#000" hoverBackground="#000" width="260px" height="44px" svgColor="#000" />
                             </ViewAllContainerTop>

                     </HealthcareSection>
                         <BlogsSection id={'blogs'}>
                            
                                <TitleMd
                                    margin={'0 0 15px 0'}
                                    textTransform={'uppercase'}
                                >
                                    READ MORe on Our Blog
                                </TitleMd>
                                <TitleXl padding="0 0 35px 0" fontWeight="900">
                                    Curious for more?
                                </TitleXl>
                                <BodyRg margin={'0 0 35px 0'}>
                                    At Real Chemistry we are focused on the stories that shape our experience. Explore these perspectives, and more, below.

                                </BodyRg>
                                <Row justifyContent="space-between">
                                    {articleData.map((article, index) => (
                                        <Col6  key={index} padding="0 10px">
                                        <MediaCard
                                            flexDirection="column"
                                            padding="0 12px 0"
                                            mediaCardHeadPad={"0 0 20px"}                                      
                                            mediaBodyPad={"25px 0"}
                                            tabletPad={"0 0"}
                                            isLoading={false}
                                            cardType={article.cardType}
                                            link={article.link}
                                            icon={article.icon}
                                            btnText={article.btnText}
                                            ctaText={article.ctaText}
                                            date={article.date}
                                            img={article.img}
                                            bodyText={article.bodyText}
                                            borderLeft={'none'}
                                            borderTop={'none'}
                                            height={'640px'}
                                        />

                                        </Col6>
                                    ))}
                              
                             </Row>
            
                             
                             <ViewAllContainer>
                                <ViewAll title={'VIEW ALL MEDIA'} link="/news-events" borderColor="#000" titleColor="#000" hoverBackground="#000" width="260px" height="44px" svgColor="#000" />
                             </ViewAllContainer>

                          
                            </BlogsSection>
                            {/*  Blogs Section End  */}


                        {/* Agenda Section begin */}
                            {/* <AgendaSection id={'agenda'}>
                                <TitleMd
                                    margin={'0 0 24px 0'}
                                    textTransform={'uppercase'}
                                >
                                    Agenda
                                </TitleMd>
                                <div className={'agenda-accordions'}>
                                    <SmallAccordion className={accordion ? "show" : ""}>
                                        <SmallAccordionHeader onClick={handleAccordion}>
                                            <TitleXs>Introduction</TitleXs>
                                            <AccHeadRight>
                                                <TitleXs>10:00 AM - 11:00 AM</TitleXs>
                                                <svg width="36" height="37" viewBox="0 0 36 37" fill="none"
                                                     xmlns="http://www.w3.org/2000/svg">
                                                    <rect x="16" y="0.438477" width="4" height="36" fill="black"/>
                                                    <rect x="36" y="16.4385" width="4" height="36"
                                                          transform="rotate(90 36 16.4385)" fill="black"/>
                                                </svg>
                                            </AccHeadRight>
                                        </SmallAccordionHeader>
                                        <SmallAccordionBody>
                                            <Row justifyContent={'space-between'} colGap={'12px'} rowGap={'35px'}>
                                                {
                                                    speakersData ? speakersData.map((item, index) => {
                                                        return (
                                                            <Col key={index} className={'speaker-col-sm'}>
                                                                <SpeakerCard
                                                                    image={item.image}
                                                                    name={item.name}
                                                                    desg={item.desg}
                                                                    company={item.company}
                                                                    link={item.link}
                                                                    size={'sm'}
                                                                />
                                                            </Col>
                                                        )
                                                    }) : ""
                                                }
                                            </Row>
                                        </SmallAccordionBody>
                                    </SmallAccordion>
                                    <SmallAccordion className={accordion ? "show" : ""}>
                                        <SmallAccordionHeader onClick={handleAccordion}>
                                            <TitleXs>Future-Proofing Health Through Inclusive Innovation</TitleXs>
                                            <AccHeadRight>
                                                <TitleXs>10:00 AM - 11:00 AM</TitleXs>
                                                <svg width="36" height="37" viewBox="0 0 36 37" fill="none"
                                                     xmlns="http://www.w3.org/2000/svg">
                                                    <rect x="16" y="0.438477" width="4" height="36" fill="black"/>
                                                    <rect x="36" y="16.4385" width="4" height="36"
                                                          transform="rotate(90 36 16.4385)" fill="black"/>
                                                </svg>
                                            </AccHeadRight>
                                        </SmallAccordionHeader>
                                        <SmallAccordionBody>
                                            <Row justifyContent={'space-between'} colGap={'12px'} rowGap={'35px'}>
                                                {
                                                    speakersData ? speakersData.map((item, index) => {
                                                        return (
                                                            <Col key={index} className={'speaker-col-sm'}>
                                                                <SpeakerCard
                                                                    image={item.image}
                                                                    name={item.name}
                                                                    desg={item.desg}
                                                                    company={item.company}
                                                                    link={item.link}
                                                                    size={'sm'}
                                                                />
                                                            </Col>
                                                        )
                                                    }) : ""
                                                }
                                            </Row>
                                        </SmallAccordionBody>
                                    </SmallAccordion>
                                    <SmallAccordion className={accordion ? "show" : ""}>
                                        <SmallAccordionHeader onClick={handleAccordion}>
                                            <TitleXs>CEO Panel #1: Activating the Health Ecosystem to Advance Health
                                                Equity</TitleXs>
                                            <AccHeadRight>
                                                <TitleXs>10:00 AM - 11:00 AM</TitleXs>
                                                <svg width="36" height="37" viewBox="0 0 36 37" fill="none"
                                                     xmlns="http://www.w3.org/2000/svg">
                                                    <rect x="16" y="0.438477" width="4" height="36" fill="black"/>
                                                    <rect x="36" y="16.4385" width="4" height="36"
                                                          transform="rotate(90 36 16.4385)" fill="black"/>
                                                </svg>
                                            </AccHeadRight>
                                        </SmallAccordionHeader>
                                        <SmallAccordionBody>
                                            <Row justifyContent={'space-between'} colGap={'12px'} rowGap={'35px'}>
                                                {
                                                    speakersData ? speakersData.map((item, index) => {
                                                        return (
                                                            <Col key={index} className={'speaker-col-sm'}>
                                                                <SpeakerCard
                                                                    image={item.image}
                                                                    name={item.name}
                                                                    desg={item.desg}
                                                                    company={item.company}
                                                                    link={item.link}
                                                                    size={'sm'}
                                                                />
                                                            </Col>
                                                        )
                                                    }) : ""
                                                }
                                            </Row>
                                        </SmallAccordionBody>
                                    </SmallAccordion>
                                    <SmallAccordion className={accordion ? "show" : ""}>
                                        <SmallAccordionHeader onClick={handleAccordion}>
                                            <TitleXs>Class in Session: What COVID-19 Is Teaching Us</TitleXs>
                                            <AccHeadRight>
                                                <TitleXs>10:00 AM - 11:00 AM</TitleXs>
                                                <svg width="36" height="37" viewBox="0 0 36 37" fill="none"
                                                     xmlns="http://www.w3.org/2000/svg">
                                                    <rect x="16" y="0.438477" width="4" height="36" fill="black"/>
                                                    <rect x="36" y="16.4385" width="4" height="36"
                                                          transform="rotate(90 36 16.4385)" fill="black"/>
                                                </svg>
                                            </AccHeadRight>
                                        </SmallAccordionHeader>
                                        <SmallAccordionBody>
                                            <Row justifyContent={'space-between'} colGap={'12px'} rowGap={'35px'}>
                                                {
                                                    speakersData ? speakersData.map((item, index) => {
                                                        return (
                                                            <Col key={index} className={'speaker-col-sm'}>
                                                                <SpeakerCard
                                                                    image={item.image}
                                                                    name={item.name}
                                                                    desg={item.desg}
                                                                    company={item.company}
                                                                    link={item.link}
                                                                    size={'sm'}
                                                                />
                                                            </Col>
                                                        )
                                                    }) : ""
                                                }
                                            </Row>
                                        </SmallAccordionBody>
                                    </SmallAccordion>
                                </div>
                                <SeeAllBtn>
                                    <PlusIconSvg/>
                                    See All
                                </SeeAllBtn>
                            </AgendaSection> */}
                            {/* Agenda Section end */}

                            {/*  Speakers Section begin  */}
                            {/* <SpeakersSection id={'speakers'}>
                                <TitleMd
                                    margin={'0 0 24px 0'}
                                    textTransform={'uppercase'}
                                >
                                    Speakers
                                </TitleMd>
                                <Row justifyContent={'space-between'} colGap={'12px'} rowGap={'35px'}>
                                    {
                                        speakersData ? speakersData.map((item, index) => {
                                            return (
                                                <Col key={index} className={'speaker-col'}>
                                                    <SpeakerCard
                                                        image={item.image}
                                                        name={item.name}
                                                        desg={item.desg}
                                                        company={item.company}
                                                        link={item.link}
                                                        size={'sm'}
                                                    />
                                                </Col>
                                            )
                                        }) : ""
                                    }
                                </Row>
                                <SeeAllBtn>
                                    <PlusIconSvg/>
                                    See All
                                </SeeAllBtn>
                            </SpeakersSection> */}
                            {/*  Speakers Section end  */}

                        {/*  Recordings Section begin  */}
                            {/* <RecordingsSection id={"recordings"}>
                                <TitleMd
                                    margin={'0 0 24px 0'}
                                    textTransform={'uppercase'}
                                >
                                    RECORDINGS
                                </TitleMd>
                                <Row justifyContent={'space-between'} colGap={'12px'} rowGap={'35px'}>
                                    <Col>
                                        <RecordingCard
                                            image={'images/video-cover.png'}
                                            title={'Lorem ipsum dolor sit amet'}
                                            listData={
                                                ["Quis enim lobortis scelerisque", "Nulla at volutpat diam ut venenatis", "Metus vulputate", "Interdum velit laoreet id donec ultrices."]
                                            }
                                        />
                                    </Col>
                                    <Col>
                                        <RecordingCard
                                            image={'images/video-cover.png'}
                                            title={'Lorem ipsum dolor sit amet'}
                                            listData={
                                                ["Quis enim lobortis scelerisque", "Nulla at volutpat diam ut venenatis", "Metus vulputate", "Interdum velit laoreet id donec ultrices."]
                                            }
                                        />
                                    </Col>
                                    <Col>
                                        <RecordingCard
                                            image={'images/video-cover.png'}
                                            title={'Lorem ipsum dolor sit amet'}
                                            listData={
                                                ["Quis enim lobortis scelerisque", "Nulla at volutpat diam ut venenatis", "Metus vulputate", "Interdum velit laoreet id donec ultrices."]
                                            }
                                        />
                                    </Col>
                                    <Col>
                                        <RecordingCard
                                            image={'images/video-cover.png'}
                                            title={'Lorem ipsum dolor sit amet'}
                                            listData={
                                                ["Quis enim lobortis scelerisque", "Nulla at volutpat diam ut venenatis", "Metus vulputate", "Interdum velit laoreet id donec ultrices."]
                                            }
                                        />
                                    </Col>
                                </Row>
                                <SeeAllBtn>
                                    <PlusIconSvg/>
                                    See All
                                </SeeAllBtn>
                            </RecordingsSection> */}
                        {/*  Recordings Section end  */}


                            {/* <LocationSection id={'location'}>
                                <TitleMd
                                    margin={'0 0 24px 0'}
                                    textTransform={'uppercase'}
                                >
                                    Location
                                </TitleMd>
                                <LocationMapCard/>
                            </LocationSection> */}
                            {/*  Location Section end  */}

                            {/* <RegSection id={'register'}>
                                <TitleMd>
                                    Register for Sxsw 2022 PRECOMMERCE INNOVATION SUMMIT
                                </TitleMd>
                                <TitleXs>
                                    Subtitle here. Quis enim lobortis scelerisque fermentum dui. Nulla at volutpat diam
                                    ut venenatis tellus in metus vulputate. Interdum velit laoreet id donec ultrices.
                                </TitleXs>
                                <RegForm>
                                    <InputWrap>
                                        <Label>First Name</Label>
                                        <FormInput placeholder={'First Name'} type={'text'}/>
                                    </InputWrap>
                                    <InputWrap>
                                        <Label>last name</Label>
                                        <FormInput placeholder={'Last Name'} type={'text'}/>
                                    </InputWrap>
                                    <InputWrap>
                                        <Label>email address</Label>
                                        <FormInput placeholder={'Email Address'} type={'email'}/>
                                    </InputWrap>
                                    <InputWrap>
                                        <Label>title</Label>
                                        <FormInput placeholder={'Title'} type={'text'}/>
                                    </InputWrap>
                                    <InputWrap>
                                        <Label>Company</Label>
                                        <FormInput placeholder={'Company'} type={'text'}/>
                                    </InputWrap>
                                    <Checkbox className={'dark square'} name="check-1" id="check-1"
                                              checkDotColor=" #000"
                                              circleColor="#000">
                                        I agree to receive relevant emails from Real Chemistry, including exclusive
                                        event
                                        invitations and a monthly digest of key healthcare news.
                                    </Checkbox>
                                    <Checkbox className={'dark square'} name="check-2" id="check-2"
                                              checkDotColor=" #000"
                                              circleColor="#000">
                                        By registering, I agree to recieve relevant emails from Real Chemistry,
                                        inclouding exclusive event invitations and a monthly digest of key healthcare
                                        news.
                                    </Checkbox>
                                    <RegSubmitBtn type={'submit'}>
                                        <ViewAll className={'register-link'} title={'SUBMIT'} link="#" borderColor="#000" titleColor="#000" hoverBackground="#000" svgColor="#000" width="290px" height="38px"/>
                                    </RegSubmitBtn>
                                </RegForm>
                            </RegSection> */}
                        </EventsContainer>
            </BlogContainer>

            {/* Share Blog */}
            {/* <AuthorSocial>
                <LinkedinShareButton url={window.location.href}>
                    <BodyRg textTransform="uppercase">SHARE ON LINKEDIN</BodyRg>
                </LinkedinShareButton>
                <TwitterShareButton url={window.location.href}>
                    <BodyRg textTransform="uppercase">SHARE ON TWITTER</BodyRg>
                </TwitterShareButton>
            </AuthorSocial> */}

            {/*</BlogCardsMobile>*/}
            {/* <div id="slider" ref={marqueRef}>
                <Marquee
                    borderTop={'none'}
                    link="/news-events"
                    title="View All Media"
                />
            </div> */}
              <FooterAnimation
				svgDesktop="/images/purple-footer.svg"
				svgMobile="/images/purple-footer-mbl.svg"
				borderTop="none"
			/>
        </EventsPageWrap>
    );
}

export default EventsPage;
