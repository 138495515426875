import React from 'react';
import {
    ImgWrap,
    SlideImg
} from "./elements";



function ImageComp({ borderTop, borderRight, borderBottom, borderLeft, backgroundColor, rows, cols, image, width, height,imagePadding }) {
    return (
        <ImgWrap
          imagePadding={imagePadding}
            borderTop={borderTop}
            borderRight={borderRight}
            borderBottom={borderBottom}
            borderLeft={borderLeft}
            backgroundColor={backgroundColor}
            rows={rows}
            cols={cols}
        >
            <SlideImg
                width={width}
                height={height}
                src={image} />
        </ImgWrap>
    )

}

export default ImageComp;