import styled from 'styled-components';
import {sizeTabletLg} from "../devices";



// Media Buttons Component Style
export const MediaButtonsScroll = styled.div`
  width: 100%;
  &::-webkit-scrollbar {
    display: none;
  }
  ${sizeTabletLg(`
    overflow: auto;
    width: calc(100% + 36px);
    z-index: 1;
    padding: 0 8px 0 18px;
  `)};
`;
export const MediaButtonsWrap = styled.div`
  width: 100%;
  ${sizeTabletLg(`
    width: 690px;
  `)}
  button,
  a {
    position: relative;
    border-right: none;
    &:last-child {
      border-right: 1px solid #000;
    }
    &:after {
      content: "";
      width: 1px;
      height: calc(100% + 2px);
      background: #D12FFF;
      opacity: 0;
      position: absolute;
      right: -1px;
      top: -1px;
      z-index: 1;
    }
    &:hover {
      opacity: 1;
      border-right-color: transparent;
      margin-right: 0;
      &:after {
        opacity: 1;
      }
    }
    &.active {
      &:after {
        content: none;
      }
    }
  }
`;
export const MediaButtonsRow = styled.div`
  display: flex;
`;
