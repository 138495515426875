import { Link } from "react-router-dom";
import styled from 'styled-components';


import {
    TitleMd,
    TitleXl,
    OverlayButton,
    Label,
    Desktop,
    Mobile,
    TitleSm,
    BodyRg
} from "components/elements";
import {
    sizeLaptopMd,
    sizeDesktopSm,
    sizeMobileLg,
    sizeMobileXs,
    sizeLaptop,
    sizeMobileMd,
    sizeTabletLg,
    sizeWideScreen
} from "components/devices";
import {
    SelectField
} from "components/SelectField";


export const Br = styled.br`
 
`;

export const ContactModalWrap = styled.div`
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-image: url(${({ backgroundImage }) => backgroundImage || ""});
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center; 
    background-color: #4A4A4A;

    box-sizing: border-box;
    text-align: center;
    display: block;
    transition: 0.15s all ease; 
    opacity: ${({ show }) => show ? "1" : "0"};
    z-index: ${({ show }) => show ? "9999999999" : "-1"};
    overflow: auto;

   
    
    ${SelectField} {
        ${sizeMobileLg(`
            max-width: 360px;
            margin-top: 10px;
            margin-left: auto;
            margin-right: auto;
            display: inline-block;
        `)}
        ${sizeMobileMd(`
            max-width: 320px;
        `)}
        ${sizeMobileXs(`
            max-width: 280px;
        `)}


        ${sizeWideScreen(`
            max-width: 850px !important;
        `)}
    }
    
`;

export const ContactModalWrapInner = styled.div`
    min-height: 100%;
    position: relative;
    padding: 70px 0 0 0;
    box-sizing: border-box;
      display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    overflow: hidden;
/*

    background-image: url(${({ backgroundImage }) => backgroundImage || ""});
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    background-color: #4A4A4A;
*/


    ${sizeDesktopSm(`
        padding: 40px 0 0 0;
    `)}
    ${sizeMobileLg(`
        padding: 25px 0 0 0;
        ${Br} {
            display: none;
        }
    `)}
    
    ${BodyRg} {
        ${sizeLaptop(`
            font-size: 22px;
        `)}

        ${sizeWideScreen(`
            font-size: 0.85vw;
        `)}
    }
`;

export const ContactModalVideo = styled.video`
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    //height: fit-content;
    object-fit: cover;
    z-index: -1;
    box-sizing: border-box;
    /* height: ${({ height }) => height + "px" || "100%"}; */
    ${sizeLaptop(`
        height: 100vh;
    `)}
    // ${sizeTabletLg(`
    //     // height: fit-content;
    // `)};



    ${sizeWideScreen(`
        height: 100vh !important;
    `)}
`;

export const ModalContainer = styled.div`
    max-width: 1400px;
    margin: 0 auto;
    padding: 70px 40px;
    height: auto;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    color: #fff;
    ${sizeDesktopSm(`
        padding: 50px 25px;
        box-sizing: border-box;
        width: 100%;
    `)}
    ${sizeMobileLg(`
        padding: 50px 20px 30px 20px;
        max-width: 360px;
    `)}
    ${sizeMobileXs(`
        max-width: 280px;
    `)}



    ${sizeWideScreen(`
        max-width: 2500px;
        width: 100%;
    `)}
`;
export const CloseModal = styled.button`
    position: absolute;
    right: 40px;
    top: 40px;
    background-color: transparent;
    border: none;
    outline: none;
    width: 84px;
    height: 84px;
    cursor: pointer;
    ${sizeDesktopSm(`
        top: 20px;
        right: 20px;
    `)}
    ${sizeMobileLg(`
        width: 50px;
        height: 50px;
        opacity: 0.7;
        top: 10px;
        right: 10px;
    `)}

        ${sizeWideScreen(`
            width: 120px;
            height: 120px;
            top: -40px;
        `)}
`;
export const CloseIcon = styled.img`
    width: 100%;
    height: 100%;
`;

export const ContactForm = styled.form`
    display: inline-block;
    font-size: 54px;
    line-height: 1.55;
    color: #fff;
    ${sizeWideScreen(`
        font-size: 2vw;
        width: 100%;
    `)}


    ${sizeDesktopSm(`
        font-size: 40px;
        line-height: 1.5;
    `)}
    ${sizeLaptopMd(`
        font-size: 34px;
    `)}
    ${sizeLaptop(`
        font-size: 44px;
        line-height: 1.95;
    `)}
    ${sizeTabletLg(`
        font-size: 40px;
        line-height: 1.4;
    `)}
    ${sizeMobileLg(`
        font-size: 28px;
    `)}
    ${sizeMobileMd(`
        font-size: 23px;
    `)}
    ${sizeMobileXs(`
        font-size: 20px;
    `)}


    ${Desktop} {
        display: inline-block;
        ${sizeMobileLg(`
            display: none;
        `)}
    }
`;
export const InputField = styled.input`
    max-width: 450px;
    border: none;
    border-bottom: 2px dashed #707070;
    padding: 0;
    color: #FFF;
    font-size: 54px;
    background-color: transparent;
    outline: none;
    box-shadow: none;
    display: inline-block;
    vertical-align: top;
    font-family: 'HelveticaNowDisplay';
    margin-left: 15px;
    text-align: center;
    box-sizing: border-box;
    -webkit-border-radius: 0; 
  -moz-border-radius: 0; 
  border-radius: 0;
    ${sizeWideScreen(`
            font-size: 1.5vw !important;
        `)}
    &:-webkit-autofill,
    &:-webkit-autofill, 
    &:-webkit-autofill:hover, 
    &:-webkit-autofill:focus, 
    &:-webkit-autofill, 
    &:-webkit-autofill:hover, 
    &:-webkit-autofill:focus, 
    &:-webkit-autofill, 
    &:-webkit-autofill:hover, 
    &:-webkit-autofill:focus {
        font-size: 54px !important;
        -webkit-text-fill-color: #fff !important;
        ${sizeDesktopSm(`
            font-size: 36px !important;
        `)}
        ${sizeLaptopMd(`
            font-size: 34px !important;
        `)}
        ${sizeLaptop(`
            font-size: 46px !important;
        `)}
        ${sizeMobileLg(`
            font-size: 22px !important;
        `)}
        ${sizeMobileXs(`
            font-size: 20px !important;
        `)}


        ${sizeWideScreen(`
            font-size: 2vw !important;
        `)}
    }
    &.error {
        color: #D54639;
        border-color: #D54639;
        background-image: url(${({ errorIcon }) => errorIcon || "inherit"});
        background-repeat: no-repeat;
        background-position: 99% center;
        &:-webkit-autofill,
        &:-webkit-autofill, 
        &:-webkit-autofill:hover, 
        &:-webkit-autofill:focus, 
        &:-webkit-autofill, 
        &:-webkit-autofill:hover, 
        &:-webkit-autofill:focus, 
        &:-webkit-autofill, 
        &:-webkit-autofill:hover, 
        &:-webkit-autofill:focus {
            -webkit-text-fill-color: #D54639 !important;
            background-image: url(${({ errorIcon }) => errorIcon || "inherit"}) !important;
            background-repeat: no-repeat !important;
            background-position: 97% center !important;
        }
    }
    &.names {
        max-width: 340px;
        ${sizeLaptop(`
            max-width: 270px;
        `)}

        ${sizeWideScreen(`
            max-width: 770px; !important;
        `)}
    }
    &:focus {
        outline: none;
        box-shadow: none;
    }
    &.textarea {
        max-width: 1070px;
        width: 100%;
        ${sizeDesktopSm(`
            max-width: 700px;
        `)}

        ${sizeWideScreen(`
            max-width: 1300px !important;
        `)}
    }
    &:required {
        border-color: #D54639;
    }
    &::placeholder {
        text-align: center;
    }
    & + ${Label} {
        color: #D54639;
        text-transform: inherit;
        position: relative;
        margin-bottom: 0;
        display: block;
        display: block;
        text-align: left;
        left: 15px;
        top: 7px;
        ${sizeMobileLg(`
            left: 0;
        `)}
    }

    ${sizeDesktopSm(`
        font-size: 36px;
    `)}
    ${sizeLaptopMd(`
        font-size: 34px;
    `)}
    ${sizeLaptop(`
        font-size: 46px;
    `)}
    ${sizeTabletLg(`
        width: 100%;
        display: block;
        max-width: 100% !important;
        width: 100% !important;
        margin: 0 auto 10px;
    `)}
    ${sizeMobileLg(`
        font-size: 22px;
        height: 35px;
    `)}
    ${sizeMobileXs(`
        font-size: 20px;
    `)}


    &.email {
        max-width: 620px;
        // border: none;
        padding-right: 0;
        width: calc(100% - 30px);
        margin-left: 0;
        ${sizeTabletLg(`
            width: calc(100% - 30px) !important;
            float: left;
        `)}
        ${sizeMobileLg(`
            padding-right: 0;
        `)}

        ${sizeWideScreen(`
            max-width: 1000px !important;
        `)}
    }
`;

export const InputDiv = styled.div`
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding-right: 4px;
    margin-bottom: 10px;
    ${sizeTabletLg(`
        width: 100%;
        display: block;
    `)}
    
    ${sizeMobileLg(`
        margin: 0 auto 15px;
        padding: 0;
        max-width: 460px;

        ${InputField} {
            display: inline-block;
            margin-bottom: 0;
            width: auto;
            max-width: 260px;
        }
    ${Mobile} {
            display: inline-block;
            vertical-align: bottom;
        }
    `)}
    &.learn_more {
        margin-top: 35px;
        width: 100%;
        flex-direction: column;
        ${sizeDesktopSm(`
            margin-top: 35px;
        `)}
        ${InputField} {
            margin-top: 0;
            max-width: 550px;
            margin-left: 0;
        }
    }
`;

export const ContactFooter = styled.div`
    ${sizeMobileLg(`
        margin-top: 15px;
    `)}
`;
export const ArrowSubmit = styled.img`
    position: relative;
    left: 15px;
    width: 64px;
    height: 44px;
    transition: 0.4s all ease;
    ${sizeMobileLg(`
        display: none;
    `)}
`;

export const SubmitButton = styled(OverlayButton)`
    background-color: transparent;
    border: none;
    outline: none;
    border-top: 1px solid #707070;
    height: 110px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    width: 100%;
    margin-top: 30px;
    cursor: pointer;
    transition: 0.4s all ease;
    &:before {
        left: ${({ activeSent }) => activeSent ? "0" : "-150%"};
    }
    ${sizeDesktopSm(`
        margin-top: 15px;
    `)}
    &:hover {
        &:before {
            left: 0;
        }
        ${ArrowSubmit} {
            transition: 0.4s all ease;
            left: 35px;
        }
    }
    ${TitleSm} {
        color: #FFF;
        font-size: 36px;
        ${sizeMobileLg(`
            font-size: 30px;
        `)}
        ${sizeMobileMd(`
            font-size: 24px;
        `)}

    ${sizeWideScreen(`
        font-size: 1vw;
    `)}
    }
    ${sizeDesktopSm(`
        height: 100px;
    `)}
    ${sizeMobileLg(`
        border-color: #fff;
        height: 50px;
        margin-top: 10px;
    `)}
    ${TitleMd} {
        font-size: 54px;
        ${sizeMobileLg(`
            font-size: 16px;
        `)}
    }


    ${sizeWideScreen(`
        border-left: 1px solid #707070;
        border-right: 1px solid #707070;
    `)}
`;



// Thanks CSS
export const ThanksContainer = styled.div`
    text-align: center;
    max-width: 1440px;
    margin: 0 auto;
    padding: 0 25px;
    display: -webkit-box; 
    display: -ms-flexbox; 
    display: flex; 
    -webkit-box-align: center; 
    -ms-flex-align: center; 
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    height: 100%;
    box-sizing: border-box;

    ${TitleXl} {
        ${sizeDesktopSm(`
            font-size: 60px;
        `)}
        ${sizeLaptopMd(`
            font-size: 54px;
        `)}
        ${sizeMobileLg(`
            font-size: 42px;
        `)}

    ${sizeWideScreen(`
        font-size: 2vw;
    `)}
    }

    ${sizeWideScreen(`
        max-width: 2500px;
        width: 100%;
    `)}
`;
export const ThanksInner = styled.div`

`;
export const CheckIcon = styled.img`
    margin-bottom: 40px;
    ${sizeDesktopSm(`
        width: 70px;
        height: 70px;
    `)}
    ${sizeMobileLg(`
        margin-bottom: 25px;
    `)}
`;
export const ArrowIcon = styled.svg`
    margin-top: 15px;
    position: relative;
    transition: 0.5s all ease;
    left: 0;
`;
export const ExploreLink = styled(Link)`
    margin-top: 70px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    &:hover {
        ${TitleSm} {
            color: #0F20FF;
        }
        ${ArrowIcon} {
            left: 15px;
            transition: left 0.5s;
            line, 
            path {
                stroke: #0F20FF;
            }
        }
    }
    ${sizeDesktopSm(`
        margin-top: 40px;
    `)}
`;

export const FormGroup = styled.div`
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    margin-left: 15px;
    ${sizeTabletLg(`
        width: 93%;
        margin-bottom: 12px;
        display: inline-block;
        vertical-align: top;
        margin-left: 0;
    `)}
    &.email_input {
        position: relative;
        margin-left: 15px;
        ${sizeTabletLg(`
            top: 0;
            margin-left: 0;
        `)}
    }
    &.email_required,
    &.email_invalid {
        border-color: #D54639;
    }
`;
export const ErrorImg = styled.img`
        position: absolute;
        right: 2px;
        top: 24px;
        ${sizeTabletLg(`
            top: 4px;
        `)}
`;
