import styled from 'styled-components';
import {
    TitleXl,
    TitleMd,
    TitleSm,
    BodySm, 
    Row, 
    Col7, 
    Col5
} from "components/elements";
import {
    sizeMobileLg,
    sizeMobileMd,
    sizeLaptopLg,
    sizeTablet,
    sizeMobileXsXs,
    sizeDesktopLg,
    sizeXlDevice,
    sizeDesktopMd,
    sizeLaptop,
    sizeMobileSm
} from "components/devices";

export const AboutUsPurpleSectionContainer = styled.div`
    width: 100%;
    min-height: 950px;
    height: 100%;
    padding: 6% 0;
    background: url("/images/about-us-purple-body.svg") center center no-repeat;
    background-size: cover;
    postion: relative;
    overflow-x: hidden;
    overflow-y: hidden;

    ${sizeMobileLg (`
        padding: 5% 0;
        min-height: 800px;
        background: url("/images/not-to-brag-mbl.svg") no-repeat;
        background-size: cover;
    `)}
    ${sizeMobileMd (`
        min-height: 850px;
    `)} 
    ${Row}{
        width: 100%;
        justify-content: space-between;
        align-items: flex-start;
        position: relative;
        ${sizeTablet(`
            flex-direction: column;
             width: 100%;
        `)}
    }

        ${Col5}{
             padding: 3% 0 0 9%;
             ${sizeLaptopLg (`
                padding: 3% 0 0 4.5%;
             `)}
            ${sizeTablet(`
                width: 100%;
                max-width: 100%;
                padding: 3% 4.5%;
         `)}

        ${TitleMd} {
            color: #fff;
            font-size: 36px;
            padding: 5% 0;
            ${sizeMobileLg (`
                font-size: 20px;
            `)}
        }

        ${BodySm} {
            color: #fff;
            font-size: 20px;           
            ${sizeMobileLg (`
                font-size: 16px;
            `)}
        }
    }

    ${Col7}{
        display: flex;
        justify-content: flex-end;
        padding: 3% 0 0 0;
        width: 100%;
        img {
            width: 90%;
        }
        ${sizeTablet(`
            width: 100%;
            max-width: 100%;
            justify-content: center;
            img {
                width: 100%;
            }
        `)}

        }

`

export const TitleXlContainer = styled.div`
        padding: 0 0 0 9%;
        max-width: 50%;
    ${sizeLaptopLg (`
        padding: 0 4.5%;
    `)}
    ${sizeTablet (`
        max-width: 75%;
    `)}
    ${sizeMobileLg(`
        padding: 5% 4.5% 0;
        max-width: 100%;
    `)}
    ${TitleXl}{
        font-size: 48px;
        line-height: 57px;
        font-weight: 900;
        color: #fff;
        .magenta {
          color:  #D12FFF;
        }
        ${sizeMobileLg (`
            font-size: 36px;
            line-height: 43px;
    `)}
    }
`


export const TopContentContainer = styled.div`
    z-index: 10;
    width: 100%;
    height: 100%;
    ${TitleMd}{
        font-size: 72px;
        color: #fff;
        line-height: 84px;
        font-weight: 700;
    } 
    ${TitleSm}{
        font-size: 18px;
        line-height: 21px;
        font-weight: 700;
        color: #fff;
    }
`

export const LeftCircleContainer = styled.div`
    z-index: 2;
    position: absolute;

    ${sizeXlDevice(`
        right: 150px;
        top: 280px;
    `)}
    ${sizeDesktopLg(`
        right: 180px;
        top: 280px;
    `)}
    ${sizeDesktopMd (`
        right: 160px;
        top: 260px;
    `)}
    ${sizeLaptopLg(`
        right: 120px;
        top: 310px;
    `)}
    ${sizeLaptop(`
        right: 260px;
        top: 250px
    `)}
    ${sizeTablet(`
        right: 150px;
        top: 300px;
    `
    )}
    ${sizeMobileLg (`
        top: 150px;
        left: 20%;
      
   `)}
   ${sizeMobileSm(`
        top: 165px;
   `)}
   ${sizeMobileXsXs(`
        // right: 20px;
        top: 170px;
        left: 15%;
        
    `)}
`

export const RightCircleContainer = styled.div`
    z-index: 1;
    position: absolute;
    ${sizeXlDevice(`
        right: -70px;
        top: 0px;
    `)}
    ${sizeDesktopLg(`
        right: -70px;
        top: 0px;
    `)}
    ${sizeDesktopMd (`
        right: -80px;
        top: 0px;
    `)}
    ${sizeLaptop(`
        right: 0px;
    `)}
    ${sizeTablet(`
        right: -70px;
        `
    )}
    ${sizeMobileLg (`
        right: -10%;
        top: 0;
    `)}
    ${sizeMobileXsXs(`
        right: -20%;
        // right: -70px;
    `)}
`

export const MiddleCircleContainer = styled.div`
    z-index: 2;
    position: absolute;
    ${sizeXlDevice(`
        left: 20px;
        top: 0px;
    `)}
    ${sizeDesktopLg(`
        left: 80px;
        top: 0px;
    `)}
    ${sizeDesktopMd (`
        left: 80px;
        top: 0;
    `)}
    ${sizeLaptop(`
        left: 0;
    `)}
    ${sizeTablet(`
        left: -50px;
    `
    )}
    ${sizeMobileLg (`
        left: -10%;
        top: 0;
    `)}
    ${sizeMobileXsXs(`
        left: -20%;
        // left: -70px;
    `)}
`