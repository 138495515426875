import styled from 'styled-components';
import {
    TitleXl,
    TitleMd,
    TitleSm, 
    Col4,
    Row,
    TitleXs,
    Col3

} from "components/elements";
import {
    sizeMobileLg,
    sizeMobileXsXs,
    sizeTabletLg,
    sizeLaptop,
    sizeTablet
} from "components/devices";



export const LeadershipTeamContainer = styled.div`
  width: 100%;
  height: 100%;
  padding: 0 0 0;
  background-color: ${({backgroundColor}) => backgroundColor|| '#F0F0F0'};
  ${sizeMobileLg(`
    padding: 0 0 50px;
  `)}
`

export const TitleXlContainer = styled.div`
    max-width: ${({maxWidth}) => maxWidth || '65%'};
    width: 100%;
    padding: 6% 9% 0;
    ${sizeLaptop(`
        max-width: 87%;
    `)}
    ${sizeTabletLg (`
        max-width: 100%;
        padding: 13% 4.5% 0;
    `)}
    ${TitleXl}{
        font-size: 48px;
        line-height: 57px;
        font-weight: 900;
        ${sizeMobileLg(`
            font-size: 36px;
            line-height: 43px;
            font-weight: 900 !important;
            max-width: 100%;
        `)}
    }
`

export const TitleMdContainer = styled.div`
    max-width: 100%;
    width: 100%;
    padding: 2% 9% 0;
    ${sizeTabletLg (`
        padding: 5% 4.5% ;
    `)}
    ${TitleMd}{
        font-size: 36px;
        line-height: 43px;
        font-weight: 400;
        ${sizeMobileLg(`
            font-size: 20px;
            line-height: 24px;
        `)}
    }
    ${TitleXl}{
        font-size: 36px;
        line-height: 43px;
        padding: 20px 0 0;

        ${sizeMobileLg(`
            font-size: 20px;
            line-height: 24px;
            padding: 0 0 0;
        `)}
    }
`

export const AdditionalLeadersContainer = styled.div`
    width: 100%;
`       

export const ExecutivesContainer = styled.div`
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        padding: 20px 0 8%;
        ${sizeTabletLg (`
            padding: 0 4.5% 5%;
        `)}
        ${sizeMobileLg(`
            padding: 0 4.5% 0;
            justify-content: space-between;
        `)}
    }
    ${Row}{
        width: 100%;
        justify-content: flex-start;       
        ${sizeTabletLg(`
            justify-content: space-between;
        `)}

        &.thmRow {
            justify-content: space-between;
            align-items: flex-start;
            row-gap: 35px;
            ${sizeTablet (`
                flex-wrap: wrap;
            `)}
        }
    }
    
    ${Col4}{
        padding: 6% 0 0;
        ${sizeTabletLg (`
            -ms-flex: 0 0 26%;
            flex: 0 0 26%;
            max-width: 26%;
            justify-content: space-between;
            align-items: flex-start;
        `)}
        ${sizeMobileLg(`
           -ms-flex: 0 0 45%;
            flex: 0 0 45%;
            max-width: 45%;
            padding: 10% 0 0;
            justify-content: space-between;
        `)}
    }

     
    ${Col3}{
        ${sizeTablet (`
        -ms-flex: 0 0 47%;
        flex: 0 0 47%;
        max-width: 47%;
        padding: 10% 0 0;
        margin: 0;
         `)}
    }
`

export const InnerColContainer = styled.div`
    text-align: left;
    margin: 0 auto;
    width: 44%;
    height: auto;
   ${sizeTabletLg (`
        width: 100%;
        margin: 0;
    `)}
    ${sizeMobileLg(`
        margin: 0;
        width: 100%;
    `)}
    &.thmInner {
        width: 60%;
        margin: 0 auto;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        text-align: left;
        ${sizeTablet (`
            width: 100%; 
            margin: 0;
        `)}
    }
`

export const ImageContainer = styled.div`
    height: 182px;
    width: 182px;
    display: flex;
    border: 12px solid #6900FF;
    border-radius: 50%;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    .leaderImage {
        width: 136px;
        height: 136px;
        border-radius: 50%;
    ${sizeMobileLg(`
        width: 115px;
        height: 115px; 
    `)}
    }
    ${sizeMobileLg(`
        border: 6px solid #6900FF;
        width: 150px; 
        height: 150px;
    `)}

    ${sizeMobileXsXs(`
        width: 120px;
        height: 120px;
        border: 4px solid #6900FF;
    `)}
`

export const NameContainer = styled.div`
    padding: 20px 0 0;
    ${TitleSm} {
        font-size: 24px;
        ${sizeMobileLg(`
            font-size: 18px;
            line-height: 20px;
        `)}
    }
    &.thmNameCont {
        padding:  0 0 !important;
        ${TitleSm}{
            padding: 15px 0 0 !important; 
        }
     
     }
`
export const TitleContainer = styled.div`
    padding: 6px 0 0;
    max-width: 280px;
    width: 100%;
    ${TitleXs} {
        font-size: 16px;
        line-height: 23px;
        color: #787878;
    }
    &.thmTitleCont {
        padding: 0 0 0 !important;
        ${TitleXs}{
            padding: 5px 0 0 !important;
        }
    }

`

export const LinkedInContainer = styled.a`
    display: block;
    padding: 10px 0;
    width: 83px;
    height: 20px;
    &.thmLinkedin{
        padding: 15px 0 0;
    }

`