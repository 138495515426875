
import React, {useEffect} from 'react';

import PlayMarquee from "components/PlayMarquee";
import { WistiaWrapper, WistiaAspectRatio, WistiaResponsiveWrapper, Video } from "./elements";
import WistiaPopupEmbed from "../WistiaPopupEmbed";

function HeroWistia(props) {
  const showPopup = (id) => {
    let getLastElemIndex = document.getElementsByClassName("wistia_placebo_close_button").length-1;
    if(getLastElemIndex>=0){
      document.getElementsByClassName("wistia_placebo_close_button")[getLastElemIndex].classList.add('wistia_show_active_button')
    }
    let video = window.Wistia.api(id);
    video.popover.show()
    video.play()
  }
  useEffect(()=>{
      return ()=>{
        for(let i=0;i < document.getElementsByClassName("wistia_placebo_close_button").length;i++) {
          document.getElementsByClassName("wistia_placebo_close_button")[i].classList.remove('wistia_show_active_button')
        }

      }
  },[])
  return (
    <>
      <WistiaWrapper onClick={() => { showPopup(props.hashedId) }}>
        <PlayMarquee marqueeText={props.marqueeText} />
        <WistiaAspectRatio height={props.height} >
          <WistiaResponsiveWrapper >
            <WistiaPopupEmbed
              customClass={'wistia_custom wistia_custom_popup'}
              hashedId={props.hashedId}
            />
            <Video
              objectFit={props.objectFit}
              width={props.width}
              backgroundColor={props.backgroundColor}
              id="heroVideo"
              isSafari={false}
              playing={true}
              loop
              muted={true}
              autoPlay={true}
              playsInline={true}
            >
              <source src={props.videoSrc} />
            </Video>
          </WistiaResponsiveWrapper>
        </WistiaAspectRatio>
      </WistiaWrapper>
    </>
  )
}

export default HeroWistia

