import React from "react";
import { Helmet } from "react-helmet";

function SEO({ description, image, title, author }) {
    return (
        <Helmet
            meta={[
                {
                    property: `title`,
                    content: title,
                },
                {
                    property: `description`,
                    content: description,
                },
                {
                    property: `og:title`,
                    content: title,
                },
                {
                    property: `og:url`,
                    content: window.location.origin,
                },
                {
                    property: `og:description`,
                    content: description,
                },
                {
                    property: `og:type`,
                    content: `website`,
                },
                {
                    property: `og:site_name`,
                    content: `RealChemistry`,
                },
                {
                    property: "og:image",
                    content: image,
                },
                {
                    property: "og:image:type",
                    content: "image/png",
                },
                {
                    property: "og:image:width",
                    content: "1200",
                },
                {
                    property: "og:image:height",
                    content: "1200",
                },
            ]}
        />
    );
}

export default SEO;