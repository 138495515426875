import React from 'react'


function PodcastArrow({ className }) {

    return (
        <svg className={className}  width="23" height="24" viewBox="0 0 23 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M22 0.461492L9.66667 0.461491L9.66667 2.46149L19.5333 2.46149L0.266668 21.7948L1.66667 23.1948L21 3.92816L21 13.7948L23 13.7948L23 1.46149L22 0.461492Z"
                fill="black"/>
        </svg>


    )
}

export default PodcastArrow;
