import React from 'react';

import { BodySm } from 'components/elements';
import {
  ContentType,
  TypeContainer,
  ContentHeadline,
  ArrowIcon,
  ContentDesc,
  ArrowIconWhite,
  grid4
} from "../elements";


function ContentCard({ rows, cols, borderTop, borderRight, borderBottom, borderLeft, data, minHeight, clickAllowed = true }) {
  return (
    <ContentType
      rows={rows}
      cols={cols}
      style={grid4}
      bordertop={borderTop}
      borderright={borderRight}
      borderbottom={borderBottom}
      borderleft={borderLeft}
      minHeight={minHeight}
      className="nav destination"
      to={(data?.destination && clickAllowed) ? data?.destination : '#'}
    >
      <TypeContainer >
        <ContentHeadline>
          <ArrowIcon src="/images/content-arrow.svg" alt="Click for more info arrow" />
          <ArrowIconWhite src="/images/content-arrow-white.svg" alt="Click for more info arrow" />
          {data?.headline}
        </ContentHeadline>
        <ContentDesc>
          <BodySm>
            {data?.description}
          </BodySm>
        </ContentDesc>
      </TypeContainer>
    </ContentType>
  )
}


export default ContentCard;
