// Subscribe BLock CSS
import styled from "styled-components";
import { sizeMobileLg, sizeLaptop} from "../devices";

export const CultureWrap = styled.div`

  .slick-slider {
    overflow: hidden;
    padding: 0 0 100px 0px;
 
  }
  .slick-list {
    overflow: visible;
    padding: 0;
  }
  .slick-prev, 
  .slick-next {
    position: absolute;
    top: auto;
    bottom: -10px;
    width: 48px;
    height: 40px;
    border-radius: 50%;
    font-size: 0;
    z-index: 22;
    opacity: 1;
    ${sizeMobileLg(`
      width: 35px;
      height: 27px;
  `)}

    &:before {
      content: "";
      background: url("/images/arrow-right-purple.png") center center no-repeat;
      background-size: cover;
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      margin: 0;
      padding: 0;
      opacity: 1;
    }
  }
  .slick-prev {
      left: calc(75% - 50px);
    ${sizeMobileLg(`
       left: calc(75% - 25px); 
    `)}
    &:before {
      transform: rotate(360deg);
      background: url("/images/arrow-left-purple.png") center center no-repeat;
      background-size: cover;
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      margin: 0;
      padding: 0;
      opacity: 1;
    }

    &.slick-disabled {
      &:before {
        background: url("/images/arrow-left-purple.png")  center center no-repeat;
        background-size: cover;
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        margin: 0;
        padding: 0;
        opacity: 0.5;
      }
    }
  }
  .slick-next {
    &.slick-disabled {
      &:before {
        transform: rotate(180deg);
        background: url("/images/arrow-left-purple.png")  center center no-repeat;
        background-size: cover;
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        margin: 0;
        padding: 0;
        opacity: 0.5;
      }
    }
    right: calc(15% - 50px);

  }
  .slick-slide {
    padding-right: 80px;
    ${sizeLaptop(`
      padding-right: 0;
    `)}

  }
  


  
`;

export const CardContainer = styled.div`
  padding: 0 28px;
${sizeMobileLg(`
  padding: 30px 0 0;
`)}
   
`;


export const Slide = styled.div`
 width: 100%;

 h3 {
  font-size: 36px !important;
  ${sizeLaptop(`
    font-size: 32px !important;
  `)}
  b{
    font-family: HelveticaNowDisplay Black !important;
    font-weight: 900 !important;
    color: #6900FF !important;
  }
 }
`;