import React from 'react';
import {
    EmptyWrap,
    EmptyImg
} from "./elements";



function EmptyComp({ borderTop, borderRight, borderBottom, borderLeft, titleNumber, backgroundColor, smallImg, rows, cols }) {
    return (
        <EmptyWrap
            borderTop={borderTop}
            borderRight={borderRight}
            borderBottom={borderBottom}
            borderLeft={borderLeft}
            backgroundColor={backgroundColor}
            rows={rows}
            cols={cols}
        >
            {smallImg && <EmptyImg src={smallImg} />}
        </EmptyWrap>
    )

}

export default EmptyComp;