import styled, { css } from "styled-components";

import { sizeTabletLg, sizeLaptopMd } from "../devices";


export const Desktop = styled.div`
  ${sizeTabletLg(`
    display: none;
  `)}
`;
export const InputSliderWrapper = styled.div`
  padding: 0 104px 30px 104px;
  ${sizeLaptopMd(`
    padding: 0 25px 30px 25px;
  `)}
`;


export const Scrollbar = styled.div`
  border: 8px solid #000;
  height: 112px;
  position: relative;
  border-radius: 130px;
  padding: 0 30px;
  overflow: hidden;
`;
export const ScrollDot = styled.div`
  width: 48px;
  height: 48px;
  border-radius: 50%;
  background-color: #000;
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  margin: 0 30px;
  cursor: pointer;
`;

export const SliderContainer = styled.div`
  overflow: hidden;
  display: grid;
  grid-auto-rows: minmax(100%, auto);
  grid-template-rows: 100% auto;
`;
export const SlideInnerWrap = styled.div`
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  cursor: pointer !important;
  ${sizeLaptopMd(`
    // width: 4320px;
  `)}
  ${sizeTabletLg(css`
    display: none;
  `)};
`;

export const DragButton = styled.div`
  width: 60px;
  height: 60px;
  border-radius: 100%;
  position: fixed;
  transform: translate(-30px, -30px);
  pointer-events: none;
  z-index: 9999;
  left: 50%;
  top: 50%;
  cursor: ${({ customCursor }) => customCursor ? "none" : "auto"};


  ${sizeTabletLg(`
    display: none;
  `)
  }
`;


export const DragIcon = styled.img``;
