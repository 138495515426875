import React from "react";

import { BodyRg, UL, LI} from "components/elements";

import { PrivacyInfoWrap, PvInfoContainer } from "./elements";

function PrivacyInfoSection({ handleToggleOtherAccordion, date, title }) {
  return (
      <PrivacyInfoWrap className="acc-cont">
        <PvInfoContainer>
          <BodyRg>
            We're incredibly excited to have you participate in our Health Care Media Lounge at SXSW 2022! We've created
            this social media toolkit, so you can tell your network about your session via your personal channels.
          </BodyRg>
          <BodyRg>
            By clicking the sections below, you'll find the following:
          </BodyRg>
          <UL>
            <LI>
              <BodyRg>
                Social copy options for both LinkedIn and Twitter.
              </BodyRg>
            </LI>
            <LI>
              <BodyRg>
                Creative assets for you to select, download and share with your social posts.
              </BodyRg>
            </LI>
            <LI>
              <BodyRg>
                Step-by-step instructions for sharing a post on LinkedIn and Twitter.
              </BodyRg>
            </LI>
          </UL>
          <BodyRg>
            If you'd like to use your own language to post about your session, please include #RealChemSXSW.
          </BodyRg>
          <BodyRg>
            Our Real Chemistry Social team will keep an eye out for your posts and engage with them as they go live!
          </BodyRg>
        </PvInfoContainer>
      </PrivacyInfoWrap>
  );
}

export default PrivacyInfoSection;