import React from 'react'


function SkipBackwardIcon() {

    return (
        <svg width="36" height="37" viewBox="0 0 36 37" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M15.6004 23.2112H13.6804V16.2932H11.3164V14.9252H11.5444C12.8704 14.8412 13.7644 14.4392 14.0824 12.9692H15.6004V23.2112Z"
                fill="black"/>
            <path
                d="M24.6845 19.8811C24.6845 22.0171 23.1185 23.4391 20.8505 23.4391C18.7805 23.4391 17.2085 22.2391 17.0645 20.2831H18.9245C18.9789 20.7447 19.2097 21.1674 19.5687 21.4627C19.9277 21.7579 20.387 21.9028 20.8505 21.8671C21.1127 21.8785 21.3744 21.8347 21.6185 21.7385C21.8627 21.6422 22.0839 21.4957 22.2678 21.3084C22.4517 21.1212 22.5941 20.8973 22.6859 20.6514C22.7777 20.4055 22.8167 20.1431 22.8005 19.8811C22.8159 19.6183 22.7759 19.3552 22.6831 19.1089C22.5902 18.8626 22.4467 18.6385 22.2617 18.4512C22.0767 18.264 21.8544 18.1177 21.6092 18.0219C21.364 17.9261 21.1014 17.8829 20.8385 17.8951C20.4692 17.8694 20.1007 17.9544 19.78 18.1393C19.4593 18.3242 19.2011 18.6006 19.0385 18.9331H17.2385L18.1925 12.9331H24.1265V14.5951H19.7465L19.2965 17.1451C19.8213 16.706 20.4841 16.466 21.1685 16.4671C23.5325 16.4671 24.6845 18.0631 24.6845 19.8811Z"
                fill="black"/>
            <path
                d="M18.0008 4.99512L16.9028 5.83512L18.0008 6.79512C20.2555 6.79512 22.4596 7.46372 24.3343 8.71636C26.209 9.96901 27.6702 11.7494 28.533 13.8325C29.3958 15.9156 29.6216 18.2078 29.1817 20.4191C28.7419 22.6305 27.6561 24.6618 26.0618 26.2561C24.4675 27.8505 22.4362 28.9362 20.2248 29.3761C18.0134 29.8159 15.7213 29.5902 13.6382 28.7273C11.5551 27.8645 9.77468 26.4033 8.52203 24.5286C7.26938 22.6539 6.60078 20.4498 6.60078 18.1951H4.80078C4.80078 20.8058 5.57495 23.3579 7.02538 25.5286C8.47582 27.6994 10.5374 29.3913 12.9494 30.3903C15.3613 31.3894 18.0154 31.6508 20.576 31.1415C23.1365 30.6322 25.4885 29.375 27.3346 27.5289C29.1806 25.6829 30.4378 23.3309 30.9471 20.7703C31.4565 18.2098 31.1951 15.5557 30.196 13.1437C29.1969 10.7317 27.505 8.67015 25.3343 7.21972C23.1636 5.76928 20.6115 4.99512 18.0008 4.99512Z"
                fill="black"/>
            <path
                d="M17.1416 5.86531L20.0696 2.94931L18.8096 1.69531L15.8876 4.61131L15.5156 4.97731V6.75331L15.8876 7.11931L18.8096 10.0353L20.0696 8.78131L17.1416 5.86531Z"
                fill="black"/>
        </svg>


    )
}

export default SkipBackwardIcon;
