import React from 'react';
import { withRouter } from 'react-router-dom';
import { TitleXl, BodySm, Row, Col4 } from 'components/elements';
import { OtherNewsLettersWrapper, TitleXlContainer, BodySmContainer } from './elements';
import Newsletters from 'components/Newsletters';

function ExploreOtherNewsletters({ cards, header }) {
  const title = header?.elements?.[0]?.text.content[0]?.content[0]?.value || '';
  const subtitle = header?.elements?.[1]?.text.content[0]?.content[0]?.value || '';
  
  return (
    <OtherNewsLettersWrapper>
      <TitleXlContainer>
        <TitleXl color="#000" fontWeight="900">{title}</TitleXl>
      </TitleXlContainer>
      <BodySmContainer>
        <BodySm color="#000">{subtitle}</BodySm>
      </BodySmContainer>
      <Row>
        {cards?.elements?.map((card) => (
          <Col4 key={card.id}>
            <Newsletters
              link={card.ctaLink}
              title={card.title}
              intro={card.summary}
              image={card.image}
            />
          </Col4>
        ))}
      </Row>
    </OtherNewsLettersWrapper>
  );
}

export default withRouter(ExploreOtherNewsletters);
