import React from 'react';

function Solutions() {
    return (
        <div className="solutions pageWrapper">
            <h1>Solutions Page!</h1>
        </div >
    );
}

export default Solutions;
