import styled, { css } from 'styled-components';
import {
    device,
    sizeMobileLg,  
    sizeMobileSm,
    sizeMobileXsXs

} from "components/devices";
import { TitleXl, TitleSm, TitleMd, BodySm} from 'components/elements';


export const CircleContainer = styled.div`
    width: auto;
    height: auto;
    z-index: -10;
    position: relative;

`

export const HeaderBanner = styled.div`
    position: absolute;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 24px;
    font-weight: 700;
    text-transform: uppercase;
    line-height: 120%;
    top: 0;
    margin-top: -8px;
    margin-left: 12px;
    padding: 15px 20px;
    text-align: center;
    background-color: ${({backgroundColor}) => backgroundColor};
    ${sizeMobileLg(`
        padding: 15px 20px;
        font-size 16px;
`)}
`

export const Circle = styled.div`
    z-index: -5;
    width:  ${({dimensionsDesktop}) => dimensionsDesktop};
    height:  ${({dimensionsDesktop}) => dimensionsDesktop};
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 12px solid;
    border-color: ${({borderColor}) => borderColor};

${props => props.dimensionsMobile && css`
    @media only screen and (max-width: ${device.mobileLg}) {
        width: ${props.dimensionsMobile}; 
        height: ${props.dimensionsMobile}; 
        border: ${props.mobileBorder};
    }
`}

`
export const CopyContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    max-width: 80%;
    width: 100%;
    height: 80%;
    position: relative;
    ${sizeMobileLg (`
         max-width: 90%;
         height: 85%;
    `)}
`

export const CopyContainerPurple = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    max-width: 80%;
    width: 100%;
    height: 90%;
    position: relative;
    ${sizeMobileLg (`
        justify-content: space-between;
        height: 100%;
        padding: 10px 0 ;
    `)}
    ${sizeMobileSm (` 
        padding: 10px 20px;
    `)}
`



export const TopContentContainer = styled.div`
    z-index: 2;
    width: 55%;
    text-align: center;
    ${sizeMobileLg (`
        width: 100%;
    `)}
    ${TitleMd}{
        font-size: 72px;
        color: #fff;
        line-height: 84px;
        font-weight: 700;
        ${sizeMobileLg (`
            font-size: 38px;
            line-height: 42px;
        `)}
        ${sizeMobileXsXs(`
           font-size: 40px;
           line-height: 48px;
        `)}
    } 
    ${TitleSm}{
        font-size: 18px;
        line-height: 21px;
        font-weight: 700;
        color: #fff;
        ${sizeMobileLg (`
            font-size: 16px;
            line-height: 19px;
        `)}
        ${sizeMobileXsXs(`
          font-size: 16x;
         line-height: 19px;
     `)}
    }
`

export const CenterContentContainer = styled.div`
    padding: 10% 0;
    display: flex;
    justify-content: center;
    ${sizeMobileLg (`
         padding: 0 0;
    `)}
    .logo {
        ${sizeMobileLg (`
            width: 70%;
        `)}
    }
`

export const BottomContentContainer = styled.div`
    display: flex;
    justify-content: center;
    padding: 0 0 10px;
    text-align: center;
    ${TitleSm}{
        font-size: 31px;
        color: #fff;
        ${sizeMobileLg(`
            font-size: 26px;
        `)}
    }
    ${sizeMobileXsXs(`
       font-size: 28px;
       line-height: 30px;
 `)}
`

export const TitleContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    ${TitleXl}{
        color: ${({color}) => color};
        font-size: ${({titleSize}) => titleSize};
        font-weight: 700 !important;
        line-height: ${({titleLineH}) => titleLineH};
        text-transform: ${({textTransform}) => textTransform || 'none'};
     
        ${sizeMobileLg(`
            font-size: 18px;
            line-height: 18px;
            padding: 5px 0 0;
        `)}
        ${sizeMobileSm(`
            font-size: 16px;
            line-height: 19px;
            padding: 5px 15px 0;
        `)}
        ${sizeMobileXsXs(`
            font-size: 14px;
            line-height: 1.2;
            padding: 5px 25px 0;

        `)}
    }
    ${TitleSm}{
        color: #fff;
        font-size: 22px;
        text-transform: uppercase;
    }
`

export const BodyContainer = styled.div`
    text-align: center;
    padding: 24px 0 0;
    ${sizeMobileLg(`
        padding: 30px 0 0;
    `)}
    ${sizeMobileSm(`
        padding: 30px 15px 5px;
    `)}
${BodySm}{
    font-size: 20px;
    line-height: 24px;
    color: #000;
    font-weight: 400;
    ${sizeMobileLg(`
        font-size: 16px;
        line-height: 18px;
    `)}
}
`