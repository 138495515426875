import React from 'react';
import { useEffect } from 'react';
import Slider from "react-slick";
import {
    CardContainer,
    CultureWrap,
    Slide

} from "./elements";


// Slick Slider
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import RichText from 'components/RichText';

function Carousel({content}) {


   
    //  Slider
    const settings = {
        infinite: false,
        centerSpacing: "0",
        margin: "10px",
        slidesToShow: 1,
        autoplay: false,
        speed: 1000,
        slidesToScroll: 1,
        useTransform:false,
        onInit: ()=>{
            console.log("on init");
        },
        dots: false,
        navigation: true,
        swipeToSlide: true,
        responsive: [
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1,
                }
            },
        ]
    };

    useEffect(() => {
        const track = document.querySelector('.slick-track');
        track.style.left = '0';
        const resizeListener = () => {
            var currentSlide = track.getElementsByClassName('slick-current');
            if(currentSlide[0].dataset.index === 0) {
                setTimeout(()=>{
                    const track = document.querySelector('.slick-track');
                    track.style.left = '0';
                },500);
            }
        };
        // set resize listener
        window.addEventListener('resize', resizeListener);
    }); 


    return (
        <CultureWrap id={'culture'} className={'culture-wrap'}>
            <CardContainer>
                <Slider {...settings}>
                {content?.map((item, index) => (
        
                        <Slide key={index}>
                            <RichText options={{fontSize: '36px', color: '#000', fontFamily: 'HelveticaNowDisplay Black' }} body={item.text}/>

                        </Slide>
                    
                ))}
                </Slider>
            </CardContainer>
        </CultureWrap>
    );
}

export default Carousel;
