import React from 'react';

import {
    Caption
} from "components/elements";

import {
    BlogHeroWrap,
    BlogHeroImg,
    BlogHeroImgMobile
} from "./elements";


function BlogHero({ image, caption, mobileImage }) {
    return (
        <BlogHeroWrap>
            <BlogHeroImg src={image} />
            <BlogHeroImgMobile src={mobileImage} />
            <Caption>{caption}</Caption>
        </BlogHeroWrap>
    );
}

export default BlogHero;
