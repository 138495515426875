
import React from 'react'
import { callApi } from 'config/callApi'
import { r3cRssFeed } from 'constants/index'

function RssFeed()
{
	const [ feedData, setFeedData ] = React.useState( null )

	React.useEffect( () =>
	{
		callApi( r3cRssFeed, 'GET' ).then( result => { setFeedData( result.data ) } )
	}, [feedData] )

	return feedData
}

export default RssFeed

