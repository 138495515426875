import React, { useState, useEffect } from 'react';
import {
    MobileBigRectItem,
    MobileSmallRectItem,
    ArrowWrap,
    ItemContainer,
    ContentContainer,
    MobileYear,
    ItemsWrap,
    MobileRowWrap,
} from './elements';
import { ReactComponent as Arrow } from './svg/arrow.svg';

export const ContentItem = ({ year, data, setIsShown, setItemIndex }) => {
    const [bigArr, setBigArr] = useState([]);
    const [smallArr, setSmallArr] = useState([]);

    useEffect(() => {
        const arr1 = [];
        const arr2 = [];

        // eslint-disable-next-line
        data.map((item, index) => {
            if (item.title_year.toString() === year.year) {
                if (item.size === 'big') {
                    arr1.push(item);
                } else {
                    arr2.push(item);
                }
            }

            if (item.no === data.length - 1) {
                setBigArr([...arr1]);
                setSmallArr([...arr2]);
            }
        });
    // eslint-disable-next-line
    }, []);

    const handleClick = (item) => {
        setIsShown(true);
        setItemIndex(item.no);

        // lock scroll
        window.scrollBy(0, 100);
        document.body.style.overflow = 'hidden';
    }
    
    return (
        <ContentContainer isLast={year.year.toString() === 2021 ? true : false}>
            <MobileYear isItem={year.item}>{year.year}</MobileYear>
            <ItemsWrap>
                {
                    bigArr.map((rectItem, index) => (
                        <MobileRowWrap key={index + rectItem.title}>
                            <ItemContainer onClick={() => handleClick(rectItem)}>
                                <MobileBigRectItem
                                    color={rectItem.color}
                                    dangerouslySetInnerHTML={{ __html: rectItem.title.replace('<br>', ' ') }}
                                />
                                <ArrowWrap  className='big-rect-arrow'>
                                    <Arrow />
                                </ArrowWrap>
                            </ItemContainer>
                        </MobileRowWrap>
                    ))
                }
                
                {
                    smallArr.map((rectItem, index) => (
                        index % 2 === 0 && <MobileRowWrap key={index + rectItem.title}>
                            <ItemContainer onClick={() => handleClick(data[rectItem.no])} style={{width: 'calc(50% - 5px)'}} isRight={false}>
                                <MobileSmallRectItem
                                    color={data[rectItem.no].color}
                                    dangerouslySetInnerHTML={{ __html: data[rectItem.no].title.replace('<br>', ' ') }}
                                />
                                <ArrowWrap>
                                    <Arrow />
                                </ArrowWrap>
                            </ItemContainer>
                            <ItemContainer onClick={() => handleClick(data[rectItem.no + 1])} style={{width: 'calc(50% - 5px)'}} isRight={true}>
                                <MobileSmallRectItem
                                    color={data[rectItem.no + 1].color}
                                    dangerouslySetInnerHTML={{ __html: data[rectItem.no + 1].title.replace('<br>', ' ') }}
                                />
                                <ArrowWrap>
                                    <Arrow />
                                </ArrowWrap>
                            </ItemContainer>
                        </MobileRowWrap>
                    ))
                }
                
            </ItemsWrap>
            {/* {
                data.map((item, index) => {
                    if (item.title_year == year.year) {
                        if (item.size === 'big') {
                            return <MobileRowWrap key={index}>
                                <ItemContainer key={index} onClick={() => handleClick(item)}>
                                    <BigRectItemMobile
                                        color={item.color}
                                        dangerouslySetInnerHTML={{ __html: item.title.replace('<br>', '') }}
                                    />
                                    <ArrowWrap>
                                        <Arrow />
                                    </ArrowWrap>
                                </ItemContainer>
                            </MobileRowWrap>
                        } else {
                            return <MobileRowWrap key={index}>
                                {
                                    item.position === 'top' && (
                                        <>
                                            <ItemContainer key={index + item.title} onClick={() => handleClick(item)}>
                                                <SmallRectItemMobile
                                                    color={item.color}
                                                    dangerouslySetInnerHTML={{ __html: item.title.replace('<br>', '') }}
                                                />
                                                <ArrowWrap>
                                                    <Arrow />
                                                </ArrowWrap>
                                            </ItemContainer>
                                            <ItemContainer key={index + data[item.no + 1].title} onClick={() => handleClick(data[item.no + 1])}>
                                                <SmallRectItemMobile
                                                    color={data[item.no + 1].color}
                                                    dangerouslySetInnerHTML={{ __html: data[item.no + 1].title.replace('<br>', '') }}
                                                />
                                                <ArrowWrap>
                                                    <Arrow />
                                                </ArrowWrap>
                                            </ItemContainer>
                                        </>
                                    )
                                }
                            </MobileRowWrap>
                        }
                    }
                })
            }
            */}
        </ContentContainer>
    );
}

export default ContentItem;