
import React from 'react'
import DoubleWide from 'components/ArticleCards/DoubleWide'

import {
	AboutWrapper,
	AboutRow,
	ColLeft,
	ColRight,
	ImageWrap,
	ImgScroll,
	ColLeftInnner,
	ImgScrollMob
} from './elements'

import InfoCard from './InfoCard'
import { ScrollStoper, TitleXl, TitleContainer } from '../elements'

function ParallaxSection(props) {
	const [leftData, setLeftData] = React.useState(null)
	const [rightData, setRightData] = React.useState(null)
	// eslint-disable-next-line

	React.useEffect(() => {
		if (props?.parallaxSectionData) {
			let psd = props.parallaxSectionData

			if (psd.leftColumnContent)
				setLeftData(psd.leftColumnContent)
			if (psd.rightColumnContent)
				setRightData(psd.rightColumnContent)
		}
		// eslint-disable-next-line
	}, [props?.parallaxSectionData])

	const renderItem = (item, index, position, type) => {
		if (item.ctype === 'articles') {
			const aDate = ((item.authors?.length > 0) ? item.authors[0].name + ' / ' : '') + item.date

			return <DoubleWide
				key={'dw-' + index}
				image={item.image}
				mobileImage={item.mobileImage}
				link={item.detailLink}
				height="1000px"
				borderTop="none"
				borderLeft="none"
				borderRight="none"
				label={item.label}
				date={aDate}
				description={item.title}
				ctaText={item.ctaText}
				type={type}
			/>
		}

		if (item.ctype === 'cards') {
			return <InfoCard
				key={index}
				label={item.label}
				title={item.title}
				subTitle={item.summary}
				description={item.detail}
				ctaText={item.ctaText}
				ctaLink={item.ctaLink}
				mobileImg={item.mobileImg}
			/>
		}

		if (item.ctype === 'image') {
			if (position === "right")
				return <ImgScroll key={'paraimg-' + index} src={item.imageAsset}></ImgScroll>
			if (position === "left" && item?.name?.includes('mobile'))
				return <ImgScrollMob key={'paraimg-' + index} src={item.imageAsset}></ImgScrollMob>
		}
	}
	return (
		<AboutWrapper>
			{
				props.title ?
					<TitleContainer style={{ borderBottom: '1px solid #fff' }}>
						<TitleXl>{props?.title ? props.title : ''}</TitleXl>
					</TitleContainer>
					: ''
			}
			<AboutRow className="parallaxRow" >
				<ColLeft isHomePage={window.location.pathname === '/' ? true : false} className="parallaxLeftDiv">
					<ColLeftInnner>
						<div>
							{
								leftData && leftData.map((item, index) => { return renderItem(item, index, "left") })
							}
						</div>
					</ColLeftInnner>
					<ScrollStoper className="scrollStoper" />
				</ColLeft>
				<ColRight className="parallaxRightDiv">
					<ImageWrap >
						<div>
							{
								rightData && rightData.map((item, index) => { return renderItem(item, index, "right") })
							}
						</div>
					</ImageWrap>
				</ColRight>
			</AboutRow>
		</AboutWrapper>
	)
}

export default ParallaxSection

