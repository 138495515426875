import React, {useState, useEffect} from 'react'
import styled from 'styled-components'
import {BodyRg, TitleMd, TitleSm} from "./elements";
import PlusIconSvg from "../assets/icons/PlusIconSvg";
import { sizeLaptop, sizeMobileLg, sizeTabletLg} from "./devices";
import RichText from './RichText';
import { Link } from 'react-router-dom';

export const DaHeader = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  width: 93%;
  margin: 0 auto;
  border-top: 1px solid #D6D6D6;
  min-height: 80px;
  height: 100%;
  padding: 15px 0 0;
  transition: 0.35s all ease;

  ${sizeTabletLg (`
    width: 100%;
    padding: 15px 0 20px;
    height: auto;
`)}

  .plus-icon {
    cursor: pointer;
    path {
      stroke: #D12FFF;
    }
  }

`;

export const HeaderLink = styled(Link)`
max-width: 80%;
${TitleMd}{
  font-size: 24px !important;
  line-height: 28px !important;
  font-weight: 400;

  ${sizeLaptop(`
      font-size: 20px !important;
      line-height: 24px !important;
 `)}

}



&.active {
  &:hover {
    ${TitleMd}{
      color: #D12FFF !important;
      text-decoration: underline;
    }
  }
}

&.notActive {
  cursor: default !important;
  ${TitleMd}{
    cursor: default !important;
  }
}


`
export const DaBody = styled.div`
  max-height: 0;
  overflow: hidden;
  transition: max-height .75s ease-in-out;
  padding: 5px 12% 0 5%;
  p {
    padding: 10px 0 0 !important;
  }

  ${sizeTabletLg(`
    padding: 5% 0 0 0;
  `)}
  ${BodyRg}{
    font-size: 16px;
    line-height: 19px;

    a{
      color: #D12FFF !important;
      text-decoration: underline;
    }

  }

  h5 {
    font-size: 20px;
    line-height: 1.2;
  }
  
  h4 {
    font-size: 22px;
    line-height: 1.2;
  }

  h3 {
    font-size: 24px;
    line-height: 1.2;
  }

  ul{
    list-style-type:square; 
    color: #000;
    float: left;
    width: 100%;
    font-size: 12px;
    line-height: 1.2;
    padding: 10px 0 15px 0 !important;
    margin: 0 0 0 25px !important;
  li {
    margin: 0 0 0 0 !important;
    p {
      line-height: 1.3 !important;
      padding: 0 0 0 0 !important;
    }
  }
}
ol {
  color: #000;
  float: left;
  width: 100%;
  font-size: 14px;
  line-height: 1.2;
  padding: 10px 0 15px 0 !important;
  margin: 0 0 0 25px !important;
  li {
    margin: 0 0 0 0 !important;
    p {
      line-height: 1.3 !important;
      padding: 0 0 0 0 !important;
    }
  }
}
`;



export const ListItem = styled.li`
  color: #000000;
  margin-left: 2em;
  line-height: 1.7 !important;
  ${TitleSm}{
    font-size: 16px !important;
    padding-left: 3px;
    a {
      color: #000 !important;
    }
  }

`



export const DarkAccordionBlock = styled.div`
  padding: 0 0 15px;
  
  &:last-child {
    margin-bottom: 0 !important;
  }

  ${sizeLaptop(`
    padding: 0 25px;
  `)}
  ${sizeTabletLg(`
     padding: 20px 15px 0;

  `)}
  ${sizeMobileLg(`
    padding: 20px 6px 0 ;
  `)}


  .plus-icon {
    width: 24px;
    height: 24px;
    transition: 0.35s all ease;
    transform: rotate(0);
    path {
      stroke: #D12FFF;
    }
  }

  &.show {
    ${DaBody} {
      max-height: 650px;
      transition: max-height .75s ease-in-out;
    }

    .plus-icon {
      transform: rotate(45deg);
    }

    ul {
      list-style-type:square; 
      color: #000;
      float: left;
      width: 100%;
      font-size: 12px;
      padding: 10px 0 15px 0 !important;
      margin: 0 0 0 25px !important;
      ${sizeMobileLg(`
        margin: 0 0 0 20px !important;
        padding: 10px 20px 15px 0 !important;
      `)}
      li {
        margin: 0 0 0 0 !important;
        p {
          line-height: 1.3 !important;
          padding: 0 0 0 0 !important;
        }
      }
    }

    ol {
      color: #000;
      float: left;
      width: 100%;
      font-size: 16px;
      padding: 10px 0 15px 0 !important;
      margin: 0 0 0 25px !important;
      li {
        margin: 0 0 0 0 !important;
        p {
          line-height: 1.3 !important;
          padding: 0 0 0 0 !important;
        }


      }
    }
  }
`;


function OurOfferingsAccordian({offeringsData}) {
    const [acc, setAcc] = useState(false);
    const [titleLink, setTitleLink] = useState(false)

    const handleAcc = () => {
        setAcc(!acc);
    } 

    useEffect(() =>  {
      offeringsData?.elements[0]?.ctaLink ? setTitleLink(true) : setTitleLink(false)
    },[offeringsData])  

    return (
        <DarkAccordionBlock className={acc ? 'show' : ''} >
            <DaHeader  >
               <HeaderLink className={titleLink ? 'active' : 'notActive'} target="_blank" to={offeringsData?.elements[0].ctaLink ? { pathname: offeringsData?.elements[0].ctaLink }: '#'}>
                 <TitleMd fontWeight={400}  >{offeringsData?.elements[0]?.title}</TitleMd>
               </HeaderLink>
                <div style={{display: 'inline-block'}} onClick={handleAcc}>
                  <PlusIconSvg className={'plus-icon'}/>
                </div>
            </DaHeader>
            <DaBody>
                {offeringsData?.elements[0]?.detail.content.map((item) => (
                  <RichText body={item}/>
                ))}
           
            </DaBody>
        </DarkAccordionBlock>
    )
}

export default OurOfferingsAccordian;
