import React from 'react'


function ReleaseIcon() {

    return (
        <svg xmlns="http://www.w3.org/2000/svg" id="Icons" viewBox="0 0 48 48">
            <path d="M12,31.5H4L2.5,30V18L4,16.5h8L13.5,18V30Zm-6.5-3h5v-9h-5Z"/>
            <path
                d="M34.05,44.16,23.46,35.5H12L10.5,34V14L12,12.5H23.46L34.05,3.84,36.5,5V43ZM13.5,32.5H24l.95.34,8.55,7V8.17l-8.55,7L24,15.5H13.5Z"/>
            <rect x="41.53" y="22.5" width="4.47" height="3"/>
            <rect x="40.5" y="33.96" width="3" height="4.42" transform="translate(-8.55 58.9) rotate(-64.94)"/>
            <rect x="39.79" y="10.33" width="4.42" height="3" transform="translate(-1.06 18.9) rotate(-25.06)"/>
        </svg>

    )
}

export default ReleaseIcon;
