import React from 'react';
import { withRouter } from "react-router-dom"
import { TitleXl,BodySm, Row, Col6, Col4, Col8, BodyRg } from "components/elements";
import { AboutUsTopSectionContainer, TitleXlContainer, FlexContainer } from './elements';
import ViewAllAnchor from "components/ViewAllAnchor";
import RichText from 'components/RichText';

function AboutUsTopWhiteSection({ content, width, image }) {

  return (
    <AboutUsTopSectionContainer>
      <TitleXlContainer width={width}>
        <TitleXl fontWeight="900">{content.title}</TitleXl>
      </TitleXlContainer>
      <FlexContainer>
        <Row>
          <Col8>
            <img src={content.columnMedia} alt={""} />
          </Col8>
          <Col4>
            <RichText body={content?.columnCopy && content?.columnCopy} />
            <ViewAllAnchor
              title={content.ctaCopy}
              href={content.ctaLink ? content.ctaLink : content.ctaPdfFile}
              target={content.ctaLink ? '_self' : '_blank'}
              borderColor="#000"
              borderColorHover="#fff"
              titleColor="#000"
              hoverBackground="#000"
              width="300px"
              height="44px"
              svgColor="#000"
            />
            {image?.imageAsset &&
              <Row style={{display:'flex',justifyContent:'start'}} >
                  <img src={image?.imageAsset} alt={image?.alt} />
                  <BodyRg> A proud participant of the UN Global Compact </BodyRg>
              </Row>
            }

          </Col4>
        </Row>
      </FlexContainer>
    </AboutUsTopSectionContainer>
  )
}

export default withRouter(AboutUsTopWhiteSection);
