import { TitleXl } from "../../components/elements";
import { sizeWideScreen, sizeMobileMd, sizeMobileSm } from 'components/devices';
import styled from 'styled-components'



export const AccordionWrapper = styled.div`
  border-bottom: 1px solid #707070;
  margin-bottom: -1px;
    ${sizeWideScreen(`
        border-left: 1px solid #707070;
        border-right: 1px solid #707070;
    `)}
`;

export const RelevantTitle = styled.div`
  ${TitleXl} {
    ${sizeMobileMd(`
        font-size: 2.25em;
    `)}
    ${sizeMobileSm(`
        font-size: 2em;
    `)}
  }
`;
