import React from "react";

import { SectionTitle, TitleXl, BodySm, BodyRg, Bold } from "components/elements";

import { PrivacyInfoWrap, PvInfoContainer, PrivacyLink } from "./elements";

function PrivacyInfoSection({ handleToggleOtherAccordion, date, title }) {
  return (
    <PrivacyInfoWrap className="acc-cont">
      <PvInfoContainer>
        <SectionTitle textAlign="center">
          <BodySm textTransform="uppercase">Effective Date: {date}</BodySm>
          <TitleXl>{title}</TitleXl>
        </SectionTitle>
        <BodyRg>
          Real Chemistry and our subsidiaries (collectively, “
          <Bold>Real Chemistry,</Bold>” or “we” – see our <PrivacyLink
            className="internal-page-link"
            href="#subsidiaries"
            onClick={handleToggleOtherAccordion.bind(
              this,
              "subsidiaries",
              true
            )}
          >subsidiaries</PrivacyLink> below) take privacy seriously and want you to be familiar with how we
          collect, use and disclose data. This Privacy Policy describes our
          practices regarding information that we or third-party service
          providers collect through certain of our owned or controlled websites
          and web properties including mobile versions of these websites
          (collectively, the <Bold>Properties</Bold>) and through the provision
          of our market research and insight services to our clients (“
          <Bold>Research Services</Bold>”). By using the Properties, you agree
          to the terms and conditions of this Privacy Policy.
        </BodyRg>
        <BodyRg>
          This Privacy Policy does not address, and we are not responsible for,
          the privacy, information or practices of any third party. In addition,
          the inclusion of a link on any Property does not imply endorsement of,
          or affiliation with, the linked site or Property by us or our
          affiliates. Please note that the Properties may include the ability to
          access and submit (including, for example, via a third-party software
          application or service that is available through or in connection with
          a Property) certain information directly to third parties. Read other
          companies’ privacy policies carefully. We are not responsible for
          these third parties.
        </BodyRg>
        <BodyRg>
          <Bold>These Properties are Not Intended for Children:</Bold> Our sites
          are meant for adults. We do not knowingly collect personally
          identifiable information from children under 13 without permission
          from a parent or legal guardian. If you are a parent or legal guardian
          and think your child under 13 has given us information, you can contact us via the directions in the <PrivacyLink
            className="internal-page-link"
            href="#contactUs"
            onClick={handleToggleOtherAccordion.bind(
              this,
              "contactUs",
              true
            )}
          >Contacting Us</PrivacyLink>  section below.
          Please mark your inquiries “COPPA Information Request.” Parents, you
          can learn more about how to protect children’s privacy on-line{" "}
          <PrivacyLink
            target="_blank"
            href="https://www.consumer.ftc.gov/articles/0031-protecting-your-childs-privacy-online"
          >
            here
          </PrivacyLink>
          .
        </BodyRg>
      </PvInfoContainer>
    </PrivacyInfoWrap>
  );
}

export default PrivacyInfoSection;
