
import React from 'react'
import RichText from 'components/RichText'
import { QuoteWrap, QuoteInner } from './elements'
import { TitleXl, BodyRg } from 'components/elements'

function QuoteCard({ borderTop, borderRight, borderBottom, borderLeft, height, padding, description, label })
{
	return (
	    <QuoteWrap
		    borderTop={borderTop}
		    borderRight={borderRight}
		    borderBottom={borderBottom}
		    borderLeft={borderLeft}
		    height={height}
		    padding={padding}
		>
		    <QuoteInner>
			<TitleXl>“</TitleXl>
			<RichText body={description}/>
			<br/>
			<BodyRg>&mdash;&nbsp;&nbsp;{label}</BodyRg>
		    </QuoteInner>
	    </QuoteWrap>
	)
}

export default QuoteCard

