import React from 'react'
import RichText from '../RichText'

import {
    InfoCardOuter,
    InfoCardWrap,
    InfoColTop,
    InfoColBottom,
    InfoImgWrap,
    InfoImg,
    Link
} from "./elements";

import {
    BodySm,
    TitleMd,
    TitleXs,
} from "../elements";

function InfoCard(props) {
    return (
        <InfoCardOuter className="parallex card">
            {
                <InfoCardWrap>
                    {
                        props.title || props.label ? <InfoColTop>
                            <BodySm textTransform="uppercase" fontWeight="500">{props.label}</BodySm>
                            <TitleMd textTransform="uppercase">{props.title}</TitleMd>
                        </InfoColTop> : ""
                    }
                    <InfoColBottom>
                        {
                            props.subTitle && <TitleXs>{props.subTitle}</TitleXs>
                        }
                        {
                            props.description && <RichText body={props.description} />
                        }
                        {props.ctaText && <Link href={props.ctaLink}>{props.ctaText}</Link>}
                    </InfoColBottom>
                </InfoCardWrap>
            }
            {
                props.mobileImg && <InfoImgWrap>
                    <InfoImg src={props.mobileImg} />
                </InfoImgWrap>
            }
        </InfoCardOuter>
    )
}

export default InfoCard
