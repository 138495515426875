import styled from 'styled-components';
import {
    BodySm,
    TitleXl,
    TitleMd,
    BodyRg,
    TitleIcon,
    OverlayBase, 
    Col, 
    Row
} from "components/elements";
import {
    sizeTabletLg,
    sizeLaptop, sizeLaptopLg, sizeDesktopMd,  sizeTablet, sizeMobileLg, sizeLaptopMd
} from "components/devices";


export const JobsSectionWrap = styled.div`
  padding: 20px 0 85px 0;
  overflow: hidden;
  ${BodySm} {
    margin-bottom: 25px;
    font-size: 16px;
    line-height: 19px;
    ${sizeTabletLg(`
      font-size: 15px;
      line-height: 20px;
      margin-bottom: 20px;
  `)}
  }

  ${TitleXl} {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    font-size: 48px;
    line-height: 57px;
    font-weight: 900;
    ${sizeTabletLg(`
        font-size: 36px;
        line-height: 43px;
    `)}
  }
  ${TitleMd} {
    font-size: 36px;
    line-height: 43px;
    font-weight: 400;
    padding: 16px 0;
    ${sizeTabletLg(`
        font-size: 20px;
        line-height: 24px;
    `)}
  }
  ${BodyRg} {
    font-size: 16px;
    line-height: 19px;
    font-weight: 400;
    max-width: 55%;
    &:last-of-type {
      padding: 20px 0 0;
    }
    ${sizeTabletLg(`
      max-width: 100%;
  `)}
   
  }

`;
export const JobsTitleWrap = styled.div`
  padding: ${({padding}) => padding || "50px 104px"};
  border-top: ${({borderTop}) => borderTop || "1px solid #707070"};

  ${sizeDesktopMd(`
      padding: 26px 104px;
    `)}
  ${sizeLaptop(`
        padding: 35px 25px !important;
    `)}
    ${sizeMobileLg(`
      padding: 35px 15px !important;
  `)}
  ${TitleIcon} {
    ${sizeLaptopLg(`
            width: 2.69em;
            height: 2.69em;
    `)}
  }

`;
export const JobsTitleContainer = styled.div`
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: end;
  -ms-flex-align: end;
  align-items: flex-end;

`;

export const DividerLg = styled.div`
  display: block;
  height: 1px;
  width: -webkit-calc(100% - 60px);
  width:    -moz-calc(100% - 60px);
  width:         calc(100% - 60px);
  background-color: #000;
  margin: 0 auto;
  ${sizeTabletLg(`
  width: -webkit-calc(100% - 30px);
  width:    -moz-calc(100% - 30px);
  width:         calc(100% - 30px);
  `)}
`;

export const Divider = styled.div`
  display: block;
  height: 1px;
  width: 329px;
  background-color: #000;
  margin-left: 30px;
  ${sizeLaptop(`
    width: 300px;
  `)}
  ${sizeLaptopMd(`
    width: 280px;
  `)}
  ${sizeTabletLg(`
    width: 250px;
    margin-left: 15px !important;
    `)}
  ${sizeMobileLg(`
        width: 225px;
        margin-left: 15px !important;
  `)}
`;


export const Disclaimer = styled.div`
  position: relative;
  margin-bottom: 35px;
  padding: 24px 24px;
  background: #A036F5;
`;

export const TabBtns = styled.div`
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding: 0 0 0 30px;
`;
export const TabBtn = styled.button`
  padding: 0;
  font-size: inherit;
  color: #000;
  border: none;
  background-color: transparent;
  text-transform: uppercase;
  cursor: pointer;
  padding: 0 15px;
  opacity: 0.5;

  &:hover {
    color: #D12FFF;
    opacity: 1;
  }

  &.active {
    opacity: 1;
  }

  &:focus {
    outline: none;
    box-shadow: none;
  }

`;


export const JobsMobile = styled.div`
  border-top: 1px solid #707070;
  display: none;
  ${sizeTabletLg(`
        display: block;
    `)}
`;
export const JobsRow = styled.div`
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding: 26px 0 0 30px;
  ${sizeDesktopMd(`
      padding: 0 0 0 30px;
    `)} /* width: 2400px; */ ${sizeTabletLg(`
        width: 100%;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
        padding: 0;
    `)}
`;
export const Tabs = styled.div`

`;
export const TabsList = styled.div`
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  border: 1px solid #707070;
  ${sizeTabletLg(`
        -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    `)}
`;
export const TabsLink = styled(OverlayBase)`
  width: 100%;
  border: none;
  background: #EAEAEA;
  height: 100px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  border-right: 1px solid #707070;
  cursor: pointer;
  font-size: 16px;
  text-transform: uppercase;
  font-weight: 600;
  color: rgba(0, 0, 0, 0.3);
  position: relative;
  z-index: 1;
  overflow: hidden;

  &:last-child {
    border-right: none;
  }

  &:hover {
    &:before {
      left: 0;
    }

    color: #fff;
  }

  &.active {
    background-color: #000000;
    color: #fff;

    &:before {
      content: none;
    }
  }

  &:focus {
    outline: none;
    box-shadow: none;
  }

  ${sizeTabletLg(`
        height: 80px;
        &:before {
            content: none;
        }
    `)}
`;


export const TabContents = styled.div`
  ${sizeTabletLg(`
        padding: 30px 15px 0 15px;
  `)}
`;
export const TabsContent = styled.div`
  transition: opacity .15s linear;

  &.hide-contents {
    display: none;
  }

  &.show-contents {
    display: block;
  }

  &.fade {
    transition: opacity .15s linear;
  }
`;


// Jobs  New Section
export const JobsGrid = styled.div`

  .slick-slider {
    padding-top: 120px;
    ${sizeTablet(`
        padding-top: 100px;
    `)}
  }
  .slick-slide {
    height: 500px;
    &.slick-active.slick-current {
      height: auto;
    }
  }
  
  .slick-dots {
    top: 0;
    bottom: auto;
    margin-bottom: 40px;
    li {
      width: 100%;
      max-width: 350px;
      height: auto;
      ${sizeTablet(`
        max-width: 50%;
        margin: 0;
      `)}
      button {
        width: 100%;
        height: 80px;
        background: transparent;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        border: none;
        cursor: pointer;
        font-size: 36px;
        text-transform: uppercase;
        font-weight: 400;
        color: #000;
        position: relative;
        z-index: 1;
        overflow: hidden;
        padding: 17px 20px;
        border-bottom: 4px solid transparent;
        ${sizeTablet(`
        flex-direction: row !important;
        padding: 0 0 !important;
        &:before { 
            font-size: 20px !important;
            line-height: 24px !important;        
            height: 38px !important;
        }
       `)}

        &:after {
          content: none !important;
        }
        &:before {
          content: "";
          width: 100%;
          height: 100%;
          opacity: 1;
          font-size: inherit;
          font-family: inherit;
          position: relative;
          top: 10px;
          left: 0;
        }
      }
      &.slick-active {
        button {
          border-color:#D12FFF;
          color: #D12FFF;
          &:before {
            color: #D12FFF;

          }
        }
      }
      &:first-child {
        button {
          &:before {
            content: 'Career focus';
          }
        }
      }
      &:nth-child(2) {
        button {
          &:before {
            content: 'location';
          }
        }
      }
    }
  }

`;
export const JobsGridContent = styled.div`
  padding: 0 104px;
  margin: 20px 0 0;
  ${sizeLaptop(`
    padding: 0 25px;
  `)}
  ${sizeMobileLg(`
  padding: 0 15px;
  `)}
  ${Row} {
    margin: 0;
    display: flex !important;
 
  }
  ${Col} {
    padding: 0 15px;
    max-width: 33.333%;
    flex: 0 0 33.333%;
    margin-bottom: 30px;
    ${sizeLaptopMd(`
        max-width: 50%;
        flex: 0 0 50%;
    `)}
    ${sizeTabletLg(`
        max-width: 100%;
        flex: 0 0 100%;
        padding-left: 0;
        padding-right: 0;
    `)}
  }

  .locationRow {
    ${Col}{
      ${sizeMobileLg(`
        flex: 0 0 48% !important;
   `)}
    }
    
  }
`;


export const JobsSectionBottom = styled.div`
  padding: 65px 0 0 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 100%;
  ${BodyRg}{
    font-size: 24px;
    line-height: 27px;
    margin-top: 35px;
    display: block;
    letter-spacing: -0.02em;
    ${sizeTabletLg(`
      margin: 20px 0 0 0;
  `)}
}
  ${sizeTabletLg(`
    padding: 30px 0 0 0;
  `)}
    .magenta {
      text-transform: inherit;
      color: #D12FFF;
      text-decoration: underline;
      margin: 0 5px 0 0;
    }
  }
`;

export const CtaContainer = styled.div`
   padding: 10px 0 0;
`