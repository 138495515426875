import React, { useEffect, useRef, useState } from 'react';

import {
  BodyRg,
  TitleSm,
  WideScreenContainer
} from "components/elements";

import {
  ContactModalWrap,
  ContactModalWrapInner,
  ContactModalVideo,
  HomeModalContainer,
  CloseModal,
  CloseIcon,
  ContactFooter,
  SubmitButton,
  ModalImg,
  ModalImgMobile,
} from "./elements";

var _isMounted = false;
function HomeModal(props) {
  const firstInputRef = useRef(null);
  const contactModalHeight = useRef(null);
  const [isSafari, setIsSafari] = useState(false)
  const [keyboard, setKeyboard] = useState(false)
  const [closeModalPopUp,setCloseModal] = useState(false);

  useEffect(()=>{
    const sessionCheck = sessionStorage.getItem('homeAnnouncementClosed');
    const isClosed = sessionCheck ? JSON.parse(sessionCheck) : false;

    if(props.data.enablePopup){
      if(!isClosed){
        setCloseModal(true);
      }
    }

  },[props.data])

  useEffect(()=>{
    document.onkeydown = function (e) {
       		if (e.keyCode === 27) {
              closeModal()
      		}
      	}
  },[])


  useEffect(() => {

    if (firstInputRef.current && !_isMounted && closeModalPopUp) {
      _isMounted = true;

      if (!isSafari) {
        firstInputRef.current.focus();
      }
    }
    if (!closeModalPopUp) _isMounted = false;

  });

  useEffect(() => {
    let checkSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
    
    setIsSafari(checkSafari)

    if (checkSafari) {
      document.addEventListener('focusin', keyboardAdded);
      document.addEventListener('focusout', keyboardRemoved);
    }

    return () => {

        document.removeEventListener('focusin', keyboardAdded)
        document.removeEventListener('focusout', keyboardRemoved)
    
    }

  }, []);

  const keyboardAdded = () => {
    setKeyboard(true)
  }
  const keyboardRemoved = () => {
    setKeyboard(false)
  }

  const closeModal = () => {
    // setFocus(false)
    setCloseModal(false);
    sessionStorage.setItem('homeAnnouncementClosed',JSON.stringify('true'));
    document.body.className = "";
  }

  const vidRef = useRef(null);
  const [modalHeight, setModalHeight] = useState(window.innerHeight);

  let size = useWindowSize();
  function useWindowSize() {
    const [windowSize, setWindowSize] = useState({
      width: undefined,
      height: undefined,
    });

    useEffect(() => {
      function handleResize() {
        setWindowSize({
          width: window.innerWidth,
          height: window.innerHeight,
        });
      }

      window.addEventListener("resize", handleResize);

      handleResize();

      return () => window.removeEventListener("resize", handleResize);
    }, []);

    return windowSize;
  }

  useEffect(() => {
    setModalHeight(window.innerHeight);
  }, [])
  useEffect(() => {
    setModalHeight(window.innerHeight);
  }, [size.height])

  useEffect(() => {
        vidRef.current.src = "/videos/contact-form-overlay/RealChem_ContactOverlay_1920.mp4";
  }, [size.width])


  return (
    <ContactModalWrap ref={contactModalHeight} modalHeight={modalHeight} show={closeModalPopUp} backgroundImage="/images/contact-form-bg.jpg">
      <ContactModalWrapInner style={{ paddingBottom: `${keyboard ? '250px' : ''}` }} >
        <ContactModalVideo
          height={contactModalHeight && contactModalHeight.current && contactModalHeight.current.offsetHeight}
          loop
          muted
          ref={vidRef}
          playsInline
          autoPlay
          poster="/images/contact-form-bg.jpg">
          src={''}

        </ContactModalVideo>
        <WideScreenContainer>
          <CloseModal onClick={closeModal}>
            <CloseIcon src="/images/close-circle.svg" />
          </CloseModal>
          <BodyRg color="#FFF" textTransform="uppercase">{props.data.introText}</BodyRg>
        </WideScreenContainer>

        <HomeModalContainer>
            <a href={props.data.url} target="_blank" rel="noopener noreferrer">

              {/* <ModalImg src="/images/ceo_homepage_takeover.jpg"></ModalImg>
              <ModalImgMobile src="/images/ceo_mobile_takeover.jpg" ></ModalImgMobile> */}
              <ModalImg src={props.data.deskopImage}></ModalImg>
              <ModalImgMobile src={props.data.mobileImage} ></ModalImgMobile>
            </a>
        </HomeModalContainer>
      
        <ContactFooter>
          <WideScreenContainer>
              <a href={props.data.url}  target="_blank" rel="noopener noreferrer">

              <SubmitButton onClick={closeModal} >
                <TitleSm color="#FFF" textTransform="uppercase">{props.data.ctaText}</TitleSm>
              </SubmitButton>
              </a>
          </WideScreenContainer>
        </ContactFooter>
      </ContactModalWrapInner>
    </ContactModalWrap>
  )
}


export default HomeModal;
