
import React, { useEffect, useRef, useState } from 'react'

import {
	BlogImage,
	BlogText,
	Section,
	Sections,
	DetailWrap,
	BlogWistiaVideo,
	BlogLeftVideo,
	SectionVideo,
	SectionVideoDesc
} from './elements'

import AuthorInfo from '../AuthorInfo'
import RichText from 'components/RichText'

import WistiaPlayer from "../../../components/WistiaPlayer/WistiaPlayer";
import {Caption, TitleMd} from "../../../components/elements";
import BlogSlider from "../BlogSlider";
import WistiaPopupEmbed from "../../../components/WistiaPopupEmbed";
import {Link} from "react-router-dom";
import PodcastBlock from "../../../components/PodcastBlock";

/*
** Sections Data
**
**  [0]
** 	ctype: "region"
** 	name: "BF-Section-1"
** 	placement: "Centered"
** 	elements: Array(2)
** 		0: {cfid: "2EXjVgFYAiBecFMCdh6nxo", name: "Jack", createdAt: "2021-04-30T19:56:06.599Z", ctype: "image", imageAsset: "http…}
** 		1: {cfid: "4ge2Sf66073UtZAU33LDjA", createdAt: "2021-04-30T19:54:09.293Z", text: {…}, ctype: "textArea", updatedAt: "2021-04-30T19:54:09.293Z"}
**  [1]
** 	ctype: "region"
** 	name: "BF-Section-2"
** 	placement: "Left-Justified"
** 	elements: Array(1)
** 		0: {cfid: "1fUgs3nLnM66TTPZkQGA7o", name: "About Temporary", createdAt: "2021-04-16T14:12:00.342Z", ctype: "video", updatedAt: "2021-04-22T17:15:55.291Z", …}
**  [2]
** 	ctype: "region"
** 	name: "BF-Section-3"
** 	placement: "Full-Width"
** 	elements: Array(2)
** 		0: {cfid: "1JoMP98m062kGewYoBJJ2W", createdAt: "2021-04-30T20:04:00.489Z", text: {…}, ctype: "textArea", updatedAt: "2021-04-30T20:04:00.489Z"}
** 		1: {cfid: "3c5sNLnXyEMAFM5omR1mvK", createdAt: "2021-04-30T20:05:42.456Z", leftContent: Array(1), ctype: "folios", updatedAt: "2021-04-30T20:05:42.456Z", …}
*/


const renderSection = (section, quoteWidth, uniqueKey) => {
	// If we get other than a region, make one that contains this single item.
	// Ensure its type is one we know how to render.

	if (section.ctype !== 'region') {
		if (section.ctype !== 'image' && section.ctype !== 'textArea' && section.ctype !== 'video' && section.ctype !== 'imageSlider' && section.ctype !=='audio')
			return <div></div>

		section =
		{
			elements: [section],
			placement: section.placement
		}
	}

	if (!section.elements?.length)
		return <div></div>

	let size = ''
	let placement = ''

	switch (section.placement) {
		default:
		case 'Centered':
			size = window.innerWidth <= 1440 ? '800px' : '900px'
			placement = 'center'
			break;

		case 'Full-Width':
			size = '100%'
			placement = 'center'
			break;

		case 'Left-Justified':
			size = quoteWidth + 'px'
			placement = 'flex-start'
			break;

		case 'Right-Justified':
			size = quoteWidth + 'px'
			placement = 'flex-end'
			break;
	}

	const ank = section.elements[0]?.anchor ? section.elements[0].anchor.substr(1) : '';



	return (
		<Section id={ank} key={section.toString().substr(20) + '' + uniqueKey} size={size} placement={placement} >
			{ section.elements.map((e, k) => { return renderElement(e, k) })}
		</Section>
	)
}

const renderElement = (e, uniqueKey) => {


	if(e.ctype === 'audio'){
		return (
			<PodcastBlock {...e}/>
		)
	}
	if (e.ctype === 'textArea') {
		return (
			<BlogText key={e.toString().substr(20) +''+ uniqueKey}>
				<RichText body={e.text} options={{ color: 'black' }} />
			</BlogText>
		)
	}

	if (e.ctype === 'image'){
		if (e?.hyperlink) {
			if (e.hyperlink?.charAt(0) === '/') {
				return <Link key={uniqueKey}  to={e?.hyperlink}>
					<BlogImage src={e.imageAsset} alt={'Body'}/>
				</Link>
			} else {
				return <a key={uniqueKey}  href={`${e?.hyperlink}`} target="_blank" rel="noopener noreferrer">
					<BlogImage src={e.imageAsset} alt={'Body'}/>
				</a>
			}
		} else {
			return <BlogImage key={e.imageAsset.substr(20)+''+ uniqueKey} src={e.imageAsset} />
		}

	}


	if (e.ctype === 'imageSlider')
		return <BlogSlider key={uniqueKey} slides={e?.images} />

	if (e.ctype === 'video') {

		let arr = e?.videoUrl?.split('/')

		let isChannel = arr?.includes('channel')

		if (e.description && e.videoUrl) {
			return (
				<SectionVideo key={uniqueKey}>
					<BlogLeftVideo>
						{/* <TitleMd lineHeight="1.15">{e.name}</TitleMd> */}
						<WistiaPopupEmbed
							playButton={true}
							customClass="wistia_custom"
							hashedId={arr[arr?.length - 1]}
							isChannel={isChannel}
						/>
						<Caption>{e.headline}</Caption>
					</BlogLeftVideo>
					<SectionVideoDesc>
						<RichText body={e.description} />
					</SectionVideoDesc>
				</SectionVideo>
			)
		}
		else if (e.videoUrl) return (
			<BlogWistiaVideo key={uniqueKey}>
				<TitleMd lineHeight="1.15">{e.headline}</TitleMd>
				<div>
					<WistiaPlayer
						controlsVisibleOnLoad={false}
						customClass="wistia_custom"
						hashedId={arr[arr.length - 1]}
						isChannel={isChannel}
					/>
				</div>
			</BlogWistiaVideo>
		)
	}
	// Ignore anything else that can be dropped into a Region, but is not handled in blog detail
	return <div> </div>
}

function BlogDetail({ authors, body, sections, size }) {

	const sectionRef = useRef(null)

	const [quoteWidth, setQuoteWidth] = useState(1200)

	let timeout = false;

	const resizeObserver = () => {
		// debouced this resize call to only fire once at the end of the resize
		clearTimeout(timeout);
		timeout = setTimeout(() => {
			// get heights
			if (sectionRef.current) {
				let windowWidth = window.innerWidth >= (2500 - "30%") ? 2420 : sectionRef.current.clientWidth
				let sectionWidth = windowWidth - (window.innerWidth <= 1440 ? 800 : 900);
				let dividedWidth = sectionWidth / 2;
				setQuoteWidth(dividedWidth + (window.innerWidth <= 1440 ? 800 : 900))
			}

		}, 750);
	};

	useEffect(() => {
		if (sectionRef.current) {
			let windowWidth = window.innerWidth >= (2500 - "30%") ? 2420 : sectionRef.current.clientWidth
			let sectionWidth = windowWidth - (window.innerWidth <= 1440 ? 800 : 900);
			let dividedWidth = sectionWidth / 2;
			setQuoteWidth(dividedWidth + (window.innerWidth <= 1440 ? 800 : 900))
		}

		window.addEventListener('resize', resizeObserver, true);
		return () => { window.removeEventListener('resize', resizeObserver) }
		// eslint-disable-next-line
	}, [])

	if (sections)				// Section-based
	{
		return (
			<Sections  ref={sectionRef}>

				{ sections && sections.map((s, i) => { return renderSection(s, quoteWidth, i) })}

				<Section className="author-section" size="900px" placement="center" >
					<AuthorInfo authors={authors} />
				</Section>

			</Sections>
		)
	}
	else if (body)				// WYSIWSYG-based
	{
		return (
			<DetailWrap>
				<RichText body={body} options={{ color: 'black' }} />
				<Section marginLeft="0" marginRight="0" placement="center">
					<AuthorInfo authors={authors} />
				</Section>
			</DetailWrap>
		)
	}
	else
		return <div></div>
}

export default BlogDetail
