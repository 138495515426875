import styled from "styled-components";
import {

  TitleXl,
  TitleLg,
  TitleSm,
  BodySm,
  WideScreenContainer,

} from "components/elements";
import {

  sizeLaptop,
  sizeMobileLg,

} from "components/devices";


export const ImageContainer = styled.div`
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
  box-sizing: border-box;

  @media (max-width: 991px) {
    width: 100%;
  }
`;

export const StyledImage = styled.img`
  max-width: 100%;
  max-height: 100%;
  object-fit: cover;
`;

export const ContentContainer = styled.div`
  width: 50%;
  height: 851px;

  @media (max-width: 991px) {
    width: 100%;
    height: auto;
  }
`;

export const ResponsiveWideScreenContainer = styled(WideScreenContainer)`
  display: flex;

  @media (max-width: 991px) {
    flex-direction: column-reverse;
  }
`;
export const AvantHeader = styled.div`
    flex: 0 0 50%;
    width: 100%;
    min-height: 851px;
    background-image: url("/images/AvantBackground1.jpeg");
    -webkit-background-size: cover;
    -moz-background-size: cover;
    background-size: cover; 
    padding: 10% 70px 3% 10%;
    ${sizeLaptop(`
        min-height: 850px;
    `)}
    ${sizeMobileLg(`
        min-height: 500px;
        // padding: 35% 15px 0;
    `)}
`
export const BodyBoldContainer = styled.div`
    font-size: .5em;
    padding-top: 40px;
    ${TitleXl} {
        color: #fff;
        ${sizeMobileLg(`
        font-size: 15px !important;
        line-height: 20px !important;
   `)}
}

`
export const BodyContainer = styled.div`
    font-size: .5em;
    padding-top: 40px;
    ${TitleSm} {
        color: #fff;
        ${sizeMobileLg(`
        font-size: 15px !important;
        line-height: 20px !important;
   `)}
}

`
export const TitleSmContainer = styled.div`
    font-size: .5em;
    max-width: 75%;
    ${TitleSm} {
        color: #fff;
        ${sizeMobileLg(`
        font-size: 15px !important;
        line-height: 20px !important;
   `)}
}

`

export const BodySmContainer = styled.div`
    padding: 6% 0 0;
    max-width: 80%;
    ${BodySm} {
        color: #fff;
        font-weight: 400;
        font-size: 20px;
        line-height: 24px;
    ${sizeMobileLg(`
        font-size: 16px !important;
        line-height: 19px !important;
    `)}
    }
    ${sizeMobileLg(`
        max-width: 100%;
    `)}

}

`

export const TitleLgContainer = styled.div`
    margin: 3% 0 0;
    font-size: 1.125em;
    ${sizeMobileLg(`
        margin: 5% 0 0;
        width: 100%;
    `)}
${TitleLg} {
    font-weight: 900;
    color: #fff;
    padding: 0 0 5px;
    .magenta {
        color: #D12FFF;
    }
    .hide-mobile {
        display: none;
    }
    ${sizeMobileLg(`
        font-size: 40px !important;
        padding: 0 0 0;
   `)}
}

`
