
import React from 'react'

import
{
	PopupCardWrap,
	PopupCardNumber,
	PopupCardImg,
	PopupCardImgWrap,
	PopupCardInfo,
	PlusIcon,
	PlusIconButton,
	PopupCardDetail,
	PopupCardLink,
} from './elements'

import { TitleSm } from '../elements'
import RichText from '../RichText'

function PopupCard({ hoverTrack, clickTrack, onCardClick, number, cardNumber, cardImg, icon, cardText, openOverlay, handleShowHideOverlay, ...props })
{
	const handleClose = (e) =>
	{
		if (openOverlay)
		{
			e.stopPropagation()
			handleShowHideOverlay(false)
			setTimeout( () => { onCardClick(null); }, 700 )

		}
	}

	const handleOpen = (e) =>
	{
		handleShowHideOverlay(true)
		onCardClick(number)
	}

	return (
	    <PopupCardWrap
		className={`${clickTrack === number && openOverlay ? "showOverlay" : ""} 
		${clickTrack !== number ? "" : "hoverRemove"}`}
	    >
		<PopupCardImgWrap>
			<PopupCardNumber>{cardNumber}</PopupCardNumber>
			<PopupCardImg src={`${cardImg}`} />
		</PopupCardImgWrap>

		<PopupCardInfo onClick={handleOpen}>
			<PlusIconButton onClick={openOverlay && clickTrack === number ? handleClose : handleOpen}>
			    <PlusIcon src={`/images/${icon}`} />
			</PlusIconButton>
			<PopupCardDetail>
			    <TitleSm color="#fff">{cardText}</TitleSm>
				<RichText body={props.detail} options={{ color: "#FFF" }}/>
			    <PopupCardLink to={props.ctaLink}>{props.ctaText}</PopupCardLink>
			</PopupCardDetail>
		</PopupCardInfo>
	    </PopupCardWrap>
	)
}

export default PopupCard

