import React, { useCallback } from 'react';
import { callApi } from '../config/callApi';
import { r3cPardotProxy } from "../constants";

const useFormManager = (initial, OPTIONAL = []) => {

  const [subscriptionFormData, setSubscriptionFormData] = React.useState(initial);
  const [isSubmitted, setIsSubmitted] = React.useState(false);

  // let [formData, setData] = React.useState([]);


  const handleSubmit = async (route) => {
    let { data, errors } = subscriptionFormData;
    let isAnyError = false;
    for (const key in data) {
      if (!(typeof data[key] === 'boolean')) {
        if (data[key]?.trim().length < 1 && !OPTIONAL.includes(key)) {
          errors[key] = "Required Field";
          isAnyError = true;
        }
      }
    }
    if (data['email'] && data['email'].length > 0 && !validateEmail(data['email'])) {
      isAnyError = true;
      errors['email'] = 'Please enter valid email'
    }

    if (data['blogEmail'] && data['blogEmail'].length > 0 && !validateEmail(data['blogEmail'])) {
      isAnyError = true;
      errors['email'] = 'Please enter valid email'
    }
    if (isAnyError) {
      setSubscriptionFormData({
        ...subscriptionFormData,
        errors
      });
      return;
    }

    if(data.test === ""){
      delete data["test"];
    }
    // callApi(address, 'POST', subscriptionFormData).then(result => {
    //     console.log("My first Api", result);
    // })
    // if (subscriptionFormData.errors.email.length > 0 && subscriptionFormData.errors.blog_email.length > 0) {
    //     return;
    // }

    setSubscriptionFormData(initial)
    setIsSubmitted(true);
    await callApi(r3cPardotProxy, 'POST', data);
  }

  function validateEmail(email) {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }

  const handleOnInputChange = useCallback((e) => {
    setSubscriptionFormData({
      data: {
        ...subscriptionFormData.data,
        [e.target.name]: e.target.name === 'test' ? e.target.checked : e.target.value
      },
      errors: {
        ...subscriptionFormData.errors,
        [e.target.name]: e.target.value?.trim().length <= 0 && !OPTIONAL.includes(e.target.name) ? "Required Field" : "",

      }
    })
  }, [subscriptionFormData, setSubscriptionFormData, OPTIONAL])

  return (
    {
      handleOnInputChange,
      setSubscriptionFormData,
      handleSubmit,
      isSubmitted,
      setIsSubmitted,
      subscriptionFormData,
    }
  );
};

export default useFormManager;
