import styled from 'styled-components';
import {
    sizeLaptop,
    sizeLaptopMd,
    sizeMobileLg,
    sizeTabletLg,
    sizeTablet,
    sizeWideScreen, sizeMobileMd
} from "components/devices";
import {
    SectionTitle, 
    BodySm,
    TitleXl
} from "components/elements";

export const TabbedGridSectionWrap = styled.div`

  ${SectionTitle} {
    padding-top: 10px;
    padding-bottom: 35px;
    ${BodySm}{
      font-size: 16px;
      line-height: 19px;
      text-transform: uppercase;
      ${sizeTabletLg(`
        font-size: 15px;
        line-height: 20px;
      `)}
    }
    ${TitleXl}{
      font-size: 48px;
      line-height: 57px;
      font-weight: 900;
      ${sizeTabletLg(`
        font-size: 36px;
        line-height: 43px;
    `)}
    }
    .magenta {
      color: #D12FFF;
    }
  }

  .slick-slider {
    padding-top: 100px;
    ${sizeTablet(`
        padding-top: 130px;
    `)}
  }
  .slick-dots {
    top: 0;
    bottom: auto;
    margin-bottom: 40px;
    li {
      width: 100%;
      max-width: 450px;
      height: auto;
      ${sizeTablet(`
        max-width: 48%;
        margin: 0;
      `)}
      button {
        width: 100%;
        height: 80px;
        background: transparent;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        border: none;
        cursor: pointer;
        text-transform: uppercase;
        font-weight: 400;
        color: #000;
        position: relative;
        z-index: 1;
        overflow: hidden;
        padding: 17px 55px;
        border-bottom: 4px solid transparent;
        ${sizeTablet(`
          flex-direction: row !important;
          padding: 0 0 !important;
          &:before { 
              font-size: 20px !important;
              line-height: 24px !important;        
              height: 25px !important;
          }
        `)}
        &:after {
          content: none !important;
        }
        &:before {
          content: "";
          width: 100%;
          height: 100%;
          opacity: 1;       
          font-family: inherit;
          position: relative;
          top: 10px;
          left: 0;
        }
      }
      &.slick-active {
        button {
          border-color: #D12FFF;
          &:before {
            color: #D12FFF;
          }
        }

      }
      &:first-child {
        button {
          &:before {
            content: 'United States';
            font-size: 36px;
            line-height: 43px;
          }
        }
      }
      &:nth-child(2) {
        button {
          &:before {
            content: 'United Kingdom';
            font-size: 36px;
            line-height: 43px;
          }
        }
      }
      &:nth-child(3) {
        button {
          &:before {
            content: 'Canada';
            font-size: 36px;
            line-height: 43px;
          }
        }
      }
    }
  }
`;
export const Tabs = styled.div`

`;

export const TabsList = styled.div`
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  border: none;
  justify-content: center;
  position: relative;
  max-width: ${({widthTabs}) => widthTabs || "710px"};
  width: 100%;
  margin: 0 auto;

  ${sizeTablet(`
        // -ms-flex-wrap: wrap;
        // flex-wrap: wrap;
    `)}
  .Tabs__presentation-slider {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 4px;
    background-color: #D12FFF;
    transform-origin: 0 0;
    transition: transform .25s;
    ${sizeTablet(`
        display: none;
    `)}
  }
`;
export const TabsLink = styled.button`
  width: auto;
  background: transparent;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  border: none;
  cursor: pointer;
  text-transform: uppercase;
  font-weight: 400;
  color: #000;
  position: relative;
  z-index: 1;
  overflow: hidden;
  padding: 17px 55px;
  border-bottom: 4px solid transparent;

  &:last-child {
    border-right: none;
  }

  &:hover {
    color: #D12FFF;
  }

  &.active {
    color: #D12FFF;

    &:nth-child(1) ~ .Tabs__presentation-slider {
      transform: translateX(calc(0.5% * 2)) scaleX(0.49);
    }

    &:nth-child(2) ~ .Tabs__presentation-slider {
      transform: translateX(calc(24.8% * 2)) scaleX(0.49);
    }

    &:before {
      content: none;
    }

    ${sizeTablet(`
         border-color: #D12FFF;
    `)}
  }

  &:focus {
    outline: none;
    box-shadow: none;
  }

  ${sizeTabletLg(`
        font-size: 26px;
        padding: 15px 30px;
        border-width: 2px;
        margin-bottom: 15px;
    `)}
  ${sizeMobileMd(`
    width: 100%;
  `)}

  ${sizeWideScreen(`
        font-size: 0.7vw;
    `)}
`;
export const GridRow = styled.div`
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin: 0 -30px;
  ${sizeLaptopMd(`
        margin: 0 -20px;
    `)}
  ${sizeMobileLg(`
        margin: 0;
    `)}
`;

export const TabContentWrap = styled.div`

`;
export const TabsContent = styled.div`

`;

export const GridSectionWrap = styled.div`
  padding: 70px 72px 0 72px;
  min-height: 700px;
  position: relative;
  ${sizeLaptop(`
        padding: 50px 30px 0 30px;
    `)}
    ${sizeMobileLg(`
      padding: 50px 15px 0 15px;
` )}
`;
export const GridCol = styled.div`
  width: 33.3333%;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 33.3333%;
  flex: 0 0 33.3333%;
  padding: 0 5px;
  margin-bottom: 80px;
  box-sizing: border-box;
  ${sizeLaptopMd(`
      width: 50%;
      -webkit-box-flex: 0;
      -ms-flex: 0 0 50%;
      flex: 0 0 50%;
      padding: 0 10px;
    `)}
  ${sizeMobileLg(`
        width: 100%;
        -webkit-box-flex: 0;
        -ms-flex: 0 0 100%;
        flex: 0 0 100%;
        padding: 0;
  `)}

  h5 {
    font-weight: 900;
    font-size: 32px;
    line-height: 47px;
  }

  p {
    font-size: 16px;
    line-height: 23px;
  }
`;
