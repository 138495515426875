import React, {useEffect, useState} from 'react'
import {
    MediaButtonsWrap,
    MediaButtonsRow,
    MediaButtonsScroll
} from './elements'
import ReleaseIcon from "../../assets/icons/ReleaseIcon";
import BlogIcon from "../../assets/icons/BlogIcon";
import PodcastIcon from "../../assets/icons/PodcastIcon";
import EventsIcon from "../../assets/icons/EventsIcon";
import MediaLink from "../MediaLink";
import { useAppContext } from 'store';


function MediaButtons() {
 
    const { activeMediaFilter } = useAppContext();

   

    const [btns, setBtns] = useState([
        {
            id:'all',
            text:'All Media',
            status:'active',
            icon:'',
        },
        {
            id:'BlogPost',
            text:'Blog',
            status:'',
            icon:<BlogIcon/>,
        },
        {
            id:'PressRelease',
            text:'Press Releases',
            status:'',
            icon:<ReleaseIcon/>,
        },
        {
            id:'Podcast',
            text:'Podcasts',
            status:'',
            icon:<PodcastIcon/>,
        },
        {
            id:'Event',
            text:'Events',
            status:'',
            icon:<EventsIcon/>,
        },

    ]);


    useEffect(()=>{
        let temp = []
        temp=btns.map(item=>({...item, status:''}));
        let index = temp.findIndex(item => item.id === activeMediaFilter)
        temp[index]['status'] = 'active';
        setBtns(temp)

        //  eslint-disable-next-line 
    },[activeMediaFilter])

    
  
    return (
        <MediaButtonsScroll>
            <MediaButtonsWrap >
                <MediaButtonsRow className={"article type"}>
                    {
                        btns.map((item,i)=>{
                            return(
                                <MediaLink
                                    filterTerm={item.id}
                                    key={i}
                                    id={item.id}
                                    index={i}
                                    icon={item.icon}
                                    text={item.text}
                                    status={item.status}
                                />
                            )
                        })
                    }
                </MediaButtonsRow>
            </MediaButtonsWrap>
        </MediaButtonsScroll>
    )
}

export default MediaButtons;

